import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Box, Divider, Button } from "@mui/material";
import CalendarIcon from "./../../../../assets/calendar.png";
import RemoveIcon from "./../../../../assets/minus.svg";

import { removeButton, justifyContentEnd } from "../../../../util/style";
import axios, { headersAPIToken } from "../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  SelectOneAutocompleteSearch,
} from "../../../../components/Form";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const STEP_NAME = "StepFour";

// const [isFormValid, setIsFormValid] = useState(false);

const consultantOptions = Object.freeze({
  YES: "yes",
  NO: "no",
});

const TYPE_FIELD = Object.freeze({
  CONSULTANT: "With consultant",
  NO_CONSULTANT: "Whithout consultant",
});

const fields = [
  {
    title: "Representative/IP Consultan",
    children: [
      {
        name: "consultan_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Client Name",
        placeholder: "Select client name",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
      },
      {
        name: "consultan_number",
        type: INPUT_TYPE.NUMBER,
        label: "Number of Consultant",
        placeholder: "Select applicant",
        defaultValue: "",

        //           apiEndpoint: "/master/applicants",
        required: false,
        gridWidth: 6, // Half width
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "office_name",
        type: INPUT_TYPE.TEXT,
        label: "Office Name",
        placeholder: "Select category",
        defaultValue: "",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        //   apiEndpoint: "/master/services", // get all service
        required: false,
        gridWidth: 6, // Half width
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "address",
        type: INPUT_TYPE.TEXTAREA,
        label: "Address",
        placeholder: "Select type",
        defaultValue: "",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        //           apiEndpoint: "/master/services",
        //           apiEndpoint: `/master/services?main_service_id=${id}`,
        required: false,
        gridWidth: 6, // Half width
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "mobile_phone",
        type: INPUT_TYPE.PHONE_NUMBER,
        label: "Phone",
        placeholder: "Input mobile phone",
        defaultValue: "",
        validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
        errorMessage:
          "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
        required: false,
        gridWidth: 6, // Half width
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "email",
        type: INPUT_TYPE.TEXT,
        label: "Email",
        defaultValue: "",
        required: false,
        validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        errorMessage: "Invalid email address",
        gridWidth: 6, // Full width
        additionalProps: {
          disabled: true,
        },
      },
    ],
  },
];

const fieldNoConsultan = [
  {
    title: "Representative/IP Consultan",
    children: [
      {
        name: "notes",
        type: INPUT_TYPE.TEXTAREA,
        defaultValue: "",
        required: true,
        placeholder: "Input Notes",
        label: "Notes",
        gridWidth: 12,
      },
    ],
  },
];

const radioConsultanField = {
  name: "consultan",
  type: INPUT_TYPE.RADIO,
  label: "Consultan",
  defaultValue: [],
  required: true,
  options: [
    { id: consultantOptions.YES, name: "Yes" },
    { id: consultantOptions.NO, name: "No" },
  ],
};

const StepFourRevisi = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
  setIsFormValid,
}) => {
  const { id } = useParams();

  //* Local Storage Hook
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    "trademark_application_options_StepFourRevisi",
    {}
  );

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered]);

  const { token } = useSelector((state) => state.auth);
  const [typeField, setTypeField] = useState(TYPE_FIELD.CONSULTANT);

  //* ===================================================================  */
  //*                              INITIAL FORM                            */
  //* ===================================================================  */

  const initialFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };

  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialErrorFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };
  const initialErrorEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialFormValues = (typeField = TYPE_FIELD.CONSULTANT) => {
    const initialValues = {};
    if (typeField == TYPE_FIELD.CONSULTANT) {
      fields.forEach((fieldGroup) => {
        if (fieldGroup.children) {
          //* Used for other section
          if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
            initialValues[fieldGroup.title] =
              fieldGroup.title === "Family" ? [initialFamily] : [initialEmergencyContact];
          } else {
            //* Check if section group undefined
            if (!initialValues[fieldGroup.title]) {
              initialValues[fieldGroup.title] = {};
            }

            initialValues[fieldGroup.title][radioConsultanField.name] =
              radioConsultanField.defaultValue;

            fieldGroup.children.forEach((field) => {
              //* Used for this section
              initialValues[fieldGroup.title][field.name] = field.defaultValue;
            });
          }
        }
      });
    } else if (typeField === TYPE_FIELD.NO_CONSULTANT) {
      fieldNoConsultan.forEach((fieldGroup) => {
        if (fieldGroup.children && Array.isArray(fieldGroup.children)) {
          //* Check if section group undefined
          if (!initialValues[fieldGroup.title]) {
            initialValues[fieldGroup.title] = {};
          }

          initialValues[fieldGroup.title][radioConsultanField.name] =
            radioConsultanField.defaultValue;

          fieldGroup.children.forEach((field) => {
            initialValues[fieldGroup.title][field.name] = field.defaultValue || "";
          });
        }
      });
    }

    return {
      ...initialValues,
    };
  };

  const initialErrors = (typeField = TYPE_FIELD.CONSULTANT) => {
    const initialErrorValues = {};

    if (typeField === TYPE_FIELD.CONSULTANT) {
      fields.forEach((fieldGroup) => {
        if (fieldGroup.children) {
          //* Used for other section
          if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
            initialErrorValues[fieldGroup.title] =
              fieldGroup.title === "Family"
                ? [initialErrorFamily]
                : [initialErrorEmergencyContact];
          } else {
            //* Check if section group undefined
            if (!initialErrorValues[fieldGroup.title]) {
              initialErrorValues[fieldGroup.title] = {};
            }
            initialErrorValues[fieldGroup.title][radioConsultanField.name] =
              radioConsultanField.defaultValue;
            fieldGroup.children.forEach((field) => {
              initialErrorValues[fieldGroup.title][field.name] = "";
            });
          }
        }
      });
    } else if (typeField === TYPE_FIELD.NO_CONSULTANT) {
      fieldNoConsultan.forEach((fieldGroup) => {
        if (fieldGroup.children && Array.isArray(fieldGroup.children)) {
          //* Check if section group undefined
          if (!initialErrorValues[fieldGroup.title]) {
            initialErrorValues[fieldGroup.title] = {};
          }
          initialErrorValues[fieldGroup.title][radioConsultanField.name] =
            radioConsultanField.defaultValue;

          fieldGroup.children.forEach((field) => {
            initialErrorValues[fieldGroup.title][field.name] = "";
          });
        }
      });
      initialErrorValues[radioConsultanField.name] = "";
    }

    return initialErrorValues;
  };

  // ---------------------- [ STATE ] ---------------------------- */
  const [options, setOptions] = useState({});

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0 ? formData : initialFormValues()
  );

  const [errors, setErrors] = useState(
    formData && Object.values(formData)?.length > 0
      ? cloneObjectWithEmptyStrings(formData)
      : initialErrors()
  );

  const fetchDataType = async () => {
    // const serviceId = formValues["Applicant Information"]["category_id"];
    // if (serviceId) {
    //   const { data } = await (
    //     await axios.get(`/master/criteria/${serviceId}`, headersAPIToken(token))
    //   )?.data;
    //   setOptions((prevOptions) => ({
    //     ...prevOptions,
    //     ["type_id"]: data,
    //   }));
    //   if (localStorageValue) {
    //     const copyLocalStorage = { ...localStorageValue };
    //     copyLocalStorage["type_id"] = data;
    //     setLocalStorageValue(copyLocalStorage);
    //   } else {
    //     // localStorage.setItem("business_permit_options_step_one", JSON.stringify(options));
    //     setLocalStorageValue(options);
    //   }
    // }
  };

  const fetchDataApplicantAndBrand = async () => {
    // const client_id = formValues["Applicant Information"]["client_id"];
    // if (client_id) {
    //   const aplicanEndpoint = `/master/applicants`;
    //   const brandEndpoint = `/master/brands`;
    //   const param = { params: client_id };
    //   fetchOption(aplicanEndpoint, "applicant_id", null, param);
    //   fetchOption(brandEndpoint, "brand_id", null, param);
    // }
  };

  useEffect(() => {
    if (!localStorageValue || Object.keys(localStorageValue || {}).length === 0 || id) {
      console.log("Masuk fetch option");
      //* Change to self invoke function
      (async function () {
        try {
          const fetchedOptions = {};

          for (const section of fields) {
            if (section.children) {
              for (const field of section.children) {
                const { name, apiEndpoint } = field;
                console.log({ name, apiEndpoint });
                if (apiEndpoint) {
                  const response = await axios.get(apiEndpoint, headersAPIToken(token));
                  fetchedOptions[name] = response.data.data;
                }
              }
            }
          }

          // Store fetched options in localStorage
          setLocalStorageValue(fetchedOptions);

          // Set options in state
          setOptions(fetchedOptions);
          fetchDataType();
          fetchDataApplicantAndBrand();
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      })();
    } else {
      console.log("Gk masuk fetcg option");
      setOptions(localStorageValue);
      fetchDataType();
    }
    console.log("Initial option");
  }, [token]);

  const fetchOption = async (
    endpoint,
    optionName,
    aliasName = "",
    customConfig = null
  ) => {
    try {
      let config = { ...headersAPIToken(token) };

      if (customConfig) {
        config = { ...config, ...customConfig };
      }

      console.log("This is fetch option", { config });

      const response = await axios.get(endpoint, config);
      let optionPayload = response?.data?.data;

      //* If option don't have key name, pass alias name for make alias;
      if (aliasName) {
        optionPayload = optionPayload.map((opt) => {
          opt["name"] = opt[aliasName];
          return opt;
        });
      }
      if (Array.isArray(optionPayload)) {
        //* Set option
        setOptions((prevValue) => ({ ...prevValue, [optionName]: optionPayload }));

        //* Set to localStorage
        if (localStorageValue) {
          const copyLocalStorage = { ...localStorageValue };
          copyLocalStorage[optionName] = optionPayload;
          setLocalStorageValue(copyLocalStorage);
        }
      } else {
        console.warn("Option payload not array");
        throw new Error("Failed get option for : ".concat(optionName));
      }
    } catch (error) {
      console.error(`failed get option for ${optionName} : ${error}`);
    }
  };

  //* ===================================================================  */
  //*                               HANDLER                                */
  //* ===================================================================  */

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    // console.log(event);
    // return
    console.group("Radio change");

    if (value === consultantOptions.YES) {
      const newInitialForm = initialFormValues(TYPE_FIELD.CONSULTANT);
      const newInitialError = initialErrors(TYPE_FIELD.CONSULTANT);
      // console.log({ newInitialError, newInitialForm, value });
      newInitialForm[fieldNoConsultan[0].title][name] = value;
      setFormValues(newInitialForm);
      setErrors(newInitialError);
      setTypeField(TYPE_FIELD.CONSULTANT);
    } else if (value === consultantOptions.NO) {
      const newInitialForm = initialFormValues(TYPE_FIELD.NO_CONSULTANT);
      const newInitialError = initialErrors(TYPE_FIELD.NO_CONSULTANT);
      // console.log({ newInitialError, newInitialForm, value });
      newInitialForm[fields[0].title][name] = value;
      setFormValues(newInitialForm);
      setErrors(newInitialError);
      setTypeField(TYPE_FIELD.NO_CONSULTANT);
    }
    console.groupEnd();
  };

  // useEffect(() => {
  //   if (typeField === TYPE_FIELD.CONSULTANT) {
  //   } else {
  //   }
  // }, [typeField]);

  const handleAddRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Family" ? initialErrorFamily : initialErrorEmergencyContact,
      ], // Add an empty error object
    }));
  };

  const handleRemoveRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter((row, index) => index !== rowIndex),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  };

  const handleInputChange = async (event, index, sectionTitle, field) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      updatedValues[sectionTitle] = {
        ...updatedValues[sectionTitle],
        [name]: value,
      };

      return updatedValues;
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      const fieldValue = value;
      const fieldErrors = validateFieldHelper(fieldValue, field);
      updatedErrors[sectionTitle] = {
        ...updatedErrors[sectionTitle],
        [name]: fieldErrors,
      };
      return updatedErrors;
    });

    // if (name === "category_id") {
    //   if (value) {
    //     const serviceId = value;
    //     const criteriaEndpoint = `/master/criteria/${serviceId}`;
    //     fetchOption(criteriaEndpoint, "type_id");
    //   }
    // }

    // if (name === "client_id") {
    //   if (value) {
    //     const client_id = value;
    //     const param = { params: { client_id } };
    //     const applicantEndpoint = "master/applicants";
    //     const brandEndpoint = "/master/brands";

    //     fetchOption(applicantEndpoint, "applicant_id", null, param);
    //     fetchOption(brandEndpoint, "brand_id", null, param);

    // const { data: dataApplicants } = await (
    //   await axios.get(``, {
    //     headers: { Authorization: `Bearer ${token}` },
    //     ,
    //   })
    // )?.data;

    // const { data: dataBrands } = await (
    //   await axios.get(``, {
    //     headers: { Authorization: `Bearer ${token}` },
    //     params: { client_id },
    //   })
    // )?.data;

    // setOptions((prevOptions) => ({
    //   ...prevOptions,
    //   ["applicant_id"]: dataApplicants,
    //   ["brand_id"]: dataBrands,
    // }));

    // const fetchedOptions = JSON.parse(
    //   localStorage.getItem("business_permit_options_step_one")
    // );
    // if (fetchedOptions) {
    //   fetchedOptions["brand_id"] = dataBrands;
    //   fetchedOptions["applicant_id"] = dataApplicants;
    //   localStorage.setItem(
    //     "business_permit_options_step_one",
    //     JSON.stringify(fetchedOptions)
    //   );
    // }
    //   }
    // }

    updateWizardData(STEP_NAME, formValues);
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    // console.log();
    updateWizardData(STEP_NAME, formValues);
    validateField(sectionTitle, fieldName, index, { name, value });
    // debugger;
  };

  //* ===================================================================  */
  //*                               VALIDATE                               */
  //* ===================================================================  */

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const validateCallback = (fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;

        const sectionErrors =
          fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact"
            ? []
            : {};

        fieldGroup.children.forEach((field) => {
          const { name } = field;

          if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
            formValues[title].forEach((sectionValues, index) => {
              const fieldValue =
                sectionValues[name] !== undefined ? sectionValues[name] : "";
              const error = validateFieldHelper(fieldValue, field);

              if (!sectionErrors[index]) {
                sectionErrors[index] = {};
              }

              sectionErrors[index][name] = error;

              if (error) {
                valid = false;
              }
            });
          } else {
            const fieldValue =
              formValues[title] && formValues[title][name] !== undefined
                ? formValues[title][name]
                : "";
            const error = validateFieldHelper(fieldValue, field);

            sectionErrors[name] = error;

            if (error) {
              valid = false;
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    };

    if (typeField === TYPE_FIELD.CONSULTANT) {
      fields.forEach(validateCallback);
    } else if (typeField === TYPE_FIELD.NO_CONSULTANT) {
      fieldNoConsultan.forEach(validateCallback);
    }

    setErrors(newErrors);
    formAttempted(valid);
    updateWizardData(STEP_NAME, formValues);
    return valid;
  };

  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    let fieldGroup;
    // console.group("Validate field");
    if (typeField === TYPE_FIELD.CONSULTANT) {
      fieldGroup = fields.find((group) => group.title === sectionTitle);
    } else if (typeField === TYPE_FIELD.NO_CONSULTANT) {
      fieldGroup = fieldNoConsultan.find(
        (fieldGroup) => fieldGroup.title === sectionTitle
      );
    }

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find((field) => field.name === fieldName);
      // console.log({ fieldGroup });
      if (field) {
        const { name } = field;
        // console.log("Dalam field",{field});

        const fieldValue = formValues[sectionTitle][name] || value;
        newErrors[sectionTitle][name] = validateFieldHelper(fieldValue, field);
      }
    }
    // console.log({newErrors});
    setErrors(newErrors);
    // console.groupEnd();
  };

  /**
   *
   * @param {any} fieldValue
   * @param {Object} field
   * @returns {String}
   */
  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (fieldValue && fieldValue.length && validation && !validation(fieldValue)) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    updateWizardData(STEP_NAME, formValues);
  }, [formValues]);

  //* ===================================================================  */
  //*                               WATCHER                                */
  //* ===================================================================  */

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  useEffect(() => {
    console.log({ typeField });
  }, [typeField]);

  useEffect(() => {
    console.log("This is option", { options });
  }, [options]);

  //* ===================================================================  */
  //*                               COMPONENT                              */
  //* ===================================================================  */

  const renderFields = (section, index, field) => {
    const currentFormValues = formValues[section.title];

    const currentErrors = errors[section.title];
    return (
      <>
        {section.children?.map((field) => {
          //   const checkCategoryId = formValues["Applicant Information"]["category_id"];

          //   const isCategoryIdBPOMandExist =
          //     checkCategoryId !== 102 &&
          //     (checkCategoryId !== undefined ||
          //       checkCategoryId !== null ||
          //       checkCategoryId !== "");

          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
                isRequired,
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];

          let fieldComponent = null;
          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, index, e);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          // function filterField(field = fields) {}

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section) => {
    return (
      <Grid item xs={12} key={section.title}>
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {section.title}
          </Typography>
          {/* Subtitle */}
          {section.subtitle && (
            <Typography variant="subtitle2">{section.subtitle}</Typography>
          )}
        </Box>
        <Grid>
          <RadioGroupInput
            errors={{}}
            field={radioConsultanField}
            fieldOptions={radioConsultanField.options}
            formValues={formValues[fields[0].title]}
            handleInputChange={handleRadioChange}
            handleInputBlur={() => {}}
          />
        </Grid>
        <Grid container spacing={1}>
          {renderFields(section)}
          {/* {typeField} */}
        </Grid>
      </Grid>
    );
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {typeField === TYPE_FIELD.CONSULTANT
          ? fields.map((section) => renderSectionFields(section))
          : fieldNoConsultan.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default StepFourRevisi;
