import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";

import DocumentIcon from "./../../../assets/document.png";
import { btnWhite } from "../../../util/style";
import { makeStyles } from "@mui/styles";

import { STEP_NAME } from "./detail_trademark_aplication";

const steps = ["Applicant", "Description", "Class", "Representative", "Attachment"];

const AttachmentItem = ({ item, title }) => {
  const alignItemsCenter = {
    display: "flex",
    alignItems: "center",
  };

  const fourColor = "#yourColorCode"; // Replace with your desired color code

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        margin: ".3rem 0",
        ...alignItemsCenter,
      }}
    >
      {/* <Typography variant="subtitle1" sx={{ minWidth: "170px", display: "block" }}>
        {title}
      </Typography> */}
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "500",
        }}
      >
        <div
          style={{
            ...alignItemsCenter,
            gap: "1rem",
            padding: "0.5rem 0",
          }}
        >
          <img src={DocumentIcon} alt="Document Icon" />
          <span>{item?.filename}</span>
          <span style={{ textTransform: "uppercase", color: fourColor }}>
            {/* {formatSize(item.size)} */}
          </span>
          <a href={item?.url} target="_blank" rel="noopener noreferrer">
            Open
          </a>
        </div>
      </Typography>
    </Box>
  );
};

const DetailWizard2 = ({ wizardData, activeStep }) => {
  // const [activeStep, setActiveStep] = useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const stepsNames = Object.values(STEP_NAME).filter((val) => typeof val !== "function");

  const CustomStepLabel = ({ step, label, isActive, isCompleted }) => {
    const stepIconStyle = {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      backgroundColor: isActive || isCompleted ? "#1268B8" : "gray",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <StepLabel icon={<div style={stepIconStyle}>{step + 1}</div>}>{label}</StepLabel>
    );
  };

  const renderValue = (value, key) => {
    if (typeof value == "string" || typeof value === "number") {
      return value;
    } else if (typeof value === "object") {
      if (Array.isArray(value)) {
        //* For array of object
        return value.map((item) => AttachmentItem({ item }));
        // return value.map((item) => AttachmentItem({ item }));
      } else {
        //* File attachment type
        if ("filename" in value && "size" in value && "url" in value) {
          return AttachmentItem({ item: value, title: key });
        } else {
          //* other type
          const aa = value;
          debugger;
        }
      }
    }
  };

  const RenderClassSection = ({ value, displayTable = false }) => {
    if (Array.isArray(value)) {
      if (displayTable) {
        return (
          <React.Fragment>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "600" }}>Class</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "600" }}>
                Description of Goods/Service
              </Typography>
            </Grid>
            {value.map((item) => (
              <>
                <Grid item xs={6}>
                  {item["Class"]}
                </Grid>
                <Grid item xs={6}>
                  {item["Description of Goods/Service"]}
                </Grid>
              </>
            ))}
          </React.Fragment>
        );
      } else {
        return value.map((item) =>
          Object.entries(item).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={6}>
                {key}
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>{renderValue(value)}</Typography>
              </Grid>
            </React.Fragment>
          ))
        );
      }
    } else {
      return Object.entries(value).map(([key, val]) => (
        <React.Fragment>
          <Grid item xs={6}>
            {key}
          </Grid>
          <Grid item xs={6}>
            {val.map((v) => (
              <Typography sx={{ fontWeight: "600" }}>{renderValue(v)}</Typography>
            ))}
          </Grid>
        </React.Fragment>
      ));
    }
  };

  const renderStepContent = (sectionData, index, arrayData) => {
    const [sectionTitle, valueData] = sectionData;

    const isLastSteps = activeStep === steps.length - 1;

    const RenderObjectValue = ({ valueData }) => {
      return Object.entries(valueData).map(([key, val]) => (
        <React.Fragment key={key}>
          <Grid item xs={6}>
            {key}
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>{renderValue(val, key)}</Typography>
          </Grid>
        </React.Fragment>
      ));
    };

    const RenderArrayValue = ({ valueData }) => {
      return (
        <>
          <RenderClassSection value={valueData} displayTable={false} />
        </>
      );
    };

    const RenderAttachmentSteps = ({ valueData }) => {
      return Object.entries(valueData).map(([key, val]) => (
        <React.Fragment key={key}>
          <Grid item xs={6}>
            {key}
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "600" }}>{renderValue(val, key)}</Typography>
          </Grid>
        </React.Fragment>
      ));
    };

    const DecisionRender = ({ valueData }) => {
      if (isLastSteps) {
        return <RenderObjectValue valueData={valueData} />;
        // return <RenderAttachmentSteps valueData={valueData} />;
      } else if (Array.isArray(valueData)) {
        return <RenderArrayValue valueData={valueData} />;
      } else {
        return <RenderObjectValue valueData={valueData} />;
      }
    };

    return (
      <>
        <Grid style={{ paddingInline: "1.5rem", marginBottom: ".6rem" }}>
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {sectionTitle}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: ".8rem" }}>
            <DecisionRender valueData={valueData} />
          </Grid>
        </Grid>
      </>
    );
    // return Object.entries(stepData).map(([key, value]) => (
    //   <Grid container spacing={steps[activeStep] !== 'Attachment' ? 1.5 : 0} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, p : steps[activeStep] !== 'Attachment' ? 3 : 0, paddingLeft:steps[activeStep] !== 'Attachment' ? 3 : 3 }}>
    //     {/* {key === 'Product Information' && (
    //           <Typography variant="h6" sx={{ fontWeight: 800 }}>Product Information</Typography>
    //         )} */}

    //        {steps[activeStep] !== 'Attachment' && (
    //         <Grid item xs={12}>
    //         {/* ini untuk title */}
    //       <Typography variant="h6" sx={{ fontWeight: 800 }}>{steps[activeStep]}</Typography>
    //       </Grid>
    //        )}

    //     {/* {Object.entries(value).map(([subKey, subValue]) => (
    //        <React.Fragment key={subKey}>
    //         {key === 'Product Information' && (
    //           <Grid item xs={12}>
    //           <div style={{ height: '20px', width: '100%' }}></div>
    //           </Grid>
    //         )}
    //         {typeof subValue === "object" && steps[activeStep] !== 'Attachment' ? (
    //           Object.entries(subValue).map(([nestedKey, nestedValue]) => (
    //               <>
    //               {console.log(key, 'AAAA', steps[activeStep])}
    //               <Grid item xs={7}>
    //                 <Typography variant="body1">{nestedKey}</Typography>
    //               </Grid>
    //               <Grid item xs={5}>
    //                 <Typography variant="subtitle1">{nestedValue}</Typography>
    //               </Grid>
    //             </>
    //           ))
    //         ) : typeof subValue === "object" && steps[activeStep] === 'Attachment' ? (
    //         <AttachmentItem item={subValue} title={key}/>
    //         ): (
    //           <>
    //           <Grid item xs={6}>
    //             <Typography variant="body1">{subKey}</Typography>
    //           </Grid>
    //           <Grid item xs={6}>
    //             <Typography variant="subtitle1">{subValue}</Typography>
    //           </Grid>
    //         </>
    //         )}
    //       </React.Fragment>
    //     ))} */}
    //   </Grid>
    // ));
  };

  // console.log({ wizardData });

  const onUnMount = () => {
    console.log("Wizard detail un moiunted");
  };
  const onMount = () => {
    // console.log(STEP_NAME.toArray());
    return onUnMount;
  };

  useEffect(onMount, []);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{ paddingTop: 2, paddingBottom: 2 }}
    >
      <Grid item xs={12}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomStepLabel
                step={index}
                label={label}
                isActive={index === activeStep}
                isCompleted={index < activeStep}
              />
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>{/* No message displayed for all steps completed */}</div>
          ) : (
            <div>
              {/* <Typography variant="h5">
                {`Step ${activeStep + 1}: ${steps[activeStep]}`}
              </Typography> */}
              <div
                style={{
                  overflow: "auto",
                  padding: steps[activeStep] === "Attachment" ? "10px" : "unset",
                  scrollBehavior: "smooth",
                  marginTop: "1rem",
                }}
              >
                {/* Render content for each step */}
                {/* {renderStepContent()} */}
                {Object.entries(wizardData[stepsNames[activeStep]]).map(
                  renderStepContent
                )}
                {/* {JSON.stringify(wizardData[stepsNames[activeStep]])} */}
              </div>

              {/* =========== Move to parent ============ */}
              {/* <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "flex-end",
                  paddingRight: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ ...btnWhite }}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  disableElevation
                >
                  Previous
                </Button>
                {activeStep === steps.length - 1 ? (
                  // Remove the 'Finish' button on the last step
                  <></>
                ) : (
                  <Button variant="contained" disableElevation onClick={handleNext}>
                    Next
                  </Button>
                )}
              </div> */}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default DetailWizard2;
