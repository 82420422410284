export const GET_TRADEMARK_APPLICATION_SUCCESS = "GET / TRADEMARK APPLICATION SUCCESS";
export const GET_TRADEMARK_APPLICATION_FAIL = "GET / TRADEMARK APPLICATION FAIL";

export const ADD_TRADEMARK_APPLICATION_SUCCESS = "ADD / TRADEMARK APLICATION SUCCESS";
export const ADD_TRADEMARK_APPLICATION_FAIL = "ADD / TRADEMARK APPLICATION FAIL";

export const EDIT_TRADEMARK_APPLICATION_SUCCESS = "EDIT / TRADEMARK APPLICATION SUCCESS";
export const EDIT_TRADEMARK_APPLICATION_FAIL = "EDIT / TRADEMARK APPLICATION FAIL";

export const DELETE_TRADEMARK_APPLICATION_SUCCESS =
  "DELETE / TRADEMARK APPLICATION SUCCESS";
export const DELETE_TRADEMARK_APPLICATION_FAIL = "DELETE / TRADEMARK APPLICATION FAIL";

export const DETAIL_TRADEMARK_APPLICATION_SUCCESS =
  "DETAIL / TRADEMARK APPLICATION SUCCESS";
export const DETAIL_TRADEMARK_APPLICATION_FAIL = "DETAIL / TRADEMARK APPLICATION FAIL";

export const CLEAR_MESSAGE = "CLEAR / TRADEMARK APPLICATION MESSAGE";
