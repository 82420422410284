import {
  ACTION_TYPE,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../util/function";
import { ANOTHER_INPUT_TYPE } from "../../payment-request/parts/imutable_state";

import {
  paymentStatusColor,
  PaymentStatusBadge,
} from "./../../payment-request/parts/CusomStyle";

export const optionCurrency = [
  { id: "Rp", name: "Rp" },
  { id: "USD", name: "USD" },
  { id: "EUR", name: "EUR" },
  { id: "CNY", name: "CNY" },
  { id: "SGD", name: "SGD" },
  { id: "GBP", name: "GBP" },
  { id: "AUD", name: "AUD" },
];

export const field_drawer = {
  client: {
    title: "",
    subtitle: "",
    type: "client",
    isDisplayTitle: false,
    /**@type {import("../clients/classType").FieldType[]} */
    field: [
      {
        name: "no_reference",
        type: INPUT_TYPE.NUMBER,
        label: "No. Reference",
        placeholder: "input no.reference ( Ops )",
        defaultValue: "",
        required: false,
        canChange: true,
      },
      {
        name: "client_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "/master/clients",
        label: "Client Name",
        placeholder: "",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        required: true,
        canChange: true,
      },
      {
        name: "sales_order_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "",
        label: "No. Sales Order",
        placeholder: "",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        required: true,
        canChange: true,
      },
      {
        name: "remark",
        type: INPUT_TYPE.TEXT,
        label: "Remark",
        placeholder: " ",
        defaultValue: "",
        required: false,
        canChange: false,
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "bill_to",
        type: INPUT_TYPE.TEXT,
        label: "Bill To",
        placeholder: "",
        defaultValue: "",
        required: true,
        canChange: true,
      },
      {
        name: "attend",
        type: INPUT_TYPE.TEXT,
        label: "Attn",
        placeholder: "",
        defaultValue: "",
        required: true,
        canChange: true,
      },
      {
        name: "bill_address",
        type: INPUT_TYPE.TEXT,
        label: "Bill Address",
        placeholder: "",
        defaultValue: "",
        required: true,
        canChange: true,
      },
    ],
  },
  service: {
    title: "Service",
    subtitle: "Define the service the client chooses",
    isDisplayTitle: true,
    type: "service",
    multipleValueName: "sub_services",
    field: [
      {
        name: "service_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "/master/services",
        label: "Service Category",
        placeholder: "Select Service Category",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        required: false,
        parent: true,
        autoFill: true,
        canChange: false,
      },
      {
        name: "sub_service_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "",
        label: "Sub Category Service",
        placeholder: "Select Sub Service",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        required: false,
        multiple: true,
        autoFill: true,
        canChange: false,
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "criteria_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "/master/criterias",
        label: "Criteria",
        placeholder: "Select criteria",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        required: false,
        multiple: true,
        autoFill: true,
        canChange: false,
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "subtotal",
        // name: "sub_total",
        type: INPUT_TYPE.TEXT,
        label: "Subtotal",
        placeholder: " ",
        defaultValue: "",
        required: false,
        errorMessage: "",
        multiple: true,
        autoFill: true,
        canChange: false,
        additionalProps: { showLable: false, disabled: true },
      },
      // field.additionalProps.showLable
      {
        name: "discount",
        type: INPUT_TYPE.TEXT,
        label: "Discount",
        placeholder: " ",
        defaultValue: "",
        errorMessage: "",
        required: false,
        multiple: true,
        autoFill: true,
        canChange: false,
        additionalProps: { showLable: false, disabled: true },
      },
      {
        name: "vat",
        type: INPUT_TYPE.CHECKBOX,
        label: "VAT",
        placeholder: "Izin usaha",
        defaultValue: "",
        required: false,
        multiple: true,
        // autoFill: true
      },
      {
        name: "description",
        type: INPUT_TYPE.TEXTAREA,
        label: "Description",
        placeholder: "Izin usaha",
        defaultValue: "",
        required: false,
        multiple: true,
        autoFill: true,
        canChange: false,
        additionalProps: {
          disabled: true,
        },
      },
      {
        name: "service_currency",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Currency",
        placeholder: "Rp",
        defaultValue: 1,
        required: true,
        autoFill: true,
        canChange: false,
        hidden: true,
        options: optionCurrency,
      },
    ],
  },
  attachment: {
    title: "Attachment",
    subtitle: "You can upload the documents required for this prosess ( Ops)",
    isDisplayTitle: true,
    type: "attachment",
    field: {
      name: "attachment",
      label: "Upload file",
      type: ANOTHER_INPUT_TYPE.FILE,
      required: false,
      autoFill: true,
      placeholder: "",
      canChange: false,
      disabled: true,
      tip: "Max size : 2 MB. Supported file : PNG, JPEG, PDF.",
    },
  },
  payment: {
    title: "Bank Transfer",
    subtitle: "",
    isDisplayTitle: true,
    type: "payment",
    field: [
      {
        name: "bank_id",
        label: "Name Of Bank",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "/master/banks",
        placeholder: "",
        required: true,
        defaultValue: "",
        reactive: "bank_address",
      },
      {
        name: "bank_address_id",
        label: "Account No",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        apiEndpoint: "/master/bank-address?bank_id=0",
        placeholder: "",
        required: true,
        defaultValue: "",
        errorMessage: "Please enter account number",
        options: [
          {
            id: 1,
            name: "[dummy]-123456789",
          },
          {
            id: 2,
            name: "[dummy]-987654321",
          },
        ],
      },
      {
        name: "account_name",
        label: "Account Name",
        type: INPUT_TYPE.TEXT,
        placeholder: "",
        required: true,
        defaultValue: "",
      },
      {
        name: "account_address",
        label: "Account Address",
        type: INPUT_TYPE.TEXT,
        placeholder: "",
        required: true,
        defaultValue: "",
      },
      {
        name: "bank_address",
        label: "Bank address",
        type: INPUT_TYPE.TEXT,
        placeholder: "",
        required: true,
        defaultValue: "",
        errorMessage: "Please enter bank address",
      },
      {
        name: "payment_currency",
        label: "Currency",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        placeholder: "",
        required: true,
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        options: optionCurrency,
      },
      {
        name: "payment_received",
        label: "Payment Received",
        type: INPUT_TYPE.TEXT,
        // Value of field reactive to other field
        reactive: "payment_currency",
        placeholder: "",
        required: true,
        hidden: true,
        defaultValue: "",
      },
      {
        name: "payment_received_convert",
        label: "Payment receive convert",
        type: INPUT_TYPE.TEXT,
        placeholder: "",
        required: false,
        hidden: false,
        defaultValue: "Rp. ",
      },
      {
        name: "note",
        label: "Note",
        type: INPUT_TYPE.TEXTAREA,
        placeholder: "Input description ( Ops)",
        required: false,
        defaultValue: "",
      },
    ],
  },
};

export const field_modal = [
  {
    name: "client_id",
    label: "Client Name",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    apiEndpoint: "/master/clients",
    withSearch: true,
    defaultValue: 0,
    required: true,
    errorMessage: "",
  },
  {
    name: "sales_order_id",
    label: "No. Sales Order",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    apiEndpoint: "/master/sales-orders",
    withSearch: true,
    defaultValue: 0,
    required: true,
    errorMessage: "",
  },
];

export const columns = [
  {
    field: "no_references",
    headerName: "No. Reference",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "code",
    headerName: "No. Proforma invoice",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "client_name",
    headerName: "Client Name",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "remark",
    headerName: "Remark",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "so_service",
    headerName: "Sub Category Service",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "so_sub_services",
    headerName: "Service Category",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: ({ value }) => value,
    renderCell: ({ value, row }) => {
      if (Array.isArray(value) && value.every((val) => val !== null)) {
        return value.map((val) => (
          <PaymentStatusBadge status="Paid">{val}</PaymentStatusBadge>
        ));
      }
      return "-";
    },
  },
  {
    field: "so_total_price",
    headerName: "Total Price",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  // {
  //   field: "payment_received_formatted",
  //   headerName: "Payment Received",
  //   width: 160,
  //   disableColumnMenu: true,
  //   sortable: true,
  // },
  // {
  //   field: "payment_conversion_received_formatted",
  //   headerName: "Payment Conversion Received",
  //   width: 160,
  //   disableColumnMenu: true,
  //   sortable: true,
  // },
  {
    field: "created_at_formatted",
    headerName: "Create At",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({ value, row }) => {
      if (value === "Received") {
        value = "Paid";
      }
      return <PaymentStatusBadge status={value}>{value}</PaymentStatusBadge>;
    },
  },
];

export const MODAL_STYLE = {
  DRAWER: "DRAWER",
  MODAL: "MODAL",
};

export const APPROVE_TYPE = {
  APPROVE: "Approve",
  RECEIVE_PAYMENT: "RECEIVE PAYMENT",
  PARTIAL: "PARTIAL",
};

export const LOCAL_ACTION_TYPE = {
  ADD_STEP_ONE: "ADD_STEP_ONE",
  ADD_STEP_TWO: "ADD_STEP_TWO",
};

/**
 *
 * @param {Function} calback
 */
export function fieldDrawerForEach(calback) {
  Object.keys(field_drawer).forEach((key) => {
    if (Array.isArray(field_drawer[key]?.field)) {
      field_drawer[key].field.forEach((value, index, array) =>
        calback(value, index, array, field_drawer[key])
      );
    }
  });
}

export function fieldDrawerArray() {
  const result = [];

  //* get every child field in field_drawer
  Object.keys(field_drawer).forEach((key) => {
    if (Array.isArray(field_drawer[key]?.field)) {
      field_drawer[key].field.forEach((fiel) => {
        result.push(fiel);
      });
    }
  });

  //* add attachment field;
  let attachmentField = field_drawer.attachment.field;
  result.push(attachmentField);

  return result;
}

//? Not used
export function fieldDrawerMap(callback) {
  const keys = Object.keys(field_drawer);
  for (let key of keys) {
    if (Array.isArray(field_drawer[key]?.field)) {
      return field_drawer[key].field.map(callback);
    }
  }
}

export const validateDisabled = (fieldName, actionType) => {
  const listAdd = [
    "service_id",
    "sub_service_id",
    "criteria_id",
    "sub_total",
    "discount",
    "description",
    "service_currency",
    "attachment",
    "remark",
  ];
  const listEdit = [...listAdd, "client_id"];
  const listApprove = [
    ...listAdd,
    "client_id",
    "sales_order_id",
    "bill_to",
    "attend",
    "bill_address",
  ];

  let isDisabled = false;
  switch (actionType) {
    case ACTION_TYPE.ADD:
    case LOCAL_ACTION_TYPE.ADD_STEP_TWO:
      isDisabled = listAdd.includes(fieldName);
      break;
    case ACTION_TYPE.EDITED:
      isDisabled = listEdit.includes(fieldName);
      break;
    case APPROVE_TYPE.APPROVE:
    case APPROVE_TYPE.PARTIAL:
    case APPROVE_TYPE.RECEIVE_PAYMENT:
      isDisabled = listApprove.includes(fieldName);
      break;
    default:
      isDisabled = false;
  }
  // console.log({ isDisabled, fieldName, actionType });
  return isDisabled;
};
