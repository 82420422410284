"use strict";

import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import "dayjs/locale/en";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles, useTheme } from "@mui/styles";
import axios, { headersAPIToken } from "../../../../../core/axios_config";
import Form, { FilePicker, SelectOneAutocomplete } from "../../../../../components/Form";
import {
  fieldDrawerForEach,
  field_drawer,
  field_modal,
} from "../../../proforma-invoice/parts/imutable_state";
import ModalConfirmCancel from "../../../../../components/ConfirmCancel";
import LeftDrawer from "../../../../../components/LeftDrawer";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
  removeButton,
} from "../../../../../util/style";
import RemoveIcon from "../../../../../assets/minus.svg";
import { formatCurrency, INPUT_TYPE, SELECT_OPTION } from "../../../../../util/function";
import ComponentFormRender from "./ComponentFormRender";
import ServiceFormComponent from "../../../proforma-invoice/components/form-content/ServiceFormComponent";
import { useParams } from "react-router-dom";
import { SET_KEY_OPTION } from "../../clients/state";
import { editInvoice, getDetailInvoice } from "../../clients/actions";
import { useRef } from "react";
import useOptions from "../../hooks/useOptions";

/**@typedef {import("react").BaseSyntheticEvent} EventType */
/**@typedef {import("../../../proforma-invoice/clients/classType").FieldType} FieldType */
/**@typedef {import("../../../proforma-invoice/clients/classType").ParentFieldType} ParentFieldType */

export default function ApprovalForm({ show, toggleShow }) {
  //@ts-ignore
  const { token } = useSelector((state) => state.auth);

  //@ts-ignore
  const dispatch = useDispatch();

  const INVOICE_STATUS = {
    PENDING: "Pending",
    APPROVED: "Approved",
    PARTIAL: "Partial",
    PAID: "Paid",
  };

  const { id } = useParams();

  const [showDrawer, setShowDrawer] = useState(show);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formTitle, setFormTitle] = useState("Pay Invoice");
  const [isLoadedFormValues, setIsLoadedFormValue] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [canConvert, setCanConvert] = useState(false);
  const [selectCurency, setSelectCurency] = useState(false);
  const [priceses, setPriceses] = useState({});
  const [statusInvoice, setStatusInvoice] = useState("");
  const [errorGetDetail, setErrorGetDetail] = useState(null);
  //* ======================================================= */
  //*                       LOCAL REDUCER                     */
  //* ======================================================= */
  const initialState = {
    client_id: 0,
    bank_id: 0,
    canConvert: false,
    showPaymentConvert: false,
    sales_order_id: 0,
  };

  const KEY_STATE = {
    SET_CLIENT_ID: "CLIENT ID",
    SET_BANK_ID: "BANK ID",
    SET_SO_ID: "SET SO ID",
    SET_BOTH: "SET BOTH STATE",
    SET_CAN_CONVERT: "SET CAN CONVERT",
    SET_SHOW_PAYMENT_CONVERT: "SET SELECTED CURRENCY",
    SET_BOTH_STATE: "SET CAN CONVERT AND SELECTED CURRENCY",
  };

  function localReducer(state, { type, payload }) {
    switch (type) {
      case KEY_STATE.SET_BANK_ID:
        return {
          ...state,
          bank_id: payload,
        };
      case KEY_STATE.SET_CLIENT_ID:
        return {
          ...state,
          client_id: payload,
        };
      case KEY_STATE.SET_BOTH:
        return {
          ...state,
          client_id: payload.client_id,
          bank_id: payload.bank_id,
        };
      case KEY_STATE.SET_BOTH_STATE:
        return {
          ...state,
          canConvert: payload,
          showPaymentConvert: payload,
        };
      case KEY_STATE.SET_CAN_CONVERT:
        return {
          ...state,
          canConvert: payload,
        };
      case KEY_STATE.SET_SHOW_PAYMENT_CONVERT:
        return {
          ...state,
          showPaymentConvert: payload,
        };
      case KEY_STATE.SET_SO_ID:
        return {
          ...state,
          sales_order_id: payload,
        };
      default:
        return state;
    }
  }

  const [formState, stateDispacth] = useReducer(localReducer, initialState);

  useEffect(() => {
    if (id) {
      setSelectedId(parseInt(id));
      setPayloadForm(id);
    }
  }, [id]);

  const setPayloadForm = async (/** @type {string} */ id) => {
    try {
      setIsLoadedFormValue(false);
      const documentForEdit = await fetchData("invoices/".concat(id, "/edit"));
      let salesOrderEdit = null;

      if (documentForEdit) {
        // console.log({ documentForEdit });
        salesOrderEdit = await fetchData(
          "/sales-orders/".concat(documentForEdit?.sales_order_id, "/detail-for-edit")
        );
      }

      if (documentForEdit && salesOrderEdit) {
        setErrorGetDetail(null);
        const newFormValues = Object.assign({}, initialFormValues());
        const newPricesesValues = {};
        let sameKey = [
          "no_reference",
          "client_id",
          "sales_order_id",
          "bill_to",
          "attend",
          "bill_address",
          "note",
          "id",
          [
            "account_name",
            "account_address",
            "bank_id",
            "bank_address_id",
            "bank_address",
            "payment_received",
            "payment_received_convert",
          ],
        ];

        stateDispacth({
          type: KEY_STATE.SET_SO_ID,
          payload: salesOrderEdit?.id,
        });

        const fillForm = (initialValue, key, isBank) => {
          if (Array.isArray(key)) {
            key.forEach((item) => {
              fillForm(initialValue, item, true);
            });
          } else {
            initialValue[key] = isBank
              ? documentForEdit["bank"][key]
              : documentForEdit[key];
          }
        };

        sameKey.forEach((item) => {
          fillForm(newFormValues, item);
        });

        Object.entries(salesOrderEdit["Price Information"]).forEach(([key, value]) => {
          const formatedCurrncy = String(salesOrderEdit["Service"]["currency"]).concat(
            ". ",
            formatToCurrency(value)
          );
          newPricesesValues[key] =
            key !== "price_services_info" ? formatedCurrncy : value;
        });

        newFormValues["payment_currency"] = documentForEdit["bank"]["currency"];
        newFormValues["service_currency"] = salesOrderEdit["Service"]["currency"];
        newFormValues["service_id"] = salesOrderEdit["Service"]["service_id"];
        newFormValues["remark"] = salesOrderEdit["Note Section"]["remarks"];
        newFormValues["attachment"] = salesOrderEdit["Attachment"]["attachment"];
        if (
          Array.isArray(
            salesOrderEdit["Sub Service"] && salesOrderEdit["Sub Service"].length
          )
        ) {
          newFormValues["sub_services"] = salesOrderEdit["Sub Service"].map((item) =>
            manipulateSubServicePayload(
              item,
              String(salesOrderEdit["Service"]["currency"])
            )
          );
        } else {
          newFormValues["sub_services"] = [];
        }

        stateDispacth({ type: KEY_STATE.SET_CAN_CONVERT, payload: true });
        if (
          documentForEdit["bank"]["payment_received_convert"] !== null &&
          documentForEdit["bank"]["payment_received_convert"] != 0
        ) {
          newFormValues["payment_received_convert"] = "Rp. ".concat(
            formatToCurrency(documentForEdit["bank"]["payment_received_convert"])
          );
          stateDispacth({
            type: KEY_STATE.SET_SHOW_PAYMENT_CONVERT,
            payload: true,
          });
          newFormValues["convert_payment"] = true;
        } else {
          stateDispacth({
            type: KEY_STATE.SET_SHOW_PAYMENT_CONVERT,
            payload: false,
          });
          newFormValues["convert_payment"] = false;
        }

        if (documentForEdit["payment_received"]) {
          const formatedPaymentReceive = String(newFormValues["payment_currency"]).concat(
            ". ",
            formatToCurrency(documentForEdit["bank"]["payment_received"])
          );
          newFormValues["payment_received"] = formatedPaymentReceive;
        }

        stateDispacth({
          type: KEY_STATE.SET_SHOW_PAYMENT_CONVERT,
          payload: true,
        });
        stateDispacth({
          type: KEY_STATE.SET_BOTH,
          payload: {
            client_id: newFormValues["client_id"],
            bank_id: newFormValues["bank_id"],
          },
        });
        setStatusInvoice(validateStatusInvoice(documentForEdit["status"]));

        // console.error("ini fetch set payload");
        // getOption(
        //   "/master/bank-address?bank_id=".concat(newFormValues["bank_id"]),
        //   "bank_address_id",
        //   "no_account"
        // );
        setPriceses(newPricesesValues);
        setFormValues(newFormValues);
        setIsLoadedFormValue(true);
      }
    } catch (error) {
      console.log(error);
      setErrorGetDetail(error);
      setIsLoadedFormValue(true);
      return;
    }
  };

  const fetchData = async (endpoint) => {
    try {
      const response = await axios.get(endpoint, headersAPIToken(token));
      if (response.status === 200 && response.data.status === true) {
        return response.data.data;
      }
      return null;
    } catch (error) {
      // console.error(`Failed get detail data: ${error}`);
      throw error;
    }
  };

  const initialAttachment = [""];
  const initialFormValues = () => {
    let newFormValue = {};
    fieldDrawerForEach(
      (
        /**@type {FieldType} */ field,
        index,
        arrr,
        /**@type {ParentFieldType} */ parentField
      ) => {
        if (field.multiple && parentField.title === "Service") {
          newFormValue[parentField.multipleValueName] = initialSubserviceField("form");
        } else if (!field.multiple && !field.hidden) {
          newFormValue[field.name] = field.defaultValue;
        }
      }
    );
    newFormValue[field_drawer.attachment.field.name] = initialAttachment;
    return newFormValue;
  };

  const initialErrors = () => {
    const newErrors = {};
    fieldDrawerForEach(
      (
        /**@type {FieldType} */ field,
        index,
        arr,
        /**@type {ParentFieldType} */ parentField
      ) => {
        if (field.multiple && parentField.title === "Service") {
          newErrors[field.name] = initialSubserviceField("error");
        } else {
          newErrors[field.name] = "";
        }
      }
    );
    newErrors[field_drawer.attachment.field.name] = initialAttachment;
    return newErrors;
  };

  /**
   *
   * @param {'form'|'error'} type
   */
  const initialSubserviceField = (type) => {
    const result = {};
    fieldDrawerForEach((/**@type {FieldType} */ field) => {
      if (field.multiple) {
        result[field.name] =
          type === "form" ? field.defaultValue : type === "error" && "";
      }
    });
    return [result];
  };

  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());
  const [options, setOptions] = useState({});

  const validateStatusInvoice = (status) => {
    let result = null;
    Object.values(INVOICE_STATUS).forEach((value) => {
      if (value === status) {
        result = value;
      }
    });
    return result !== null ? result : "";
  };

  const manipulateSubServicePayload = (subServices, currency) => {
    if (Array.isArray(subServices)) return subServices;
    const newPayload = {
      sub_service_id: subServices["sub_service_id"],
      criteria_id: subServices["criteria_id"],
      sub_total: subServices["total_to_rp"],
      discount: subServices["discount"],
      vat: subServices["vat_to_rp"],
      description: subServices["desc"],
    };

    if (Array.isArray(subServices["vat_percent"])) {
      newPayload["vat_percent"] = subServices["vat_percent"][0];
    } else {
      newPayload["vat_percent"] = subServices["vat_percent"];
    }
    // console.log("Termanipulate", { newPayload });
    ["vat", "sub_total", "discount"].forEach((key) => {
      newPayload[key] = String(currency).concat(". ", formatToCurrency(newPayload[key]));
    });
    return newPayload;
  };

  // useEffect(() => {
  //   /**
  //    *
  //    * @param {string} apiEndpoint
  //    * @param {string} name
  //    * @param {string} [aliasKey]
  //    */
  //   const fetchOpion = async (apiEndpoint, name, aliasKey) => {
  //     try {
  //       const response = await axios.get(apiEndpoint, headersAPIToken(token));
  //       if (response.status === 200 && response.data.status === true) {
  //         let payloadOptions = response.data.data;

  //         if (aliasKey) {
  //           payloadOptions = payloadOptions.map((item) => {
  //             item["name"] = item[aliasKey];
  //             return item;
  //           });
  //         }

  //         if (payloadOptions.length < 0 && name === "bank_address_id") {
  //           payloadOptions = [
  //             {
  //               id: 1,
  //               name: "[dummy]-123456789",
  //             },
  //             {
  //               id: 2,
  //               name: "[dummy]-987654321",
  //             },
  //           ];
  //         }

  //         console.log({ payloadOptions, name });
  //         setOptions((prev) => ({ ...prev, [name]: payloadOptions }));
  //       }
  //     } catch (error) {
  //       console.error(`Failed get option ${name} :`, error);
  //     }
  //   };

  //   if (formState.bank_id) {
  //     console.warn("Form state watch");
  //     fetchOpion(
  //       "master/bank-address?bank_id=".concat(formState.bank_id),
  //       "bank_address_id",
  //       "no_account"
  //     );
  //   }
  // }, [formState]);

  // useEffect(() => {
  //   const getOption = async (endpoint, name, alias) => {
  //     try {
  //       const response = await axios.get(endpoint, headersAPIToken(token));
  //       if (response.status === 200 && response.data.status == true) {
  //         let payloadOpt = response.data.data;

  //         if (alias) {
  //           payloadOpt = payloadOpt.map((opt) => {
  //             opt["name"] = opt[alias];
  //             return opt;
  //           });
  //         }
  //         if (name === "bank_address_id" && payloadOpt.length < 1) {
  //           payloadOpt = [
  //             {
  //               id: 1,
  //               name: "[dummy]-123456789",
  //             },
  //             {
  //               id: 2,
  //               name: "[dummy]-987654321",
  //             },
  //           ];
  //         }
  //         setOptions((prev) => ({ ...prev, [name]: payloadOpt }));
  //       }
  //     } catch (error) {
  //       console.error(`Failed get option for : ${name}`, error);
  //     }
  //   };

  //   const bankId = formValues["bank_id"];
  //   if (bankId && bankId !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE) {
  //     getOption(
  //       "/master/bank-address?bank_id=".concat(bankId),
  //       "bank_address_id",
  //       "no_account"
  //     );
  //   }
  // }, [formValues]);

  const getOption = (endpoint, name, alias) => {
    setIsLoadedFormValue(false);
    axios
      .get(endpoint, headersAPIToken(token))
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          let payloadOpt = response.data.data;
          if (alias) {
            payloadOpt = payloadOpt.map((opt) => {
              opt["name"] = opt[alias];
              return opt;
            });

            if (name === "bank_address_id" && payloadOpt.length < 1) {
              payloadOpt = [
                {
                  id: 1,
                  name: "[dummy]-123456789",
                },
                {
                  id: 2,
                  name: "[dummy]-987654321",
                },
              ];
            }
          }
          setOptions((prev) => ({ ...prev, [name]: payloadOpt }));
        }
      })
      .catch((err) => {
        console.error(`Failed get option for ${name}: `, err);
      })
      .finally(() => {
        setIsLoadedFormValue(true);
      });
  };

  const createDataEndpoint = () => {
    /**
     *  @typedef {object} OptionData;
     * @property {string} endpoint
     * @property {string} name
     */
    /**@type {OptionData[]} */
    let listData = [];
    const listExclude = ["bank_address_id", "sales_order_id"];
    fieldDrawerForEach((/** @type {FieldType} */ field) => {
      if (field.apiEndpoint && !listExclude.includes(field.name)) {
        listData.push({ name: field.name, endpoint: field.apiEndpoint });
      }
    });

    return listData;
  };

  const [listEndpoint, setListEndpoint] = useState(createDataEndpoint());
  const [canGet, setCanGet] = useState(true);
  const [listOptions, doneGetOptions, errorGetOption] = useOptions(canGet, listEndpoint);

  useEffect(() => {
    const fetchOpt = async (endpoint, name, aliasName) => {
      axios
        .get(endpoint, headersAPIToken(token))
        .then((response) => {
          if (response.status === 200 && response.data.status === true) {
            let payloadData = response.data.data;
            if (aliasName) {
              payloadData = payloadData.map((item) => {
                item["name"] = item[aliasName];
                return item;
              });
            }

            console.log("bedore", { payloadData });
            if (name === "bank_address_id") {
              console.log("Masuk sini");
              payloadData = [
                {
                  id: 1,
                  name: "[dummy]-123456789",
                },
                {
                  id: 2,
                  name: "[dummy]-987654321",
                },
              ];
            }
            console.log({ payloadData, name });
            setOptions((prev) => ({ ...prev, [name]: payloadData }));
          } else {
            setOptions((prev) => ({ ...prev, [name]: [] }));
          }
        })
        .catch((error) => {
          console.error(`Failed get options ${name}: ${error.message}`);
        })
        .finally(() => {});
    };

    if (formState.client_id && !options.hasOwnProperty("sales_order_id")) {
      fetchOpt(
        //@ts-ignore
        "/master/sales-orders?client_id=".concat(formState.client_id),
        "sales_order_id",
        "code"
      );
    }

    if (formState.bank_id) {
      // console.log("re fetch");
      console.warn("Dalam watgh");
      fetchOpt(
        //@ts-ignore
        "master/bank-address?bank_id=".concat(formState.bank_id),
        "bank_address_id",
        "no_account"
      );
    }
    // console.log({ formState });
  }, [formState]);

  useEffect(() => {
    if (doneGetOptions) {
      // console.log({ listOptions });
      setOptions((prev) => ({ ...prev, ...listOptions }));
      setCanGet(false);
    }
  }, [listOptions, doneGetOptions]);

  useEffect(() => {
    if (statusInvoice === INVOICE_STATUS.PENDING) {
      setFormTitle("Approve Invoice");
    } else if (statusInvoice === INVOICE_STATUS.APPROVED) {
      setFormTitle("Receive Payment Invoice");
    }
  }, [statusInvoice]);

  //* ==================================================================== *//
  //*                               FORM                                   *//
  //* ==================================================================== *//

  const resetFormValue = () => {
    setFormValues(initialFormValues());
    setErrors(initialErrors());
  };

  const validateField = (/** @type {string} */ fieldName) => {
    /**@type {FieldType|undefined} */
    let field = undefined;
    fieldDrawerForEach((/**@type {FieldType} */ fie) => {
      if (fie.name === fieldName) field = fie;
    });
    const copyError = { ...errors };

    if (/** @type {FieldType} */ field) {
      const { required, name, label, errorMessage, validation, defaultValue } = field;
      const valueInForm = formValues[name];
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === undefined ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        //@ts-ignore
        copyError[name] = `Please enter ${String(label).toLowerCase()}`;
      }

      //@ts-ignore
      if (valueInForm && validation && !validation(valueInForm)) {
        copyError[name] = errorMessage || `${label} is invalid`;
      } else {
        //@ts-ignore
        copyError[name] = "";
      }
    }
    setErrors(copyError);
  };

  const validateForm = () => {
    const copyError = { ...errors };
    let valid = true;
    fieldDrawerForEach((/**@type {FieldType} */ field, index) => {
      const valueInForm = formValues[field.name];
      let messageError = "";
      const { name, label, required, defaultValue, validation, errorMessage } = field;
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === defaultValue ||
          valueInForm === undefined ||
          valueInForm === null ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        // copyError[name] = `Please enter ${String(label).toLowerCase()}`;
        messageError = `Please enter ${String(label).toLowerCase()}`;
        valid = false;
      }

      if (valueInForm && valueInForm.length && validation && !validation(valueInForm)) {
        copyError[name] = errorMessage || `${label} is invalid`;
        valid = false;
      } else {
        copyError[name] = "";
      }

      if (messageError) {
        copyError[name] = messageError;
      }
      console.log({ name, required, valueInForm, valid, messageError });
    });
    // console.log("Done", copyError);
    setErrors(copyError);
    return valid;
  };

  const formatToCurrency = (value) => {
    value = String(value);
    value = value.replace(/^0+(?=\d{0,})/, "0");

    // Use a regular expression to allow only digits
    value = value.replace(/\D/g, "");

    // Format the input value as a currency by adding commas every three digits
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return value;
  };
  const formatToNumber = (value) => {
    value = String(value);
    value = value.replace(/\D/g, "");
    // console.log("dalam format", value);
    return parseInt(value);
  };
  //* ==================================================================== *//
  //*                              HANDLER                                 *//
  //* ==================================================================== *//

  const handleSubmitForm = (/**@type {EventType} */ e) => {
    e.preventDefault();
    if (validateForm()) {
      // const validForm = "";
      // debugger;
      handleUpdateApproval();
    } else {
      console.log("Invalid form");
    }
  };

  const manipulatePayload = () => {
    // key = formValues, value = key payload;
    const keyValuesForm = {
      no_reference: "no_reference",
      client_id: "client_id",
      sales_order_id: "sales_order_id",
      bill_to: "bill_to",
      attend: "attend",
      bill_address: "bill_address",
      invoice_proforma_id: "invoice_proforma_id",
      banks: {
        account_name: "account_name",
        account_address: "account_address",
        bank_address: "bank_address",
        payment_currency: "currency",
        bank_id: "bank_id",
        bank_address_id: "bank_address_id",
        payment_received: "payment_received",
        currency_convert: "currency_convert",
        payment_received_convert: "payment_received_convert",
      },
    };

    // const keyCurr = ["payment_received", "payment_received_convert"];
    const fillValue = (curentForm, field) => {
      let resultPayload = {};
      Object.entries(field).forEach(([key, value]) => {
        if (typeof value === "object") {
          let banks = fillValue(curentForm, value);
          resultPayload = Object.assign(resultPayload, { [key]: banks });
        } else if (curentForm[key]) {
          let valuePayload = curentForm[key];
          // if (keyCurr.includes(key)) {
          //   valuePayload = formatToNumber(valuePayload);
          //   console.log({ valuePayload, key });
          // }
          resultPayload[value] = valuePayload;
        }
      });
      // console.log({ result: resultPayload });
      return resultPayload;
    };

    const copyForm = { ...formValues };

    const result = fillValue(copyForm, keyValuesForm);

    result["banks"]["payment_received"] = formatToNumber(
      result["banks"]["payment_received"]
    );
    if (result["currency"] !== "Rp") {
      result["banks"]["currency_convert"] = "Rp";
      result["banks"]["payment_received_convert"] = formatToNumber(
        formValues["payment_received_convert"]
      );
    } else {
      result["banks"]["currency_convert"] = "";
      result["banks"]["payment_received_convert"] = "";
    }

    /**------------------------- [status]---------------------- */
    const subTotal = formatToNumber(priceses["total_price_info_to_rp"]);
    let paymentAmount = 0;
    if (result["banks"]["payment_received_convert"]) {
      paymentAmount = formatToNumber(result["banks"]["payment_received_convert"]);
    } else {
      paymentAmount = formatToNumber(result["banks"]["payment_received"]);
    }

    /**Validate status */
    if (paymentAmount < subTotal) {
      result["status"] = "Partial";
    } else if (paymentAmount >= subTotal) {
      result["status"] = "Paid";
    }
    return result;
  };

  const handleUpdateApproval = () => {
    const manipulated = manipulatePayload();
    // if (statusInvoice === INVOICE_STATUS.PENDING) {
    //   manipulated["status"] = "Approved";
    // } else if (statusInvoice === INVOICE_STATUS.APPROVED) {
    //   console.log("masuk paid");

    // }
    dispatch(editInvoice(token, manipulated, selectedId)).then(() => {
      dispatch(getDetailInvoice(token, selectedId));
      toggleShow(false);
    });
  };

  const handleInputChange = (/**@type {EventType} */ e) => {
    const { name, checked } = e.target;
    let { value } = e.target;

    //* Validate when selected currency is not Rp. show payment receive convert
    if (name === "convert_payment") {
      setFormValues((prev) => {
        prev["convert_payment"] = checked;
        if (checked) {
          prev["payment_received_convert"] = "Rp. ";
        } else {
          prev["payment_received_convert"] = null;
        }
        return prev;
      });
      // return;
      value = checked;
    }

    if (name === "payment_currency") {
      if (value !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE) {
        if (value) {
          stateDispacth({
            type: KEY_STATE.SET_SHOW_PAYMENT_CONVERT,
            payload: true,
          });
          setFormValues((prev) => {
            prev["payment_received"] = value.concat(
              ". ",
              formatToCurrency(prev["payment_received"])
            );
            if (value === "Rp") {
              prev["payment_receive_convert"] = null;
            }
            // prev["convert_payment"] = false;
            return prev;
          });
        } else {
          stateDispacth({
            type: KEY_STATE.SET_SHOW_PAYMENT_CONVERT,
            payload: false,
          });
        }
      }
    }

    if (name === "payment_received") {
      if (value && !formValues["payment_currency"]) {
        value = formatToCurrency(value);
      } else if (formValues["payment_currency"]) {
        value = String(formValues["payment_currency"]).concat(
          ". ",
          formatToCurrency(value)
        );
      }
    }

    if (name === "bank_id" && value !== undefined) {
      getOption(
        "/master/bank-address?bank_id=".concat(value),
        "bank_address_id",
        "no_account"
      );
      // stateDispacth({ type: KEY_STATE.SET_BANK_ID, payload: value });
      // setOptions((prev) => ({
      //   ...prev,
      //   bank_address_id: [],
      // }));
      setFormValues((prev) => ({
        ...prev,
        bank_address_id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      }));
    }

    if (name === "payment_received_convert") {
      value = "Rp. ".concat(formatToCurrency(value));
    }

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputBlur = (/**@type {EventType} */ e, parentField) => {
    validateField(e.target.name);
  };

  const handleRemoveSubService = (fieldName, index) => {};

  const handleAddSubService = (fieldName) => {};

  const handleFileSelect = () => {};
  const handleCloseModal = (/**@type {EventType} */ e) => {
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };
  const handleYesConfirmModal = () => {
    setConfirmModal(false);
    setShowDrawer(false);
    toggleShow(false);
  };

  //* ==================================================================== *//
  //*                              COMPONENT                               *//
  //* ==================================================================== *//

  // const dummySubService = {
  //   [field_drawer.service.multipleValueName]: [
  //     {
  //       sub_service_id: 2,
  //       criteria_id: 3,
  //       sub_total: "12.000",
  //       discount: "12000",
  //       vat: "5000",
  //       description: "Bayar Mahkota",
  //     },
  //   ],
  // };

  const RenderInputTextDisabled = ({ field }) => {
    let valueRender = formValues[field.name];
    if (field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
      const fieldValue = options[field.name]?.find((opt) => opt.id === valueRender);
      valueRender = fieldValue?.name;
    }
    let rightAlignField = null;
    if (field.name === "sub_total") {
      valueRender = new String("Rp ").concat(valueRender);
      rightAlignField = true;
    } else if (field.name === "discount") {
      valueRender = valueRender.padEnd(10, " ").concat("%");
      rightAlignField = true;
    }
    return rightAlignField ? (
      <Grid container style={{ paddingBlock: ".6rem" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Typography> {field.label}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "right" }}>
          <input
            disabled
            type="text"
            placeholder=""
            value={valueRender}
            style={{
              width: field.name === "discount" ? "40%" : "70%",
              paddingBlock: ".6rem",
              paddingInline: ".5rem",
            }}
          />
        </Grid>
      </Grid>
    ) : (
      <div style={{ padding: 0, margin: 0, paddingRight: ".55rem" }}>
        <p style={{ paddingBlock: ".4rem", marginBlock: "0", fontWeight: "500" }}>
          {field.label}
        </p>
        {field.name === "description" ? (
          <textarea
            readOnly
            disabled
            style={{ width: "100%", paddingBlock: ".6rem" }}
            value={valueRender}
          ></textarea>
        ) : (
          <input
            type="text"
            placeholder={field.placeholder}
            disabled
            style={{
              width: "100%",
              paddingBlock: ".6rem",
              paddingInline: ".5rem",
            }}
            value={valueRender}
          />
        )}
      </div>
    );
  };

  const ServiceFormRender = ({ field }) => {
    const currencyField = field_drawer.service?.field.find(
      (fiel) => fiel.name === "service_currency"
    );
    let optionData = options[field.name] || [];
    let requireOption = null;
    if (field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && field.require) {
      requireOption = {
        id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        name: "Choose Option",
      };
    }
    optionData = requireOption ? [...optionData, requireOption] : optionData;
    if (!field.additionalProps) {
      field.additionalProps = {};
    }
    field.additionalProps.disabled = true;
    return (
      <React.Fragment key={field.name}>
        <Grid container>
          {field.parent ? (
            <>
              <Grid item xs={9.5} paddingRight={".8rem"}>
                {/* <RenderInputTextDisabled field={field} /> */}
                <SelectOneAutocomplete
                  key={"seervice category"}
                  errors={errors}
                  field={field}
                  fieldOptions={optionData}
                  formValues={formValues}
                  handleInputBlur={(e) => handleInputBlur(e, field_drawer.service)}
                  handleInputChange={handleInputChange}
                  tip={field.tip}
                />
              </Grid>
              <Grid item xs={2.5}>
                {/* <RenderInputTextDisabled field={currencyField} /> */}
                <SelectOneAutocomplete
                  key={"Curreny service"}
                  errors={errors}
                  field={currencyField}
                  fieldOptions={currencyField?.options}
                  formValues={formValues}
                  handleInputBlur={(e) => handleInputBlur(e, field_drawer.service)}
                  handleInputChange={handleInputChange}
                  tip={currencyField?.tip}
                />
              </Grid>
            </>
          ) : (
            <>
              {formValues[field_drawer.service.multipleValueName]?.map((_, index) => (
                <>
                  <Grid item xs={10.75}>
                    <ComponentFormRender
                      parentField={field_drawer.service}
                      field={field}
                      options={options}
                      errors={errors}
                      formValues={
                        formValues[field_drawer.service.multipleValueName][index]
                      }
                      handleChange={handleInputChange}
                      handleBlur={(e) => handleInputBlur(e, field_drawer.service)}
                      canConvert={false}
                      showPaymentConvert={false}
                      key={index}
                    />
                  </Grid>
                  {field.name === "sub_service_id" && (
                    <Grid item xs={1.25} sx={{ marginTop: "1.8rem" }}>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          backgroundColor: (theme) => theme.palette.primary.main,
                          ...removeButton,
                        }}
                        onClick={() => handleRemoveSubService(field.name, index)}
                        disabled={
                          formValues[field_drawer.service?.multipleValueName]?.length ===
                          1
                        }
                      >
                        <img src={RemoveIcon} alt={`Remove ${field.name}`} />
                      </Button>
                    </Grid>
                  )}
                  {field.name === "description" && false && (
                    <Grid xs={12}>
                      <Box sx={{ ...justifyContentEnd, display: "flex" }}>
                        <Button
                          onClick={() => handleAddSubService(field.name)}
                          disabled={true}
                        >
                          <span
                            style={{
                              fontSize: "1.5rem",
                              marginRight: ".6rem",
                              display: "inline-block",
                            }}
                          >
                            +
                          </span>
                          Add More
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </>
              ))}
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  const RenderCostText = ({ label, value, varianLabel }) => (
    <React.Fragment>
      <Grid container marginY=".5rem">
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={varianLabel === "bold" ? 700 : 300}>
            {/* TODO: Make it dynamic depend on service choosed */}
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={"700"} textAlign={"right"}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  // const dummyFile = {
  //   id: 39,
  //   url: "https://storage.googleapis.com/ppm_storage/payment_request/1707960840_example_image.png",
  //   filename: "1707960840_example_image.png",
  //   size: 69822,
  //   created_at: "2024-02-15T01:34:00.000000Z",
  //   updated_at: "2024-02-15T01:34:00.000000Z",
  // };

  const checkDisabledBankSection = (fieldName) => {
    const listDisabled = [
      "bank_id",
      "bank_address_id",
      "account_name",
      "account_address",
      "bank_address",
    ];

    return listDisabled.includes(fieldName);
  };

  const renderFormElement = () => {
    return !isLoadedFormValues ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FacebookCircularProgress />
      </Box>
    ) : errorGetDetail !== null ? (
      <Box component={"div"} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="body2" sx={{ fontSize: "1.1rem" }}>
          Failed Get Data
        </Typography>
      </Box>
    ) : (
      <React.Fragment>
        {/* ===================== Client Section =======================*/}
        {field_drawer.client.field.map((field) => (
          <ComponentFormRender
            errors={errors}
            field={field}
            formValues={formValues}
            handleBlur={handleInputBlur}
            handleChange={handleInputChange}
            options={options}
            parentField={field_drawer.client}
            key={field.name}
            canConvert={false}
            showPaymentConvert={false}
            disabled={true}
          />
        ))}
        <Box marginY={".5rem"}>
          <Divider />
        </Box>
        {/* ================== SERVICE SECTION ====================*/}
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {field_drawer.service.title}
          </Typography>
          {field_drawer.service.isDisplayTitle && (
            <Typography variant="subtitle2">{field_drawer.service.subtitle}</Typography>
          )}
        </Box>
        {field_drawer.service.field.map((field) => (
          <ServiceFormRender field={field} />
        ))}

        {/* =========================== ATTACHMENT SECTION ========================= */}
        <React.Fragment>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {field_drawer.attachment.title}
            </Typography>
            {field_drawer.attachment.subtitle && (
              <Typography variant="subtitle2">
                {field_drawer.attachment.subtitle}
              </Typography>
            )}
          </Box>
          {formValues[field_drawer.attachment.field.name].map((_, indexValue) => (
            <Grid container>
              <Grid item xs={10.5}>
                <FilePicker
                  key={indexValue}
                  errors={errors}
                  field={field_drawer.attachment.field}
                  formValues={formValues[field_drawer.attachment.field.name][indexValue]}
                  handleInputChange={handleInputChange}
                  indexFile={indexValue}
                  onFileSelect={handleFileSelect}
                  tip={field_drawer.attachment.field.tip}
                />
              </Grid>
              <Grid
                item
                xs={1.5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    ...removeButton,
                  }}
                  onClick={() => {}}
                  disabled={true}
                >
                  <img src={RemoveIcon} alt={`Remove File`} />
                </Button>
              </Grid>
            </Grid>
          ))}
          <Box marginY={".5rem"}>
            <Divider />
          </Box>
          <RenderCostText
            varianLabel={"bold"}
            label={priceses["price_services_info"]}
            value={priceses["price_services_info_to_rp"]}
          />
          <RenderCostText
            varianLabel={"bold"}
            label={"Discount"}
            value={`(${priceses["discount_info_to_rp"]})`}
          />
          <Box marginY={".5rem"}>
            <Divider />
          </Box>
          <RenderCostText
            varianLabel={"bold"}
            label={"VAT"}
            value={priceses["vat_info_to_rp"]}
          />
          <RenderCostText
            varianLabel={"bold"}
            label={"Total Price"}
            value={priceses["total_price_info_to_rp"]}
          />
          <Box marginY={".5rem"}>
            <Divider />
          </Box>
        </React.Fragment>
        {/* =========================== BANK TRANSFER SECTION ========================= */}
        <Box marginBottom={".2rem"} marginY=".5rem">
          <Typography variant="h6" fontWeight={800}>
            {field_drawer.payment.title}
          </Typography>
        </Box>
        {field_drawer.payment.field.map((field) => (
          <ComponentFormRender
            field={field}
            errors={errors}
            formValues={formValues}
            options={options}
            handleChange={handleInputChange}
            handleBlur={handleInputBlur}
            parentField={field_drawer.payment}
            key={field.name}
            canConvert={formState.canConvert}
            showPaymentConvert={formState.showPaymentConvert}
            disabled={checkDisabledBankSection(field.name)}
          />
        ))}
      </React.Fragment>
    );
  };
  const renderActionButton = () => {
    if (isLoadedFormValues === false) return null;

    if (errorGetDetail !== null) {
      return (
        <Box>
          <Button
            color={"primary"}
            variant="contained"
            disableElevation
            onClick={() => setPayloadForm(String(selectedId))}
          >
            Try Again
          </Button>
        </Box>
      );
    }

    return (
      <Box>
        <Button variant="outlined" sx={{ ...btnWhite }} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color={"primary"} variant="contained" disableElevation type="submit">
          Pay
        </Button>
      </Box>
    );
  };

  //* ==================================================================== *//
  //*                              DEBUGGER                                *//
  //* ==================================================================== *//

  // useEffect(() => {
  //   console.info(options, "options ========");
  // }, [options]);

  useEffect(() => {
    console.info(errors, "Errors -------------------");
  }, [errors]);

  useEffect(() => {
    // console.warn("ini form values");
    console.info(formValues, "Form values +++++++++++++++++");
  }, [formValues]);

  // useEffect(() => {
  //   // console.warn("ini state");
  //   console.log(formState);
  // }, [formState]);

  return (
    <>
      <LeftDrawer open={showDrawer}>
        <Box>
          <Form
            workingWithDrawer={true}
            title={formTitle}
            onSubmit={handleSubmitForm}
            onCloseModal={handleCloseModal}
            elementForm={renderFormElement}
            elementActionButton={renderActionButton}
            elementOtherActionButton={null}
          />
        </Box>
      </LeftDrawer>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        message="You want to cancel the process approving invoice"
        onCloseModal={handleYesConfirmModal}
        onConfirmModal={handleCloseConfirmModal}
        children={null}
      />
    </>
  );
}
