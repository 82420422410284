import Stage from "./pages/stages/stages";
import Menu from "./pages/menu/menu";
import User from "./pages/users/users";
import Director from "./pages/director/director";
import Status from "./pages/status/status";
import Service from "./pages/services/services";
import UserLogs from "./pages/user-logs/user-logs";
import UsersAccess from "./pages/users-acces/users_acces";
import Attachment from "./pages/attachment/attachment";
import AddAttachment from "./pages/attachment/add_attachment";
import Archive from "./pages/archive/archive/archive";
import AddArchive from "./pages/archive/archive/add_archive";
import DetailArchive from "./pages/archive/archive/detail_archive";
import Request from "./pages/archive/request/request";
import AddRequest from "./pages/archive/request/add_request";
import DetailRequest from "./pages/archive/request/detail_request";
import RequestRecipient from "./pages/archive/request_recipient/request_recipient";
import DetailRequestRecipient from "./pages/archive/request_recipient/detail_request_recipient";
import Return from "./pages/archive/return/return";
import AddReturn from "./pages/archive/return/add_return";
import DetailReturn from "./pages/archive/return/detail_return";
import Cupboard from "./pages/archive/cupboard/cupboard";
import TimeOff from "./pages/time-off/time-off";
import KPIRules from "./pages/human-resources/setting/kpi-rules/kpi-rules";
import ScheduleAnnualLeave from "./pages/schedule-annual-leave/schedule-annual-leave";
import Employees from "./pages/employees/employees";
import AddEmployee from "./pages/employees/add_employee";
import EditEmployee from "./pages/employees/edit_employee";
import DetailEmployee from "./pages/employees/detail_employee";
import Attendances from "./pages/attendances/attendances";
import Activities from "./pages/activity/activity";
import DetailAttendance from "./pages/attendances/detail_attendance";
import Benefits from "./pages/benefits/benefits";
import DetailAllowance from "./pages/benefits/allowance/detail_allowance";
import DetailMealAllowance from "./pages/benefits/meal_allowance/detail_meal_allowance";
import Leave from "./pages/human-resources/leave/leave";
import DetailLeave from "./pages/human-resources/leave/detail-leave";
import TimeOffRequest from "./pages/human-resources/time-off-request/time-off-request";
import DetailTimeOffRequest from "./pages/human-resources/time-off-request/detail-time-off-request";
import EmployeResignation from "./pages/human-resources/employe-resign/employe-resign";
import EmployeTransfer from "./pages/human-resources/employe-transfer/employe-transfer";
import AddEmployeTransfer from "./pages/human-resources/employe-transfer/add-employe-transfer";
import Client from "./pages/sales-management/client/client";
import Applicant from "./pages/sales-management/applicant/applicant";
import SalesQuotation from "./pages/sales-management/sales-quotation/sales-quotation";
import DetailSalesQuotation from "./pages/sales-management/sales-quotation/detail-sales-quotation";
import SalesOrder from "./pages/sales-management/sales-order/sales-order";
import Brand from "./pages/sales-management/brand/brand";
import DetailSalesOrder from "./pages/sales-management/sales-order/detail-sales-order";
import PatentApplication from "./pages/patent/application/application";
import AddPatentApplication from "./pages/patent/application/add_application";
import EditPatentApplication from "./pages/patent/application/edit_application";
import DetailPatentApplication from "./pages/patent/application/detail-application";
import ResubmitPatentApplication from "./pages/patent/application/resubmit_application";
import PatentRegistration from "./pages/patent/registration/registration";
import PatentAnuity from "./pages/patent/anuity/anuity";
import DetailPatentRegistration from "./pages/patent/registration/detail-registration";
import DetailPatentAnuity from "./pages/patent/anuity/detail-anuity";
import Application from "./pages/business-permit/application/application";
import Renewal from "./pages/business-permit/renewal/renewal";
import Recordial from "./pages/business-permit/recordial/recordial";
import Registration from "./pages/business-permit/registration/registration";
import AddApplication from "./pages/business-permit/application/add_application";
import EditApplication from "./pages/business-permit/application/edit_application";
import EditRecordial from "./pages/business-permit/recordial/edit_recordial";
import ResubmitApplication from "./pages/business-permit/application/resubmit_application";
import DetailApplication from "./pages/business-permit/application/detail-application";
import DetailRenewal from "./pages/business-permit/renewal/detail-renewal";
import DetailRecordial from "./pages/business-permit/recordial/detail-recordial";
import DetailRegistration from "./pages/business-permit/registration/detail-registration";
import Invoice from "./pages/finance/invoice/invoice";
import DetailInvoice from "./pages/finance/invoice/detail_invoice.jsx";
import Payment from "./pages/finance/payment/payment.jsx";
import PaymentRequest from "./pages/finance/payment-request/payment_request.jsx";
import DetailPaymenRequest from "./pages/finance/payment-request/detail_payment_request.jsx";
import PerformaInvoice from "./pages/finance/proforma-invoice/proforma-invoice.jsx";
import DetailProformaInvoice from "./pages/finance/proforma-invoice/detail_proforma_invoice.jsx";
import DetailPayment from "./pages/finance/payment/detail_payment.jsx";
import RoomName from "./pages/ga/meeting_room/room_name/room_name.jsx";
import BookingSubmission from "./pages/ga/meeting_room/booking_submission/booking_submission.jsx";
import PurchaseRequest from "./pages/ga/purchase_request/purchase_request.jsx";
import Asset from "./pages/ga/asset/asset.jsx";
import StockIn from "./pages/ga/stock_in/stock_in.jsx";
import SetupRewardPoint from "./pages/finance/reward/set-up-reward-point/set-up-reward-point.jsx";
import ClaimRewardPoint from "./pages/finance/reward/claim-reward-point/claim-reward-point.jsx";
import StockOut from "./pages/ga/stock_out/stock_out.jsx";
import TrademarkApplication from "./pages/trademark/aplication/trademark_aplication.jsx";
import AddTrademarkApplication from "./pages/trademark/aplication/add_trademark_aplication.jsx";
import DetailTrademarkApplication from "./pages/trademark/aplication/detail_trademark_aplication.jsx";
import TrademarkRegistration from "./pages/trademark/registration/trademark_registration.jsx";
import DetailTrademarkRegistartion from "./pages/trademark/registration/detail_trademark_registrations.jsx";
import EditTrademarkApplication from "./pages/trademark/aplication/edit_trademark_aplication.jsx";

export const routes = [
  {
    path: "/",
    component: <>Dashboard</>,
    name: "Home",
  },
  {
    path: "/system-configuration",
    name: "System Configuration",
    children: [
      {
        path: "/system-configuration/menu-management",
        component: <Menu />,
        name: "Menu Management",
      },
      {
        path: "/system-configuration/power",
        component: <Director />,
        name: "Power",
      },

      {
        path: "/system-configuration/director",
        component: <Director />,
        name: "Director",
      },
      {
        path: "/system-configuration/stages",
        component: <Stage />,
        name: "Stages",
      },
      {
        path: "/system-configuration/services",
        component: <Service />,
        name: "Services",
      },
      {
        path: "/system-configuration/attachment-file",
        component: <Attachment />,
        name: "Attachment",
      },
      {
        path: "/system-configuration/attachment-file/add",
        component: <AddAttachment />,
        name: "Add New Attachment",
      },
      {
        path: "/system-configuration/attachment-file/edit/:id",
        component: <AddAttachment />,
        name: "Edit Attachment",
      },
      {
        path: "/system-configuration/status",
        component: <Status />,
        name: "Status",
      },
      {
        path: "/system-configuration/user-logs",
        component: <UserLogs />,
        name: "User Logs",
      },
    ],
  },
  {
    path: "/human-resources",
    exact: true,
    name: "Human Resources",
    children: [
      {
        path: "/human-resources/employees",
        component: <Employees />,
        name: "Employees",
      },
      {
        path: "/human-resources/employees/add",
        component: <AddEmployee />,
        name: "Add Employee",
      },
      {
        path: "/human-resources/employees/edit/:id/:type",
        component: <EditEmployee />,
        name: "Edit Employee",
      },
      {
        path: "/human-resources/employees/detail/:id",
        component: <DetailEmployee />,
        name: "Detail Employee",
      },
      {
        path: "/human-resources/attendance",
        component: <Attendances />,
        name: "Attendances",
      },
      {
        path: "/human-resources/activity",
        component: <Activities />,
        name: "Activities",
      },
      {
        path: "/human-resources/attendance/detail/:id",
        component: <DetailAttendance />,
        name: "Detail Attendance",
      },
      {
        path: "/human-resources/leaves",
        component: <Leave />,
        name: "Leave",
      },
      {
        path: "/human-resources/leaves/detail/:id",
        component: <DetailLeave />,
        name: "Detail Leave",
      },
      {
        path: "/human-resources/time-off-requests",
        component: <TimeOffRequest />,
        name: "Time Off Request",
      },
      {
        path: "/human-resources/time-off-requests/detail/:id",
        component: <DetailTimeOffRequest />,
        name: "Detail Time Off Request",
      },
      {
        path: "/human-resources/employe-resign",
        component: <EmployeResignation />,
        name: "Employee Resignation",
      },
      {
        path: "/human-resources/employe-transfer",
        component: <EmployeTransfer />,
        name: "Employee Transfer",
      },
      {
        path: "/human-resources/employe-transfer/add",
        component: <AddEmployeTransfer />,
        name: "Add New Employee Transfer",
      },
      {
        path: "/human-resources/employe-transfer/edit/:id",
        component: <AddEmployeTransfer />,
        name: "Edit Employee Transfer",
      },
      {
        path: "/human-resources/setting",
        exact: true,
        name: "Setting",
        children: [
          {
            path: "/human-resources/setting/time-off",
            exact: true,
            name: "Time Off",
            component: <TimeOff />,
          },
          {
            path: "/human-resources/setting/schedule-annual-leave",
            exact: true,
            name: "Schedule & Annual Leave",
            component: <ScheduleAnnualLeave />,
          },
          {
            path: "/human-resources/setting/kpi-rules",
            exact: true,
            name: "KPI Rules",
            component: <KPIRules />,
          },
        ],
      },
      {
        path: "/human-resources/benefit",
        component: <Benefits />,
        name: "Benefit",
      },
      {
        path: "/human-resources/benefit/detail/:id/allowance",
        component: <DetailAllowance />,
        name: "Detail Allowance",
      },
      {
        path: "/human-resources/benefit/detail/:id/meal_allowance",
        component: <DetailMealAllowance />,
        name: "Detail Meal Allowance",
      },
    ],
  },
  {
    path: "/archive-management",
    name: "Archive Management",
    children: [
      {
        path: "/archive-management/archive",
        component: <Archive />,
        name: "Archive",
      },
      {
        path: "/archive-management/archive/add",
        component: <AddArchive />,
        name: "Add Archive",
      },
      {
        path: "/archive-management/archive/edit/:id",
        component: <AddArchive />,
        name: "Edit Archive",
      },
      {
        path: "/archive-management/archive/detail/:id",
        component: <DetailArchive />,
        name: "Detail Archive",
      },
      {
        path: "/archive-management/request",
        component: <Request />,
        name: "Request",
      },
      {
        path: "/archive-management/request/add",
        component: <AddRequest />,
        name: "Add Request",
      },
      {
        path: "/archive-management/request/detail/:id",
        component: <DetailRequest />,
        name: "Detail Request",
      },
      {
        path: "/archive-management/request/edit/:id",
        component: <AddRequest />,
        name: "Edit Request",
      },
      {
        path: "/archive-management/request_recipient",
        component: <RequestRecipient />,
        name: "Request Recipient",
      },
      {
        path: "/archive-management/request_recipient/detail/:id",
        component: <DetailRequestRecipient />,
        name: "Detail Request Recipient",
      },
      {
        path: "/archive-management/return",
        component: <Return />,
        name: "Return",
      },
      {
        path: "/archive-management/return/add",
        component: <AddReturn />,
        name: "Add Return",
      },
      {
        path: "/archive-management/return/detail/:id",
        component: <DetailReturn />,
        name: "Detail Return",
      },
      {
        path: "/archive-management/return/edit/:id",
        component: <AddReturn />,
        name: "Edit Return",
      },
      {
        path: "/archive-management/cupboard",
        component: <Cupboard />,
        name: "Cupboard",
      },
    ],
  },
  {
    path: "/user-management",
    name: "Users",
    children: [
      {
        path: "/user-management/users",
        component: <User />,
        name: "Users",
      },
      {
        path: "/user-management/users-access",
        component: <UsersAccess />,
        name: "User Access",
      },
    ],
  },
  {
    path: "/sales-management",
    name: "Sales Management",
    children: [
      {
        path: "/sales-management/sales-order",
        component: <SalesOrder />,
        name: "Sales Order",
      },
      {
        path: "/sales-management/brand",
        component: <Brand />,
        name: "Brand",
      },

      {
        path: "/sales-management/sales-order/detail/:id",
        component: <DetailSalesOrder />,
        name: "Detail Sales Order",
      },
      {
        path: "/sales-management/sales-quotation",
        component: <SalesQuotation />,
        name: "Sales Quotation",
      },
      {
        path: "/sales-management/sales-quotation/detail/:id",
        component: <DetailSalesQuotation />,
        name: "Detail Sales Order",
      },
      {
        path: "/sales-management/applicant",
        component: <Applicant />,
        name: "Applicant",
      },
      {
        path: "/sales-management/client",
        component: <Client />,
        name: "Client",
      },
    ],
  },
  {
    path: "/general-affair",
    exact: true,
    name: "General Affair",
    children: [
      {
        path: "/general-affair",
        name: "Meeting Room",
        children: [
          {
            path: "/general-affair/room",
            component: <RoomName />,
            name: "Meeting Room",
          },
          {
            path: "/general-affair/booking",
            component: <BookingSubmission />,
            name: "Booking Submission",
          },
        ],
      },
      {
        path: "/general-affair/purchase-requests",
        component: <PurchaseRequest />,
        name: "Purchase Request",
      },
      {
        path: "/general-affair/assets",
        component: <Asset />,
        name: "Asset",
      },
      {
        path: "/general-affair/stock-in",
        component: <StockIn />,
        name: "Stock in",
      },
      {
        path: "/general-affair/stock-out",
        component: <StockOut />,
        name: "Stock Out",
      },
    ],
  },
  {
    path: "/operation-management",
    exact: true,
    name: "Operation Management",
    children: [
      {
        path: "/operation-management/business-permit",
        name: "Business Permit",
        children: [
          {
            path: "/operation-management/business-permit/application",
            component: <Application />,
            name: "Application",
          },
          {
            path: "/operation-management/business-permit/application/add",
            component: <AddApplication />,
            name: "Add Application",
          },
          {
            path: "/operation-management/business-permit/application/edit/:id",
            component: <EditApplication />,
            name: "Edit Application",
          },
          {
            path: "/operation-management/business-permit/application/resubmit/:id",
            component: <ResubmitApplication />,
            name: "Resubmit Application",
          },
          {
            path: "/operation-management/business-permit/application/detail/:id",
            component: <DetailApplication />,
            name: "Detail Application",
          },
          {
            path: "/operation-management/business-permit/registration",
            component: <Registration />,
            name: "Registration",
          },
          {
            path: "/operation-management/business-permit/registration/detail/:id",
            component: <DetailRegistration />,
            name: "Detail Registration",
          },
          // ### Renewal ###
          {
            path: "/operation-management/business-permit/renewal",
            component: <Renewal />,
            name: "Renewal",
          },
          {
            path: "/operation-management/business-permit/renewal/edit/:id",
            component: <EditApplication />,
            name: "Edit Renewal",
          },
          {
            path: "/operation-management/business-permit/renewal/detail/:id",
            component: <DetailRenewal />,
            name: "Detail Renewal",
          },
          // ### Recordial ###
          {
            path: "/operation-management/business-permit/recordial",
            component: <Recordial />,
            name: "Recordial",
          },
          {
            path: "/operation-management/business-permit/recordial/:type/:id", // :type => edit / preview
            component: <EditRecordial />,
            name: "Edit Recordial",
          },
          {
            path: "/operation-management/business-permit/recordial/:type/:id",
            component: <EditRecordial />,
            name: "Edit Recordial",
          },
          {
            path: "/operation-management/business-permit/recordial/detail/:id",
            component: <DetailRecordial />,
            name: "Detail Recordial",
          },
        ],
      },
      {
        path: "/operation-management/domestic-trademark",
        name: "Domestic Trademark",
        children: [
          {
            path: "/operation-management/domestic-trademark/trademark-applications",
            name: "Trademark Aplication",
            component: <TrademarkApplication />,
          },
          {
            path: "/operation-management/domestic-trademark/trademark-applications/add",
            name: "Add Trademark Aplication",
            component: <AddTrademarkApplication />,
          },
          {
            path: "/operation-management/domestic-trademark/trademark-applications/edit/:id",
            name: "Add Trademark Aplication",
            component: <EditTrademarkApplication />,
          },
          {
            path: "/operation-management/domestic-trademark/trademark-applications/detail/:id",
            name: "Detail Trademark Aplication",
            component: <DetailTrademarkApplication />,
          },
          {
            path: "/operation-management/domestic-trademark/trademark-registartions",
            name: "Trademark Registrations",
            component: <TrademarkRegistration />,
          },
          {
            path: "/operation-management/domestic-trademark/trademark-registartions/detail/:id",
            name: "Detail Trademark Registrations",
            component: <DetailTrademarkRegistartion />,
          },
        ],
      },
      {
        path: "/operation-management/patent",
        name: "Patent",
        children: [
          {
            path: "/operation-management/patent/application",
            component: <PatentApplication />,
            name: "Application",
          },
          {
            path: "/operation-management/patent/application/add",
            component: <AddPatentApplication />,
            name: "Add Application",
          },
          {
            path: "/operation-management/patent/application/edit/:id",
            component: <EditPatentApplication />,
            name: "Edit Application",
          },
          {
            path: "/operation-management/patent/application/detail/:id",
            component: <DetailPatentApplication />,
            name: "Detail Application",
          },
          {
            path: "/operation-management/patent/application/resubmit/:id",
            component: <ResubmitPatentApplication />,
            name: "Resubmit Application",
          },
           {
            path: "/operation-management/patent/registration",
            component: <PatentRegistration />,
            name: "Registration",
          },
          {
            path: "/operation-management/patent/registration/detail/:id",
            component: <DetailPatentRegistration />,
            name: "Detail Registration",
          },
          {
            path: "/operation-management/patent/anuity",
            component: <PatentAnuity/>,
            name: "Anuity",
          },
          {
            path: "/operation-management/patent/anuity/detail/:id",
            component: <DetailPatentAnuity/>,
            name: "Detail Anuity",
          },
        ],
      }
    ],
  },
  {
    path: "/finance",
    name: "finance",
    children: [
      {
        path: "/finance/invoice",
        component: <Invoice />,
        name: "invoice",
      },
      {
        path: "/finance/invoice/detail/:id",
        component: <DetailInvoice />,
        name: "detail-invoice",
      },
      {
        path: "/finance/proforma-invoice",
        component: <PerformaInvoice />,
        name: "proforma-invoice",
      },
      {
        path: "/finance/proforma-invoice/detail/:id",
        component: <DetailProformaInvoice />,
        name: "detail-proforma-invoice",
      },
      {
        path: "/finance/payment-request",
        component: <PaymentRequest />,
        name: "payment-request",
      },
      {
        path: "/finance/payment-request/detail/:id",
        component: <DetailPaymenRequest />,
        name: "detail-payment-request",
      },
      //* Payment
      {
        path: "/finance/payment",
        component: <Payment />,
        name: "payment",
      },
      {
        path: "/finance/payment/detail/:id",
        component: <DetailPayment />,
        name: "detail-payment",
      },
      {
        path: "/finance/reward",
        exact: true,
        name: "Reward",
        children: [
          {
            path: "/finance/reward/setup-reward",
            component: <SetupRewardPoint />,
            name: "Set Up Reward Point",
          },
          {
            path: "/finance/reward/claim-reward",
            component: <ClaimRewardPoint />,
            name: "Claim Reward Point",
          },
        ]
      }
    ],
  },
  /* --------------------- KODE DIBAWAH INI JANGAN DIHAPUS -------------------- */
  {
    path: "/level1",
    exact: true,
    name: "Level 1",
    children: [
      {
        path: "/level1/level2",
        exact: true,
        name: "Level 2",
        children: [
          {
            path: "/level1/level2/level3",
            exact: true,
            name: "Level 3",
            children: [
              {
                path: "/level1/level2/level3/level4",
                component: <Director />,
                exact: true,
                name: "Level 4",
              },
            ],
          },
        ],
      },
    ],
  },
];
