//@ts-check

import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import { logout } from "../../../auth/clients/login/actions";

import {
  CLEAR_TRADEMARK_REGISTRATION_UPDATE_ID,
  GET_TRADEMARK_REGISTRATION_FAILED,
  GET_TRADEMARK_REGISTRATION_SUCCESS,
  SET_TRADEMARK_REGISTRATION_UPDATE_ID,
  UPDATE_TRADEMARK_REGISTRATION_FAILED,
  UPDATE_TRADEMARK_REGISTRATION_SUCCESS,
  STATUS_APPLICATION,
} from "./state";

/**
 * @typedef {import("@reduxjs/toolkit").Dispatch} Dispatch
 * @typedef {'GET'|'POST'|'EDIT'|'DELETE'|'DETAIL'} MethodType
 */

const baseUrl = "/trademark-registrations";

//* ============================================================= */
//*                             HELPERS                           */
//* ============================================================= */

/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {Dispatch} dispatch
 * @param {Function} successHandler
 * @param {Function} errorHandler
 * @param {MethodType} type
 */

const responseHandler = (response, dispatch, successHandler, errorHandler, type) => {
  if (typeof successHandler !== "function" || typeof errorHandler !== "function") {
    throw new Error("Handler is not function");
  }
  //   const listActionRunModal = ["POST", "EDIT", "DELETE"];
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    if (type === "GET") {
      dispatch(successHandler(response.data.data, response.data));
    } else if (type === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      dispatch(
        RunModal(
          type === "POST" ? "SAVED" : type === "EDIT" ? "EDITED" : "DELETED",
          "Stock in"
        )
      );
      dispatch(successHandler(response.data.message));
    }
  } else if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === false
  ) {
    if (type === "GET") {
      dispatch(successHandler([], null));
    } else {
      dispatch(errorHandler(response.data.message));
      dispatch(RunModal("FAILED", response.data.message));
    }
  } else {
    dispatch(errorHandler(response.data.message));
    dispatch(RunModal("FAILED", response.data.message));
  }
};

/**
 *
 * @param {import('axios').AxiosError} errorResposense
 * @param {Dispatch} dispatch
 * @param {Function} failHandler
 */
const errorHandler = (errorResposense, dispatch, failHandler) => {
  let messageError = "";
  if (
    errorResposense.response !== undefined &&
    errorResposense.response.data !== undefined
  ) {
    if (errorResposense.response.status === 401) {
      dispatch(logout());
      return;
    } else {
      dispatch(failHandler(errorResposense.response.data.message));
      messageError = errorResposense.response.data.message;
    }
  } else {
    messageError = formatNetworkErrorMessage(errorResposense.message);
    dispatch(failHandler(messageError));
  }

  dispatch(RunModal("FAILED", messageError));
};

//* ------------------------------------------------------------- */
//*                            GET                                */
//* ------------------------------------------------------------- */

export const getTrademarkApplication = (
  token,
  { page = 1, limit = 10, status, search, searchBy, receiptDate, sortBy, sortDirection }
) => {
  return (/** @type {Dispatch} */ dispatch) => {
    //@ts-ignore
    const getUrl = urlRequest({
      url: baseUrl,
      page,
      limit,
      searchValue: search,
      searchBy: search ? searchBy : null,
      status:
        status !== ACTION_TYPE.DEFAULT_STATUS && status !== ACTION_TYPE.ALL_STATUS
          ? status
          : null,
      period: receiptDate ? receiptDate : null,
      sortBy, sortDirection
    });

    dispatch(RequestLoading());
    axios
      .get(getUrl, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          getTrademarkApplicationSucces,
          getTrademarkApplicationFail,
          "GET"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, getTrademarkApplicationFail);
      });
  };
};

/**
 *
 * @param {any} data
 * @param {any} pagination
 * @returns
 */
function getTrademarkApplicationSucces(data, pagination) {
  const payload = {
    type: GET_TRADEMARK_REGISTRATION_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: parseInt(pagination.limit),
            totalData: pagination.total_data,
            totalPage: pagination.total_data,
          },
  };
  return payload;
}

function getTrademarkApplicationFail(/** @type {String} */ message) {
  return {
    type: GET_TRADEMARK_REGISTRATION_FAILED,
    payload: message,
  };
}

//* ------------------------------------------------------------- */
//*                            UPDATE                             */
//* ------------------------------------------------------------- */

export const updateTrademarkRegister = (token, payload, id) => {
  return async (/** @type {Dispatch} */ dispatch) => {
    const localUrl = baseUrl.concat("/", id);
    dispatch(RequestLoading());
    return axios
      .patch(localUrl, payload, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          updateTrademarkRegistrationsSuccess,
          updateTrademarkRegistrationFailed,
          "EDIT"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, updateTrademarkRegistrationFailed);
      });
  };
};

function updateTrademarkRegistrationsSuccess(/** @type {String} */ message) {
  return { type: UPDATE_TRADEMARK_REGISTRATION_SUCCESS, payload: message };
}

function updateTrademarkRegistrationFailed(/** @type {String} */ message) {
  return { type: UPDATE_TRADEMARK_REGISTRATION_FAILED, payload: message };
}

export const setUpdateId = (/** @type {number|string} */id) => {
  return {
    type: SET_TRADEMARK_REGISTRATION_UPDATE_ID,
    payload: id,
  };
};

export const clearUpdateId = () => {
  return {
    type: CLEAR_TRADEMARK_REGISTRATION_UPDATE_ID,
  };
};
