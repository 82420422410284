import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
    Tooltip,
    Grid,
    Divider,
    Button,
    MenuItem,
    Card,
    CardContent,
    Typography,
    TextField,
    Modal,
    Box,
    InputLabel,
    FormControlLabel,
    Select,
    Checkbox,
    IconButton,
    Popover,
    Chip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import InfoCircle from "./../../../assets/info-circle.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import editIcon from "./../../../assets/time-circle.png";

import {
    borderRadius,
    secondColor,
    thirdColor,
    btnWhite,
    formGroup,
    StatusSwitch,
    StatusBadge,
    defaultStylePage,
    inputDate,
    btnTableToolbar,
    imgBtnToolbar,
    fourColor,
    FacebookCircularProgress,
    tabStyles,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
    SelectMultiple,
    SelectMultipleAutocomplete,
    SelectOne,
    SelectOneAutocomplete,
    TextInput,
    TextArea,
    TimePickerInput,
    DatePickerInput,
} from "../../../components/Form";
import {
    getBenefits,
    addBenefits,
    editBenefits,
    deleteBenefits,
} from "../clients/actions";
import {
    ACTION_TYPE,
    USER_ACCESS as _,
    findMenuItemByLink,
    INPUT_TYPE,
    SELECT_OPTION,
    TabPanel,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";

const MealAllowance = ({ dateFilter, period }) => {
    /* -------------------------------------------------------------------------- */
    /*                                   STYLES                                   */
    /* -------------------------------------------------------------------------- */

    const theme = useTheme();
    const useStyles = defaultStylePage;

    /* -------------------------------------------------------------------------- */
    /*                                    STATE                                   */
    /* -------------------------------------------------------------------------- */
    const [infoLastUpdated, setInfoLastUpdated] = useState(null);
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
    const [selectedData, setSelectedData] = useState([]);

    const fields = [
        {
            name: "start_date",
            type: INPUT_TYPE.DATE,
            label: "Start From",
            endIcon: <img src={CalendarIcon} />,
            formatDate: "DD/MM/YYYY", // Custom date format
            defaultValue: dayjs(),
            required: true,
            additionalProps: {
                disabled: selectedData.length && actionType === ACTION_TYPE.EDITED,
            },
            gridWidth: 6, // Half width
        },
        {
            name: "end_date",
            type: INPUT_TYPE.DATE,
            label: "Until",
            endIcon: <img src={CalendarIcon} />,
            formatDate: "DD/MM/YYYY", // Custom date format
            defaultValue: dayjs(),
            required: true,
            additionalProps: {
                disabled: selectedData.length && actionType === ACTION_TYPE.EDITED,
            },
            gridWidth: 6, // Half width
        },
    ];

    const columns = [
        {
            field: "periode",
            headerName: "Week Period",
            sortable: true,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) => params.value,
            renderCell: (params) => {
                return (
                    <div style={{ color: "blue" }}>
                        <a
                            onClick={() => navigate(`detail/${params.row.id}/meal_allowance`)}
                            style={{ textDecoration: "none", cursor: "grab" }}
                        >
                            {params.value}
                        </a>
                    </div>
                );
            },
        },
        {
            field: "total_employe",
            headerName: "Total Employees",
            sortable: true,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => (
                <p>
                    <b>{params.value}</b> Employes
        </p>
            ),
        },
        {
            field: "total_pay",
            headerName: "Total Pay",
            sortable: true,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) => {
                return `Rp. ${params.value}`;
            },
        },
        {
            field: "status",
            headerName: "Payment Status",
            sortable: true,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params) => params.value, // Return the status value as a string
            renderCell: (params) => (
                <StatusBadge
                    bgColor={params.value === "Paid" ? "#D2F0CC" : "#FFF1CF"}
                    colors={params.value === "Paid" ? "#1DB200" : "#FFBA10"}
                >
                    {params.value}
                </StatusBadge>
            ),
        },
    ];

    const TYPE_BENEFIT = {
        ALLOWANCES: "allowance",
        MEAL_ALLOWANCES: "meal_allowance",
    };
    const { token } = useSelector((state) => state.auth);
    const [isFetchLoading, setIsFetchLoading] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [userDeleted, setUserDeleted] = useState(null);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [page, setPage] = useState(1);
    const [pageDB, setPageDB] = useState(0);
    const [limit, setLimit] = useState(10);
    const [title, setTitle] = useState("Meal Allowance");
    const [options, setOptions] = useState({});
    const [categoryBenefits, setCategoryBenefits] = useState(
        ACTION_TYPE.DEFAULT_CATEGORY_TIME_OFF
    );
    const [typeBenefit, setTypeBenefit] = useState(TYPE_BENEFIT.MEAL_ALLOWANCES);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.benefits);

    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    useEffect(() => {
        setSelectedData([]); //buat disabled button toolbar
    }, [dispatch]);

    const resetFormValues = () => {
        setFormValues(
            Object.assign({}, initialFormValues("fields"), {
                id: null,
                type: typeBenefit,
            })
        );
    };

    /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

    const initialFormValues = (type = "fields") => {
        if (type === "columns") {
            return columns.reduce(
                (acc, field) => ({ ...acc, [field.field]: "" }),
                {}
            );
        } else {
            return fields.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
            );
        }
    };

    const initialErrors = fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: "" }),
        {}
    );

    const [formValues, setFormValues] = useState(
        Object.assign({}, initialFormValues("fields"), {
            id: null,
            type: typeBenefit,
        })
    );
    const [errors, setErrors] = useState(initialErrors);

    useEffect(() => {
        console.log(formValues, "formValues,");
        // console.log(initialFormValues(), "asdad");
    }, [formValues]);

    useEffect(() => {
        const filteredRows = rows.filter((row) => selectedData.includes(row.id));
        // console.log(filteredRows, "filteredRows");
    }, [selectedData]);

    useEffect(() => {
        // console.log(sortModel, "newSortModel");
        if (sortModel && sortModel.length) {
            const { field, sort } = sortModel[0];
            setSortBy(field);
            setSortDirection(sort);
        }
    }, [sortModel]);

    useEffect(() => {
        if (isActive === true) {
            dispatch(
                getBenefits({
                    token,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    period,
                    typeBenefit,
                })
            );
        }
    }, [isActive]);

    const setFormValuesFromSelectedData = () => {
        const data = rows.filter((row) => selectedData.includes(row.id))[0];

        for (const key in data) {
            let value;

            if (key === "last_update") {
                if (data[key]) {
                    const aa = data[key];
                    debugger;
                    setInfoLastUpdated(data[key]);
                } else {
                    const asdad = 'gak ada'
                    debugger;
                    setInfoLastUpdated(null);
                }
            } else {
                value = data[key];
            }

            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [key]: value,
            }));
        }
    };

    const isFormFilled = () => {
        // return Object.values(formValues).every((value) => value.trim() !== "");
        return Object.values(formValues).some((value) => value.trim() !== "");
    };

    const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
        setActionType(actionType);
        setFormModal(true);
        setErrors(initialErrors);
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
            setTitle("Update Meal Allowance");
            setFormValuesFromSelectedData();
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            setTitle("Delete");
            const data = rows.filter((row) => selectedData.includes(row.id));
            setUserDeleted(data.map((row) => row.user ?.fullname).join(", "));
        } else {
            resetFormValues();
            setTitle("Add Meal Allowance");
        }
    };

    const handleCloseFormModal = (important = false) => {
        if (!important) {
            if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
                if (isFormFilled) setConfirmModal(true);
            } else {
                setFormModal(false);
            }
        } else {
            setFormModal(false);
        }
    };

    const handleOnConfirmCancelModal = () => {
        setConfirmModal(false);
    };

    const handleOnCloseConfirmCancelModal = () => {
        setConfirmModal(false);
        setFormModal(false);
        resetFormValues();
        // setSelectedData([]); //buat disabled button toolbar
    };

    const handleExitedModal = () => {
        resetFormValues();
    };

    const handleAddRow = () => {
        dispatch(addBenefits(token, formValues));
    };

    const handleEditRow = () => {
        dispatch(editBenefits(token, formValues, formValues.id));
    };

    const handleDeleteRow = () => {
        const data = rows.filter((row) => selectedData.includes(row.id));
        const ids = data.map((row) => row.id);
        dispatch(deleteBenefits(token, { data: { ids } }, true));
    };

    /* -------------------------------------------------------------------------- */
    /*                                   ACTION                                   */
    /* -------------------------------------------------------------------------- */

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        // console.log(name, checked);
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    const handleInputBlur = (event) => {
        const { name } = event.target;
        validateField(name);
    };

    const validateField = (fieldName) => {
        const field = fields.find((field) => field.name === fieldName);
        const newErrors = { ...errors };

        if (field) {
            const { name, required, validation, errorMessage, label, type } = field;

            if (
                required &&
                (String(formValues[name]).trim() === "" ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
            ) {
                newErrors[name] = `${field.label} is required`;
            } else if (validation && !validation(formValues[name])) {
                newErrors[name] = errorMessage || `${label} is invalid`;
            } else {
                newErrors[name] = "";
            }

            setErrors(newErrors);
            console.log(newErrors, "newErrors");
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...initialErrors };

        fields.forEach((field) => {
            const { name, required, validation, errorMessage, type } = field;

            if (
                required &&
                (String(formValues[name]).trim() === "" ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
            ) {
                newErrors[name] = `${field.label} is required`;
                valid = false;
            }

            if (validation && !validation(formValues[name])) {
                newErrors[name] = errorMessage || `${field.label} is invalid`;
                valid = false;
            }
        });

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formValues);
        if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            handleDeleteRow();
            handleCloseFormModal(true);
            setSelectedData([]);
        } else {
            if (validateForm()) {
                if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
                    handleEditRow();
                    handleCloseFormModal(true);
                } else if (actionType === ACTION_TYPE.ADD) {
                    handleAddRow();
                    handleCloseFormModal(true);
                }
                setSelectedData([]);
            }
        }
    };

    const handleSortModelChange = (newSortModel) => {
        // if (newSortModel && newSortModel.length) {
        //   const { field, sort } = newSortModel[0];
        setSortModel(newSortModel);
        // setSortBy(field);
        // setSortDirection(sort);
        // }
    };

    const _infoLastUpdated = () => {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED && infoLastUpdated !== null) {
            return (
                <StatusBadge isinfo={true} style={{ marginBottom: "1rem", fontWeight: 500, fontSize: "13px" }}>
                    <img src={InfoCircle} style={{ marginRight: "5px" }} /> {infoLastUpdated}
                </StatusBadge>
            )
        }
    };

    /* -------------------------------------------------------------------------- */
    /*                                    FORM                                    */
    /* -------------------------------------------------------------------------- */

    const renderFormContent = () => {
        if (
            (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
            (!selectedData.length && actionType === ACTION_TYPE.ADD)
        ) {
            return (
                <Grid container spacing={1}>
                    {/* /* ---------------------------- START -Info Last Updated --------------------------- */}
                    {_infoLastUpdated()}
                    {/* /* ---------------------------- END- Info Last Updated --------------------------- */}
                    {fields.map((field) => {
                        // # Old
                        // const fieldOptions = Array.isArray(options[field.name])
                        //   ? [
                        //     field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                        //       id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                        //       // name: `Select ${field.label}`,
                        //       name: `Choose Option`,
                        //     },
                        //     ...options[field.name],
                        //   ]
                        //   : [];

                        // # New
                        let optionsData;
                        if (Array.isArray(options[field.name])) {
                            const isRequired = field.required &&
                                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                                    name: "Choose Option",
                                };

                            if (isRequired) {
                                optionsData = [
                                    // field.required &&
                                    //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                                    //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                                    //     name: "Choose Option",
                                    //   },
                                    ...options[field.name],
                                ];
                            } else {
                                optionsData = [...options[field.name]];
                            }
                        }

                        const fieldOptions = Array.isArray(options[field.name])
                            ? optionsData
                            : [];

                        let fieldComponent = null;

                        switch (field.type) {
                            case INPUT_TYPE.TEXT:
                            case INPUT_TYPE.NUMBER:
                            case INPUT_TYPE.PHONE_NUMBER:
                            case INPUT_TYPE.POINT:
                            case INPUT_TYPE.CURRENCY:
                                fieldComponent = (
                                    <TextInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.TEXTAREA:
                                fieldComponent = (
                                    <TextArea
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        tip={field.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_ONE:
                                fieldComponent = (
                                    <SelectOne
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                                fieldComponent = (
                                    <SelectOneAutocomplete
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_MULTIPLE:
                                fieldComponent = (
                                    <SelectMultiple
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                                fieldComponent = (
                                    <SelectMultipleAutocomplete
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.DATE:
                                fieldComponent = (
                                    <DatePickerInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                    />
                                );
                                break;
                            case INPUT_TYPE.TIME:
                                fieldComponent = (
                                    <TimePickerInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                    />
                                );
                                break;
                            default:
                                fieldComponent = null;
                        }

                        return (
                            <Grid
                                item
                                xs={field.gridWidth || 12}
                                key={field.name}
                                sx={{ paddingTop: "unset !important" }}
                            >
                                {fieldComponent}
                            </Grid>
                        );
                    })}
                </Grid>
            );
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            return <ConfirmDelete userDeleted={userDeleted} />;
        }
    };

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => handleCloseFormModal()}
                >
                    Cancel
        </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit"
                >
                    {actionType === ACTION_TYPE.EDITED ? "Update" : "Save"}
                </Button>
            </Box>
        );
    };

    /* -------------------------------------------------------------------------- */
    /*                                  DATATABLE                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        if (
            dateFilter === ACTION_TYPE.ALL_DATE ||
            (dateFilter === "custom_date" && period)
        ) {
            dispatch(
                getBenefits({
                    token,
                    page,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    period,
                    typeBenefit,
                })
            );
        } else {
            setSelectedData([]);
        }
    }, [period, dateFilter]);

    useEffect(() => {
        if (period) {
            dispatch(
                getBenefits({
                    token,
                    page,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    period,
                    typeBenefit,
                })
            );
        }
    }, [sortDirection, sortBy]);

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getBenefits({
                token,
                page,
                limit,
                search,
                sortDirection,
                sortBy,
                period,
                typeBenefit,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getBenefits({
                token,
                limit,
                search,
                sortDirection,
                sortBy,
                period,
                typeBenefit,
            })
        );
    }

    const handlePageChange = (newPage) => {
        setPage(newPage + 1);
        setPageDB(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        // console.log(newLimit);
        setPage(1);
        setPageDB(1);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const ModifyToolbarRight = () => {
        return (
            <span>
                <Button
                    size="medium"
                    color="primary"
                    sx={{
                        ...btnTableToolbar,
                        borderRadius: "5px !important",
                        backgroundColor: "primary.main",
                        "&:hover": {
                            backgroundColor: "primary.dark",
                        },
                    }}
                    onClick={() => {
                        window.open(
                            process.env.REACT_APP_API_URL +
                            `/api/benefits/export?type=meal_allowance`,
                            "_blank"
                        );
                    }}
                >
                    <img
                        src={exportIcon}
                        style={{
                            ...imgBtnToolbar,
                            marginRight: 5,
                        }}
                    />
                    Export
        </Button>
            </span>
        );
    };

    return (
        <>
            {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
            <DataTable
                title={"Meal Allowance"}
                useStyles={useStyles}
                rows={rows}
                columns={columns}
                isLoading={isLoading}
                pagination={pagination}
                limit={limit}
                page={pageDB}
                handleFormModal={handleFormModal}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                selectedData={selectedData}
                handleSelectionChange={handleSelectionChange}
                theme={theme}
                dateChoosed={dateFilter}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                showDeleteToolbar={false}
                componentModifyToolbarRight={ModifyToolbarRight}
                iconEditToolbar={editIcon}
            />

            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={handleSubmit}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                onConfirmModal={handleOnConfirmCancelModal}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
        </>
    );
};

export default MealAllowance;
