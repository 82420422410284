"use strict";
//@ts-check

import React, { useEffect, useRef, useState } from "react";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../../util/style";
import { pxToMm } from "../../../../../util/function";
import boderImg from "./../../../../../assets/border.png";
import logo from "./../../../../../logo.png";
import Signature from "../../../payment-request/componens/Signature";
import { ValidateValueRender } from "../../validation/validateValue";
// import "../../styles/style.css";

//* Main Function
function DocumentForPrint({ useStyles, detailDocument, height }) {
  const classes = useStyles();
  //* =========== Primitif Var ===============

  const validation = new ValidateValueRender(detailDocument);
  // const statusColors = {
  //   pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
  //   approved: { bgColor: successSurfaceColor, colors: successMainColor },
  //   rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
  //   // Add more statuses as needed
  // };

  //* ====================================================== *//
  //*                         COMPONENT                      *//
  //* ====================================================== *//

  const renderGridItemsFromObject = () => {
    /**
     * key === key on detail document
     * value === display in UI
     */
    const alias = {
      no_proforma: "No",
      // TODO: create at need customize to display time;
      create_at: "Create At",
      no_reference: "No. Reference",
      remark: "Remark",
    };

    return Object.entries(alias).map(([key, aliasName]) => (
      <React.Fragment key={key}>
        <Grid item xs={4}>
          <Typography variant="body1">{aliasName}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
            {detailDocument.product[key]}
          </Typography>
        </Grid>
      </React.Fragment>
    ));
  };

  const RenderClientInformation = () => {
    const listRender = {
      bill_to: "Bill To",
      attn: "Attn",
      bill_address: "Bill Address",
    };

    return Object.entries(listRender).map(([key, aliasName]) => (
      <>
        <Box paddingY=".1rem">
          <Typography sx={{ color: neutral70, fontWeight: "600" }}>
            {aliasName}
          </Typography>
        </Box>
        <Box paddingY=".1rem">
          <Typography fontWeight={"500"}>
            {detailDocument.client[key]}
          </Typography>
        </Box>
      </>
    ));
  };

  const RenderPriceInformation = () => {
    /**
     * Structure of service
     * 
     * service = [
          {
              "name": "Patent Pecahan",
              "id": 2,
              "price": 98789876789
          },
          {
              "name": "Patent Sederhana",
              "id": 4,
              "price": 1324543245
          }
        ]
     */

    const listRender = {
      discount: "Discount",
      vat: "VAT",
    };

    /**
     * @typedef {object} SubServiceType
     * @property {string} name - name of service
     * @property {number} id - id
     * @property {number|string} price - price
     */

    return (
      <>
        <Typography variant="subtitle1">Price</Typography>
        <ul style={{ marginBlock: ".1rem", paddingLeft: "1.6rem" }}>
          <li>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">{`Price (${detailDocument?.product?.sub_service?.length} Services)`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" textAlign={"right"}>
                  {detailDocument?.product?.price}
                </Typography>
              </Grid>
            </Grid>
          </li>
          {detailDocument?.product?.discount != "Rp 0" && (
            <li>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">Discount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" textAlign={"right"}>
                    {detailDocument?.product?.discount}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )}
          <Box sx={{ paddingTop: ".5rem" }}>
            <Divider />
          </Box>
          <li>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" textAlign={"right"}>
                  {detailDocument?.product?.vat}
                </Typography>
              </Grid>
            </Grid>
          </li>
          <li style={{ listStyleType: "none" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"800"}
                >
                  Total
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"800"}
                >
                  {detailDocument?.product?.total}
                </Typography>
              </Grid>
            </Grid>
          </li>
        </ul>
      </>
    );
  };

  const RenderBankTransfer = () => {
    const listRender = {
      acount_name: "Account Name",
      acount_address: "Account address",
      bank_name: "Name of Bank",
      acount_no: "Account No.",
      bank_address: "Bank Address",
      // payment_receive: "Payment Received",
    };
    return (
      <>
        <Typography
          variant="subtitle1"
          sx={{ color: neutral70, fontWeight: "600", paddingY: ".5rem" }}
        >
          Bank Transfer
        </Typography>
        {Object.entries(listRender).map(([key, aliasName]) => (
          <Grid container>
            <Grid item xs={6}>
              <Typography>{aliasName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{detailDocument?.bank[key]}</Typography>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const SignatureSectionComp = () => (
    <Grid
      container
      spacing={3}
      sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
    >
      <Grid item xs={8} sx={{ ...justifyContentCenter }}>
        {/* <Signature companyName="User" signatureText={detailPR["Created By"]} /> */}
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        {/* TODO: Change to relative to data */}
        <Signature
          companyName="Accounting"
          signatureText={detailDocument["approval"]}
        />
      </Grid>
    </Grid>
  );

  const RenderFooter = () => (
    <div className={`${classes.footer} DocumentPreviewFooter`}>
      <Box sx={{ display: "flex", gap: ".5rem" }}>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          +6221 2986 5888
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          pacific@pacificpatent.com
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        www.pacificpatent.com
      </Typography>
    </div>
  );

  return (
    <>
      <style>
        {`
        .page-break {
          page-break-before: always;
        }
        .DocumentPreview{
          display:none;
        }
        .DocumentPreviewFooter{
          padding: 20px;
          background-color: #09345C;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media print {
//        /* size: A4; */
//        /* margin: 200mm; */
//        /* Adjust margins as needed */

          @page {
              // size: A4;
              //  size: 210mm 280mm;
            size: 210mm ${pxToMm(height)}mm; /* A4 size */
              margin: 0;
              /* Remove all margins */
          }

          body {
              margin: 0;
          }

          body *{
            visibility:hidden;
          }
          .DocumentPreviewFooter{
            position:fixed;
            bottom:0;
            left:0;
            width:100%;
            border-radius:unset;
          }
          .DocumentPreviewFooter, .DocumentPreviewFooter *{
            visibility: visible;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            print-color-adjust: exact;
            webkit-print-color-adjust: exact; /* For WebKit-based browsers */
            background-color:  #09345C !important; /* Override background color */
          }

          .DocumentPreview{
            display:block;
            position: fixed !important;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 11999;
            visibility: visible;
            page-break-before: always;
          }

          .DocumentPreview,.DocumentPreview *{
              visibility: visible;
          }
        }

        `}
      </style>
      <div className="page-break">
        <Paper className={`${classes.root} DocumentPreview`}>
          <img src={boderImg} className={classes.logo} />

          {/* Header */}
          <div className={classes.header}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "800" }}
              className="not-effect"
            >
              PROFORMA INVOICE
            </Typography>
            <img src={logo} alt="logo" />
          </div>
          {/* End of Header */}

          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs="6" container>
              {renderGridItemsFromObject()}
            </Grid>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                PT Pacific Patent Multiglobal{" "}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "right", color: neutral70 }}
              >
                DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav
                51-52
                <br /> Jakarta Pusat 10260
              </Typography>
            </Box>
          </Grid>

          {/* Content */}
          <div className={classes.content}>
            <Box marginTop={"1rem"}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: neutral70,
                  fontWeight: "600",
                  paddingY: ".5rem",
                }}
              >
                Description
              </Typography>
              <Typography>{detailDocument?.product?.description}</Typography>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 0 }}
            >
              <Grid item xs={8} paddingRight={".5rem"}>
                <Divider />
                <RenderPriceInformation />

                <RenderBankTransfer />
              </Grid>
              <Grid item xs={4}>
                <RenderClientInformation />
              </Grid>
              <Box marginLeft={"1.2rem"}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: neutral70,
                    fontWeight: "600",
                    paddingY: ".5rem",
                  }}
                >
                  Note
                </Typography>
                <Typography>
                  1. Metode Pembayaran – via bank transfer sesuai dengan bank
                  details yang tercantum pada invoice
                </Typography>
                <Typography>
                  2. Mohon cantumkan nomor invoice / proforma invoice pada saat
                  pembayaran dan bukti pembayaran di email ke
                  accounting@pacificpatent.com atau via WhatsApp ke 0877 2245
                  8585
                </Typography>
              </Box>
            </Grid>
          </div>
          <SignatureSectionComp />
          <RenderFooter />
        </Paper>
      </div>
    </>
  );
}

export default DocumentForPrint;
