import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
    Tooltip,
    Grid,
    Divider,
    Button,
    MenuItem,
    Card,
    CardContent,
    Typography,
    TextField,
    Modal,
    Box,
    InputLabel,
    FormControlLabel,
    Select,
    Checkbox,
    IconButton,
    Popover,
    Chip,
    Autocomplete,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
    borderRadius,
    secondColor,
    thirdColor,
    btnWhite,
    formGroup,
    StatusSwitch,
    StatusBadge,
    defaultStylePage,
    inputDate,
    btnTableToolbar,
    imgBtnToolbar,
    fourColor,
    FacebookCircularProgress,
    alignItemsCenter,
    removeButton,
    tabStyles,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
    SelectMultiple,
    SelectMultipleAutocomplete,
    SelectOne,
    SelectOneAutocomplete,
    TextInput,
    TextArea,
    TimePickerInput,
    DatePickerInput,
} from "../../../components/Form";
import {
    getBenefits,
    getBenefitDetails,
    editPayBenefitDetail,
    addPayBenefitDetail,
    addBenefits,
    editBenefits,
    deleteBenefits,
} from "../clients/actions";
import {
    ACTION_TYPE,
    USER_ACCESS as _,
    findMenuItemByLink,
    INPUT_TYPE,
    SELECT_OPTION,
    TabPanel,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackWhiteIcon from "./../../../assets/back-white.svg";

const DetailMealAllowance = ({ dateFilter, period }) => {
    /* -------------------------------------------------------------------------- */
    /*                                   STYLES                                   */
    /* -------------------------------------------------------------------------- */

    const theme = useTheme();
    const useStyles = defaultStylePage;

    /* -------------------------------------------------------------------------- */
    /*                                    STATE                                   */
    /* -------------------------------------------------------------------------- */
    const [employeeNameDisabled, setEmployeeNameDisabled] = useState(true);
    const [getDateBenefit, setGetDateBenefit] = useState({ start_date: dayjs(), end_date: dayjs() });

    const isEmployeeNameDisabled = () => {
        return employeeNameDisabled;
    };

    const isEmployeeNameRequired = () => {
        return !employeeNameDisabled;
    };

    const fields = [
        {
            name: "start_date",
            type: INPUT_TYPE.DATE,
            label: "Start From",
            endIcon: <img src={CalendarIcon} />,
            formatDate: "DD/MM/YYYY", // Custom date format
            defaultValue: getDateBenefit.start_date,
            required: true,
            additionalProps: {
                disabled: true,
            },
            gridWidth: 6, // Half width
        },
        {
            name: "end_date",
            type: INPUT_TYPE.DATE,
            label: "Until",
            endIcon: <img src={CalendarIcon} />,
            formatDate: "DD/MM/YYYY", // Custom date format
            defaultValue: getDateBenefit.end_date,
            required: true,
            additionalProps: {
                disabled: true,
            },
            gridWidth: 6, // Half width
        },
        {
            name: "user_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Employee Name",
            defaultValue: "",
            apiEndpoint: "/master/users",
            additionalProps: {
                disabled: isEmployeeNameDisabled(),
            },
            required: isEmployeeNameRequired()
        },
        {
            name: "pay",
            type: INPUT_TYPE.CURRENCY,
            label: "Meal Allowance",
            startIcon: "Rp",
            defaultValue: "",
            required: true
        },
        {
            name: "total_deduction",
            type: INPUT_TYPE.CURRENCY,
            label: "Total Deduction",
            startIcon: "Rp",
            required: true,
            additionalProps: {
                disabled: true,
            }
        },
        {
            name: "deduction_names",
            type: INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE,
            label: "Reason Deduction",
            placeholder: "Reason Deduction ( Conditional )",
            startIcon: "Rp",
            additionalProps: {
                disabled: true,
            }
        },
    ];

    const columns = [
        {
            field: "employe_id",
            headerName: "Employee ID",
            sortable: true,
            // fieldSortable: "employe.id",
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: "employe_name",
            headerName: "Employee Name",
            sortable: true,
            // fieldSortable: "employe.name",
            flex: 1,
            disableColumnMenu: true,
            // valueGetter: (params) => {
            //     return `${params.row.user ?.fullname}`;
            // },
        },
        {
            field: "pay",
            headerName: "Meal Allowance",
            sortable: true,
            // fieldSortable: "total_pay.amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span
                        style={{
                            color: 'green',
                        }}
                    >
                        {params.value}
                    </span>
                )
            },

        },
        {
            field: "attend",
            headerName: "Attend",
            sortable: true,
            // fieldSortable: "deduction.amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <span
                        // style={{
                        //     color: 'red',
                        // }}
                    >
                        {params.value}
                    </span>
                )
            },
        },
        // {
        //     field: "deduction_names",
        //     headerName: "Reason for Deduction",
        //     sortable: true,
        //     fieldSortable: "reason.amount",
        //     flex: 1,
        //     disableColumnMenu: true,
        //     valueGetter: (params) => params.value, // Return the status value as a string
        //     renderCell: (params) => {
        //         if (params.value ?.length) {
        //             return (
        //                 <Box
        //                     sx={{
        //                         display: "inline-block",
        //                         gap: "0.4rem",
        //                         padding: ".5rem 0",
        //                     }}
        //                 >
        //                     {params.value.map((v, index) => (
        //                         <StatusBadge
        //                             key={index}
        //                             isinfo={true}
        //                             style={{ margin: ".1rem .25rem" }}
        //                         >
        //                             {v.name}
        //                         </StatusBadge>
        //                     ))}
        //                 </Box>
        //             );
        //         } else {
        //             return null; // Return null if params.value is empty or undefined
        //         }
        //     },
        // },
        {
            field: "total_pay",
            headerName: "Total Pay",
            sortable: true,
            // fieldSortable: "total_pay.amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <span>
                  {params.value}
                </span>
              )
            },
      
          },
    ];

    const TYPE_BENEFIT = {
        ALLOWANCES: "allowance",
        MEAL_ALLOWANCES: "meal_allowance",
    };
    const { token } = useSelector((state) => state.auth);
    const [isFetchLoading, setIsFetchLoading] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [userDeleted, setUserDeleted] = useState(null);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [page, setPage] = useState(1);
    const [pageDB, setPageDB] = useState(0);
    const [limit, setLimit] = useState(10);
    const [title, setTitle] = useState("Meal Allowance");
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
    const [options, setOptions] = useState({
        all_date_allowance: [],
    });

    const [optionsTemporary, setOptionsTemporary] = useState({
        all_date_allowance: [],
    });
    const [categoryBenefits, setCategoryBenefits] = useState(
        ACTION_TYPE.DEFAULT_CATEGORY_TIME_OFF
    );
    const [typeBenefit, setTypeBenefit] = useState(TYPE_BENEFIT.MEAL_ALLOWANCES);
    const [selectedIdBenefit, setSelectedIdBenefit] = useState(null);
    const params = useParams();
    const [id, setId] = useState(parseInt(params.id));
    const [allUserNotAdded, setAllUserNotAdded] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        data: { user_pays: rows, detail: analytic, benefit },
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.benefitsdetail);

    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    useEffect(() => {
        if (benefit) {
            const aa = benefit;
            debugger;
            setGetDateBenefit({ start_date: benefit.start_date, end_date: benefit.end_date })
        }
    }, [benefit])

    useEffect(() => {
        setSelectedData([]); //buat disabled button toolbar
    }, [dispatch]);

    useEffect(() => {
        setSelectedIdBenefit(parseInt(id));
    }, [id]);

    useEffect(() => {

        if (options.hasOwnProperty('user_id')) {
            const updatedOptions = {
                ...options,
                user_id: actionType === ACTION_TYPE.EDITED ? optionsTemporary.user_id : optionsTemporary.user_id.filter(user => allUserNotAdded.some(notAddedUser => notAddedUser.id === user.id))
            };

            setOptions(updatedOptions);
        }
    }, [actionType, allUserNotAdded]);

    useEffect(() => {
        if (selectedIdBenefit) {
            navigate(`/human-resources/benefit/detail/${selectedIdBenefit}/meal_allowance`);
            dispatch(
                getBenefitDetails({
                    token,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    id: selectedIdBenefit
                })
            );
        }
    }, [selectedIdBenefit, token]);

    const resetFormValues = () => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            deduction_names: [],
        }));
        setFormValues(
            Object.assign({}, initialFormValues("fields"), {
                id: null,
                type: typeBenefit,
            })
        );
    };

    /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

    const initialFormValues = (type = "fields") => {
        if (type === "columns") {
            return columns.reduce(
                (acc, field) => ({ ...acc, [field.field]: "" }),
                {}
            );
        } else {
            return fields.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
            );
        }
    };

    const initialErrors = fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: "" }),
        {}
    );

    const [formValues, setFormValues] = useState(
        Object.assign({}, initialFormValues("fields"), {
            id: null,
            type: typeBenefit,
        })
    );
    const [errors, setErrors] = useState(initialErrors);

    const handleChange = ({ target: { value } }) => {
        if (value && value !== -1) {
            setSelectedIdBenefit(parseInt(value));
        } else {
            setSelectedIdBenefit(parseInt(id));
        }
        // alert("handleChange Working, tinggal logicnya");
    };

    useEffect(() => {
        setIsFetchLoading(true);

        /* ------------------------ Fetch Options on Columns ------------------------ */

        const fetchOptions = async (apiEndpoint, name) => {
            try {
                const response = await axios.get(apiEndpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const modifiedData = response.data.data.map((item) => {
                    if (item.hasOwnProperty("code")) {
                        return {
                            ...item,
                            name: `${item.code} - (${item.description})`,
                        };
                    } else if (item.hasOwnProperty("fullname")) {
                        return {
                            ...item,
                            name: item.fullname,
                        };
                    } else {
                        return item;
                    }
                });
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [name]: modifiedData,
                }));
                setOptionsTemporary((prevOptions) => ({
                    ...prevOptions,
                    [name]: modifiedData,
                }));

                setIsFetchLoading(false);
            } catch (error) {
                console.error(`Error fetching ${name} options:`, error);
            }
        };

        fields.forEach((field) => {
            const { name, apiEndpoint } = field;
            if (apiEndpoint) {
                fetchOptions(apiEndpoint, name);
            }
        });

        /* ------------------------- Fetch all date allowance ------------------------- */

        axios
            .get("/master/all-date-allowance", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                // Check if the response data has the 'fullname' property
                const modifiedData = response.data.data.map((item) => {
                    if (item.hasOwnProperty("fullname")) {
                        return {
                            ...item,
                            name: item.fullname,
                        };
                    } else {
                        return item;
                    }
                });

                setOptions((prevOptions) => ({
                    ...prevOptions,
                    all_date_allowance: modifiedData,
                }));
                setOptionsTemporary((prevOptions) => ({
                    ...prevOptions,
                    all_date_allowance: modifiedData,
                }));
            })
            .catch((error) => {
                console.error("Error fetching service categories:", error);
            });
    }, [token, id]);

    useEffect(() => {
        console.log(errors, "errors,");
        // console.log(initialFormValues(), "asdad");
    }, [formValues, errors]);

    useEffect(() => {
        const filteredRows = rows.filter((row) => selectedData.includes(row.id));
        // console.log(filteredRows, "filteredRows");
    }, [selectedData]);

    useEffect(() => {
        if (!search) {
            _search();
        }
    }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

    function _search() {

        if (selectedIdBenefit) {
            dispatch(
                getBenefitDetails({
                    token,
                    page,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    id: selectedIdBenefit
                })
            );
        }
    }

    useEffect(() => {
        // console.log(sortModel, "newSortModel");
        if (sortModel && sortModel.length) {
            const { field, sort } = sortModel[0];

            // Find the corresponding column object
            const column = columns.find((col) => col.field === field);

            setSortBy(column.fieldSortable || field);
            setSortDirection(sort);
        }
    }, [sortModel]);

    useEffect(() => {
        if (isActive === true) {
            dispatch(
                getBenefitDetails({
                    token,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    id: selectedIdBenefit
                })
            );
        }
    }, [isActive]);

    const setFormValuesFromSelectedData = () => {
        const data = rows.filter((row) => selectedData.includes(row.id))[0];
        for (const key in data) {
            let value;

            if (key === "start_date") {
                value = getDateBenefit.start_date
            } else if (key === "end_date") {
                value = getDateBenefit.end_date
            } else if (key === "deduction_names") {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    deduction_names: data[key],
                }));
            } else {
                value = data[key];
            }

            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [key]: value,
                employee_name: data.user ?.fullname || prevFormValues.employee_name, // Update employee_name
            }));
        }
    };

    const isFormFilled = () => {
        // return Object.values(formValues).every((value) => value.trim() !== "");
        return Object.values(formValues).some((value) => value.trim() !== "");
    };

    const handleFormModal = async (actionType = ACTION_TYPE.ADD) => {
        setActionType(actionType);
        setFormModal(true);
        setErrors(initialErrors);
        setEmployeeNameDisabled(false)
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
            setTitle("Edit Meal Allowance");
            setFormValuesFromSelectedData();
            setEmployeeNameDisabled(true)
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            setTitle("Delete");
            const data = rows.filter((row) => selectedData.includes(row.id));
            setUserDeleted(data.map((row) => row.user ?.fullname).join(", "));
        } else {
            resetFormValues();
            setTitle("Add Meal Allowance");
            /* ------------------------- Fetch employee not add on this allowance ------------------------- */
            if (selectedIdBenefit) {
                axios
                    .get(`/benefits/all-user-not-added/${selectedIdBenefit}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        setAllUserNotAdded(response.data.data)
                    })
                    .catch((error) => {
                        console.error("Error fetching service categories:", error);
                    });
            }
        }
    };

    const handleCloseFormModal = (important = false) => {
        if (!important) {
            if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
                if (isFormFilled) setConfirmModal(true);
            } else {
                setFormModal(false);
            }
        } else {
            setFormModal(false);
        }
    };

    const handleOnConfirmCancelModal = () => {
        setConfirmModal(false);
    };

    const handleOnCloseConfirmCancelModal = () => {
        setConfirmModal(false);
        setFormModal(false);
        resetFormValues();
        // setSelectedData([]); //buat disabled button toolbar
    };

    const handleExitedModal = () => {
        resetFormValues();
    };

    const handleAddRow = () => {
        formValues.benefit_id = selectedIdBenefit;
        dispatch(addPayBenefitDetail(token, formValues));
    };

    const handleEditRow = () => {
        dispatch(
            editPayBenefitDetail(
                token,
                formValues,
                formValues.id
            )
        );
    };

    const handleDeleteRow = () => {
        const data = rows.filter((row) => selectedData.includes(row.id));
        const ids = data.map((row) => row.id);
        dispatch(deleteBenefits(token, { data: { ids } }, true));
    };

    /* -------------------------------------------------------------------------- */
    /*                                   ACTION                                   */
    /* -------------------------------------------------------------------------- */

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        // console.log(name, checked);
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    const handleInputBlur = (event) => {
        const { name } = event.target;
        validateField(name);
    };

    const validateField = (fieldName) => {
        const field = fields.find((field) => field.name === fieldName);
        const newErrors = { ...errors };

        if (field) {
            const { name, required, validation, errorMessage, label, type } = field;

            if (
                required &&
                (String(formValues[name]).trim() === "" ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
            ) {
                newErrors[name] = `${field.label} is required`;
            } else if (validation && !validation(formValues[name])) {
                newErrors[name] = errorMessage || `${label} is invalid`;
            } else {
                newErrors[name] = "";
            }

            setErrors(newErrors);
            console.log(newErrors, "newErrors");
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...initialErrors };

        fields.forEach((field) => {
            const { name, required, validation, errorMessage, type } = field;

            if (
                required &&
                (String(formValues[name]).trim() === "" ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
                    formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
            ) {
                newErrors[name] = `${field.label} is required`;
                valid = false;
            }

            if (formValues[name] &&
                formValues[name].length && validation && !validation(formValues[name])) {
                newErrors[name] = errorMessage || `${field.label} is invalid`;
                valid = false;
            }
        });

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formValues);
        if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            handleDeleteRow();
            handleCloseFormModal(true);
            setSelectedData([]);
        } else {
            if (validateForm()) {
                if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
                    handleEditRow();
                    handleCloseFormModal(true);
                } else if (actionType === ACTION_TYPE.ADD) {
                    handleAddRow();
                    handleCloseFormModal(true);
                }
                setSelectedData([]);
            }
        }
    };

    const handleSortModelChange = (newSortModel) => {
        // if (newSortModel && newSortModel.length) {
        //   const { field, sort } = newSortModel[0];
        setSortModel(newSortModel);
        // setSortBy(field);
        // setSortDirection(sort);
        // }
    };

    /* -------------------------------------------------------------------------- */
    /*                                    FORM                                    */
    /* -------------------------------------------------------------------------- */

    const renderFormContent = () => {

        if (
            (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
            (!selectedData.length && actionType === ACTION_TYPE.ADD)
        ) {
            return (
                <Grid container spacing={1}>
                    {fields.map((field) => {

                        // # Old
                        // const fieldOptions = Array.isArray(options[field.name])
                        //   ? [
                        //     field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                        //       id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                        //       // name: `Select ${field.label}`,
                        //       name: `Choose Option`,
                        //     },
                        //     ...options[field.name],
                        //   ]
                        //   : [];

                        // # New
                        let optionsData;
                        if (Array.isArray(options[field.name])) {
                            const isRequired = field.required &&
                                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                                    name: "Choose Option",
                                };

                            if (isRequired) {
                                optionsData = [
                                    // field.required &&
                                    //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                                    //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                                    //     name: "Choose Option",
                                    //   },
                                    ...options[field.name],
                                ];
                            } else {
                                optionsData = [...options[field.name]];
                            }
                        }

                        const fieldOptions = Array.isArray(options[field.name])
                            ? optionsData
                            : [];

                        let fieldComponent = null;


                        switch (field.type) {
                            case INPUT_TYPE.TEXT:
                            case INPUT_TYPE.NUMBER:
                            case INPUT_TYPE.PHONE_NUMBER:
                            case INPUT_TYPE.POINT:
                            case INPUT_TYPE.CURRENCY:
                                fieldComponent = (
                                    <TextInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.TEXTAREA:
                                fieldComponent = (
                                    <TextArea
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        tip={field.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_ONE:
                                fieldComponent = (
                                    <SelectOne
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                                fieldComponent = (
                                    <SelectOneAutocomplete
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_MULTIPLE:
                                fieldComponent = (
                                    <SelectMultiple
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                                fieldComponent = (
                                    <SelectMultipleAutocomplete
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                        fieldOptions={fieldOptions}
                                        tip={field ?.tip}
                                    />
                                );
                                break;
                            case INPUT_TYPE.DATE:
                                fieldComponent = (
                                    <DatePickerInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                    />
                                );
                                break;
                            case INPUT_TYPE.TIME:
                                fieldComponent = (
                                    <TimePickerInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                    />
                                );
                                break;
                            default:
                                fieldComponent = null;
                        }

                        return (
                            <Grid
                                item
                                xs={field.gridWidth || 12}
                                key={field.name}
                                sx={{ paddingTop: "unset !important" }}
                            >
                                {fieldComponent}
                            </Grid>
                        );
                    })}
                </Grid>
            );
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            return <ConfirmDelete userDeleted={userDeleted} />;
        }

    };

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => handleCloseFormModal()}
                >
                    Cancel
        </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit"
                >
                    {actionType === ACTION_TYPE.EDITED ? "Update" : "Save"}
                </Button>
            </Box>
        );
    };

    /* -------------------------------------------------------------------------- */
    /*                                  DATATABLE                                 */
    /* -------------------------------------------------------------------------- */
    // useEffect(() => {
    //   if (
    //     dateFilter === ACTION_TYPE.ALL_DATE ||
    //     (dateFilter === "custom_date" && period)
    //   ) {
    //     dispatch(
    //       getBenefitDetails({
    //         token,
    //         page,
    //         limit,
    //         search,
    //         sortDirection,
    //         sortBy,
    //         id: selectedIdBenefit
    //       })
    //     );
    //   } else {
    //     setSelectedData([]);
    //   }
    // }, [period, dateFilter]);

    useEffect(() => {
        if (selectedIdBenefit) {
            dispatch(
                getBenefitDetails({
                    token,
                    page,
                    limit,
                    search,
                    sortDirection,
                    sortBy,
                    id: selectedIdBenefit
                })
            );
        }
    }, [sortDirection, sortBy]);

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getBenefitDetails({
                token,
                page,
                limit,
                search,
                sortDirection,
                sortBy,
                id: selectedIdBenefit
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getBenefitDetails({
                token,
                limit,
                search,
                sortDirection,
                sortBy,
                id: selectedIdBenefit
            })
        );
    }

    const handlePageChange = (newPage) => {
        setPage(newPage + 1);
        setPageDB(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        // console.log(newLimit);
        setPage(1);
        setPageDB(1);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const ModifyToolbarRight = () => {
        return (
            <span>
                <Button
                    size="medium"
                    color="primary"
                    sx={{
                        ...btnTableToolbar,
                        borderRadius: "5px !important",
                        backgroundColor: "primary.main",
                        "&:hover": {
                            backgroundColor: "primary.dark",
                        },
                    }}
                    onClick={() => {
                        window.open(
                            process.env.REACT_APP_API_URL +
                            `/api/benefits/export-detail/${id}`,
                            "_blank"
                        );
                    }}
                >
                    <img
                        src={exportIcon}
                        style={{
                            ...imgBtnToolbar,
                            marginRight: 5,
                        }}
                    />
                    Export
        </Button>
            </span>
        );
    };

    const toolBarLeft = () => {
        return (
            <div style={{ ...alignItemsCenter, gap: "1rem" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bolder', paddingLeft: ".5rem", }}>{benefit.date_custom}</Typography>
                <StatusBadge
                    bgColor={benefit.status === "Paid" ? "#D2F0CC" : "#FFF1CF"}
                    colors={benefit.status === "Paid" ? "#1DB200" : "#FFBA10"}
                >
                    {benefit.status}
                </StatusBadge>
            </div>
        );
    };

    const Analytics = () => {
        return (
            <>
                {/*isLoading == true || isFetchLoading === true*/}
                {isLoading ? (
                    <FacebookCircularProgress />
                ) : (
                        <Box>
                            {toolBarLeft()}
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "5rem",
                                    "> div": {
                                        flexGrow: "1",
                                        gap: "0.3rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        "& span": {
                                            fontSize: "1.2rem",
                                        },
                                        "& .count": {
                                            fontWeight: "bold",
                                        },
                                    },
                                    "> div:first-child": {
                                        paddingLeft: ".5rem",
                                    },
                                    "> div:last-child": {
                                        paddingRight: ".5rem",
                                    },
                                }}
                            >
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                                        Total Employee
              </Typography>
                                    <span className="count">{analytic.total_employe}</span>
                                </Box>
                                {/* <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                                        Total Meal Allowances
              </Typography>
                                    <span className="count">{analytic.total}</span>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                                        Deduction
              </Typography>
                                    <span className="count">{analytic.total_deduction}</span>
                                </Box> */}
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                                        Total Pay
              </Typography>
                                    <span className="count">{analytic.total_pay}</span>
                                </Box>
                            </Box>
                        </Box>
                    )}
            </>
        );
    };


    return (
        <>
            {/* /* -------------------------------------------------------------------------- */
      /*                           ANALYTIC & FILTER                                   */
      /* -------------------------------------------------------------------------- */}

            <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                <Grid item xs={0.7} sx={{ ...alignItemsCenter }}>
                    <Button
                        variant="contained"
                        disableElevation
                        sx={{
                            backgroundColor: (theme) => theme.palette.primary.main,
                            ...removeButton,
                        }}
                        onClick={() => navigate("/human-resources/benefit")}
                    >
                        <img src={ArrowBackWhiteIcon} />
                    </Button>
                </Grid>

                <Grid item xs={11.3}>
                    <Card
                        style={{
                            boxShadow: "unset",
                            borderRadius,
                            border: "1px solid #ddd",
                        }}
                    >
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Analytics />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
            <Card
                style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
            >
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                placeholder="Search employees name"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <img
                                            src={SearchIconBlack}
                                            style={{ width: "20px", margin: " 0 8px 0 0" }}
                                        />
                                    ),
                                }}
                                sx={{
                                    borderRadius,
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ height: "100%" }}
                                onClick={_search}
                            // onClick={() => alert("Under Construction")}
                            >
                                <img src={SearchIcon} />
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
            <Card
                style={{
                    marginTop: "1rem",
                    boxShadow: "unset",
                    borderRadius,
                    border: "1px solid #ddd",
                }}
            >
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Allowance"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={pageDB}
                        handleFormModal={handleFormModal}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        selectedData={selectedData}
                        handleSelectionChange={handleSelectionChange}
                        theme={theme}
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        showDeleteToolbar={false}
                        additionalProps={{
                            currentPath: "/human-resources/benefit",
                        }}
                        componentModifyToolbarRight={ModifyToolbarRight}
                    />
                </CardContent>
            </Card>
            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={handleSubmit}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                onConfirmModal={handleOnConfirmCancelModal}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
        </>
    );
};

export default DetailMealAllowance;
