//@ts-check;

/**
 * @typedef {'ADD'|'EDIT'|'DELETE'|'GET'|'DETAIL'|DETAIL_FOR_EDIT} actionType
 */

import { AxiosError } from "axios";
import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
  SELECT_OPTION,
} from "../../../../util/function";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import { logout } from "../../../auth/clients/login/actions";
import { Pagination, urlHeaderFiletype } from "./classType";
import {
  ADD_PERFORMA_INVOICE_FAIL,
  ADD_PERFORMA_INVOICE_SUCCESS,
  DELETE_PERFORMA_INVOICE_FAIL,
  DELETE_PERFORMA_INVOICE_SUCCESS,
  EDIT_PERFORMA_INVOICE_FAIL,
  EDIT_PERFORMA_INVOICE_SUCCESS,
  GET_DETAIL_FOR_EDIT_FAIL,
  GET_DETAIL_FOR_EDIT_SUCCESS,
  GET_DETAIL_PERFORMA_INVOICE_FAIL,
  GET_DETAIL_PERFORMA_INVOICE_SUCCESS,
  GET_PERFORMA_INVOICE_FAIL,
  GET_PERFORMA_INVOICE_SUCCESS,
  SET_ID_EDITED_DOCUMENT,
} from "./state";
import { ConstructionOutlined } from "@mui/icons-material";

/* ==================================================================================== */
/*                                         HELPER                                       */
/* ==================================================================================== */

/**
 * @param {AxiosError} errorResponse
 * @param {import("@reduxjs/toolkit").Dispatch} dispatch
 * @param {FunctionConstructor} handler
 */
function failHendler(errorResponse, dispatch, handler) {
  if (
    errorResponse.response !== undefined &&
    errorResponse.response.data !== undefined
  ) {
    if (errorResponse.response.status === 401) {
      dispatch(logout());
    } else {
      dispatch(handler(errorResponse.response.data.message));
      dispatch(
        RunModal(
          "FAILED",
          formatNetworkErrorMessage(errorResponse.response.data.message)
        )
      );
    }
  } else {
    dispatch(handler(formatNetworkErrorMessage(errorResponse.message)));
    dispatch(RunModal("FAILED", errorResponse.message));
  }
}

/**
 * @param {actionType} action
 * @param {import("@reduxjs/toolkit").Dispatch} dispacth
 */
function runModalByAction(action, dispacth) {
  switch (action) {
    case "ADD":
      dispacth(RunModal("SAVED", "Proforma invoice"));
      break;
    case "EDIT":
      dispacth(RunModal("EDITED", "Proforma invoice"));
      break;
    case "DELETE":
      dispacth(RunModal("DELETED", "Proforma invoice"));
      break;
  }
}

/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {import("@reduxjs/toolkit").Dispatch} dispatch
 * @param {Function} successHandler
 * @param {Function} failHandler
 * @param {actionType} actionType
 */
function responseHandler(
  response,
  dispatch,
  successHandler,
  failHandler,
  actionType
) {
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    runModalByAction(actionType, dispatch);
    if (actionType === "GET") {
      dispatch(successHandler(response.data.data, response.data));
    } else if (actionType === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      dispatch(successHandler(response.data.message));
    }
  } else if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === false
  ) {
    if (actionType === "GET") {
      dispatch(successHandler([], null));
    } else {
      dispatch(failHandler(response.data.message));
    }
  } else {
    dispatch(failHandler(response.message));
  }
}

const PI_URI = "/proforma-invoices";

/* ==================================================================================== */
/*                                         GET                                          */
/* ==================================================================================== */

/**
 * get performa invoice param
 *@typedef {object} ParamGet
 * @property {string} token
 * @property {number} [page]
 * @property {number} [limit]
 * @property {string|number} [search]
 * @property {string} [status]
 * @property {string} [category]
 * @property {string} [sortDirection]
 * @property {string} [sortBy]
 * @property {string} [searchBy]
 */

/** @param {ParamGet} param0 */
export const getPerformaInvoice = ({
  token,
  page = 1,
  limit = 10,
  search = "",
  status,
  category,
  sortDirection,
  sortBy,
  searchBy,
}) => {
  return async (dispatch, state) => {
    try {
      dispatch(RequestLoading());
      if (category === ACTION_TYPE.DEFAULT_TYPE) {
        category = null;
      }
      if (status === ACTION_TYPE.DEFAULT_STATUS || status === "ALL STATUS") {
        status = null;
      }
      const requestUrl = urlRequest({
        url: PI_URI,
        page,
        limit,
        search: null,
        searchBy: search === "" ? null : searchBy,
        searchValue: search,
        categoryId:
          category === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          category === ACTION_TYPE.ALL_STATUS
            ? null
            : category,
        status:
          status === ACTION_TYPE.ALL_STATUS ||
          status === ACTION_TYPE.DEFAULT_STATUS
            ? null
            : status,
        sortBy,
        sortDirection,
      });
      const response = await axios.get(requestUrl, headersAPIToken(token));
      responseHandler(
        response,
        dispatch,
        getPerformaInvoiceSuccess,
        getPerformaInvoiceFail,
        "GET"
      );
    } catch (error) {
      failHendler(error, dispatch, getPerformaInvoiceFail);
    }
  };
};

const getPerformaInvoiceSuccess = (data, resPagination) => {
  let pagination = null;
  if (resPagination) {
    pagination = {};
    pagination.limit = resPagination.limit;
    pagination.page = resPagination.page;
    pagination.totalData = resPagination.total_data;
    pagination.totalPage = resPagination.total_page;
  }
  return {
    type: GET_PERFORMA_INVOICE_SUCCESS,
    payload: data,
    pagination,
  };

  // :
  //     resPagination === null
  //       ? null
  //       : {
  //           page: resPagination.page,
  //           limit: parseInt(resPagination.limit),
  //           totalData: resPagination.total_data,
  //           totalPage: resPagination.total_page,
  //         },
};

const getPerformaInvoiceFail = (errorMessage) => {
  return {
    type: GET_PERFORMA_INVOICE_FAIL,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* ==================================================================================== */
/*                                           ADD                                        */
/* ==================================================================================== */

export const addPerformaInvoice = (token, payload) => {
  return async (dispatch, state) => {
    try {
      dispatch(RequestLoading());
      const response = await axios.post(
        PI_URI,
        payload,
        urlHeaderFiletype(token)
      );
      responseHandler(
        response,
        dispatch,
        addPerformaInvoiceSuccess,
        addPerformaInvoiceFail,
        "ADD"
      );
      return new Promise((resolve) => resolve(1));
    } catch (error) {
      failHendler(error, dispatch, addPerformaInvoiceFail);
    }
  };
};

const addPerformaInvoiceSuccess = (message) => ({
  type: ADD_PERFORMA_INVOICE_SUCCESS,
  payload: message,
});

const addPerformaInvoiceFail = (message) => ({
  type: ADD_PERFORMA_INVOICE_FAIL,
  payload: formatNetworkErrorMessage(message),
});

/* ==================================================================================== */
/*                                             EDIT                                     */
/* ==================================================================================== */

/**
 *
 * @param {string} token
 * @param {object} payload
 * @param {number} id
 * @returns
 */
export const editPerformaInvoice = (token, payload, id) => {
  return async (dispacth, state) => {
    try {
      dispacth(RequestLoading());
      const response = await axios.patch(
        PI_URI.concat("/", id),
        payload,
        headersAPIToken(token)
      );
      responseHandler(
        response,
        dispacth,
        editPerformaInvoiceSuccess,
        editPerformaInvoiceFail,
        "EDIT"
      );
      dispacth(detailPerformaInvoice(token, id));
      return new Promise((resolve) => resolve(1));
    } catch (error) {
      failHendler(error, dispacth, editPerformaInvoiceFail);
    }
  };
};

const editPerformaInvoiceSuccess = (message) => ({
  type: EDIT_PERFORMA_INVOICE_SUCCESS,
  payload: message,
});

const editPerformaInvoiceFail = (message) => ({
  type: EDIT_PERFORMA_INVOICE_FAIL,
  payload: message,
});

/* ==================================================================================== */
/*                                         DELETE                                       */
/* ==================================================================================== */

/**
 *
 * @param {string} token
 * @param {Array.<number>} ids
 * @returns
 */
export const deletePerformaInvoice = (token, ids) => {
  return async (dispatch, state) => {
    try {
      let joinIds = ids.join(",");
      const localUri = PI_URI.concat("/", joinIds);
      dispatch(RequestLoading());
      const respons = await axios.delete(localUri, headersAPIToken(token));
      responseHandler(
        respons,
        dispatch,
        deletePerformaInvoiceSuccess,
        deletePerformaInvoiceFail,
        "DELETE"
      );
    } catch (error) {
      failHendler(error, dispatch, deletePerformaInvoiceFail);
    }
  };
};

const deletePerformaInvoiceSuccess = (message) => ({
  type: DELETE_PERFORMA_INVOICE_SUCCESS,
  payload: message,
});
const deletePerformaInvoiceFail = (message) => ({
  type: DELETE_PERFORMA_INVOICE_FAIL,
  payload: message,
});

/* ==================================================================================== */
/*                                         DETAIL                                       */
/* ==================================================================================== */

/**
 *
 * @param {string} token - token string
 * @param {number|string} idProformaInvoice - id document proforma invoice
 * @returns
 */
export const detailPerformaInvoice = (token, idProformaInvoice) => {
  return async (dispatch) => {
    try {
      dispatch(RequestLoading());
      let localUri = PI_URI.concat("/", idProformaInvoice);
      const response = await axios.get(localUri, headersAPIToken(token));
      responseHandler(
        response,
        dispatch,
        detailProformaInvoiceSuccess,
        detailProformaInvoiceFail,
        "DETAIL"
      );
    } catch (error) {
      failHendler(error, dispatch, detailProformaInvoiceFail);
    }
  };
};

function detailProformaInvoiceSuccess(payload) {
  return {
    type: GET_DETAIL_PERFORMA_INVOICE_SUCCESS,
    payload: payload,
  };
}

function detailProformaInvoiceFail(payload) {
  return {
    type: GET_DETAIL_PERFORMA_INVOICE_FAIL,
    payload: payload,
  };
}

export const setEditId = (id) => ({
  type: SET_ID_EDITED_DOCUMENT,
  payload: id,
});

/* ==================================================================================== */
/*                                   GET FOR EDIT                                       */
/* ==================================================================================== */

/**
 *
 * @param {string} token - string token
 * @param {number|string} id - ID document
 * @returns
 */
export const getDetailForEditProformaInvoice = (token, id) => {
  return function (dispatch) {
    let urlGetEdit = `/proforma-invoices/${String(id)}/edit`;
    axios
      .get(urlGetEdit, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          getDetailForEditSuccess,
          getDetailForEditFail,
          "DETAIL"
        );
      })
      .catch((error) => {
        failHendler(error, dispatch, getDetailForEditFail);
      });
  };
};

/**
 * to set detail edit from response API and also use to clear detail edit
 * @param {any} payload
 * @returns
 */
export function getDetailForEditSuccess(payload) {
  return {
    type: GET_DETAIL_FOR_EDIT_SUCCESS,
    payload,
  };
}

function getDetailForEditFail(payload) {
  return {
    type: GET_DETAIL_FOR_EDIT_FAIL,
    payload,
  };
}
