import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import {
  REQUEST_LOADING,
  RequestLoading,
  RunModal,
} from "../../../../util/global_state";
import {
  ADD_PAYMENT_REQUEST_FAILED,
  ADD_PAYMENT_REQUEST_SUCCESS,
  CLEAR_DATA_DETAIL_PAYMENT_REQUEST,
  CLEAR_DATA_PAYMENT_REQUEST,
  DELETE_PAYMENT_REQUEST_FAILED,
  DELETE_PAYMENT_REQUEST_SUCCESS,
  EDIT_PAYMENT_REQUEST_FAILED,
  EDIT_PAYMENT_REQUEST_SUCCESS,
  GET_PAYMENT_REQUEST_DETAIL_FAILED,
  GET_PAYMENT_REQUEST_DETAIL_SUCCESS,
  GET_PAYMENT_REQUEST_FAILED,
  GET_PAYMENT_REQUEST_SUCCESS,
  MARK_DETAIL_FOR_EDIT,
  STOP_LOADING,
} from "./state";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

import { ACTION_APPROVE } from "../parts/imutable_state";
const PR_URI = "payment-requests";

const stopLoading = () => ({ type: STOP_LOADING, payload: false });
/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getPaymentRequest = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  category = null,
  sortDirection = null,
  sortBy = null,
}) => {
  return (dispatch) => {
    dispatch(clearData());
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: PR_URI,
          page,
          limit,
          search,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          categoryTimeOff:
            category == ACTION_TYPE.DEFAULT_TYPE ? null : category,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getPaymentRequestSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getPaymentRequestSuccess([], null));
        } else {
          dispatch(getPaymentRequesFail(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            dispatch(getPaymentRequesFail(error.response.data.message));
          }
        } else {
          dispatch(getPaymentRequesFail(error.message));
        }
      });
  };
};

const getPaymentRequestSuccess = (data, pagination) => {
  return {
    type: GET_PAYMENT_REQUEST_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: parseInt(pagination.limit),
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getPaymentRequesFail = (errorMessage) => {
  return {
    type: GET_PAYMENT_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addPaymentRequest = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    return axios
      .post(PR_URI, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      })
      .then((response) => {
        console.log(response, "respone add ");
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "Payment request"));
          dispatch(addPaymentRequestSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(RunModal("FAILED", response.data.message));
          dispatch(addPaymentRequestFail(response.data.message));
        } else {
          dispatch(addPaymentRequestFail(response.data.message));
        }
        // console.log(response);
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            dispatch(addPaymentRequestFail(error.response.data.message));
          }
        } else {
          dispatch(addPaymentRequestFail(error.message));
        }
      });
  };
};

const addPaymentRequestSuccess = (message) => {
  return {
    type: ADD_PAYMENT_REQUEST_SUCCESS,
    payload: message,
  };
};

const addPaymentRequestFail = (message) => {
  return {
    type: ADD_PAYMENT_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

/**
 *
 * @param {string} token - token Auth
 * @param {ObjectConstructor} formData - the payload edit
 * @param {number} id - id of document want to edit
 * @returns
 */
export const editPaymentRequest = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`${PR_URI}/${id}/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "payment-request"));
          dispatch(editPaymentRequestSucess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editPaymentRequestFail(response.data.message));
        } else {
          dispatch(editPaymentRequestFail(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            dispatch(editPaymentRequestFail(error.response.data.message));
          }
        } else {
          dispatch(editPaymentRequestFail(error.message));
        }
      });
  };
};

const editPaymentRequestSucess = (message) => {
  return {
    type: EDIT_PAYMENT_REQUEST_SUCCESS,
    payload: message,
  };
};

const editPaymentRequestFail = (message) => {
  return {
    type: EDIT_PAYMENT_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

/**
 *
 * @param {string} token
 * @param {number[]} listId
 * @param {boolean} toParam
 * @returns
 */
export const deletePaymentRequest = (token, listId, toParam) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    let param = toParam ? "/".concat(listId.join(",")) : "";
    axios
      .delete(PR_URI + param, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "payment-request"));
          dispatch(deletePaymentRequestSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(RunModal("FAILED", response.data.message));
          dispatch(deletePaymentRequestFailed(response.data.message));
        } else {
          dispatch(deletePaymentRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            dispatch(deletePaymentRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(deletePaymentRequestFailed(error.message));
        }
      });
  };
};

const deletePaymentRequestSuccess = (message) => {
  return {
    type: DELETE_PAYMENT_REQUEST_SUCCESS,
    payload: message,
  };
};

const deletePaymentRequestFailed = (message) => {
  return {
    type: DELETE_PAYMENT_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                  GET DETAIL                                */
/* -------------------------------------------------------------------------- */
export const getDetailPaymentRequest = (token, paymentReqId = null) => {
  return async (dispatch) => {
    let detailUri = PR_URI.concat("/", paymentReqId, "/detail");
    try {
      dispatch(RequestLoading());
      let response = await axios.get(detailUri, headersAPIToken(token));
      if (response.status === 200 && response.data.status === true) {
        dispatch(getDetailPaymentRequestSuccess(response.data.data));
      } else if (response.status === 200 && response.data.status === false) {
        //* Do something when data fail to get
      } else {
        dispatch(getDetailPaymentRequestFailed(response.data.message));
      }
    } catch (error) {
      if (error.response !== undefined && error.response.data !== undefined) {
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          dispatch(getDetailPaymentRequestFailed(error.response.data.message));
        }
      } else {
        dispatch(getDetailPaymentRequestFailed(error.message));
      }
    }
  };
};

const getDetailPaymentRequestSuccess = (payloadData) => ({
  type: GET_PAYMENT_REQUEST_DETAIL_SUCCESS,
  payload: payloadData,
});

const getDetailPaymentRequestFailed = (message) => ({
  type: GET_PAYMENT_REQUEST_DETAIL_FAILED,
  payload: message,
});

/* -------------------------------------------------------------------------- */
/*                                CLEAR DETAIL                                */
/* -------------------------------------------------------------------------- */

export const clearDetailPaymentRequest = () => {
  return {
    type: CLEAR_DATA_DETAIL_PAYMENT_REQUEST,
  };
};

export function clearData() {
  return { type: CLEAR_DATA_PAYMENT_REQUEST };
}

/**
 *
 * @param {number} detailId
 */
export const markDetailForEdit = (detailId) => {
  return {
    type: MARK_DETAIL_FOR_EDIT,
    payload: detailId,
  };
};

/* -------------------------------------------------------------------------- */
/*                               SEND APPROVAL                                */
/* -------------------------------------------------------------------------- */

/**
 *
 * @param {object} payload
 * @param {string} action
 * @param {number} id
 * @param {'APPROVED'|'REJECTED'} action
 * @returns
 */
export const sendApproval = (token, payload, id, action) => {
  return async (dispatch) => {
    try {
      const localUrl = "payment-requests/".concat(id, "/approval-general");
      dispatch(RequestLoading());
      const response = await axios.post(
        localUrl,
        payload,
        headersAPIToken(token)
      );
      if (response.status === 200 || response.status === 201) {
        if (response.data.status === true) {
          dispatch(
            RunModal(
              action === ACTION_APPROVE.APPROVE ? "APPROVED" : "REJECTED",
              "Payment request"
            )
          );
          // for stop loading
          dispatch(stopLoading());
        } else if (response.data.status === false) {
          dispatch(RunModal("FAILED", response.data.message));
        }
      }
    } catch (error) {
      if (error.response !== undefined && error.response.data !== undefined) {
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          dispatch(getDetailPaymentRequestFailed(error.response.data.message));
        }
      } else {
        dispatch(getDetailPaymentRequestFailed(error.message));
      }
    }
  };
};
