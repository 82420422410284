import { combineReducers } from "redux";
import directorReducer from "../pages/director/clients/reducer";
import statusReducer from "../pages/status/clients/reducer";
import globalReducer from "../util/global_state";
import servicesReducer from "../pages/services/clients/reducer";
import userlogsReducer from "../pages/user-logs/clients/reducer";
import menusReducer from "../pages/menu/clients/reducer";
import usersReducer from "../pages/users/clients/reducer";
import stagesReducer from "../pages/stages/clients/reducer";
import usersaccessReducer from "../pages/users-acces/clients/reducer";
import authReducer from "../pages/auth/clients/login/reducer";
import otpReducer from "../pages/auth/clients/verif-otp/reducer";
import attachmentReducer from "../pages/attachment/clients/reducer";
import timeOffReducer from "../pages/time-off/clients/reducer";
import employeesReducer from "../pages/employees/clients/reducer";
import cupboardReducer from "../pages/archive/cupboard/clients/reducer";
import archiveReducer from "../pages/archive/archive/clients/reducer";
import requestReducer from "../pages/archive/request/clients/reducer";
import returnReducer from "../pages/archive/return/clients/reducer";
import attendancesReducer from "../pages/attendances/clients/reducer";
import employeeResignationReducer from "../pages/human-resources/employe-resign/clients/reducer";
import employeeTransferReducer from "../pages/human-resources/employe-transfer/clients/reducer";
import activitiesReducer from "../pages/activity/clients/reducer";
import leaveRequestReducer from "../pages/human-resources/leave/clients/reducer";
import leaveRequestDetailReducer from "../pages/human-resources/leave/clients/reducer_detail";
import timeOffRequestReducer from "../pages/human-resources/time-off-request/clients/reducer";
import timeOffRequestDetailReducer from "../pages/human-resources/time-off-request/clients/reducer_detail";
import benefitsReducer from "../pages/benefits/clients/reducer";
import benefitsDetailReducer from "../pages/benefits/clients/reducer_detail";
import groupClientReducer from "../pages/sales-management/client/clients/group_reducer";
import clientsReducer from "../pages/sales-management/client/clients/reducer";
import applicantsReducer from "../pages/sales-management/applicant/clients/reducer";
import salesordersReducer from "../pages/sales-management/sales-order/clients/reducer";
import brandReducer from "../pages/sales-management/brand/clients/reducer";
import salesOrdersDetailReducer from "../pages/sales-management/sales-order/clients/reducer_detail";
import salesquotationsReducer from "../pages/sales-management/sales-quotation/clients/reducer";
import salesQuotationsDetailReducer from "../pages/sales-management/sales-quotation/clients/reducer_detail";
import financeInvoiceReducer from "../pages/finance/invoice/clients/reducer";
import paymentRequestReducer from "../pages/finance/payment-request/clients/reducer";
import paymentRequestDetailReducer from "../pages/finance/payment-request/clients/reducer_detail";
import paymentReducer from "../pages/finance/payment/clients/reducer";
import paymentPerformaInvoiceReducer from "../pages/finance/proforma-invoice/clients/reducer";
import applicationsReducer from "../pages/business-permit/application/clients/reducer";
import registrationsReducer from "../pages/business-permit/registration/clients/reducer";
import applicationsDetailReducer from "../pages/business-permit/application/clients/reducer_detail";
import registrationsDetailReducer from "../pages/business-permit/registration/clients/reducer_detail";
import detailInvoiceReducer from "../pages/finance/invoice/clients/detail-reducer";
import roomNameReducer from "../pages/ga/meeting_room/room_name/clients/reducer";
import bookingSubmissionReducer from "../pages/ga/meeting_room/booking_submission/clients/reducer";
import detailProformaInvoice from "../pages/finance/proforma-invoice/clients/detail_reducer";
import setupRewardPointReducer from "../pages/finance/reward/set-up-reward-point/clients/reducer";
import claimRewardPointReducer from "../pages/finance/reward/claim-reward-point/clients/reducer";
import purchaseRequestReducer from "../pages/ga/purchase_request/clients/reducer";
import assetsReducer from "../pages/ga/asset/client/reducer";
import stockInReducer from "../pages/ga/stock_in/client/reducer";
import stockOutReducer from "../pages/ga/stock_out/client/reducer";
import trademarkRegistrationReducer from "../pages/trademark/registration/client/reducer";
import trademarkApplicationReducer from "../pages/trademark/aplication/client/reducer";

export default combineReducers({
  global: globalReducer,
  auth: authReducer,
  otp: otpReducer,
  directors: directorReducer,
  status: statusReducer,
  services: servicesReducer,
  userlogs: userlogsReducer,
  menus: menusReducer,
  users: usersReducer,
  stages: stagesReducer,
  usersaccess: usersaccessReducer,
  attachments: attachmentReducer,
  timeoffs: timeOffReducer,
  employees: employeesReducer,
  attendances: attendancesReducer,
  activities: activitiesReducer,
  leaveRequest: leaveRequestReducer,
  leaveRequestDetail: leaveRequestDetailReducer,
  timeOffRequest: timeOffRequestReducer,
  timeOffRequestDetail: timeOffRequestDetailReducer,
  benefits: benefitsReducer,
  benefitsdetail: benefitsDetailReducer,
  cupboards: cupboardReducer,
  archive: archiveReducer,
  request: requestReducer,
  return: returnReducer,
  clients: clientsReducer,
  groupclient: groupClientReducer,
  applicants: applicantsReducer,
  salesorders: salesordersReducer,
  brands: brandReducer,
  salesordersdetail: salesOrdersDetailReducer,
  salesquotations: salesquotationsReducer,
  salesquotationsdetail: salesQuotationsDetailReducer,
  applications: applicationsReducer,
  applicationsdetail: applicationsDetailReducer,
  registrations: registrationsReducer,
  registrationsdetail: registrationsDetailReducer,
  financeInvoice: financeInvoiceReducer,
  financeInvoiceDetail: detailInvoiceReducer,
  financePayment: paymentReducer,
  financePaymentRequest: paymentRequestReducer,
  financePaymentRequestDetail: paymentRequestDetailReducer,
  financePerformaInvoice: paymentPerformaInvoiceReducer,
  financeProformaInvoiceDetail: detailProformaInvoice,
  roomName: roomNameReducer,
  bookingSubmission: bookingSubmissionReducer,
  employeeResignation: employeeResignationReducer,
  employeeTransfer: employeeTransferReducer,
  setupRewardPoint: setupRewardPointReducer,
  claimRewardPoint: claimRewardPointReducer,
  purchaseRequest: purchaseRequestReducer,
  asset: assetsReducer,
  stockIn: stockInReducer,
  stockOut: stockOutReducer,
  trademarkRegistration: trademarkRegistrationReducer,
  trademarkApplication: trademarkApplicationReducer,
});
