import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../../util/global_state";
import {
  GET_BOOKING_SUBMISSION_SUCCESS,
  GET_BOOKING_SUBMISSION_FAILED,
  ADD_BOOKING_SUBMISSION_SUCCESS,
  ADD_BOOKING_SUBMISSION_FAILED,
  EDIT_BOOKING_SUBMISSION_SUCCESS,
  EDIT_BOOKING_SUBMISSION_FAILED,
  DELETE_BOOKING_SUBMISSION_FAILED,
  DELETE_BOOKING_SUBMISSION_SUCCESS,
  CANCELED_BOOKING_SUBMISSION_FAILED,
  CANCELED_BOOKING_SUBMISSION_SUCCESS,
} from "./state";
import { logout } from "../../../../auth/clients/login/actions";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getBookingSubmission = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  searchBy = null,
  statusFilter = null,
  sortDirection = null,
  sortBy = null,
  period = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "book_submission_list",
          page,
          limit,
          searchValue,
          searchBy,
          filterDate: period,
          status: statusFilter == ACTION_TYPE.ALL_STATUS ? null : statusFilter,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.status === true) {
          dispatch(getBookingSubmissionSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getBookingSubmissionSuccess([], null));
        } else {
          dispatch(getBookingSubmissionFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getBookingSubmissionFailed(error.response.data.message));
          }
        } else {
          dispatch(getBookingSubmissionFailed(error.message));
        }
      });
  };
};

const getBookingSubmissionSuccess = (data, pagination) => {
  return {
    type: GET_BOOKING_SUBMISSION_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getBookingSubmissionFailed = (errorMessage) => {
  return {
    type: GET_BOOKING_SUBMISSION_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addBookingSubmission = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`book_submission`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "Room Name"));
          dispatch(addBookingSubmissionSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addBookingSubmissionFailed(response.data.message));
        } else {
          dispatch(addBookingSubmissionFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addBookingSubmissionFailed(error.response.data.message));
          }
        } else {
          dispatch(addBookingSubmissionFailed(error.message));
        }
      });
  };
};

const addBookingSubmissionSuccess = (message) => {
  return {
    type: ADD_BOOKING_SUBMISSION_SUCCESS,
    payload: message,
  };
};

const addBookingSubmissionFailed = (message) => {
  return {
    type: ADD_BOOKING_SUBMISSION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editBookingSubmission = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `book_submission/${id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "Room Name"));
          dispatch(editBookingSubmissionSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editBookingSubmissionFailed(response.data.message));
        } else {
          dispatch(editBookingSubmissionFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editBookingSubmissionFailed(error.response.data.message));
          }
        } else {
          dispatch(editBookingSubmissionFailed(error.message));
        }
      });
  };
};

const editBookingSubmissionSuccess = (message) => {
  return {
    type: EDIT_BOOKING_SUBMISSION_SUCCESS,
    payload: message,
  };
};

const editBookingSubmissionFailed = (message) => {
  return {
    type: EDIT_BOOKING_SUBMISSION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteBookingSubmission = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`book_submission${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "Room Name"));
          dispatch(deleteBookingSubmissionSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteBookingSubmissionFailed(response.data.message));
        } else {
          dispatch(deleteBookingSubmissionFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteBookingSubmissionFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteBookingSubmissionFailed(error.message));
        }
      });
  };
};

const deleteBookingSubmissionSuccess = (message) => {
  return {
    type: DELETE_BOOKING_SUBMISSION_SUCCESS,
    payload: message,
  };
};

const deleteBookingSubmissionFailed = (message) => {
  return {
    type: DELETE_BOOKING_SUBMISSION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    CANCELED                                  */
/* -------------------------------------------------------------------------- */

export const canceledBookingSubmission = (token, formData, toParams) => {
  console.log(token);
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ?? "";
    console.log(toParams);
    console.log(params);
    axios
      .post(`book_submission_list/cancel-request${params}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          if (params.includes('End')) {
            dispatch(RunModal("FINISHED", "Booking Submission"));
          } else {
            dispatch(RunModal("CANCELED", "Booking Submission"));
          }
          dispatch(canceledBookingSubmissionSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(canceledBookingSubmissionFailed(response.data.message));
        } else {
          dispatch(canceledBookingSubmissionFailed(response.data.message));
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(canceledBookingSubmissionFailed(error.response.data.message));
          }
        } else {
          dispatch(canceledBookingSubmissionFailed(error.message));
        }
      });
  };
};

const canceledBookingSubmissionSuccess = (message) => {
  return {
    type: CANCELED_BOOKING_SUBMISSION_SUCCESS,
    payload: message,
  };
};

const canceledBookingSubmissionFailed = (message) => {
  return {
    type: CANCELED_BOOKING_SUBMISSION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
