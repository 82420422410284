import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Box, Typography, IconButton, Collapse, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/Lock";
import { confirmDelete, mainColor, secondColor } from "../util/style";

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    // backgroundColor: theme.palette.error.main,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    padding: "0px 1rem",
    color: " red",
    backgroundColor: "#FFCDCC",
  },
  errorIcon: {
    marginRight: theme.spacing(2),
  },
}));

const ErrorAlert = ({ title, message, custom = true }) => {
  const classes = useStyles();

  return (
    <Alert severity="error" className={classes.errorAlert}>
      {!custom ? (
        <div>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </div>
      ) : (
          title
        )}
    </Alert>
  );
};

const AccessRestrictedAlert = () => {
  const classes = useStyles();

  return (
    <Box style={{ ...confirmDelete }}>
      {/* <img src={noDataIMG} alt="Confirmation Delete" /> */}
      <LockIcon sx={{ color: mainColor, fontSize: "4rem" }} />
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "700" }}>
        Access Restricted
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: secondColor, fontStyle: "italic", fontWeight: "400" }}
      >
        You don't have permission to view this data.
      </Typography>
    </Box>
  );
};



const TransitionAlerts = ({isOpen= false, severity = "success", message = "Success" }) => {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}

export { ErrorAlert, AccessRestrictedAlert, TransitionAlerts };
