export const GET_ATTENDANCE_SUCCESS = "GET_ATTENDANCE_SUCCESS";
export const GET_ATTENDANCE_FAILED = "GET_ATTENDANCE_FAILED";
export const ADD_ATTENDANCE_SUCCESS = "ADD_ATTENDANCE_SUCCESS";
export const ADD_ATTENDANCE_FAILED = "ADD_ATTENDANCE_FAILED";
export const EDIT_ATTENDANCE_SUCCESS = "EDIT_ATTENDANCE_SUCCESS";
export const EDIT_ATTENDANCE_FAILED = "EDIT_ATTENDANCE_FAILED";
export const DELETE_ATTENDANCE_SUCCESS = "DELETE_ATTENDANCE_SUCCESS";
export const DELETE_ATTENDANCE_FAILED = "DELETE_ATTENDANCE_FAILED";
export const GET_ATTENDANCE_DETAILS_SUCCESS = "GET_ATTENDANCE_DETAILS_SUCCESS";
export const GET_ATTENDANCE_DETAILS_FAILED = "GET_ATTENDANCE_DETAILS_FAILED";
