import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
} from "./../../../util/style";
import axios from "./../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "./../../../components/Form";
import { addClient, deleteClient, editClient, getClient, getGroupClient,addGroupClient } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import LeftDrawer from "./../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";

const Client = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const ANOTHER_ACTION_TYPE = {
    ADD_NEW_GROUP: "ADD_NEW_GROUP",
    PREVIEW: 'PREVIEW'
  }
  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setClientDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Client");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [type, setType] = useState(ACTION_TYPE.ALL_TYPE);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "code",
      headerName: "Code",
      width: 160,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Client Name",
      width: 260,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <div style={{ color: "blue" }}>
          <a
            // onClick={() => navigate(`detail/${params.row.id}`)}
            onClick={() => handleFormModal(ACTION_TYPE.DETAIL, params.row)}
            style={{ textDecoration: "none", cursor: "grab" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "pic_name",
      headerName: "PIC Name",
      width: 200,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Box sx={{ '& > img': { mr: 1, flexShrink: 0 }, '& > span': { color: '#555' } }}>
          <img
            loading="lazy"
            width="20"
            // srcSet={`https://flagcdn.com/w40/${params.value.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${params.value.code.toLowerCase()}.png`}
            alt=""
          />
          {params.value.name} <span>({params.value.code})</span>
        </Box>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || '';
        return text.length > 52 ? text.slice(0, 49) + '...' : text;
      },
    },
    // {
    //   field: "approval",
    //   headerName: "Approval",
    //   width:180,
    //   disableColumnMenu: true,
    //   sortable: true,
    //   valueGetter: (params) => {
    //     return params.row.approval ? params.row.approval?.fullname : "-";
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 180,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value}> {params.value} </StatusBadge>
      ),
    },
  ];

  const fields = actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP
    ? [
      {
        name: "name",
        type: INPUT_TYPE.TEXT,
        label: "Group Name",
        defaultValue: "",
        required: true,
      }
    ]
    : [
      {
        name: "category",
        type: INPUT_TYPE.RADIO,
        label: "Category",
        defaultValue: [], // Array for selected format values
        options: [
          { id: "Direct Client", name: "Direct Client" },
          { id: "Associate", name: "Associate" },
        ],
        required: true,
        errorMessage: "Please select a category",
      },
      {
        name: "type",
        type: INPUT_TYPE.RADIO,
        label: "Type",
        defaultValue: [], // Array for selected format values
        options: [
          { id: "Individual", name: "Individual" },
          { id: "Company", name: "Company" },
        ],
        required: true,
        errorMessage: "Please select a type",
      },
      {
        name: "name",
        type: INPUT_TYPE.TEXT,
        label: "Client Name",
        defaultValue: "",
        required: true,
      },
      {
        name: "pic_name",
        type: INPUT_TYPE.TEXT,
        label: "PIC Name",
        defaultValue: "",
        required: true,
      },
      {
        name: "country_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY,
        label: "Country",
        apiEndpoint: "/master/countries",
        defaultValue: "",
        required: true,
        
      },
      {
        name: "address",
        type: INPUT_TYPE.TEXTAREA,
        label: "Address",
        defaultValue: "",
        required: true,
      },
      {
        name: "email",
        type: INPUT_TYPE.TEXT,
        label: "Email",
        placeholder: "Input email ( Ops )",
        defaultValue: "",
        required: false,
        validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        errorMessage: "Invalid email address",
      },
      {
        name: "phone",
        type: INPUT_TYPE.NUMBER,
        label: "Phone",
        placeholder: "Input phone ( Ops )",
        defaultValue: "",
        required: false,
      },
      {
        name: "mobile_number",
        type: INPUT_TYPE.NUMBER,
        label: "Mobile Number",
        placeholder: "Input mobile number ( Ops )",
        defaultValue: "",
        required: false,
      },
      {
        name: "group_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Group",
        placeholder: "Select group ( Ops )",
        // apiEndpoint: "/master/groups",
        required: false,
      },
      {
        name: "npwp",
        type: INPUT_TYPE.NUMBER,
        label: "NPWP",
        defaultValue: "",
        placeholder: "Input NPWP ( Ops )",
        validation: (value) => /^.{1,15}$/.test(value),
        errorMessage: "Please enter a value with a maximum of 15 characters",
        required: false,
      },
      {
        name: "npwp_file",
        type: INPUT_TYPE.FILE,
        label: "NPWP Attachment",
        tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
        allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 2, //MB
        defaultValue: ''
      },
    ];

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.clients);

  const {
    data: dataGroupClient,
    isLoading: isLoadingGroupClient,
  } = useSelector((state) => state.groupclient);


  // const rows = [

  //   {
  //     "id": 1,
  //     "code": "Code 1",
  //     "name": "PT. Telkom Indonesia ",
  //     "pic_name": "Marco",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 2,
  //     "code": "Code 2",
  //     "name": "PT. Unilever Tbk",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 3,
  //     "code": "Code 3",
  //     "name": "PT. Kompas Tbk",
  //     "pic_name": "Faris",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 4,
  //     "code": "Code 4",
  //     "name": "PT. Siantar Top Tbk",
  //     "pic_name": "Zaid",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 5,
  //     "code": "Code 5",
  //     "name": "PT. Wilmar Tbk",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 6,
  //     "code": "Anauco",
  //     "name": "PT. Gudang Garam Tbk",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 7,
  //     "code": "Code 7",
  //     "name": "Client Name 7",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 8,
  //     "code": "Code 8",
  //     "name": "Client Name 8",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 9,
  //     "code": "Code 9",
  //     "name": "Client Name 9",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 10,
  //     "code": "Code 10",
  //     "name": "Client Name 10",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 11,
  //     "code": "Code 11",
  //     "name": "Client Name 11",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 12,
  //     "code": "Code 12",
  //     "name": "Client Name 12",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 13,
  //     "code": "Code 13",
  //     "name": "Client Name 13",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 14,
  //     "code": "Code 14",
  //     "name": "Client Name 14",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 15,
  //     "code": "Code 15",
  //     "name": "Client Name 15",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 16,
  //     "code": "Code 16",
  //     "name": "Client Name 16",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 17,
  //     "code": "Code 17",
  //     "name": "Client Name 17",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 18,
  //     "code": "Code 18",
  //     "name": "Client Name 18",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 19,
  //     "code": "Code 19",
  //     "name": "Client Name 19",
  //     "pic_name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 20,
  //     "code": "Code 20",
  //     "name": "Client Name 20",
  //     "pic_name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   }
  // ];

  // const countries = [
  //   { dd 'AD', label: 'Andorra', phone: '376' },
  //   {
  //     dd 'AE',
  //     label: 'United Arab Emirates',
  //     phone: '971',
  //   },
  //   { dd 'AF', label: 'Afghanistan', phone: '93' },
  //   {
  //     dd 'AG',
  //     label: 'Antigua and Barbuda',
  //     phone: '1-268',
  //   },
  //   { dd 'AI', label: 'Anguilla', phone: '1-264' },
  //   { dd 'AL', label: 'Albania', phone: '355' },
  //   { dd 'AM', label: 'Armenia', phone: '374' },
  //   { dd 'AO', label: 'Angola', phone: '244' },
  //   { dd 'AQ', label: 'Antarctica', phone: '672' },
  //   { dd 'AR', label: 'Argentina', phone: '54' },
  //   { dd 'AS', label: 'American Samoa', phone: '1-684' },
  //   { dd 'AT', label: 'Austria', phone: '43' },
  //   {
  //     dd 'AU',
  //     label: 'Australia',
  //     phone: '61',
  //     suggested: true,
  //   },
  //   { dd 'AW', label: 'Aruba', phone: '297' },
  //   { dd 'AX', label: 'Alland Islands', phone: '358' },
  //   { dd 'AZ', label: 'Azerbaijan', phone: '994' },
  //   {
  //     dd 'BA',
  //     label: 'Bosnia and Herzegovina',
  //     phone: '387',
  //   },
  //   { dd 'BB', label: 'Barbados', phone: '1-246' },
  //   { dd 'BD', label: 'Bangladesh', phone: '880' },
  //   { dd 'BE', label: 'Belgium', phone: '32' },
  //   { dd 'BF', label: 'Burkina Faso', phone: '226' },
  //   { dd 'BG', label: 'Bulgaria', phone: '359' },
  //   { dd 'BH', label: 'Bahrain', phone: '973' },
  //   { dd 'BI', label: 'Burundi', phone: '257' },
  //   { dd 'BJ', label: 'Benin', phone: '229' },
  //   { dd 'BL', label: 'Saint Barthelemy', phone: '590' },
  //   { dd 'BM', label: 'Bermuda', phone: '1-441' },
  //   { dd 'BN', label: 'Brunei Darussalam', phone: '673' },
  //   { dd 'BO', label: 'Bolivia', phone: '591' },
  //   { dd 'BR', label: 'Brazil', phone: '55' },
  //   { dd 'BS', label: 'Bahamas', phone: '1-242' },
  //   { dd 'BT', label: 'Bhutan', phone: '975' },
  //   { dd 'BV', label: 'Bouvet Island', phone: '47' },
  //   { dd 'BW', label: 'Botswana', phone: '267' },
  //   { dd 'BY', label: 'Belarus', phone: '375' },
  //   { dd 'BZ', label: 'Belize', phone: '501' },
  //   {
  //     dd 'CA',
  //     label: 'Canada',
  //     phone: '1',
  //     suggested: true,
  //   },
  //   {
  //     dd 'CC',
  //     label: 'Cocos (Keeling) Islands',
  //     phone: '61',
  //   },
  //   {
  //     dd 'CD',
  //     label: 'Congo, Democratic Republic of the',
  //     phone: '243',
  //   },
  //   {
  //     dd 'CF',
  //     label: 'Central African Republic',
  //     phone: '236',
  //   },
  //   {
  //     dd 'CG',
  //     label: 'Congo, Republic of the',
  //     phone: '242',
  //   },
  //   { code: 'CH', label: 'Switzerland', phone: '41' },
  //   { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  //   { code: 'CK', label: 'Cook Islands', phone: '682' },
  //   { code: 'CL', label: 'Chile', phone: '56' },
  //   { code: 'CM', label: 'Cameroon', phone: '237' },
  //   { code: 'CN', label: 'China', phone: '86' },
  //   { code: 'CO', label: 'Colombia', phone: '57' },
  //   { code: 'CR', label: 'Costa Rica', phone: '506' },
  //   { code: 'CU', label: 'Cuba', phone: '53' },
  //   { code: 'CV', label: 'Cape Verde', phone: '238' },
  //   { code: 'CW', label: 'Curacao', phone: '599' },
  //   { code: 'CX', label: 'Christmas Island', phone: '61' },
  //   { code: 'CY', label: 'Cyprus', phone: '357' },
  //   { code: 'CZ', label: 'Czech Republic', phone: '420' },
  //   {
  //     code: 'DE',
  //     label: 'Germany',
  //     phone: '49',
  //     suggested: true,
  //   },
  //   { code: 'DJ', label: 'Djibouti', phone: '253' },
  //   { code: 'DK', label: 'Denmark', phone: '45' },
  //   { code: 'DM', label: 'Dominica', phone: '1-767' },
  //   {
  //     code: 'DO',
  //     label: 'Dominican Republic',
  //     phone: '1-809',
  //   },
  //   { code: 'DZ', label: 'Algeria', phone: '213' },
  //   { code: 'EC', label: 'Ecuador', phone: '593' },
  //   { code: 'EE', label: 'Estonia', phone: '372' },
  //   { code: 'EG', label: 'Egypt', phone: '20' },
  //   { code: 'EH', label: 'Western Sahara', phone: '212' },
  //   { code: 'ER', label: 'Eritrea', phone: '291' },
  //   { code: 'ES', label: 'Spain', phone: '34' },
  //   { code: 'ET', label: 'Ethiopia', phone: '251' },
  //   { code: 'FI', label: 'Finland', phone: '358' },
  //   { code: 'FJ', label: 'Fiji', phone: '679' },
  //   {
  //     code: 'FK',
  //     label: 'Falkland Islands (Malvinas)',
  //     phone: '500',
  //   },
  //   {
  //     code: 'FM',
  //     label: 'Micronesia, Federated States of',
  //     phone: '691',
  //   },
  //   { code: 'FO', label: 'Faroe Islands', phone: '298' },
  //   {
  //     code: 'FR',
  //     label: 'France',
  //     phone: '33',
  //     suggested: true,
  //   },
  //   { code: 'GA', label: 'Gabon', phone: '241' },
  //   { code: 'GB', label: 'United Kingdom', phone: '44' },
  //   { code: 'GD', label: 'Grenada', phone: '1-473' },
  //   { code: 'GE', label: 'Georgia', phone: '995' },
  //   { code: 'GF', label: 'French Guiana', phone: '594' },
  //   { code: 'GG', label: 'Guernsey', phone: '44' },
  //   { code: 'GH', label: 'Ghana', phone: '233' },
  //   { code: 'GI', label: 'Gibraltar', phone: '350' },
  //   { code: 'GL', label: 'Greenland', phone: '299' },
  //   { code: 'GM', label: 'Gambia', phone: '220' },
  //   { code: 'GN', label: 'Guinea', phone: '224' },
  //   { code: 'GP', label: 'Guadeloupe', phone: '590' },
  //   { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  //   { code: 'GR', label: 'Greece', phone: '30' },
  //   {
  //     code: 'GS',
  //     label: 'South Georgia and the South Sandwich Islands',
  //     phone: '500',
  //   },
  //   { code: 'GT', label: 'Guatemala', phone: '502' },
  //   { code: 'GU', label: 'Guam', phone: '1-671' },
  //   { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  //   { code: 'GY', label: 'Guyana', phone: '592' },
  //   { code: 'HK', label: 'Hong Kong', phone: '852' },
  //   {
  //     code: 'HM',
  //     label: 'Heard Island and McDonald Islands',
  //     phone: '672',
  //   },
  //   { code: 'HN', label: 'Honduras', phone: '504' },
  //   { code: 'HR', label: 'Croatia', phone: '385' },
  //   { code: 'HT', label: 'Haiti', phone: '509' },
  //   { code: 'HU', label: 'Hungary', phone: '36' },
  //   { code: 'ID', label: 'Indonesia', phone: '62' },
  //   { code: 'IE', label: 'Ireland', phone: '353' },
  //   { code: 'IL', label: 'Israel', phone: '972' },
  //   { code: 'IM', label: 'Isle of Man', phone: '44' },
  //   { code: 'IN', label: 'India', phone: '91' },
  //   {
  //     code: 'IO',
  //     label: 'British Indian Ocean Territory',
  //     phone: '246',
  //   },
  //   { code: 'IQ', label: 'Iraq', phone: '964' },
  //   {
  //     code: 'IR',
  //     label: 'Iran, Islamic Republic of',
  //     phone: '98',
  //   },
  //   { code: 'IS', label: 'Iceland', phone: '354' },
  //   { code: 'IT', label: 'Italy', phone: '39' },
  //   { code: 'JE', label: 'Jersey', phone: '44' },
  //   { code: 'JM', label: 'Jamaica', phone: '1-876' },
  //   { code: 'JO', label: 'Jordan', phone: '962' },
  //   {
  //     code: 'JP',
  //     label: 'Japan',
  //     phone: '81',
  //     suggested: true,
  //   },
  //   { code: 'KE', label: 'Kenya', phone: '254' },
  //   { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  //   { code: 'KH', label: 'Cambodia', phone: '855' },
  //   { code: 'KI', label: 'Kiribati', phone: '686' },
  //   { code: 'KM', label: 'Comoros', phone: '269' },
  //   {
  //     code: 'KN',
  //     label: 'Saint Kitts and Nevis',
  //     phone: '1-869',
  //   },
  //   {
  //     code: 'KP',
  //     label: "Korea, Democratic People's Republic of",
  //     phone: '850',
  //   },
  //   { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  //   { code: 'KW', label: 'Kuwait', phone: '965' },
  //   { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  //   { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  //   {
  //     code: 'LA',
  //     label: "Lao People's Democratic Republic",
  //     phone: '856',
  //   },
  //   { code: 'LB', label: 'Lebanon', phone: '961' },
  //   { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  //   { code: 'LI', label: 'Liechtenstein', phone: '423' },
  //   { code: 'LK', label: 'Sri Lanka', phone: '94' },
  //   { code: 'LR', label: 'Liberia', phone: '231' },
  //   { code: 'LS', label: 'Lesotho', phone: '266' },
  //   { code: 'LT', label: 'Lithuania', phone: '370' },
  //   { code: 'LU', label: 'Luxembourg', phone: '352' },
  //   { code: 'LV', label: 'Latvia', phone: '371' },
  //   { code: 'LY', label: 'Libya', phone: '218' },
  //   { code: 'MA', label: 'Morocco', phone: '212' },
  //   { code: 'MC', label: 'Monaco', phone: '377' },
  //   {
  //     code: 'MD',
  //     label: 'Moldova, Republic of',
  //     phone: '373',
  //   },
  //   { code: 'ME', label: 'Montenegro', phone: '382' },
  //   {
  //     code: 'MF',
  //     label: 'Saint Martin (French part)',
  //     phone: '590',
  //   },
  //   { code: 'MG', label: 'Madagascar', phone: '261' },
  //   { code: 'MH', label: 'Marshall Islands', phone: '692' },
  //   {
  //     code: 'MK',
  //     label: 'Macedonia, the Former Yugoslav Republic of',
  //     phone: '389',
  //   },
  //   { code: 'ML', label: 'Mali', phone: '223' },
  //   { code: 'MM', label: 'Myanmar', phone: '95' },
  //   { code: 'MN', label: 'Mongolia', phone: '976' },
  //   { code: 'MO', label: 'Macao', phone: '853' },
  //   {
  //     code: 'MP',
  //     label: 'Northern Mariana Islands',
  //     phone: '1-670',
  //   },
  //   { code: 'MQ', label: 'Martinique', phone: '596' },
  //   { code: 'MR', label: 'Mauritania', phone: '222' },
  //   { code: 'MS', label: 'Montserrat', phone: '1-664' },
  //   { code: 'MT', label: 'Malta', phone: '356' },
  //   { code: 'MU', label: 'Mauritius', phone: '230' },
  //   { code: 'MV', label: 'Maldives', phone: '960' },
  //   { code: 'MW', label: 'Malawi', phone: '265' },
  //   { code: 'MX', label: 'Mexico', phone: '52' },
  //   { code: 'MY', label: 'Malaysia', phone: '60' },
  //   { code: 'MZ', label: 'Mozambique', phone: '258' },
  //   { code: 'NA', label: 'Namibia', phone: '264' },
  //   { code: 'NC', label: 'New Caledonia', phone: '687' },
  //   { code: 'NE', label: 'Niger', phone: '227' },
  //   { code: 'NF', label: 'Norfolk Island', phone: '672' },
  //   { code: 'NG', label: 'Nigeria', phone: '234' },
  //   { code: 'NI', label: 'Nicaragua', phone: '505' },
  //   { code: 'NL', label: 'Netherlands', phone: '31' },
  //   { code: 'NO', label: 'Norway', phone: '47' },
  //   { code: 'NP', label: 'Nepal', phone: '977' },
  //   { code: 'NR', label: 'Nauru', phone: '674' },
  //   { code: 'NU', label: 'Niue', phone: '683' },
  //   { code: 'NZ', label: 'New Zealand', phone: '64' },
  //   { code: 'OM', label: 'Oman', phone: '968' },
  //   { code: 'PA', label: 'Panama', phone: '507' },
  //   { code: 'PE', label: 'Peru', phone: '51' },
  //   { code: 'PF', label: 'French Polynesia', phone: '689' },
  //   { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  //   { code: 'PH', label: 'Philippines', phone: '63' },
  //   { code: 'PK', label: 'Pakistan', phone: '92' },
  //   { code: 'PL', label: 'Poland', phone: '48' },
  //   {
  //     code: 'PM',
  //     label: 'Saint Pierre and Miquelon',
  //     phone: '508',
  //   },
  //   { code: 'PN', label: 'Pitcairn', phone: '870' },
  //   { code: 'PR', label: 'Puerto Rico', phone: '1' },
  //   {
  //     code: 'PS',
  //     label: 'Palestine, State of',
  //     phone: '970',
  //   },
  //   { code: 'PT', label: 'Portugal', phone: '351' },
  //   { code: 'PW', label: 'Palau', phone: '680' },
  //   { code: 'PY', label: 'Paraguay', phone: '595' },
  //   { code: 'QA', label: 'Qatar', phone: '974' },
  //   { code: 'RE', label: 'Reunion', phone: '262' },
  //   { code: 'RO', label: 'Romania', phone: '40' },
  //   { code: 'RS', label: 'Serbia', phone: '381' },
  //   { code: 'RU', label: 'Russian Federation', phone: '7' },
  //   { code: 'RW', label: 'Rwanda', phone: '250' },
  //   { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  //   { code: 'SB', label: 'Solomon Islands', phone: '677' },
  //   { code: 'SC', label: 'Seychelles', phone: '248' },
  //   { code: 'SD', label: 'Sudan', phone: '249' },
  //   { code: 'SE', label: 'Sweden', phone: '46' },
  //   { code: 'SG', label: 'Singapore', phone: '65' },
  //   { code: 'SH', label: 'Saint Helena', phone: '290' },
  //   { code: 'SI', label: 'Slovenia', phone: '386' },
  //   {
  //     code: 'SJ',
  //     label: 'Svalbard and Jan Mayen',
  //     phone: '47',
  //   },
  //   { code: 'SK', label: 'Slovakia', phone: '421' },
  //   { code: 'SL', label: 'Sierra Leone', phone: '232' },
  //   { code: 'SM', label: 'San Marino', phone: '378' },
  //   { code: 'SN', label: 'Senegal', phone: '221' },
  //   { code: 'SO', label: 'Somalia', phone: '252' },
  //   { code: 'SR', label: 'Suriname', phone: '597' },
  //   { code: 'SS', label: 'South Sudan', phone: '211' },
  //   {
  //     code: 'ST',
  //     label: 'Sao Tome and Principe',
  //     phone: '239',
  //   },
  //   { code: 'SV', label: 'El Salvador', phone: '503' },
  //   {
  //     code: 'SX',
  //     label: 'Sint Maarten (Dutch part)',
  //     phone: '1-721',
  //   },
  //   {
  //     code: 'SY',
  //     label: 'Syrian Arab Republic',
  //     phone: '963',
  //   },
  //   { code: 'SZ', label: 'Swaziland', phone: '268' },
  //   {
  //     code: 'TC',
  //     label: 'Turks and Caicos Islands',
  //     phone: '1-649',
  //   },
  //   { code: 'TD', label: 'Chad', phone: '235' },
  //   {
  //     code: 'TF',
  //     label: 'French Southern Territories',
  //     phone: '262',
  //   },
  //   { code: 'TG', label: 'Togo', phone: '228' },
  //   { code: 'TH', label: 'Thailand', phone: '66' },
  //   { code: 'TJ', label: 'Tajikistan', phone: '992' },
  //   { code: 'TK', label: 'Tokelau', phone: '690' },
  //   { code: 'TL', label: 'Timor-Leste', phone: '670' },
  //   { code: 'TM', label: 'Turkmenistan', phone: '993' },
  //   { code: 'TN', label: 'Tunisia', phone: '216' },
  //   { code: 'TO', label: 'Tonga', phone: '676' },
  //   { code: 'TR', label: 'Turkey', phone: '90' },
  //   {
  //     code: 'TT',
  //     label: 'Trinidad and Tobago',
  //     phone: '1-868',
  //   },
  //   { code: 'TV', label: 'Tuvalu', phone: '688' },
  //   {
  //     code: 'TW',
  //     label: 'Taiwan',
  //     phone: '886',
  //   },
  //   {
  //     code: 'TZ',
  //     label: 'United Republic of Tanzania',
  //     phone: '255',
  //   },
  //   { code: 'UA', label: 'Ukraine', phone: '380' },
  //   { code: 'UG', label: 'Uganda', phone: '256' },
  //   {
  //     code: 'US',
  //     label: 'United States',
  //     phone: '1',
  //     suggested: true,
  //   },
  //   { code: 'UY', label: 'Uruguay', phone: '598' },
  //   { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  //   {
  //     code: 'VA',
  //     label: 'Holy See (Vatican City State)',
  //     phone: '379',
  //   },
  //   {
  //     code: 'VC',
  //     label: 'Saint Vincent and the Grenadines',
  //     phone: '1-784',
  //   },
  //   { code: 'VE', label: 'Venezuela', phone: '58' },
  //   {
  //     code: 'VG',
  //     label: 'British Virgin Islands',
  //     phone: '1-284',
  //   },
  //   {
  //     code: 'VI',
  //     label: 'US Virgin Islands',
  //     phone: '1-340',
  //   },
  //   { code: 'VN', label: 'Vietnam', phone: '84' },
  //   { code: 'VU', label: 'Vanuatu', phone: '678' },
  //   { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  //   { code: 'WS', label: 'Samoa', phone: '685' },
  //   { code: 'XK', label: 'Kosovo', phone: '383' },
  //   { code: 'YE', label: 'Yemen', phone: '967' },
  //   { code: 'YT', label: 'Mayotte', phone: '262' },
  //   { code: 'ZA', label: 'South Africa', phone: '27' },
  //   { code: 'ZM', label: 'Zambia', phone: '260' },
  //   { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  // ];
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });

    /* ----------------------------- getGroupClient ----------------------------- */

    dispatch(
      getGroupClient({
        token,
      })
    );
  }, []);



  useEffect(() => {
    if(dataGroupClient) {
      // if (!options["group_id"]) {
      //   setOptions((prevOptions) => ({
      //     ...prevOptions,
      //     group_id: dataGroupClient,
      //   }));
      // } else {
        setOptions((prevOptions) => ({
          ...prevOptions,
          group_id: dataGroupClient,
        }));
      // }
    }

  }, [ dataGroupClient ]);

  useEffect(() => {
    if (status === ACTION_TYPE.DEFAULT_STATUS) {
      setType(ACTION_TYPE.ALL_TYPE);
    }
  }, [status]);

  useEffect(() => {
    if (
      type !== ACTION_TYPE.DEFAULT_TYPE &&
      status !== ACTION_TYPE.DEFAULT_STATUS
    ) {

      dispatch(
        getClient({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          type,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [type, status]);

  // useEffect(() => {
  //   if (type !== ACTION_TYPE.DEFAULT_TYPE) {
  //     dispatch(
  //       getClient({
  //         token,
  //         limit,
  //         search,
  //         status,
  //         sortDirection,
  //         sortBy,
  //         type,
  //       })
  //     );
  //   } else {
  //     setSelectedData([]); //buat disabled button toolbar
  //   }
  // }, [type]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues(fields), actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_GROUP
        ? {
          id: null,
          status: false,
        }
        : {})
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null, status: false })
  );
  const [detailRow, setDetailRow] = useState({});
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_GROUP && actionType !== actionType.DELETE) {
      localStorage.setItem("sales-management/client/formValues", JSON.stringify(formValues));
    }
    console.log(formValues, 'GANTIIII')
  }, [formValues, actionType]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getClient({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          type,
        })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = async (idFromDetail) => {
    //const data = rows.filter((row) => selectedData.includes(row.id))[0];
    let data;
    if(idFromDetail) {
      data = rows.filter((row) => row.id == idFromDetail)[0];
    } else {
      data = rows.filter((row) => selectedData.includes(row.id))[0];
    }
    const {id} = data;
    // debugger;
    setIsLoadedFormValues(true);
    try {
        const response = await axios.get(`/clients/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        let data = response.data.data;
        
        setFormValues(data);
        setErrors(createEmptyErrors(data))

      } catch (error) {
        console.error("Error fetching client data:", error);
    } finally {
      setIsLoadedFormValues(false);
    }
    
  };

  const setDetailRowFromSelectedData = async (dataSelected) => {
    const {id} = dataSelected;
    // debugger;
    setIsLoadedFormValues(true);
    try {
        const response = await axios.get(`/clients/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        let data = response.data.data;
        setDetailRow(data);

      } catch (error) {
        console.error("Error fetching client data:", error);
    } finally {
      setIsLoadedFormValues(false);
    }
    
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleFileSelect = (
    file,
    fieldName
  ) => {
    const [name] = [fieldName]
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: file,
    }));
  };

  const handleInputChange = (event, field) => {
    const { name, value, type } = event.target;
    const newErrors = { ...errors };
    console.log(name, value, "name, value");

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
          newErrors[name] = fileError;
          setErrors(newErrors);
          return;
        // }
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: file,
        }));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateFileInput = (
    fieldValue,
    field,
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }

    return "";
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" || formValues[name] === undefined ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
      } else if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;


      if (
        type === INPUT_TYPE.FILE
      ) {
        // Handle file input validation
        const fileError = validateFileInput(formValues[name], field);
        if (fileError) {
          valid = false;
        }
      } else if (type !== "file") {
        if (
          required &&
          (String(formValues[name]).trim() === "" || formValues[name] === undefined ||
            formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
            formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
            formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
        ) {
          newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
          valid = false;
        }

        if (
          formValues[name] &&
          formValues[name].length &&
          validation &&
          !validation(formValues[name])
        ) {
          newErrors[name] = errorMessage || `${field.label} is invalid`;
          valid = false;
        }

      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        } else if (actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP) {
          handleCloseFormModal(true);
          handleAddRow();
          handleFormModal(ACTION_TYPE.ADD)
        }
        setSelectedData([]);
      }
    }
  };

  const isImageExtension = (filename) => {
    const imageExtensions = [".jpeg", ".jpg", ".png"];
    const extension = filename.split('.').pop();
    return imageExtensions.includes(`.${extension.toLowerCase()}`);
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
//     debugger;
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Client");
      setFormValuesFromSelectedData(dataSelected?.id);
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Client");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setClientDeleted(
        `the client '${data.map((row) => row.name).join(", ")}'`
      );
    } else if (actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP) {
      setTitle("Add New Group");
      resetFormValues();
    } else if (actionType === "PREVIEW") {
      setTitle("Preview NPWP");
    }
    else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle(`Detail Client`, );
      setDetailRowFromSelectedData(dataSelected)
    } else {
      resetFormValues();
      setTitle("Add Client");
      setFormValues(JSON.parse(localStorage.getItem("sales-management/client/formValues")));
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD || actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);

    if (actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP) {
      handleFormModal(ACTION_TYPE.ADD) // formValue yang digunakan adalah formValues yang bukan ADD_NEW_GROUP
    } else {
      setFormModal(false);
      resetFormValues()
    }

    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues()
  };

  const handleAddRow = () => {
    if(actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP) {
      dispatch(addGroupClient(token, formValues));
    } else {
      dispatch(addClient(token, formValues));
    }
  };

  const handleEditRow = () => {
    dispatch(editClient(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteClient(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */
  const Detail = () => {

    return (
      <>
        <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' } }}>
          <Grid item xs={4}>
            <Typography variant="body2">Code</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.code}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Category</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.category} </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Type</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.type}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Client Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">PIC Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.pic_name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Country</Typography>
          </Grid>
          <Grid item xs={8}>
            {detailRow.country ? (
              <Box sx={{ '& > img': { mr: 1, flexShrink: 0 }, '& > span': { color: '#555' } }}>
                <img src={`https://flagcdn.com/w20/${detailRow.country.code.toLowerCase()}.png`} alt={`${detailRow.country.name} flag`} loading="lazy"
                  width="20" />
                {detailRow.country.name} <span>({detailRow.country.code})</span>
              </Box>
            ) : (
                <div>No country information available</div>
              )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Address</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.address}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Email</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.email || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Phone</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.phone || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Mobile Number</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.mobile_number || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Group</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.group_name || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">NPWP</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.npwp || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">NPWP Attachment</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.npwp_file?.filename}
            {
              /* OLD
               * detailRow.npwp_file && detailRow.npwp_file?.filename !== '-' && (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              size="small"
              onClick={() => handleFormModal('PREVIEW')}
              sx={{marginLeft:1}}
            >
              Preview
            </Button>
            )*/}
              {detailRow.npwp_file && detailRow.npwp_file?.filename !== '-' && (
                isImageExtension(detailRow.npwp_file.filename) ? (
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    size="small"
                    onClick={() => handleFormModal('PREVIEW')}
                    sx={{ marginLeft: 1 }}
                  >
                    Preview
                  </Button>
                ) : (
                  <a href={detailRow.npwp_file_url} target="_blank" rel="noopener noreferrer">
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      size="small"
                      sx={{ marginLeft: 1 }}
                    >
                      Preview
                    </Button>
                  </a>
                )
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Status</Typography>
          </Grid>
          <Grid item xs={8}>
          { detailRow.status && <StatusBadge status={detailRow.status}> {detailRow.status} </StatusBadge> }
          </Grid>
        </Grid>

      </>
    );
  };

  const renderFormContent = () => {
    if(!isLoadedFormValues) {
    if (
//       (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD) || actionType === ANOTHER_ACTION_TYPE.ADD_NEW_GROUP
    ) {
      return (
        <>
          <Grid>
            {fields.map((field) => {
              // # Old
              // const fieldOptions = Array.isArray(options[field.name])
              //   ? [
              //     field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //       id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //       // name: `Select ${field.label}`,
              //       name: `Choose Option`,
              //     },
              //     ...options[field.name],
              //   ]
              //   : [];

              // # New
              let optionsData;
              if (Array.isArray(options[field.name])) {
                const isRequired = field.required &&
                  field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    name: "Choose Option",
                  };

                if (isRequired) {
                  optionsData = [
                    // field.required &&
                    //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    //     name: "Choose Option",
                    //   },
                    ...options[field.name],
                  ];
                } else {
                  optionsData = [...options[field.name]];
                }
              }

              const fieldOptions = Array.isArray(options[field.name])
                ? optionsData
                : [];



              let fieldComponent = null;

              switch (field.type) {
                case INPUT_TYPE.TEXT:
                case INPUT_TYPE.NUMBER:
                case INPUT_TYPE.PHONE_NUMBER:
                case INPUT_TYPE.POINT:
                case INPUT_TYPE.CURRENCY:
                  fieldComponent = (
                    <TextInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.PASSWORD:
                  fieldComponent = (
                    <PasswordInputWithValidation
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TEXTAREA:
                  fieldComponent = (
                    <TextArea
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.FILE:
                  fieldComponent = (
                    <FilePicker
                      field={field}
                      formValues={formValues[field.name]}
                      errors={errors}
                      onFileSelect={(file) =>
                        handleFileSelect(
                          file,
                          field.name
                        )
                      }
                      handleInputChange={(e) =>
                        handleInputChange(
                          e,
                          field
                        )
                      }
                      tip={field ?.tip}
                      URIForViewDocument={formValues[`${field.name}_url`]}
                    />
                  );

                  break;
                case INPUT_TYPE.SELECT_ONE:
                  fieldComponent = (
                    <SelectOne
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectOneAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
                  fieldComponent = (
                    <SelectOneCountryAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                  );

                  break;

                case INPUT_TYPE.SELECT_MULTIPLE:
                  fieldComponent = (
                    <SelectMultiple
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectMultipleAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;

                case INPUT_TYPE.CHECKBOX:
                  fieldComponent = (
                    <CheckboxGroupInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={
                        fieldOptions.length
                          ? fieldOptions.filter((option) => option !== false)
                          : field.options
                      }
                    />
                  );
                  break;

                case INPUT_TYPE.RADIO:
                  fieldComponent = (
                    <RadioGroupInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={
                        fieldOptions.length
                          ? fieldOptions.filter((option) => option !== false)
                          : field.options
                      }
                    />
                  );
                  break;

                case INPUT_TYPE.DATE:
                  fieldComponent = (
                    <DatePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TIME:
                  fieldComponent = (
                    <TimePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                default:
                  fieldComponent = null;
              }

              return (
                <Grid
                  item
                  xs={field.gridWidth || 12}
                  key={field.name}
                  sx={{ paddingTop: "unset !important" }}
                >
                  {fieldComponent}



                  {field.name === "group_id" && (
                    <>
                      <Box sx={{
                        position: "absolute",
                        top: "6rem",
                        right: "2rem",
                        width: "90%"
                      }}>
                        <TransitionAlerts isOpen={isLoadingGroupClient} message="New group added successfully" />
                      </Box>

                      {/* OR Text */}
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Typography variant="subtitle1">or</Typography>
                      </Grid>

                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleAddNewGroup}
                        onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.ADD_NEW_GROUP)}
                        sx={{ marginTop: .5, marginBottom: 1 }}
                        fullWidth
                        disableElevation
                      >
                        Add new group
                    </Button>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
          {actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_GROUP && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ...formGroup,
                padding: "0 1rem",
              }}
            >
              <FormControlLabel
                control={
                  <StatusSwitch
                    checked={formValues.status}
                    name="status"
                    onChange={handleSwitchChange}
                  />
                }
                label={
                  <Typography
                    variant="h7"
                    sx={{ marginLeft: 1, fontWeight: "500" }}
                  >
                    {formValues.status ? "Active" : "Inactive"}
                  </Typography>
                }
              />
            </Box>
          )}
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    } else if (actionType === ACTION_TYPE.DETAIL) {
      return <Detail />
    } else if (actionType === 'PREVIEW') {
      return <Grid
        item
        xs={12}>
          <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'}}>
          <img
            src={detailRow.npwp_file_url ||`https://www.hipajak.id/images/post/ugY1EKGtT04PzHXmCP7jIdHO.jpg`}
            loading="lazy"
            // style={{ width: 'auto', height: 'auto' }}
            style={{maxWidth: '100%',
              maxHeight:'400px',
              objectFit: 'cover',}}
          />
          </Box>
      </Grid>
    }
  } else {
    return(<Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'
    }}><FacebookCircularProgress /></Box>)
  }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        {(actionType !== ACTION_TYPE.DETAIL && actionType !== ANOTHER_ACTION_TYPE.PREVIEW ) ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
            >
              {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
            </Button>
          </>
        ) : actionType === ANOTHER_ACTION_TYPE.PREVIEW ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => handleCloseFormModal()}
            >
              Yes
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              onClick={() => handleFormModal(ACTION_TYPE.EDITED, detailRow)}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    );
  };
  

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getClient({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          type,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getClient({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        type,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getClient({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        type,
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getClient({ token, limit, search, status, sortDirection, sortBy, type })
      );
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getClient({ token, limit, search, status, sortDirection, sortBy, type })
    // );
  }

  function _type(val) {
    setType(val);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder="Search name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={type}
                onChange={(e) => _type(e.target.value)}
                disabled={status === ACTION_TYPE.DEFAULT_STATUS}
              >
                <MenuItem value={ACTION_TYPE.ALL_TYPE}>
                  All Type
                </MenuItem>
                <MenuItem value={"Individual"}>
                Individual
                </MenuItem>
                <MenuItem value={"Company"}>
                Company
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Client"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          // userAccess={userAccess}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED && actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_GROUP && actionType !== ACTION_TYPE.DETAIL && actionType !== "PREVIEW" ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
          <Modal open={formModal} onClose={() => handleCloseFormModal()}>
            <Box>
              <Form
                title={title}
                onCloseModal={() => handleCloseFormModal()}
                elementForm={renderFormContent}
                elementActionButton={renderActionButtons}
                onSubmit={handleSubmit}
              />
            </Box>
          </Modal>
        )}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Client;
