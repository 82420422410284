import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../components/Form";
import { addStage, deleteStage, editStage, getStage } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";

const Stage = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    console.log(currentPath, userAccess);
  }, [userAccess]);
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const columns = [
    {
      field: "name",
      headerName: "Stages Name",
      width: 230,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "Stages Type",
      width: 140,
      disableColumnMenu: true,
    },

    {
      field: "service_category",
      headerName: "Service Category",
      width: 200,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        return params.row.service_category
          ? params.row.service_category ?.name
            : "-";
      },
    },

    {
      field: "sub_service_categories",
      headerName: "Sub Service Category",
      width: 200,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        return params.row.service_sub_categories
          ? params.row.service_sub_categories ?.name
            : "-";
      },
    },
    {
      field: "statuses",
      headerName: "Status Inside",
      // description: "This column has a value getter and is not sortable.",
      // sortable: false,
      width: 280,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        // 'params.value' will be the 'statuses' array
        return (
          <Box
            sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
          >
            {params.value.map((status, key) => (
              <StatusBadge
                key={key}
                status={status.name}
                bgColor={status.bg_code}
                colors={status.color_code}
                style={{ margin: ".1rem .25rem" }}
              >
                {status.name}
              </StatusBadge>
            ))}
          </Box>
        );
      },
    },
  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [formValuesV2, setFormValuesV2] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [typeStage, setTypeStage] = useState(ACTION_TYPE.DEFAULT_TYPE_STAGE);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Stages");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [selectedOption, setSelectedOption] = useState("Internal"); // 'External' or 'Internal'
  const [selectedServiceCategoryId, setSelectedServiceCategoryId] =
    useState(null);
  const dispatch = useDispatch();

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.stages);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(name, response.data.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });
  }, []);

  useEffect(() => {
    console.log("--------------------------options-------------------------");

    console.log(options);
  }, [options]);

  useEffect(() => {
    // Fetch sub_category_id options based on the selected category_id
    if (selectedServiceCategoryId !== null) {
      const fetchSubServiceCategoryOptions = async () => {
        try {
          const response = await axios.get(`/master/services`, {
            params: {
              main_service_id: selectedServiceCategoryId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setOptions((prevOptions) => ({
            ...prevOptions,
            sub_service_category_id: response.data.data,
          }));
        } catch (error) {
          console.error(
            "Error fetching sub_service_category_id options:",
            error
          );
        }
      };
      fetchSubServiceCategoryOptions();
    } else {
      // If no category is selected, reset the sub_category_id options
      setOptions((prevOptions) => ({
        ...prevOptions,
        sub_service_category_id: [],
      }));
    }
  }, [selectedServiceCategoryId]);

  useEffect(() => {
    if (typeStage !== ACTION_TYPE.DEFAULT_TYPE_STAGE) {
      dispatch(
        getStage({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          typeStage,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [typeStage]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        type: "Internal",
      })
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */


  const fields = [
    {
      name: "name",
      type: INPUT_TYPE.TEXT,
      label: "Stage Name",
      defaultValue: "",
      required: true,
      placeholder: 'Input stages name',
      tip: "Example: Request",
      errorMessage: 'Please enter stages name. Example: Request',
    },
    {
      name: "service_category_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Service Category",
      placeholder: 'Select service category',
      // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      apiEndpoint: "/master/services",
      required: true,
    },
    {
      name: "sub_service_category_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Sub Service Category",
      placeholder: 'Select sub category service',
      // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      required: true,
      additionalProps: {
        disabled: formValuesV2.service_category_id === '' || formValuesV2.service_category_id === null,
      },
    },
    {
      name: "status",
      type: INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE,
      label: "Status",
      placeholder: 'Select status',
      defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE,
      required: true,
      apiEndpoint: "/master/statuses",
    },
  ];

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), {
      id: null,
      type: "Internal",
    })
  );
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
    setFormValuesV2(formValues);
  }, [formValues]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);


  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getStage({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          typeStage,
        })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    for (const key in data) {
      // Check if the property exists in formValues before updating
      const value = data[key];
      const newValue = typeof value === "object" ? value ?.id : value;

      if (key === "type") setSelectedOption(value);
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: newValue,
        }));
      } else {
        if (key === "service_category") {
          setSelectedServiceCategoryId(newValue);
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            service_category_id: newValue,
          }));
        } else if (key === "service_sub_categories") {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            service_sub_category_id: newValue,
          }));
        } else if (key === "statuses") {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            status: value.map((status) => status.id),
          }));
        }
      }
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "service_category_id") {
      setSelectedServiceCategoryId(value);
    }

    if (name === "type") {
      setSelectedOption(value);
      // Reset service_category_id and sub_service_category_id values when switching between options
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        service_category_id: "",
        sub_service_category_id: "",
      }));
      setErrors(initialErrors);
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        (required && String(formValues[name]).trim() === "") ||
        formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
      } else if (formValues[name] &&
        formValues[name].length && validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;

      if (selectedOption == "Internal") {
        if (
          name == "service_category_id" ||
          name == "sub_service_category_id"
        ) {
          return;
        }
      }

      if (
        (required && String(formValues[name]).trim() === "") ||
        formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
        valid = false;
      }

      if (formValues[name] &&
        formValues[name].length && validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        }

        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Stages");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(`
      stages '${data.map((row) => row.name).join(", ")}'
      `);
    } else {
      resetFormValues();
      setTitle("Add Stages");
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
      }
    } else {
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  const handleAddRow = () => {
    dispatch(addStage(token, formValues));
  };

  const handleEditRow = () => {
    dispatch(editStage(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteStage(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    const isInternal = formValues.type === "Internal";

    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose Option</FormLabel>
            <RadioGroup
              row
              aria-label="selectedOption"
              name="type"
              value={formValues.type}
              onChange={handleInputChange}

            // checked={formValues.status}
            // name="status"
            // onChange={handleSwitchChange}
            >
              <FormControlLabel
                value="Internal"
                disabled={actionType === ACTION_TYPE.EDITED}
                control={<Radio />}
                label="Internal"
              />
              <FormControlLabel
                value="External"
                disabled={actionType === ACTION_TYPE.EDITED}
                control={<Radio />}
                label="External"
              />
            </RadioGroup>
          </FormControl>
          {fields.map((field) => {
            const isServiceCategory = field.name === "service_category_id";
            const isSubServiceCategory =
              field.name === "sub_service_category_id";

            let optionsData;
            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              if (isRequired) {
                optionsData = [
                  // field.required &&
                  //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  //     name: "Choose Option",
                  //   },
                  ...options[field.name],
                ];
              } else {
                optionsData = [...options[field.name]];
              }
            }

            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            if ((isServiceCategory || isSubServiceCategory) && isInternal) {
              return null;
            }


            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
                return (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.TEXTAREA:
                return (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field.tip}
                  />
                );
              case INPUT_TYPE.SELECT_ONE:
                return (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                return (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE:
                return (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                return (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              default:
                return null;
            }
          })}
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
    console.log(selectedOption, "selectedOption");
  }, [search, status, isLoading, selectedOption]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getStage({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          typeStage,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getStage({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        typeStage,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getStage({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        typeStage,
      })
    );
  }
  function _search() {
    dispatch(
      getStage({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        typeStage,
      })
    );
  }
  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getStage({ token, limit, search, status, sortDirection, sortBy,typeStage, })
    // );
  }

  function _typeStage(type) {
    setTypeStage(type);
    console.log(type, typeStage, "KOKOOKO");
    // dispatch(
    //   getStage({ token, limit, search, status, sortDirection, sortBy,typeStage, })
    // );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search stages name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={typeStage}
                onChange={(e) => _typeStage(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_TYPE_STAGE}>
                  Select Type
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_TYPE_STAGE}>All Type</MenuItem>
                <MenuItem value="Internal">Internal</MenuItem>
                <MenuItem value="External">External</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Stage"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            typeStageChoosed={typeStage}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            userAccess={userAccess}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Stage;
