import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../../../core/axios_config";
import dayjs from "dayjs";
import ArrowBackIcon from "./../../../assets/back.svg";
import RemoveIcon from "./../../../assets/minus.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import EmptyAttachment from "./../../../assets/empty-attachment.svg";
import InfoIcon from "./../../../assets/info-circle.svg";
import {
  FacebookCircularProgress,
  borderRadius,
  btnWhite,
  dangerMainColor,
  dangerSurfaceColor,
  formGroup,
  mainColor,
  neutral100,
  neutral60,
  removeButton,
} from "../../../util/style";
import { useDispatch } from "react-redux";
import { ACTION_TYPE, INPUT_TYPE } from "../../../util/function";
import { addReturn, editReturn } from "./clients/actions";
import { DatePickerInput, TextArea, TextInput } from "../../../components/Form";
import { useSelector } from "react-redux";

const fields = [
  {
    name: "request_name",
    type: INPUT_TYPE.TEXT,
    label: "Request By",
    defaultValue: "",
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "request_date",
    type: INPUT_TYPE.DATE,
    label: "Request Date",
    endIcon: <img src={CalendarIcon} />,
    formatDate: "DD/MM/YYYY", // Custom date format
    defaultValue: "",
    required: true,
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "return_date",
    type: INPUT_TYPE.DATE,
    label: "Return Date",
    endIcon: <img src={CalendarIcon} />,
    formatDate: "DD/MM/YYYY", // Custom date format
    defaultValue: "",
    required: true,
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "actual_return_date",
    type: INPUT_TYPE.DATE,
    label: "Actual Return Date",
    endIcon: <img src={CalendarIcon} />,
    formatDate: "DD/MM/YYYY", // Custom date format
    defaultValue: "",
    required: true,
  },
  {
    name: "customer_name",
    type: INPUT_TYPE.TEXT,
    label: "Customer Name",
    defaultValue: "",
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "note",
    type: INPUT_TYPE.TEXTAREA,
    label: "Note (Ops)",
    defaultValue: "",
    required: false,
  },
  {
    name: "divider",
    type: INPUT_TYPE.DIVIDER,
  },
  {
    name: "service_category",
    type: "service_category",
    label: "Service Category",
    defaultValue: [
      {
        service_category_id: "",
        sub_category_service_id: "",
        registration_number: "",
        reference_number: "",
        applicant_id: "",
        brand_id: "",
        cupboard_id: "",
        rack_seting_id: "",
      },
    ],
  },
  {
    name: "attach_file",
    type: "attach_file",
    label: "Attach File",
    defaultValue: [],
  },
];

const AddReturn = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("Add Return");
  const { token } = useSelector((state) => state.auth);
  const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState([]);
  const [cupboards, setCupboards] = useState([]);
  const [actionType, setActionType] = useState();
  const { id } = useParams();
  const [lengthService, setLengthService] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const initialFormValues = (type = "fields") => {
    return fields.reduce(
      (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
      {}
    );
  };

  const initialErrors = {
    actual_return_date: "",
    request_id: "",
    note: "",
    service_category: [
      {
        service_category_id: null,
        sub_category_service_id: null,
        registration_number: null,
        reference_number: null,
        applicant_id: null,
        brand_id: null,
      },
    ],
  };

  const [errors, setErrors] = useState(initialErrors);

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"))
  );


  const [options, setOptions] = useState({
    serviceCategories: [],
    subServiceCategories: [],
    cupboardIds: [],
    rackNumbers: [],
    applicantIds: [],
    brandIds: [],
    attachChecked: [],
    attachDesc: [],
  });

  useEffect(() => {
    let name = "";
    if (state && state.type) {
      name = `${state.type}`
    }
    if (id) {
      setTitle(`Edit Return ${name}`);
      setActionType(ACTION_TYPE.EDIT);
      fetchRequestData();
    } else {
      setTitle(`Add Return ${name}`);
      setActionType(ACTION_TYPE.ADD);
      // resetFormValues();
    }
  }, [id]);

  useEffect(() => {
    if (state) {
      resetFormValues();
      const s = state.data;
      setIsLoading(true);
      console.log("disini");
      axios
        .get(
          `return/cek_data_request?request_by=${s.request_by}&req_code=${s.request_number}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setIsLoading(false);
          let data = response.data.data;
          console.log("data");
          console.log(data);
          const sC = [];
          const cp = [];

          const newFormValues = { ...formValues };
          data.get_archive_service.map((s, i) => {
            const service = newFormValues.service_category[i];
            sC.push({
              id: s.get_service_category.id,
              name: s.get_service_category.name,
            });
            cp.push({
              id: s.get_service_category.id,
              cupboardName: s.get_archive_detail.get_cupboard.cupboard_name,
              rackNumber: s.get_archive_detail.get_rack.rack_number,
            });

            service.registration_number = s.registration_number;
            service.reference_number = s.reference_number;

          });
          const request_date = dayjs(data.request_date).isValid()
            ? dayjs(data.request_date)
            : dayjs(data.request_date).format("DD/MM/YYYY");
          const return_date = dayjs(data.return_date).isValid()
            ? dayjs(data.return_date)
            : dayjs(data.return_date).format("DD/MM/YYYY");
          console.log(cp, "cp");
          let date = new Date();
          let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
          let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
          let year = date.getFullYear();
          const actual_return_date = `${year}-${month}-${day}`;
          setCupboards(cp);
          setLengthService(data.length);
          setOptions((prevOptions) => ({
            ...prevOptions,
            serviceCategories: sC,
          }));
          setFormValues((prevValues) => ({
            ...prevValues,
            ["request_id"]: data.id,
            ["customer_id"]: data.customer_id,
            ["request_name"]: data.get_user.fullname,
            ["request_date"]: request_date,
            ["return_date"]: return_date,
            ["actual_return_date"]: actual_return_date,
            ["customer_name"]: data.customer_name,
            // ['service_category']: service_category,
            // ['attach_file']: attach_file,
          }));
          console.log("formValues==>" + formValues.service_category);
        })
        .catch((v) => {
          setIsLoading(false);
        });
    }
  }, [state]);

  useEffect(() => {
    const s = state.data;
    // Fetch sub-service category options based on selected service category
    const uniqueSelectedServiceCategoryId = [
      ...new Set(selectedServiceCategoryId),
    ];
    for (let i = 0; i < uniqueSelectedServiceCategoryId.length; i++) {
      const element = uniqueSelectedServiceCategoryId[i];

      axios
        .get(
          `return/cek_data_request?request_by=${s.request_by}&req_code=${s.request_number}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.data.get_archive_service.filter((item) => item.service_category_id === element);
          const sSC = [];
          const appIds = [];
          const brIds = [];
          console.log(element, "element");
          console.log(data);
          const newFormValues = { ...formValues };
          data.map((s, i) => {
            const service = newFormValues.service_category[i];
            service.registration_number = s.registration_number;
            service.reference_number = s.reference_number;
            sSC.push({
              id: s.get_sub_service_category.id,
              name: s.get_sub_service_category.name
            })
            appIds.push({
              id: s.applicant.id,
              name: s.applicant.name,
            })
            brIds.push({
              id: s.brand.id,
              name: s.brand.name,
            })

          })
          console.log(sSC);
          setOptions((prevOptions) => ({
            ...prevOptions,
            // subServiceCategories: sSC,
            subServiceCategories: {
              ...prevOptions.subServiceCategories,
              [element]: [...sSC],
            },
            applicantIds: {
              ...prevOptions.applicantIds,
              [element]: [...appIds],
            },
            brandIds: {
              ...prevOptions.brandIds,
              [element]: [...brIds],
            }
          }));
          console.log(options);
          console.log(options.subServiceCategories);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedServiceCategoryId]);



  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      let date = new Date(formValues.actual_return_date);
      let ard_day =
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
      let ard_month =
        (date.getMonth() + 1) < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      let ard_year = date.getFullYear();
      formValues.actual_return_date = `${ard_year}-${ard_month}-${ard_day}`;
      console.log(formValues);
      navigate(-1);
      if (actionType === ACTION_TYPE.EDIT) {
        dispatch(editReturn(token, formValues, id));
      } else {
        dispatch(addReturn(token, formValues));
      }
      console.log(formValues);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name } = e.target;
    // validateField(name);
  };

  const handleSettingChange = (serviceIndex, field, value) => {
    const newFormValues = { ...formValues };
    const service = newFormValues.service_category[serviceIndex];

    if (field === "service_category_id") {
      if (service.service_category_id !== value) {
        // Service ID is changing, reset sub_category_service_id
        service.sub_category_service_id = "";
      }
      service.service_category_id = value;
      // Assuming `value` is the ID of the selected service category
      setSelectedServiceCategoryId((prevIds) => [...prevIds, value]);
    } else {
      service[field] = value;
      if (field === "sub_category_service_id") {

        const strData = formValues.service_category.reduce((r, i) => {
          return `${r}${i.sub_category_service_id},`;
        }, "");
        const s = strData.slice(0, -1);
        if (s === "null") {
          formValues.attach_file = [];
          setFormValues(Object.assign({}, {
            attach_file: [],
          }))
        } else {
          getDataAttach();
        }
      }
    }
    validateField(field, serviceIndex);
    setFormValues(newFormValues);
  };

  const handleAttachChange = (attachIndex, field, value) => {
    const newFormValues = { ...formValues };
    const attach = newFormValues.attach_file[attachIndex];
    attach[field] = value;
    setFormValues(newFormValues);
  };

  const validateField = (fieldName, serviceIndex) => {
    const newErrors = { ...errors };
    const serviceErr = newErrors.service_category[serviceIndex];
    const formValuesService = formValues.service_category[serviceIndex];

    if (fieldName === "actual_return_date") {
      newErrors.actual_return_date = !formValues.actual_return_date
        ? "Actual Return Date is required"
        : null;
    }
    if (fieldName === "service_category_id") {
      if (
        !formValuesService.service_category_id ||
        formValues.service_category_id === -1
      ) {
        serviceErr.service_category_id = "Service Category is required";
      } else {
        serviceErr.service_category_id = null;
      }
    }

    if (fieldName === "sub_category_service_id") {
      if (
        !formValuesService.sub_category_service_id ||
        formValues.sub_category_service_id === -1
      ) {
        serviceErr.sub_category_service_id = "Sub Service Category is required";
      } else {
        serviceErr.sub_category_service_id = null;
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    formValues.service_category.forEach((service, serviceIndex) => {
      const serviceCategoryErr = newErrors.service_category[serviceIndex];
      console.log(service.service_category_id);
      if (!service.service_category_id || service.service_category_id === -1) {
        serviceCategoryErr.service_category_id = "Service Category is required";
        valid = false;
      } else {
        serviceCategoryErr.service_category_id = null;
      }
      if (
        !service.sub_category_service_id ||
        service.sub_category_service_id === -1
      ) {
        serviceCategoryErr.sub_category_service_id =
          "Sub Service Category is required";
        valid = false;
      } else {
        serviceCategoryErr.sub_category_service_id = null;
      }
    });

    if (!formValues.actual_return_date) {
      newErrors.actual_return_date = "Actual Return Date is required";
      valid = false;
    } else {
      newErrors.actual_return_date = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const addService = () => {
    const newFormValues = { ...formValues };
    newFormValues.service_category.push({
      service_category_id: "",
      sub_category_service_id: "",
    });
    setFormValues(newFormValues);
    setErrors((prevErrors) => {
      const updateService = [...prevErrors.service_category];
      updateService.push({
        service_category_id: null,
        sub_category_service_id: null,
      });

      return {
        ...prevErrors,
        service_category: updateService,
      };
    });
  };

  const removeService = (serviceIndex) => {
    const newFormValues = { ...formValues }; // Use formValues, not fields
    newFormValues.service_category.splice(serviceIndex, 1);
    setFormValues(newFormValues);
  };

  const handleNameServiceCategory = (id) => {
    const data = options.serviceCategories;
    if (data) {
      const item = data.find(item => item.id === id);
      return item ? item.name : null;
    }
    return null
  }

  const handleNameSubServiceCategory = (id, idSub) => {
    const data = options.subServiceCategories[id];
    if (data) {
      const item = data.find(item => item.id === idSub);
      return item ? item.name : null;
    }
    return null
  }

  const resetFormValues = () => {
    // let date = new Date();
    // let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    // let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    // let year = date.getFullYear();
    setFormValues(Object.assign({}, {
      request_name: "",
      request_date: "",
      return_date: "",
      actual_return_date: "",
      customer_name: "",
      note: "",
      service_category: [
        {
          service_category_id: "",
          sub_category_service_id: "",
          cupboard_id: "",
          rack_seting_id: "",
        },
      ],
      attach_file: [],
    }))
  }

  const fetchRequestData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/return/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data.data;
      const actual_return_date = dayjs(data.actual_return_date).isValid()
        ? dayjs(data.actual_return_date)
        : dayjs(data.actual_return_date).format("DD/MM/YYYY");

      let service_category = [];
      let attach_file = [];
      for (let i of data.get_return) {
        service_category.push({
          service_category_id: i.get_service_category.id,
          sub_category_service_id: i.get_sub_service_category.id,
        });
        setSelectedServiceCategoryId((prevIds) => [...prevIds, i.service_category_id]);
      }

      console.log(selectedServiceCategoryId, "selectedServiceCategoryId");
      for (let i of data.get_attachment) {
        attach_file.push({
          id_attachment: i.id_attachment,
          services_category_id: i.services_category_id,
          sub_services_category_id: i.sub_services_category_id,
          is_checked: i.is_checked,
          desc: i.desc,
          status_loan: i.status_loan,
        });
      }
      setFormValues((prevValues) => ({
        ...prevValues,
        ["id"]: id,
        ["actual_return_date"]: actual_return_date,
        ["note"]: data.note,
        ["service_category"]: service_category,
        ["attach_file"]: attach_file,
      }));
    } catch (error) {
      console.error("Error fetching archive data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDataAttach = () => {
    const s = state.data;
    axios
      .get(`return/cek_data_request?request_by=${s.request_by}&req_code=${s.request_number}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        formValues.attach_file = [];
        const newFormValues = { ...formValues };
        for (const i of response.data.data.get_attachment) {
          newFormValues.attach_file.push({
            id_attachment: i.id_attachment,
            services_category_id: i.services_category_id,
            sub_services_category_id: i.sub_services_category_id,
            is_checked: i.is_checked,
            desc: i.desc,
          });
        }
        setFormValues(newFormValues);
      })
      .catch((error) => {
        console.error("Error fetching sub-service categories:", error);
      });

  };


  const renderFormContent = () => {
    return (
      <Box
        display="flex"
        sx={{ gap: "1.5rem" }}
        style={{ overflow: "auto", maxHeight: "480px" }}
      >
        <Box flex="1" style={{ overflow: "auto" }}>
          {fields.map((field) => {
            const fieldOptions = Array.isArray(options[field.name])
              ? [...options[field.name]]
              : [];

            switch (field.type) {
              case INPUT_TYPE.TEXT:
                if (field.name == "request_name") {
                  return (
                    <TextInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                }
                return null;

              default:
                return null;
            }
          })}
          <Box display="flex">
            {fields
              .filter(
                (field) =>
                  field.type === INPUT_TYPE.DATE && field.additionalProps
              )
              .map((field, index) => {
                switch (field.type) {
                  case INPUT_TYPE.DATE:
                    return (
                      <Box flex="1" sx={{ marginRight: index === 1 ? 0 : 1 }}>
                        <DatePickerInput
                          key={field.name}
                          field={field}
                          formValues={formValues}
                          errors={errors}
                          handleInputChange={handleInputChange}
                          handleInputBlur={handleInputBlur}
                        />
                      </Box>
                    );
                  default:
                    return null;
                }
              })}
          </Box>
          {fields.map((field) => {
            const fieldOptions = Array.isArray(options[field.name])
              ? [...options[field.name]]
              : [];
            const len = fields.filter(
              (field) => field.type === INPUT_TYPE.DATE
            );

            switch (field.type) {
              case INPUT_TYPE.TEXT:
                if (field.name == "customer_name") {
                  return (
                    <TextInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                }
                return null;
              case INPUT_TYPE.TEXTAREA:
                return (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.DIVIDER:
                return <Divider />;
              case INPUT_TYPE.DATE:
                if (field.additionalProps) {
                  return <></>;
                }
                return (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              default:
                return null;
            }
          })}
          {fields.map((field) => {
            if (field.name === "service_category") {
              return (
                <div
                  key={field.name}
                  style={{ marginTop: 16, marginBottom: 16 }}
                >
                  <Typography variant="h6">Service</Typography>
                  <Typography sx={{ marginBottom: 1.5 }}>
                    Specify the service requested and to be returned
                  </Typography>
                  {formValues.service_category.map((service, serviceIndex) => {
                    const otherServiceIds = formValues.service_category
                      .filter((_, index) => index !== serviceIndex)
                      .map((otherService) => otherService.service_category_id);
                    // const otherCupboardIds = formValues.service_category
                    //     .filter((_, index) => index !== serviceIndex)
                    //     .map((otherService) => otherService.cupboard_id);
                    const cup = cupboards.filter(
                      (item) => item.id === service.service_category_id
                    );

                    const fieldOptions = {
                      serviceCategories: [
                        ...(options.serviceCategories || []).filter(
                          (category) => !otherServiceIds.includes(category.id)
                        ),
                      ],
                      subServiceCategories: [
                        ...(options.subServiceCategories[service.service_category_id] || []),
                      ],
                      applicantIds: [
                        ...(options.applicantIds[service.service_category_id] || []),
                      ],
                      brandIds: [
                        ...(options.brandIds[service.service_category_id] || []),
                      ],
                    };


                    const serviceErrors =
                      errors.service_category[serviceIndex] || {};
                    return (
                      <div key={serviceIndex}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Box style={{ flex: 11 }}>
                            <InputLabel>Service Category</InputLabel>
                            <Autocomplete
                              fullWidth
                              size="small"
                              options={fieldOptions["serviceCategories"]}
                              getOptionLabel={(category) => category.name}
                              value={
                                fieldOptions["serviceCategories"].find(
                                  (option) =>
                                    option.id === service.service_category_id
                                ) || null
                              }
                              onChange={(_, newValue) =>
                                handleSettingChange(
                                  serviceIndex,
                                  "service_category_id",
                                  newValue?.id || null
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  error={serviceErrors.service_category_id}
                                  placeholder={`Select Service Category `}
                                  sx={{
                                    ...formGroup,
                                    marginBottom: "0.2rem",
                                  }}
                                />
                              )}
                            />
                          </Box>
                          <Box style={{ flex: 1, alignItems: "center" }}>
                            <InputLabel>&nbsp;</InputLabel>
                            <Button
                              variant="contained"
                              disableElevation
                              sx={{
                                // backgroundColor: (theme) =>
                                //   theme.palette.primary.main,
                                // marginTop: 2,
                                height: "38px",
                                backgroundColor: formValues.service_category.length == 1 ? `${dangerSurfaceColor} !important` : `${dangerMainColor} !important`,
                                ...removeButton,
                              }}
                              onClick={() => removeService(serviceIndex)}
                              disabled={formValues.service_category.length == 1}
                            >
                              <img src={RemoveIcon} />
                            </Button>
                          </Box>
                        </div>
                        {formValues.service_category[serviceIndex]
                          .service_category_id ? (
                          <>
                            <InputLabel>Sub Category Service</InputLabel>
                            <Autocomplete
                              fullWidth
                              size="small"
                              options={fieldOptions["subServiceCategories"]}
                              getOptionLabel={(option) => option.name}
                              value={
                                fieldOptions["subServiceCategories"].find(
                                  (option) =>
                                    option.id ===
                                    service.sub_category_service_id
                                ) || null
                              }
                              onChange={(_, newValues) =>
                                handleSettingChange(
                                  serviceIndex,
                                  "sub_category_service_id",
                                  newValues?.id || null
                                )
                              }

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  error={serviceErrors.sub_category_service_id}
                                  placeholder={`Select Sub Category Service`}
                                  sx={{ ...formGroup, marginBottom: "0.2rem" }}
                                />
                              )}
                            />
                            {/* {serviceErrors.sub_category_service_id && (
                              <FormHelperText
                                error
                                sx={{
                                  color: "#fb2525",
                                  fontSize: "1rem",
                                  margin: "0.3rem 0 0",
                                }}
                              >
                                {serviceErrors.sub_category_service_id}
                              </FormHelperText>
                            )} */}
                            <Box>
                              <InputLabel>Registration Number</InputLabel>
                              <TextField
                                fullWidth
                                placeholder={`Input Registration Number`}
                                value={service["registration_number"] || null}
                                margin="normal"
                                size="small"
                                variant="outlined"
                                error={serviceErrors.registration_number}
                                sx={{ ...formGroup }}
                                onChange={(event) =>
                                  handleSettingChange(
                                    serviceIndex,
                                    "registration_number",
                                    event.target.value || null
                                  )
                                }
                              />
                            </Box>
                            <Box>
                              <InputLabel>Reference Number</InputLabel>
                              <TextField
                                fullWidth
                                placeholder={`Input Reference Number`}
                                value={service["reference_number"] || null}
                                margin="normal"
                                size="small"
                                variant="outlined"
                                error={serviceErrors.reference_number}
                                sx={{ ...formGroup }}
                                onChange={(event) =>
                                  handleSettingChange(
                                    serviceIndex,
                                    "reference_number",
                                    event.target.value || null
                                  )
                                }
                              />
                            </Box>
                            <Box>
                              <InputLabel>Brand</InputLabel>
                              <Autocomplete
                                fullWidth
                                size="small"
                                options={fieldOptions["brandIds"]}
                                getOptionLabel={(value) => value.name}
                                value={
                                  fieldOptions["brandIds"].find(
                                    (option) => option.id === service.brand_id
                                  ) || null
                                }
                                onChange={(_, newValue) =>
                                  handleSettingChange(
                                    serviceIndex,
                                    "brand_id",
                                    newValue?.id || null
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={serviceErrors.brand_id}
                                    variant="outlined"
                                    placeholder={`Select Brand`}
                                    sx={{
                                      ...formGroup,
                                      marginBottom: "0.2rem",
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <Box>
                              <InputLabel>Applicant</InputLabel>
                              <Autocomplete
                                fullWidth
                                size="small"
                                options={fieldOptions["applicantIds"]}
                                getOptionLabel={(value) => value.name}
                                value={
                                  fieldOptions["applicantIds"].find(
                                    (option) => option.id === service.applicant_id
                                  ) || null
                                }
                                onChange={(_, newValue) =>
                                  handleSettingChange(
                                    serviceIndex,
                                    "applicant_id",
                                    newValue?.id || null
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={serviceErrors.applicant_id}
                                    variant="outlined"
                                    placeholder={`Select Applicant`}
                                    sx={{
                                      ...formGroup,
                                      marginBottom: "0.2rem",
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <div style={{ display: "flex", height: "80px" }}>
                              <Box style={{ flex: 1 }}>
                                <InputLabel>Cupboard Name</InputLabel>
                                <TextField
                                  fullWidth
                                  placeholder="Cupboard Name"
                                  size="small"
                                  sx={{

                                    background: "#f5f5f5"
                                  }}
                                  disabled
                                  value={
                                    cup.length < 1 ? "" : cup[0].cupboardName
                                  }
                                />
                              </Box>
                              <Box style={{ flex: 1, marginLeft: "8px" }}>
                                <InputLabel>Rack Number</InputLabel>
                                <TextField
                                  fullWidth
                                  placeholder="Rack Number"
                                  size="small"
                                  sx={{
                                    background: "#f5f5f5"
                                  }}
                                  disabled
                                  value={
                                    cup.length < 1 ? "" : cup[0].rackNumber
                                  }
                                />
                              </Box>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                  {formValues.service_category.length <
                    options.serviceCategories.length ? (
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                        textAlign: "right",
                      }}
                      onClick={addService}
                    >
                      Add Another Service
                    </Typography>
                  ) : (
                    <Box sx={{ marginBottom: "300px" }} />
                  )}
                </div>
              );
            }
          })}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex="1" style={{ overflow: "auto" }}>
          <Box flex="1" sx={{ overflow: "auto", maxHeight: "56vh" }}>
            {fields.map((field) => {
              if (field.name === "attach_file") {
                return (
                  <div
                    key={field.name}
                    style={{ marginTop: 16, marginBottom: 16 }}
                  >
                    <Typography variant="h6">Attachment File List</Typography>
                    <Typography>
                      Check the list of file attachments that you want to return
                      from the requested service.
                    </Typography>
                    {formValues.attach_file.length === 0 ? (
                      <>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          height="50vh"
                        >
                          <img
                            src={EmptyAttachment}
                            alt="Centered Image"
                            style={{ maxWidth: "100%" }}
                          />
                          <Typography
                            sx={{ color: "#616161", fontStyle: "italic" }}
                          >
                            Specify the requested service first
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}

                    {
                      formValues.service_category && formValues.service_category.map((service, serviceIndex) => (
                        <>
                          <Box>
                            <Box sx={{ display: "flex", marginY: "4px" }}>
                              <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${serviceIndex + 1}`}</Typography></Avatar>
                              <Typography> {` ${handleNameServiceCategory(service.service_category_id)} - ${handleNameSubServiceCategory(service.service_category_id, service.sub_category_service_id)}`}</Typography>
                            </Box>
                            {
                              formValues.attach_file.map((attach, attachIndex) => (
                                attach.services_category_id === service.service_category_id && attach.sub_services_category_id === service.sub_category_service_id && (
                                  <div key={attachIndex} style={{ display: "flex", alignItems: "center" }}>
                                    <Box>
                                      <Checkbox
                                        checked={attach.status_loan ? false : attach.is_checked}
                                        sx={{ paddingLeft: 0 }}
                                        disabled={attach.status_loan}
                                        // color={neutral60}
                                        onChange={(e) =>
                                          handleAttachChange(
                                            attachIndex,
                                            "is_checked",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </Box>
                                    <Box sx={{ flex: 1 }} >
                                      <Typography sx={{ color: attach.status_loan ? neutral60 : neutral100 }}>
                                        {attach.desc}
                                      </Typography>
                                    </Box>
                                    {
                                      attach.status_loan && (
                                        <Box >
                                          <Tooltip title="This attached file is being borrowed" sx={{ margin: 0, padding: 0 }}>
                                            <IconButton aria-label="Image">
                                              <img src={InfoIcon} />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )
                                    }
                                  </div>
                                )
                              ))
                            }
                          </Box>
                        </>
                      ))
                    }
                    {/* {formValues.attach_file.map((attach, attachIndex) => {
                      return (
                        <div
                          key={attachIndex}
                          style={{ display: "flex", alignItems: "center" }} >
                          <Box sx={{ flex: 1 }}>
                            <Checkbox
                              checked={attach.is_checked}
                              onChange={(e) =>
                                handleAttachChange(
                                  attachIndex,
                                  "is_checked",
                                  e.target.checked
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ flex: 11 }}>
                            <Typography>{attach.desc}</Typography>
                          </Box>
                        </div>
                      );
                    })} */}
                  </div>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => {
            resetFormValues();
            navigate(-1)
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        // onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <Card
      style={{
        marginTop: "1rem",
        boxShadow: "unset",
        borderRadius,
        border: "1px solid #ddd",
        height: "81vh",
        position: "relative",
      }}
    >
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.4rem",
              fontWeight: 800,
              paddingLeft: "1rem",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              variant="outlined"
              onClick={() => navigate(-1)}
              sx={{ marginRight: ".5rem" }}
            >
              <img src={ArrowBackIcon} />
            </IconButton>
            {title}
          </div>
        }
      />
      <Divider />
      {isLoading ? (
        <FacebookCircularProgress />
      ) : (
        <form ref={formRef} onSubmit={handleSubmit}>
          <CardContent sx={{ padding: "1.7rem" }}>
            {renderFormContent()}
          </CardContent>
          <CardActions
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "100%",
              justifyContent: "flex-end",
              background: "white",
              padding: "20px",
            }}
          >
            {renderActionButtons()}
          </CardActions>
        </form>
      )}
    </Card>
  );
};
export default AddReturn;
