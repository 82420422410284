import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Chip,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Allowance from "./allowance/allowance";
import MealAllowance from "./meal_allowance/meal_allowance";

import {
  borderRadius,
  secondColor,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  inputDate,
  btnTableToolbar,
  imgBtnToolbar,
  fourColor,
  FacebookCircularProgress,
  tabStyles,
  CustomOpenPickerButton,
} from "../../util/style";
import exportIcon from "./../../assets/export.png";
import axios, { urlRequest } from "../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../components/Form";
import {
  getBenefits,
  addBenefits,
  editBenefits,
  deleteBenefits,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  TabPanel,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { RequestLoading } from "../../util/global_state";

const Benefits = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const TYPE_BENEFIT = {
    ALLOWANCES: "Allowances",
    MEAL_ALLOWANCES: "Meal Allowances",
  };
  const { token } = useSelector((state) => state.auth);
  const [typeBenefit, setTypeBenefit] = useState(TYPE_BENEFIT.ALLOWANCES);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [period, setPeriod] = useState(null);
  const [periodElm, setPeriodElm] = useState(null);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.DEFAULT_DATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    if (period) {
      setShowSelectDate(false);
      // dispatch(
      //   getUserLogs({
      //     token,
      //     limit,
      //     search,
      //     status,
      //     sortDirection,
      //     sortBy,
      //     dateFilter,
      //     startDate,
      //     endDate,
      //     action,
      //   })
      // );
    }
  }, [period]);

  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handlePeriodChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM");
      setPeriod(formattedDate);
      setPeriodElm(date);
    } else {
      console.error("Invalid start date:", date);
    }
  };

  const handleSelectClose = (e) => {
    if (e) e.stopPropagation();
    setShowDatePicker(false);
    setShowSelectDate(false);
  };

  function _dateFilter(date) {
    if (date === "custom_date") {
      setShowDatePicker(true);
      setDateFilter(date);

    } else {
      setShowSelectDate(false);

      if ((dateFilter == 'custom_date' && (date !== 'custom_date' && date !== undefined))) {
        setShowDatePicker(false);

        setPeriod(null);
        setPeriodElm(null);
      }

      if (date) {
        setDateFilter(date);
      }

    }
  }

  return (
    <>
      {/*
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          marginTop: "1rem",
        }}
      >
        <CardContent>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={3}>
              <Box sx={tabStyles}>
                <Chip
                  label={TYPE_BENEFIT.ALLOWANCES}
                  className={
                    typeBenefit === TYPE_BENEFIT.ALLOWANCES
                      ? "activeChip"
                      : "inactiveChip"
                  }
                  onClick={() => setTypeBenefit(TYPE_BENEFIT.ALLOWANCES)}
                  color={
                    typeBenefit === TYPE_BENEFIT.ALLOWANCES
                      ? "primary"
                      : "default"
                  }
                />
                <Chip
                  label={TYPE_BENEFIT.MEAL_ALLOWANCES}
                  className={
                    typeBenefit === TYPE_BENEFIT.MEAL_ALLOWANCES
                      ? "activeChip"
                      : "inactiveChip"
                  }
                  onClick={() => setTypeBenefit(TYPE_BENEFIT.MEAL_ALLOWANCES)}
                  color={
                    typeBenefit === TYPE_BENEFIT.MEAL_ALLOWANCES
                      ? "primary"
                      : "default"
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                  open: showSelectDate, // Set the open prop based on showDatePicker
                  onClose: () => handleSelectClose, // Close the menu on selecting an option
                  onOpen: () => setShowSelectDate(true),
                  // onOpen: () => date !== 'custom_date' , // Open the menu when "Custom Date" is
                }}
                sx={{
                  borderRadius,
                }}
                value={dateFilter}
                onChange={(e) => _dateFilter(e.target.value)}
                MenuProps={{
                  disableCloseOnSelect: true,
                }}
              >
                <MenuItem sx={{
                  outline: '1px solid red', position: 'absolute', right: 0, padding: '1px 5px', fontWeight: '500', borderRadius: 50, color: 'red', zIndex: '999', width: 'auto', height: '16px', display: 'flex',
                  justifyContent: 'center',
                  top: '1.12rem',
                  right: '1rem',
                }}> Close </MenuItem>
                <MenuItem value={ACTION_TYPE.DEFAULT_DATE}>
                  Select Period
                </MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value={ACTION_TYPE.ALL_DATE}>All Period</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="custom_date">Set Month</MenuItem>
                {showDatePicker && (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                    <Grid container p={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bolder" }}
                        >
                          Month & Year Period
                        </Typography>
                        <DatePicker
                          selectedDate={periodElm}
                          onChange={handlePeriodChange}
                          components={{
                            OpenPickerButton: CustomOpenPickerButton,
                          }}
                          format="MMMM YYYY"
                          views={["month", "year"]}
                          value={periodElm}
                          sx={{
                            ...inputDate,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                )}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <TabPanel value={typeBenefit} index={TYPE_BENEFIT.ALLOWANCES}>
            <Allowance dateFilter={dateFilter} period={period} />
          </TabPanel>
          <TabPanel value={typeBenefit} index={TYPE_BENEFIT.MEAL_ALLOWANCES}>
            <MealAllowance dateFilter={dateFilter} period={period} />
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};

export default Benefits;
