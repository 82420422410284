import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    IconButton,
    Box,
    Button,
    Typography,
    Autocomplete,
    TextField,
    InputLabel,
    Checkbox,
    FormHelperText,
    Avatar,
} from "@mui/material";
import {
    FacebookCircularProgress,
    borderRadius,
    btnWhite,
    formGroup,
    neutral20,
    neutral30,
    dangerMainColor,
    dangerSurfaceColor,
    removeButton,
    infoSurfaceColor,
    infoMainColor,
    mainColor,
} from "../../../util/style";
import {
    ACTION_TYPE,
    USER_ACCESS as _,
    findMenuItemByLink,
    INPUT_TYPE,
    SELECT_OPTION,
} from "../../../util/function";
import EmptyAttachment from "./../../../assets/empty-attachment.svg";
import ArrowBackIcon from "./../../../assets/back.svg";
import RemoveIcon from "./../../../assets/minus.svg";
import InfoIcon from "./../../../assets/info-circle.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import { CheckboxGroupInput, DatePickerInput, SelectMultiple, SelectMultipleAutocomplete, SelectOne, SelectOneAutocomplete, TextArea, TextInput } from "../../../components/Form";
import axios from "../../../core/axios_config";
import { addArchive, editArchive } from "./clients/actions";
import dayjs from "dayjs";




const AddArchive = () => {
    const [title, setTitle] = useState("Add Archive");
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useSelector(state => state.auth)
    const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState([]);
    const [selectedSubServiceCategoryId, setSelectedSubServiceCategoryId] = useState([]);
    const [selectedCupboardId, setSelectedCupboardId] = useState([]);
    const [listServices, setListServices] = useState([]);
    const [actionType, setActionType] = useState();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();

    const fields = [
        {
            name: "date",
            type: INPUT_TYPE.DATE,
            label: "Date Created",
            endIcon: <img src={CalendarIcon} />,
            formatDate: "DD/MM/YYYY", // Custom date format
            defaultValue: "",
            required: true,
            additionalProps: {
                disabled: id ? true : false,
            }
        },
        {
            name: "customer_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Client Name",
            defaultValue: "",
            apiEndpoint: "/master/clients",
            required: true,
            additionalProps: {
                disabled: id ? true : false,
            }
        },
        {
            name: "note",
            type: INPUT_TYPE.TEXTAREA,
            label: "Note (Ops)",
            defaultValue: "",
            required: false,
        },
        {
            name: "divider",
            type: INPUT_TYPE.DIVIDER,
        },
        {
            name: "service_category",
            type: "service_category",
            label: "Service Category",
            defaultValue: [
                {
                    service_category_id: "",
                    sub_service_category_id: "",
                    applicant_id: "",
                    brand_id: "",
                    registration_number: "",
                    reference_number: "",
                    cupboard_id: "",
                    rack_seting_id: "",
                }
            ]
        },
        {
            name: "attach_file",
            type: "attach_file",
            label: "Attach File",
            defaultValue: [
                // {
                //     is_checked: false,
                //     desc: ""
                // },
            ]
        },
    ];

    useEffect(() => {
        let name = "";
        if (state && state.type) {
            name = `${state.type}`
        }
        if (id) {
            setTitle(`Edit Archive ${name}`)
            setActionType(ACTION_TYPE.EDIT);
            fetchArchiveData();
        } else {
            resetFormValues();
            setTitle(`Add Archive ${name}`)
            setActionType(ACTION_TYPE.ADD);
        }
    }, [id])

    const fetchArchiveData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/archive/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            let data = response.data.data;

            const date = dayjs(data.date).isValid() ? dayjs(data.date) : dayjs(data.date).format("DD/MM/YYYY");
            console.log(data.date.replaceAll("-", "/"));
            // formValues.date = data["date"].replace("-", "/")
            let service_category = [];
            let attach_file = [];
            getListApplicant(data.customer_id)
            getListBrand(data.customer_id)
            for (let i of data.service_category) {
                service_category.push({
                    service_category_id: i.service_category_id,
                    sub_service_category_id: i.sub_service_category_id,
                    applicant_id: i.applicant_id,
                    brand_id: parseInt(i.brand_id),
                    reference_number: i.reference_number,
                    registration_number: i.registration_number,
                    cupboard_id: i.cupboard_id,
                    rack_seting_id: i.rack_id,
                });
                setSelectedServiceCategoryId((prevIds) => [...prevIds, i.service_category_id]);
                setSelectedCupboardId((prevIds) => [...prevIds, i.cupboard_id]);
            }
            const newErrors = { ...errors };
            for (let i of data.attachment_list) {
                attach_file.push({
                    id_attachment: i.id,
                    services_category_id: i.services_category_id,
                    sub_services_category_id: i.sub_services_category_id,
                    is_checked: i.is_checked,
                    desc: i.desc
                });
                newErrors.attach_file.push({
                    id_attachment: null
                })
            }
            console.log("service_category");
            console.log(service_category);
            data.service_category.forEach((service, serviceIndex) => {
                newErrors.service_category[serviceIndex] = {
                    service_category_id: null,
                    sub_service_category_id: null,
                    applicant_id: null,
                    brand_id: null,
                    registration_number: null,
                    reference_number: null,
                    cupboard_id: null,
                    rack_seting_id: null,
                }
            })
            setErrors(newErrors);
            setFormValues((prevValues) => ({
                ...prevValues,
                ['date']: date,
                ['note']: data.note,
                ['customer_id']: data.customer_id,
                ['service_category']: service_category,
                ['attach_file']: attach_file,
            }));
        } catch (error) {
            console.error("Error fetching archive data:", error);
        } finally {
            setIsLoading(false);
        }

    }

    const initialFormValues = (type = "fields") => {
        return fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
            {}
        );
    };

    const initialErrors = {
        date: "",
        customer_id: "",
        note: "",
        service_category: [{
            service_category_id: null,
            sub_service_category_id: null,
            applicant_id: null,
            brand_id: null,
            registration_number: null,
            reference_number: null,
            cupboard_id: null,
            rack_seting_id: null,
        }],
        attach_file: [],
    };
    const [errors, setErrors] = useState(initialErrors);

    const [formValues, setFormValues] = useState(
        Object.assign({}, initialFormValues("fields"), { id: null, status: false })
    );

    const [options, setOptions] = useState({
        serviceCategories: [],
        subServiceCategories: [],
        cupboardIds: [],
        rackNumbers: [],
        attachChecked: [],
        attachDesc: [],
    });

    // useEffect
    useEffect(() => {
        const fetchOptions = async (apiEndpoint, name) => {
            try {
                await axios.get(apiEndpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    const modifiedData = response.data.data.map((item) => {
                        if (item.hasOwnProperty("fullname")) {
                            return {
                                ...item,
                                name: item.fullname,
                            };
                        } else {
                            return item;
                        }
                    });
                    console.log(name, response.data.data);
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        [name]: modifiedData,
                    }));
                })
            } catch (error) {
                console.error(`Error fetching ${name} options:`, error);
            }
        };

        fields.forEach((field) => {
            const { name, apiEndpoint } = field;
            if (apiEndpoint) {
                fetchOptions(apiEndpoint, name);
            }
        });

        axios
            .get("/master/services", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    serviceCategories: response.data.data,
                }));
            })
            .catch((error) => {
                console.error("Error fetching service categories:", error);
            });

        axios
            .get("cupboard", {
                params: {
                    limit: 1000
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log(response.data.data);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    cupboardIds: response.data.data,
                }));
            })
            .catch((error) => {
                console.error("Error fetching cupboard:", error);
            });
    }, [])

    useEffect(() => {
        for (const i of selectedCupboardId) {
            if (selectedCupboardId) {
                axios
                    .get(`cupboard/${i}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        console.log(response.data.data.detail_rack);
                        setOptions((prevOptions) => ({
                            ...prevOptions,
                            rackNumbers: {
                                ...prevOptions.rackNumbers, // Preserve existing data
                                [i]: response.data.data.detail_rack,
                            },
                        }));
                        console.log(options);
                        // debugger;
                    })
                    .catch((error) => {
                        console.error("Error fetching sub-service categories:", error);
                    });
            }
        }
    }, [selectedCupboardId])

    useEffect(() => {
        // Fetch sub-service category options based on selected service category
        for (const i of selectedServiceCategoryId) {
            if (selectedServiceCategoryId) {
                axios
                    .get(`/master/services`, {
                        params: {
                            main_service_id: i,
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        setOptions((prevOptions) => ({
                            ...prevOptions,
                            subServiceCategories: {
                                ...prevOptions.subServiceCategories, // Preserve existing data
                                [i]: response.data.data,
                            },
                        }));
                        console.log(options);
                        // debugger;
                    })
                    .catch((error) => {
                        console.error("Error fetching sub-service categories:", error);
                    });
            }
        }
    }, [selectedServiceCategoryId]);

    useEffect(() => {
        console.log("masuk");
        const strData = formValues.service_category.reduce((r, i) => {
            return `${r}${i.sub_service_category_id},`;
        }, "")
        console.log(`${strData.slice(0, -1)}`);
        axios
            .get(`/master/services-attachment/${strData.slice(0, -1)}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                formValues.attach_file = [];
                const newFormValues = { ...formValues };
                const newErrors = { ...errors };
                for (const i of response.data.data) {
                    newFormValues.attach_file.push({
                        id_attachment: i.id,
                        services_category_id: i.id_service_category_name,
                        sub_services_category_id: i.id_sub_service_category_name,
                        is_checked: false,
                        is_mandatory: i.is_mandatory,
                        desc: i.attachment_name,
                    })
                    newErrors.attach_file.push({
                        id_attachment: null
                    })
                }
                console.log(newFormValues.attach_file, "newFormValues.attach_file");
                setFormValues(newFormValues)
                setErrors(newErrors)

            })
            .catch((error) => {
                console.error("Error fetching sub-service categories:", error);
            });
    }, [selectedSubServiceCategoryId || selectedServiceCategoryId])



    // handle
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            let date = new Date(formValues.date);
            let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
            let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
            let year = date.getFullYear();
            formValues.date = `${year}-${month}-${day}`;
            console.log("formValues");
            console.log(formValues);
            if (actionType === ACTION_TYPE.EDIT) {
                dispatch(editArchive(token, formValues, id));
            } else {
                dispatch(addArchive(token, formValues));
            }
            setTimeout(resetFormValues, 2700);
            navigate(-1);
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "service_category_id") {
            setSelectedServiceCategoryId((prevIds) => [...prevIds, value]);
        }
        if (name === "cupboard_id") {
            setSelectedCupboardId((prevIds) => [...prevIds, value]);
        }
        if (name === "customer_id") {
            getListServices(value)
            getListApplicant(value)
            getListBrand(value)
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleInputBlur = (e) => {
        const { name } = e.target;
        validateField(name);
    };

    const handleSettingChange = (serviceIndex, field, value) => {
        const newFormValues = { ...formValues };
        const service = newFormValues.service_category[serviceIndex];

        if (field === "service_category_id") {
            if (service.service_category_id !== value) {
                const newSelectedSubServiceCategoryId = [...selectedSubServiceCategoryId];
                service.sub_service_category_id = '';
                newSelectedSubServiceCategoryId.splice(serviceIndex, 1);
                setSelectedSubServiceCategoryId(newSelectedSubServiceCategoryId);
            }
            service.service_category_id = value;
            setSelectedServiceCategoryId((prevIds) => [...prevIds, value]);
        } else if (field === "cupboard_id") {
            if (service.cupboard_id !== value) {
                service.rack_seting_id = '';
            }
            service.cupboard_id = value;
            setSelectedCupboardId((prevIds) => [...prevIds, value]);
        } else if (field === "registration_number") {
            service.registration_number = value;
        } else if (field === "reference_number") {
            service.reference_number = value;
        } else {
            service[field] = value;
            if (field === "sub_service_category_id") {
                setSelectedSubServiceCategoryId((prevIds) => [...prevIds, value])
            }
        }
        validateField(field, serviceIndex);
        setFormValues(newFormValues);
    };

    const handleAttachChange = (attachIndex, field, value) => {
        const newFormValues = { ...formValues };
        const attach = newFormValues.attach_file[attachIndex];
        attach[field] = value;
        setFormValues(newFormValues);
    }

    const handleNameServiceCategory = (id) => {
        const data = options.serviceCategories;
        if (data) {
            const item = data.find(item => item.id === id);
            return item ? item.name : null;
        }
        return null
    }
    const handleNameSubServiceCategory = (id, idSub) => {
        const data = options.subServiceCategories[id];
        if (data) {
            const item = data.find(item => item.id === idSub);
            return item ? item.name : null;
        }
        return null
    }

    const getListApplicant = (i) => {
        axios
            .get(`master/applicants`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { client_id: i },
            })
            .then((response) => {
                const data = response.data;
                if (data.status) {
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        applicantIds: data.data,
                    }));
                }
            })
            .catch((error) => {
                console.error("Error fetching sub-service categories:", error);
            });
    }

    const getListBrand = (i) => {
        axios
            .get("master/brands", {
                params: { client_id: i },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log(response.data.data);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    brandIds: response.data.data,
                }));
            })
            .catch((error) => {
                console.error("Error fetching cupboard:", error);
            });
    }

    const getListServices = (i) => {
        axios
            .get(`master/customer`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    customer_id: i,
                },

            })
            .then((response) => {

                const data = response.data;
                if (data.status) {
                    setListServices(data.data);
                } else {
                    setListServices([]);
                }
                // debugger;
            })
            .catch((error) => {
                console.error("Error fetching sub-service categories:", error);
            });
    }

    const resetFormValues = () => {
        let date = new Date();
        let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
        let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
        let year = date.getFullYear();
        setFormValues(
            Object.assign({}, {
                date: `${year}-${month}-${day}`,
                customer_id: "",
                note: "",
                service_category: [
                    {
                        service_category_id: "",
                        sub_service_category_id: "",
                        applicant_id: "",
                        brand_id: "",
                        registration_number: "",
                        reference_number: "",
                        cupboard_id: "",
                        rack_seting_id: "",
                    }
                ],
                attach_file: []
            })
        );
        setOptions({
            serviceCategories: [],
            subServiceCategories: [],
            applicantIds: [],
            cupboardIds: [],
            rackNumbers: [],
            attachChecked: [],
            attachDesc: [],
        });
        setSelectedServiceCategoryId([])
        setSelectedSubServiceCategoryId([])
        setSelectedCupboardId([])

    }

    const validateField = (fieldName, serviceIndex) => {
        const newErrors = { ...errors };
        const serviceErr = newErrors.service_category[serviceIndex]
        const attachErr = newErrors.attach_file[serviceIndex]
        const formValuesService = formValues.service_category[serviceIndex];

        if (fieldName === "date") {
            newErrors.date = !formValues.date
                ? "Date Created is required"
                : null
        }

        if (fieldName === "customer_id") {
            newErrors.customer_id = (!formValues.customer_id || formValues.customer_id === -1)
                ? "Customer Name is required"
                : null
        }
        if (fieldName === "registration_number") {
            serviceErr.registration_number = !formValuesService.registration_number
                ? "Registration Number is required"
                : null
        }
        if (fieldName === "reference_number") {
            serviceErr.reference_number = !formValuesService.reference_number
                ? "Reference Number is required"
                : null
        }
        if (fieldName === "service_category_id") {
            if (!formValuesService.service_category_id || formValues.service_category_id === -1) {
                serviceErr.service_category_id = "Service Category is required";
            } else {
                serviceErr.service_category_id = null;
            }
        }
        if (fieldName === "sub_service_category_id") {
            if (!formValuesService.sub_service_category_id || formValues.sub_service_category_id === -1) {
                serviceErr.sub_service_category_id = "Sub Service Category is required";
            } else {
                serviceErr.sub_service_category_id = null;
            }
        }
        if (fieldName === "applicant_id") {
            if (!formValuesService.applicant_id || formValues.applicant_id === -1) {
                serviceErr.applicant_id = "Applicant is required";
            } else {
                serviceErr.applicant_id = null;
            }
        }
        if (fieldName === "brand_id") {
            if (!formValuesService.brand_id || formValues.brand_id === -1) {
                serviceErr.brand_id = "Brand is required";
            } else {
                serviceErr.brand_id = null;
            }
        }
        if (fieldName === "cupboard_id") {
            if (!formValuesService.cupboard_id || formValues.cupboard_id === -1) {
                serviceErr.cupboard_id = "Cupboard Name is required";
            } else {
                serviceErr.cupboard_id = null;
            }
        }
        if (fieldName === "rack_seting_id") {
            if (!formValuesService.rack_seting_id || formValues.rack_seting_id === -1) {
                serviceErr.rack_seting_id = "Rack Number is required";
            } else {
                serviceErr.rack_seting_id = null;
            }
        }

        setErrors(newErrors);
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };

        formValues.service_category.forEach((service, serviceIndex) => {
            const serviceCategoryErr = newErrors.service_category[serviceIndex];
            if (!service.service_category_id || service.service_category_id === -1) {
                serviceCategoryErr.service_category_id = "Service Category is required";
                valid = false;
            } else {
                serviceCategoryErr.service_category_id = null
            }
            if (!service.sub_service_category_id || service.sub_service_category_id === -1) {
                serviceCategoryErr.sub_service_category_id = "Sub Service Category is required";
                valid = false;
            } else {
                serviceCategoryErr.sub_service_category_id = null
            }
            if (!service.applicant_id || service.applicant_id === -1) {
                serviceCategoryErr.applicant_id = "Applicant is required";
                valid = false;
            } else {
                serviceCategoryErr.applicant_id = null
            }
            if (!service.brand_id || service.brand_id === -1) {
                serviceCategoryErr.brand_id = "Brand is required";
                valid = false;
            } else {
                serviceCategoryErr.brand_id = null
            }
            if (!service.registration_number) {
                serviceCategoryErr.registration_number = "Registration Number is required";
                valid = false;
            } else {
                serviceCategoryErr.registration_number = null
            }
            if (!service.reference_number) {
                serviceCategoryErr.reference_number = "Reference Number is required";
                valid = false;
            } else {
                serviceCategoryErr.reference_number = null
            }
            if (!service.cupboard_id || service.cupboard_id === -1) {
                serviceCategoryErr.cupboard_id = "Cupboard Name is required";
                valid = false;
            } else {
                serviceCategoryErr.cupboard_id = null
            }
            if (!service.rack_seting_id || service.rack_seting_id === -1) {
                serviceCategoryErr.rack_seting_id = "Rack Number is required";
                valid = false;
            } else {
                serviceCategoryErr.rack_seting_id = null
            }
        });

        formValues.attach_file.forEach((attach, attachIndex) => {
            const attachErr = newErrors.attach_file[attachIndex];
            if (attach.is_mandatory && !attach.is_checked) {
                attachErr.id_attachment = "This file is mandatory";
                valid = false;
            } else {
                attachErr.id_attachment = null;
            }
        })

        if (!formValues.date) {
            newErrors.date = "Date Created is required";
            valid = false;
        } else {
            newErrors.date = null;
        }
        if (!formValues.customer_id) {
            newErrors.customer_id = "Customer Name is required";
            valid = false;
        } else {
            newErrors.customer_id = null;
        }

        setErrors(newErrors);
        return valid;
    }

    const addService = () => {
        const newFormValues = { ...formValues };
        newFormValues.service_category.push({
            service_category_id: "",
            cupboard_id: "",
            rack_seting_id: "",
            sub_service_category_id: ""
        });
        setFormValues(newFormValues);
        setErrors((prevErrors) => {
            const updateService = [...prevErrors.service_category];
            updateService.push({
                service_category_id: null,
                sub_service_category_id: null,
                cupboard_id: null,
                rack_seting_id: null,
            });

            return {
                ...prevErrors,
                service_category: updateService,
            };
        });
    }

    const removeService = (serviceIndex) => {
        const newFormValues = { ...formValues }; // Use formValues, not fields
        newFormValues.service_category.splice(serviceIndex, 1);
        setFormValues(newFormValues);
    };

    // render
    const renderFormContent = () => {
        return (
            <Box display="flex" sx={{ gap: "1.5rem" }} style={{ overflow: "auto", maxHeight: "500px" }}>
                <Box flex="1" style={{ overflow: "auto" }}>
                    {
                        fields.map((field) => {
                            const fieldOptions = Array.isArray(options[field.name])
                                ? [...options[field.name]]
                                : [];
                            switch (field.type) {
                                case INPUT_TYPE.DATE:
                                    return (
                                        <DatePickerInput
                                            key={field.name}
                                            field={field}
                                            formValues={formValues}
                                            errors={errors}
                                            showError={false}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                        />
                                    );
                                case INPUT_TYPE.TEXT:
                                    return (
                                        <TextInput
                                            key={field.name}
                                            field={field}
                                            formValues={formValues}
                                            errors={errors}
                                            showError={false}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                        />
                                    );
                                case INPUT_TYPE.TEXTAREA:
                                    return (
                                        <TextArea
                                            key={field.name}
                                            field={field}
                                            formValues={formValues}
                                            errors={errors}
                                            showError={false}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                        />
                                    );
                                case INPUT_TYPE.DIVIDER:
                                    return (
                                        <Divider />
                                    );
                                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                                    return (
                                        <SelectOneAutocomplete
                                            key={field.name}
                                            field={field}
                                            formValues={formValues}
                                            errors={errors}
                                            showError={false}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                            fieldOptions={fieldOptions}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })
                    }
                    {
                        fields.map((field) => {
                            if (field.name === "service_category") {
                                return (
                                    <div key={field.name} style={{ marginTop: 16, marginBottom: 16 }}>
                                        <Typography variant="h6">
                                            Service
                                        </Typography>
                                        <Typography sx={{ marginBottom: 1.5 }}>
                                            Define the service the customer chooses
                                        </Typography>
                                        {
                                            listServices.length > 0 ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        backgroundColor: infoSurfaceColor,
                                                        color: infoMainColor,
                                                        borderRadius: "6px",
                                                        padding: 1
                                                    }}>
                                                    <Box sx={{ marginRight: 1 }}><img src={InfoIcon} /></Box>
                                                    <Box>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            This customer already has some services
                                                            <br />
                                                            {
                                                                listServices.map((v) => (
                                                                    <span>
                                                                        • {v.service_category_name} - {v.sub_service_category_name}
                                                                        <br />
                                                                    </span>
                                                                ))
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                : <></>
                                        }
                                        {
                                            formValues.service_category.map((service, serviceIndex) => {
                                                // const otherServiceIds = formValues.service_category
                                                //     .filter((_, index) => index !== serviceIndex)
                                                //     .map((otherService) => otherService.service_category_id);
                                                // const otherCupboardIds = formValues.service_category
                                                //     .filter((_, index) => index !== serviceIndex)
                                                //     .map((otherService) => otherService.cupboard_id);

                                                const fieldOptions = {
                                                    serviceCategories: [
                                                        ...(options.serviceCategories || []),
                                                        // ...(options.serviceCategories || []).filter(
                                                        //     (category) => !otherServiceIds.includes(category.id)
                                                        // ),
                                                    ],
                                                    applicantIds: [
                                                        ...(options.applicantIds || []),
                                                    ],
                                                    brandIds: [
                                                        ...(options.brandIds || []),
                                                    ],
                                                    cupboardIds: [
                                                        // ...(options.cupboardIds || []).filter(
                                                        //     (category) => !otherCupboardIds.includes(category.id)
                                                        // ),
                                                        ...(options.cupboardIds || []),
                                                    ],
                                                    subServiceCategories: [
                                                        ...(options.subServiceCategories[service.service_category_id] || []),
                                                    ],
                                                    rackNumbers: [
                                                        ...(options.rackNumbers[service.cupboard_id] || []),
                                                    ]
                                                };

                                                // debugger;
                                                const serviceErrors =
                                                    errors.service_category[serviceIndex] || {};
                                                return (
                                                    <div key={serviceIndex}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Box style={{ flex: 11 }}>
                                                                <InputLabel>Service Category</InputLabel>
                                                                <Autocomplete
                                                                    fullWidth
                                                                    size="small"
                                                                    options={fieldOptions["serviceCategories"]}
                                                                    getOptionLabel={(category) => category.name}
                                                                    value={
                                                                        fieldOptions["serviceCategories"].find(
                                                                            (option) => option.id === service.service_category_id
                                                                        ) || null
                                                                    }
                                                                    onChange={(_, newValue) =>
                                                                        handleSettingChange(
                                                                            serviceIndex,
                                                                            "service_category_id",
                                                                            newValue?.id || null
                                                                        )
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={serviceErrors.service_category_id}
                                                                            variant="outlined"
                                                                            placeholder={`Select Service Category `}
                                                                            sx={{
                                                                                ...formGroup,
                                                                                marginBottom: "0.2rem",
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Box>
                                                            <Box style={{ flex: 1, alignItems: "center" }}>
                                                                <InputLabel>&nbsp;</InputLabel>
                                                                <Button
                                                                    variant="contained"
                                                                    disableElevation
                                                                    sx={{
                                                                        height: "100%",
                                                                        backgroundColor: formValues.service_category.length == 1 ? `${dangerSurfaceColor} !important` : `${dangerMainColor} !important`,
                                                                        ...removeButton
                                                                    }}
                                                                    onClick={() => removeService(serviceIndex)}
                                                                    disabled={formValues.service_category.length == 1} >
                                                                    <img src={RemoveIcon} />
                                                                </Button>
                                                            </Box>
                                                        </div>
                                                        {/* {serviceErrors.service_category_id && (
                                                            <FormHelperText
                                                                error
                                                                sx={{
                                                                    color: "#fb2525",
                                                                    fontSize: "1rem",
                                                                    margin: "0.3rem 0 0",
                                                                }}
                                                            >
                                                                {serviceErrors.service_category_id}
                                                            </FormHelperText>
                                                        )} */}
                                                        {
                                                            formValues.service_category[serviceIndex].service_category_id ?
                                                                <>
                                                                    <InputLabel>Sub Category Service</InputLabel>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        size="small"
                                                                        options={fieldOptions["subServiceCategories"]}
                                                                        getOptionLabel={(option) => option.name}
                                                                        value={
                                                                            fieldOptions["subServiceCategories"].find(
                                                                                (option) => option.id === service.sub_service_category_id
                                                                            ) || null
                                                                        }
                                                                        onChange={(_, newValues) =>
                                                                            handleSettingChange(
                                                                                serviceIndex,
                                                                                "sub_service_category_id",
                                                                                newValues?.id || null
                                                                            )
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                error={serviceErrors.sub_service_category_id}
                                                                                placeholder={`Select Sub Category Service`}
                                                                                sx={{ ...formGroup, marginBottom: "0.2rem" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {/* {serviceErrors.sub_service_category_id && (
                                                                        <FormHelperText
                                                                            error
                                                                            sx={{
                                                                                color: "#fb2525",
                                                                                fontSize: "1rem",
                                                                                margin: "0.3rem 0 0",
                                                                            }}
                                                                        >
                                                                            {serviceErrors.sub_service_category_id}
                                                                        </FormHelperText>
                                                                    )} */}
                                                                </>
                                                                : <></>
                                                        }
                                                        <Box>
                                                            <InputLabel>Applicant</InputLabel>
                                                            <Autocomplete
                                                                fullWidth
                                                                size="small"
                                                                options={fieldOptions["applicantIds"]}
                                                                getOptionLabel={(value) => value.name}
                                                                value={
                                                                    fieldOptions["applicantIds"].find(
                                                                        (option) => option.id === service.applicant_id
                                                                    ) || null
                                                                }
                                                                onChange={(_, newValue) =>
                                                                    handleSettingChange(
                                                                        serviceIndex,
                                                                        "applicant_id",
                                                                        newValue?.id || null
                                                                    )
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={serviceErrors.applicant_id}
                                                                        variant="outlined"
                                                                        placeholder={`Select Applicant`}
                                                                        sx={{
                                                                            ...formGroup,
                                                                            marginBottom: "0.2rem",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <InputLabel>Brand</InputLabel>
                                                            <Autocomplete
                                                                fullWidth
                                                                size="small"
                                                                options={fieldOptions["brandIds"]}
                                                                getOptionLabel={(value) => value.name}
                                                                value={
                                                                    fieldOptions["brandIds"].find(
                                                                        (option) => option.id === service.brand_id
                                                                    ) || null
                                                                }
                                                                onChange={(_, newValue) =>
                                                                    handleSettingChange(
                                                                        serviceIndex,
                                                                        "brand_id",
                                                                        newValue?.id || null
                                                                    )
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={serviceErrors.brand_id}
                                                                        variant="outlined"
                                                                        placeholder={`Select Brand`}
                                                                        sx={{
                                                                            ...formGroup,
                                                                            marginBottom: "0.2rem",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <InputLabel>Registration Number</InputLabel>
                                                            <TextField
                                                                fullWidth
                                                                placeholder={`Input Registration Number`}
                                                                value={service["registration_number"] || null}
                                                                margin="normal"
                                                                size="small"
                                                                variant="outlined"
                                                                error={serviceErrors.registration_number}
                                                                sx={{ ...formGroup }}
                                                                onChange={(event) =>
                                                                    handleSettingChange(
                                                                        serviceIndex,
                                                                        "registration_number",
                                                                        event.target.value || null
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <InputLabel>Reference Number</InputLabel>
                                                            <TextField
                                                                fullWidth
                                                                placeholder={`Input Reference Number`}
                                                                value={service["reference_number"] || null}
                                                                margin="normal"
                                                                size="small"
                                                                variant="outlined"
                                                                error={serviceErrors.reference_number}
                                                                sx={{ ...formGroup }}
                                                                onChange={(event) =>
                                                                    handleSettingChange(
                                                                        serviceIndex,
                                                                        "reference_number",
                                                                        event.target.value || null
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                        <div style={{ display: "flex", height: "80px" }}>
                                                            <Box style={{ flex: 1 }}>
                                                                <InputLabel>Cupboard Name</InputLabel>
                                                                <Autocomplete
                                                                    fullWidth
                                                                    size="small"
                                                                    disabled={!formValues.service_category[serviceIndex].sub_service_category_id}
                                                                    options={fieldOptions["cupboardIds"]}
                                                                    getOptionLabel={(category) => category.cupboard_name}
                                                                    value={
                                                                        fieldOptions["cupboardIds"].find(
                                                                            (option) => option.id === service.cupboard_id
                                                                        ) || null
                                                                    }
                                                                    onChange={(_, newValue) =>
                                                                        handleSettingChange(
                                                                            serviceIndex,
                                                                            "cupboard_id",
                                                                            newValue?.id || null
                                                                        )
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            error={serviceErrors.cupboard_id}
                                                                            placeholder={`Select Cupboard Name`}
                                                                            sx={{
                                                                                ...formGroup,
                                                                                marginBottom: "0.2rem",
                                                                                backgroundColor: !formValues.service_category[serviceIndex].sub_service_category_id ? "#F5F5F5" : "#FFFFFF"
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {/* {serviceErrors.cupboard_id && (
                                                                    <FormHelperText
                                                                        error
                                                                        sx={{
                                                                            color: "#fb2525",
                                                                            fontSize: "1rem",
                                                                            margin: "0.3rem 0 0",
                                                                        }}
                                                                    >
                                                                        {serviceErrors.cupboard_id}
                                                                    </FormHelperText>
                                                                )} */}
                                                            </Box>
                                                            <Box style={{ flex: 1, marginLeft: "8px" }}>
                                                                <InputLabel>Rack Number</InputLabel>
                                                                <Autocomplete
                                                                    fullWidth
                                                                    size="small"
                                                                    disabled={!formValues.service_category[serviceIndex].sub_service_category_id}
                                                                    options={fieldOptions["rackNumbers"]}
                                                                    getOptionLabel={(category) => category.rack_number}
                                                                    value={
                                                                        fieldOptions["rackNumbers"].find(
                                                                            (option) => option.id === service.rack_seting_id
                                                                        ) || null
                                                                    }
                                                                    onChange={(_, newValue) =>
                                                                        handleSettingChange(
                                                                            serviceIndex,
                                                                            "rack_seting_id",
                                                                            newValue?.id || null
                                                                        )
                                                                    }

                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            error={serviceErrors.rack_seting_id}
                                                                            placeholder={`Select Rack Number`}
                                                                            sx={{
                                                                                ...formGroup,
                                                                                marginBottom: "0.2rem",
                                                                                backgroundColor: !formValues.service_category[serviceIndex].sub_service_category_id ? "#F5F5F5" : "#FFFFFF"
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {/* {serviceErrors.rack_seting_id && (
                                                                    <FormHelperText
                                                                        error
                                                                        sx={{
                                                                            color: "#fb2525",
                                                                            fontSize: "1rem",
                                                                            margin: "0.3rem 0 0",
                                                                        }}
                                                                    >
                                                                        {serviceErrors.rack_seting_id}
                                                                    </FormHelperText>
                                                                )} */}
                                                            </Box>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: (theme) => theme.palette.primary.main,
                                                cursor: "pointer",
                                                textAlign: "right",
                                                marginBottom: "150px"
                                            }}
                                            onClick={addService}
                                        >
                                            Add Another Service
                                        </Typography>
                                    </div>
                                );
                            }
                        })
                    }
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex="1" sx={{ overflow: "auto", maxHeight: "56vh" }}>

                    {
                        fields.map((field) => {
                            if (field.name === "attach_file") {
                                return (
                                    <div key={field.name} style={{ marginTop: 16, marginBottom: 16 }}>
                                        <Typography variant="h6">
                                            Attachment File List
                                        </Typography>
                                        <Typography >
                                            Check the list of attached files you have received for this archive
                                        </Typography>
                                        {
                                            formValues.attach_file.length === 0 ?
                                                <>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        flexDirection="column"
                                                        height="50vh" >
                                                        <img src={EmptyAttachment} alt="Centered Image" style={{ maxWidth: '100%' }} />
                                                        <Typography sx={{ color: "#616161", fontStyle: "italic" }} >
                                                            Determine the service the customer chooses first
                                                        </Typography>
                                                    </Box>
                                                </>
                                                : <></>
                                        }
                                        {
                                            formValues.service_category.map((service, serviceIndex) => (
                                                <>
                                                    <Box>
                                                        <Box sx={{ display: "flex", marginY: "4px" }}>
                                                            <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${serviceIndex + 1}`}</Typography></Avatar>
                                                            <Typography> {` ${handleNameServiceCategory(service.service_category_id)} - ${handleNameSubServiceCategory(service.service_category_id, service.sub_service_category_id)}`}</Typography>
                                                        </Box>
                                                        {
                                                            formValues.attach_file.map((attach, attachIndex) => {
                                                                const attachErrors = errors.attach_file[attachIndex] || {};
                                                                if (attach.services_category_id === service.service_category_id && attach.sub_services_category_id === service.sub_service_category_id) {
                                                                    return (
                                                                        <div>
                                                                            <div key={attachIndex} style={{ display: "flex", alignItems: "center" }}>
                                                                                <Box>
                                                                                    <Checkbox checked={attach.is_checked}
                                                                                        sx={{ paddingLeft: 0 }}
                                                                                        onChange={(e) =>
                                                                                            handleAttachChange(
                                                                                                attachIndex,
                                                                                                "is_checked",
                                                                                                e.target.checked
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                                <Box >
                                                                                    <Typography >
                                                                                        {attach.desc}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </div>
                                                                            {attachErrors.id_attachment !== null && attachErrors.id_attachment && (
                                                                                <FormHelperText
                                                                                    error
                                                                                    sx={{
                                                                                        color: "#fb2525",
                                                                                        fontSize: "1rem",
                                                                                        margin: "0.3rem 0 0",
                                                                                    }}
                                                                                >
                                                                                    {attachErrors.id_attachment}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Box>
                                                </>
                                            ))
                                        }
                                    </div>
                                );
                            }


                        })
                    }
                </Box>
            </Box>
        );
    }

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => {
                        // resetFormValues()
                        navigate(-1)
                    }
                    }
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit"
                // onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>
        );
    };

    return (
        <Card
            style={{
                marginTop: "1rem",
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
                height: "81vh",
                position: "relative",
            }}
        >
            <CardHeader
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1.4rem",
                            fontWeight: 800,
                            paddingLeft: "1rem",
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            sx={{ marginRight: ".5rem" }}
                        >
                            <img src={ArrowBackIcon} />
                        </IconButton>
                        {title}
                    </div>
                }
            />
            <Divider />
            {
                isLoading ? <FacebookCircularProgress /> :
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <CardContent sx={{ padding: "1.7rem" }}>
                            {renderFormContent()}
                        </CardContent>
                        <CardActions
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                width: "100%",
                                justifyContent: "flex-end",
                                background: "white",
                                padding: "20px",
                            }}
                        >
                            {renderActionButtons()}
                        </CardActions>
                    </form>
            }
        </Card>
    );
}

export default AddArchive;