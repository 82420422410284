import { REQUEST_LOADING } from "../../../../util/global_state";

import {
  ADD_RETURN_FAILED,
  ADD_RETURN_SUCCESS,
  DELETE_RETURN_FAILED,
  DELETE_RETURN_SUCCESS,
  EDIT_RETURN_FAILED,
  EDIT_RETURN_SUCCESS,
  GET_RETURN_FAILED,
  GET_RETURN_SUCCESS,
} from "./state";

const initState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

const returnReducer = (
  state = initState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_RETURN_SUCCESS:
      return {
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case GET_RETURN_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_RETURN_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_RETURN_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_RETURN_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_RETURN_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_RETURN_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_RETURN_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default returnReducer;
