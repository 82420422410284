import React, { useEffect, useState } from "react";
import { fields } from "./parts/imutable_state";
import { ANOTHER_INPUT_TYPE } from "../payment-request/parts/imutable_state";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import { INPUT_TYPE } from "./../../../util/function";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
  FilePicker,
} from "../../../components/Form";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../util/style";
import RemoveIconSvg from "../../../assets/minus.svg";

//* ======================= Type Def ========================
/**
 * - Type for field
 * @typedef {object} FieldType
 * @property {string} name
 * @property {string} type
 * @property {string} label
 * @property {string} defaultValue
 * @property {boolean} required
 * @property {string} [errorMessage]
 * @property {CallableFunction} [validation]
 * @property {string} [aliasLable]
 * @property {string} [tip]
 */

//* Main function
export default function DetailPaymentForm({
  formValues,
  errors,
  setFormValues,
  setErrors,
}) {
  /**======================================================== */
  /**                      FILE SECTION                       */
  /**======================================================== */

  /**
   * set File to form
   * @param {File} file
   * @param {FieldType} field
   * @param {number} index
   */
  const handleFileSelect = (file, field, index) => {
    setFormValues((prevValue) => {
      //* Copy file field in formValues;
      const prevFileValue = prevValue[field.name];
      //* Set the file by index
      prevFileValue[index] = file;
      //* Return the new value
      return { ...prevValue, [field.name]: prevFileValue };
    });
  };

  /**
   * validate file input
   * @param {File} file
   * @param {FieldType} field
   * @returns {string}
   */
  const validateInputFile = (file, field) => {
    if (file instanceof File) {
      /**@type {Array} */
      const allowedExtension = field.allowedExtension || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtension.includes(".".concat(fileExtension))) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (file.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  /**======================================================== */
  /**                  VALIDATION SECTION                     */
  /**======================================================== */

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newError = { ...errors };
    if (field) {
      const { name, required, validation, errorMessage, label, aliasLable } =
        field;
      const errorLabel = aliasLable ? aliasLable : label;
      const valueForm = formValues[name];
      if (
        required &&
        (String(valueForm).trim() === "" || valueForm === undefined)
      ) {
        newError[name] =
          errorMessage ||
          "Please enter".concat(String(errorLabel).toLowerCase());
      } else if (
        valueForm &&
        valueForm.length &&
        validation &&
        !validation(valueForm)
      ) {
        newError[name] = errorMessage || errorLabel.concat("is invalid");
      } else {
        newError[name] = "";
      }
    }
  };

  /**======================================================== */
  /**                    HANDLER SECTION                      */
  /**======================================================== */

  /**
   *
   * @param {import("react").SyntheticEvent} event
   * @param {import("@mui/x-date-pickers/internals").FieldChangeHandler} field
   * @param {number} index
   */
  const handleChangeInput = (event, field, index) => {
    const { name, value, type } = event.target;
    const copyErrors = { ...errors };
    // if type file, just validate input
    if (type === "file") {
      const errorFileInput = validateInputFile(value, field);
      if (errorFileInput) {
        const curentErrorFile = copyErrors[field.name];
        curentErrorFile[index] = errorFileInput;
        setErrors((prev) => ({ ...prev, curentErrorFile }));
      }
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleBlurInput = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const handleAddFile = (fieldName) => {
    setFormValues((prev) => {
      let updatedForm = { ...prev };
      updatedForm[fieldName].push("");
      return updatedForm;
    });
  };

  /**
   *
   * @param {FieldType} field
   * @param {number} index
   */
  const handleRemoveFile = (fieldName, index) => {
    if (!isNaN(index)) {
      setFormValues((prev) => {
        let updatedForm = { ...prev };
        updatedForm[fieldName].splice(index, 1);
        return updatedForm;
      });

      setErrors((prev) => {
        let updatedError = { ...prev };
        updatedError[fieldName].splice(index, 1);
        return updatedError;
      });
    } else {
      console.error("Invalid index");
    }
  };

  const returnable = fields.map((field) => {
    let fieldComponent;
    switch (field.type) {
      case INPUT_TYPE.DATE:
        fieldComponent = (
          <DatePickerInput
            errors={errors}
            field={field}
            formValues={formValues}
            handleInputBlur={handleBlurInput}
            handleInputChange={handleChangeInput}
            tip={field.tip}
          />
        );
        break;
      case ANOTHER_INPUT_TYPE.FILE:
        fieldComponent = (
          <Grid item key={field.title}>
            <Box sx={{ marginBottom: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 800 }}>
                {field.title}
              </Typography>
              <Typography variant="subtitle2">{field.subtitle}</Typography>
            </Box>
            {formValues[field.name].map((_, index) => (
              <React.Fragment key={index}>
                <Grid container spacing={0.3}>
                  <Grid item xs={11.3}>
                    <Grid item>
                      <FilePicker
                        indexFile={index}
                        errors={errors}
                        field={field}
                        formValues={formValues[field.name][index]}
                        handleInputChange={(event) =>
                          handleChangeInput(event, field, index)
                        }
                        onFileSelect={(file) =>
                          handleFileSelect(file, field, index)
                        }
                        tip={field?.tip}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={0.5}
                    sx={{ marginTop: "2.5rem", marginLeft: "-0.3rem" }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (them) => them.palette.primary.main,
                        ...removeButton,
                      }}
                      onClick={() => handleRemoveFile(field.name, index)}
                      disabled={formValues[field.name].length === 1}
                    >
                      <img src={RemoveIconSvg} alt={`Remove ${field.name}`} />
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            <Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFile(field.name)}>
                <span
                  style={{
                    fontSize: "1.5rem",
                    marginRight: ".6rem",
                    display: "inline-block",
                  }}
                >
                  +
                </span>
                Add More
              </Button>
            </Box>
          </Grid>
        );
        break;
      default:
        fieldComponent = null;
    }

    return (
      <React.Fragment key={field.name}>
        <Grid>
          <Grid
            item
            xs={field.gridWidth || 12}
            key={field.name}
            sx={{ paddingTop: "unset !important" }}
          >
            {fieldComponent}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  });
  return returnable;
}
