import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../core/axios_config";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "./../../../assets/back.svg";
import DocumentActive from "./../../../assets/document_active.svg";
import DocumentNonActive from "./../../../assets/document_nonactive.svg";
import InfoIcon from "./../../../assets/info-circle.svg";
import {
    FacebookCircularProgress,
    StatusBadge,
    borderRadius,
    mainColor,
    warningSurfaceColor,
    warningMainColor,
    successSurfaceColor,
    successMainColor,
    dangerSurfaceColor,
    dangerMainColor,
    btnWhite,
} from "../../../util/style";
import { useSelector } from "react-redux";

const DetailRequest = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState("Detail Request Hardcopy");
    const [status, setStatus] = useState(null);
    const [requestInfo, setRequestInfo] = useState([]);
    const [serviceInfo, setServiceInfo] = useState([]);
    const [attachInfo, setAttachInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams();
    const { token } = useSelector(state => state.auth)
    const [formValues, setFormValues] = useState(Object.assign({}));

    useEffect(() => {
        console.log(token);
        if (id) {
            axios
                .get(`requests/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    const data = response.data.data

                    const requestNumber = { request_number: data.request_code.replace("-", "") }
                    const requestDate = { request_date: formatDate(data.request_date) }
                    const returnDate = { return_date: formatDate(data.return_date) }
                    const customerName = { customer_name: data.customer_name }
                    const note = { note: data.note }
                    const approvalLine = { approval_line: data.approval_line.fullname }
                    const updateRequestInfo = [
                        ...requestInfo,
                        requestNumber,
                        requestDate,
                        returnDate,
                        customerName,
                        note,
                        approvalLine
                    ]
                    const services = [];
                    console.log("data.get_archive_service");
                    console.log(data.get_archive_service);
                    data.get_archive_service.map((e) => {
                        services.push({
                            "service_category_name": e.get_service_category.name,
                            "sub_service_category_name": e.get_sub_service_category.name,
                            "reference_number": e.reference_number,
                            "registration_number": e.registration_number,
                            "applicant_name": e.applicant.name,
                            "brand_name": e.brand.name,
                        })
                    })
                    // const attachs = [];
                    // data.get_attachment.map((e) => {
                    //     attachs.push({
                    //         "is_checked": e.is_checked,
                    //         "desc": e.desc
                    //     })
                    // })
                    const attachs = transformAttachFile(data.get_attachment);
                    attachs.reverse()
                    const newFormValues = {
                        ...formValues,
                        ['archive_id']: data.archive_id,
                        ['customer_id']: data.customer_id,
                        ['customer_name']: data.customer_name,
                    }
                    setFormValues(newFormValues)
                    setRequestInfo(updateRequestInfo)
                    setStatus(data.status)
                    setServiceInfo(services)
                    setAttachInfo(attachs)
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    // console.error("Error fetching sub-service categories:", error);
                });
        }
    }, [])

    function transformAttachFile(attachment_list) {
        const transformedList = {};
        attachment_list.forEach((attachment) => {
            const {
                service_category_name,
                sub_service_category_name,
                id,
                is_checked,
                desc,
                created_at,
                updated_at,
                status_loan,
            } = attachment;
            const key = `${service_category_name}_${sub_service_category_name}`;

            if (!transformedList[key]) {
                transformedList[key] = {
                    service_category_name,
                    sub_service_category_name,
                    data: [],
                };
            }

            transformedList[key].data.push({
                id,
                is_checked,
                desc,
                created_at,
                updated_at,
                status_loan,
            });
        });
        const result = Object.values(transformedList);
        return result;
    }

    function keyObj(fieldName) {
        return fieldName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('id-ID', options);

        return formattedDate;
    }

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate(-1)}
                    sx={{ marginRight: "16px" }}
                >
                    Close
                </Button>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => {
                        navigate(`/archive-management/request/edit/${id}`,
                            { state: { customer: formValues } }
                        )
                    }}
                >
                    Edit
                </Button>
            </Box>
        );
    };
    return (
        <Card
            style={{
                marginTop: "1rem",
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
                height: "81vh",
                position: "relative",
            }}>
            <CardHeader
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1.4rem",
                            fontWeight: 800,
                            paddingLeft: "1rem",
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            sx={{ marginRight: ".5rem" }}
                        >
                            <img src={ArrowBackIcon} />
                        </IconButton>
                        {title}
                    </div>
                }
            />
            <Divider />
            {
                isLoading ? <FacebookCircularProgress /> :
                    <>
                        <CardContent sx={{ padding: "1.7rem" }}>
                            <Box display={"flex"} sx={{ gap: "1.5rem" }}>
                                <Box flex={1} sx={{ overflow: "auto", maxHeight: "480px" }}>
                                    <Box display={"flex"} sx={{ marginY: "16px" }}>
                                        <Box flex={1}>
                                            <Typography variant="h6">
                                                Request Information
                                            </Typography>
                                        </Box>
                                        <Box >
                                            <StatusBadge
                                                key={0}
                                                status={status ? status.name : ""}
                                                bgColor={status ? status.bg_code : ""}
                                                colors={status ? status.color_code : ""} >
                                                {status ? status.name : ""}
                                            </StatusBadge>
                                        </Box>
                                    </Box>
                                    {
                                        requestInfo.map((request) => (
                                            <Box display={"flex"} sx={{ marginY: "16px" }}>
                                                <Box flex={1}>
                                                    <Typography>{keyObj(`${Object.keys(request)}`)}</Typography>
                                                </Box>
                                                <Box flex={1}>
                                                    <Typography>{Object.values(request)}</Typography>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                    <Typography variant="h6" sx={{ marginY: "24px" }}>
                                        Service Information
                                    </Typography>
                                    {
                                        serviceInfo.map((service, serviceIndex) => (
                                            <>
                                                <Box sx={{ display: "flex" }}>
                                                    <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${serviceIndex + 1}`}</Typography></Avatar>
                                                    <Typography> {` ${service.service_category_name} - ${service.sub_service_category_name}`}</Typography>
                                                </Box>
                                                <Box display={"flex"} sx={{ marginY: "16px" }}>
                                                    <Box flex={1}>
                                                        <Typography>Reference Number</Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography>{service.reference_number}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} sx={{ marginY: "16px" }}>
                                                    <Box flex={1}>
                                                        <Typography>Registration Number</Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography>{service.registration_number}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} sx={{ marginY: "16px" }}>
                                                    <Box flex={1}>
                                                        <Typography>Brand</Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography>{service.brand_name}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} sx={{ marginY: "16px" }}>
                                                    <Box flex={1}>
                                                        <Typography>Applicant</Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography>{service.applicant_name}</Typography>
                                                    </Box>
                                                </Box>
                                            </>

                                        ))
                                    }
                                    <Box sx={{ height: "300px" }} />
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box flex={1} sx={{ overflow: "auto", maxHeight: "480px" }}>
                                    <Typography variant="h6">
                                        Attachment File List
                                    </Typography>
                                    <Typography sx={{ marginBottom: "16px" }}>
                                        List of attached files used for the selected service
                                    </Typography>
                                    {
                                        attachInfo.map((attach, attachIndex) => (
                                            <>
                                                <Box>
                                                    <Box sx={{ display: "flex", marginBottom: "16px" }}>
                                                        <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${attachIndex + 1}`}</Typography></Avatar>
                                                        <Typography> {` ${attach.service_category_name} - ${attach.sub_service_category_name}`}</Typography>
                                                    </Box>
                                                    {
                                                        attach.data.map((v) => (
                                                            <Box sx={{ display: "flex", marginBottom: "16px" }}>
                                                                <Box sx={{ marginRight: "8px" }}>
                                                                    <CardMedia
                                                                        image={v.is_checked ? DocumentActive : DocumentNonActive}
                                                                        component="img"
                                                                        alt="Sample Image" />
                                                                </Box>
                                                                <Box sx={{ flex: 1 }}>
                                                                    <Typography sx={{ color: v.is_checked ? "#0A0A0A" : "#9E9E9E" }}>
                                                                        {v.desc}
                                                                    </Typography>
                                                                </Box>
                                                                {
                                                                    v.status_loan && (
                                                                        <Box >
                                                                            <Tooltip title="This attached file is being borrowed" sx={{ margin: 0, padding: 0 }}>
                                                                                <IconButton aria-label="Image">
                                                                                    <img src={InfoIcon} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    )
                                                                }
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            </>

                                        ))
                                    }
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                width: "100%",
                                justifyContent: "flex-end",
                                background: "white",
                                padding: "20px",
                            }}
                        >
                            {renderActionButtons()}
                        </CardActions>
                    </>
            }


        </Card>
    );
}
export default DetailRequest;