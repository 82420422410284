import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../components/Form";
import {
  addService,
  deleteService,
  editService,
  getService,
} from "./clients/actions";
import { getGroupClient,addGroupClient } from "./../sales-management/client/clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  formatCurrency
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { TransitionAlerts } from "../../components/Alert";


const Service = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */

  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    console.log(currentPath, userAccess);
  }, [userAccess]);

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const ANOTHER_ACTION_TYPE = {
    ADD_NEW_SERVICE: "ADD_NEW_SERVICE",
  }

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New Service");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();

  const fields = actionType === ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE
? [
  {
    name: "name",
    type: INPUT_TYPE.TEXT,
    label: "Service Name",
    placeholder: 'Input service name',
    defaultValue: "",
    required: true,
    errorMessage: 'Please enter the service name',
  },
] : [
    //NEW
    {
    name: "sub_service_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Select Service for Particular Settings",
    apiEndpoint: "/master/sub-services",
    placeholder: 'Select service name',
    defaultValue: "",
    required: false,
    styleForGrid: {
      display:actionType === ACTION_TYPE.ADD ? 'block' : 'none'
    },
  },
//       ...(actionType === ANOTHER_ACTION_TYPE.ADD) ?
//     [
//       {
//       name: "sub_service_id",
//       type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
//       label: "Select Service for Criteria Settings",
//       apiEndpoint: "/master/sub-services",
//       placeholder: 'Select service name',
//       defaultValue: "",
//       required: false,
//     },
//   ]: [
//
//   ],
  {
    name: "name",
    type: INPUT_TYPE.TEXT,
    label: "Service Name",
    placeholder: 'Input service name',
    defaultValue: "",
    required: true,
    errorMessage: 'Please enter the service name',
  },
  {
    name: "main_service_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Divisi (Main Service)",
    defaultValue:"",
    // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    apiEndpoint: "/master/services",
    required: false,
    placeholder: "Select main service ( Ops )",
    tip: "Select one if you want to make services a sub category",
  },
  {
    name: "criteria_name",
    type: INPUT_TYPE.TEXT,
    label: "Particular",
    placeholder: 'Input particular ( Ops )',
    defaultValue: "",
    required: false,
    styleForGrid: {
      display:'none'
    },
  },
  // ...(process.env.REACT_APP_API_URL !== "https://api-app.pacificpatent.com"
  ...(true
    ? [
      {
        name: "basic_price",
        type: INPUT_TYPE.CURRENCY,
        label: "Basic Price",
        startIcon: "Rp",
        defaultValue: "0",
        required: false,
      },
      {
        name: "sell_price_rp",
        type: INPUT_TYPE.CURRENCY,
        label: "Sell Price in Rupiah",
        startIcon: "Rp",
        defaultValue: "0",
        required: false,
      },
      {
        name: "sell_price_dollar",
        type: INPUT_TYPE.CURRENCY,
        label: "Sell Price in Dollar",
        startIcon: "$",
        defaultValue: "0",
        required: false,
      },
    ]
    : []),
  ];


  const columns = [
    {
      field: "code",
      headerName: "Service Code",
      width: 170,
      disableColumnMenu: true,
    },
    {
      field: "main_service_id",
      headerName: "Divisi (Main service)",
      width: 210,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        return params.row.main_service ? params.row.main_service ?.name : "-";
      },
    },
    {
      field: "name",
      headerName: "Service Name",
      width: 210,
      disableColumnMenu: true,
    },
    {
      field: "criteria_name",
      headerName: "Particular",
      width: 210,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return params.value ?  params.value : '-'
      }
    },
//      {
//       field: "basic_price",
//       headerName: "Basic Price",
//       width: 210,
//       disableColumnMenu: true,
//       sortable: false,
//       valueGetter: (params) => {
//         return params.value
//       },
//       renderCell: (params) => {
//         return formatCurrency(params.value)
//       }
//     },
      {
      field: "sell_price_rp",
      headerName: "Sell in Price (Rp)",
      width: 210,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        return params.value
      },
      renderCell: (params) => {
        return formatCurrency(params.value)
      }
    },
     {
      field: "sell_price_dollar",
      headerName: "Sell in Price (USD)",
      width: 210,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params) => {
        return params.value
      },
      renderCell: (params) => {
        return formatCurrency(params.value, { country: 'en-US', currency: 'USD' })
      }
    },
    {
      field: "status",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 170,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value}> {params.value} </StatusBadge>
      ),
    },
  ];


  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.services);

  //NEW
  const {
    data: dataGroupClient,
    isLoading: isLoadingGroupClient,
  } = useSelector((state) => state.groupclient);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(name, response.data.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });

  }, []);



  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(name, response.data.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });

  }, [isActive]);


  useEffect(() => {
    if(dataGroupClient) {
      // if (!options["group_id"]) {
      //   setOptions((prevOptions) => ({
      //     ...prevOptions,
      //     group_id: dataGroupClient,
      //   }));
      // } else {
        setOptions((prevOptions) => ({
          ...prevOptions,
          group_id: dataGroupClient,
        }));
      // }
    }

  }, [ dataGroupClient ]);


  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getService({ token, limit, search, status, sortDirection, sortBy })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
      })
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null, status: false })
  );
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE && actionType !== actionType.DELETE) {
      localStorage.setItem("system-configuration/services/formValues", JSON.stringify(formValues));
    }
    console.log(formValues, 'GANTIIII')
  }, [formValues, actionType]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);



  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getService({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }, [isActive]);

const setFormValuesFromSelectedData = () => {
  const data = rows.filter((row) => selectedData.includes(row.id))[0];
  for (const key in data) {
    if (formValues.hasOwnProperty(key)) {
        const value = key === "status" ? data[key] === "active" : key === "id" ? Number(data[key].replace(/\D/g, "")) : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value?.id : value,
        }));
    }
    if (key === "id") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        type: data[key].split(" ")[0], // Set sub_service_id when key is 'id'
      }));

    }
  };
}


  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name, value");

    if(name=="sub_service_id") {
      if(value) {
        const id = value;
        const { data } = await(await axios.get(`/services/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }))?.data;
//       debugger
        delete data.name

        for (const key in data) {
          // Check if the property exists in formValues before updating
          console.log(key, 'KAYYY', formValues)
          if (formValues.hasOwnProperty(key)) {
            console.log(key, 'KUYY')
            // Special handling for the 'status' property
            const value = key === "status" ? data[key] === "active" : data[key];
            setFormValues((prevFormValues) => ({
              ...prevFormValues,
              [key]: typeof value === "object" ? value ?.id : value,
            }));
          }
        }

            // Update the 'required' property directly in the fields array
            fields.forEach(field => {
              if (field.name === "name") {
                field.styleForGrid = {
                  display:'none'
                };
              }else if (field.name === "criteria_name") {
                field.styleForGrid = {
                  display:'block'
                };
              }
            });

        // error di name dihilangkan
        const newErrors = { ...errors };
        newErrors['name'] = "";
        setErrors(newErrors);

      } else {
        fields.forEach(field => {
          if (field.name === "criteria_name") {
            field.styleForGrid = {
              display:'none'
            };
          }
        })
      }
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
      } else if (formValues[name] &&
        formValues[name].length &&
        validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };


    fields.forEach((field) => {
      if(formValues.sub_service_id) {
        if (field.name !== "name") {
          const { name, required, validation, errorMessage, type } = field;

          if (
            required &&
            (String(formValues[name]).trim() === "" ||
              formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
              formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
          ) {
            newErrors[name] = errorMessage || `${field.label} is required`;
            valid = false;
          }

          if (
            formValues[name] &&
            formValues[name].length && validation && !validation(formValues[name])) {
            newErrors[name] = errorMessage || `${field.label} is invalid`;
            valid = false;
          }
        }
      } else {
         const { name, required, validation, errorMessage, type } = field;

          if (
            required &&
            (String(formValues[name]).trim() === "" ||
              formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
              formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
          ) {
            newErrors[name] = errorMessage || `${field.label} is required`;
            valid = false;
          }

          if (
            formValues[name] &&
            formValues[name].length && validation && !validation(formValues[name])) {
            newErrors[name] = errorMessage || `${field.label} is invalid`;
            valid = false;
          }
      }

    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        } else if (actionType === ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE) { //NEW
          handleCloseFormModal(true);
          handleAddRow();
          handleFormModal(ACTION_TYPE.ADD)
        }
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Service");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Service");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(` service
      '${data.map((row) => row.name).join(", ")}'
      `);
    } else if (actionType === ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE) { //NEW
      setTitle("Add New Service");
      resetFormValues();
    } else {
      resetFormValues();
      setTitle("Add New Service");
      setFormValues(JSON.parse(localStorage.getItem("system-configuration/services/formValues")));
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
      }
    } else {
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);

    //NEW
    if (actionType === ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE) {
      handleFormModal(ACTION_TYPE.ADD) // formValue yang digunakan adalah formValues yang bukan ADD_NEW_GROUP
    } else {
      setFormModal(false);
      resetFormValues()
      localStorage.setItem("system-configuration/services/formValues", JSON.stringify({basic_price:0, sell_price_rp:0,sell_price_dollar:0 }));
    }
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  //NEW
  const handleAddRow = () => {
    if(actionType === ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE) {
      dispatch(addGroupClient(token, formValues));
    } else {
      dispatch(addService(token, formValues));
    }
  };

  const handleEditRow = () => {
    dispatch(editService(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteService(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)  || actionType === ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE
    ) {
      return (
        <>
          {fields.map((field) => {
            let optionsData;
            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              if (isRequired) {
                optionsData = [
                  // field.required &&
                  //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  //     name: "Choose Option",
                  //   },
                  ...options[field.name],
                ];
              } else {
                optionsData = [...options[field.name]];
              }
            }

            // Sub Service defuault tanpa criteria
            if (actionType === ACTION_TYPE.EDITED) {
              if(formValues.type === 'Service') {
                if(formValues.criteria_name === '') {
                  if(field.name === "criteria_name"){
                    field = {
                      ...field, styleForGrid: {
                        display: 'none',
                      },
                    }
                  }
                }
              }else {
                if(formValues.main_service_id) {
                  if(field.name === "criteria_name"){
                    field = {
                      ...field, styleForGrid: {
                        display: 'block',
                      },
                    }
                  }
                }
              }
            }


            if(actionType === ACTION_TYPE.ADD && formValues.sub_service_id) {
              if (field.name === "name") {
                field = {
                  ...field, additionalProps: {
                    ...field.additionalProps,
                    disabled: true ,
                  }, required:false,
                styleForGrid : {
                  display:'none'
                }
              }
            }
          }


            if(formValues.name) {
              if (field.name === "sub_service_id") {
                field = {
                  ...field, additionalProps: {
                    ...field.additionalProps,
                    disabled: true ,
                  },
                  required:false
                }
              }
            }

            if(formValues.sub_service_id) {
              if(field.name === "criteria_name"){
                field = {
                  ...field, styleForGrid: {
                    display: 'block',
                  },
                }
              }
            }

            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field ?.tip}
                  />
                );
                break
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
               fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
               break
              default:
                fieldComponent = null;
            }

          //NEW
              return (
                <Grid
                  item
                  xs={field.gridWidth || 12}
                  key={field.name}
                  sx={{ paddingTop: "unset !important",...field.styleForGrid }}
                >
                  {fieldComponent}


                  {field.name === "group_id" && (
                    <>
                      <Box sx={{
                        position: "absolute",
                        top: "6rem",
                        right: "2rem",
                        width: "90%"
                      }}>
                        <TransitionAlerts isOpen={isLoadingGroupClient} message="New group added successfully" />
                      </Box>

                      {/* OR Text */}
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Typography variant="subtitle1">or</Typography>
                      </Grid>

                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleAddNewGroup}
                        onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE)}
                        sx={{ marginTop: .5, marginBottom: 1 }}
                        fullWidth
                        disableElevation
                      >
                        Add new service
                    </Button>
                    </>
                  )}
                </Grid>
              );


          })}
          {actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_SERVICE && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ...formGroup,
                padding: "0 1rem",
              }}
            >
              <FormControlLabel
                control={
                  <StatusSwitch
                    checked={formValues.status}
                    name="status"
                    onChange={handleSwitchChange}
                  />
                }
                label={
                  <Typography
                    variant="h7"
                    sx={{ marginLeft: 1, fontWeight: "500" }}
                  >
                    {formValues.status ? "Active" : "Inactive"}
                  </Typography>
                }
              />
            </Box>
          )}
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };


  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getService({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getService({ token, page, limit, search, status, sortDirection, sortBy })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getService({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getService({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }
  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getService({ token, limit, search, status, sortDirection, sortBy })
    // );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search service name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Service"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            statusChoosed={status}
            search={search}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            userAccess={userAccess}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Service;
