import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_APPLICANT_SUCCESS,
  GET_APPLICANT_FAILED,
  ADD_APPLICANT_SUCCESS,
  ADD_APPLICANT_FAILED,
  EDIT_APPLICANT_SUCCESS,
  EDIT_APPLICANT_FAILED,
  DELETE_APPLICANT_FAILED,
  DELETE_APPLICANT_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getApplicant = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "aplicants",
          page,
          limit,
          search,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getApplicantSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getApplicantSuccess([], null));
        } else {
          dispatch(getApplicantFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getApplicantFailed(error.response.data.message));
          }
        } else {
          dispatch(getApplicantFailed(error.message));
        }
      });
  };
};

const getApplicantSuccess = (data, pagination) => {
  return {
    type: GET_APPLICANT_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getApplicantFailed = (errorMessage) => {
  return {
    type: GET_APPLICANT_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addApplicant = (token, formData) => {
  delete formData.id
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "aplicants",
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "applicant"));
          dispatch(addApplicantSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addApplicantFailed(response.data.message));
        } else {
          dispatch(addApplicantFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addApplicantFailed(error.response.data.message));
          }
        } else {
          dispatch(addApplicantFailed(error.message));
        }
      });
  };
};

const addApplicantSuccess = (message) => {
  return {
    type: ADD_APPLICANT_SUCCESS,
    payload: message,
  };
};

const addApplicantFailed = (message) => {
  return {
    type: ADD_APPLICANT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editApplicant = (token, formData, id) => {
  debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(
        `aplicants/${id}`,
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "applicant"));
          dispatch(editApplicantSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editApplicantFailed(response.data.message));
        } else {
          dispatch(editApplicantFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editApplicantFailed(error.response.data.message));
          }
        } else {
          dispatch(editApplicantFailed(error.message));
        }
      });
  };
};

const editApplicantSuccess = (message) => {
  return {
    type: EDIT_APPLICANT_SUCCESS,
    payload: message,
  };
};

const editApplicantFailed = (message) => {
  return {
    type: EDIT_APPLICANT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteApplicant = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`aplicants${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "applicant"));
          dispatch(deleteApplicantSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteApplicantFailed(response.data.message));
        } else {
          dispatch(deleteApplicantFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteApplicantFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteApplicantFailed(error.message));
        }
      });
  };
};

const deleteApplicantSuccess = (message) => {
  return {
    type: DELETE_APPLICANT_SUCCESS,
    payload: message,
  };
};

const deleteApplicantFailed = (message) => {
  return {
    type: DELETE_APPLICANT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
