import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILED,
  ADD_REQUEST_SUCCESS,
  ADD_REQUEST_FAILED,
  EDIT_REQUEST_SUCCESS,
  EDIT_REQUEST_FAILED,
  DELETE_REQUEST_FAILED,
  DELETE_REQUEST_SUCCESS,
} from "./state";
import { logout } from "../../../auth/clients/login/actions";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getRequest = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  searchBy = null,
  dateFilter = null,
  statusFilter = null,
  categoryFilter = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "requests",
          page,
          limit,
          searchValue,
          searchBy,
          dateFilter:
            dateFilter == ACTION_TYPE.DEFAULT_DATE ? null : dateFilter,
          categoryFilter:
            categoryFilter == ACTION_TYPE.ALL_CATEGORY ? null : categoryFilter,
          status: statusFilter == ACTION_TYPE.ALL_STATUS ? null : statusFilter,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getRequestSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getRequestSuccess([], null));
        } else {
          dispatch(getRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(getRequestFailed(error.message));
        }
      });
  };
};

const getRequestSuccess = (data, pagination) => {
  return {
    type: GET_REQUEST_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getRequestFailed = (errorMessage) => {
  return {
    type: GET_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addRequest = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`requests`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "request"));
          dispatch(addRequestSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addRequestFailed(response.data.message));
        } else {
          dispatch(addRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(addRequestFailed(error.message));
        }
      });
  };
};

const addRequestSuccess = (message) => {
  return {
    type: ADD_REQUEST_SUCCESS,
    payload: message,
  };
};

const addRequestFailed = (message) => {
  return {
    type: ADD_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editRequest = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `requests/${id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "request"));
          dispatch(editRequestSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editRequestFailed(response.data.message));
        } else {
          dispatch(editRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(editRequestFailed(error.message));
        }
      });
  };
};

const editRequestSuccess = (message) => {
  return {
    type: EDIT_REQUEST_SUCCESS,
    payload: message,
  };
};

const editRequestFailed = (message) => {
  return {
    type: EDIT_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteRequest = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`requests${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "request"));
          dispatch(deleteRequestSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteRequestFailed(response.data.message));
        } else {
          dispatch(deleteRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteRequestFailed(error.message));
        }
      });
  };
};

const deleteRequestSuccess = (message) => {
  return {
    type: DELETE_REQUEST_SUCCESS,
    payload: message,
  };
};

const deleteRequestFailed = (message) => {
  return {
    type: DELETE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
