export const GET_TRADEMARK_REGISTRATION_SUCCESS = "GET / TRADEMARK APPLICATION SUCCESS";
export const GET_TRADEMARK_REGISTRATION_FAILED = "GET / TRADEMARK APPLICATION FAILED";

export const UPDATE_TRADEMARK_REGISTRATION_SUCCESS = "UPDATE / TRADEMARK REGISTRATION SUCCESS";
export const UPDATE_TRADEMARK_REGISTRATION_FAILED = "UPDATE / TRADEMARK REGISTRATION FAILED";

export const SET_TRADEMARK_REGISTRATION_UPDATE_ID = "SET / TRADEMARK REGISTRATION UPDATE ID";
export const CLEAR_TRADEMARK_REGISTRATION_UPDATE_ID = "CLEAR / TRADEMARK REGISTRATION UPDATE ID";

export const STATUS_APPLICATION = Object.freeze({
  PENDING: "Pending",
  SUBMITTED: "Submitted",
  FINISH: "Finish",
  GRANTED: "Granted",
});
