import React, { useState, useEffect, useRef, memo } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  formHelperTextClasses,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  InputLabel,
  Modal,
  IconButton,
  FormHelperText,
  TextareaAutosize,
  InputAdornment,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import CalendarIcon from "./../../../../assets/calendar.png";
import RemoveIcon from "./../../../../assets/minus.svg";
import closeIcon from "../../../../assets/failed.png";
import Microphone from "../../../../assets/microphone.svg";

import {
  removeButton,
  justifyContentEnd,
  modal,
  modalHeader,
  modalActionButton,
  btnWhite,
  borderRadius,
  StyledTextArea,
  formGroup,
} from "../../../../util/style";
import axios, { headersAPIToken } from "../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  SelectOneAutocompleteSearch,
  SelectOneCountryAutocomplete,
  FilePicker,
} from "../../../../components/Form";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import AudioRecord from "../components/AudioRecord";
import { NewReleases } from "@mui/icons-material";
import { CustomStyledTextArea } from "../styles/CustomStyle";

const ANOTHER_INPUT_TYPE = Object.freeze({
  SELECT_ONE_AUTOCOMPLETE_SEARCH: "SELECT_ONE_AUTOCOMPLETE_SEARCH",
  FILE_WITH_PREVIEW: "File with preview",
  EMPTY_FIELD: "Empty Field",
  FILE: "File",
});

const TYPE_OF_MARK = Object.freeze({
  IMAGE: "Image",
  AUDIO: "Audio",
});

const fields = [
  {
    title: "Applicant Information",
    children: [
      {
        name: "client_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Client Name",
        placeholder: "Select client name",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "applicant_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Applicant",
        placeholder: "Select applicant",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        //           apiEndpoint: "/master/applicants",
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "category_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Category",
        placeholder: "Select category",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        apiEndpoint: "/master/services", // get all service
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "type_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Type",
        placeholder: "Select type",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        // apiEndpoint: "/master/criterias",
        //           apiEndpoint: `/master/services?main_service_id=${id}`,
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "email",
        type: INPUT_TYPE.TEXT,
        label: "Email",
        defaultValue: "",
        required: true,
        validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        errorMessage: "Invalid email address",
        gridWidth: 6, // Full width
      },
      {
        name: "mobile_phone",
        type: INPUT_TYPE.PHONE_NUMBER,
        label: "Phone",
        placeholder: "Input mobile phone",
        defaultValue: "",
        validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
        errorMessage:
          "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "business_field_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Business Field",
        placeholder: "Select business field",
        // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        apiEndpoint: "/master/business-fields",
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "company_name",
        type: INPUT_TYPE.TEXT,
        label: "Company",
        placeholder: "Enter company name",
        defaultValue: "",
        required: true,
        gridWidth: 6, // Full width
      },
      {
        name: "submission_on_behalf_of",
        type: INPUT_TYPE.RADIO,
        label: "Owner Type",
        defaultValue: [], // Array for selected format values
        options: [
          { id: "Personal", name: "Personal" },
          { id: "Company", name: "Company" },
        ],
        required: true,
        gridWidth: 6, // Half width
      },
    ],
  },
];

const fields2 = [
  {
    title: "Class",
    deepth: 1,
    multiple: true,
    children: [
      {
        name: "class",
        required: true,
        type: INPUT_TYPE.NUMBER,
        defaultValue: "",
        label: "Class",
        placeholder: "0",
        gridWidth: 12,
      },
      {
        name: "description_goods_service_indonesia",
        required: true,
        type: INPUT_TYPE.TEXTAREA,
        defaultValue: "",
        label: "Description of Goods/Services Indonesia",
        placeholder: "Input Description of Goods/Services",
        gridWidth: 6,
        rowsMin: 4
      },
      {
        name: "description_goods_service_english",
        required: false,
        type: INPUT_TYPE.TEXTAREA,
        defaultValue: "",
        label: "Description of Goods/Services English (Optional)",
        placeholder: "Input Description of Goods/Services (English)",
        gridWidth: 6,
        rowsMin: 4
      },
    ],
  },
  // {
  //   title: "Priority Data (Optional)",
  //   deepth: 1,
  //   children: [
  //     {
  //       name: "priority_date",
  //       required: false,
  //       type: INPUT_TYPE.DATE,
  //       defaultValue: "",
  //       label: "Priority Date",
  //       gridWidth: 6,
  //     },
  //     {
  //       name: "priority_country_id",
  //       required: false,
  //       type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY,
  //       defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //       label: "Priority Country",
  //       placeholder: "Select Priority Country",
  //       gridWidth: 6,
  //       apiEndpoint: "/master/countries", // get all service
  //     },
  //     {
  //       name: "number_of_priority",
  //       required: false,
  //       type: INPUT_TYPE.NUMBER,
  //       defaultValue: "",
  //       label: "Number of Priority",
  //       placeholder: "0",
  //       gridWidth: 6,
  //     },
  //   ],
  // },
];

const style = {
  microphoneCard: {
    border: "1px solid #00000050",
    background: "#D0E1F1",
    width: "3.3rem",
    height: "3.3rem",
    borderRadius: "15%",
    display: "grid",
    placeItems: "center",
    // marginBottom: "1rem",
    cursor: "pointer",
  },
};

const StepThreeRevisi = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
  setIsFormValid,
}) => {
  const { id } = useParams();

  //* Local Storage Hook
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    "trademark_application_options_stepThreeRevisi",
    {}
  );

  const CurrentStepName = "StepThree";

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered]);

  const { token } = useSelector((state) => state.auth);

  const [typeOfMark, setTypeOfMark] = useState(TYPE_OF_MARK.IMAGE);
  const [showAudioPick, setShowAudioPick] = useState(false);
  //* ===================================================================  */
  //*                              INITIAL FORM                            */
  //* ===================================================================  */

  const initialFormValues = () => {
    console.group("Initial form");
    const initialValues = {};
    const initialValues2 = {};

    //* OLD Data
    // fields.forEach((fieldGroup) => {
    //   if (fieldGroup.children) {
    //     if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
    //       initialValues[fieldGroup.title] =
    //         fieldGroup.title === "Family" ? [initialFamily] : [initialEmergencyContact];
    //     } else {
    //       fieldGroup.children.forEach((field) => {
    //         if (!initialValues[fieldGroup.title]) {
    //           initialValues[fieldGroup.title] = {};
    //         }
    //         initialValues[fieldGroup.title][field.name] = field.defaultValue;
    //       });
    //     }
    //   }
    // });

    const sectionFieldCallback = (sectionField) => {
      const resultValue = {};
      if (sectionField.children && Array.isArray(sectionField.children)) {
        sectionField.children.forEach((field) => {
          if (field.multiple) {
            resultValue[field.name] = [field.defaultValue] || [""];
          } else {
            resultValue[field.name] = field.defaultValue || "";
          }
        });
      }
      return resultValue;
    };

    //* New (Currently Used)
    fields2.forEach((section) => {
      if (section.children && Array.isArray(section.children)) {
        if (!initialValues2[section.title]) {
          initialValues2[section.title] = {};
        }
        if (section.multiple) {
          initialValues2[section.title] = [sectionFieldCallback(section)];
        } else {
          initialValues2[section.title] = sectionFieldCallback(section);
        }

        // section.children.forEach((field) => {
        //   // console.log(field);
        //   if (field.children && Array.isArray(field.children)) {
        //     let childVal = {};
        //     field.children.forEach((fiel) => {
        //       if (!fiel.hidden) {
        //         childVal[fiel.name] = fiel.defaultValue || "";
        //       }
        //     });

        //     if (field.multiple) {
        //       initialValues2[section.title][field.name] = [childVal];
        //     } else {
        //       initialValues2[section.title][field.name] = childVal;
        //     }
        //   } else {
        //     initialValues2[section.title][field.name] = field.multiple
        //       ? [field.defaultValue]
        //       : field.defaultValue || "";
        //   }
        // });
      }
    });

    console.log("Result init 2", initialValues2);
    console.groupEnd();
    return {
      ...initialValues2,
    };
  };

  const initialErrors = () => {
    console.groupEnd();
    const initialErrorValues = {};
    const initialErrorValues2 = {};

    //* OLD Data
    // fields.forEach((fieldGroup) => {
    //   if (fieldGroup.children) {
    //     if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
    //       initialErrorValues[fieldGroup.title] =
    //         fieldGroup.title === "Family"
    //           ? [initialErrorFamily]
    //           : [initialErrorEmergencyContact];
    //     } else {
    //       if (!initialErrorValues[fieldGroup.title]) {
    //         initialErrorValues[fieldGroup.title] = {};
    //       }
    //       fieldGroup.children.forEach((field) => {
    //         initialErrorValues[fieldGroup.title][field.name] = "";
    //       });
    //     }
    //   }
    // });

    const sectionCallback = (section) => {
      const resultValue = {};
      if (section.children && Array.isArray(section.children)) {
        section.children.forEach((field) => {
          if (field.multiple) {
            resultValue[field.name] = [field.defaultValue] || [""];
          } else {
            resultValue[field.name] = field.defaultValue || "";
          }
        });
      }
      return resultValue;
    };

    //* New (Currently Used)
    fields2.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (!initialErrorValues2[fieldGroup.title]) {
          initialErrorValues2[fieldGroup.title] = {};
        }
        if (fieldGroup.multiple) {
          initialErrorValues2[fieldGroup.title] = [sectionCallback(fieldGroup)];
        } else {
          initialErrorValues2[fieldGroup.title] = sectionCallback(fieldGroup);
        }

        // fieldGroup.children.forEach((field) => {
        //   if (field.children && Array.isArray(field.children)) {
        //     let childVal = {};
        //     field.children.forEach((fiel) => {
        //       if (!fiel.hidden) {
        //         childVal[fiel.name] = "";
        //       }
        //     });
        //     if (field.multiple) {
        //       initialErrorValues2[fieldGroup.title][field.name] = [childVal];
        //     } else {
        //       initialErrorValues2[fieldGroup.title][field.name] = childVal;
        //     }
        //   } else {
        //     initialErrorValues2[fieldGroup.title][field.name] = field.multiple
        //       ? [""]
        //       : "";
        //   }
        // });
      }
    });

    console.log("Initial error 2", initialErrorValues2);
    console.groupEnd();

    return { ...initialErrorValues2 };
  };

  // ---------------------- [ STATE ] ---------------------------- */
  const [options, setOptions] = useState({});
  const [urlPreview, setUrlPreview] = useState(null);
  const [audioField, setAudioField] = useState({});
  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0 ? formData : initialFormValues()
  );

  const [errors, setErrors] = useState(
    formData && Object.values(formData)?.length > 0
      ? cloneObjectWithEmptyStrings(formData)
      : initialErrors()
  );

  //   const fetchDataType = async () => {
  //     try {
  //       const serviceId = formValues["Applicant Information"]["category_id"];

  //       // console.log(headersAPIToken(token));
  //       if (serviceId) {
  //         const response = await axios.get(
  //           `/master/criterias/${serviceId}`,
  //           headersAPIToken(token)
  //         );

  //         const responseData = response?.data?.data;

  //         setOptions((prevOptions) => ({
  //           ...prevOptions,
  //           ["type_id"]: responseData,
  //         }));

  //         if (localStorageValue) {
  //           const copyLocalStorage = { ...localStorageValue };
  //           copyLocalStorage["type_id"] = responseData;
  //           setLocalStorageValue(copyLocalStorage);
  //         } else {
  //           // localStorage.setItem("business_permit_options_step_one", JSON.stringify(options));
  //           setLocalStorageValue(options);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Failed get option, error: ", error);
  //     }
  //   };

  //   const fetchDataApplicantAndBrand = async () => {
  //     const client_id = formValues["Applicant Information"]["client_id"];

  //     if (client_id) {
  //       const aplicanEndpoint = `/master/applicants`;
  //       const brandEndpoint = `/master/brands`;
  //       const param = { params: client_id };

  //       fetchOption(aplicanEndpoint, "applicant_id", null, param);
  //       fetchOption(brandEndpoint, "brand_id", null, param);

  //       // const { data: dataApplicants } = await (
  //       //   await axios.get(`master/applicants`, {
  //       //     headers: { Authorization: `Bearer ${token}` },
  //       //     params: { client_id },
  //       //   })
  //       // )?.data;

  //       // const { data: dataBrands } = await (
  //       //   await axios.get(`/master/brands`, {
  //       //     headers: {
  //       //       Authorization: `Bearer ${token}`,
  //       //       params: { client_id },
  //       //     },
  //       //   })
  //       // )?.data;

  //       // setOptions((prevOptions) => ({
  //       //   ...prevOptions,
  //       //   ["applicant_id"]: dataApplicants,
  //       //   ["brand_id"]: dataBrands,
  //       // }));

  //       // const fetchedOptions = JSON.parse(
  //       //   localStorage.getItem("business_permit_options_step_one")
  //       // );
  //       // if (fetchedOptions) {
  //       //   fetchedOptions["applicant_id"] = dataApplicants;
  //       //   fetchedOptions["brand_id"] = dataBrands;
  //       //   localStorage.setItem(
  //       //     "business_permit_options_step_one",
  //       //     JSON.stringify(fetchedOptions)
  //       //   );
  //       // } else {
  //       //   localStorage.setItem("business_permit_options_step_one", JSON.stringify(options));
  //       //   setLocalStorageValue(options);
  //       // }
  //       // debugger;
  //     }
  //   };

  //   useEffect(() => {
  //     if (!localStorageValue || Object.keys(localStorageValue || {}).length === 0 || id) {
  //       console.log("Masuk fetch option");
  //       const fetchData = async () => {
  //         try {
  //           const fetchedOptions = {};

  //           for (const section of fields2) {
  //             if (section.children) {
  //               for (const field of section.children) {
  //                 const { name, apiEndpoint } = field;
  //                 // console.log({ name, apiEndpoint });
  //                 if (apiEndpoint) {
  //                   const response = await axios.get(apiEndpoint, headersAPIToken(token));
  //                   fetchedOptions[name] = response.data.data;
  //                 }
  //               }
  //             }
  //           }

  //           // Store fetched options in localStorage
  //           setLocalStorageValue(fetchedOptions);
  //           // localStorage.setItem(
  //           //   "business_permit_options_step_one",
  //           //   JSON.stringify(fetchedOptions)
  //           // );

  //           // Set options in state
  //           setOptions(fetchedOptions);
  //           fetchDataType();
  //         //   fetchDataApplicantAndBrand();
  //         } catch (error) {
  //           console.error("Error fetching options:", error);
  //         }
  //       };

  //       fetchData();
  //     } else {
  //       console.log("Gk masuk fetcg option");
  //       setOptions(localStorageValue);
  //       fetchDataType();
  //     }
  //     console.log("Initial option");
  //   }, [token]);

  //   const fetchOption = async (
  //     endpoint,
  //     optionName,
  //     aliasName = "",
  //     customConfig = null
  //   ) => {
  //     try {
  //       let config = { ...headersAPIToken(token) };

  //       if (customConfig) {
  //         config = { ...config, ...customConfig };
  //       }
  //       console.log("This is fetch config", { config });

  //       const response = await axios.get(endpoint, config);
  //       let optionPayload = response?.data?.data;

  //       //* If option don't have key name, pass alias name for make alias;
  //       if (aliasName) {
  //         optionPayload = optionPayload.map((opt) => {
  //           opt["name"] = opt[aliasName];
  //           return opt;
  //         });
  //       }
  //       if (Array.isArray(optionPayload)) {
  //         //* Set option
  //         setOptions((prevValue) => ({ ...prevValue, [optionName]: optionPayload }));

  //         //* Set to localStorage
  //         if (localStorageValue) {
  //           const copyLocalStorage = { ...localStorageValue };
  //           copyLocalStorage[optionName] = optionPayload;
  //           setLocalStorageValue(copyLocalStorage);
  //         }
  //       } else {
  //         console.warn("Option payload not array");
  //         throw new Error("Failed get option for : ".concat(optionName));
  //       }
  //     } catch (error) {
  //       console.error(`failed get option for ${optionName} : ${error}`);
  //     }
  //   };

  const initialFormValuesBySection = (sectionTitle) => {
    const newFormValues = {};
    const sectionField = fields2.find((section) => section.title === sectionTitle);
    if (sectionField) {
      sectionField.children.forEach((field) => {
        newFormValues[field.name] = field.defaultValue || "";
      });
    }
    return newFormValues;
  };

  const onUnMount = () => {
    console.log("Step two on mounted");
  };
  const onMount = () => {
    //* Find the file field;
    let fieldGroup, fileField;
    fields2.forEach((groupField) => {
      if (groupField.children && Array.isArray(groupField.children)) {
        groupField.children.forEach((field) => {
          if (field.type === ANOTHER_INPUT_TYPE.FILE_WITH_PREVIEW) {
            fileField = field;
            fieldGroup = groupField;
          }
        });
      }
    });

    if (fieldGroup && fileField) {
      const valueFile = formValues[fieldGroup.title][fileField.name];
      if (valueFile) {
        if (valueFile instanceof File) {
          const urlFile = window.URL.createObjectURL(valueFile);
          setUrlPreview(urlFile);
        } else if (typeof valueFile === "object") {
          setUrlPreview(valueFile.url);
        }
      } else {
        console.log("File tidak ketemu");
      }

      const audioField = {
        ...fileField,
        name: "sound_of_mark",
        allowedExtensions: [".mp3", ".weba"],
        maximumSizeInMB: 5,
      };
      setAudioField(audioField);
    }

    /** Find file field */

    return () => {
      onUnMount();
    };
  };

  useEffect(onMount, []);

  //* ===================================================================  */
  //*                               HANDLER                                */
  //* ===================================================================  */

  const replaceNextLine = (text) => text.replace(/\n/gm, "; ");

  const handleCloseRecordModal = () => {
    const descriptionSection = fields2[0];
    const markAttachment = descriptionSection.children.find(
      (field) => field.name === "mark_attachment"
    );
    if (markAttachment) {
      const updatedForm = { ...formValues[descriptionSection.title] };
      const soundMark = updatedForm[audioField.name];
      if (soundMark) {
        updatedForm[markAttachment.name] = soundMark;
        updatedForm[audioField.name] = "";
      }

      setFormValues((prevVal) => {
        const newForm = { ...prevVal, [descriptionSection.title]: updatedForm };
        return newForm;
      });
    }
    setShowAudioPick(false);
  };

  const handleCloseModalRecord = () => {
    const descriptionSection = fields2[0];
    const updatedFormValues = { ...formValues[descriptionSection.title] };
    updatedFormValues[audioField.name] = "";
    setFormValues((prevValue) => {
      const newVal = { ...prevValue, [descriptionSection.title]: updatedFormValues };
      return newVal;
    });
    setShowAudioPick(false);
  };

  const handleChangerRecord = (e) => {
    console.group("Audio file on change");
    const { name, value } = e.target;
    // console.log(value);
    const errorAudio = validateFileInput(value, audioField);
    // console.log({ errorAudio });
    const descriptionSectionField = fields2[0];
    const updatedFormValues = { ...formValues[descriptionSectionField.title] };
    const updatedErrors = { ...errors[descriptionSectionField.title] };
    const imageMarkField = descriptionSectionField.children.find(
      (field) => field.name === "mark_attachment"
    );
    updatedFormValues[audioField.name] = value;
    updatedErrors[imageMarkField.name] = errorAudio;
    console.log({ updatedErrors, updatedFormValues, errorAudio, imageMarkField });

    setFormValues((prev) => {
      const newForm = { ...prev, [fields2[0].title]: updatedFormValues };
      return newForm;
    });
    setErrors((prevVal) => {
      const newErr = { ...prevVal, [fields2[0].title]: updatedErrors };
      return newErr;
    });
    console.groupEnd();
    // console.log();
    // setFormValues((prevValue) => {
    //   const updatedFormValues = { ...prevValue };
    //   updatedFormValues[descriptionSectionField.title][imageMarkField.name] = value;
    //   return updatedFormValues;
    // });

    // setErrors((prevValue) => {
    //   const updatedErrors = { ...prevValue };
    //   updatedErrors[descriptionSectionField.title][imageMarkField.name] = errorAudio;
    // });
  };

  const handleFileSelect = (file, fieldName, sectionName) => {
    // const file = e.target.files;
    setFormValues((prevValue) => {
      const updatedFormValues = { ...prevValue };
      updatedFormValues[sectionName][fieldName] = file;
      return updatedFormValues;
    });
    // console.log(file);
  };

  const handleRemoverImage = (fieldName, sectionName) => {
    setFormValues((prevValue) => {
      const updatedFormValues = { ...prevValue };
      updatedFormValues[sectionName][fieldName] = "";
      return updatedFormValues;
    });
    setUrlPreview(null);
  };

  const handleCheckboxSetSameAddres = (e) => {
    const { checked } = e.target;
    // console.log(name, checked);
    const updatedFormValues = { ...formValues };
    const addressSection = fields2[1];
    const deleveryAddressSection = fields2[2];
    if (checked) {
      const addressFormValues = formValues[addressSection.title];
      const newDeleveryAddress = {};

      //* Copy value from address;
      for (const keyForm in addressFormValues) {
        newDeleveryAddress[keyForm] = addressFormValues[keyForm];
      }
      updatedFormValues[deleveryAddressSection.title] = newDeleveryAddress;
    } else {
      const emptyFormValues = initialFormValuesBySection(deleveryAddressSection.title);
      updatedFormValues[deleveryAddressSection.title] = emptyFormValues;
    }

    setFormValues(updatedFormValues);
  };

  const handleAddRow = (section, field) => {
    const newFormValues = { ...formValues };
    const updatedErrors = { ...errors };

    const newFieldFormValues = initialFormValues()[section.title];
    newFormValues[section.title] =
      newFormValues[section.title].concat(newFieldFormValues);

    const initErrors = initialErrors()[section.title];
    updatedErrors[section.title] = updatedErrors[section.title].concat(initErrors);

    // console.log(newFormValues);
    // console.log(init);
    // let newValue = null;
    // let newErrors = null;
    // if (field?.children) {
    //   newValue = {};
    //   newErrors = {};
    //   field.children.forEach((fiel) => {
    //     newValue[fiel.name] = fiel.defaultValue || "";
    //     // updatedErrors[fiel.name] = "";
    //     newErrors[fiel.name] = "";
    //   });
    // } else {
    //   newValue = field.defaultValue || "";
    //   newErrors = "";
    // }
    // //* Assing new value to copy formValues
    // newFormValues[section.title][field.name].push(newValue);
    setFormValues(newFormValues);

    // //* Set new Errors
    // updatedErrors[section.title][field.name].push(newErrors);
    setErrors(updatedErrors);
  };

  const handleRemoveRow = (rowIndex, section, field) => {
    const newFormValues = { ...formValues };
    const newErrors = { ...errors };

    if (
      !isNaN(rowIndex) &&
      newFormValues[section.title] &&
      Array.isArray(newFormValues[section.title])
    ) {
      newFormValues[section.title].splice(rowIndex, 1);
      newErrors[section.title].splice(rowIndex, 1);
    }
    // console.log({ rowIndex, section, newFormValues });
    // if (!isNaN(rowIndex)) {
    //   newFormValues[section.title][field.name].splice(rowIndex, 1);
    //   // console.log({ errors: newErrors[section.title] });
    //   console.log({ error: newErrors[section.title][field.name] });
    //   // newErrors[section.title][field.name]?.splice(rowIndex, 1);
    // } else {
    //   /** For remove audio mark */
    //   newFormValues[section.title][field.name] = "";
    //   newErrors[section.title][field.name] = "";
    // }

    setFormValues(newFormValues);
    setErrors(newErrors);
  };

  const handleInputChange = async (event, index, sectionTitle, field, parent) => {
    console.group("handle change");
    const { name, type } = event.target;
    let { value } = event.target;
    // const updatedErrors = { ...errors };
    const decriptionFieldNames = [
      "description_goods_service_indonesia",
      "description_goods_service_english",
    ];
    const updatedFormValues = { ...formValues };
    const updatedErrors = { ...errors };

    //* Validate input file;
    if (type === "file") {
      /** Get the file from event target */
      const file = event.target.files[0];
      if (file) {
        /** Validate File */
        const errorFile = validateFileInput(file, field);
        if (!errorFile) {
          /** If no error apear, create and set url preview */
          const fileUrl = window.URL.createObjectURL(file);
          setUrlPreview(fileUrl);
        }
        updatedErrors[sectionTitle][field.name] = errorFile;
      }
      setErrors(updatedErrors);
      /** Return the function, process not be continue */
      return;
    }

    /** Replace nextLine with  */
    // if (decriptionFieldNames.includes(name)) {
    //   value = replaceNextLine(value);
    // }

    // console.log({ index, sectionTitle, field, parent });
    if (parent) {
      if (!isNaN(index)) {
        updatedFormValues[sectionTitle][parent.name][index][name] = value;
      }
    } else if (!isNaN(index)) {
      if (field.multiple) {
        updatedFormValues[sectionTitle][name][index] = value;
      } else {
        updatedFormValues[sectionTitle][index][field.name] = value;
      }
    } else {
      updatedFormValues[sectionTitle][name] = value;
    }

    setFormValues(updatedFormValues);
    updateWizardData(CurrentStepName, updatedFormValues);
    console.groupEnd();

    // if (name === "type_of_mark_id") {
    //   if (value === "suara") {
    //     setTypeOfMark(TYPE_OF_MARK.AUDIO);
    //   } else {
    //     setTypeOfMark(TYPE_OF_MARK.IMAGE);
    //   }
    // }

    //* OLD

    // debugger;
    // setFormValues((prevValues) => {
    //   const updatedValues = { ...prevValues };
    //   if (
    //     index !== undefined &&
    //     (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
    //   ) {
    //     updatedValues[sectionTitle] = updatedValues[sectionTitle].map((item, i) =>
    //       i === index ? { ...item, [name]: value } : item
    //     );
    //   } else {
    //     updatedValues[sectionTitle] = {
    //       ...updatedValues[sectionTitle],
    //       [name]: value,
    //     };
    //   }

    //   return updatedValues;
    // });

    // setErrors((prevErrors) => {
    //   const fieldValue = value;
    //   const fieldErrors = validateFieldHelper(fieldValue, field);

    //   if (
    //     index !== undefined &&
    //     (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
    //   ) {
    //     updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map((item, i) =>
    //       i === index ? { ...item, [name]: fieldErrors } : { ...item }
    //     );
    //   } else {
    //     updatedErrors[sectionTitle] = {
    //       ...updatedErrors[sectionTitle],
    //       [name]: fieldErrors,
    //     };
    //   }
    //   return updatedErrors;
    // });

    // if (name === "category_id") {
    //   if (value) {
    //     const serviceId = value;
    //     const criteriaEndpoint = `/master/criterias/${serviceId}`;
    //     fetchOption(criteriaEndpoint, "type_id");
    //   }
    // }

    // if (name === "client_id") {
    //   if (value) {
    //     const client_id = value;
    //     const param = { params: { client_id } };
    //     const applicantEndpoint = "master/applicants";
    //     const brandEndpoint = "/master/brands";

    //     fetchOption(applicantEndpoint, "applicant_id", null, param);
    //     fetchOption(brandEndpoint, "brand_id", null, param);

    // const { data: dataApplicants } = await (
    //   await axios.get(``, {
    //     headers: { Authorization: `Bearer ${token}` },
    //     ,
    //   })
    // )?.data;

    // const { data: dataBrands } = await (
    //   await axios.get(``, {
    //     headers: { Authorization: `Bearer ${token}` },
    //     params: { client_id },
    //   })
    // )?.data;

    // setOptions((prevOptions) => ({
    //   ...prevOptions,
    //   ["applicant_id"]: dataApplicants,
    //   ["brand_id"]: dataBrands,
    // }));

    // const fetchedOptions = JSON.parse(
    //   localStorage.getItem("business_permit_options_step_one")
    // );
    // if (fetchedOptions) {
    //   fetchedOptions["brand_id"] = dataBrands;
    //   fetchedOptions["applicant_id"] = dataApplicants;
    //   localStorage.setItem(
    //     "business_permit_options_step_one",
    //     JSON.stringify(fetchedOptions)
    //   );
    // }
    //   }
    // }

    // if (name === "province_id") {
    //   if (value) {
    //     const provinceId = value;
    //     const rigencyEndpoint = "/master/regencies?province_id=".concat(provinceId);
    //     fetchOption(rigencyEndpoint, "rigency_id");
    //   }
    // }
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event, parent) => {
    const { name, value } = event.target;
    console.group("On Blur");
    updateWizardData(CurrentStepName, formValues);
    validateField(sectionTitle, fieldName, index, parent, { name, value });
    // debugger;
    console.groupEnd();
  };

  //* ===================================================================  */
  //*                               VALIDATE                               */
  //* ===================================================================  */

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const validateFormCallback = (field, indexField, fieldGroup) => {
      const sectionTitle = fieldGroup.title;
      if (field.children && Array.isArray(field.children)) {
        if (!newErrors[sectionTitle]) {
          newErrors[sectionTitle] = {};
        }
        if (!newErrors[sectionTitle][field.name]) {
          newErrors[sectionTitle][field.name] = [];
        }
        const childField = field.children;

        formValues[sectionTitle][field.name].forEach((valueInForm, indexValue) => {
          // console.log({ valueInForm });
          if (typeof valueInForm === "object") {
            for (const keyValue in valueInForm) {
              if (!newErrors[sectionTitle][field.name][indexValue]) {
                newErrors[sectionTitle][field.name][indexValue] = {};
              }
              const currentField = childField.find((fiel) => fiel.name === keyValue);
              if (currentField) {
                const errorValidate = validateFieldHelper(
                  valueInForm[keyValue],
                  currentField
                );

                if (errorValidate) {
                  valid = false;
                }
                newErrors[sectionTitle][field.name][indexValue][keyValue] = errorValidate;
              }
            }
          }
        });
      } else if (field.multiple) {
        /** For multiple value field */
        // console.log("Multiple values", field);
        const valueInForm = formValues[sectionTitle][field.name];
        if (valueInForm && Array.isArray(valueInForm)) {
          if (!newErrors[sectionTitle]) {
            newErrors[sectionTitle] = {};
          }
          const errorsValidate = valueInForm.map((value) =>
            validateFieldHelper(value, field)
          );
          const hasErrorMessage = errorsValidate.some((val) => val != "");
          if (hasErrorMessage) {
            valid = false;
          }
          newErrors[sectionTitle][field.name] = errorsValidate;
        }
      } else {
        /** For normal field */
        if (!newErrors[sectionTitle]) {
          newErrors[sectionTitle] = {};
        }
        const valueInForm = formValues[sectionTitle][field.name];
        const errorValidate = validateFieldHelper(valueInForm, field);

        if (errorValidate) {
          valid = false;
        }
        newErrors[sectionTitle][field.name] = errorValidate;
      }
    };

    //* Old Field
    // fields.forEach((fieldGroup) => {
    //   if (fieldGroup.children) {
    //     const { title } = fieldGroup;

    //     const sectionErrors =
    //       fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact"
    //         ? []
    //         : {};

    //     fieldGroup.children.forEach((field) => {
    //       const { name } = field;

    //       if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
    //         formValues[title].forEach((sectionValues, index) => {
    //           const fieldValue =
    //             sectionValues[name] !== undefined ? sectionValues[name] : "";
    //           const error = validateFieldHelper(fieldValue, field);

    //           if (!sectionErrors[index]) {
    //             sectionErrors[index] = {};
    //           }

    //           sectionErrors[index][name] = error;

    //           if (error) {
    //             valid = false;
    //           }
    //         });
    //       } else {
    //         const categoryId = formValues["Applicant Information"]["category_id"];

    //         if (categoryId && categoryId !== 102) {
    //           if (field.name !== "type_id") {
    //             const fieldValue =
    //               formValues[title] && formValues[title][name] !== undefined
    //                 ? formValues[title][name]
    //                 : "";
    //             const error = validateFieldHelper(fieldValue, field);

    //             sectionErrors[name] = error;

    //             if (error) {
    //               valid = false;
    //             }
    //           }
    //         } else {
    //           const fieldValue =
    //             formValues[title] && formValues[title][name] !== undefined
    //               ? formValues[title][name]
    //               : "";
    //           const error = validateFieldHelper(fieldValue, field);

    //           sectionErrors[name] = error;

    //           if (error) {
    //             valid = false;
    //           }
    //         }
    //       }
    //     });

    //     newErrors[title] = sectionErrors;
    //   }
    // });

    //* New Field (Currenly Used)
    fields2.forEach((fieldGroup) => {
      console.group("validate form, section: ".concat(fieldGroup.title));
      if (fieldGroup.children && Array.isArray(fieldGroup.children)) {
        /** For field that has children, example Class Field */

        if (fieldGroup.multiple) {
          if (!newErrors[fieldGroup.title]) {
            newErrors[fieldGroup.title] = [];
          }
          // console.log({ fieldGroup });
          formValues[fieldGroup.title].forEach((valueForm, indexValue) => {
            const sectionError = {};
            // console.log(valueForm, indexValue);
            Object.entries(valueForm).forEach(([key, value]) => {
              const findedField = fieldGroup.children.find((field) => field.name === key);
              if (findedField) {
                // console.log({ findedField, value });
                const errorValidation = validateFieldHelper(value, findedField);
                sectionError[findedField.name] = errorValidation;
                if (errorValidation) {
                  valid = false;
                }
              }
            });

            newErrors[fieldGroup.title][indexValue] = sectionError;
          });
        } else {
          // valid = false;
          fieldGroup.children.forEach((field, index) =>
            validateFormCallback(field, index, fieldGroup)
          );
        }
      }
      console.log("Errors", newErrors);
      console.groupEnd();
    });
    // console.log(newErrors);

    // fields2.forEach((fieldGroup) => {
    //   console.group("Section ".concat(fieldGroup.title));
    //   if (fieldGroup.multiple) {
    //     if (!newErrors[fieldGroup.title]) {
    //       newErrors[fieldGroup.title] = [];
    //     }
    //     // console.log({ fieldGroup });
    //     formValues[fieldGroup.title].forEach((valueForm, indexValue) => {
    //       const sectionError = {};
    //       // console.log(valueForm, indexValue);
    //       Object.entries(valueForm).forEach(([key, value]) => {
    //         const findedField = fieldGroup.children.find((field) => field.name === key);
    //         if (findedField) {
    //           // console.log({ findedField, value });
    //           const errorValidation = validateFieldHelper(value, findedField);
    //           sectionError[findedField.name] = errorValidation;
    //           if (errorValidation) {
    //             valid = false;
    //           }
    //         }
    //       });

    //       newErrors[fieldGroup.title][indexValue] = sectionError;
    //     });
    //   }
    //   console.groupEnd();
    // });

    // console.log({ newErrors });
    setErrors(newErrors);
    formAttempted(valid);
    updateWizardData(CurrentStepName, formValues);
    return valid;
  };

  const validateField = (sectionTitle, fieldName, index, parent, { value }) => {
    // console.group("Validate field");
    const updatedErrors = { ...errors };
    const fieldGroup = fields2.find((group) => group.title === sectionTitle);
    let findedField = null;

    //* find field by type
    if (parent && parent !== null) {
      findedField = parent.children.find((fiel) => fiel.name == fieldName);
    } else if (fieldGroup && fieldGroup.children) {
      findedField = fieldGroup.children.find((field) => field.name === fieldName);
    }

    console.log({ sectionTitle, fieldName, findedField, index, parent, value });
    if (findedField) {
      const { name, type } = findedField;

      // const validateErrorMessage = validateFieldHelper(valueForm, findedField);
      if (parent) {
        const valueForm = formValues[sectionTitle][parent.name][index][name];
        updatedErrors[sectionTitle][parent.name][index][name] = validateFieldHelper(
          valueForm,
          findedField
        );
      } else if (findedField.multiple) {
        const valueForm = formValues[sectionTitle][name][index];
        updatedErrors[sectionTitle][name][index] = validateFieldHelper(
          valueForm,
          findedField
        );
      } else if (!isNaN(index)) {
        const valueForm = formValues[sectionTitle][index][findedField.name];
        const validateErrorMessage = validateFieldHelper(valueForm, findedField);
        updatedErrors[sectionTitle][index][findedField.name] = validateErrorMessage;
        console.log({ valueForm, validateErrorMessage });
      } else {
        const valueForm = formValues[sectionTitle][name];
        updatedErrors[sectionTitle][name] = validateFieldHelper(valueForm, findedField);
      }
    }
    // console.log("Error validate", updatedErrors[sectionTitle]);
    // console.groupEnd();
    setErrors(updatedErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    }
    if (fieldValue && fieldValue.length && validation && !validation(fieldValue)) {
      return errorMessage || `${label} is invalid`;
    }
    return "";
  };

  const validateFileInput = (file, field) => {
    if (file instanceof File) {
      let allowExtension;
      if (typeOfMark == TYPE_OF_MARK.AUDIO) {
        allowExtension = field.allowedExtensions || [".mp3"];
      } else if (typeOfMark === TYPE_OF_MARK.IMAGE) {
        allowExtension = field.allowedExtensions || [".jpeg", ".jpg", ".png"];
      }

      const size = field.maximumSizeInMB || 5;
      const maxSizeInBytes = size * 1024 * 1024; //MB
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowExtension.includes(`.${fileExtension}`)) {
        // console.log({ allowExtension, fileExtension, typeOfMark, field });
        return "Invalid file extension. Please select a file with valid extension";
      } else if (file.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  useEffect(() => {
    updateWizardData(CurrentStepName, formValues);
  }, [formValues]);

  //* ===================================================================  */
  //*                               WATCHER                                */
  //* ===================================================================  */

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  useEffect(() => {
    console.log("This is option", { options });
  }, [options]);

  //* ===================================================================  */
  //*                               COMPONENT                              */
  //* ===================================================================  */

  const ButtonAdd = ({ section, field }) => {
    // const aa = {section, field};
    // debugger;
    return (
      <Box sx={{ ...justifyContentEnd }}>
        <Button
          onClick={() => handleAddRow(section, field)}
          //   disabled={actionType === ACTION_TYPE.EDITED}
        >
          <span
            style={{
              fontSize: "1.5rem",
              marginRight: ".6rem",
              display: "inline-block",
            }}
          >
            +
          </span>
          Add More
        </Button>
      </Box>
    );
  };

  const ButtonRemove = ({ index, section, field, disabled = false }) => {
    // if (field.multiple) {
    //   // console.log({ formValues: formValues[section.title][field.name] });
    //   isDisabled = formValues[section.title][field.name].length <= 1;
    // } else {
    //   isDisabled = formValues[section.title][field.name] == "";
    // }
    return (
      <Grid grid item xs={2} style={{ paddingBlock: ".2rem" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            ...removeButton,
          }}
          onClick={() => handleRemoveRow(index, section, field)}
        >
          <img src={RemoveIcon} alt={`Remove item`} />
        </Button>
      </Grid>
    );
  };

  const HiddenComponent = () => {
    return (
      <div
        aria-hidden
        style={{
          width: "100%",
          height: "inherit",
          color: "transparent",
          background: "#FFFFFF00",
          userSelect: "none",
        }}
      >
        &nbsp;
      </div>
    );
  };

  const renderFields = (section, index, field, sectionFormValues = null) => {
    const currentFormValues = formValues[section.title];
    let currentErrors = errors[section.title];

    // const renderComponentField = (children, parent = null, indexValueParent) => {
    //   return children?.map((field) => {
    //     let fieldComponent = null;
    //     let hasChildren = false;
    //     if (field.children) {
    //       hasChildren = true;
    //       fieldComponent = (
    //         <>
    //           {formValues[section.title][field.name].map((_, indexValue) => {
    //             return (
    //               <>
    //                 <Grid
    //                   item
    //                   xs={11.3}
    //                   container
    //                   spacing={1}
    //                   sx={{
    //                     border: "1px solid black",
    //                     padding: ".4rem",
    //                     marginTop: ".5rem",
    //                     marginLeft: ".5rem",
    //                     borderRadius: "12px",
    //                     paddingRight: "1rem",
    //                   }}
    //                 >
    //                   {renderComponentField(field.children, field, indexValue)}
    //                 </Grid>
    //                 <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
    //                   <ButtonRemove index={indexValue} field={field} section={section} />
    //                 </Grid>
    //               </>
    //             );
    //           })}
    //         </>
    //       );
    //     } else {
    //       // const checkCategoryId = formValues["Applicant Information"]["category_id"];

    //       // const isCategoryIdBPOMandExist =
    //       //   checkCategoryId !== 102 &&
    //       //   (checkCategoryId !== undefined ||
    //       //     checkCategoryId !== null ||
    //       //     checkCategoryId !== "");

    //       // Check if children array exists
    //       let optionsData;
    //       if (Array.isArray(options[field.name])) {
    //         const isRequired = field.required &&
    //           field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
    //             id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    //             name: "Choose Option",
    //           };

    //         if (isRequired) {
    //           optionsData = [
    //             // field.required &&
    //             //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
    //             //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    //             //     name: "Choose Option",
    //             //   },
    //             ...options[field.name],
    //           ];
    //         } else {
    //           optionsData = [...options[field.name]];
    //         }
    //       }

    //       let fieldOptions;
    //       const localFieldOption = field.options;
    //       if (Array.isArray(options[field.name])) {
    //         fieldOptions = optionsData;
    //       } else if (Array.isArray(localFieldOption)) {
    //         fieldOptions = field.options;
    //       } else {
    //         fieldOptions = [];
    //       }

    //       /** Child component for input file and preview */

    //       const PreviewImage = () => {
    //         return (
    //           <div style={{ marginLeft: ".4rem", paddingBlock: ".6rem" }}>
    //             <Typography variant="body1" style={{ fontWeight: 600 }}>
    //               Image of Mark
    //             </Typography>
    //             <div
    //               style={{
    //                 position: "relative",
    //                 display: "inline-block",
    //                 padding: 0,
    //               }}
    //             >
    //               <div
    //                 style={{
    //                   position: "absolute",
    //                   right: 5,
    //                   top: 5,
    //                   padding: 0,
    //                   cursor: "pointer",
    //                 }}
    //                 title="Remove image"
    //                 onClick={() => handleRemoverImage(field.name, section.title)}
    //               >
    //                 <img
    //                   src={closeIcon}
    //                   alt="remove btn"
    //                   style={{
    //                     width: "1.5rem",
    //                     height: "1.5rem",
    //                     background: "#EFC3CA",
    //                     borderRadius: "9999px",
    //                     padding: 0,
    //                     margin: 0,
    //                   }}
    //                 />
    //               </div>
    //               <img
    //                 src={urlPreview}
    //                 alt="preview image"
    //                 style={{
    //                   borderRadius: ".4rem",
    //                   aspectRatio: "1/1",
    //                   width: "9rem",
    //                   height: "9rem",
    //                   border: "1px solid black",
    //                 }}
    //               />
    //             </div>
    //           </div>
    //         );
    //       };

    //       const PreviewAudio = memo(({ valueField }) => {
    //         const hasError = !!currentErrors[field.name];
    //         const audioMarkUrl = window.URL.createObjectURL(valueField);
    //         return (
    //           <>
    //             <div className="input-file" style={{ paddingBlock: ".25rem" }}>
    //               <div>
    //                 <InputLabel>Sound of Mark</InputLabel>
    //               </div>
    //               <Grid container spacing={1}>
    //                 <Grid item xs={10}>
    //                   <audio
    //                     src={audioMarkUrl}
    //                     controls
    //                     style={{ width: "100%" }}
    //                   ></audio>
    //                 </Grid>
    //                 <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
    //                   <ButtonRemove field={field} section={section} />
    //                 </Grid>
    //               </Grid>
    //               <FormHelperText>Max size: 5 MB. Format file: MP3.</FormHelperText>
    //               {hasError && (
    //                 <FormHelperText error>{currentErrors[field.name]}</FormHelperText>
    //               )}
    //             </div>
    //           </>
    //         );
    //       });

    //       if (!isNaN(index)) {
    //         currentErrors = currentErrors[index];
    //       }
    //       console.log({ currentErrors, currentFormValues });
    //       switch (field.type) {
    //         case INPUT_TYPE.TEXT:
    //         case INPUT_TYPE.NUMBER:
    //         case INPUT_TYPE.PHONE_NUMBER:
    //         case INPUT_TYPE.POINT:
    //         case INPUT_TYPE.CURRENCY:
    //           if (field.multiple) {
    //             // console.log({
    //             //   formValues: formValues[section.title][field.name],
    //             //   error: currentErrors[field.name],
    //             //   field: field.name,
    //             // });
    //             let localFormValues;
    //             if (sectionFormValues) {
    //               localFormValues = sectionFormValues;
    //             } else {
    //               localFormValues = formValues[section.title][field.name];
    //             }

    //             fieldComponent = (
    //               <>
    //                 {localFormValues.map((_, indexValue) => {
    //                   return (
    //                     <Grid container spacing={0.5} key={indexValue}>
    //                       <Grid item xs={11}>
    //                         <TextInput
    //                           key={field.name}
    //                           field={field}
    //                           formValues={{
    //                             [field.name]: currentFormValues[field.name][indexValue],
    //                           }}
    //                           errors={{
    //                             [field.name]: currentErrors[field.name][indexValue],
    //                           }}
    //                           handleInputChange={(e) =>
    //                             handleInputChange(
    //                               e,
    //                               indexValue,
    //                               section.title,
    //                               field,
    //                               parent
    //                             )
    //                           }
    //                           handleInputBlur={(e) =>
    //                             handleInputBlur(
    //                               field.name,
    //                               section.title,
    //                               indexValue,
    //                               e,
    //                               parent
    //                             )
    //                           }
    //                         />
    //                       </Grid>
    //                       <Grid
    //                         item
    //                         xs={1}
    //                         sx={{
    //                           display: "flex",
    //                           alignItems: "center",
    //                         }}
    //                       >
    //                         <div style={{ marginTop: "1rem" }}>
    //                           <ButtonRemove
    //                             field={field}
    //                             index={indexValue}
    //                             section={section}
    //                           />
    //                         </div>
    //                       </Grid>
    //                     </Grid>
    //                   );
    //                 })}
    //                 <Grid>
    //                   <ButtonAdd field={field} section={section} />
    //                 </Grid>
    //               </>
    //             );
    //           } else {
    //             let localErrors = currentErrors;
    //             if (parent) {
    //               index = indexValueParent;
    //               // console.log("has Parrent", {
    //               //   currentFormValues: currentFormValues[field.name],
    //               //   currentErrors,
    //               // });
    //               localErrors = {
    //                 [field.name]: currentErrors[parent.name][index][field.name],
    //               };
    //             }
    //             fieldComponent = (
    //               <TextInput
    //                 key={field.name}
    //                 field={field}
    //                 formValues={currentFormValues}
    //                 errors={localErrors}
    //                 handleInputChange={(e) =>
    //                   handleInputChange(e, index, section.title, field, parent)
    //                 }
    //                 handleInputBlur={(e) =>
    //                   handleInputBlur(field.name, section.title, index, e, parent)
    //                 }
    //               />
    //             );
    //           }
    //           break;
    //         case INPUT_TYPE.TEXTAREA:
    //           let localFormValues = currentFormValues,
    //             localErorrs = currentErrors;
    //           if (parent) {
    //             localFormValues = currentFormValues[parent.name][indexValueParent];
    //             localErorrs = currentErrors[parent.name][indexValueParent];
    //           }
    //           // console.log({ field, localFormValues, localErorrs, parent, indexValueParent });
    //           fieldComponent = (
    //             <TextArea
    //               key={field.name}
    //               field={field}
    //               formValues={{ [field.name]: localFormValues[field.name] }}
    //               errors={{ [field.name]: localErorrs[field.name] }}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) =>
    //                 handleInputBlur(field.name, section.title, index, e, parent)
    //               }
    //             />
    //           );
    //           break;
    //         case INPUT_TYPE.SELECT_ONE:
    //           if (field.multiple) {
    //             const localFormValues = formValues[section.title][field.name];
    //             fieldComponent = (
    //               <>
    //                 {localFormValues.map((_, indexValue) => {
    //                   return (
    //                     <Grid container spacing={1}>
    //                       <Grid item xs={11}>
    //                         <SelectOne
    //                           key={field.name}
    //                           field={field}
    //                           formValues={currentFormValues[indexValue]}
    //                           errors={currentErrors}
    //                           handleInputChange={(e) =>
    //                             handleInputChange(
    //                               e,
    //                               indexValue,
    //                               section.title,
    //                               field,
    //                               parent
    //                             )
    //                           }
    //                           handleInputBlur={(e) =>
    //                             handleInputBlur(
    //                               field.name,
    //                               section.title,
    //                               indexValue,
    //                               e,
    //                               parent
    //                             )
    //                           }
    //                           fieldOptions={fieldOptions}
    //                         />
    //                       </Grid>
    //                       <Grid
    //                         item
    //                         xs={1}
    //                         sx={{ display: "flex", alignItems: "center" }}
    //                       >
    //                         <div style={{ marginTop: "1rem" }}>
    //                           <ButtonRemove
    //                             index={indexValue}
    //                             field={field}
    //                             section={section}
    //                           />
    //                         </div>
    //                       </Grid>
    //                     </Grid>
    //                   );
    //                 })}
    //                 <Grid>
    //                   <ButtonAdd section={section} field={field} />
    //                 </Grid>
    //               </>
    //             );
    //           } else {
    //             fieldComponent = (
    //               <SelectOne
    //                 key={field.name}
    //                 field={field}
    //                 formValues={currentFormValues}
    //                 errors={currentErrors}
    //                 handleInputChange={(e) =>
    //                   handleInputChange(e, index, section.title, field, parent)
    //                 }
    //                 handleInputBlur={(e) =>
    //                   handleInputBlur(field.name, section.title, index, e, parent)
    //                 }
    //                 fieldOptions={fieldOptions}
    //               />
    //             );
    //           }
    //           break;
    //         case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
    //           if (field.multiple) {
    //             const localFormValues = formValues[section.title][field.name];
    //             const localErrors = currentErrors[field.name];
    //             // console.log({ localFormValues, localErrors });
    //             fieldComponent = (
    //               <>
    //                 {localFormValues.map((_, indexValue) => {
    //                   return (
    //                     <Grid container spacing={1} key={indexValue}>
    //                       <Grid item xs={11}>
    //                         <SelectOneAutocomplete
    //                           key={field.name}
    //                           field={field}
    //                           formValues={{ [field.name]: localFormValues[indexValue] }}
    //                           errors={{ [field.name]: localErrors[indexValue] }}
    //                           handleInputChange={(e) =>
    //                             handleInputChange(
    //                               e,
    //                               indexValue,
    //                               section.title,
    //                               field,
    //                               parent
    //                             )
    //                           }
    //                           handleInputBlur={(e) => {
    //                             handleInputBlur(
    //                               field.name,
    //                               section.title,
    //                               indexValue,
    //                               e,
    //                               parent
    //                             );
    //                           }}
    //                           fieldOptions={fieldOptions}
    //                         />
    //                       </Grid>
    //                       <Grid
    //                         item
    //                         xs={1}
    //                         sx={{ display: "flex", alignItems: "center" }}
    //                       >
    //                         <div style={{ marginTop: "1rem" }}>
    //                           <ButtonRemove
    //                             field={field}
    //                             index={indexValue}
    //                             section={section}
    //                           />
    //                         </div>
    //                       </Grid>
    //                     </Grid>
    //                   );
    //                 })}
    //                 <Grid>
    //                   <ButtonAdd section={section} field={field} />
    //                 </Grid>
    //               </>
    //             );
    //           } else {
    //             // console.log({ currentErrors });
    //             fieldComponent = (
    //               <SelectOneAutocomplete
    //                 key={field.name}
    //                 field={field}
    //                 formValues={currentFormValues}
    //                 errors={currentErrors}
    //                 handleInputChange={(e) =>
    //                   handleInputChange(e, index, section.title, field, parent)
    //                 }
    //                 handleInputBlur={(e) => {
    //                   handleInputBlur(field.name, section.title, index, e, parent);
    //                 }}
    //                 fieldOptions={fieldOptions}
    //               />
    //             );
    //           }
    //           break;
    //         case INPUT_TYPE.SELECT_MULTIPLE:
    //           fieldComponent = (
    //             <SelectMultiple
    //               key={field.name}
    //               field={field}
    //               formValues={currentFormValues}
    //               errors={currentErrors}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) =>
    //                 handleInputBlur(field.name, section.title, index, e, parent)
    //               }
    //               fieldOptions={fieldOptions}
    //             />
    //           );
    //           break;
    //         case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
    //           fieldComponent = (
    //             <SelectMultipleAutocomplete
    //               key={field.name}
    //               field={field}
    //               formValues={currentFormValues}
    //               errors={currentErrors}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) =>
    //                 handleInputBlur(field.name, section.title, index, e, parent)
    //               }
    //               fieldOptions={fieldOptions}
    //             />
    //           );
    //           break;
    //         case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
    //           fieldComponent = (
    //             <SelectOneCountryAutocomplete
    //               key={field.name}
    //               field={field}
    //               formValues={currentFormValues}
    //               errors={currentErrors}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) => {
    //                 handleInputBlur(field.name, section.title, index, e, parent);
    //               }}
    //               fieldOptions={fieldOptions}
    //             />
    //           );
    //           break;
    //         case INPUT_TYPE.CHECKBOX:
    //           fieldComponent = (
    //             <CheckboxGroupInput
    //               key={field.name}
    //               field={field}
    //               formValues={currentFormValues}
    //               errors={currentErrors}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) =>
    //                 handleInputBlur(field.name, section.title, index, e, parent)
    //               }
    //               fieldOptions={
    //                 fieldOptions.length
    //                   ? fieldOptions.filter((option) => option !== false)
    //                   : field.options
    //               }
    //             />
    //           );
    //           break;
    //         case INPUT_TYPE.RADIO:
    //           fieldComponent = (
    //             <RadioGroupInput
    //               key={field.name}
    //               field={field}
    //               formValues={currentFormValues}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) =>
    //                 handleInputBlur(field.name, section.title, index, e, parent)
    //               }
    //               errors={currentErrors}
    //               fieldOptions={
    //                 fieldOptions.length
    //                   ? fieldOptions.filter((option) => option !== false)
    //                   : field.options
    //               }
    //             />
    //           );
    //           break;
    //         case INPUT_TYPE.DATE:
    //           fieldComponent = (
    //             <DatePickerInput
    //               key={field.name}
    //               field={field}
    //               formValues={currentFormValues}
    //               errors={currentErrors}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field, parent)
    //               }
    //               handleInputBlur={(e) =>
    //                 handleInputBlur(field.name, section.title, index, e, parent)
    //               }
    //             />
    //           );
    //           break;
    //         case ANOTHER_INPUT_TYPE.FILE_WITH_PREVIEW:
    //           fieldComponent = (
    //             <FilePicker
    //               errors={currentErrors}
    //               field={field}
    //               formValues={currentFormValues[field.name]}
    //               handleInputChange={(e) =>
    //                 handleInputChange(e, index, section.title, field)
    //               }
    //               indexFile={index}
    //               onFileSelect={(e) => handleFileSelect(e, field.name, section.title)}
    //               tip={"Max size: 5 MB. Format file: PDF, JPG, PNG."}
    //             />
    //           );
    //           break;
    //         case ANOTHER_INPUT_TYPE.EMPTY_FIELD:
    //           fieldComponent = <HiddenComponent />;
    //           break;
    //         // Add cases for other field types
    //         default:
    //           fieldComponent = null;
    //       }
    //     }

    //     // const parentStyle = {
    //     //   marginTop: ".2rem",
    //     // };
    //     // let defaultStyle = { paddingTop: "unset !important" };

    //     // if (hasChildren) {
    //     //   defaultStyle = { ...parentStyle };
    //     // }
    //     // if (field.multiple && hasChildren) {
    //     //   return (
    //     //     <>
    //     //       <Grid
    //     //         item
    //     //         container={hasChildren}
    //     //         spacing={hasChildren ? 1 : 0}
    //     //         xs={field.gridWidth || 11}
    //     //         key={field.name}
    //     //         sx={defaultStyle}
    //     //       >
    //     //         {fieldComponent}
    //     //       </Grid>
    //     //       <Grid item xs={12}>
    //     //         <ButtonAdd field={field} section={section} />
    //     //       </Grid>
    //     //     </>
    //     //   );
    //     // }
    //     return (
    //       <>
    //         <Grid
    //           item
    //           key={`${field.name}-${index}`}
    //           xs={field.gridWidth || 12}
    //           sx={{ paddingTop: "unset !important" }}
    //         >
    //           {fieldComponent}
    //         </Grid>
    //       </>
    //     );
    //   });
    // };
    return (
      <>
        {/* {renderComponentField(section.children)} */}
        {section.children?.map((field, indexField) => {
          // Check if children array exists;

          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];

          let fieldComponent = null;
          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              const localError = currentErrors[index];
              // console.log({ currentErrors,localError });
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={localError}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              const localErrors = currentErrors[index];
              // console.log(localErrors);
              fieldComponent = (
                <CustomTextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={localErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, index, e);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section, indexSection) => {
    if (section.multiple) {
      // Render Family or Emergency Contact section
      return (
        <>
          <Grid item xs={12} key={section.title}>
            <Box sx={{ marginBottom: 2 }}>
              {/* Title */}
              <Typography variant="h6" sx={{ fontWeight: 800 }}>
                {section?.title}
              </Typography>
              {/* Subtitle */}
              {section.subtitle && (
                <Typography variant="subtitle2">{section?.subtitle}</Typography>
              )}
            </Box>
            <Grid container spacing={1}>
              {formValues[section.title].map((sectionValue, indexValue, arrayValue) => {
                // console.log({ sectionValue });
                return (
                  <>
                    <Grid
                      container
                      key={indexValue}
                      spacing={1}
                      item
                      xs={12}
                      sx={{ marginBlock: ".4rem" }}
                    >
                      <Grid
                        item
                        xs={11}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "12px",
                          paddingInline: ".5rem",
                        }}
                        container
                        spacing={1}
                      >
                        {renderFields(section, indexValue, null, sectionValue)}
                      </Grid>
                      <Grid item xs={1}>
                        <ButtonRemove
                          field={null}
                          index={indexValue}
                          section={section}
                          disabled={arrayValue.length < 2}
                          key={"remove buttoon"}
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={11}>
                <ButtonAdd section={section} />
              </Grid>
              {/* {renderFields(section)} */}
            </Grid>
          </Grid>
        </>
      );
    } else {
      //* Render other sections using renderFields function
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section?.title}
            </Typography>
            {/* Subtitle */}
            {section.subtitle && (
              <Typography variant="subtitle2">{section?.subtitle}</Typography>
            )}
          </Box>
          <Grid container spacing={1}>
            {renderFields(section)}
          </Grid>
        </Grid>
      );
    }
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {fields2.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return (
    <>
      <div>{renderFormContent()}</div>
    </>
  );
};

export default StepThreeRevisi;


const ErrorIcon = () => <ErrorOutlineIcon style={{ color: "red", marginRight: "8px" }} />;

/**
 * Custom textarea can auto rezise height
 */
const CustomTextArea = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  placeholder,
  showError = true,
  tip,
}) => {
  const hasError = !!errors[field.name];
  const isShowError = !!showError;

  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <CustomStyledTextArea
        minRows={field?.rowsMin || 3} // set the minimum rows as base heign
        placeholder={field.placeholder ? field.placeholder : `Input ${field.label}`}
        value={formValues[field.name]}
        name={field.name}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        error={hasError}
        {...(field.additionalProps || {})}
        sx={{
          width: `calc(100% - 18px)`,
          ...formGroup,
        }}
        InputProps={{
          startAdornment: field?.startIcon ? (
            <InputAdornment position="start">{field.startIcon}</InputAdornment>
          ) : null,
          endAdornment: hasError ? <ErrorIcon /> : null,
        }}
        id={field.name}
      />
      {tip && !hasError && <FormHelperText>{tip}</FormHelperText>}
      {hasError && isShowError && (
        <FormHelperText error>{errors[field.name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};