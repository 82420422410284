import React, { useState, useEffect, useRef, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
} from "@mui/material";
import { Stepper, Step, StepLabel, Container, Paper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../../assets/search.svg";
import CalendarIcon from "./../../../../assets/calendar.png";
import SearchIconBlack from "./../../../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../../../assets/back.svg";
import { AccessRestrictedAlert } from "../../../../components/Alert";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
  justifyContentEnd,
} from "../../../../util/style";
import axios from "../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../../../components/Form";
// import {
//   addApplication,
//   addApplicationSaveAsDraft,
//   deleteApplication,
//   editApplication,
//   editApplicationSaveAsDraft,
//   getApplication,
//   getApplicationDetails,
// } from "./../clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../util/function";
import DataTable from "../../../../components/Table";
import ConfirmDelete from "../../../../components/ConfirmDelete";
import ModalConfirmSaveAsDraft from "../../../../components/ConfirmSaveAsDraft";
import ModalConfirmCancel from "../../../../components/ConfirmCancel";
import { modalActionButton } from "../../../../util/style";
import { useNavigate, useParams } from "react-router-dom";

import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepTwoRevisi from "./StepTwoRevisi";
import StepThreeRevisi from "./StepThreeRevisi";
import StepFourRevisi from "./StepFourRevisi";
import StepFiveRevisi from "./StepFiveRevisi";
// import Step1 from "./step1";

// import Step2_Halal from "./step2/step2_Halal";
// import Step2_BPOM_CPPOB from "./step2/step2_BPOM_CPPOB";
// import Step2_BPOM_CPKB from "./step2/step2_BPOM_CPKB";
// import Step2_BPOM_MD from "./step2/step2_BPOM_MD";
// import Step2_BPOM_ML from "./step2/step2_BPOM_ML";

// import Step3_Halal from "./step3/step3_Halal";
// import Step3_BPOM_CPPOB from "./step3/step3_BPOM_CPPOB_";
// import Step3_BPOM_CPKB from "./step3/step3_BPOM_CPKB";
// import Step3_BPOM_MD from "./step3/step3_BPOM_MD";
// import Step3_BPOM_ML from "./step3/step3_BPOM_ML";

// import Step4_Halal from "./step4/step4_Halal";
// import Step4_BPOM_CPPOB from "./step4/step4_BPOM_CPPOB";
// import Step4_BPOM_CPKB from "./step4/step4_BPOM_CPKB";
// import Step4_BPOM_MD from "./step4/step4_BPOM_MD";
// import Step4_BPOM_ML from "./step4/step4_BPOM_ML";

// import Step5_Halal from "./step5/step5_Halal";
// import Step5_BPOM_CPPOB from "./step5/step5_BPOM_CPPOB";
// import Step5_BPOM_CPKB from "./step5/step5_BPOM_CPKB";
// import Step5_BPOM_MD from "./step5/step5_BPOM_MD";
// import Step5_BPOM_ML from "./step5/step5_BPOM_ML";

// import Step4 from "./step4";
// import Step5 from "./step5";

// import { RequestApplicationDetailLoading } from "../clients/actions";

export const FormDataContext = createContext({});

const steps = ["Applicant", "Description", "Class", "Presentative", "Attachment"];

const STEP_NAME = Object.freeze({
  STEP_ONE: "StepOne",
  STEP_TWO: "StepTwo",
  STEP_THREE: "StepThree",
  STEP_FOUR: "StepFour",
  STEP_FIVE: "StepFive",
  // STEP_SIX: "StepSix",
  /**
   *
   * @returns {Array}
   */
  toArray: () => {
    return Object.values(STEP_NAME).filter((val) => typeof val !== "function");
  },
});

const Wizard = () => {
  const [stepLoading, setStepLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [saveAsDraftModal, setSaveAsDraftModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNextTriggered, setIsNextTriggered] = useState({ isRun: "NOT" });
  const [currentPath, setCurrentPath] = useState("");
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  // // Create an array of data (e.g., binary or text data)
  // const dataArray = ["Hello, World!"];
  // // Create a Blob object from the array of data
  // const blob = new Blob(dataArray, { type: "application/pdf" });
  // // Create a File object with the Blob and a filename
  // const file = new File([blob], "example.pdf", { type: "application/pdf" });

  // console.log(file);

  {
    /*
          category_id =
          1 Halal
          2 BPOM
          type_id of BPOM =
          1. CPPOB
          2. CPKB
          3. MD
          4. ML
          */
  }

  const [formData, setFormData] = useState({
    // StepOne: {
    //   "Applicant Information": {
    //     owner_type: "company",
    //   },
    // },
    // StepTwo:{},
    // StepThree: {},
    // StepFive:{}
    // StepFour: {},
    /*
                    "StepOne": {
            "Applicant Information": {
                "id": 9,
                "client_id": 1,
                "applicant_id": 1,
                "category_id": 101,
                "type_id": 101,
                "email": "muhamadsobari198@gmail.com",
                "mobile_phone": "083456789012",
                "business_field_id": 2,
                "brand_id": 5,
                "company_name": "PT. Pacific Patent Multiglobal",
                "submission_on_behalf_of": "Company"
            }
        },

        "StepTwo": {
            "Business Description": {
                "id": 8,
                "business_activity_id": 2,
                "product_origin": "Import",
                "product_marketing_id": 2
            }
        },
        "StepThree": {
            "Office Address": {
                "id": 7,
                "province_id": 12,
                "district_id": 1101021,
                "village_id": 1101010004,
                "regency_id": 1103,
                "address": "Address",
                "postal_code": "101"
            },
            "Facility Address": {
                "id": 7,
                "province_id": 17,
                "district_id": 1101031,
                "village_id": 1101010010,
                "regency_id": 1107,
                "address": "Address2",
                "postal_code": "103",
                "phone": "02812381238123",
                "status": "Rent",
                "number_of_employees": null
            }
        },
        "StepFour": {
            "Product Information": [
                {
                    "id": 7,
                    "product_name": "energen",
                    "brand_name": "wiw",
                    "primary_packaging": "bottle",
                    "secondary_packaging": "box",
                    "net_weight": 50,
                    "net_weight_type": "mg",
                    "monthly_production_capacity": 5000
                }
            ]
        },
        "StepFive": {
               "KTP Pemohon": {
                attachments: [
                  {
                    id: 2,
                    filename: "1706839979_Events.png",
                    size: 203956,
                    url: "https://storage.googleapis.com/ppm_storage/application_attachments/1706839979_Events.png"
                  }
                ]
              },
              "KTP Penyelia Halal": {
                attachments: [
                  {
                    id: 4,
                    filename: "ktp_penyedia_halal.jpg",
                    size: 158932,
                    url: "https://example.com/ktp_penyedia_halal.jpg"
                  }
                ]
              },
              "NPWP": {
                attachments: [
                  {
                    id: 6,
                    filename: "npwp_document.pdf",
                    size: 305678,
                    url: "https://example.com/npwp_document.pdf"
                  }
                ]
              },
              "Nomor Induk Berusaha (NIB)": {
                attachments: [
                  {
                    id: 8,
                    filename: "nib_certificate.pdf",
                    size: 408765,
                    url: "https://example.com/nib_certificate.pdf"
                  }
                ]
              },
              "Akte Perusahaan": {
                attachments: [
                  {
                    id: 10,
                    filename: "company_deed.pdf",
                    size: 567890,
                    url: "https://example.com/company_deed.pdf"
                  }
                ]
              },
              "Sertifikat Pelatihan Eksternal Halal": {
                attachments: [
                  {
                    id: 12,
                    filename: "halal_training_certificate.jpg",
                    size: 234567,
                    url: "https://example.com/halal_training_certificate.jpg"
                  }
                ]
              }
        }
        */
    //             response.data.data
    /*

    "StepOne": {
      "Applicant Information": {
        "client_id": 1,
        "applicant_id": 1,
        "category_id": 101,//2,
//         "type_id": 101,// 1,
        "email": "muhamadsobari198@gmail.com",
        "mobile_phone": "083456789012",
        "business_field_id": 2,
        "brand_id": 5,
        "company_name": "PT. Pacific Patent Multiglobal",
        "submission_on_behalf_of": "Personal"
      },
    },

    "StepTwo": {
      "Business Description": {
        "business_activity_id":1,
        "product_origin": "Local",
        "organization_id": 2,
        "product_marketing_id": 1,
      },
    },
    "StepThree": {
      "Office Address": {
        "province_id": 1,
        "district_id": 1,
        "sub_district_id": 2,
        "subdivision_id": 1,
        "address": "E.g.: Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
        "postal_code": "16680",
      },
      "Facility Address": {
        "province_id": 1,
        "district_id": 1,
        "sub_district_id": 2,
        "subdivision_id": 1,
        "address": "E.g.: Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
        "postal_code": "16680",
        "phone": "0218123817",
        "status": "Owned",
        "number_of_employees":"21312313"
      }
    },
    "StepFour": {
      "Product Information": [
      {
        "product_name": "Product 1",
        "brand_name": "Brand ABC",
        "primary_packaging": "Plastic Bottle",
        "secondary_packaging": "Cardboard Box",
        "net_weight": "2kg",
        "monthly_production_capacity":5000
      }
      ],
    },
    "StepFive": {
        "KTP Pemohon": { "attachments": [] },
        "KTP Penyelia Halal": { "attachments": [] },
        "NPWP": { "attachments": [] },
        "Nomor Induk Berusaha (NIB)": { "attachments": [] },
        "Nomor Induk Berusaha (NIB)": { "attachments": [] },
        "Akte Perusahaan":{ "attachments": [] },
        "Sertifikat Pelatihan Eksternal Halal":{ "attachments": [] },
        "KTP Pimpinan":{ "attachments": [] },
    },
  }
    */
  });

  const dispatch = useDispatch();

  const { data: rows, message, isLoading } = useSelector((state) => state.applications);

  //   const {
  //     data: applicationsdetail,
  //     //     isLoading: isLoadingDetail,
  //   } = useSelector((state) => state.applicationsdetail);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    if (isActive === true) {
      setSaveAsDraftModal(false);
      navigate(-1);
    }
  }, [isActive]);

  const updateWizardData = (stepName, stepData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [stepName]: stepData,
    }));
  };

  const { id } = useParams();

  const fetchDataDetailForEdit = async () => {
    try {
      console.trace("function get data");
      setIsFetchLoading(true);
      //TODO: URL not yet set
      const response = await axios.get(`/trademark-registrations/${id}/detail-for-edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response, "okelah");
      setFormData(response.data.data);
      setIsFetchLoading(false);
    } catch (error) {
      //TODO: show alert modal and redirec back to trademark application
      //       alert('Data tidak ada');
      // navigate("/operation-management/business-permit/application");
    }
  };

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
    if (path.includes("edit") && id) {
      fetchDataDetailForEdit();
    }
    // console.log(path.includes("edit"), id);
  }, []);

  // -------------------------------- [ EDIT SECTION ] ------------------------- //
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const path = window.location.pathname;
  //       try {
  //         if (applicationsdetail) {
  //           if (path.includes("resubmit") && applicationsdetail.canResubmit === false) {
  //             return navigate("/operation-management/business-permit/application");
  //           } else {
  //             if (id) {
  //               const response = await axios.get(`/applications/${id}/detail-for-edit`, {
  //                 headers: {
  //                   Authorization: `Bearer ${token}`,
  //                 },
  //               });
  //               console.log(response, "okelah");
  //               setFormData(response.data.data);
  //               setIsFetchLoading(false);
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         //       alert('Data tidak ada');
  //         navigate("/operation-management/business-permit/application");
  //       }
  //     };

  //     fetchData();
  //   }, [applicationsdetail]);

  //* ======================================================================= */
  //*                                  HANDLER                                */
  //* ======================================================================= */

  const manipulatePayload = (initialPayload = formData) => {
    const stepName = STEP_NAME.toArray();
    const result = {
      Other: {},
    };
    for (let i = 0; i < stepName.length; i++) {
      const step = stepName[i];
      const isLastIndex = i === stepName.length - 1;
      if (typeof initialPayload[step] === "object") {
        Object.entries(initialPayload[step]).forEach(([key, value]) => {
          if (isLastIndex) {
            // console.log("Last index", { key, value });
            result["Other"][key] = value;
          } else {
            result[key] = value;
          }
        });
      } else {
        // console.log("Bukan object", initialPayload[step]);
      }
    }
    // console.log("Done",result);
    return result;
  };

  const handleCancel = () => {
    setConfirmModal(true);
  };

  const handleSaveAsDraft = () => {
    //TODO: save draft
    setSaveAsDraftModal(true);
  };

  const handleNext = () => {
    // Trigger validation for the current step

    // setIsNextTriggered({isRun:"NOT"});

    // Check if the current step is valid based on isFormValid
    // if (isFormValid) {
    // setIsNextTriggered(true);
    // console.log("Next");
    setIsNextTriggered({ isRun: "YES" });
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
    if (isFormValid && activeStep === steps.length - 1) {
      // alert("Baru sumbit");
      handleSubmit()
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsNextTriggered({ isRun: "NOT" });
    // setStepLoading(false);
  };

  const handleSubmit = () => {
    if (activeStep < steps.length - 1) {
      handleNext();
      // setStepLoading(true);
      console.log("Next");
      //TODO: Dispatch
    } else if (id) {
      //TODO: For edit
      console.log("Submit edit");
      // dispatch(editApplication(token, formData, id));
    } else {
      //TODO: For add

      // dispatch(addApplication(token, formData));
      alert("Done");
      console.log("Add", { formData });
      // const manipulated = manipulatePayload();
      // manipulated["status"] = "Pending";
      // console.log(manipulated, "Payload add");
    }
  };

  const formAttempted = (/** @type {Boolean} */ val) => {
    setIsNextTriggered({ isRun: "NOT" });
    setIsFormValid(val);
    if (val) {
      if (activeStep === steps.length - 1) {
        return; // If in the last step, return
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    if (isFormValid) {
      //   dispatch(addApplication(token, formData));
    } else {
      setIsNextTriggered({ isRun: "NOT" });
    }
  }, [isFormValid]);

  useEffect(() => {
    console.log({
      formData,
      activeStep,
      stepLen: steps.length,
      // isTrue: formData[STEP_NAME.STEP_FOUR],
    });
    const arrStepName = STEP_NAME.toArray();
    // console.log(arrStepName);
    // console.log("Current Step data", formData[arrStepName[activeStep]]);
    // && formData[STEP_NAME.STEP_FOUR]
    // activeStep === 4

    if (activeStep >= 4 && formData.hasOwnProperty("StepFive")) {
      //       handleSubmit();
    }
    if (activeStep > steps.length - 1) {
      //       setActiveStep(4);
    }
  }, [formData, activeStep]);

  // --------------------------- [ HANDLE MODAL ] ----------------------------------

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseSaveAsDraftModal = () => {
    setSaveAsDraftModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    navigate("/operation-management/domestic-trademark/trademark-applications");
  };

  const handleOnConfirmSaveAsDraftModal = () => {
    setSaveAsDraftModal(false);
    //TODO: Dispatch
    if (id) {
      // dispatch(editApplicationSaveAsDraft(token, formData, id));
    } else {
      // dispatch(addApplicationSaveAsDraft(token, formData));
    }
  };

  //* ======================================================================= */
  //*                                  COMPONENT                              */
  //* ======================================================================= */

  const renderFormByStep = () => {
    // console.log(activeStep);
    if (activeStep == 0) {
      return (
        <StepOne
          formAttempted={formAttempted}
          formData={formData[STEP_NAME.STEP_ONE]}
          isNextTriggered={isNextTriggered}
          updateWizardData={updateWizardData}
        />
      );
    } else if (activeStep === 1 && formData[STEP_NAME.STEP_ONE]) {
      return (
        <StepTwoRevisi
          formAttempted={formAttempted}
          formData={formData[STEP_NAME.STEP_TWO]}
          isNextTriggered={isNextTriggered}
          updateWizardData={updateWizardData}
        />
      );
    } else if (activeStep === 2 && formData[STEP_NAME.STEP_TWO]) {
      return (
        <StepThreeRevisi
          formAttempted={formAttempted}
          formData={formData[STEP_NAME.STEP_THREE]}
          isNextTriggered={isNextTriggered}
          updateWizardData={updateWizardData}
        />
      );
    } else if (activeStep === 3 && formData[STEP_NAME.STEP_THREE]) {
      return (
        <StepFourRevisi
          formAttempted={formAttempted}
          formData={formData[STEP_NAME.STEP_FOUR]}
          isNextTriggered={isNextTriggered}
          updateWizardData={updateWizardData}
        />
      );
    } else if (activeStep === 4 && formData[STEP_NAME.STEP_FOUR]) {
      return (
        <StepFiveRevisi
          formAttempted={formAttempted}
          formData={formData[STEP_NAME.STEP_FIVE]}
          isNextTriggered={isNextTriggered}
          updateWizardData={updateWizardData}
        />
      );
    }
    // else if (activeStep === 5 && formData["StepFive"]) {
    //   return (
    //     <StepSix
    //       formAttempted={formAttempted}
    //       formData={formData[STEP_NAME.STEP_SIX]}
    //       isNextTriggered={isNextTriggered}
    //       updateWizardData={updateWizardData}
    //     />
    //   );
    // }
    return null;
  };

  const RenderActionButton = () => {
    const parentStyle = {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      bottom: 0,
      width: "100%",
      left: 0,
      padding: ".5rem 1.7rem 1.5rem ",
      background: "white",
      boxSizing: "border-box",
      alignItems: "center",
    };

    return (
      <div style={parentStyle}>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Previous
        </Button>
        <div>
          <Button variant="outlined" sx={{ ...btnWhite }} onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ ...btnWhite }}
            onClick={() => handleSaveAsDraft()}
          >
            Save as Draft
          </Button>
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              disableElevation
            >
              Next
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              // onClick={() => handleSubmit()}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    );
  };

  const LoadingComponent = () => (
    <div
      style={{
        minHeight: "45vh",
        display: "flex",
        alignItems: " center",
        justifyContent: "center",
      }}
    >
      <FacebookCircularProgress />
    </div>
  );

  useEffect(() => {
    console.log({ isFetchLoading, isLoading });
  }, [isFetchLoading, isLoading]);

  const valueContextProvider = {
    value: {
      allStepFormData: formData,
      isNextTriggered,
      formData: formData[Object.values(STEP_NAME)[activeStep]],
      stepName: STEP_NAME,
    },
    setter: {
      formAttempted,
      updateWizardData,
    },
  };

  return (
    <Paper elevation={0} sx={{ paddingTop: 2 }}>
      <Box>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginTop: -1, marginInline: ".5rem" }}
        >
          {steps.map((label) => (
            <Step key={label} sx={{ marginInline: ".4rem" }}>
              <StepLabel StepIconProps={{ style: { fontSize: "30px" } }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <br />

      <div>
        {isLoading || isFetchLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <FormDataContext.Provider value={valueContextProvider}>
              <div>{renderFormByStep()}</div>
            </FormDataContext.Provider>
          </>
        )}
      </div>

      {/* ====== Bottom action ======  */}
      <RenderActionButton />
      {/* ====== Bottom action ====== */}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
      <ModalConfirmSaveAsDraft
        confirmModal={saveAsDraftModal}
        message={"You want to continue the Trademark application process?"}
        onConfirmModal={handleOnConfirmSaveAsDraftModal}
        onCloseModal={handleOnCloseSaveAsDraftModal}
      ></ModalConfirmSaveAsDraft>
    </Paper>
  );
};

export default Wizard;
