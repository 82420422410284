//@ts-check
import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  ADD_PAYMENT_FAILED,
  ADD_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAILED,
  DELETE_PAYMENT_SUCCESS,
  EDIT_PAYMENT_FAILED,
  EDIT_PAYMENT_SUCCESS,
  GET_DETAIL_PAYMENT_FAILED,
  GET_DETAIL_PAYMENT_SUCCESS,
  GET_PAYMENT_FAILED,
  GET_PAYMENT_SUCCESS,
  PAY_PAYMENT_FAILED,
  PAY_PAYMENT_SUCCESS,
} from "./state";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";
import { AxiosError } from "axios";

/* ======================================================================= */
/*                             HELPER AND TYPE                             */
/* ======================================================================= */
/**@typedef {import("@reduxjs/toolkit").Dispatch} DispatchType */
/**@typedef {'GET'|'ADD'|'UPDATE'|'DELETE'|'DETAIL'|'PAY'} actionType */

/**
 * @typedef {object} returnAction
 * @property {string} type
 * @property {any} payload
 */

/**
 * @typedef {object} paginationResponse
 * @property {Array<object>} data
 * @property {boolean} hasNextPage
 * @property {boolean} hasPrevPage
 * @property {string | number} limit
 * @property {string} message
 * @property {number} page
 * @property {number} pagingCounter
 * @property {null|string} nextPage
 * @property {null|string} prevPage
 * @property {number} totalPages
 * @property {number} total_data
 * @property {number} total_page
 */

/**
 * @param {actionType} actionType
 * @param {DispatchType} dispatch
 */
function runModalByAction(dispatch, actionType) {
  switch (actionType) {
    case "ADD":
      dispatch(RunModal("SAVED", "Payment"));
      break;
    case "UPDATE":
      dispatch(RunModal("EDITED", "Payment"));
      break;
    case "DELETE":
      dispatch(RunModal("DELETED", "Payment"));
      break;
    case "PAY":
      dispatch(RunModal("SAVED", "Paying payment"));
  }
}
/**
 *
 * @param {AxiosError} error
 * @param {DispatchType} dispatch
 * @param {Function} handler
 */
function failHandler(error, dispatch, handler) {
  if (error.response !== undefined && error.response.data !== undefined) {
    if (error.response.status === 401) {
      dispatch(logout());
    } else {
      dispatch(handler(error.response.data.message));
    }
  } else {
    dispatch(handler(error.message));
  }
  console.log(error.message);
}
/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {DispatchType} dispatch
 * @param {Function} successHandler
 * @param {Function} errorHandle
 * @param {actionType} type
 */
function successHandler(response, dispatch, successHandler, errorHandle, type) {
  if (
    response.status === 201 ||
    (response.status === 200 && response.data.status === true)
  ) {
    runModalByAction(dispatch, type);
    if (type === "GET") {
      dispatch(successHandler(response.data.data, response.data));
    } else if (type === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      dispatch(successHandler(response.data.message));
    }
  } else if (
    response.status === 201 ||
    (response.status === 200 && response.data.status === false)
  ) {

    if (type === "PAY") {
      dispatch(RunModal("FAILED", response.data.message));
    }
    
    if (type === "GET") {
      dispatch(successHandler([], null));
    } else {
      dispatch(errorHandle(response.data.message));
    }
  } else {
    dispatch(errorHandle(response.data.message));
  }
}
const paymentPath = "payments";
/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getPayment = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  category = null,
  sortDirection = null,
  sortBy = null,
}) => {
  /**@param {DispatchType} dispatch */
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: paymentPath,
          page,
          limit,
          search,
          status: status === ACTION_TYPE.DEFAULT_STATUS ? null : status,
          categoryTimeOff:
            category == ACTION_TYPE.DEFAULT_TYPE ? null : category,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        successHandler(
          response,
          dispatch,
          getPaymentSuccess,
          getPaymentFail,
          "GET"
        );
      })
      .catch((error) => {
        failHandler(error, dispatch, getPaymentFail);
      });
  };
};

/**
 *
 * @param {Array<any>} data
 * @param {paginationResponse|null} responsePaging
 * @returns
 */
const getPaymentSuccess = (data, responsePaging) => {
  let pagination = null;
  if (responsePaging !== null || responsePaging !== undefined) {
    pagination = {
      page: responsePaging?.page,
      limit: responsePaging?.limit,
      totalData: responsePaging?.total_data,
      totalPage: responsePaging?.total_page,
    };
  }
  return {
    type: GET_PAYMENT_SUCCESS,
    payload: data,
    pagination,
  };
};

/**
 * handle fail when get
 * @param {string} errorMessage
 * @returns {returnAction}
 */
const getPaymentFail = (errorMessage) => {
  return {
    type: GET_PAYMENT_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

//! <================================[ NOT USE ]==============================> */
/**
 *
 * @param {string} token
 * @param {object} formData
 * @returns
 */
export const addPayment = (token, formData) => {
  /**@param {DispatchType} dispatch */
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        paymentPath,
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        successHandler(
          response,
          dispatch,
          addPaymentSuccess,
          addPaymentFail,
          "ADD"
        );
      })
      .catch((error) => {
        failHandler(error, dispatch, addPaymentFail);
      });
  };
};

/**
 *
 * @param {string} message
 * @returns {returnAction}
 */
const addPaymentSuccess = (message) => {
  return {
    type: ADD_PAYMENT_SUCCESS,
    payload: message,
  };
};

/**
 *
 * @param {string} message
 * @returns {returnAction}
 */
const addPaymentFail = (message) => {
  return {
    type: ADD_PAYMENT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

/**
 *
 * @param {string} token
 * @param {object} formData
 * @param {number} id
 * @returns
 */
export const editPayment = (token, formData, id) => {
  /**@param {DispatchType} dispatch */
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        `${paymentPath}/${id}/update`,
        Object.assign({}, formData, {}),
        headersAPIToken(token)
      )
      .then((response) => {
        successHandler(
          response,
          dispatch,
          editPaymentSucess,
          editPaymentFail,
          "UPDATE"
        );
      })
      .catch((error) => {
        failHandler(error, dispatch, editPaymentFail);
      });
  };
};

/**
 *
 * @param {string} message
 * @returns {returnAction}
 */
const editPaymentSucess = (message) => {
  return {
    type: EDIT_PAYMENT_SUCCESS,
    payload: message,
  };
};

/**
 *
 * @param {string} message
 * @returns {returnAction}
 */
const editPaymentFail = (message) => {
  return {
    type: EDIT_PAYMENT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

/**
 *
 * @param {string} token
 * @param {Array.<number>} ids
 * @returns
 */
export const deletePayment = (token, ids) => {
  /**@param {import("@reduxjs/toolkit").Dispatch} dispatch */
  return async (dispatch) => {
    dispatch(RequestLoading());
    let joindedIds = ids.join(",");
    const localUri = paymentPath.concat("/", joindedIds);
    axios
      .delete(localUri, headersAPIToken(token))
      .then((response) => {
        successHandler(
          response,
          dispatch,
          deletePaymentSuccess,
          deletePaymentFailed,
          "DELETE"
        );
      })
      .catch((error) => {
        failHandler(error, dispatch, deletePaymentFailed);
      });
  };
};

/**
 *
 * @param {string} message
 * @returns {returnAction}
 */
const deletePaymentSuccess = (message) => {
  return {
    type: DELETE_PAYMENT_SUCCESS,
    payload: message,
  };
};

/**
 *
 * @param {string} message
 * @returns {returnAction}
 */
const deletePaymentFailed = (message) => {
  return {
    type: DELETE_PAYMENT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* =============================================================== */
/*                               DETAIL                            */
/* =============================================================== */

/**
 *
 * @param {string} token token Auth
 * @param {string} paymentId id for payment document
 * @returns
 */
export const getDetailPayment = (token, paymentId) => {
  /**@param {import("@reduxjs/toolkit").Dispatch} dispatch */
  return async (dispatch) => {
    try {
      dispatch(RequestLoading());
      const response = await axios.get(
        "payments".concat("/", paymentId, "/detail"),
        headersAPIToken(token)
      );
      successHandler(
        response,
        dispatch,
        getDetailPaymentSuccess,
        getDetailPaymentFailed,
        "DETAIL"
      );
    } catch (error) {
      failHandler(error, dispatch, getDetailPaymentFailed);
    }
  };
};

/**
 *
 * @param {object} payload
 * @returns {returnAction}
 */
function getDetailPaymentSuccess(payload) {
  return {
    type: GET_DETAIL_PAYMENT_SUCCESS,
    payload: payload,
  };
}

/**
 *
 * @param {string} message
 * @return {returnAction}
 */
function getDetailPaymentFailed(message) {
  return {
    type: GET_DETAIL_PAYMENT_FAILED,
    payload: message,
  };
}

/**
 *
 * @param {string} token
 * @param {object} payload
 * @param {string|number} id
 * @returns
 */
export function payPayment(token, payload, id) {
  /**@param {import("@reduxjs/toolkit").Dispatch} dispatch*/
  return async (dispatch) => {
    try {
      const response = await axios.post(`/payments/${id}/pay`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data;",
        },
      });
      successHandler(
        response,
        dispatch,
        payPaymentSuccess,
        payPaymentFailed,
        "PAY"
      );
      //* Get Detail payment aftet pay
      //@ts-ignore
      dispatch(getDetailPayment(token, id));
    } catch (error) {
      failHandler(error, dispatch, payPaymentFailed);
    }
  };
}

/**@param {string} payload */
function payPaymentSuccess(payload) {
  return {
    type: PAY_PAYMENT_SUCCESS,
    payload,
  };
}

/**@param {string} payload */
function payPaymentFailed(payload) {
  return {
    type: PAY_PAYMENT_FAILED,
    payload,
  };
}

/* =============================================================== */
/*                              APPROVAL                           */
/* =============================================================== */

/**
 *
 * @param {string} token
 * @param {object} payload
 * @param {string|number} detailId
 * @returns
 */
export function approvePayment(token, payload, detailId) {
  /** @param {import("@reduxjs/toolkit").Dispatch} dispatch */
  return async (dispatch) => {
    try {
      const response = await axios.post(
        paymentPath.concat("/", String(detailId), "/approve"),
        payload,
        headersAPIToken(token)
      );
      if (response.status == 200 && response.data.status === true) {
        // run modal\
        dispatch(
          RunModal(
            payload?.status === "Approved" ? "APPROVED" : "REJECTED",
            "Payment"
          )
        );
        //@ts-ignore
        dispatch(getDetailPayment(token, detailId));
      }
    } catch (error) {
      if (error.response !== undefined && error.response.data !== undefined) {
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          // dispatch(handler(error.response.data.message));
          console.log(error.response?.data?.message);
        }
      } else {
        console.log(error.response?.message);
        // dispatch(handler(error.message));
      }
      console.log(error.message);
    }
  };
}
