import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import DeleteIcon from "../../../../assets/delete-red.png";
// import { FilePicker } from "../../../../components/Form";

const STATUS_RECORD = Object.freeze({
  RECORDING: "Recording",
  INACTIVE: "Inactive",
});

const mimeType = "audio/webm";

const audioField = {
  label: "Pick file",
  name: "audio",
  maximumSizeInMB: 5,
  allowedExtensions: [".mp3"],
};

const AudioRecord = ({ onChange, field, formValues }) => {
  const mediaRecord = useRef(null);
  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState(STATUS_RECORD.INACTIVE);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [errors, setErrors] = useState("");

  // const handleDoneRecord = () => {};

  useEffect(() => {
    if (audioFile !== null) {
      // const file = new File(
      //   [audioFile],
      //   "Recording".concat(new Date().toLocaleDateString()),
      //   { type: mimeType }
      // );
      onChange({ target: { name: field.name, value: audioFile, files: audioFile } });
    }
  }, [audioFile]);

  useEffect(() => {
    console.log(formValues);
    if(formValues){
      setAudioBlob(formValues);
      setAudioFile(formValues);
      const valueUrl = window.URL.createObjectURL(formValues);
      setAudioUrl(valueUrl)
    }
  },[])

  const getAccess = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("Browser does'nt support for media device");
      setErrors("Browser does'nt support for media device");
    }
  };

  const startRecording = async () => {
    try {
      setRecordingStatus(STATUS_RECORD.RECORDING);
      /**Create new media recorder instance using stream */
      const media = new MediaRecorder(stream, { mimeType });
      /** Set media instance to mediaRecorder ref */
      mediaRecord.current = media;
      /** Invoke the start method to recording */
      mediaRecord.current.start();
      let localAudioChunks = [];
      mediaRecord.current.ondataavailable = (event) => {
        if (typeof event === undefined) return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };
      setAudioChunks(localAudioChunks);
    } catch (error) {
      setErrors(error.message);
      console.error(error.message);
    }
  };

  const stopRecording = async () => {
    try {
      setRecordingStatus(STATUS_RECORD.INACTIVE);
      // console.log(AudioFor);
      /** Stop the instance */
      mediaRecord.current.stop();
      mediaRecord.current.onstop = () => {
        /** Create a blob file file from audio chunk data */
        const localAudioBlob = new Blob(audioChunks, { type: mimeType });
        /** Create audio url for preview */
        // const audioContex = new AudioContext(),
        //   fileReader = new FileReader();

        // fileReader.onloadend = () => {
        //   const arrayBuffer = fileReader.result;
        //   audioContex.decodeAudioData(arrayBuffer, (audioBuffer) => {
        //     const mp3Blob = audioBufferToWav(audioBuffer);
        //     setAudioBlob(mp3Blob);
        //     const audioUrl = window.URL.createObjectURL(mp3Blob);
        //     setAudioUrl(audioUrl);
        //   })
        // }
        // fileReader.readAsArrayBuffer(localAudioBlob);

        setAudioBlob(localAudioBlob);
        const file = new File(
          [localAudioBlob],
          "recording-".concat(new Date().toLocaleDateString(), ".weba"),
          { type: mimeType }
        );
        const audioUrl = window.URL.createObjectURL(file);
        setAudioUrl(audioUrl);
        setAudioFile(file);
        setAudioChunks([]);
      };
    } catch (error) {
      setErrors(error.message);
    }
  };

  // /** @param {AudioBuffer} aBuffer */
  // const audioBufferToWav = (aBuffer) => {
  //   let numOfChan = aBuffer.numberOfChannels,
  //     btwLength = aBuffer.length * numOfChan * 2 + 44,
  //     btwArrBuff = new ArrayBuffer(btwLength),
  //     btwView = new DataView(btwArrBuff),
  //     btwChan = [],
  //     btwOffset = 0,
  //     btwPost = 0,
  //     btwIndex,
  //     btwSample;
  //   setUint32(0x46464952); // "RIFF"
  //   setUint32(btwLength - 8); // file length - 8
  //   setUint32(0x45564157); // "WAVE"
  //   setUint32(0x20746d66); // "fmt " chunk
  //   setUint32(16); // length = 16
  //   setUint16(1); // PCM (uncompressed)
  //   setUint16(numOfChan);
  //   setUint32(aBuffer.sampleRate);
  //   setUint32(aBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  //   setUint16(numOfChan * 2); // block-align
  //   setUint16(16); // 16-bit
  //   setUint32(0x61746164); // "data" - chunk
  //   setUint32(btwLength - btwPost - 4); // chunk length
  // };

  // function setUint16(data) {
  //   btwView.setUint16(btwPost, data, true);
  //   btwPost += 2;
  // }

  // function setUint32(data) {
  //   btwView.setUint32(btwPost, data, true);
  //   btwPost += 4;
  // }

  const handleDeleteRecord = () => {
    setAudioBlob(null);
    setAudioUrl(null);
    setRecordingStatus(STATUS_RECORD.INACTIVE);
  };

  /** ============================================================ */
  /**                              COMPOMENT                       */
  /** ============================================================ */

  const RecorderControl = () => (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: ".5rem",
      }}
    >
      <Button
        color="primary"
        variant={recordingStatus === STATUS_RECORD.RECORDING ? "outlined" : "contained"}
        disabled={recordingStatus === STATUS_RECORD.RECORDING}
        onClick={startRecording}
      >
        Start Recording
      </Button>
      <Button
        color="error"
        variant={recordingStatus === STATUS_RECORD.INACTIVE ? "outlined" : "contained"}
        disabled={recordingStatus === STATUS_RECORD.INACTIVE}
        onClick={stopRecording}
      >
        Stop Recording
      </Button>
    </Grid>
  );

  const AudioPreview = () => (
    <Grid>
      <h3 style={{ textAlign: "center" }}>Result</h3>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: ".75rem",
          alignItems: "center",
        }}
      >
        <Grid container spacing={1} sx={{ marginInline: "1.5rem" }}>
          <Grid item xs={11}>
            <audio src={audioUrl} controls style={{ width: "100%" }}></audio>
          </Grid>
          <Grid item xs={1} sx={{ display: "grid", placeItems: "center" }}>
            <Box
              disableElevation
              onClick={handleDeleteRecord}
              sx={{
                // background: "yellow",
                cursor: "pointer",
                width: "3rem",
                height: "3rem",
                display: "grid",
                placeItems: "center",
              }}
            >
              <img src={DeleteIcon} alt="Delete" style={{ width: "1.5rem" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", marginBlock: ".75rem" }}>
        <a download href={audioUrl} name={audioFile.name}>
          Download audio
        </a>
      </Box>
    </Grid>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".75rem",
          marginBlock: ".5rem",
        }}
      >
        {!permission && (
          <Grid sx={{ display: "grid", placeItems: "center" }}>
            <Button color="secondary" variant="outlined" onClick={getAccess}>
              Get Access
            </Button>
          </Grid>
        )}

        {permission && recordingStatus === STATUS_RECORD.RECORDING && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4">Recording...</Typography>
          </div>
        )}

        {permission && audioBlob === null && <RecorderControl />}

        {audioUrl !== null && audioBlob !== null && <AudioPreview />}
      </Box>
    </>
  );
};

export default AudioRecord;
