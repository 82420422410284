import React, { useState, useEffect, useRef, useContext } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  ownerDocument,
  ownerWindow,
} from "@mui/material";
import CalendarIcon from "./../../../../assets/calendar.png";
import RemoveIcon from "./../../../../assets/minus.svg";

import {
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../../util/style";
import axios, { headersAPIToken } from "../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  SelectOneAutocompleteSearch,
  FilePicker,
} from "../../../../components/Form";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import { FormDataContext } from "./wizard";

const STEP_NAME = "StepFive";

// const [isFormValid, setIsFormValid] = useState(false);

const fileFieldTip = "Max size: 5 MB. Format file: JPG, PNG, or PDF.";

/** Value OWNER_TYPE bind to value in StepOne */
const OWNER_TYPE = Object.freeze({
  PERSONAL: "personal",
  COMPANY: "company",
});

const ANOTHER_INPUT_TYPE = Object.freeze({
  SELECT_ONE_AUTOCOMPLETE_SEARCH: "SELECT_ONE_AUTOCOMPLETE_SEARCH",
  FILE: "FILE",
});

const fields = [
  {
    title: "Attachment",
    children: [
      {
        name: "support_documents",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Supporting Documents",
        placeholder: "Select client name",
        defaultValue: [""],
        multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
      },
      {
        name: "surat_kuasa",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Surat Kuasa Konsultan KI Bermatrai Cukup (Asli)",
        //   placeholder: "S",
        defaultValue: "",
        //           apiEndpoint: "/master/applicants",
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "tanda_tangan_kuasa",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Tanda Tangan Digital Kuasa",
        //   placeholder: "Select category",
        defaultValue: "",
        //   apiEndpoint: "/master/services", // get all service
        required: true,
        gridWidth: 6, // Half width
      },
      {
        name: "tanda_tangan_pemohon",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Tanda Tangan Digital Pemohon",
        //   placeholder: "Select type",
        defaultValue: "",
        //           apiEndpoint: "/master/services",
        //           apiEndpoint: `/master/services?main_service_id=${id}`,
        required: true,
        gridWidth: 6, // Half width
      },
      // {
      //   name: "mobile_phone",
      //   type: INPUT_TYPE.PHONE_NUMBER,
      //   label: "Phone",
      //   placeholder: "Input mobile phone",
      //   defaultValue: "",
      //   validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
      //   errorMessage:
      //     "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
      //   required: false,
      //   gridWidth: 6, // Half width
      //   additionalProps: {
      //     disabled: true,
      //   },
      // },
      // {
      //   name: "email",
      //   type: INPUT_TYPE.TEXT,
      //   label: "Email",
      //   defaultValue: "",
      //   required: false,
      //   validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      //   errorMessage: "Invalid email address",
      //   gridWidth: 6, // Full width
      //   additionalProps: {
      //     disabled: true,
      //   },
      // },
      // {
      //   name: "business_field_id",
      //   type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      //   label: "Business Field",
      //   placeholder: "Select business field",
      //   // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      //   apiEndpoint: "/master/business-fields",
      //   required: true,
      //   gridWidth: 6, // Half width
      // },
      // {
      //   name: "company_name",
      //   type: INPUT_TYPE.TEXT,
      //   label: "Company",
      //   placeholder: "Enter company name",
      //   defaultValue: "",
      //   required: true,
      //   gridWidth: 6, // Full width
      // },
      // {
      //   name: "submission_on_behalf_of",
      //   type: INPUT_TYPE.RADIO,
      //   label: "Owner Type",
      //   defaultValue: [], // Array for selected format values
      //   options: [
      //     { id: "Personal", name: "Personal" },
      //     { id: "Company", name: "Company" },
      //   ],
      //   required: true,
      //   gridWidth: 6, // Half width
      // },
    ],
  },
];

const personalTypeFields = [
  {
    title: "Attachment",
    children: [
      {
        name: "surat_kuasa",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Surat Kuasa",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "surat_pernyataan",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Surat Pernyataan",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "tanda_tangan_kuasa",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Tanda Tangan Digital Kuasa",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "tanda_tangan_direktur",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Tanda Tangan Direktur",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "npwp",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "NPWP",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "ktp_direktur",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "KTP Direktur",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "akta",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Akta",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "support_documents",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Supporting Documents",
        placeholder: "Select client name",
        defaultValue: "",
        multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
    ],
  },
];

const companyTypeFields = [
  {
    title: "Attachment",
    children: [
      {
        name: "surat_kuasa",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Surat Kuasa",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "surat_pernyataan",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Surat Pernyataan",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "tanda_tangan_pemohon",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Tanda Tangan Digital Kuasa",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "ktp_pemohon",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Tanda Tangan Digital Kuasa",
        placeholder: "Upload File",
        defaultValue: "",
        // multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
      {
        name: "support_documents",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Supporting Documents",
        placeholder: "Select client name",
        defaultValue: "",
        multiple: true,
        //   apiEndpoint: "/master/clients",
        required: true,
        gridWidth: 6, // Half width,
        tip: fileFieldTip,
      },
    ],
  },
];

const StepFiveRevisi = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
  setIsFormValid,
}) => {
  const { id } = useParams();

  //* Local Storage Hook
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    "trademark_application_options_stepFiveRevisi",
    {}
  );

  useEffect(() => {
    //* If last step, isRun === NOT
    if (isNextTriggered.isRun === "YES") {
      const validForm = validateForm();
      console.log({ validForm });
    }
    // console.log({ isNextTriggered });
  }, [isNextTriggered]);

  const { token } = useSelector((state) => state.auth);

  const [ownerType, setOwnerType] = useState("");

  // useEffect(() => {
  //   console.log(formData);
  // }, []);

  //* ===================================================================  */
  //*                               CONTEXT                                */
  //* ===================================================================  */
  const { value, setter } = useContext(FormDataContext);

  const { allStepFormData, stepName } = value;
  // const {} = setter;

  // useEffect(() => {}, [ownerType]);

  // useEffect(() => {
  //   console.log({ ownerType });
  // }, [ownerType]);

  //* ===================================================================  */
  //*                              INITIAL FORM                            */
  //* ===================================================================  */

  const initialFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };

  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialErrorFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };
  const initialErrorEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  /**
   *
   * @param { 'personal'|'company' } type
   * @returns
   */
  const initialFormValues = (type = "company") => {
    const initialValues = {};

    const setterCallback = (fieldGroup) => {
      if (fieldGroup.children && Array.isArray(fieldGroup.children)) {
        if (!initialValues[fieldGroup.title]) {
          initialValues[fieldGroup.title] = {};
        }
        fieldGroup.children.forEach((field) => {
          if (field.multiple) {
            initialValues[fieldGroup.title][field.name] = [field.defaultValue] || [""];
          } else {
            initialValues[fieldGroup.title][field.name] = field.defaultValue || "";
          }
        });
      }
    };

    if (type === OWNER_TYPE.COMPANY) {
      companyTypeFields.forEach(setterCallback);
    } else if (type == OWNER_TYPE.PERSONAL) {
      personalTypeFields.forEach(setterCallback);
    }
    // console.log({ OWNER_TYPE, type });
    // fields.forEach((fieldGroup) => {
    //   if (fieldGroup.children) {
    //     //* Used for other section
    //     if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
    //       initialValues[fieldGroup.title] =
    //         fieldGroup.title === "Family" ? [initialFamily] : [initialEmergencyContact];
    //     } else {
    //       fieldGroup.children.forEach((field) => {
    //         if (!initialValues[fieldGroup.title]) {
    //           initialValues[fieldGroup.title] = {};
    //         }
    //         //* Used for this section
    //         initialValues[fieldGroup.title][field.name] = field.defaultValue;
    //       });
    //     }
    //   }
    // });

    // console.log({ initialValues });
    return {
      ...initialValues,
    };
  };

  /**
   *
   * @param { 'personal'|'company'} type
   * @returns
   */
  const initialErrors = (type = "company") => {
    const initialErrorValues = {};

    const setterCallback = (fieldGroup) => {
      if (fieldGroup.children && Array.isArray(fieldGroup.children)) {
        if (!initialErrorValues[fieldGroup.title]) {
          initialErrorValues[fieldGroup.title] = {};
        }
        fieldGroup.children.forEach((field) => {
          if (field.multiple) {
            initialErrorValues[fieldGroup.title][field.name] = [""];
          } else {
            initialErrorValues[fieldGroup.title][field.name] = "";
          }
        });
      }
    };

    if (type === OWNER_TYPE.COMPANY) {
      companyTypeFields.forEach(setterCallback);
    } else if (type === OWNER_TYPE.PERSONAL) {
      personalTypeFields.forEach(setterCallback);
    }

    // fields.forEach((fieldGroup) => {
    //   if (fieldGroup.children) {
    //     //* Used for other section
    //     if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
    //       initialErrorValues[fieldGroup.title] =
    //         fieldGroup.title === "Family"
    //           ? [initialErrorFamily]
    //           : [initialErrorEmergencyContact];
    //     } else {
    //       if (!initialErrorValues[fieldGroup.title]) {
    //         initialErrorValues[fieldGroup.title] = {};
    //       }
    //       //* Used for this section
    //       fieldGroup.children.forEach((field) => {
    //         initialErrorValues[fieldGroup.title][field.name] = field.multiple ? [""] : "";
    //       });
    //     }
    //   }
    // });

    return initialErrorValues;
  };

  // ---------------------- [ STATE ] ---------------------------- */
  const [options, setOptions] = useState({});

  const [formValues, setFormValues] = useState({});

  const [errors, setErrors] = useState({});

  const fetchDataType = async () => {
    // const serviceId = formValues["Applicant Information"]["category_id"];
    // if (serviceId) {
    //   const { data } = await (
    //     await axios.get(`/master/criteria/${serviceId}`, headersAPIToken(token))
    //   )?.data;
    //   setOptions((prevOptions) => ({
    //     ...prevOptions,
    //     ["type_id"]: data,
    //   }));
    //   if (localStorageValue) {
    //     const copyLocalStorage = { ...localStorageValue };
    //     copyLocalStorage["type_id"] = data;
    //     setLocalStorageValue(copyLocalStorage);
    //   } else {
    //     // localStorage.setItem("business_permit_options_step_one", JSON.stringify(options));
    //     setLocalStorageValue(options);
    //   }
    // }
  };

  const fetchDataApplicantAndBrand = async () => {
    // const client_id = formValues["Applicant Information"]["client_id"];
    // if (client_id) {
    //   const aplicanEndpoint = `/master/applicants`;
    //   const brandEndpoint = `/master/brands`;
    //   const param = { params: client_id };
    //   fetchOption(aplicanEndpoint, "applicant_id", null, param);
    //   fetchOption(brandEndpoint, "brand_id", null, param);
    // }
  };

  const fetchOption = async (
    endpoint,
    optionName,
    aliasName = "",
    customConfig = null
  ) => {
    try {
      let config = { ...headersAPIToken(token) };

      if (customConfig) {
        config = { ...config, ...customConfig };
      }

      console.log("This is fetch option", { config });

      const response = await axios.get(endpoint, config);
      let optionPayload = response?.data?.data;

      //* If option don't have key name, pass alias name for make alias;
      if (aliasName) {
        optionPayload = optionPayload.map((opt) => {
          opt["name"] = opt[aliasName];
          return opt;
        });
      }
      if (Array.isArray(optionPayload)) {
        //* Set option
        setOptions((prevValue) => ({ ...prevValue, [optionName]: optionPayload }));

        //* Set to localStorage
        if (localStorageValue) {
          const copyLocalStorage = { ...localStorageValue };
          copyLocalStorage[optionName] = optionPayload;
          setLocalStorageValue(copyLocalStorage);
        }
      } else {
        console.warn("Option payload not array");
        throw new Error("Failed get option for : ".concat(optionName));
      }
    } catch (error) {
      console.error(`failed get option for ${optionName} : ${error}`);
    }
  };

  //* ===================================================================  */
  //*                               HANDLER                                */
  //* ===================================================================  */

  const onMount = () => {
    console.group("On Mount");
    let choosedOwnerType;
    if (!ownerType) {
      const stepNames = Object.values(stepName);
      const formGroup = allStepFormData[stepNames[0]];
      // console.log({ formGroup });
      function recursiveFindData(form, count = 0) {
        if (choosedOwnerType) {
          return;
        }
        // console.log({form, count});
        for (const keyForm in form) {
          if (choosedOwnerType) {
            break;
          }
          if (typeof form[keyForm] === "object") {
            recursiveFindData(form[keyForm], count + 1);
          } else {
            if (keyForm === "owner_type") {
              choosedOwnerType = form[keyForm];
            }
          }
        }
      }
      recursiveFindData(formGroup);

      if (choosedOwnerType) {
        setOwnerType(choosedOwnerType);
      }
    }
    console.log({ choosedOwnerType, formData });
    if (formData && Object.values(formData).length > 0) {
      setFormValues(formData);
    } else {
      // console.log({ ownerType });
      console.log({
        form: initialFormValues(choosedOwnerType),
        error: initialErrors(choosedOwnerType),
      });
      setFormValues(initialFormValues(choosedOwnerType));
      setErrors(initialErrors(choosedOwnerType));
    }

    console.groupEnd();
    return () => {
      onUnMount();
    };
  };

  const onUnMount = () => {
    console.log("Component un mounted");
  };

  const handleSelectFile = (file, fieldName, sectionName, index) => {
    setFormValues((prevValue) => {
      const updatedFormValues = { ...prevValue };
      // const param = {file, fieldName, sectionName, index};
      // debugger;
      if (index !== null && !isNaN(index)) {
        // console.log({ form: updatedFormValues[sectionName][fieldName], sectionName, fieldName});
        updatedFormValues[sectionName][fieldName][index] = file;
      } else {
        updatedFormValues[sectionName][fieldName] = file;
      }
      return updatedFormValues;
    });
    // console.log("Handdle filke select", file);
  };
  const handleAddFileField = (sectionName, fieldName) => {
    setFormValues((prevValue) => {
      const updatedFormVal = { ...prevValue };
      updatedFormVal[sectionName][fieldName].push("");
      return updatedFormVal;
    });
  };
  const handleRemoveFile = (index, sectionName, fieldName) => {
    setFormValues((prevValue) => {
      const updatedFormVal = { ...prevValue };
      let removed;
      if (index !== null && !isNaN(index)) {
        removed = updatedFormVal[sectionName][fieldName].splice(index, 1);
      } else {
        removed = updatedFormVal[sectionName][fieldName] = null;
      }
      // const aa = updatedFormVal;
      // const bb = removed;
      // const ii = index;
      // // debugger;
      return updatedFormVal;
    });

    setErrors((prevError) => {
      const updatedError = { ...prevError };
      if (index !== null && !isNaN(index)) {
        updatedError[sectionName][fieldName].splice(index, 1);
      } else {
        updatedError[sectionName][fieldName] = "";
      }
      return updatedError;
    });
  };

  const handleAddRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Family" ? initialErrorFamily : initialErrorEmergencyContact,
      ], // Add an empty error object
    }));
  };

  const handleRemoveRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter((row, index) => index !== rowIndex),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  };

  const handleInputChange = async (event, sectionTitle, field, index) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        //* Note: nyoba;

        const errorFile = validateInputFile(file, field);
        setErrors((prevValue) => {
          const updatedError = { ...prevValue };
          if (index !== null && !isNaN(index)) {
            updatedError[sectionTitle][name][index] = errorFile;
          } else {
            updatedError[sectionTitle][name] = errorFile;
          }
          // console.log("Handle input change file error: ", updatedError);
          return updatedError;
        });
      }
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle] = {
          ...updatedValues[sectionTitle],
          [name]: value,
        };

        return updatedValues;
      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);
        updatedErrors[sectionTitle] = {
          ...updatedErrors[sectionTitle],
          [name]: fieldErrors,
        };
        return updatedErrors;
      });
    }
    updateWizardData(STEP_NAME, formValues);
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    updateWizardData(STEP_NAME, formValues);
    validateField(sectionTitle, fieldName, index, { name, value });
    // debugger;
  };

  // setIsFormValid(validateForm());

  //* ===================================================================  */
  //*                               VALIDATE                               */
  //* ===================================================================  */

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const validateCallback = (fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;

        const sectionErrors = {};

        fieldGroup.children.forEach((field) => {
          const { name } = field;

          const fieldValue =
            formValues[title] && formValues[title][name] !== undefined
              ? formValues[title][name]
              : "";
          const error = validateFieldHelper(fieldValue, field);

          sectionErrors[name] = error;

          //* validate for multiple value
          if (Array.isArray(error)) {
            error.forEach((err) => {
              if (err) {
                valid = false;
              }
            });
          } else if (error) {
            valid = false;
          }
        });

        newErrors[title] = sectionErrors;
      }
    };

    if (ownerType === OWNER_TYPE.COMPANY) {
      companyTypeFields.forEach(validateCallback);
    } else if (ownerType === OWNER_TYPE.PERSONAL) {
      personalTypeFields.forEach(validateCallback);
    }
    // fields.forEach();

    console.log({ newErrors });
    setErrors(newErrors);
    formAttempted(valid);
    updateWizardData(STEP_NAME, formValues);
    return valid;
  };

  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    let fieldGroup;
    if (ownerType === OWNER_TYPE.COMPANY) {
      // fields.find((group) => group.title === sectionTitle);
      fieldGroup = companyTypeFields.find(
        (sectionGroup) => sectionGroup.title === sectionTitle
      );
    } else if (ownerType === OWNER_TYPE.PERSONAL) {
      fieldGroup = personalTypeFields.find(
        (sectionGroup) => sectionGroup.title === sectionTitle
      );
    }

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find((field) => field.name === fieldName);

      if (field) {
        const { name } = field;

        // if (
        //   !isNaN(index) &&
        //   (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
        // ) {
        //   const fieldValue = formValues[sectionTitle][index][name] || value;
        //   newErrors[sectionTitle][index][name] = validateFieldHelper(fieldValue, field);
        // } else {
        // }
        const fieldValue = formValues[sectionTitle][name] || value;
        newErrors[sectionTitle][name] = validateFieldHelper(fieldValue, field);
      }
    }
    setErrors(newErrors);
  };

  /**
   *
   * @param {any} fieldValue
   * @param {Object} field
   * @returns {String}
   */
  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (type === ANOTHER_INPUT_TYPE.FILE) {
      if (Array.isArray(fieldValue)) {
        //* Validate for multiple value
        const errorFiles = fieldValue.map((file) => validateInputFile(file, field));
        return errorFiles;
      } else {
        //* validate for single value
        return validateInputFile(fieldValue, field);
      }
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (fieldValue && fieldValue.length && validation && !validation(fieldValue)) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  const validateInputFile = (file, field) => {
    if (file instanceof File) {
      const allowExtension = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10,
        maxSizeInByte = size * 1024 * 1024, // MB
        fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowExtension.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with valid extension";
      } else if (file.size > maxSizeInByte) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    } else if (file === "") {
      //* When file is empty string
      return String(field.label).concat(" is required!");
    }
    return "";
  };
  useEffect(() => {
    updateWizardData(STEP_NAME, formValues);
  }, [formValues]);

  //* ===================================================================  */
  //*                               WATCHER                                */
  //* ===================================================================  */

  useEffect(onMount, []);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  useEffect(() => {
    console.log("This is option", { options });
  }, [options]);

  //* ===================================================================  */
  //*                               COMPONENT                              */
  //* ===================================================================  */

  const ButtonAdd = ({ section, field }) => {
    return (
      <Box sx={{ ...justifyContentEnd }}>
        <Button
          onClick={() => handleAddFileField(section.title, field.name)}
          //   disabled={actionType === ACTION_TYPE.EDITED}
        >
          <span
            style={{
              fontSize: "1.5rem",
              marginRight: ".6rem",
              display: "inline-block",
            }}
          >
            +
          </span>
          Add More
        </Button>
      </Box>
    );
  };

  const ButtonRemove = ({ index, section, field, disabled = false }) => {
    return (
      <Grid grid item xs={2} style={{ paddingBlock: ".2rem" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            ...removeButton,
          }}
          onClick={() => handleRemoveFile(index, section.title, field.name)}
        >
          <img src={RemoveIcon} alt={`Remove item`} />
        </Button>
      </Grid>
    );
  };

  const LoadingComponent = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  );

  const renderFields = (section, index, field) => {
    const currentFormValues = formValues[section.title];
    const currentErrors = errors[section.title];
    // console.log(section);
    return (
      <>
        {section.children?.map((field) => {
          //   const checkCategoryId = formValues["Applicant Information"]["category_id"];

          //   const isCategoryIdBPOMandExist =
          //     checkCategoryId !== 102 &&
          //     (checkCategoryId !== undefined ||
          //       checkCategoryId !== null ||
          //       checkCategoryId !== "");

          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
                isRequired,
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];

          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, index, e);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case ANOTHER_INPUT_TYPE.FILE:
              const sectionName = section.title;
              const valueInForm = currentFormValues[field.name];
              if (field.multiple) {
                fieldComponent = (
                  <React.Fragment>
                    <Grid>
                      {valueInForm.map((_, indexValueFile, arayValue) => (
                        <Grid container key={indexValueFile}>
                          <Grid item xs={10.5}>
                            <FilePicker
                              formValues={valueInForm[indexValueFile]}
                              errors={currentErrors}
                              field={field}
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  sectionName,
                                  field.name,
                                  indexValueFile
                                )
                              }
                              onFileSelect={(file) =>
                                handleSelectFile(
                                  file,
                                  field.name,
                                  sectionName,
                                  indexValueFile
                                )
                              }
                              indexFile={indexValueFile}
                              URIForViewDocument={valueInForm[indexValueFile]?.url}
                              tip={"Max size: 5 MB. Format file: JPG, PNG, or PDF."}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1.5}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <ButtonRemove
                              index={indexValueFile}
                              section={section}
                              field={field}
                              disabled={arayValue.length < 2}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid>
                      <ButtonAdd section={section} field={field} />
                    </Grid>
                  </React.Fragment>
                );
              } else {
                fieldComponent = (
                  <Grid container sx={{ marginBlock: ".7rem" }}>
                    <Grid item xs={10.5}>
                      <FilePicker
                        formValues={valueInForm}
                        errors={currentErrors}
                        field={field}
                        handleInputChange={(e) =>
                          handleInputChange(e, sectionName, field.name, null)
                        }
                        onFileSelect={(file) =>
                          handleSelectFile(file, field.name, sectionName, null)
                        }
                        URIForViewDocument={valueInForm?.url}
                        tip={"Max size: 5 MB. Format file: JPG, PNG, or PDF."}
                      />
                    </Grid>
                    <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
                      <ButtonRemove
                        index={null}
                        field={field}
                        section={section}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                );
              }
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }
          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section) => {
    return (
      <Grid item xs={12} key={section.title}>
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {section.title}
          </Typography>
          {/* Subtitle */}
          {section.subtitle && (
            <Typography variant="subtitle2">{section.subtitle}</Typography>
          )}
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {renderFields(section)}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {ownerType === OWNER_TYPE.COMPANY
          ? companyTypeFields.map(renderSectionFields)
          : ownerType === OWNER_TYPE.PERSONAL &&
            personalTypeFields.map(renderSectionFields)}
        {/* {fields.map((section) => renderSectionFields(section))} */}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default StepFiveRevisi;
