import { VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILED } from "./state";

const initState = {
  user: null,
  isVerified: false,
  message: null,
  isLoading: false,
};

const otpReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        user: payload,
        isVerified: true,
        message: null,
        isLoading: false,
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default otpReducer;
