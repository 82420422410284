import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import "dayjs/locale/en";
import CalendarIcon from "./../../../assets/calendar.png";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../util/style";
import axios, { headersAPIToken } from "./../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "./../../../components/Form";
import { addPayment, deletePayment, editPayment, getPayment } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import LeftDrawer from "./../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";
// import { PaymentStatusBadge } from "../payment-request/parts/CusomStyle";
import {
  // columns,
  fields,
  ANOTHER_INPUT_TYPE,
} from "./../payment-request/parts/imutable_state";
import FormContent from "../payment-request/componens/FormContent";
import useGetDetail from "../payment-request/hooks/useGetDetail";
import { columns } from "./parts/imutable_state";

/* =========================================================== */
/*                            MAIN FUNCTION                    */
/* =========================================================== */
const Payment = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  //* ========== State =============

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [paymentDeleted, setPaymentDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Payament Request");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [chooseCategory, setChooseCategory] = useState("");
  const [choosedStatus, setChoosedStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  //* Type as Category
  const [type, setType] = useState(ACTION_TYPE.DEFAULT_TYPE);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //TODO: Register new reducer
  const {
    data: rows,
    message,
    isLoading,
    pagination,
    detail_payment,
  } = useSelector((state) => state.financePayment);

  //* =============== Global modal ===========
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  //* buat disabled button toolbar
  useEffect(() => {
    setSelectedData([]);
  }, [dispatch]);

  // * ==================== Status watcher ===============
  // useEffect(() => {
  //   if (status === ACTION_TYPE.DEFAULT_STATUS) {
  //     setType(ACTION_TYPE.DEFAULT_TYPE);
  //   }
  // }, [status]);

  function getDataPayment() {
    let optionPayload = {
      token,
      limit,
      search,
      // status,
      sortDirection,
      sortBy,
      // type,
    };
    if (
      type !== ACTION_TYPE.DEFAULT_TYPE ||
      status !== ACTION_TYPE.DEFAULT_STATUS ||
      (search !== undefined && search !== "")
    ) {
      if (type && type !== ACTION_TYPE.DEFAULT_TYPE) {
        optionPayload.category = type;
      }
      if (
        status &&
        status !== ACTION_TYPE.DEFAULT_STATUS &&
        status !== ACTION_TYPE.ALL_STATUS
      ) {
        optionPayload.status = status;
      }
      // console.log("get khusus");
      // console.log(optionPayload);
      dispatch(getPayment(optionPayload));
    } else {
      // console.log(type);
      // console.log(status);
    }
  }

  useEffect(() => {
    if (type !== ACTION_TYPE.DEFAULT_TYPE || status !== ACTION_TYPE.DEFAULT_STATUS) {
      getDataPayment();
      setChoosedStatus("Fileter by category");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [type, status]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const { data: response } = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };
    fields.forEach((field) => {
      if (field.apiEndpoint) {
        const { name, apiEndpoint } = field;
        if (!options.hasOwnProperty(name)) {
          fetchOptions(apiEndpoint, name);
        }
      }
    });
  }, [options]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  //* ===================== Initial Value ==================

  const initialAttachment = [""];
  const initialErrorAttachment = [""];

  const initialFormValues = () => {
    let initialValues = {};
    fields.forEach((field) => {
      if (field.children && field.title === "Attachment") {
        let { name } = field.children[0];
        initialValues = {
          ...initialValues,
          [name]: initialAttachment,
        };
      } else {
        initialValues = {
          ...initialValues,
          [field.name]: field["defaultValue"],
        };
      }
    });
    return initialValues;
  };

  const initialErrors = () => {
    let initialErrorValues = {};
    fields.forEach((field) => {
      if (field.children && field.title === "Attachment") {
        let { name } = field.children[0];
        initialErrorValues = {
          ...initialErrorValues,
          [name]: initialErrorAttachment,
        };
      } else {
        initialErrorValues = {
          ...initialErrorValues,
          [field.name]: "",
        };
      }
    });
    return initialErrorValues;
  };

  //*  ================ Initial State ====================== */
  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors);

  //* ================== Reseter ==========================
  const resetFormValues = () => {
    setFormValues(initialFormValues());
  };

  // useEffect(() => {
  //   console.log(formValues, Date.now(), "========================= ");
  // }, [formValues]);

  // useEffect(() => {
  //   console.log(
  //     errors.attachments[0],
  //     Date.now(),
  //     "============================== error ======="
  //   );
  // }, [errors]);

  // const [detailRow, setDetailRow] = useState({});

  //* =============== Watcher Getter =================
  useEffect(() => {
    if (isActive === true) {
      // dispatch(
      //   getPayment({
      //     token,
      //     limit,
      //     search,
      //     status,
      //     sortDirection,
      //     sortBy,
      //     type,
      //   })
      // );
      console.log("isactive");
    }
  }, [isActive]);

  // useEffect(() => {
  //   if (formModal) {
  //     fields.forEach((field) => {
  //       // if (field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
  //       //   let optionCategory = [
  //       //     { name: "Service", id: "service" },
  //       //     { name: "Non Service", id: "non_service" },
  //       //   ];
  //       //   let optionCurentcy = [
  //       //     { name: "Rp", id: "rp" },
  //       //     { name: "$", id: "dolar" },
  //       //   ];
  //       //   if (field.name === "category") {
  //       //     setOptions((prev) => ({ ...prev, [field.name]: optionCategory }));
  //       //   } else if (field.name === "currency") {
  //       //     setOptions((prev) => ({ ...prev, [field.name]: optionCurentcy }));
  //       //   }
  //       // }
  //     });
  //   }
  // }, [formModal]);

  /* ============================================================ */
  /*                        EDIT ACTION                           */
  /* ============================================================ */

  const getOptions = async (url, fieldName) => {
    try {
      const { data } = await axios.get(url, headersAPIToken(token));
      setOptions((prev) => ({ ...prev, [fieldName]: data.data }));
    } catch (error) {
      console.error(new Error("Error fethcing option".concat("--", error)));
    }
  };

  //TODO: Need customize
  const setFormValuesFromResponse = async (response) => {
    let { data: detail } = response;
    resetFormValues();
    setErrors(initialErrors());
    let filledValue = {
      id: detail.id,
      category_id: detail.Category.category_id,
      attachments: detail.Attachment.attachment,
      due_date: detail["Due Date"],
      currency: detail["Currency"],
      note: detail["Note"],
      total_price: detail["Total"],
      code_billing: detail["code_billing"],
    };

    /** Check for category
     * 1 = service
     * 2 = non-service
     */
    setChooseCategory(detail.Category.category_id);
    if (detail.Category.category_id === 1) {
      filledValue.service_id = detail.Service.service_id;
      filledValue.sub_service_id = detail["Sub Service"].sub_service_id;
      filledValue.client_id = detail.Cients.client_id;
      await getOptions(
        "/master/services?main_service_id=".concat(detail.Service.service_id),
        "sub_service_id"
      );
      await getOptions("/master/clients", "client_id");
    }
    setFormValues((prev) => ({ ...prev, ...filledValue }));
    setIsLoadedFormValues(false);
  };

  const handleDetailPaymentRequest = ({ id }) => {
    const curentPath = location.pathname;
    navigate(curentPath.concat("/detail/", id));
  };

  const [responseDetail, errorDetail, getDetail] = useGetDetail("/payments/", token);
  useEffect(() => {
    // console.log(responseDetail);
    if (responseDetail) {
      setFormValuesFromResponse(responseDetail);
    } else if (errorDetail) {
      console.error(errorDetail);
    }
  }, [responseDetail, errorDetail]);

  //TODO: Need improve
  /**
   *
   * @param {number} [idData]
   */
  const setFormValuesFromSelectedData = async (idData) => {
    let editData;
    if (idData) {
      //* Get Data from param
      editData = rows.filter((el) => el.id === idData)[0];
    } else if (selectedData.length > 0 && selectedData.length < 2) {
      //* Get from selected data;
      editData = rows.filter((elem) => selectedData.includes(elem.id))[0];
    } else {
      //* When can't get data for detail
      console.log("Cannot get detail");
      return;
    }
    //* Extrak data to get id
    const { id } = editData;
    setIsLoadedFormValues(true);
    getDetail(id);
  };

  const isFormFilled = () => {
    return Object.values(formValues).some((value) => value.trim() !== "");
  };

  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  //TODO: need improve
  const handleFileSelect = (file, fieldName, index) => {
    setFormValues((prevValues) => {
      const prevValueFile = formValues[fieldName];
      if (!isNaN(index)) {
        prevValueFile[index] = file;
      }
      return { ...prevValues, [fieldName]: prevValueFile };
    });
  };

  const handleInputChange = (event, field, index, fieldTitle) => {
    const { name, value, type } = event.target;
    const newErrors = { ...errors };
    if (name === "category_id") {
      setChooseCategory(value);
    }
    if (name === "service_id") {
      // setSelectedServiceId(value);
    }
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const childField = field.children[0];
        const fileError = validateFileInput(file, childField);
        // console.log(fileError);
        if (fileError && Array.isArray(newErrors[childField?.name])) {
          let curentErorrFile = newErrors[childField?.name];
          curentErorrFile[index] = fileError;
          newErrors[childField?.name] = curentErorrFile;
          setErrors(newErrors);
        }
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateFileInput = (fieldValue, field) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;
      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === undefined ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] =
          errorMessage || `Please enter ${String(field.label).toLowerCase()}`;
      } else if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validationNonFileType = (field, curError) => {
    let { name, required, validation, errorMessage, type } = field;
    let valid = true;
    let ignoreList = ["service_id", "sub_service_id", "client_id"];
    const valueForm = formValues[name];
    if (chooseCategory !== 1 && ignoreList.includes(field.name)) {
      required = false;
    }

    if (
      (required &&
        (String(valueForm).trim() === "" ||
          valueForm === undefined ||
          valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
          valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)) ||
      valueForm === 0
    ) {
      console.log(field, "field nya");
      curError[name] =
        errorMessage ||
        `Please enter ${String(
          field.aliasLabel ? field.aliasLabel : field.label
        ).toLowerCase()}`;
      valid = false;
    }

    if (
      formValues[name] &&
      formValues[name].length &&
      validation &&
      !validation(formValues[name])
    ) {
      curError[name] = errorMessage || `${field.label} is invalid`;
      valid = false;
    }
    return [valid, curError];
  };
  const validateForm = () => {
    let valid = true;
    let newErrors = { ...errors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;

      if (type === ANOTHER_INPUT_TYPE.FILE) {
        // Handle file input validation
        const fileField = field.children[0];
        const fileValue = formValues[fileField.name];
        fileValue.forEach((fileVal, indexFile) => {
          const fileError = validateFileInput(fileVal, fileField);
          if (fileError) {
            newErrors[fileField.name][indexFile] = fileError;
            valid = false;
          }
        });
      } else if (required) {
        const [returnValid, returnError] = validationNonFileType(field, newErrors);
        valid = returnValid;
        newErrors = returnError;
      }
    });
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          //! Not use Add action
          // handleAddRow();
          // handleCloseFormModal(true);
        }
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.EDITED, dataSelected) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors());
    if (actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Payment");
      //* Set form values depend on selected data
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Payment");
      setPaymentDeleted("payment");
    } else {
      console.log(formValues, "form values add");
      resetFormValues();
      setTitle("Add Payment");

      //* Method that get value from local storage
      // setFormValues(
      //   JSON.parse(localStorage.getItem("sales-management/client/formValues"))
      // );
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleRemoveFile = (fieldName, index) => {
    setFormValues((prevVal) => {
      let updatedValue = { ...prevVal };
      if (!isNaN(index)) {
        updatedValue[fieldName]["attachment"].splice(index, 1);
      } else {
        console.error("Failed delete, invalid index");
      }
      return updatedValue;
    });
  };

  const handleAddFile = (fieldName) => {
    setFormValues((prevValue) => {
      let updatedValue = { ...prevValue };
      updatedValue[fieldName]["attachment"].push("");
      return updatedValue;
    });
  };

  //* ============= Modal Handler ================

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    setSelectedData([]); //buat disabled button toolbar
  };

  const formatPayload = (curentForm) => {
    const copyFormValue = { ...curentForm };
    if (copyFormValue.total_price.includes(",")) {
      copyFormValue.total_price = copyFormValue.total_price.split(",").join("");
    }
    if (copyFormValue.hasOwnProperty("id")) {
      delete copyFormValue.id;
    }
    return copyFormValue;
  };

  const handleAddRow = () => {
    dispatch(addPayment(token, formatPayload(formValues)));
  };

  const handleEditRow = () => {
    dispatch(editPayment(token, formatPayload(formValues), formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deletePayment(token, ids));
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const renderSelectOneAutocomplite = (field, fieldOptions) => {
    if (field.name === "currency") {
      return (
        <React.Fragment key={field.name}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <SelectOneAutocomplete
                field={field}
                formValues={formValues}
                errors={errors}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                fieldOptions={fieldOptions}
                tip={field?.tip}
              />
            </Grid>
            <Grid item xs={10} style={{ marginTop: "1.4rem" }}>
              <TextInput
                field={fields.find((fiel) => fiel.type === INPUT_TYPE.CURRENCY)}
                formValues={formValues}
                errors={errors}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                tip={field?.tip}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (field.name === "service_id" || field.name === "sub_service_id") {
      return chooseCategory === 1 ? (
        <React.Fragment key={field.name}>
          <SelectOneAutocomplete
            field={field}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            fieldOptions={fieldOptions}
            tip={field?.tip}
          />
        </React.Fragment>
      ) : null;
    }
    return (
      <React.Fragment key={field.name}>
        <SelectOneAutocomplete
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={fieldOptions}
          tip={field?.tip}
        />
      </React.Fragment>
    );
  };

  const renderFormContent = () => (
    <FormContent
      actionType={actionType}
      errors={errors}
      formValues={formValues}
      handleAddFile={handleAddFile}
      handleFileSelect={handleFileSelect}
      handleInputBlur={handleInputBlur}
      handleInputChange={handleInputChange}
      handleRemoveFile={handleRemoveFile}
      isLoadedFormValues={isLoadedFormValues}
      options={options}
      selectedData={selectedData}
      dataDeleted={paymentDeleted}
      chooseCategory={chooseCategory}
    />
  );

  // const renderFileInputForm = (field, index) => {
  //   return (
  //     <>
  //       <FilePicker
  //         indexFile={index}
  //         field={field}
  //         formValues={formValues[aliasName][index]}
  //         errors={errors}
  //         onFileSelect={(file) => handleFileSelect(file, aliasName, index)}
  //         handleInputChange={(e) =>
  //           handleInputChange(e, field, index, aliasName)
  //         }
  //         tip={field?.tip}
  //       />
  //     </>
  //   );
  // };

  const renderActionButtons = () => {
    return (
      <Box>
        <>
          <Button
            variant="outlined"
            sx={{ ...btnWhite }}
            onClick={() => handleCloseFormModal()}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" disableElevation type="submit">
            {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
          </Button>
        </>

        {/* {actionType !== ACTION_TYPE.DETAIL &&
        actionType !== ANOTHER_ACTION_TYPE.PREVIEW ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
            >
              {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
            </Button>
          </>
        ) : actionType === ANOTHER_ACTION_TYPE.PREVIEW ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => handleCloseFormModal()}
            >
              Yes
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              //   onClick={() => handleFormModal(ACTION_TYPE.EDITED, detailRow)}
            >
              Edit
            </Button>
          </>
        )} */}
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */

  // useEffect(() => {
  //   // console.log(search, status, isLoading, "query");
  // }, [search, status, isLoading]);

  useEffect(() => {
    if (search) {
      _search();
      setChoosedStatus("search");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }, [search]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      getDataPayment();
      // dispatch(
      //   getPayment({
      //     token,
      //     page,
      //     limit,
      //     search,
      //     status,
      //     sortDirection,
      //     sortBy,
      //     type,
      //   })
      // );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    // getDataPayment();
    console.log("page");
    dispatch(
      getPayment({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        type,
      })
    );
  }

  function _getByLimit(limit) {
    // getDataPayment();
    console.log("limit changer");
    dispatch(
      getPayment({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        type,
      })
    );
  }
  function _search() {
    console.log("search");
    getDataPayment();
    // dispatch(
    //   getPayment({
    //     token,
    //     limit,
    //     search,
    //     status,
    //     sortDirection,
    //     sortBy,
    //     type,
    //   })
    // );
  }

  function _status(status) {
    setStatus(status);
  }

  function _type(val) {
    setType(val);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder="Search by no. reference "
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                  onKeyDown: handleKeyPress,
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={type}
                onChange={(e) => _type(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_TYPE}>Select category</MenuItem>
                <MenuItem value="Services">Service</MenuItem>
                <MenuItem value="Non%20Service">Non Service</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>Select Status</MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                {/* <MenuItem value="Rejected">Rejected</MenuItem> */}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Payment"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            showAddToolbar={false}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={choosedStatus}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            // userAccess={userAccess}
            handleRowDoubleClick={handleDetailPaymentRequest}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED && actionType !== ACTION_TYPE.DETAIL ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
        <Modal open={formModal} onClose={() => handleCloseFormModal()}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
            />
          </Box>
        </Modal>
      )}
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Payment;
