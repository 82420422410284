import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { borderRadius } from "../../../../util/style";
import { INPUT_TYPE } from "../../../../util/function";
import { Typography } from "@mui/material";

/** ---------------------------------------------- */

/**
 * @typedef {object} ColumnType
 * @property {string} field
 * @property {string} headerName
 * @property {number} width
 * @property {boolean} disableColumnMenu
 * @property {boolean} sortable
 */

/** ---------------------------------------------- */

/**
 * @typedef {object} AdditionalPropsType
 * @property {boolean} disabled
 */

/** ---------------------------------------------- */

/**
 * @typedef {object} FieldType
 * @property {string} name
 * @property {string} label
 * @property {string} type
 * @property {string|number|boolean} defaultValue
 * @property {string} required
 * @property {boolean} disabled
 * @property {boolean} multiple
 * @property {string} [aliasLabel]
 * @property {string} [errorMessage]
 * @property {string} [apiEndpoint]
 * @property {any[]} [options]
 * @property {AdditionalPropsType} [additionalProps]
 * @property {Function} [validate]
 */

/** ---------------------------------------------- */

/**
 * @typedef {object} PurchaseChildsType
 * @property {string} category
 * @property {string} brandName
 * @property {string} itemName
 * @property {number} quantity
 * @property {string} unitPrice
 */

/** ---------------------------------------------- */

/**
 * @typedef {object} PurchaseType
 * @property {FieldType[]} purchases
 * @property {FieldType} note
 */

/** ---------------------------------------------- */

const statusColor = {
  pending: { backgroundColor: "#FFF1CF", color: "#FFBA10" },
  cancel: { backgroundColor: "#F5F5F5", color: "#9E9E9E" },
  approved: { backgroundColor: "#D2F0CC", color: "#1DB200" },
  rejected: { backgroundColor: "#FFCDCC", color: "#FF0400" },
  // partial: { backgroundColor: "#1B3B94", color: "#FFFFFF" },
};

export const StatusBadge = styled(Badge)(
  ({ theme, status, isinfo, style = {}, bgColor, colors }) => {
    let backgroundColor = statusColor.pending.backgroundColor;
    let color = statusColor.pending.color;

    // console.log({ status });
    switch (status) {
      case "Approved":
        backgroundColor = statusColor.approved.backgroundColor;
        color = statusColor.approved.color;
        break;
      case "Canceled":
        backgroundColor = statusColor.cancel.backgroundColor;
        color = statusColor.cancel.color;
        break;
      case "Rejected":
        backgroundColor = statusColor.rejected.backgroundColor;
        color = statusColor.rejected.color;
        break;
      default:
        if (colors) {
          color = colors;
        }
        if (bgColor) {
          backgroundColor = bgColor;
        }
    }

    return {
      backgroundColor,
      color,
      padding: "2px 8px",
      borderRadius,
      textTransform: "capitalize",
      ...style,
    };
  }
);

/**@type {FieldType[]} */
export const fields = [
  {
    name: "category",
    type: INPUT_TYPE.TEXT,
    label: "Category",
    defaultValue: "",
    required: true,
    multiple: true,
    additionalProps: {
      disabled: false,
    },
  },
  {
    name: "brand",
    type: INPUT_TYPE.TEXT,
    label: "Brand Name",
    defaultValue: "",
    required: true,
    multiple: true,
    additionalProps: {
      disabled: false,
    },
  },
  {
    name: "name",
    type: INPUT_TYPE.TEXT,
    label: "Item Name",
    defaultValue: "",
    required: true,
    multiple: true,
    additionalProps: {
      disabled: false,
    },
  },
  {
    name: "qty",
    type: INPUT_TYPE.NUMBER,
    label: "Quantity",
    defaultValue: "",
    required: true,
    multiple: true,
    additionalProps: {
      disabled: false,
    },
  },
  {
    name: "price",
    type: INPUT_TYPE.CURRENCY,
    label: "Unit Price",
    defaultValue: "",
    required: true,
    multiple: true,
    additionalProps: {
      disabled: false,
    },
  },
  {
    name: "total_price",
    type: INPUT_TYPE.CURRENCY,
    label: "Total Price",
    defaultValue: "",
    required: false,
    multiple: true,
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "total_purchase",
    type: INPUT_TYPE.CURRENCY,
    defaultValue: "",
    label: "Total Purchase",
    required: false,
    additionalProps: {
      disabled: true,
    },
  },
  {
    name: "desc",
    type: INPUT_TYPE.TEXTAREA,
    defaultValue: "",
    additionalProps: {
      disabled: false,
    },
    label: "Description ( Optional )",
    required: false,
    placeholder: "Input Description",
  },
];

/**@type {ColumnType[]} */
export const columns = [
  {
    field: "code",
    headerName: "No. References",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "total_purchase",
    headerName: "Total Purchase",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "request_by",
    headerName: "Request By",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({ value, row }) => {
      return (
        <Typography>
          {value} <span style={{ opacity: ".5" }}>as {row?.position}</span>
        </Typography>
      );
    },
  },
  {
    field: "created_at_formatted",
    headerName: "Create At",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 180,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({ value }) => {
      // console.log(value);
      return <StatusBadge status={value}>{value}</StatusBadge>;
    },
  },
];
