"use strict";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import "dayjs/locale/en";
import ExportIcon from "./../../../assets/export.png";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  // StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  btnTableToolbar,
  imgBtnToolbar,
} from "../../../util/style";
import axios, { headersAPIToken } from "./../../../core/axios_config";
import OriginAxios from "axios";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "./../../../components/Form";
import {
  addPaymentRequest,
  addPurchaseRequest,
  clearDetailPaymentRequest,
  deletePaymentRequest,
  deletePurchaseRequest,
  detailPurchaseRequest,
  editPaymentRequest,
  getPaymentRequest,
  getPurchaseRequest,
  updatePurchaseRequest,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
  localToUTC,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import LeftDrawer from "./../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";
import {
  fields,
  columns,
  ANOTHER_INPUT_TYPE,
  StatusBadge,
} from "./parts/imutable_state";
import { DETAIL_PURCHASE_REQUEST_SUCCESS } from "./clients/state";

const PurchaseRequest = () => {
  /**
   * @typedef {object} ItemsType
   * @property {string} category
   * @property {string} brand
   * @property {string} name
   * @property {string | number} qty
   * @property {string | number} price
   * @property {string | number} total_price
   */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  const { token, user } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortModel, setSortModel] = useState([]);
  const [messageConfirm, setMessageConfirm] = useState("");

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Purchase Request");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [choosedStatus, setChoosedStatus] = useState(
    ACTION_TYPE.DEFAULT_STATUS
  );
  const [totalPurchase, setTotalPurchase] = useState("");
  const [detailPr, setDetailPr] = useState(null);

  //* Type as Category
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);
  const dispatch = useDispatch();

  const GLOBAL_NAME = Object.freeze({
    ITEMS: "items",
  });

  const {
    data: rows,
    isLoading,
    message,
    pagination,
    detail,
  } = useSelector((state) => state.purchaseRequest);

  //* =================================================================================*//
  //*                                    INITIAL FORM                                  *//
  //* =================================================================================*//

  const initialFormValues = () => {
    const newFormValues = {};
    const multipleField = {};
    fields.forEach((field) => {
      let valueDefault = field.defaultValue;
      if (field.type === INPUT_TYPE.CURRENCY) {
        valueDefault = "Rp. ".concat(valueDefault);
      }
      if (field.multiple) {
        multipleField[field.name] = valueDefault;
      } else {
        newFormValues[field.name] = valueDefault;
      }
    });
    newFormValues[GLOBAL_NAME.ITEMS] = [multipleField];
    return newFormValues;
  };

  const initialErrors = (amount) => {
    const newErrors = {};
    const multipleField = {};
    fields.forEach((field) => {
      if (field.multiple) {
        multipleField[field.name] = "";
      } else {
        newErrors[field.name] = "";
      }
    });
    newErrors[GLOBAL_NAME.ITEMS] = [];
    if (amount) {
      for (let i = 0; i < amount; i++) {
        newErrors[GLOBAL_NAME.ITEMS].push(multipleField);
      }
    } else {
      newErrors[GLOBAL_NAME.ITEMS] = [multipleField];
    }
    return newErrors;
  };
  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());

  const resetFormValues = () => {
    setFormValues(initialFormValues());
    setErrors(initialErrors());
  };

  const isFormFilled = (value = formValues) => {
    const listIgnoreColumn = ["total_purchase", "total_price", "price"];
    const isFilled = Object.entries(value).some(([key, val]) => {
      let result = false;
      if (Array.isArray(val)) {
        result = isFormFilled(val);
      }

      if (listIgnoreColumn.includes(key)) return result;
      if (val !== "" || val !== 0) {
        result = true;
      } else {
        result = false;
      }
      return result;
    });

    return isFilled;
  };

  //* =================================================================================*//
  //*                                      HANDLER                                     *//
  //* =================================================================================*//

  const onMount = () => {
    setSelectedData([]); //* Disabled action table;
    return onUnMount();
  };
  const onUnMount = () => {};

  const validateField = (fieldName, index) => {
    let field = fields.find((field) => field.name === fieldName);
    // console.log({ field, index });
    const specialField = ["price"];
    if (field) {
      const copyError = { ...errors };
      const {
        name,
        defaultValue,
        validate,
        label,
        errorMessage,
        required,
        additionalProps,
      } = field;
      let valueInForm = formValues[name];
      let messageError = "";
      if (index !== undefined && index !== null) {
        valueInForm = formValues[GLOBAL_NAME.ITEMS][index][name];
      }

      if (required) {
        //* Clear default value "Rp. "
        if (specialField.includes(name) && valueInForm.length === 4) {
          valueInForm = valueInForm.substring(4);
        }

        if (
          valueInForm === "" ||
          valueInForm === 0 ||
          valueInForm === defaultValue
        ) {
          messageError = "Please enter ".concat(label.toLowerCase());
        }
      }

      if (valueInForm && validate && !validate(valueInForm)) {
        messageError = errorMessage || label.concat(" is invalid");
      }

      if (index !== undefined && index !== null) {
        copyError[GLOBAL_NAME.ITEMS][index][name] = messageError;
      } else {
        copyError[name] = messageError;
      }
      setErrors(copyError);
    }
  };

  const validateForm = () => {
    const copyError = { ...errors };
    let valid = true;

    formValues[GLOBAL_NAME.ITEMS].forEach((item, index) => {
      Object.entries(item).forEach(([itemName, val]) => {
        let field = fields.find((F) => F.name === itemName);
        if (field) {
          let messageError = "";
          const { required, defaultValue, validate, errorMessage, label } =
            field;

          //* Remove default 'Rp .'
          if (itemName === "price") {
            val = val.substring(4);
          }
          if (required && (val === "" || val == 0 || val === defaultValue)) {
            messageError = "Please enter ".concat(label.toLowerCase());
            valid = false;
          }

          if (val && validate && !validate(val)) {
            messageError = errorMessage || `${label} is invalid`;
            valid = false;
          }
          copyError[GLOBAL_NAME.ITEMS][index][itemName] = messageError;
        }
      });
    });

    setErrors(copyError);
    return valid;
  };

  const handleInputBlur = (param, index) => {
    const { name } = param.target;
    //TODO: validate field;
    validateField(name, index);
  };
  const handleInputChange = (
    /** @type {import("react").SyntheticEvent} */ event,
    index
  ) => {
    const { name, value } = event.target;
    if (index !== undefined) {
      const currencyField = ["price"];
      setFormValues((prev) => {
        const copy = { ...prev };
        if (index !== null || index !== undefined) {
          if (currencyField.includes(name)) {
            copy[GLOBAL_NAME.ITEMS][index][name] = "Rp. ".concat(value);
          } else {
            copy[GLOBAL_NAME.ITEMS][index][name] = value;
          }
        } else {
          copy[name] = value;
        }
        return copy;
      });
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    formValues[GLOBAL_NAME.ITEMS].forEach(calculateTotalPrice);
    const copyForm = { ...formValues };
    const calculatedTotalPurchase = copyForm[GLOBAL_NAME.ITEMS]
      .map((item) => formatValueCurrencyToNumber(item.total_price.substring(4)))
      .reduce((prev, current) => prev + current, 0);
    // copyForm["total_purchase"] = "Rp. ".concat(
    //   formatValueCurrencyToString(totalPurchase)
    // );
    setTotalPurchase(
      "Rp. ".concat(formatValueCurrencyToString(calculatedTotalPurchase))
    );
  }, [formValues]);

  const calculateTotalPrice = (fieldValue, index) => {
    let unitPrice = String(fieldValue["price"]).substring(4);
    const quantity = fieldValue["qty"];
    if (unitPrice !== "" && quantity != "") {
      unitPrice = formatValueCurrencyToNumber(unitPrice);
      let totalPrice = 0;
      totalPrice = parseInt(unitPrice) * parseInt(quantity);
      totalPrice = formatValueCurrencyToString(totalPrice);
      const copyForm = { ...formValues };
      copyForm[GLOBAL_NAME.ITEMS][index]["total_price"] = "Rp. ".concat(
        totalPrice
      );
    }
  };
  //! Not use
  const calculateTotalPurchase = () => {
    const copyForm = { ...formValues };
    let resultSum = 0;
    copyForm[GLOBAL_NAME.ITEMS].forEach((item) => {
      resultSum += formatValueCurrencyToNumber(item.total_price);
    });
    copyForm["total_purchase"] = formatValueCurrencyToString(resultSum);
  };

  const formatValueCurrencyToString = (value) => {
    value = String(value);
    // console.log("in formater before", value);
    // Remove leading zeros, but allow a single "0"
    value = value.replace(/^0+(?=\d{0,})/, "0");

    // Use a regular expression to allow only digits
    value = value.replace(/\D/g, "");

    // Format the input value as a currency by adding commas every three digits
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // console.log("in formater after", value);
    return value;
  };

  const formatValueCurrencyToNumber = (value) => {
    if (typeof value === "number") return value;
    value = String(value);
    if (value.includes(",")) {
      value = value.replace(/,/g, "");
    }
    //* Parse string to number in other way
    return +value;
  };

  function handleFormModal(actionType = ACTION_TYPE.ADD) {
    setActionType(actionType);
    switch (actionType) {
      case ACTION_TYPE.DELETED:
        setTitle("Delete purchase request");
        setFormModal(true);
        break;
      case ACTION_TYPE.EDITED:
        setTitle("Edit purchase request");
        setIsLoadedFormValues(false);
        getPayloadEdit().then(() => {
          setFormModal(true);
          setIsLoadedFormValues(true);
        });
        break;
      case ACTION_TYPE.ADD:
        setTitle("Add purchase Request");
        setFormModal(true);
        break;
    }
  }

  const handleSubmit = (
    /** @type {import("react").SyntheticEvent} */ event
  ) => {
    event.preventDefault();
    if (actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
    } else if (actionType === ACTION_TYPE.DETAIL) {
      setConfirmModal(true);
      setMessageConfirm("You want to cancel the purchase request");
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
        } else if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
        }
      } else {
        //* When form invalid
        console.log("Invalid form", errors);
      }
    }
  };

  const handleAddItem = () => {
    console.log("Add item");
    const copyFormValues = { ...formValues };
    const copyErrors = { ...errors };
    const multipleFormValues = {};
    const newErrors = {};
    fields.forEach((field) => {
      let valueDefault = field.defaultValue;
      if (field.type === INPUT_TYPE.CURRENCY) {
        valueDefault = "Rp. ".concat(valueDefault);
      }
      if (field.multiple) {
        multipleFormValues[field.name] = valueDefault;
        newErrors[field.name] = "";
      }
    });

    copyFormValues[GLOBAL_NAME.ITEMS].push(multipleFormValues);
    errors[GLOBAL_NAME.ITEMS].push(newErrors);

    setFormValues(copyFormValues);
    setErrors(copyErrors);
  };

  const handleRemoveItem = (index) => {
    console.log("Remove item", index);
    const copyForm = { ...formValues };
    const copyError = { ...errors };

    copyForm[GLOBAL_NAME.ITEMS].splice(index, 1);
    copyError[GLOBAL_NAME.ITEMS].splice(index, 1);

    setFormValues(copyForm);
    setErrors(copyError);
  };

  //* ------------------------------- METHODS ------------------------------------ *//

  const manipulateItemsToStr = (/** @type {ItemsType} */ item) => {
    const listFormatField = ["price", "total_price"];
    Object.keys(item).forEach((key) => {
      if (listFormatField.includes(key)) {
        item[key] = "Rp. ".concat(
          formatValueCurrencyToString(String(item[key]))
        );
      }
    });
    return item;
  };

  const manipulateItemToNum = (/**@type {ItemsType} */ item) => {
    const listFormatField = ["price", "total_price"];
    Object.keys(item).forEach((key) => {
      if (listFormatField.includes(key)) {
        item[key] = formatValueCurrencyToNumber(item[key].substring(4));
      }
    });
    return item;
  };

  const manipulatedPayload = (values = formValues, type) => {
    //* Code here
    const manipulated = {
      desc: values["desc"],
    };
    manipulated[GLOBAL_NAME.ITEMS] =
      values[GLOBAL_NAME.ITEMS]?.map(manipulateItemToNum);
    manipulated["total_purchase"] = formatValueCurrencyToNumber(
      values["total_purchase"].substring(4)
    );
    return manipulated;
  };

  //* ------------------------------- EDIT SECTION ------------------------------------ *//

  const getPayloadEdit = (paramId) => {
    let detailRow;
    if (rows?.length > 0) {
      detailRow = rows.find((row) => selectedData[0] === row.id);
    } else {
      //* Code here
      detailRow = null;
    }

    if (detailRow || paramId) {
      // const { id } = detailRow;
      const id = selectedData[0] || paramId;
      setSelectedId(id);
      // setIsLoadedFormValues(false);
      // setFormModal(true);
      return axios
        .get(`purchase-requests/${id}/edit`, headersAPIToken(token))
        .then((response) => {
          if (response.status === 200 && response.data.status === true) {
            const result = setPayloadEdit(response.data.data);
            return new Promise((resolve) => {
              resolve(result);
            });
          }
        })
        .catch((error) => {
          console.error(`Failed get detail for edit data: ${error.message}`);
        });
    } else {
      return new Promise((resolve) => setTimeout(() => resolve(false), 100));
    }
  };

  const setPayloadEdit = (detailData) => {
    const newFormValues = {
      desc: detailData["desc"],
      items: detailData[GLOBAL_NAME.ITEMS],
    };

    newFormValues["total_purchase"] = "Rp. ".concat(
      formatValueCurrencyToString(String(detailData["total_purchase"]))
    );

    newFormValues[GLOBAL_NAME.ITEMS] =
      detailData[GLOBAL_NAME.ITEMS].map(manipulateItemsToStr);

    const itemAmount = newFormValues[GLOBAL_NAME.ITEMS].length;
    setErrors(initialErrors(itemAmount));
    setFormValues(newFormValues);
    setIsLoadedFormValues(true);
    // const v = newFormValues,
    //   e = initialErrors(itemAmount);
    // debugger;
    return newFormValues;
  };

  //* ----------------------------- DETAIL SECTION --------------------------------- *//

  const handleDetailPurchaseRequest = ({ row }) => {
    // console.log(row);
    const { id } = row;
    setTitle("Detail Purchase Request");
    dispatch(detailPurchaseRequest(token, id)).then(() => {
      setActionType(ACTION_TYPE.DETAIL);
      setFormModal(true);
    });
  };

  useEffect(() => {
    // setIsLoadedFormValues()
    if (detail !== null && isLoading === false) {
      setDetailPr(detail);

      console.log(detail);
    }
  }, [detail, isLoading]);
  //* =================================================================================*//
  //*                                  HANDLER REDUCER                                 *//
  //* =================================================================================*//

  const handleAddRow = () => {
    const payloadAdd = manipulatedPayload();
    setIsLoadedFormValues(false);
    dispatch(addPurchaseRequest(token, payloadAdd)).then(() => {
      //* Code here
      handleCloseFormModal(true);
      setIsLoadedFormValues(true);
      dispatch(
        getPurchaseRequest(token, {
          search,
          sortBy,
          sortDirection,
          status,
          limit,
          page,
        })
      );
    });
    // alert("Value Added");
    // console.log("Payload add", payloadAdd);
  };

  const handleEditRow = (optionalPayload, detailId = 0) => {
    const payloadEdit = manipulatedPayload(optionalPayload);
    //* handle when cancel purchase request
    if (actionType === ACTION_TYPE.DETAIL) {
      payloadEdit["status"] = "Canceled";
    }
    setIsLoadedFormValues(false);
    // debugger;
    dispatch(
      updatePurchaseRequest(token, payloadEdit, selectedId || detailId)
    ).then(() => {
      setSelectedId(null);
      handleCloseFormModal(true);
      setIsLoadedFormValues(true);
      setSelectedData([]); // disable action table
      if (rows.length) {
        dispatch(
          getPurchaseRequest(token, {
            sortBy,
            sortDirection,
            status,
            limit,
            page,
            search,
          })
        );
      }
    });
  };

  const handleDeleteRow = () => {
    const ids = [...selectedData];
    const joinedIds = ids.join(",");
    setFormModal(false);
    dispatch(deletePurchaseRequest(token, joinedIds));
  };

  const getDetailData = (id) => {
    setFormModal(true);
    setActionType(ACTION_TYPE.DETAIL);
    setTitle("Detail Purchase Requests");
    dispatch(detailPurchaseRequest(token, id));
  };

  const resetDetailReducer = () => ({
    type: DETAIL_PURCHASE_REQUEST_SUCCESS,
    payload: null,
  });
  //* =================================================================================*//
  //*                                     COMPONENTS                                   *//
  //* =================================================================================*//

  const ButtonAdd = () => {
    return (
      <Box sx={{ ...justifyContentEnd }}>
        <Button
          onClick={() => handleAddItem()}
          disabled={actionType === ACTION_TYPE.EDITED}
        >
          <span
            style={{
              fontSize: "1.5rem",
              marginRight: ".6rem",
              display: "inline-block",
            }}
          >
            +
          </span>
          Add More
        </Button>
      </Box>
    );
  };

  const ButtonRemove = ({ index }) => {
    return (
      <Grid grid item xs={2} style={{ paddingBlock: ".2rem" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={
            formValues[GLOBAL_NAME.ITEMS].length === 1 ||
            actionType === ACTION_TYPE.EDITED
          }
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            ...removeButton,
          }}
          onClick={() => handleRemoveItem(index)}
        >
          <img src={RemoveIcon} alt={`Remove item`} />
        </Button>
      </Grid>
    );
  };

  const LoadingComponent = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  );

  const ModifiedLeftToolbar = () => {
    let exportUrl = process.env.REACT_APP_API_URL.concat(
      `/api/purchase-requests/export`
    );
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      exportUrl += "?status=".concat(
        status == ACTION_TYPE.ALL_STATUS && status
      );
    }
    if (search && search !== "") {
      const isAlreadyQuery = exportUrl.includes("?");
      if (isAlreadyQuery) {
        exportUrl += "&search=".concat(search);
      } else {
        exportUrl += "?search=".concat(search);
      }
    }
    return (
      <span>
        <Button
          size="medium"
          color="primary"
          disabled={search === "" && status === ACTION_TYPE.DEFAULT_STATUS}
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            window.open(exportUrl, "_blank");
          }}
        >
          <img
            src={ExportIcon}
            style={{
              ...imgBtnToolbar,
              marginRight: 5,
            }}
          />
          Export
        </Button>
      </span>
    );
  };
  //* =================================================================================*//
  //*                                         MODAL                                    *//
  //* =================================================================================*//

  const RenderField = ({ localFormValues, field, index }) => {
    let localError = { ...errors };
    if (index !== null && index !== undefined) {
      localError = localError[GLOBAL_NAME.ITEMS][index];
    }
    // console.log({
    //   field: field.name,
    //   formValues: localFormValues[field.name],
    //   index,
    //   error: localError[field.name],
    // });

    switch (field.type) {
      case INPUT_TYPE.TEXT:
      case INPUT_TYPE.CURRENCY:
      case INPUT_TYPE.NUMBER:
        if (field.name === "total_purchase") {
          formValues[field.name] = totalPurchase;
        }
        return (
          <Box
            sx={{ marginBlock: ".3rem" }}
            width={field.type === INPUT_TYPE.NUMBER ? "5rem" : "inherit"}
          >
            <TextInput
              errors={localError}
              field={field}
              formValues={localFormValues}
              tip={""}
              showError={true}
              handleInputBlur={(e) => handleInputBlur(e, index)}
              handleInputChange={(e) => handleInputChange(e, index)}
            />
          </Box>
        );
      case INPUT_TYPE.TEXTAREA:
        return (
          <Box>
            <TextArea
              errors={localError}
              field={field}
              formValues={localFormValues}
              handleInputBlur={handleInputBlur}
              handleInputChange={handleInputChange}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const RenderFormField = () => {
    const nonMultipleField = fields.filter((field) => !field.multiple);
    return (
      <React.Fragment>
        {formValues[GLOBAL_NAME.ITEMS].map((_, index) => (
          <Grid container>
            <Grid
              item
              xs={10.5}
              style={{
                border: "1px solid #0005",
                borderRadius: "12px",
                margin: ".3rem",
                marginTop: ".5rem",
                paddingInline: ".8rem",
                paddingBlock: "1rem",
              }}
            >
              {fields.map((field) => {
                if (!field.multiple) return null;
                return RenderField({
                  localFormValues: formValues[GLOBAL_NAME.ITEMS][index],
                  field: field,
                  index: index,
                });
              })}
            </Grid>
            {/* --------------- REMOVE BUTTON ----------------- */}
            <Grid item xs={1}>
              <ButtonRemove index={index} />
            </Grid>
          </Grid>
        ))}
        {/* --------------- ADD BUTTON ----------------- */}
        {actionType !== ACTION_TYPE.EDITED && <ButtonAdd />}
        <Grid>
          {nonMultipleField.map((field) =>
            // <RenderField field={field} localFormValues={formValues} />
            RenderField({ localFormValues: formValues, field, index: null })
          )}
        </Grid>
      </React.Fragment>
    );
  };

  const RenderDetail = () => {
    //* Key = Render key, value = key data   */
    // const listRender1 = {
    //   "No. References": "no_references",
    //   "Request By": "request_by",
    //   "Create At": "created_at",
    // };
    // const listRender2 = {
    //   "Total Purchase": "total_purchase",
    //   Description: "desc",
    // };
    // const listRenderItem = {
    //   Category: "category",
    //   "Brand Name": "brand",
    //   "Item Name": "name",
    //   Quantity: "qty",
    //   "Unit Price": "price",
    //   "Total Price": "total_price",
    // };

    const styleGridElem = { marginBlock: ".5rem" };
    const styleTypography = { fontSize: "1rem" };
    const styleCenter = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const mapElement = ([key, value], index, status) => {
      return (
        <React.Fragment key={`${index}-${key}`}>
          <Grid item xs={4} style={styleGridElem}>
            <Typography variant="body1" style={styleTypography}>
              {key}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              ...styleGridElem,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" style={styleTypography}>
              {key === "Request By" ? value["fullname"] : value || "-"} &nbsp;
              {/* Position */}
              {key === "Request By" && (
                <span style={{ opacity: ".5", ...styleTypography }}>
                  as {value["job_level"]}
                </span>
              )}
            </Typography>
            {key === "No. Reference" && (
              <Typography style={styleTypography}>
                <StatusBadge status={status}>{status}</StatusBadge>
              </Typography>
            )}
          </Grid>
        </React.Fragment>
      );
    };

    const gridSizeNumber = 1;
    const mapItems = (item, indexItem) => {
      return (
        <React.Fragment key={indexItem}>
          <Grid
            item
            xs={gridSizeNumber}
            style={{
              ...styleGridElem,
              ...styleCenter,
            }}
          >
            <div
              style={{
                ...styleCenter,
                height: "1.6rem",
                width: "1.6rem",
                background: "#00f",
                color: "#fff",
                borderRadius: "50%",
              }}
            >
              {indexItem + 1}
            </div>
          </Grid>
          {Object.entries(item).map(([key, value], indexElem) => {
            return (
              <React.Fragment key={`${indexElem}-${indexItem}`}>
                {indexElem > 0 && <Grid item xs={gridSizeNumber}></Grid>}
                <Grid item xs={4} style={styleGridElem}>
                  <Typography variant="body1" style={styleTypography}>
                    {key}
                  </Typography>
                </Grid>
                <Grid item xs={7} style={styleGridElem}>
                  <Typography variant="body1" style={styleTypography}>
                    {value}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    };

    const excludeFields = ["id", "can_cancel", "status"];
    const status = detailPr["status"];

    const renderField = ([key, val], index) => {
      if (excludeFields.includes(key)) return null;
      if (Array.isArray(val) && val.length) {
        return val.map(mapItems);
      } else {
        return mapElement([key, val], index, status);
      }
    };

    return (
      <React.Fragment>
        {detailPr === null ? (
          <LoadingComponent />
        ) : (
          <Grid container>{Object.entries(detailPr).map(renderField)}</Grid>
        )}
      </React.Fragment>
    );
  };

  const renderFormContent = () => {
    if (!isLoadedFormValues) return <LoadingComponent />;
    switch (actionType) {
      case ACTION_TYPE.DELETED:
        return <ConfirmDelete />;
      case ACTION_TYPE.ADD:
      case ACTION_TYPE.EDITED:
        return RenderFormField();
      case ACTION_TYPE.DETAIL:
        return <RenderDetail />;
      default:
        return null;
    }
  };

  const renderActionButtons = () => {
    const textbuton = {
      DELETED: "Yes",
      DETAIL: "Cancel Purchase Request",
    };

    const ButtonCancel = () => (
      <Button
        variant="outlined"
        sx={{ ...btnWhite }}
        onClick={() => handleCloseFormModal(actionType === ACTION_TYPE.DETAIL)}
      >
        {actionType === ACTION_TYPE.DETAIL ? "Close" : "Cancel"}
      </Button>
    );
    const ButtonSubmit = () => (
      <Button
        color="primary"
        variant="contained"
        disableElevation
        type="submit"
      >
        {textbuton[actionType] || "Save"}
      </Button>
    );

    if (actionType === ACTION_TYPE.DETAIL) {
      return (
        <Box>
          {detailPr && detailPr["can_cancel"] ? (
            <ButtonSubmit />
          ) : (
            <ButtonCancel />
          )}
        </Box>
      );
    }
    return (
      <Box>
        <ButtonCancel />
        <ButtonSubmit />
      </Box>
    );
  };

  const handleCloseFormModal = (importan = false) => {
    if (importan) {
      setFormModal(false);
      resetFormValues();
      if (actionType === ACTION_TYPE.DETAIL) {
        dispatch(resetDetailReducer());
        setDetailPr(null);
      }
    } else {
      if (isFormFilled()) {
        setConfirmModal(true);
      } else {
        setFormModal(false);
      }
    }
  };

  const onConfirmModal = () => {
    setFormModal(false);
    setConfirmModal(false);
    //* Cancel Purhcase request
    if (actionType === ACTION_TYPE.DETAIL) {
      const { id } = detailPr;
      setSelectedId(id);
      setIsLoadedFormValues(false);
      getPayloadEdit(id).then((result) => {
        handleEditRow(result, id);
      });
    } else {
      resetFormValues();
    }
  };

  const onCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  //* =================================================================================*//
  //*                                   HANDLER PAGING                                 *//
  //* =================================================================================*//

  useEffect(() => {
    if (!search && search !== "") {
      // console.log({ search });
      _search();
    }
  }, [search]);

  useEffect(() => {
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getPurchaseRequest(token, {
          search,
          sortBy,
          sortDirection,
          status,
          limit,
          page,
        })
      );
    }
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getPurchaseRequest(token, {
          sortBy,
          sortDirection,
          status,
          limit,
          page,
          search,
        })
      );
      setChoosedStatus(status);
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }, [status]);

  const handleKeyPress = (/** @type {KeyboardEvent} */ event) => {
    if (event.key === "Enter" && search) {
      _search();
    }
  };

  function handlePageChange(newPage) {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  }
  function handleLimitChange(newLimit) {
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  }
  function handleSortModelChange(newSortModel) {
    setSortModel(newSortModel);
  }

  function handleSelectionChange(selection) {
    setSelectedData(selection || []);
  }

  function _getByLimit(paramLimit) {
    dispatch(
      getPurchaseRequest(token, {
        sortBy,
        sortDirection,
        status,
        limit: paramLimit,
        page,
        search,
      })
    );
  }

  function _getByPage(pageParam) {
    dispatch(
      getPurchaseRequest(token, {
        sortBy,
        search,
        sortDirection,
        status,
        limit,
        page: pageParam,
      })
    );
  }
  function _search() {
    dispatch(
      getPurchaseRequest(token, {
        sortBy,
        sortDirection,
        status: status === ACTION_TYPE.DEFAULT_STATUS ? null : status,
        limit,
        page,
        search,
      })
    );
    if (search !== "") {
      setChoosedStatus("Search");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }

  function _status(status) {
    setStatus(status);
  }

  //* =================================================================================*//
  //*                                      WATCHER                                     *//
  //* =================================================================================*//

  useEffect(onMount, []);

  // useEffect(() => {
  //   setChoosedStatus(status);
  // }, [status]);

  // useEffect(() => {
  //   console.log("This is formValues", formValues);
  // }, [formValues]);

  // useEffect(() => {
  //   console.log("This is errors", errors);
  // }, [errors]);

  // useEffect(() => {
  //   console.log("Total purchase:", totalPurchase);
  // }, [totalPurchase]);

  //* =================================================================================*//
  //*                                    DUMMY DATA                                    *//
  //* =================================================================================*//

  const dummyRows = [
    {
      id: 1,
      no_references: "A0001",
      status: "Pending",
      position: "Staff",
      request_by: "Alex",
      createAt: "12/02/2024",
      total_purchase: 5000,
      note: "",
      item: [
        {
          category: "",
          brand_name: "",
          item_name: "",
          quantity: 1,
          unit_price: 5000,
          total_price: 5000,
        },
      ],
    },
    {
      id: 2,
      no_references: "A0002",
      status: "Pending",
      position: "Staff",
      request_by: "Alex",
      createAt: "12/02/2024",
      total_purchase: 10000,
      note: "",
      item: [
        {
          category: "",
          brand_name: "",
          item_name: "",
          quantity: 2,
          unit_price: 5000,
          total_price: 10000,
        },
      ],
    },
    {
      id: 3,
      no_references: "A0003",
      status: "Pending",
      position: "Staff",
      request_by: "Alex",
      createAt: "12/02/2024",
      total_purchase: 50000,
      note: "",
      item: [
        {
          category: "",
          brand_name: "",
          item_name: "",
          quantity: 2,
          unit_price: 5000,
          total_price: 10000,
        },
        {
          category: "",
          brand_name: "",
          item_name: "",
          quantity: 4,
          unit_price: 10000,
          total_price: 40000,
        },
      ],
    },
  ];

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                     SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                  onKeyDown: handleKeyPress,
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Canceled">Canceled</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Purchase Request"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={choosedStatus}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            componentModifyToolbarLeft={ModifiedLeftToolbar}
            // userAccess={userAccess}
            handleRowDoubleClick={handleDetailPurchaseRequest}
          />
        </CardContent>
      </Card>

      <Modal open={formModal} onClose={() => {}}>
        <Form
          title={title}
          onCloseModal={() =>
            handleCloseFormModal(actionType === ACTION_TYPE.DETAIL)
          }
          elementForm={renderFormContent}
          elementActionButton={renderActionButtons}
          onSubmit={handleSubmit}
        />
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={onCloseConfirmModal}
        onCloseModal={onConfirmModal}
        message={messageConfirm}
      ></ModalConfirmCancel>
    </>
  );
};
export default PurchaseRequest;
