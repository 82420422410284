import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  TextField,
  Button,
  FormGroup,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Switch,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Checkbox,
  InputAdornment,
  Tooltip,
  TextareaAutosize,
  RadioGroup,
  Radio,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import {
  SELECT_OPTION,
  INPUT_TYPE,
  localToUTC,
  makeLocalAppearUTC,
  formatDate,
} from "../util/function";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DocumentIcon from "./../assets/document.png";
import CloudIcon from "./../assets/cloud.png";
import ViewIcon from "./../assets/eye.png";
import DeleteIcon from "./../assets/delete-red.png";
import MinusIcon from "./../assets/minus-blue.svg";
import {
  borderRadius,
  btnWhite,
  modal,
  modalActionButton,
  modalHeader,
  drawerHeader,
  form,
  formDrawer,
  formGroup,
  drawer,
  StyledTextArea,
  inputDate,
  fourColor,
  alignItemsCenter,
  thirdColor,
  secondColor,
} from "../util/style";

/* -------------------------------------------------------------------------- */
/*                            Set Locale & Timezone                           */
/* -------------------------------------------------------------------------- */
import "dayjs/locale/en";
import "dayjs/locale/id"; // Import the Indonesian locale
import "dayjs/plugin/timezone"; // Import the timezone plugin
import axios, { headersAPIToken } from "../core/axios_config";
import { useSelector } from "react-redux";
import { field_modal } from "../pages/finance/proforma-invoice/parts/imutable_state";
import { AxiosError } from "axios";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
// Set the locale to Indonesian
dayjs.locale("id");
// dayjs.locale("en");
// Set the default timezone to 'Asia/Jakarta'
dayjs.tz.setDefault("Asia/Jakarta");
// Check the current locale
const currentLocale = dayjs.locale();
const currentTimezone = dayjs.tz.guess();
console.log("currentTimezone", currentTimezone); // This will print the current timezone, e.g., 'Asia/Jakarta'
console.log("currentLocale", currentLocale);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 58,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2.5,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(27px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 25,
    height: 25,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Form = ({
  onCloseModal,
  title,
  subtitle = null,
  elementForm,
  elementActionButton,
  elementOtherActionButton,
  onSubmit,
  workingWithDrawer = false,
  elementHeaderActionButton = null,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          ...(workingWithDrawer ? drawer : modal),
        }}
      >
        <Box
          sx={{
            ...(workingWithDrawer ? drawerHeader : modalHeader),
          }}
        >
          <Box sx={{ display: "grid" }}>
            <Typography variant="h5" component="div" style={{ fontWeight: "bold" }}>
              {title}
            </Typography>
            {subtitle && <Typography variant="body2">{subtitle}</Typography>}
          </Box>

          <Box sx={{ display: "flex" }}>
            {elementHeaderActionButton && elementHeaderActionButton()}
            <IconButton edge="end" color="inherit" onClick={onCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ ...(workingWithDrawer ? formDrawer : form) }}>{elementForm()}</Box>
        <Box
          sx={{
            ...modalActionButton,
            ...(workingWithDrawer && {
              position: "absolute",
              bottom: 0,
              width: "-webkit-fill-available",
              width: "-moz-available",
              width: "93%",
            }),
          }}
        >
          <Box>{elementOtherActionButton && elementOtherActionButton()}</Box>
          {elementActionButton()}
        </Box>
      </Box>
    </form>
  );
};
export default Form;

const ErrorIcon = () => <ErrorOutlineIcon style={{ color: "red", marginRight: "8px" }} />;

// TextInput Component
const TextInput = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  showError = true,
  tip,
  unitTypeIsOn = false,
}) => {
  const hasError = !!errors[field.name];

  const [unit, setUnit] = useState(formValues["net_weight_type"] || "kg");

  useEffect(() => {
    if (unitTypeIsOn) {
      //     if(field.type === INPUT_TYPE.UNIT_TYPE) {
      handleInputChange({ target: { name: "net_weight_type", value: unit } });
      //     }
    }
  }, [unit, unitTypeIsOn]);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  if (!field.additionalProps) {
    field.additionalProps = {}; // create additionalProps object if it doesn't exist
  }

  if (field.additionalProps?.showLable === undefined) {
    field.additionalProps.showLable = true; // set showLable to true if undefined
  }

  if (field.additionalProps?.showError === undefined) {
    field.additionalProps.showError = true; // set showLable to true if undefined
  }

  if (field.additionalProps?.readOnly === undefined) {
    field.additionalProps.readOnly = false;
  }

  const formatAndValidateInput = (value, fieldType) => {
    if (fieldType === INPUT_TYPE.CURRENCY) {
      // Remove leading zeros, but allow a single "0"
      value = value.replace(/^0+(?=\d{0,})/, "0");

      // Use a regular expression to allow only digits
      value = value.replace(/\D/g, "");

      // Format the input value as a currency by adding commas every three digits
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (fieldType === INPUT_TYPE.POINT) {
      // Define the regular expression pattern
      const pattern = /^[\d,]*$/; // Use * to allow zero or more digits and commas

      // Test if the value matches the pattern
      if (!pattern.test(value)) {
        return null; // Invalid value
      }
    } else if (fieldType === INPUT_TYPE.NUMBER) {
      // Use a regular expression to allow only digits
      value = value.replace(/\D/g, "");
    } else if (fieldType === INPUT_TYPE.UNIT) {
      // Use a regular expression to allow only digits
      value = value ? value.replace(/\D/g, "") : value;
    } else if (fieldType === INPUT_TYPE.PHONE_NUMBER) {
      // Check if it's a valid phone number format
      if (!/^\+?(62)?[0-9]*$/.test(value)) {
        return null; // Invalid value
      }
    } else if (fieldType === INPUT_TYPE.DISCOUNT) {
      // Use a regular expression to allow only digits
      value = value.replace(/\D/g, "");

      // Ensure the value is between 0 and 100
      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue) || numericValue < 0) {
        value = "0";
      } else if (numericValue > 100) {
        value = "100";
      }
    }

    return value;
  };

  const onInputChange = (e) => {
    const { value, name } = e.target;
    const charCode = e.which ? e.which : e.keyCode;

    // Check if the entered key is a number or not
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault(); // Prevent input of non-numeric characters
      return;
    }

    const formattedValue = formatAndValidateInput(value, field.type);

    if (formattedValue !== null) {
      e.target.value = formattedValue;
      handleInputChange({ target: { name, value: formattedValue } });
    }
  };

  const onInputBlur = (e) => {
    const { value, name } = e.target;
    const charCode = e.which ? e.which : e.keyCode;

    // Check if the entered key is a number or not
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault(); // Prevent input of non-numeric characters
      return;
    }

    const formattedValue = formatAndValidateInput(value, field.type);

    if (formattedValue !== null) {
      e.target.value = formattedValue;
      handleInputBlur({ target: { name, value: formattedValue } });
    }
  };

  const inputProps = {
    fullWidth: true,
    placeholder: field.placeholder ? field.placeholder : `Input ${field.label}`,
    margin: "normal",
    size: "small",
    variant: "outlined",
    value: formValues[field.name],
    name: field.name,
    type:
      field.type === INPUT_TYPE.CURRENCY ||
      field.type === INPUT_TYPE.POINT ||
      field.type === INPUT_TYPE.UNIT
        ? INPUT_TYPE.TEXT
        : field.type,
    onChange: onInputChange,
    onKeyUp: onInputChange,
    onBlur: onInputBlur,
    error: hasError,
    helperText:
      field.additionalProps && field.additionalProps.showError && errors[field.name],
    ...(field.additionalProps || {}),
    sx: {
      ...formGroup,
      backgroundColor:
        field.additionalProps && field.additionalProps.disabled ? "#F5F5F5" : "#FFFFFF",
    },
    InputProps: {
      ...(field.additionalProps && field.additionalProps.readOnly
        ? { readOnly: true }
        : {}),
      startAdornment: field?.startIcon ? (
        <InputAdornment position="start">{field.startIcon}</InputAdornment>
      ) : null,
      endAdornment: (
        <InputAdornment position="end">
          <>
            {/* OLD */}
            {/* {hasError ?  <ErrorIcon /> : null} */}
            {/* New */}
            {field.type === INPUT_TYPE.UNIT && (
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={unit}
                sx={{
                  "& fieldset": {
                    border: "unset",
                  },
                  borderLeft: `2px solid #ddd`,
                }}
                onChange={handleUnitChange}
                disabled={field.additionalProps.disabled}
              >
                <MenuItem value="kg">kg</MenuItem>
                <MenuItem value="g">g</MenuItem>
                <MenuItem value="mg">mg</MenuItem>
                <MenuItem value="l">l</MenuItem>
                <MenuItem value="ml">ml</MenuItem>
              </TextField>
            )}
            {hasError ? field.additionalProps?.inputGroup ? null : <ErrorIcon /> : null}
            {field.additionalProps?.inputGroup && (
              <Button
                variant="contained"
                disableElevation
                startIcon={<img src={field.additionalProps.inputGroup?.icon} />}
                onClick={field.additionalProps.inputGroup?.handleAction || undefined}
              >
                {field.additionalProps.inputGroup?.textContent}
              </Button>
            )}
            {field?.endIcon && <Box sx={{ p: 1 }}>{field?.endIcon}</Box>}
          </>
        </InputAdornment>
      ),
    },
    id: field.name,
  };

  if (field.type === INPUT_TYPE.NUMBER) {
    // Add the onKeyPress event for number fields
    inputProps.onKeyPress = (e) => {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    };
  }

  return (
    <React.Fragment key={field.name}>
      {field.additionalProps && field.additionalProps.showLable && (
        <InputLabel>{field.label}</InputLabel>
      )}
      <TextField {...inputProps} />
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
    </React.Fragment>
  );
};

const TextArea = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  placeholder,
  showError = true,
  tip,
}) => {
  const hasError = !!errors[field.name];
  const isShowError = !!showError;

  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <StyledTextArea
        rows={field?.rowsMin || 3} // You can adjust the number of rows as needed
        placeholder={field.placeholder ? field.placeholder : `Input ${field.label}`}
        value={formValues[field.name]}
        name={field.name}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        error={hasError}
        {...(field.additionalProps || {})}
        sx={{
          width: `calc(100% - 18px)`,
          ...formGroup,
        }}
        InputProps={{
          startAdornment: field?.startIcon ? (
            <InputAdornment position="start">{field.startIcon}</InputAdornment>
          ) : null,
          endAdornment: hasError ? <ErrorIcon /> : null,
        }}
        id={field.name}
      />
      {tip && !hasError && <FormHelperText>{tip}</FormHelperText>}
      {hasError && isShowError && (
        <FormHelperText error>{errors[field.name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

// SelectOne Component
const SelectOne = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  fieldOptions,
  tip,
}) => {
  const hasError = !!errors[field.name];

  if (!field.additionalProps) {
    field.additionalProps = {}; // create additionalProps object if it doesn't exist
  }

  if (field.additionalProps?.showLable === undefined) {
    field.additionalProps.showLable = true; // set showLable to true if undefined
  }

  if (field.additionalProps?.showError === undefined) {
    field.additionalProps.showError = true; // set showLable to true if undefined
  }

  return (
    <React.Fragment key={field.name}>
      <Select
        fullWidth
        margin="normal"
        size="small"
        variant="outlined"
        value={formValues[field.name]}
        name={field.name}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        error={hasError}
        //         helperText={errors[field.name]}
        helperText={
          field.additionalProps && field.additionalProps.showError && errors[field.name]
        }
        {...(field.additionalProps || {})}
        sx={{ ...formGroup }}
        // endAdornment={hasError ? <ErrorIcon /> : null}
        endAdornment={hasError ? <></> : null}
        id={field.name}
      >
        {fieldOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {field.additionalProps && field.additionalProps.showError && errors[field.name] && (
        <FormHelperText error>{errors[field.name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

// SelectOneAutocomplete Component
const SelectOneAutocomplete = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  fieldOptions,
  showError = true,
  tip,
}) => {
  //   debugger;

  // const ff = field;
  // const vv = formValues;
  // debugger;

  const hasError = !!errors[field.name];
  const isShowError = !!showError || field.additionalProps.showError;

  if (!field.additionalProps) {
    field.additionalProps = {}; // create additionalProps object if it doesn't exist
  }

  if (field.additionalProps?.showLable === undefined) {
    field.additionalProps.showLable = true; // set showLable to true if undefined
  }

  if (field.additionalProps?.showError === undefined) {
    field.additionalProps.showError = true; // set showLable to true if undefined
  }

  return (
    <React.Fragment key={field.name}>
      {field.additionalProps && field.additionalProps.showLable && (
        <InputLabel>{field.label}</InputLabel>
      )}
      <Autocomplete
        options={fieldOptions}
        getOptionLabel={(option) => option.name || ""}
        value={
          fieldOptions.find((option) => option.id === formValues[field.name]) ||
          // -1 old
          null
        }
        onBlur={(event, newValue) =>
          handleInputBlur({
            target: {
              name: field.name,
              value: newValue?.id || null, // -1 old
            },
          })
        }
        onChange={(event, newValue) =>
          handleInputChange({
            target: {
              name: field.name,
              value: newValue?.id || null, // -1 old
            },
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor:
                field.additionalProps && field.additionalProps.disabled
                  ? "#F5F5F5"
                  : "#FFFFFF",
            }}
            placeholder={
              field.placeholder
                ? field.placeholder
                : `Select ${String(field.label).toLowerCase()}`
            } // Add the placeholder text here
            error={hasError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {/* {hasError ? <ErrorIcon color="error" /> : null} */}
                    {hasError ? <></> : null}
                    {params.InputProps?.endAdornment}
                  </>
                </InputAdornment>
              ),
            }}
          />
        )}
        sx={{ ...formGroup }}
        {...(field.additionalProps || {})}
        id={field.name}
      ></Autocomplete>
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {field.additionalProps &&
        field.additionalProps.showError &&
        hasError &&
        isShowError && <FormHelperText error>{errors[field.name]}</FormHelperText>}
    </React.Fragment>
  );
};

// SelectOneCountryAutocomplete Component
const SelectOneCountryAutocomplete = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  fieldOptions,
  showError = true,
  tip,
}) => {
  const hasError = !!errors[field.name];
  const isShowError = !!showError || field.additionalProps.showError;

  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <Autocomplete
        sx={{ width: 300 }}
        options={fieldOptions}
        autoHighlight
        getOptionLabel={(option) => option.name || ""}
        value={
          fieldOptions.find((option) => option.id === formValues[field.name]) ||
          // -1 old
          null
        }
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.name} ({option.code})
          </Box>
        )}
        onBlur={(event, newValue) =>
          handleInputBlur({
            target: {
              name: field.name,
              value: newValue?.id || null,
            },
          })
        }
        onChange={(event, newValue) =>
          handleInputChange({
            target: {
              name: field.name,
              value: newValue?.id || null,
            },
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder={
              field.placeholder
                ? field.placeholder
                : `Select ${String(field.label).toLowerCase()}`
            }
            error={hasError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {hasError ? <></> : null}
                    {params.InputProps?.endAdornment}
                  </>
                </InputAdornment>
              ),
            }}
          />
        )}
        sx={{ ...formGroup }}
        {...(field.additionalProps || {})}
        id={field.name}
      />
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {hasError && isShowError && (
        <FormHelperText error>{errors[field.name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

const SelectMultiple = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  fieldOptions,
  tip,
}) => {
  const hasError = !!errors[field.name];

  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <Select
        fullWidth
        multiple
        margin="normal"
        size="small"
        variant="outlined"
        value={formValues[field.name]}
        name={field.name}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        error={hasError}
        helperText={errors[field.name]}
        {...(field.additionalProps || {})}
        sx={{ ...formGroup }}
        // endAdornment={hasError ? <ErrorIcon /> : null}
        endAdornment={hasError ? <></> : null}
        id={field.name}
      >
        {fieldOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues[field.name]?.includes(option.id) || false}
                />
              }
              label={option.name}
            />
          </MenuItem>
        ))}
      </Select>
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {hasError && <FormHelperText error>{errors[field.name]}</FormHelperText>}
    </React.Fragment>
  );
};

const SelectOneAutocompleteSearch = ({
  title,
  url,
  handleInputChange,
  name,
  disabled,
  manipulateResponse,
  errors,
  isUseId = false,
  isUseTitle = true,
  value = null,
}) => {
  const hasError = !!errors[name];
  const { token } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (value) {
      var selectedData = suggestedItems.find((option) => option.id === value);
      if (selectedData) {
        setSelectedTerm(selectedData.name);
      }
    }
  });

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchData();
    }, 300);

    return () => {
      clearTimeout(delay);
    };
  }, [searchTerm]);

  const handleSelected = () => {
    if (isShow) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    console.log(suggestion);
    handleSelected();
    setSelectedTerm(suggestion.name);
    setSearchTerm("");
    setSuggestedItems([]);
    handleInputChange({
      target: {
        name: name,
        value: suggestion.id,
      },
    });
  };

  const fetchData = async () => {
    try {
      var response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const modifiedData = response.data.data.map((item) => {
        if (item.hasOwnProperty("fullname")) {
          return {
            ...item,
            name: item.fullname,
          };
        } else {
          return item;
        }
      });
      const newmodifiedData = modifiedData.map((item) => {
        if (item.hasOwnProperty("created_by") && !isUseId) {
          return {
            ...item,
            id: item.created_by,
          };
        } else {
          return item;
        }
      });
      const newmodifiedData2 = newmodifiedData.map((item) => {
        if (item.hasOwnProperty("request_number")) {
          return {
            ...item,
            id: item.request_number,
            name: item.request_number,
          };
        } else {
          return item;
        }
      });
      const newModifiedData3 = newmodifiedData2.map((item) => {
        if (item.hasOwnProperty("approval_id")) {
          return {
            ...item,
            id: item.code,
            name: item.code,
          };
        } else {
          return item;
        }
      });

      // for sugest proforma-invouices in invoice page
      const newModiedData4 = newModifiedData3.map((item) => {
        if (url.includes("proforma-invoices?client_id=")) {
          item["name"] = item?.code;
          return item;
        } else {
          return item;
        }
      });

      let modifiedPayload = newModiedData4;
      if (manipulateResponse && typeof manipulateResponse === "function") {
        modifiedPayload = response.data.data.map(manipulateResponse);
      }

      if (searchTerm) {
        const filteredList = modifiedPayload.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSuggestedItems(filteredList);
      } else {
        setSuggestedItems(modifiedPayload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSuggestedItems([]);
    fetchData();
  }, [isShow]);

  return (
    <div>
      <Typography>{isUseTitle ? title : ""}</Typography>
      <TextField
        fullWidth
        placeholder={`Search ${title}`}
        size="small"
        disabled={disabled}
        error={hasError}
        sx={{
          backgroundColor: disabled ? "#F5F5F5" : "#FFFFFF",
        }}
        value={selectedTerm}
        onClick={() => handleSelected()}
      />
      {isShow && (
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            zIndex: 1,
            width: "90%",
            padding: "8px",
            maxHeight:'300px',
            overflow:'auto'
          }}
        >
          <TextField
            fullWidth
            placeholder={`Search ${title}`}
            size="small"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            error={errors.name}
            sx={{ borderRadius }}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <List>
            {suggestedItems.length < 1 && (
              <ListItem>
                <ListItemText primary="No Data Found" />
              </ListItem>
            )}
            {suggestedItems.map((suggestion) => (
              <ListItem
                key={suggestion.id}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {
                  // suggestion.request_date ?
                  //   <ListItemText primary={`${suggestion.name.replace("-", "")}\nRequest date: ${formatDate(suggestion.request_date)}`} /> :
                  <ListItemText>
                    <Typography>{suggestion.name.replace("-", "")}</Typography>
                    {suggestion.request_date && (
                      <Typography>
                        Request date: {formatDate(suggestion.request_date)}
                      </Typography>
                    )}
                  </ListItemText>
                }
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

//* Independen component that can CURD options
const SelectOneAutocompleteSearchManageble = ({
  title,
  url,
  handleInputChange,
  name,
  disabled,
  errors,
  field,
  formValues,
  additionalProps,
}) => {
  const hasError = !!errors[name];
  const { token } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [value, setValue] = useState(formValues[field.name]);

  const [newOption, setNewOption] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setValue(value);
    setSearchTerm(value);
  };

  useEffect(() => {
    if (!additionalProps) {
      additionalProps = {};
    }
    if (
      value !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE &&
      value !== undefined &&
      suggestedItems.length < 1
    ) {
      console.log("Fetching when value is exist");
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (
      (value !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE || value !== undefined) &&
      suggestedItems.length > 0
    ) {
      suggestedItems.forEach((item) => {
        if (item.id == value) {
          setSelectedTerm(item.name);
        }
      });
    }
  }, [suggestedItems, value]);

  useEffect(() => {
    let delay;
    if (searchTerm !== "") {
      delay = setTimeout(() => {
        fetchData();
      }, 500);
    }

    return () => {
      clearTimeout(delay);
    };
  }, [searchTerm]);

  const handleSelected = () => {
    if (isShow) {
      console.log("Satu");
      setIsShow(false);
    } else if (!isAdd) {
      console.log("Dua");
      setIsShow(true);
    } else {
      //* Case when is add is "true", set is off
      setIsAdd(false);
    }
  };

  const handleSuggestionClick = (
    /** @type {import("react").SyntheticEvent} */ e,
    suggestion
  ) => {
    e.stopPropagation();
    handleSelected();
    setSelectedTerm(suggestion.name);
    setSearchTerm("");
    setSuggestedItems([]);
    handleInputChange({
      target: {
        name: name,
        value: suggestion.id,
      },
    });
  };

  const handleInputChangeForm = (event) => {
    const { value } = event.target;
    setNewOption(value);
  };

  const handleAddAction = () => {
    setIsShow(false);
    setIsAdd(true);
    setSelectedTerm("");
    setSearchTerm("");
    setSuggestedItems([]);
  };
  const handleAddOption = () => {
    addData();
  };
  const handleDeleteOption = (
    /** @type {import("react").SyntheticEvent} */ event,
    id
  ) => {
    event.stopPropagation();
    deleteOption(id)
      .then(() => {
        fetchData();
      })
      .catch(handleError);
  };

  // useEffect(() => {
  //   console.log({ suggestedItems, isShow });
  // }, [suggestedItems, isShow]);

  const fetchData = async () => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let payloadData = response?.data?.data;

      if (searchTerm) {
        const filteredList = payloadData.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSuggestedItems(filteredList);
      } else {
        setSuggestedItems(payloadData);
      }

      return new Promise((resolve) => resolve(payloadData));
    } catch (error) {
      console.log(error);
    }
  };

  const addData = () => {
    const payload = { name: newOption, ...additionalProps.optionPayload };
    let splitedUrl = url.split("?");
    const craftedEndpoint = splitedUrl[0];
    console.log(additionalProps);
    return axios
      .post(craftedEndpoint, payload, headersAPIToken(token))
      .then((response) => {
        if (response.status === 201 && response.data.status === true) {
          setNewOption("");
          setIsAdd(false);
          fetchData();
        }
      })
      .catch(handleError);
  };

  const deleteOption = (id) => {
    const splitedUrl = url.split("?");
    let craftedUrl = splitedUrl[0];
    // console.log({ splitedUrl, craftedUrl });
    return axios
      .delete(craftedUrl.concat("/", id), headersAPIToken(token))
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          //* code when success delete
        } else {
          //* code when fail delete
        }
      })
      .catch(handleError);
  };

  const handleError = (/** @type {AxiosError} */ error) => {
    let messageError = "";
    const method = error?.config?.method || "GET";
    if (error.response !== undefined && error.response.data !== undefined) {
      messageError = error.response.data.message;
    } else {
      messageError = error.message;
    }
    console.error(`Error while ${method} - ${messageError}`);
  };

  useEffect(() => {
    setSuggestedItems([]);
    if (isShow) {
      fetchData().then((response) => {
        if (!response.length && isShow === true && isAdd === false) {
          setIsShow(false);
          setIsAdd(true);
        }
      });
    }
  }, [isShow, isAdd]);

  return (
    <div>
      <InputLabel>{title}</InputLabel>
      <TextField
        fullWidth
        placeholder={`Search ${title}`}
        size="small"
        disabled={disabled}
        error={hasError}
        sx={{
          backgroundColor: disabled ? "#F5F5F5" : "#FFFFFF",
        }}
        value={selectedTerm}
        onClick={() => handleSelected()}
      />
      {isShow && (
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            zIndex: 1,
            width: "90%",
            padding: "8px",
          }}
        >
          <TextField
            fullWidth
            placeholder={`Search ${title}`}
            size="small"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            error={errors.name}
            sx={{ borderRadius }}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <List>
            {suggestedItems.length < 1 && (
              <ListItem>
                <ListItemText primary="No Data Found" />
              </ListItem>
            )}
            {suggestedItems.map((suggestion) => (
              <ListItem
                key={suggestion.id}
                onClick={(e) => handleSuggestionClick(e, suggestion)}
                sx={{ "&:hover": { background: "#f8fafc" } }}
              >
                {
                  // suggestion.request_date ?
                  //   <ListItemText primary={`${suggestion.name.replace("-", "")}\nRequest date: ${formatDate(suggestion.request_date)}`} /> :
                  <ListItemText>
                    <Typography>{suggestion.name.replace("-", "")}</Typography>
                    {suggestion.request_date && (
                      <Typography>
                        Request date: {formatDate(suggestion.request_date)}
                      </Typography>
                    )}
                  </ListItemText>
                }
                <Typography
                  onClick={(e) => handleDeleteOption(e, suggestion.id)}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  {/* {MinusIcon} */}
                  <img src={MinusIcon} alt="delete" style={{ fill: "#00f" }} />
                </Typography>
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "#00f",
                textDecoration: "underline",
                textUnderlineOffset: ".2rem",
                fontWeight: 500,
                "&:hover": {
                  cursor: "pointer",
                  color: "#00c",
                },
              }}
              onClick={handleAddAction}
            >
              Add {field.label}
            </Typography>
          </Box>
        </Paper>
      )}
      {isAdd && (
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            zIndex: 1,
            width: "90%",
            padding: "8px",
          }}
        >
          {suggestedItems.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                paddingBlock: ".5rem",
              }}
            >
              <Button onClick={() => setIsAdd(false)}>Cancel</Button>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              placeholder={`Input ${title}`}
              size="small"
              // InputProps={{
              //   startAdornment: <SearchIcon />,
              // }}
              error={errors.name}
              sx={{ borderRadius, width: "90%" }}
              value={newOption}
              onChange={handleInputChangeForm}
            />
            <Button onClick={handleAddOption}>Add</Button>
          </Box>
        </Paper>
      )}
      {field.additionalProps && field.additionalProps.showError && hasError && (
        <FormHelperText error sx={{ fontSize: "14px" }}>
          {errors[field.name]}
        </FormHelperText>
      )}
    </div>
  );
};

// SelectMultipleAutocomplete Component
const SelectMultipleAutocomplete = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  fieldOptions,
  tip,
}) => {
  const valueArray = Array.isArray(formValues[field.name])
    ? formValues[field.name]
    : [formValues[field.name]];

  const hasError = !!errors[field.name];

  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <Autocomplete
        multiple
        options={fieldOptions}
        getOptionLabel={(option) => option.name || ""}
        value={valueArray
          .map((id) => fieldOptions.find((option) => option.id === id))
          .filter(Boolean)}
        onBlur={(event, newValue) =>
          handleInputBlur({
            target: {
              name: field.name,
              value: newValue?.map((option) => option.id) || [], // Map to array of selected option IDs
            },
          })
        }
        onChange={(event, newValue) =>
          handleInputChange({
            target: {
              name: field.name,
              value: newValue?.map((option) => option.id) || [], // Map to array of selected option IDs
            },
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder={
              field.placeholder ? field.placeholder : `Select ${field.label} ...`
            } // Add the placeholder text here
            error={hasError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {/* {hasError ? <ErrorIcon color="error" /> : null} */}
                    {hasError ? <></> : null}
                    {params.InputProps?.endAdornment}
                  </>
                </InputAdornment>
              ),
            }}
          />
        )}
        {...(field.additionalProps || {})}
        sx={{
          ...formGroup,
          backgroundColor:
            field.additionalProps && field.additionalProps.disabled
              ? "#F5F5F5"
              : "#FFFFFF",
        }}
        id={field.name}
      ></Autocomplete>
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {hasError && <FormHelperText error>{errors[field.name]}</FormHelperText>}
    </React.Fragment>
  );
};

const PasswordInputWithValidation = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  tip,
}) => {
  const [password, setPassword] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPopoverContentHovered, setIsPopoverContentHovered] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !isPopoverContentHovered
      ) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverContentHovered]);

  const isPasswordValid = () => {
    // Implement your password validation logic here.
    // You can check the password length, uppercase, lowercase, numbers, special characters, etc.
    return (
      password.length >= 8 &&
      containsLowerCase() &&
      containsUpperCase() &&
      containsNumber()
    );
  };

  const containsLowerCase = () => {
    const lowerCaseLetters = /[a-z]/;
    return lowerCaseLetters.test(password);
  };

  const containsUpperCase = () => {
    const upperCaseLetters = /[A-Z]/;
    return upperCaseLetters.test(password);
  };

  const containsNumber = () => {
    const numbers = /[0-9]/;
    return numbers.test(password);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setShowPopover(true);
    if (isPasswordValid()) {
      setShowPopover(false);
    }
    handleInputChange(event); // Pass the event to the form component's handleInputChange
  };

  const handlePasswordBlur = (event) => {
    handleInputBlur(event);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePopoverContentHover = (isHovered) => {
    setIsPopoverContentHovered(isHovered);
  };

  const hasError = !!errors[field.name];

  return (
    <React.Fragment key={field.name}>
      <div style={{ position: "relative" }}>
        <InputLabel>{field.label}</InputLabel>
        <TextField
          fullWidth
          margin="normal"
          name={field.name}
          type={showPassword ? "text" : "password"}
          size="small"
          variant="outlined"
          placeholder={field.placeholder || "Input Password"}
          onChange={handlePasswordChange}
          onKeyUp={handlePasswordChange}
          onBlur={handlePasswordBlur}
          error={hasError}
          helperText={errors[field.name]}
          {...(field.additionalProps || {})}
          sx={{ ...formGroup }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {/* {isPasswordValid() ? (
                  <Tooltip title="Password is valid">
                    <CheckCircleOutlineIcon color="success" />
                  </Tooltip>
                ) : hasError ? (
                  <ErrorIcon />
                ) : null} */}
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          id={field.name}
        />
        {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
        {showPopover && (
          <div
            ref={popoverRef}
            style={{
              // position: "fixed",
              position: "absolute",
              top: "70px",
              background: "rgb(0 0 0 / 92%)",
              color: "rgb(255, 255, 255)",
              padding: "8px",
              borderRadius: "8px",
              minWidth: "200px",
              zIndex: 9999,
            }}
            onMouseEnter={() => handlePopoverContentHover(true)}
            onMouseLeave={() => handlePopoverContentHover(false)}
          >
            <div>
              Password must contain the following:
              <div style={{ display: "flex", alignItems: "center" }}>
                {containsLowerCase() ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
                <span style={{ marginLeft: "8px" }}>A lowercase letter</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {containsUpperCase() ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
                <span style={{ marginLeft: "8px" }}>A capital letter</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {containsNumber() ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
                <span style={{ marginLeft: "8px" }}>A number</span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {password.length >= 8 ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
                <span style={{ marginLeft: "8px" }}>Minimum 8 characters</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const CheckboxGroupInput = ({
  field,
  formValues,
  errors,
  handleInputBlur,
  handleInputChange,
  fieldOptions,
}) => {
  const { label, name, options } = field;
  const handleChange = (value) => {
    const updatedValues = formValues[name].includes(value)
      ? formValues[name].filter((val) => val !== value)
      : [...formValues[name], value];
    debugger;
    handleInputChange({
      target: { name, value: updatedValues, checked: !formValues[name].includes(value) },
    });
  };

  //   const handleSelectAll = () => {
  //     const allSelected = formValues[name].length === fieldOptions.length;
  //     setFormValues({
  //       ...formValues,
  //       [name]: allSelected ? [] : fieldOptions.map((option) => option.id),
  //     });
  //   };

  if (!field.additionalProps) {
    field.additionalProps = {}; // create additionalProps object if it doesn't exist
  }

  if (field.additionalProps?.showLable === undefined) {
    field.additionalProps.showLable = true; // set showLable to true if undefined
  }

  if (field.additionalProps?.showError === undefined) {
    field.additionalProps.showError = true; // set showLable to true if undefined
  }

  if (field.additionalProps?.styleFlexWrapTwoColumns === undefined) {
    field.additionalProps.styleFlexWrapTwoColumns = false; // set showLable to true if undefined
  }

  if (field.additionalProps?.isHaveSelectAllOptions === undefined) {
    field.additionalProps.isHaveSelectAllOptions = false; // set showLable to true if undefined
  }

  return (
    <FormGroup
      className={`horizontal-checkbox-group ${errors[name] ? "error-border" : ""}`}
    >
      {field.additionalProps && field.additionalProps.showLable && (
        <FormLabel>{label}</FormLabel>
      )}
      <div
        className={`${
          field.additionalProps.styleFlexWrapTwoColumns
            ? "checkbox-container-flex-wrap-to-columns"
            : "checkbox-container"
        }`}
      >
        {field.additionalProps.isHaveSelectAllOptions && (
          <FormControlLabel
            className="form-control-label full-width-checkbox"
            control={
              <Checkbox
                name="select-all"
                //               checked={formValues[name].length === fieldOptions.length}
                //               onChange={handleSelectAll}
                checked={formValues[name].includes("Select All")}
                onChange={() => handleChange("Select All")}
                id="select-all"
                disabled={field.additionalProps?.disabled}
              />
            }
            label="Select All"
          />
        )}
        {fieldOptions.map((option, index) => (
          <FormControlLabel
            key={option.id}
            className="form-control-label"
            control={
              <Checkbox
                name={name}
                checked={formValues[name].includes(option.id)}
                onChange={() => handleChange(option.id)}
                onBlur={handleInputBlur}
                id={name}
                disabled={field.additionalProps?.disabled}
              />
            }
            label={option.name}
          />
        ))}
      </div>
      {field.additionalProps && field.additionalProps.showError && errors[name] && (
        <FormHelperText error>{errors[name]}</FormHelperText>
      )}
    </FormGroup>
  );
};

const RadioGroupInput = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur, // Pass the handleInputBlur function as a prop
  fieldOptions,
}) => {
  const { label, name, options } = field;

  if (!field.additionalProps) {
    field.additionalProps = {};
  }

  const handleChange = (value) => {
    handleInputChange({ target: { name, value } });
  };

  const handleRadioGroupBlur = () => {
    handleInputBlur({ target: { name } });
  };

  return (
    <FormGroup className="horizontal-radio-group" onBlur={handleRadioGroupBlur}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup className="radio-container">
        {fieldOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id} // Assuming option.id is a number
            control={<Radio disabled={field.additionalProps.disabled} />}
            label={option.name}
            checked={formValues[name] == option.id}
            onChange={() => handleChange(option.id)}
            id={option.name}
          />
        ))}
      </RadioGroup>
      {errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormGroup>
  );
};

const DatePickerInput = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  showError = true,
  tip,
}) => {
  // debugger;
  const hasError = !!errors[field.name];
  const isShowError = !!showError;

  const handleChange = (date) => {
    console.log(date, "date");
    handleInputChange({
      target: {
        name: field.name,
        value: localToUTC(date),
      },
    });
  };

  const handleBlur = (date) => {
    handleInputBlur({
      target: {
        name: field.name,
        value: localToUTC(date),
      },
    });
  };

  const CustomOpenPickerButton = ({ onClick }) => {
    return (
      <IconButton onClick={onClick} size="medium" variant="text" id="open-picker-button">
        {field.endIcon}
      </IconButton>
    );
  };
  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <DatePicker
          value={
            dayjs(formValues[field.name]).isValid() ? dayjs(formValues[field.name]) : null
          }
          onChange={handleChange}
          format={field.formatDate}
          components={{
            OpenPickerButton: field?.endIcon && CustomOpenPickerButton,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={hasError} // Set the error state
              helperText={errors[field.name]} // Set the error message
              onBlur={handleBlur}
            />
          )}
          slotProps={{
            textField: {
              error: hasError || false, // Set the error state
              ...(field.additionalProps || {}),
            },
          }}
          sx={{
            ...formGroup,
            ...inputDate,
            backgroundColor:
              field.additionalProps && field.additionalProps.disabled
                ? "#F5F5F5"
                : "#FFFFFF",
          }}
          error={hasError}
          {...(field.additionalProps || {})}
        />
      </LocalizationProvider>
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {hasError && isShowError && (
        <FormHelperText error>{errors[field.name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

const TimePickerInput = ({
  field,
  formValues,
  errors,
  handleInputChange,
  handleInputBlur,
  ampm = false,
  tip,
}) => {
  const hasError = !!errors[field.name];

  const handleChange = (time) => {
    handleInputChange({
      target: {
        name: field.name,
        value: time,
      },
    });
  };

  const handleBlur = (time) => {
    handleInputBlur({
      target: {
        name: field.name,
        value: time,
      },
    });
  };

  return (
    <React.Fragment key={field.name}>
      <InputLabel>{field.label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={
            dayjs(formValues[field.name]).isValid() ? dayjs(formValues[field.name]) : null
          }
          ampm={ampm}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={hasError} // Set the error state
              helperText={errors[field.name]} // Set the error message
              onBlur={handleBlur}
            />
          )}
          sx={{
            ...formGroup,
            ...inputDate,
            backgroundColor:
              field.additionalProps && field.additionalProps.disabled
                ? "#F5F5F5"
                : "#FFFFFF",
          }} // You can adjust the styling here
          error={hasError}
          {...(field.additionalProps || {})}
        />
      </LocalizationProvider>
      {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
      {errors[field.name] && <FormHelperText error>{errors[field.name]}</FormHelperText>}
    </React.Fragment>
  );
};

const FileSelect = ({}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("Selected file:", file);
    }
  };

  const handleFileDisplayClick = (event) => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <InputLabel>Upload File</InputLabel>
      <div style={{ margin: ".5rem 0" }}>
        <input
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          name="file_input"
          style={{ display: "none" }}
        />
        <Box
          sx={{
            cursor: "pointer",
            padding: "1rem",
            background: "#f7f7f7",
            borderRadius: "6px",
            border: "1px dashed #999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
          onClick={handleFileDisplayClick}
        ></Box>
      </div>
    </React.Fragment>
  );
};

const FilePicker = ({
  field,
  formValues,
  errors,
  tip,
  onFileSelect,
  handleInputChange,
  indexFile,
  isModeEdit = null,
  URIForViewDocument = undefined,
}) => {
  //   debugger;
  // console.log("BISSAAAA");
  // console.log(formValues, "editt");
  // console.log(errors, "errors");
  // console.log({ errors, tip, formValues, indexFile });

  if (!field.hasOwnProperty("required")) {
    field.required = false;
  }

  if (!field.hasOwnProperty("disabled")) {
    field.disabled = false;
  }

  function fileToObject(file) {
    if (file) {
      // Check if the file object has properties like name, size, type, lastModified
      if ("name" in file && "size" in file && "type" in file && "lastModified" in file) {
        return {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          // Add other properties as needed
        };
      }
      // Check if the file object has properties like filename, size
      else if ("filename" in file && "size" in file) {
        return {
          name: file.filename, // Assuming you want to use 'name' property in the returned object
          size: file.size,
          // Add other properties as needed
        };
      } else {
        return file;
      }
    } else {
      return file || null;
    }
  }

  const [selectedFile, setSelectedFile] = useState(fileToObject(formValues || null));
  const newFormValues = fileToObject(formValues);
  // console.log("BAAAAAAAAAAAAAMMMMMMMMMMMM");
  // // console.log(selectedFile);
  // console.log(isModeEdit, "isModeEdit");

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    // debugger;
    if (file) {
      setSelectedFile(file);
      // if (isModeEdit) isModeEdit(false);

      if (onFileSelect) {
        onFileSelect(file);
      }

      if (handleInputChange) {
        handleInputChange(event);
      }
    }
  };

  const formatSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  const handleFileDisplayClick = (event) => {
    // Programmatically trigger the file input when the file information is clicked
    fileInputRef.current.click();

    // Prevent the click event from further propagation (e.g., to parent elements)
    // event.stopPropagation();
  };

  const handleDeleteClick = () => {
    setSelectedFile(null);
    // Assuming you also want to clear the form values when DeleteIcon is clicked
    if (handleInputChange) {
      handleInputChange({ target: { name: field.name, value: null } });
    }
  };

  return (
    <React.Fragment>
      <div className="input-file">
        <InputLabel>{field.label}</InputLabel>
        <div style={{ margin: ".5rem 0" }}>
          <input
            type="file"
            // accept={field.allowedExtensions.join(",")}
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
            ref={fileInputRef}
            name={field.name}
          />
          <div
            style={{
              cursor: "pointer",
              padding: "1rem",
              background: "#f7f7f7",
              borderRadius: "6px",
              border: "1px dashed #999",
              display: "flex",
              alignItems: "center",
              justifyContent: selectedFile ? "flex-start" : "center", // Correctly set justifyContent
              gap: "0.5rem",
            }}
            // OLD : onClick={handleFileDisplayClick}
            onClick={field.disabled === false ? handleFileDisplayClick : undefined}
            id="div-file-input"
          >
            {formValues !== null &&
            typeof formValues === "object" &&
            Object.entries(formValues).length > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    justifyContent: selectedFile ? "flex-start" : "center", // Correctly set justifyContent
                  }}
                >
                  <img src={DocumentIcon} alt="Document Icon" />
                  <span>{formValues.filename}</span>
                  <span style={{ textTransform: "uppercase", color: fourColor }}>
                    {formatSize(formValues.size)}
                  </span>
                </div>

                <div onClick={(e) => e.stopPropagation()}>
                  {URIForViewDocument && (
                    <a
                      href={URIForViewDocument}
                      onClick={(e) => e.stopPropagation()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={ViewIcon} alt="Cloud Icon" />
                    </a>
                  )}
                  {/*<div
                    onClick={handleDeleteClick}
                  >
                    <img src={DeleteIcon} alt="Delete Icon" />
                  </div>*/}
                </div>
              </div>
            ) : selectedFile ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    justifyContent: selectedFile ? "flex-start" : "center", // Correctly set justifyContent
                  }}
                >
                  <img src={DocumentIcon} alt="Document Icon" />
                  <span>{newFormValues?.name || selectedFile.name}</span>
                  <span style={{ textTransform: "uppercase", color: fourColor }}>
                    {formatSize(newFormValues?.size || selectedFile.size)}
                  </span>
                </div>

                {/*<div onClick={(e) => e.stopPropagation()}>
                  <div
                    onClick={handleDeleteClick}
                  >
                    <img src={DeleteIcon} alt="Delete Icon" />
                  </div>
                </div>
                */}
              </div>
            ) : (
              <>
                <img src={CloudIcon} alt="Cloud Icon" />
                <span>
                  Drop or browse file {field.required === false ? "( Ops )" : ""}
                </span>
              </>
            )}
          </div>
        </div>

        {tip && <FormHelperText sx={{ margin: "-3px 0 .4rem" }}>{tip}</FormHelperText>}
        {isNaN(indexFile) === false ? (
          <FormHelperText error>{errors[field.name][indexFile]}</FormHelperText>
        ) : (
          errors[field.name] && (
            <FormHelperText error>{errors[field.name]}</FormHelperText>
          )
        )}
      </div>
    </React.Fragment>
  );
};

export {
  DatePickerInput,
  TimePickerInput,
  RadioGroupInput,
  CheckboxGroupInput,
  TextInput,
  TextArea,
  SelectOne,
  SelectOneAutocomplete,
  SelectOneAutocompleteSearch,
  SelectOneCountryAutocomplete,
  SelectOneAutocompleteSearchManageble,
  SelectMultiple,
  SelectMultipleAutocomplete,
  PasswordInputWithValidation,
  FilePicker,
  FileSelect,
};

/* -------------------------------------------------------------------------- */
/*                                     OLD                                    */
/* -------------------------------------------------------------------------- */

// import React from "react";
// import {
//   Box,
//   Typography,
//   IconButton,
//   Divider,
//   TextField,
//   Button,
//   FormControlLabel,
//   InputLabel,
//   Switch,
//   Select,
//   MenuItem,
//   FormHelperText,
//   Autocomplete,
//   Checkbox,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { styled } from "@mui/material/styles";
// import { SELECT_OPTION } from "../util/function";
// import {
//   btnWhite,
//   modal,
//   modalActionButton,
//   modalHeader,
//   form,
//   formGroup,
// } from "../util/style";

// const IOSSwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 58,
//   height: 30,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2.5,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(27px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.primary.main,
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 25,
//     height: 25,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 30 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));

// const Form = ({
//   onCloseModal,
//   title,
//   elementForm,
//   elementActionButton,
//   onSubmit,

// }) => {
//   return (
//     <form onSubmit={onSubmit}>
//       <Box
//         sx={{
//           ...modal,
//         }}
//       >
//         <Box
//           sx={{
//             ...modalHeader,
//           }}
//         >
//           <Typography
//             variant="h5"
//             component="div"
//             style={{ fontWeight: "bold" }}
//           >
//             {title}
//           </Typography>
//           <IconButton edge="end" color="inherit" onClick={onCloseModal}>
//             <CloseIcon />
//           </IconButton>
//         </Box>
//         <Divider />
//         <Box sx={{ ...form }}>{elementForm()}</Box>
//         <Box
//           sx={{
//             ...modalActionButton,
//           }}
//         >
//           {elementActionButton()}
//         </Box>
//       </Box>
//     </form>
//   );
// };
// export default Form;

// // TextInput Component
// const TextInput = ({
//   field,
//   formValues,
//   errors,
//   handleInputChange,
//   handleInputBlur,

// }) => {
//   return (
//     <React.Fragment key={field.name}>
//       <InputLabel>{field.label}</InputLabel>
//       <TextField
//         fullWidth
//         placeholder={`Input ${field.label}`}
//         margin="normal"
//         size="small"
//         variant="outlined"
//         value={formValues[field.name]}
//         name={field.name}
//         type={field.type}
//         onChange={handleInputChange}
//         onKeyUp={handleInputBlur}
//         onBlur={handleInputBlur}
//         error={!!errors[field.name]}
//         helperText={errors[field.name]}
//         {...(field.additionalProps || {})}
//         sx={{ ...formGroup }}
//       />
//     </React.Fragment>
//   );
// };

// // SelectOne Component
// const SelectOne = ({
//   field,
//   formValues,
//   errors,
//   handleInputChange,
//   handleInputBlur,
//   fieldOptions,

// }) => {
//   return (
//     <React.Fragment key={field.name}>
//       <Select
//         fullWidth
//         margin="normal"
//         size="small"
//         variant="outlined"
//         value={formValues[field.name]}
//         name={field.name}
//         onChange={handleInputChange}
//         onBlur={handleInputBlur}
//         error={!!errors[field.name]}
//         helperText={errors[field.name]}
//         {...(field.additionalProps || {})}
//         sx={{ ...formGroup }}
//       >
//         <MenuItem value={SELECT_OPTION.DEFAULT}>Choose Option</MenuItem>
//         {fieldOptions.map((option) => (
//           <MenuItem key={option.id} value={option.id}>
//             {option.name}
//           </MenuItem>
//         ))}
//       </Select>
//       {errors[field.name] && (
//         <FormHelperText error>{errors[field.name]}</FormHelperText>
//       )}
//     </React.Fragment>
//   );
// };

// // SelectOneAutocomplete Component
// const SelectOneAutocomplete = ({
//   field,
//   formValues,
//   errors,
//   handleInputChange,
//   handleInputBlur,
//   fieldOptions,

// }) => {
//   return (
//     <React.Fragment key={field.name}>
//       <InputLabel>{field.label}</InputLabel>
//       <Autocomplete
//         options={fieldOptions}
//         getOptionLabel={(option) => option.name || ""}
//         value={
//           fieldOptions.find((option) => option.id === formValues[field.name]) ||
//           -1
//         }
//         onBlur={(event, newValue) =>
//           handleInputBlur({
//             target: {
//               name: field.name,
//               value: newValue?.id || -1,
//             },
//           })
//         }
//         onChange={(event, newValue) =>
//           handleInputChange({
//             target: {
//               name: field.name,
//               value: newValue?.id || -1,
//             },
//           })
//         }
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             variant="outlined"
//             size="small"
//             placeholder={`Select ${field.label}...`} // Add the placeholder text here
//           />
//         )}
//         sx={{ ...formGroup }}
//       ></Autocomplete>
//       {errors[field.name] && (
//         <FormHelperText error>{errors[field.name]}</FormHelperText>
//       )}
//     </React.Fragment>
//   );
// };

// const SelectMultiple = ({
//   field,
//   formValues,
//   errors,
//   handleInputChange,
//   handleInputBlur,
//   fieldOptions,

// }) => {
//   return (
//     <React.Fragment key={field.name}>
//       <InputLabel>{field.label}</InputLabel>
//       <Select
//         fullWidth
//         multiple
//         margin="normal"
//         size="small"
//         variant="outlined"
//         value={formValues[field.name]}
//         name={field.name}
//         onChange={handleInputChange}
//         onBlur={handleInputBlur}
//         error={!!errors[field.name]}
//         helperText={errors[field.name]}
//         {...(field.additionalProps || {})}
//         sx={{ ...formGroup }}
//       >
//         {fieldOptions.map((option) => (
//           <MenuItem key={option.id} value={option.id}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={formValues[field.name]?.includes(option.id) || false}
//                 />
//               }
//               label={option.name}
//             />
//           </MenuItem>
//         ))}
//       </Select>
//       {errors[field.name] && (
//         <FormHelperText error>{errors[field.name]}</FormHelperText>
//       )}
//     </React.Fragment>
//   );
// };

// // SelectMultipleAutocomplete Component
// const SelectMultipleAutocomplete = ({
//   field,
//   formValues,
//   errors,
//   handleInputChange,
//   handleInputBlur,
//   fieldOptions,

// }) => {
//   const valueArray = Array.isArray(formValues[field.name])
//     ? formValues[field.name]
//     : [formValues[field.name]];

//   return (
//     <React.Fragment key={field.name}>
//       <InputLabel>{field.label}</InputLabel>
//       <Autocomplete
//         multiple
//         options={fieldOptions}
//         getOptionLabel={(option) => option.name || ""}
//         value={valueArray
//           .map((id) => fieldOptions.find((option) => option.id === id))
//           .filter(Boolean)}
//         onBlur={(event, newValue) =>
//           handleInputBlur({
//             target: {
//               name: field.name,
//               value: newValue?.map((option) => option.id) || [], // Map to array of selected option IDs
//             },
//           })
//         }
//         onChange={(event, newValue) =>
//           handleInputChange({
//             target: {
//               name: field.name,
//               value: newValue?.map((option) => option.id) || [], // Map to array of selected option IDs
//             },
//           })
//         }
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             variant="outlined"
//             size="small"
//             placeholder={`Select ${field.label}...`} // Add the placeholder text here
//           />
//         )}
//         sx={{ ...formGroup }}
//       ></Autocomplete>
//       {errors[field.name] && (
//         <FormHelperText error>{errors[field.name]}</FormHelperText>
//       )}
//     </React.Fragment>
//   );
// };

// export {
//   TextInput,
//   SelectOne,
//   SelectOneAutocomplete,
//   SelectMultiple,
//   SelectMultipleAutocomplete,
// };
