import {
    Tooltip,
    Grid,
    Divider,
    Button,
    MenuItem,
    Card,
    CardContent,
    CardHeader,
    Typography,
    TextField,
    Modal,
    Box,
    InputLabel,
    FormControlLabel,
    FormControl,
    Select,
    Checkbox,
    IconButton,
    Popover,
    Paper,
    Autocomplete,
  } from "@mui/material";
  import successIMG from "./../../../../assets/success.png";

const Signature = ({ companyName, isMarked, signatureText }) => (
    <Box sx={{ textAlign: "center", width: "100%", borderRadius: "8px", p: 1 }}>
      <Typography variant="subtitle1">{companyName}</Typography>
      <Box sx={{ height: "60px" }}>
        {isMarked && <img src={successIMG} width="55px" />}
      </Box>
      <Typography sx={{ fontWeight: "500" }}>
        {signatureText
          ? signatureText
          : "(................................................)"}
      </Typography>
    </Box>
  );

  export default Signature