import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_EMPLOYEE_RESIGNATION_SUCCESS,
  GET_EMPLOYEE_RESIGNATION_FAILED,
  ADD_EMPLOYEE_RESIGNATION_SUCCESS,
  ADD_EMPLOYEE_RESIGNATION_FAILED,
  EDIT_EMPLOYEE_RESIGNATION_SUCCESS,
  EDIT_EMPLOYEE_RESIGNATION_FAILED,
  DELETE_EMPLOYEE_RESIGNATION_FAILED,
  DELETE_EMPLOYEE_RESIGNATION_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getEmployeResignation = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "employe-resigns",
          page,
          limit,
          search,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getEmployeResignationSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getEmployeResignationSuccess([], null));
        } else {
          dispatch(getEmployeResignationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getEmployeResignationFailed(error.response.data.message));
          }
        } else {
          dispatch(getEmployeResignationFailed(error.message));
        }
      });
  };
};

const getEmployeResignationSuccess = (data, pagination) => {
  return {
    type: GET_EMPLOYEE_RESIGNATION_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getEmployeResignationFailed = (errorMessage) => {
  return {
    type: GET_EMPLOYEE_RESIGNATION_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */
const transformToJson = (inputJson) => {
  const transformedJson = {
    user_id: inputJson['Employee Information'].user_id,
    effective_date: inputJson['Employee Information'].effective_date,
    requested_at: inputJson['Employee Information'].requested_at,
    reason_id: inputJson['Employee Information'].reason_id,
    notes: inputJson["Employee Information"].notes,
    attachments: inputJson.Attachment.attachment,
  };

  return transformedJson;
};

export const addEmployeResignation = (token, formData) => {
  delete formData.id
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "employe-resigns",
        transformToJson(formData),
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for file uploads
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "employee resignation"));
          dispatch(addEmployeResignationSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addEmployeResignationFailed(response.data.message));
        } else {
          dispatch(addEmployeResignationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addEmployeResignationFailed(error.response.data.message));
          }
        } else {
          dispatch(addEmployeResignationFailed(error.message));
        }
      });
  };
};

const addEmployeResignationSuccess = (message) => {
  return {
    type: ADD_EMPLOYEE_RESIGNATION_SUCCESS,
    payload: message,
  };
};

const addEmployeResignationFailed = (message) => {
  return {
    type: ADD_EMPLOYEE_RESIGNATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editEmployeResignation = (token, formData, id) => {
  debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        `employe-resigns/${id}`,
        transformToJson(formData),
        {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "employee resignation"));
          dispatch(editEmployeResignationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editEmployeResignationFailed(response.data.message));
        } else {
          dispatch(editEmployeResignationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editEmployeResignationFailed(error.response.data.message));
          }
        } else {
          dispatch(editEmployeResignationFailed(error.message));
        }
      });
  };
};

const editEmployeResignationSuccess = (message) => {
  return {
    type: EDIT_EMPLOYEE_RESIGNATION_SUCCESS,
    payload: message,
  };
};

const editEmployeResignationFailed = (message) => {
  return {
    type: EDIT_EMPLOYEE_RESIGNATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteEmployeResignation = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`employe-resigns${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "employee resignation"));
          dispatch(deleteEmployeResignationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteEmployeResignationFailed(response.data.message));
        } else {
          dispatch(deleteEmployeResignationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteEmployeResignationFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteEmployeResignationFailed(error.message));
        }
      });
  };
};

const deleteEmployeResignationSuccess = (message) => {
  return {
    type: DELETE_EMPLOYEE_RESIGNATION_SUCCESS,
    payload: message,
  };
};

const deleteEmployeResignationFailed = (message) => {
  return {
    type: DELETE_EMPLOYEE_RESIGNATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
