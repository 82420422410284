export const GET_SALES_ORDER_SUCCESS = "GET_SALES_ORDER_SUCCESS";
export const GET_SALES_ORDER_FAILED = "GET_SALES_ORDER_FAILED";
export const ADD_SALES_ORDER_SUCCESS = "ADD_SALES_ORDER_SUCCESS";
export const ADD_SALES_ORDER_FAILED = "ADD_SALES_ORDER_FAILED";
export const EDIT_SALES_ORDER_SUCCESS = "EDIT_SALES_ORDER_SUCCESS";
export const EDIT_SALES_ORDER_FAILED = "EDIT_SALES_ORDER_FAILED";
export const DELETE_SALES_ORDER_SUCCESS = "DELETE_SALES_ORDER_SUCCESS";
export const DELETE_SALES_ORDER_FAILED = "DELETE_SALES_ORDER_FAILED";
export const GET_SALES_ORDER_DETAILS_SUCCESS = "GET_SALES_ORDER_DETAILS_SUCCESS";
export const GET_SALES_ORDER_DETAILS_FAILED = "GET_SALES_ORDER_DETAILS_FAILED";
