import { REQUEST_LOADING } from "../../../../util/global_state";
import {
    CLEAR_TRADEMARK_REGISTRATION_UPDATE_ID,
    GET_TRADEMARK_REGISTRATION_FAILED,
    GET_TRADEMARK_REGISTRATION_SUCCESS,
    SET_TRADEMARK_REGISTRATION_UPDATE_ID,
    UPDATE_TRADEMARK_REGISTRATION_FAILED,
    UPDATE_TRADEMARK_REGISTRATION_SUCCESS,
  } from "./state";

const initialState = {
  isLoading: false,
  pagination: null,
  data: null,
  detail: null,
  message: null,
  updateId: null,
};

const trademarkRegistrationReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TRADEMARK_REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        pagination,
      };
    case SET_TRADEMARK_REGISTRATION_UPDATE_ID:
      return {
        ...state,
        updateId: payload,
      };
    case CLEAR_TRADEMARK_REGISTRATION_UPDATE_ID:
      return {
        ...state,
        updateId: null,
      };
    case GET_TRADEMARK_REGISTRATION_FAILED:
    case UPDATE_TRADEMARK_REGISTRATION_SUCCESS:
    case UPDATE_TRADEMARK_REGISTRATION_FAILED:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default trademarkRegistrationReducer