import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
  AttachmentItem,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../../components/Form";
import { getDetailInvoice, setEditId } from "./clients/actions";
import {
  ACTION_TYPE,
  SALES_ORDER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackIcon from "./../../../assets/back.svg";
import ZoomInIcon from "./../../../assets/zoom-in.png";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import PrinterGrayIcon from "./../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../assets/download-gray.png";
import ZoomOutIcon from "./../../../assets/zoom-out.png";
import logo from "./../../../logo.png";
import boderImg from "./../../../assets/border.png";
import DocumentDetail from "./component/details/DocumentDetail";
import DocumentPreview from "./component/details/DocumentPreview";
import RenderBottomAction from "../proforma-invoice/components/detail/BottomAction";
import DocumentPrint from "./component/details/DocumentPrint";
import ApprovalForm from "./component/forms/ApprovalForm";
import ArrowDown from "./../../../assets/arrow-down.svg";
import { manipulatePaylaodDetail } from "./parts/imutable_state";
function DetailInvoice() {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  // const useStyles = defaultStylePage;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#e1e1e1",
      overflow: "hidden",
      position: "relative !important",
    },
    logo: {
      position: "absolute",
      width: "100%",
    },
    header: {
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    footer: {
      padding: "20px",
      backgroundColor: "#09345C",
      borderRadius: "0 0 10px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  const [title, setTitle] = useState("Detail Invoice");
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const { token } = useSelector((state) => state.auth);
  const params = useParams();
  const [id, setId] = useState(parseInt(params.id));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [documentTitle, setDocumentTitle] = useState(document.title);
  const documentPreviewForPrintRef = useRef(null);
  const [formApproval, setFormApproval] = useState(false);
  const [height, setHeight] = useState(0);
  const [options, setOptions] = useState({});
  const [isDoneFetchHeightDocumentPreview, setIsDoneFetchHeightDocumentPreview] =
    useState(false);

  const [selectedIdInvoice, setSelectedIdInvoice] = useState(null);
  const [detailDocument, setDetailDocument] = useState({
    product: {
      status: "Pending",
      no_proforma: "12782",
      create_at: new Date(),
      no_reference: "123321",
      service_category: "Patent",
      created_by: "Json miranti",
      currency: "Rp",
      sub_service: [
        { name: "Patent Pecahan", id: 2, price: 2_000_000 },
        { name: "Patent Sederhana", id: 4, price: 4_000_000 },
      ],
      price: 12323121,
      discount: 2_000_000,
      vat: 50_000,
      total: 2536273,
      description:
        "Lorem ipsum dolor sit amet consectetur. Auctor dolor pellentesque viverra enim posuere elementum iaculis a",
      remark: "Microsoft Office",
    },
    bank: {
      acount_name: "eric",
      acount_address: "Jl. Gatot",
      acount_no: 1232321,
      bank_name: "Bank XYZ",
      bank_address: "Jalan Sudirman",
      payment_receive: 99999999,
      payment_conversion_receive: 990099,
    },
    client: {
      bill_to: "Andre",
      attn: "Avon product",
      bill_address: "Jl. Tomang No. 123 Jakarta 456",
    },
    Accounting: "Markus jhonson",
  });
  const { data, isLoading } = useSelector((state) => state.financeInvoiceDetail);

  useEffect(() => {
    if (id) {
      setSelectedIdInvoice(parseInt(id));
      getDetailData(id);
    }
  }, [id]);

  const getDetailData = (detailId) => {
    dispatch(getDetailInvoice(token, detailId));
  };

  const listOption = [
    {
      name: "sub_services",
      apiEndpoint: "/master/sub-services",
    },
  ];

  useEffect(() => {
    const getOptions = async (endpont, name) => {
      try {
        const { data } = await axios.get(endpont, headersAPIToken(token));
        setOptions((prev) => ({ ...prev, [name]: data.data }));
      } catch (error) {
        console.error(`failed get option: ${error}`);
      }
    };

    listOption.forEach((item) => {
      if (!options.hasOwnProperty(item.name)) {
        getOptions(item.apiEndpoint, item.name);
      }
    });
  }, [options]);

  const handlePrintDocument = () => {
    window.print();
  };

  const handleBackPage = () => {
    navigate("/finance/invoice");
  };

  const handleDownloadPdf = (e) => {
    e.preventDefault();
    window.open(
      process.env.REACT_APP_API_URL.concat(
        "/api/invoices/",
        selectedIdInvoice,
        "/download-pdf"
      ),
      "_blank"
    );
  };

  const handleEditBtn = () => {
    dispatch(setEditId(selectedIdInvoice));
    navigate(`/finance/invoice?edit=${selectedIdInvoice}`);
  };

  const handleActionBtn = () => {
    setFormApproval(true);
    // alert(detailDocument["product"]["status"]);
  };

  useEffect(() => {
    if (data) {
      const manipulated = manipulatePaylaodDetail(data);
      setDetailDocument(manipulated);
    }
  }, [data]);

  const handleBeforePrint = () => {
    setDocumentTitle(document.title);
    document.title = `Invoice : ${detailDocument["product"]["No. Proforma Invoice"]}`;
  };

  // useEffect(() => {
  //   console.log(detailDocument);
  // }, [detailDocument]);

  const handleAfterPrint = () => {
    document.title = documentTitle || "PPM Website"; // Replace with your original title
  };

  useEffect(() => {
    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, [detailDocument]);

  // Function to set height if the ref is initialized
  const setHeightIfRefExists = () => {
    if (documentPreviewForPrintRef?.current) {
      setHeight(documentPreviewForPrintRef.current.clientHeight);
    }
  };

  // Check if the ref exists and then set the height
  useEffect(() => {
    if (!isLoading && data !== null) {
      setTimeout(() => {
        console.log("timeout");
        setHeightIfRefExists();
        setIsDoneFetchHeightDocumentPreview(true);
      }, 2000);
    }
  }, [dispatch, isLoading, data, documentPreviewForPrintRef]); // Dependency array to watch for changes in the ref

  const BottomAction = () => {
    const [expandAction, setExpandAction] = useState(false);
    return (
      <Box
        sx={{
          display: "flex",
          marginInline: "1rem",
          marginBlock: ".5rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: ".5rem", marginInline: "1rem" }}>
          <Button
            variant="outlined"
            sx={{
              ...btnLightBlue,
            }}
            style={{ borderRadius: "7px" }}
            disableElevation
            className="not-effect"
            onClick={handlePrintDocument}
            disabled={!detailDocument["others"]?.canDownload}
          >
            <img
              src={PrinterGrayIcon}
              style={{ marginRight: "5px", margin: "4px" }}
              alt="Printer Icon"
            />
            Print
          </Button>
          <Button
            variant="outlined"
            sx={{
              ...btnLightBlue,
            }}
            disableElevation
            className="not-effect"
            disabled={!detailDocument["others"]?.canDownload}
            // onClick={() => {
            //   window.open(downloadURL, "_blank");
            // }}
            onClick={handleDownloadPdf}
          >
            <img
              src={DownloadGrayIcon}
              style={{ marginRight: "5px", margin: "4px" }}
              alt="Download Icon"
            />
            Download
          </Button>
        </Box>
        <Box
          style={{ display: "flex", gap: ".4rem", fledDirection: "row" }}
          position="relative"
        >
          {/* 
          - TODO: Edit button only show when status === Pending 
          - TODO: Action button only show when status === Approve | Pending | Partial
          - See notes.todo for more information
          */}
          {detailDocument["product"]["status"] === "Pending" && (
            <Button variant="outlined" onClick={handleEditBtn}>
              Edit
            </Button>
          )}

          {detailDocument["product"]["status"] !== "Paid" &&
            detailDocument["others"]?.canAction && (
              <Button
                variant="contained"
                onClick={() => setExpandAction((prev) => !prev)}
              >
                Action
                <img
                  src={ArrowDown}
                  alt="arrow"
                  style={{ rotate: expandAction ? "180deg" : "0deg" }}
                />
              </Button>
            )}
          {/* Developmnent purpose */}
          {/* {true && (
            <Button
              variant="contained"
              onClick={() => setExpandAction((prev) => !prev)}
            >
              Action
              <img
                src={ArrowDown}
                alt="arrow"
                style={{ rotate: expandAction ? "180deg" : "0deg" }}
              />
            </Button>
          )} */}
          {expandAction && (
            <Box
              position="absolute"
              border="1px solid #3498db"
              bottom="105%"
              width={"100%"}
              paddingY={".2rem"}
              borderRadius={"5px"}
              sx={{
                background: "#fff",
                ".MuiTypography-body2:hover": {
                  background: "#1268B8",
                  color: "#fff",
                },
              }}
            >
              <Typography
                variant="body2"
                padding={".5rem"}
                textAlign={"center"}
                // bgcolor={"#1268B8"}
                marginY={".2rem"}
                // color={"#fff"}
                style={{ cursor: "pointer" }}
                onClick={handleActionBtn}
              >
                Pay
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={() => handleBackPage()}
                  sx={{ marginRight: ".5rem" }}
                >
                  <img src={ArrowBackIcon} />
                </IconButton>
                {title}
              </Box>
            </div>
          }
        />
        <Divider />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            maxHeight: "62dvh",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                {/* <ForPrintingV3 /> */}
                <DocumentPrint />
              </Grid>
              <Grid item xs={5.2}>
                {/* <DetailV2 /> */}
                <DocumentDetail />
              </Grid>
              <Grid item xs={6.8}>
                {/* <img src="https://s3.bukalapak.com/img/3942411727/w-1000/lacto.png"></img> */}
                {/* <PreviewPDFV2 /> */}
                <DocumentPreview
                  documentPreviewForPrintRef={documentPreviewForPrintRef}
                  height={height}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider />
        <style>
          {`
      .page-break {
        page-break-before: always;
      }
      .DocumentPreview{
        display:none;
      }
      .DocumentPreviewFooter{
        padding: 20px;
        background-color: #09345C;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

        @page {
            // size: A4;
//               size: 210mm 280mm;
          size: 210mm ${pxToMm(height)}mm; /* A4 size */
            margin: 0;
            /* Remove all margins */
        }

        body {
            margin: 0;
        }

        body *{
          visibility:hidden;
        }
.DocumentPreviewFooter{
 position:fixed;
bottom:0;
left:0;
width:100%;
border-radius:unset;
}
        .DocumentPreviewFooter, .DocumentPreviewFooter *{
            visibility: visible;
padding: 10px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        print-color-adjust: exact;
        webkit-print-color-adjust: exact; /* For WebKit-based browsers */
      background-color:  #09345C !important; /* Override background color */
      }

        .DocumentPreview{
          display:block;
          position: fixed !important;
          top: 0;import { INPUT_TYPE } from './../../../util/function';


          left: 0;
          width: 100%;
          z-index: 11999;
          visibility: visible;
          page-break-before: always;
        }

        .DocumentPreview,.DocumentPreview *{
            visibility: visible;
        }
      }

      `}
        </style>
        <Box>{!isLoading && <BottomAction />}</Box>
      </Card>
      {formApproval && <ApprovalForm show={formApproval} toggleShow={setFormApproval} />}
    </>
  );
}

export default DetailInvoice;
