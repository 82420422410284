import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import { field_drawer } from "../../parts/imutable_state";
import {
  SelectOneAutocomplete,
  SelectOneAutocompleteSearch,
} from "../../../../../components/Form";
import { ACTION_TYPE, INPUT_TYPE, SELECT_OPTION } from "../../../../../util/function";
import ComponentRender from "./ComponentRender";
import { justifyContentEnd, removeButton } from "../../../../../util/style";
import RemoveIcon from "../../../../../assets/minus.svg";
import { FireHydrantAltRounded } from "@mui/icons-material";
import { validateDisabled } from "../../parts/imutable_state";

export default function ServiceFormComponent({
  // field,
  // parentField,
  actionType,
  errors,
  options,
  formValues,
  handleInputChange,
  handleInputBlur,
  handleAddSubService,
  handleRemoveSubService,
}) {
  // useEffect(() => {
  //   console.count("Sub service count");
  //   console.log(field);
  // },[])

  // if (field.hidden) return null;

  const currencyField = field_drawer.service?.field.find(
    (fiel) => fiel.name === "service_currency"
  );

  const setviceCategoryField = field_drawer.service.field.find(
    (fiel) => fiel.parent === true
  );

  let optionData = options[setviceCategoryField.name] || [];
  let requireOption = null;
  // if (setviceCategoryField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && setviceCategoryField.require) {
  //   requireOption = {
  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  //     name: "Choose Option",
  //   };
  // }
  optionData = requireOption ? [...optionData, requireOption] : optionData;

  //* Disabled currency field
  currencyField["additionalProps"] = {
    disabled: true,
  };

  setviceCategoryField["additionalProps"] = {
    disabled: true,
  };

  const aliasFormValues = formValues[field_drawer.service.multipleValueName];
  const resFieldCurrency = field_drawer.service.field.filter(
    (fiel) => !fiel.parent && !fiel.hidden
  );
  // console.log(resFieldCurrency);
  return (
    <React.Fragment key={setviceCategoryField.name}>
      <Grid container>
        <Grid item xs={9.5} paddingRight={".8rem"}>
          <SelectOneAutocomplete
            key={"service category"}
            errors={errors}
            field={setviceCategoryField}
            fieldOptions={optionData}
            formValues={formValues}
            handleInputBlur={(e) => handleInputBlur(e, field_drawer.service)}
            handleInputChange={handleInputChange}
            tip={setviceCategoryField.tip}
          />
        </Grid>
        <Grid item xs={2.5}>
          <SelectOneAutocomplete
            key={"Curreny service".concat(formValues[setviceCategoryField.name])}
            errors={errors}
            field={currencyField}
            fieldOptions={currencyField?.options}
            formValues={formValues}
            handleInputBlur={(e) => handleInputBlur(e, field_drawer.service)}
            handleInputChange={handleInputChange}
            tip={currencyField?.tip}
          />
        </Grid>
        {/* <>for subservices</> */}
        {/* {JSON.stringify(formValues[field_drawer.service.multipleValueName])} */}
        {aliasFormValues?.map((valueForm, indexValue) => {
          return resFieldCurrency.map((field, indexField) => {
            return (
              <>
                <Grid item xs={10.75} key={`${indexValue}-${indexField}`}>
                  <ComponentRender
                    field={field}
                    options={options}
                    errors={errors}
                    formValues={valueForm}
                    handleChange={handleInputChange}
                    handleBlur={(e) => handleInputBlur(e, field_drawer.service)}
                    disabled={validateDisabled(field.name, actionType)}
                    paymentReceive={false}
                    canConvert={false}
                  />
                </Grid>
                {field.name === "sub_service_id" && (
                  <Grid item xs={1.25} sx={{ marginTop: "1.8rem" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ...removeButton,
                      }}
                      disabled={true}
                    >
                      <img src={RemoveIcon} alt={`Remove ${field.name}`} />
                    </Button>
                  </Grid>
                )}
                {/* <Grid sx={{ border: "1px solid black" }}>
                    {JSON.stringify(value)}
                </Grid> */}
              </>
            );
          });
        })}
        {/* {formValues[parentField.multipleValueName]?.map((val, index) => {
          // return JSON.stringify(val)
          // return (
          //   <>
          //     <Grid item xs={10.75}>
          //       <ComponentRender
          //         field={field}
          //         options={options}
          //         errors={errors}
          //         formValues={
          //           formValues[parentField.multipleValueName][index]
          //         }
          //         handleChange={handleInputChange}
          //         handleBlur={(e) => handleInputBlur(e, parentField)}
          //         disabled={validateDisabled(field.name, actionType)}
          //         paymentReceive={false}
          //         canConvert={false}
          //       />
          //     </Grid>
          //     {field.name === "sub_service_id" && (
          //       <Grid item xs={1.25} sx={{ marginTop: "1.8rem" }}>
          //         <Button
          //           variant="contained"
          //           disableElevation
          //           sx={{
          //             backgroundColor: (theme) =>
          //               theme.palette.primary.main,
          //             ...removeButton,
          //           }}
          //           disabled={true}
          //         >
          //           <img src={RemoveIcon} alt={`Remove ${field.name}`} />
          //         </Button>
          //       </Grid>
          //     )}
          //   </>
          // )
        })} */}
      </Grid>
    </React.Fragment>
  );
}
