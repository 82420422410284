// import React, { useState } from 'react';
// import {
//   Stepper,
//   Step,
//   StepLabel,
//   Button,
//   Typography,
//   Grid,
//   Box,
// } from '@mui/material';
//
// import DocumentIcon from "./../../../assets/document.png";
//
// const steps = ['Applicant', 'Description', 'Address', 'Product', 'Attachment'];
//
// const sampleData = {
//   "Applicant": {
//     "Applicant Information": {
//       "Applicant": "Lincoln Carder",
//       "Category": "BPOM",
//       "Type": "CPKB",
//       "Email": "lincolncarder@gmail.com",
//       "Mobile Number": "081234567890",
//       "Business Field": "Kosmetik & Kecantikan",
//       "Submission on Behalf of": "Company"
//     },
//   },
//   "Description": {
//     "Business Description": {
//       "Business Activities": "Produksi",
//       "Product Origin": "Local",
//       "Product Marketing": "Nasional"
//     },
//   },
//   "Address": {
//     "Office Address": {
//       "Province": "DKI Jakarta",
//       "Sub-district": "Tanah Abang",
//       "City/District": "Kota Jakarta Pusat",
//       "Subdivision": "Petamburan",
//       "Address": "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
//       "Postal Code": "10260"
//     },
//     "Facility Address": {
//       "Province": "DKI Jakarta",
//       "Sub-district": "Tanah Abang",
//       "City/District": "Kota Jakarta Pusat",
//       "Subdivision": "Petamburan",
//       "Address": "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
//       "Postal Code": "10260",
//       "Phone Number": "02123456789",
//       "Status": "Owned"
//     }
//   },
//   "Product": {
//     "Product Information": {
//       "Product": "Minuman Bersoda",
//       "Primary Packaging": "Botol Plastik",
//       "Net Weight": "450 ml",
//       "Brand": "RefreshFizz",
//       "Secondary Packaging": "Kardus",
//       "Monthly Production Capacity": 50000
//     }
//   },
//   "Attachment": {
//     "KTP Pimpinan": [
//       {
//         filename: "certificatedoc1.pdf",
//         size: "3.2 MB",
//         url: "https://download_certificate1.pdf",
//       },
//       {
//         filename: "certificatedoc2.pdf",
//         size: "4.2 MB",
//         url: "https://download_certificate2.pdf",
//       },
//     ],
//     "NPWP": [
//       {
//         filename: "certificatedoc1.pdf",
//         size: "3.2 MB",
//         url: "https://download_certificate1.pdf",
//       },
//       {
//         filename: "certificatedoc2.pdf",
//         size: "4.2 MB",
//         url: "https://download_certificate2.pdf",
//       },
//     ],
//     "Nomor Induk Berusaha (NIB)": [
//       {
//         filename: "certificatedoc1.pdf",
//         size: "3.2 MB",
//         url: "https://download_certificate1.pdf",
//       },
//       {
//         filename: "certificatedoc2.pdf",
//         size: "4.2 MB",
//         url: "https://download_certificate2.pdf",
//       },
//     ],
//     "Akte Perusahaan": [
//       {
//         filename: "certificatedoc1.pdf",
//         size: "3.2 MB",
//         url: "https://download_certificate1.pdf",
//       },
//       {
//         filename: "certificatedoc2.pdf",
//         size: "4.2 MB",
//         url: "https://download_certificate2.pdf",
//       },
//     ],
//     // etc.....
//   },
// };
//
//
// const DetailWizard = () => {
//   const [activeStep, setActiveStep] = useState(0);
//
//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };
//
//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };
//
//   const CustomStepLabel = ({ step, label, isActive, isCompleted }) => {
//     const stepIconStyle = {
//       width: '24px',
//       height: '24px',
//       borderRadius: '50%',
//       backgroundColor: isActive || isCompleted ? '#1268B8' : 'gray',
//       color: 'white',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//     };
//
//     return (
//       <StepLabel
//         icon={
//           <div style={stepIconStyle}>
//             {step + 1}
//           </div>
//         }
//       >
//         {label}
//       </StepLabel>
//     );
//   };
//
//
//   const renderStepContent = () => {
//     const stepData = sampleData[steps[activeStep]];
//     if (!stepData) {
//       return <Typography>No data available for this step.</Typography>;
//     }
//
//     return Object.entries(stepData).map(([key, value]) => (
//       <div key={key}>
//         {Object.entries(value).map(([subKey, subValue]) => (
//           <Typography key={subKey}>
//             {subKey}: {subValue}
//           </Typography>
//         ))}
//
//         {/* Render attachments using AttachmentItem component */}
//         {key === 'Attachment' && (
//           <div>
//             <Typography variant="h6">{key}</Typography>
//             {Object.values(value).flat().map((attachment, index) => (
//               <AttachmentItem key={index} item={attachment} />
//             ))}
//           </div>
//         )}
//       </div>
//     ));
//   };
//
//
//   return (
//     <Grid container justifyContent="center">
//       <Grid item xs={12}>
//         <Stepper activeStep={activeStep} alternativeLabel>
//           {steps.map((label, index) => (
//             <Step key={label}>
//               <CustomStepLabel
//                 step={index}
//                 label={label}
//                 isActive={index === activeStep}
//                 isCompleted={index < activeStep}
//               />
//             </Step>
//           ))}
//         </Stepper>
//         <div>
//           {activeStep === steps.length ? (
//             <div>
//               {/* No message displayed for all steps completed */}
//             </div>
//           ) : (
//             <div>
//               <Typography variant="h5">
//                 {`Step ${activeStep + 1}: ${steps[activeStep]}`}
//               </Typography>
//               <div>
//                 {/* Render content for each step */}
//                 {renderStepContent()}
//               </div>
//               <div style={{ marginTop: '20px' }}>
//                 <Button
//                   disabled={activeStep === 0}
//                   onClick={handleBack}
//                   sx={{ mr: 1 }}
//                 >
//                   Back
//                 </Button>
//                 {activeStep === steps.length - 1 ? (
//                   // Remove the 'Finish' button on the last step
//                   <></>
//                 ) : (
//                   <Button variant="contained" onClick={handleNext}>
//                     Next
//                   </Button>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </Grid>
//     </Grid>
//   );
// };
//
//
// const AttachmentItem = ({ item }) => {
//   const alignItemsCenter = {
//     display: 'flex',
//     alignItems: 'center',
//   };
//
//   const fourColor = '#yourColorCode'; // Replace with your desired color code
//
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         width: '100%',
//         margin: '.3rem 0',
//       }}
//     >
//       <Typography variant="subtitle1">
//         Attachment
//       </Typography>
//       <Typography
//         variant="subtitle1"
//         sx={{
//           fontWeight: '500',
//         }}
//       >
//         <div
//           style={{
//             ...alignItemsCenter,
//             gap: '1rem',
//             padding: '0.5rem 0',
//           }}
//         >
//           <img src={DocumentIcon} alt="Document Icon" />
//           <span>{item.filename}</span>
//           <span style={{ textTransform: 'uppercase', color: fourColor }}>
//             {/* {formatSize(item.size)} */}
//           </span>
//           <a href={item.url} target="_blank" rel="noopener noreferrer">
//             Open
//           </a>
//         </div>
//       </Typography>
//     </Box>
//   );
// };
//
//
// export default DetailWizard;

import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";

import DocumentIcon from "./../../../assets/document.png";
import { btnWhite } from "../../../util/style";
import { makeStyles } from "@mui/styles";

const steps = [
  "Applicant",
  "Description",
  "Address",
  "Representative",
  "Attachment",
  "Other",
];

// Key as field name on payload and values as display on screen
const STEP_ALIAS_NAME = Object.freeze({
  [steps[0]]: {
    client_name: "Client Name",
    applicant_name: "Application Name",
    category: "Category",
    type: "Type",
    bussines_field: "Bussines Field",
    phone: "Phone",
    email: "Email",
    company: "Company",
    owner_type: "Owner Type",
  },
  [steps[1]]: {
    mark_type: "Type of Mark",
    image: "Image of Mark",
    trademark: "Trademark",
    description: "Description",
    colors: "Colors",
    translation: "Translation",
    transliteration: "Transliteration",
    disclaimers: "Disclaimers",
  },
  [steps[2]]: {
    address: "Address",
    city: "City",
    province: "Province",
    zip_code: "Zip Code",
    country: "Country",
  },
  [steps[3]]: {
    no_consultant: "Number Of Consultant",
    name: "Consultant Name",
    office_name: "Office Name",
    address: "Address",
    phone: "Phone",
    email: "Email",
  },
  [steps[4]]: {
    support_document: "Support Documents",
    surat_kuasa_konsultan: "Surat Kuasa konsultan KI Bermatrai Cukup (Asli)",
    tanda_tangan_kuasa: "Tanda Tangan Digital Kuasa",
    tanda_tangan_pemohon: "Tanda Tangan Digital Pemohon",
  },
  [steps[5]]: {
    priorityData: {
      displayName: "Priority Data",
      children: {
        date: "Priority Date",
        country: "Priority Country",
        number_of_priority: "Number of Priority",
      },
    },
    class: {
      displayName: "Class",
      children: {
        class: "Class",
        description: "Description of Goods/Service",
      },
    },
    additional_applicant: {
      displayName: "Additional Applicant",
      children: { applicant_name: "Applicant's Name" },
    },
    additional_mark: {
      displayName: "Additional Mark",
      children: {
        upload_file: "Upload File",
      },
    },
  },
});

/*
const wizardData = {
  "Applicant": {
      "Applicant Information": {
          "Applicant": "test applicant1",
          "Category": "Halal",
          "Type": "Halal",
          "Email": "muhamadsobari198@gmail.com",
          "Mobile Number": "083456789012",
          "Business Field": "BussnesField 2",
          "Submission on Behalf of": "Company"
      }
  },
  "Description": {
      "Business Description": {
          "Business Activities": "Bisnis Activity 2",
          "Product Origin": "Import",
          "Product Marketing": "Produk Marketing 2"
      }
  },
  "Address": {
      "Office Address": {
          "Province": "SUMATERA UTARA",
          "Regency": "KABUPATEN ACEH SELATAN",
          "District": "TEUPAH BARAT",
          "Village": "ANA AO",
          "Address": "Address",
          "Postal Code": "101"
      },
      "Facility Address": {
          "Province": "BENGKULU",
          "Regency": "KABUPATEN ACEH BARAT",
          "District": "TELUK DALAM",
          "Village": "PASIR TINGGI",
          "Address": "Address2",
          "Postal Code": "103",
          "Phone Number": "02812381238123",
          "Status": "Rent"
      }
  },
  "Product": {
      "Product Information": [
          {
              "Product": "energen",
              "Primary Packaging": "bottle",
              "Net Weight": "50 mg",
              "Brand": "wiw",
              "Secondary Packaging": "box",
              "Monthly Production Capacity": 5000
          },
          {
            "Product": "energen2",
            "Primary Packaging": "bottle",
            "Net Weight": "50 mg",
            "Brand": "wiw",
            "Secondary Packaging": "box",
            "Monthly Production Capacity": 5000
        }
      ]
  },
  "Attachment": {
      "KTP Pemohon": [
          {
              "id": 2,
              "filename": "1706839979_Events.png",
              "size": 203956,
              "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839979_Events.png"
          }
      ],
      "KTP Penyelia Halal": [
          {
              "id": 3,
              "filename": "1706839980_JH1.png",
              "size": 161374,
              "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839980_JH1.png"
          }
      ],
      "NPWP": [
          {
              "id": 4,
              "filename": "1706839981_merging.png",
              "size": 130002,
              "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_merging.png"
          }
      ],
      "Nomor Induk Berusaha (NIB)": [
          {
              "id": 5,
              "filename": "1706839981_Screenshot_2022-01-05_11-50-53.png",
              "size": 154928,
              "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_Screenshot_2022-01-05_11-50-53.png"
          }
      ],
      "Akte Perusahaan": [
          {
              "id": 6,
              "filename": "1706839981_Screenshot_2022-10-14_07-47-57.png",
              "size": 369262,
              "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_Screenshot_2022-10-14_07-47-57.png"
          }
      ],
      "Sertifikat Pelatihan Eksternal Halal": [
          {
              "id": 7,
              "filename": "1706839982_Screenshot_1668003144.png",
              "size": 4858,
              "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839982_Screenshot_1668003144.png"
          }
      ]
  }
};
*/

const AttachmentItem = ({ item, title }) => {
  const alignItemsCenter = {
    display: "flex",
    alignItems: "center",
  };

  const fourColor = "#yourColorCode"; // Replace with your desired color code

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        margin: ".3rem 0",
        ...alignItemsCenter,
      }}
    >
      {/* <Typography variant="subtitle1" sx={{ minWidth: "170px", display: "block" }}>
        {title}
      </Typography> */}
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "500",
        }}
      >
        <div
          style={{
            ...alignItemsCenter,
            gap: "1rem",
            padding: "0.5rem 0",
          }}
        >
          <img src={DocumentIcon} alt="Document Icon" />
          <span>{item?.filename}</span>
          <span style={{ textTransform: "uppercase", color: fourColor }}>
            {/* {formatSize(item.size)} */}
          </span>
          <a href={item?.url} target="_blank" rel="noopener noreferrer">
            Open
          </a>
        </div>
      </Typography>
    </Box>
  );
};

const DetailWizard = ({ wizardData, activeStep }) => {
  // const [activeStep, setActiveStep] = useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const CustomStepLabel = ({ step, label, isActive, isCompleted }) => {
    const stepIconStyle = {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      backgroundColor: isActive || isCompleted ? "#1268B8" : "gray",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <StepLabel icon={<div style={stepIconStyle}>{step + 1}</div>}>{label}</StepLabel>
    );
  };

  const renderValue = (value, key) => {
    if (typeof value == "string" || typeof value === "number") {
      return value;
    } else if (typeof value === "object") {
      if (Array.isArray(value)) {
        //* For array of object
        return value.map((item) => AttachmentItem({ item }));
      } else {
        //* File attachment type
        if ("filename" in value && "size" in value && "url" in value) {
          return AttachmentItem({ item: value, title: key });
        } else {
          //* other type
          const aa = value;
          debugger;
        }
      }
    }
  };

  const renderLastStep = (value, aliasSection, displayTable = false) => {
    if (Array.isArray(value)) {
      if (displayTable) {
        return (
          <React.Fragment>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "600" }}>Class</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "600" }}>Description</Typography>
            </Grid>
            {value.map((item) => (
              <>
                <Grid item xs={6}>
                  {item?.class}
                </Grid>
                <Grid item xs={6}>
                  {item?.description}
                </Grid>
              </>
            ))}
          </React.Fragment>
        );
      } else {
        return value.map((item) =>
          Object.entries(item).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={6}>
                {aliasSection[key]}
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>{renderValue(value)}</Typography>
              </Grid>
            </React.Fragment>
          ))
        );
      }
    } else {
      return Object.entries(value).map(([key, val]) => (
        <React.Fragment>
          <Grid item xs={6}>
            {aliasSection[key]}
          </Grid>
          <Grid item xs={6}>
            {val.map((v) => (
              <Typography sx={{ fontWeight: "600" }}>{renderValue(v)}</Typography>
            ))}
          </Grid>
        </React.Fragment>
      ));
    }
  };

  const renderStepContent = (stepDataTitle) => {
    const stepData = wizardData[steps[activeStep]];
    if (!stepData) {
      return <Typography>No data available for this step.</Typography>;
    }
    const activeAliasName = STEP_ALIAS_NAME[steps[activeStep]];
    // console.log({ data: stepData });
    // return (<h1>Ada datanya</h1>)
    if (activeStep === steps.length - 1) {
      return Object.entries(stepData).map(([sectionTitle, value]) => (
        <>
          <Grid style={{ paddingInline: "1.5rem", marginBottom: ".6rem" }}>
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {activeAliasName[sectionTitle]?.displayName}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: ".8rem" }}>
              {renderLastStep(
                value,
                activeAliasName[sectionTitle]?.children,
                activeAliasName[sectionTitle]?.displayName === "Class"
              )}
            </Grid>
          </Grid>
        </>
      ));
    }
    return (
      <>
        <Grid style={{ paddingInline: "1.5rem", marginBottom: ".6rem" }}>
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {steps[activeStep]}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: ".8rem" }}>
            {Object.entries(stepData).map(([key, val]) => (
              <React.Fragment key={key}>
                <Grid item xs={6}>
                  {activeAliasName[key]}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "600" }}>
                    {renderValue(val, key)}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </>
    );
    // return Object.entries(stepData).map(([key, value]) => (
    //   <Grid container spacing={steps[activeStep] !== 'Attachment' ? 1.5 : 0} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, p : steps[activeStep] !== 'Attachment' ? 3 : 0, paddingLeft:steps[activeStep] !== 'Attachment' ? 3 : 3 }}>
    //     {/* {key === 'Product Information' && (
    //           <Typography variant="h6" sx={{ fontWeight: 800 }}>Product Information</Typography>
    //         )} */}

    //        {steps[activeStep] !== 'Attachment' && (
    //         <Grid item xs={12}>
    //         {/* ini untuk title */}
    //       <Typography variant="h6" sx={{ fontWeight: 800 }}>{steps[activeStep]}</Typography>
    //       </Grid>
    //        )}

    //     {/* {Object.entries(value).map(([subKey, subValue]) => (
    //        <React.Fragment key={subKey}>
    //         {key === 'Product Information' && (
    //           <Grid item xs={12}>
    //           <div style={{ height: '20px', width: '100%' }}></div>
    //           </Grid>
    //         )}
    //         {typeof subValue === "object" && steps[activeStep] !== 'Attachment' ? (
    //           Object.entries(subValue).map(([nestedKey, nestedValue]) => (
    //               <>
    //               {console.log(key, 'AAAA', steps[activeStep])}
    //               <Grid item xs={7}>
    //                 <Typography variant="body1">{nestedKey}</Typography>
    //               </Grid>
    //               <Grid item xs={5}>
    //                 <Typography variant="subtitle1">{nestedValue}</Typography>
    //               </Grid>
    //             </>
    //           ))
    //         ) : typeof subValue === "object" && steps[activeStep] === 'Attachment' ? (
    //         <AttachmentItem item={subValue} title={key}/>
    //         ): (
    //           <>
    //           <Grid item xs={6}>
    //             <Typography variant="body1">{subKey}</Typography>
    //           </Grid>
    //           <Grid item xs={6}>
    //             <Typography variant="subtitle1">{subValue}</Typography>
    //           </Grid>
    //         </>
    //         )}
    //       </React.Fragment>
    //     ))} */}
    //   </Grid>
    // ));
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{ paddingTop: 2, paddingBottom: 2 }}
    >
      <Grid item xs={12}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomStepLabel
                step={index}
                label={label}
                isActive={index === activeStep}
                isCompleted={index < activeStep}
              />
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>{/* No message displayed for all steps completed */}</div>
          ) : (
            <div>
              {/* <Typography variant="h5">
                {`Step ${activeStep + 1}: ${steps[activeStep]}`}
              </Typography> */}
              <div
                style={{
                  overflow: "auto",
                  padding: steps[activeStep] === "Attachment" ? "10px" : "unset",
                  scrollBehavior: "smooth",
                  marginTop: "1rem",
                }}
              >
                {/* Render content for each step */}
                {renderStepContent()}
              </div>

              {/* =========== Move to parent ============ */}
              {/* <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "flex-end",
                  paddingRight: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ ...btnWhite }}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  disableElevation
                >
                  Previous
                </Button>
                {activeStep === steps.length - 1 ? (
                  // Remove the 'Finish' button on the last step
                  <></>
                ) : (
                  <Button variant="contained" disableElevation onClick={handleNext}>
                    Next
                  </Button>
                )}
              </div> */}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default DetailWizard;
