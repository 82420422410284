import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    MenuItem,
    Box,
    Modal,
    Paper,
    List,
    ListItem,
    ListItemText,
    FormHelperText,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    borderRadius,
    StatusBadge,
    warningMainColor,
    successMainColor,
    dangerMainColor,
    warningSurfaceColor,
    successSurfaceColor,
    dangerSurfaceColor,
    defaultStylePage,
    btnWhite,
    mainColor,
    neutral100,
    surfaceColor,
    neutral10,
    neutral40,
} from "../../../util/style";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../components/Table";
import { deleteRequest, getRequest } from "./clients/actions";
import { ACTION_TYPE, INPUT_TYPE, MODE_CRUD_WITH, formatDate } from "../../../util/function";
import Form, { SelectOneAutocomplete } from "../../../components/Form";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import axios from "../../../core/axios_config";
import ConfirmDelete from "../../../components/ConfirmDelete";

const columns = [
    {
        field: "request_code",
        headerName: "Request Number",
        width: 135,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div><span>{params.value.replace("-", "")}</span></div>
            );
        }
    },
    {
        field: "request_date",
        headerName: "Request Date",
        width: 201,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div><span>{formatDate(params.value)}</span></div>
            );
        }
    },
    {
        field: "return_date",
        headerName: "Return Date",
        width: 201,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div><span>{formatDate(params.value)}</span></div>
            );
        }
    },
    {
        field: "customer_name",
        headerName: "Customer Name",
        width: 291,
        disableColumnMenu: true,
    },
    {
        field: "service_category",
        headerName: "Service Category",
        width: 161,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {params.value.map((v, key) => (
                        <StatusBadge
                            key={key}
                            status={v.get_service_category ? v.get_service_category.name : ""}
                            isinfo={true}
                            style={{ margin: ".1rem .25rem" }}
                        >
                            {v.get_service_category ? v.get_service_category.name : ""}
                        </StatusBadge>
                    ))}
                </Box>
            );
        }
    },
    {
        field: "sub_service_category",
        headerName: "Sub Service Category",
        width: 301,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.get_sub_service_category);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v ? v.name : ""}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v ? v.name : ""}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "registration_number",
        headerName: "Registration Number",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {params.value.map((v, key) => (
                        // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
                        <StatusBadge
                            key={key}
                            status={v.registration_number}
                            isinfo={true}
                            style={{ margin: ".1rem .25rem" }}
                        >
                            {v.registration_number}
                        </StatusBadge>
                    ))}
                </Box>
            );
        }
    },
    {
        field: "reference_number",
        headerName: "Reference Number",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {params.value.map((v, key) => (
                        // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
                        <StatusBadge
                            key={key}
                            status={v.reference_number}
                            isinfo={true}
                            style={{ margin: ".1rem .25rem" }}
                        >
                            {v.reference_number}
                        </StatusBadge>
                    ))}
                </Box>
            );
        }
    },
    {
        field: "brand",
        headerName: "Brand",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.brand);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v ? v.name : ""}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v ? v.name : ""}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "applicant",
        headerName: "Applicant",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.applicant);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v ? v.name : ""}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v ? v.name : ""}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "note",
        headerName: "Note",
        width: 240,
        disableColumnMenu: true,
    },
    {
        field: "status",
        headerName: "Request Status",
        width: 240,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.status;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    <StatusBadge
                        colors={params.value.color_code}
                        bgColor={params.value.bg_code}
                        status={params.value.name}
                        isinfo={false}
                        style={{ margin: ".1rem .25rem" }}
                    >
                        {params.value.name}
                    </StatusBadge>
                </Box>
            );
        }
    },
    {
        field: "get_user",
        headerName: "Approval Line",
        width: 250,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_user.fullname;
            return sc;
        },
    },
];

const fields = [
    {
        name: "customer_id",
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        label: "Customer Name",
        defaultValue: "",
        apiEndpoint: "/master/users",
        required: true,
    },
]

const Request = () => {

    const theme = useTheme();
    const useStyles = defaultStylePage;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useSelector(state => state.auth)
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [searchBy, setSearchBy] = useState("customer_name");
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [requestDeleted, setRequestDeleted] = useState(null);
    const [formModal, setFormModal] = useState(false);
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
    const [dateFilter, setDateFilter] = useState(ACTION_TYPE.DEFAULT_DATE);
    const [categoryFilter, setCategoryFilter] = useState(ACTION_TYPE.ALL_CATEGORY);
    const [listCategory, setlistCategory] = useState([]);
    const [statusFilter, setStatusFilter] = useState(ACTION_TYPE.ALL_STATUS);
    const [listStatus, setlistStatus] = useState([]);
    const [title, setTitle] = useState("Add New Archive Hardcopy");
    const [typeData, setTypeData] = useState("Hardcopy");
    const [selectedData, setSelectedData] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const initialErrors = {
        customer_name: null,
    };
    const [errors, setErrors] = useState(initialErrors);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestedItems, setSuggestedItems] = useState([]);
    const [isSelectedTerm, setIsSelectedTerm] = useState(true);
    const placeholder = {
        "customer_name": "Customer Name",
        "Brand": "Brand",
        "Applicant": "Applicant",
        "reference_number": "No. Reference",
        "registration_number": "No. Registration",
    }

    const initialFormValues = (type = "fields") => {
        return fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
            {}
        );
    };
    const [formValues, setFormValues] = useState(
        Object.assign({}, initialFormValues("fields"), { id: null, status: false })
    );
    const [options, setOptions] = useState({});

    useEffect(() => {
        const fetchOptions = async (apiEndpoint, name) => {
            try {
                const response = await axios.get(apiEndpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const modifiedData = response.data.data.map((item) => {
                    if (item.hasOwnProperty("fullname")) {
                        return {
                            ...item,
                            name: item.fullname,
                        };
                    } else {
                        return item;
                    }
                });
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [name]: modifiedData,
                }));
            } catch (error) {
                console.error(`Error fetching ${name} options:`, error);
            }
        };

        fields.forEach((field) => {
            const { name, apiEndpoint } = field;
            if (apiEndpoint) {
                fetchOptions(apiEndpoint, name);
            }
        });
    }, [])

    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.request);

    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    useEffect(() => {
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }, [sortDirection, sortBy]);

    useEffect(() => {
        axios
            .get("/master/services", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setlistCategory(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching service categories:", error);
            });
    }, [])

    useEffect(() => {
        axios
            .get("/master/statuses", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setlistStatus(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching service categories:", error);
            });
    }, [])

    useEffect(() => {
        if (searchTerm.length >= 2) {
            axios.get(`/requests/cek_archive?search=${searchTerm}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    setSuggestedItems(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching suggestions:', error);
                });
        } else {
            setSuggestedItems([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        const filteredRows = rows.filter((row) => selectedData.includes(row.id));
        if (filteredRows.length === 1) {
            const newFormValues = {
                ...formValues,
                ['archive_id']: filteredRows[0].archive_id,
                ['customer_id']: filteredRows[0].customer_id,
                ['customer_name']: filteredRows[0].customer_name,
            };
            setFormValues(newFormValues);
            console.log(filteredRows, "filteredRows");
        }
    }, [selectedData]);

    useEffect(() => {
        if (isActive === true) {
            dispatch(
                getRequest({
                    statusFilter,
                    categoryFilter,
                    dateFilter,
                    token,
                    page,
                    limit,
                    searchValue,
                    searchBy,
                    sortDirection,
                    sortBy,
                })
            )
        }
    }, [isActive])

    useEffect(() => {
        if (!searchValue) { _search() }
    }, [searchValue])
    const handleKeyPress = (e) => {
        if (e.key === "Enter" && searchValue) {
            _search();
        }
    };


    useEffect(() => {
        if (sortModel && sortModel.length) {
            const { field, sort } = sortModel[0];
            setSortBy(field);
            setSortDirection(sort);
        }
    }, [sortModel]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        // console.log(newLimit);
        setPage(0);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
        setActionType(actionType);
        setFormModal(true);
        console.log("actionType==>" + ACTION_TYPE);
        if (actionType === ACTION_TYPE.ADD) {
            setTitle(`Check Archive ${typeData}`);

        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            setTitle(`Delete Request ${typeData}`);
            const data = rows.filter((row) => selectedData.includes(row.id));
            setRequestDeleted(data.map((row) => row.customer_name).join(", "));
        }
    };

    const handleCloseFormModal = (important = false) => {
        if (!important) {
            console.log("a", important);
            if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
                console.log("b", important);
                if (true) setConfirmModal(true);
            } else {
                setFormModal(false);
                console.log("c", important);
            }
        } else {
            console.log("d", important);
            setFormModal(false);
        }
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setIsSelectedTerm(false);
        setSearchTerm(value);
    };

    const resetSearchTerm = () => {
        const newErrors = { ...errors }
        newErrors.customer_name = "";
        setErrors(newErrors);
    }

    const handleSuggestionClick = (suggestion) => {
        resetSearchTerm();
        setSearchTerm(suggestion.customer_name);
        setSuggestedItems([]);
        const newFormValues = {
            ...formValues,
            ['archive_id']: suggestion.archive_id,
            ['customer_id']: suggestion.customer_id,
            ['customer_name']: suggestion.customer_name,
        };
        setFormValues(newFormValues);
        setIsSelectedTerm(true);
    };

    const renderFormContent = () => {
        if (actionType === ACTION_TYPE.ADD) {
            return (
                <div>
                    <TextField
                        fullWidth
                        placeholder="Search customer name"
                        size="small" // Set the size to "small"
                        InputProps={{
                            startAdornment: <SearchIcon />,
                        }}
                        error={errors.customer_name}
                        sx={{
                            borderRadius, // Apply the desired border radius value
                        }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    {suggestedItems.length < 1 && searchTerm.length >= 2 && !isSelectedTerm && (
                        <Paper elevation={3} style={{ position: 'absolute', zIndex: 1, width: '100%' }}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="No Data Found" />
                                </ListItem>
                            </List>
                        </Paper>
                    )}
                    {suggestedItems.length > 0 && !isSelectedTerm && searchTerm.length >= 2 && (
                        <Paper elevation={3} style={{ position: 'absolute', zIndex: 1, width: '100%' }}>
                            <List>
                                {suggestedItems.map((suggestion) => (
                                    <ListItem key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)}>
                                        <ListItemText primary={suggestion.customer_name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    )}
                    {/* {errors.customer_name && (
                        <FormHelperText
                            error
                            sx={{
                                color: "#fb2525",
                                fontSize: "1rem",
                                margin: "0.3rem 0 0",
                            }}
                        >
                            {errors.customer_name}
                        </FormHelperText>
                    )} */}
                </div>
            );
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            // console.log(requestDeleted);
            return <ConfirmDelete userDeleted={"request hardcopy"} />;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            handleDeleteRow();
            handleCloseFormModal(true);
            setSelectedData([]);
        } else {
            const newErrors = { ...errors }
            if (isSelectedTerm && searchTerm) {
                const data = { ...formValues };
                navigate("add", { state: { customer: data, type: typeData } })
                newErrors.customer_name = null
            } else {
                newErrors.customer_name = "Customer Name is required"
            }
            setErrors(newErrors);
        }
    }

    const handleDeleteRow = () => {
        const data = rows.filter((row) => selectedData.includes(row.id));
        const ids = data.map((row) => row.id);
        dispatch(deleteRequest(token, { data: { ids } }, true));
    };

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => handleCloseFormModal()} >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit" >
                    {
                        actionType === ACTION_TYPE.DELETED ? "Yes" : "Get It"
                    }
                </Button>
            </Box>
        );
    };

    const handleOnConfirmCancelModal = () => {
        setConfirmModal(false);
    };

    const handleOnCloseConfirmCancelModal = () => {
        resetSearchTerm();
        setConfirmModal(false);
        setFormModal(false);
        setSearchTerm("");
        setSelectedData([]); //buat disabled button toolbar
    };

    const handleRowDoubleClick = (selection) => {
        navigate(`detail/${selection.id}`, { state: { type: typeData } });
    }

    const handleChangeType = (v) => {
        setTypeData(v);
    }

    const handleToolbarLeft = () => {
        return (
            <Box>
                <StatusBadge
                    key={"Hardcopy"}
                    status={"Hardcopy"}
                    isinfo={false}
                    colors={typeData === "Hardcopy" ? mainColor : neutral100}
                    bgColor={typeData === "Hardcopy" ? surfaceColor : neutral10}
                    onClick={() => handleChangeType("Hardcopy")}
                    style={{
                        marginRight: "16px",
                        borderRadius: "48px",
                        padding: "6px 16px 6px 16px",
                        border: typeData !== "Hardcopy" ? `1px solid ${neutral40}` : 'none'
                    }} >
                    Hardcopy
                </StatusBadge>
                <StatusBadge
                    key={"Softcopy"}
                    status={"Softcopy"}
                    isinfo={false}
                    colors={typeData === "Softcopy" ? mainColor : neutral100}
                    bgColor={typeData === "Softcopy" ? surfaceColor : neutral10}
                    onClick={() => handleChangeType("Softcopy")}
                    style={{
                        marginRight: "16px",
                        borderRadius: "48px",
                        padding: "6px 16px 6px 16px",
                        border: typeData !== "Softcopy" ? `1px solid ${neutral40}` : 'none'
                    }} >
                    Softcopy
                </StatusBadge >
            </Box >
        )
    }

    function _search() {
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _categoryFilter(category) {
        setCategoryFilter(category);
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter: category,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _statusFilter(status) {
        setStatusFilter(status);
        dispatch(
            getRequest({
                statusFilter: status,
                categoryFilter,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _dateFilter(date) {
        setDateFilter(date);
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter: date,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,

            })
        )
    }

    return (
        <>
            <Card style={{ boxShadow: "unset" }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                placeholder={`Search ${placeholder[searchBy]}`}
                                size="small" // Set the size to "small"
                                sx={{
                                    borderRadius, // Apply the desired border radius value
                                }}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    onKeyDown: handleKeyPress,
                                    startAdornment: <SearchIcon />,
                                    endAdornment: (
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                                IconComponent: ExpandMoreIcon,
                                            }}
                                            value={searchBy}
                                            sx={{
                                                width: 250,
                                                '& fieldset': {
                                                    border: 'unset',
                                                }, borderLeft: `2px solid #ddd`
                                            }}
                                            onChange={(e) => setSearchBy(e.target.value)}
                                        >
                                            <MenuItem value="customer_name">Customer Name</MenuItem>
                                            <MenuItem value="Brand">Brand</MenuItem>
                                            <MenuItem value="Applicant">Applicant</MenuItem>
                                            <MenuItem value="reference_number">No. Reference</MenuItem>
                                            <MenuItem value="registration_number">No. Registration</MenuItem>
                                        </TextField>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ height: "100%" }}
                                onClick={_search}>
                                <SearchIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                value={categoryFilter}
                                size="small"
                                disabled={dateFilter === ACTION_TYPE.DEFAULT_DATE}
                                SelectProps={{
                                    IconComponent: ExpandMoreIcon,
                                }}
                                onChange={(e) => _categoryFilter(e.target.value)}
                                sx={{
                                    borderRadius,
                                }} >
                                <MenuItem value={ACTION_TYPE.ALL_CATEGORY}>All service category</MenuItem>
                                {listCategory.map((category) => (
                                    <MenuItem value={category.id}>{category.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                value={statusFilter}
                                size="small"
                                disabled={dateFilter === ACTION_TYPE.DEFAULT_DATE}
                                SelectProps={{
                                    IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                                    // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                                    // IconComponent: ExpandLessIcon,
                                }}
                                onChange={(e) => _statusFilter(e.target.value)}
                                sx={{
                                    borderRadius, // Apply the desired border radius value
                                }} >
                                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All status</MenuItem>
                                {listStatus.map((status) => (
                                    <MenuItem value={status.id}>{status.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                value={dateFilter}
                                size="small"
                                SelectProps={{
                                    IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                                    // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                                    // IconComponent: ExpandLessIcon,
                                }}
                                onChange={(e) => _dateFilter(e.target.value)}
                                style={{
                                    borderRadius, // Apply the desired border radius value
                                }}  >
                                <MenuItem value={ACTION_TYPE.DEFAULT_DATE}>Select Date</MenuItem>
                                <MenuItem value="all_date">All date</MenuItem>
                                <MenuItem value="this_week">This week</MenuItem>
                                <MenuItem value="this_month">This month</MenuItem>
                                <MenuItem value="last_6_months">Last 6 months</MenuItem>
                                <MenuItem value="this_year">This year</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Request"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        handleFormModal={handleFormModal}
                        selectedData={selectedData}
                        handleSelectionChange={handleSelectionChange}
                        handleRowDoubleClick={handleRowDoubleClick}
                        theme={theme}
                        sortModel={sortModel}
                        search={searchValue}
                        dateChoosed={dateFilter}
                        toolBarLeft={handleToolbarLeft}
                        onSortModelChange={handleSortModelChange}
                        modeCRUD={MODE_CRUD_WITH.PAGING}
                        stateUpdate={{ customer: formValues, type: typeData }}
                        linkCRUD={{ create: "add", update: `edit/${selectedData}`, createOption: "hybrid" }}
                    />
                </CardContent>
            </Card>
            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={handleSubmit}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                onConfirmModal={handleOnConfirmCancelModal}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
        </>
    )
}

export default Request;