import axios from "../core/axios_config";

export function formatNetworkErrorMessage(params) {
  if (params === "Network Error") {
    params = "Server sedang gangguan... (#BAP99)";
  }

  return params;
}

export const TabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};

export function formatCurrency(amount, type = { country: "id-ID", currency: 'IDR' }) {
  return new Intl.NumberFormat(type.country, {
    style: "currency",
    currency: type.currency,
    minimumFractionDigits: 0,
  }).format(amount);
}

export function formatCurrencyWithoutLable(amount) {
  // Convert the amount to a string
  let amountString = String(amount);

  // Split the amount into integer and decimal parts
  let [integerPart, decimalPart] = amountString.split('.');

  // Remove leading zeros from the integer part
  integerPart = integerPart.replace(/^0+(?=\d{1,})/, '');

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Reassemble the amount with the formatted integer part
  amountString = (integerPart || '0') + (decimalPart ? '.' + decimalPart : '');

  return amountString;
}


// Recursive function to find a menu item by link in the menu structure
export const findMenuItemByLink = (items, link) => {
  for (const item of items) {
    if (item.link === link) {
      return item;
    } else if (item.children.length > 0) {
      const childResult = findMenuItemByLink(item.children, link);
      if (childResult) {
        return childResult;
      }
    }
  }
  return null;
};

const isDateLike = (obj) => {
  // Check if an object has properties related to dates
  const dateKeys = [
    "$L",
    "$u",
    "$d",
    "$x",
    "$y",
    "$M",
    "$D",
    "$W",
    "$H",
    "$m",
    "$s",
    "$ms",
  ];
  return dateKeys.some((key) => key in obj);
};

// OLD


export const cloneObjectWithEmptyStrings = (obj, keyObjectToString = 'certificates') => {
  const result = {};

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      //vat 11%
      if (obj[key].length === 1 && (obj[key][0] === 1 || obj[key][0] === '11')) {
        // [1] untuk field dengan type CHECKBOX, seperti use_residential_address, permanent dll
        result[key] = "";
      } else {
        result[key] = obj[key].map((item) => {
          // handle column certificates atau column attachment di employe -> step3  / di sales management -> sales order
          if (key === keyObjectToString) {
            // debugger;
            return "";
          } else if (item !== null && typeof item === "object") {
            if (isDateLike(item)) {
              // Handle date-like objects
              return "";
            } else {
              // Recursively process non-date-like objects
              return cloneObjectWithEmptyStrings(item, keyObjectToString);
            }
          } else {
            return item;
          }
        });
      }
    } else if (obj[key] !== null && typeof obj[key] === "object") {
      if (isDateLike(obj[key])) {
        // Handle date-like objects
        result[key] = "";
      } else {
        // Recursively process non-date-like objects
        result[key] = cloneObjectWithEmptyStrings(obj[key], keyObjectToString);
      }
    } else {
      result[key] = "";
    }
  }
  return result;
};


export const createEmptyErrors = (data) => {
  const errors = {};
  for (const key in data) {
    errors[key] = "";
  }
  return errors;
}

// NEW
/*export const cloneObjectWithEmptyStrings = (obj) => {
  const result = {};

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length === 1 && obj[key][0] === 1) {
        // Handle checkboxes
        result[key] = "";
      } else {
        result[key] = obj[key].map((item) => {
          if (item !== null && typeof item === "object") {
            if (isDateLike(item)) {
              // Handle date-like objects
              return {};
            } else {
              // Recursively process non-date-like objects
              return cloneObjectWithEmptyStrings(item);
            }
          } else {
            return item;
          }
        });
      }
    } else if (obj[key] !== null && typeof obj[key] === "object") {
      if (isDateLike(obj[key])) {
        // Handle date-like objects
        result[key] = {};
      } else {
        // Recursively process non-date-like objects
        result[key] = cloneObjectWithEmptyStrings(obj[key]);
      }
    } else {
      // Handle non-object values (including strings)
      result[key] = "";
    }
  }

  return result;
};*/

export const formatSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  } else {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  }
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
};
export const formatDateTime = (inputDate) => {
  const date = new Date(inputDate);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${hour}:${minute}`;
  return formattedDate;
};

export const saveResponseToFile = (url, token) => {
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const responseData = response.data;

      // Generate a timestamp-based file name
      const timestamp = new Date().toISOString().replace(/[:.]/g, "_");
      const outputFile = `response_${timestamp}.json`;

      // Save the response to a file
      const blob = new Blob([JSON.stringify(responseData, null, 2)], {
        type: "application/json",
      });
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element for triggering the download
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open the download in a new tab (optional)

      // Programmatically trigger the download
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      a.dispatchEvent(clickEvent);

      // Clean up the URL object
      window.URL.revokeObjectURL(url);

      console.log(`Response saved to ${outputFile}`);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value) => {
  const dateTime = new Date(value);
  const utcFromLocal = new Date(
    dateTime.getTime() + getTimezoneOffset(dateTime)
  );

  // Extract year, month, and day components
  const year = utcFromLocal.getUTCFullYear();
  const month = String(utcFromLocal.getUTCMonth() + 1).padStart(2, "0"); // Add 1 to month (0-based) and pad with leading zeros
  const day = String(utcFromLocal.getUTCDate()).padStart(2, "0"); // Pad with leading zeros

  // Format as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;
  // debugger;
  return formattedDate;
};

export const localToUTC = (value) => {
  const dateTime = new Date(value);
  const utcFromLocal = new Date(
    dateTime.getTime() - getTimezoneOffset(dateTime)
  );

  // Extract year, month, and day components
  const year = utcFromLocal.getUTCFullYear();
  const month = String(utcFromLocal.getUTCMonth() + 1).padStart(2, "0"); // Add 1 to month (0-based) and pad with leading zeros
  const day = String(utcFromLocal.getUTCDate()).padStart(2, "0"); // Pad with leading zeros

  // Format as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const toCapitalize = (str) => {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export const paramURLRequest = {
  token: "token",
  limit: "limit",
  search: "search",
  status: "status",
  sortDirection: "sortDirection",
  sortBy: "sortBy",
};

export const INPUT_TYPE = {
  SELECT_ONE: "SELECT_ONE ONE",
  SELECT_MULTIPLE: "SELECT_MULTIPLE",
  SELECT_ONE_AUTOCOMPLETE: "SELECT_ONE_AUTOCOMPLETE",
  SELECT_MULTIPLE_AUTOCOMPLETE: "SELECT_MULTIPLE_AUTOCOMPLETE",
  SELECT_ONE_AUTOCOMPLETE_COUNTRY: "SELECT_ONE_AUTOCOMPLETE_COUNTRY",
  TEXT: "text",
  NUMBER: "number",
  CURRENCY: "currency",
  POINT: "point", // bisa digunakan untuk score 99.2
  PHONE_NUMBER: "phone number", // bisa digunakan untuk no hp +62
  DISCOUNT: "discount", // minamal 0 dan maximal 100 %
  PASSWORD: "password",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  DATE: "date",
  DIVIDER: "divider",
  TIME: "time",
  TYPOGRAPHY: 'Typography',
  TYPOGRAPHY_DYNAMIC: 'Typography Dynamic',
  UNIT: 'unit', // such as kg, g, mg
  FILE: "file",
  ONLY_ONE_FILE: 'only one file'
};

export const ACTION_TYPE = {
  DEFAULT_STATUS: "CHOOSE STATUS",
  DEFAULT_CATEGORY: "CHOOSE CATEGORY",
  DEFAULT_POSITION: "CHOOSE POSITION",
  DEFAULT_TYPE_STAGE: "CHOOSE TYPE STAGE",
  DEFAULT_CATEGORY_TIME_OFF: "CHOOSE CATGEORY TIME OFF",
  DEFAULT_ACTION: "CHOOSE ACTION",
  DEFAULT_DATE: "CHOOSE DATE",
  DEFAULT_TYPE: "CHOOSE TYPE",
  ALL_STATUS: "ALL STATUS",
  ALL_TYPE_STAGE: "ALL TYPE_STAGE",
  ALL_CATEGORY: "ALL_CATEGORY",
  ALL_CATEGORY_TIME_OFF: "ALL CATEGORY TIME OFF",
  ALL_POSITION: "ALL POSITION",
  ALL_ACTION: "ALL ACTION",
  ALL_DATE: "ALL DATE",
  ALL_TYPE: 'ALL TYPE',
  DELETED: "DELETED",
  DETAIL: "DETAIL",
  EDITED: "EDITED",
  ADD: "ADD",
};

export const SELECT_OPTION = {
  DEFAULT_VALUE_SELECT_ONE: -1,
  DEFAULT_VALUE_SELECT_ONE_NULL: null,
  DEFAULT_VALUE_SELECT_MULTIPLE: [],
};

export const USER_ACCESS = {
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const MODE_CRUD_WITH = {
  MODAL: "CRUD dengan Modal",
  PAGING: "CRUD dengan Paging",
};

export function pxToMm(pixels, dpi = 96) {
  // 1 inch = 25.4 mm
  return (pixels / dpi) * 25.4;
}
