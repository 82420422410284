import {
  IconButton,
  Modal,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  modal,
  modalHeader,
  modalActionButton,
  btnWhite,
  confirmDelete,
  secondColor,
} from "../util/style";
import confirmSaveAsDraftIMG from "./../assets/save-as-draft.png";
import CloseIcon from "@mui/icons-material/Close";

const ModalConfirmSaveAsDraft = ({
  confirmModal,
  onConfirmModal,
  onCloseModal,
  children,
  message,
}) => {
  return (
    <Modal open={confirmModal} onClose={onConfirmModal}>
      <Box
        sx={{
          ...modal,
        }}
      >
        <Box
          sx={{
            ...modalHeader,
          }}
        >
          <Typography
            variant="h5"
            component="div"
            style={{ fontWeight: "bold" }}
          >
            Save as Draft
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box style={{ ...confirmDelete }}>
          <img src={confirmSaveAsDraftIMG} alt="Confirmation Save as Draft" />
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: "700" }}
          >
            Successfully saved as draft
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: secondColor, fontStyle: "italic", fontWeight: "400" }}
          >
            {message ? message : "You want to continue the application process?"}
          </Typography>
        </Box>
        <Box
          sx={{
            ...modalActionButton,
            justifyContent: "flex-end",
          }}
        >
          <Box>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={onCloseModal}
              id="cancel-confirm"
            >
              Back to Main
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="button"
              onClick={onConfirmModal}
              id="yes-confirm"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConfirmSaveAsDraft;
