// import React, { useState, useEffect } from "react";
// import dayjs from "dayjs";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   FormControl,
//   Grid,
//   Button,
//   MenuItem,
//   Card,
//   CardContent,
//   Typography,
//   TextField,
//   Modal,
//   Box,
//   InputLabel,
//   FormControlLabel,
//   Select,
//   Autocomplete,
//   Checkbox,
//   FormHelperText,
//   InputAdornment,
//   Divider,
// } from "@mui/material";
// import "dayjs/locale/en";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//
// import { makeStyles, useTheme } from "@mui/styles";
// import SearchIcon from "./../../../assets/search.svg";
// import successIMG from "./../../../assets/success.png";
// import PrinterIcon from "./../../../assets/printer.png";
// import CalendarIcon from "./../../../assets/calendar.png";
// import SearchIconBlack from "./../../../assets/search-black.svg";
// import RemoveIcon from "./../../../assets/minus.svg";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//
// import {
//   borderRadius,
//   thirdColor,
//   btnWhite,
//   formGroup,
//   StatusSwitch,
//   StatusBadge,
//   defaultStylePage,
//   justifyContentBetween,
//   warningSurfaceColor,
//   successSurfaceColor,
//   warningMainColor,
//   successMainColor,
//   dangerMainColor,
//   dangerSurfaceColor,
//   infoSurfaceColor,
//   infoMainColor,
//   btnGreen,
//   btnLightBlue,
//   justifyContentCenter,
//   mainColor,
//   alignItemsCenter,
//   inputDate,
//   CustomOpenPickerButton,
//   removeButton,
//   justifyContentEnd,
//   FacebookCircularProgress,
//   fourColor,
//   secondColor,
// } from "../../../util/style";
// import axios from "../../../core/axios_config";
// import Form, {
//   TimePickerInput,
//   CheckboxGroupInput,
//   DatePickerInput,
//   RadioGroupInput,
//   SelectMultiple,
//   SelectMultipleAutocomplete,
//   SelectOne,
//   SelectOneAutocomplete,
//   TextInput,
//   TextArea,
//   PasswordInputWithValidation,
//   FilePicker,
// } from "../../../components/Form";
// import { addApplication, deleteApplication, editApplication, getApplication } from "./clients/actions";
// import {
//   ACTION_TYPE,
//   USER_ACCESS as _,
//   findMenuItemByLink,
//   INPUT_TYPE,
//   SELECT_OPTION,
//   cloneObjectWithEmptyStrings,
//   MODE_CRUD_WITH,
// } from "../../../util/function";
// import DataTable from "../../../components/Table";
// import ConfirmDelete from "../../../components/ConfirmDelete";
// import ModalConfirmCancel from "../../../components/ConfirmCancel";
// import LeftDrawer from "../../../components/LeftDrawer";
// import { TransitionAlerts } from "../../../components/Alert";
//
// const Application = () => {
//   /* -------------------------------------------------------------------------- */
//   /*                                   STYLES                                   */
//   /* -------------------------------------------------------------------------- */
//
//   const theme = useTheme();
//   const useStyles = defaultStylePage;
//
//   /* -------------------------------------------------------------------------- */
//   /*                           USER HAVE PERMISSION ???                          */
//   /* -------------------------------------------------------------------------- */
//   const [currentPath, setCurrentPath] = useState("");
//   const [userAccess, setUserAccess] = useState([
//     _.CREATE,
//     _.READ,
//     _.UPDATE,
//     _.DELETE,
//   ]);
//   const { menus } = useSelector((state) => state.auth);
//
//   useEffect(() => {
//     // Get the current path when the component mounts
//     const path = window.location.pathname;
//     setCurrentPath(path);
//   }, []);
//
//   useEffect(() => {
//     if (menus.length > 0) {
//       // Find the menu item with the matching pathname
//       const menuItem = findMenuItemByLink(menus, currentPath);
//
//       if (menuItem) {
//         // Filter permissions with has_access set to true
//         const filteredPermissions = menuItem.permission.filter(
//           (permission) => permission.has_access
//         );
//
//         // Extract the permission actions and update userAccess state
//         const accessNames = filteredPermissions.map((permission) => {
//           const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
//           return action.toUpperCase(); // Convert to uppercase
//         });
//         setUserAccess(accessNames);
//       }
//     }
//   }, [currentPath, menus]);
//
//   useEffect(() => {
//     console.log(currentPath, userAccess);
//   }, [userAccess]);
//   */
//   /* -------------------------------------------------------------------------- */
//   /*                                    STATE                                   */
//   /* -------------------------------------------------------------------------- */
//   const ANOTHER_ACTION_TYPE = {
//     ADD_SALES_ORDER: 'ADD_SALES_ORDER',
//     CHECK_SALES_QUOTATION: 'CHECK_SALES_QUOTATION'
//   }
//   const { token } = useSelector((state) => state.auth);
//   const [formModal, setFormModal] = useState(false);
//   const [confirmModal, setConfirmModal] = useState(false);
//   const [selectedData, setSelectedData] = useState([]);
//   const [userDeleted, setUserDeleted] = useState(null);
//   const [search, setSearch] = useState("");
//   const [searchBy, setSearchBy] = useState("No. Reference");
//   const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
//   const [sortDirection, setSortDirection] = useState("desc");
//   const [sortBy, setSortBy] = useState("id");
//   const [sortModel, setSortModel] = useState([]);
//   const [page, setPage] = useState(1);
//   const [pageDB, setPageDB] = useState(0);
//   const [limit, setLimit] = useState(10);
//   const [title, setTitle] = useState("Add Sales Order");
//   const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
//   const [options, setOptions] = useState({});
//   const [type, setType] = useState(ACTION_TYPE.DEFAULT_TYPE);
//   const [dateFilter, setDateFilter] = useState(ACTION_TYPE.ALL_DATE);
//   const [showDatePicker, setShowDatePicker] = useState(false);
//   const [showSelectDate, setShowSelectDate] = useState(false);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [startDateElm, setStartDateElm] = useState(null);
//   const [endDateElm, setEndDateElm] = useState(null);
//   const [periodElm, setPeriodElm] = useState(null);
//   const [period, setPeriod] = useState(null);
//   const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
//   const dispatch = useDispatch();
//
//
//   const columns = [
//     {
//       field: "number",
//       headerName: "No. Reference",
//       width: 180,
//       disableColumnMenu: true,
//       renderCell: (params) => (
//         <div>
//           <a
//             // onClick={() => handleFormModal(ACTION_TYPE.DETAIL, params.row)}
//             style={{ textDecoration: "none", cursor: "grab", color:'blue' }}
//           >
//           {params.value}
//           </a>
//         </div>
//       ),
//     },
//     {
//       field: "client_name",
//       headerName: "Client Name",
//       width: 190,
//       disableColumnMenu: true,
//     },
//     {
//       field: "category",
//       headerName: "Category",
//       width: 180,
//       disableColumnMenu: true,
//     },
//
//     {
//       field: "business_field",
//       headerName: "Business Field",
//       width: 180,
//       disableColumnMenu: true,
//     },
//     {
//       field: "brand",
//       headerName: "Brand",
//       width: 180,
//       disableColumnMenu: true,
//     },
//     {
//       field: "applicant",
//       headerName: "Applicant",
//       width: 180,
//       disableColumnMenu: true,
//     },
//     {
//       field: "submitted",
//       headerName: "Submitted",
//       width: 180,
//       disableColumnMenu: true,
//     },
//     {
//       field: "due_date",
//       headerName: "Due Date",
//       width: 180,
//       disableColumnMenu: true,
//     },
//     {
//       field: "last_update",
//       headerName: "Last Update",
//       width: 180,
//       disableColumnMenu: true,
//     },
//     {
//       field: "status",
//       headerName: "Status",
//       width: 180,
//       disableColumnMenu: true,
//       valueGetter: (params) => params.value, // Return the status value as a string
//       renderCell: (params) => {
//         const statusColors = {
//           Draft: { bgColor: warningSurfaceColor, colors: warningMainColor },
//           Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
//           Approved: { bgColor: successSurfaceColor, colors: successMainColor },
//           Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
//           // Add more statuses as needed
//         };
//
//         const statusInfo = statusColors[params.value] || {
//           bgColor: infoSurfaceColor,
//           colors: infoMainColor,
//         };
//         return (
//           <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
//             {params.value}
//           </StatusBadge>
//         )
//       },
//     },
//
//   ];
//
//   const [fields, setFields] = useState([]);
//
//
//   const [formValues, setFormValues] = useState({});
//
//   // const [options, setOptions] = useState({});
//   const [errors, setErrors] = useState({});
//
//
//   const initialFormValues = (fields) => {
//     const nonDividerAndTypographyFields = fields.filter(
//       (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
//     );
//
//     const initialValues = {};
//
//     nonDividerAndTypographyFields.forEach((fieldGroup) => {
//       if (fieldGroup.children) {
//         initialValues[fieldGroup.title] =
//           fieldGroup.title === "Sub Service"
//             ? [initialSubService]
//             : fieldGroup.title === "Attachment"
//               ? initialAttachment
//               : fieldGroup.children.reduce(
//                 (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
//                 {}
//               );
//       }
//     });
//
//     return {
//       ...initialValues,
//     };
//   };
//
//   const initialErrors = (fields) => {
//     const nonDividerAndTypographyFields = fields.filter(
//       (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
//     );
//
//     const initialErrorValues = {};
//
//     nonDividerAndTypographyFields.forEach((fieldGroup) => {
//       if (fieldGroup.children) {
//         initialErrorValues[fieldGroup.title] =
//           fieldGroup.title === "Sub Service"
//             ? [initialErrorSubService]
//             : fieldGroup.title === "Attachment"
//               ? initialErrorAttachment
//               : fieldGroup.children.reduce(
//                 (acc, field) => ({ ...acc, [field.name]: "" }),
//                 {}
//               );
//       }
//     });
//
//     return initialErrorValues;
//   };
//
//
//   const handlePeriodChange = (date) => {
//     if (dayjs(date).isValid()) {
//       const formattedDate = dayjs(date).format("YYYY-MM");
//       setPeriod(formattedDate);
//       setPeriodElm(date);
//     } else {
//       console.error("Invalid start date:", date);
//     }
//   };
//   const {
//     // data: rows,
//     message,
//     isLoading,
//     pagination,
//   } = useSelector((state) => state.users);
//
//
//   const rows = [
//     {
//       "id": 1,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Draft"
//     },
//     {
//       "id": 2,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Waiting"
//     },
//     {
//       "id": 3,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Draft"
//     },
//     {
//       "id": 4,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Waiting"
//     },
//     {
//       "id": 5,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 6,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 7,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 8,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 9,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 10,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 11,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 12,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 13,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//   ]
//
//
//
//   const {
//     modal: { isActive },
//   } = useSelector((state) => state.global);
//
//   useEffect(() => {
//     setSelectedData([]); //buat disabled button toolbar
//   }, [dispatch]);
//
//   useEffect(() => {
//     const fetchOptions = async (apiEndpoint, name) => {
//       try {
//         const response = await axios.get(apiEndpoint, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//
//         // Check if the response data has the 'fullname' property
//         const modifiedData = response.data.data.map((item) => {
//           if (item.hasOwnProperty("fullname")) {
//             return {
//               ...item,
//               name: item.fullname,
//             };
//           } else {
//             return item;
//           }
//         });
//
//         // console.log("fetchOptions", name, modifiedData);
//         setOptions((prevOptions) => ({
//           ...prevOptions,
//           [name]: modifiedData,
//         }));
//       } catch (error) {
//         console.error(`Error fetching ${name} options:`, error);
//       }
//     };
//
//     fields.forEach((field) => {
//       const { name, apiEndpoint } = field;
//       if (apiEndpoint) {
//         fetchOptions(apiEndpoint, name);
//       }
//     });
//   }, []);
//
//   useEffect(() => {
//     if (status === ACTION_TYPE.DEFAULT_STATUS) {
//       setType(ACTION_TYPE.DEFAULT_TYPE);
//     }
//   }, [status]);
//
//   useEffect(() => {
//     if (
//       type !== ACTION_TYPE.DEFAULT_TYPE &&
//       status !== ACTION_TYPE.DEFAULT_STATUS
//     ) {
//
//       dispatch(
//         getApplication({
//           token,
//           limit,
//           search,
//           status,
//           sortDirection,
//           sortBy,
//           type,
//         })
//       );
//     } else {
//       setSelectedData([]); //buat disabled button toolbar
//     }
//   }, [type, status]);
//
//   // useEffect(() => {
//   //   if (type !== ACTION_TYPE.DEFAULT_TYPE) {
//   //     dispatch(
//   //       getApplication({
//   //         token,
//   //         limit,
//   //         search,
//   //         status,
//   //         sortDirection,
//   //         sortBy,
//   //         type,
//   //       })
//   //     );
//   //   } else {
//   //     setSelectedData([]); //buat disabled button toolbar
//   //   }
//   // }, [type]);
//
//   const initialSubService = {
//     sub_service_id: "",
//     subtotal_to_rp: "0",
//     discount: "",
//     vat: "",
//     vat_to_rp: "0",
//     total_to_rp: "0",
//     criteria_id: "",
//     description: "",
//   };
//
//   const initialAttachment = {
//     attachment: [""],
//   };
//
//   const initialErrorSubService = {
//     sub_service_id: "",
//     discount: "",
//     vat: "",
//     criteria_id: "",
//     description: "",
//   };
//   const initialErrorAttachment = {
//     attachment: [""],
//   };
//
//   const handleAddFieldRow = (sectionTitle) => {
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [sectionTitle]: [
//         ...prevValues[sectionTitle],
//         sectionTitle === "Sub Service" && initialSubService,
//       ], // Add an empty row
//     }));
//
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [sectionTitle]: [
//         ...prevErrors[sectionTitle],
//         sectionTitle === "Sub Service"
//         && initialErrorSubService
//         ,
//       ], // Add an empty error object
//     }));
//   };
//
//   const handleRemoveFieldRow = (sectionTitle, rowIndex) => {
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [sectionTitle]: prevValues[sectionTitle].filter(
//         (row, index) => index !== rowIndex
//       ),
//     }));
//
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [sectionTitle]: prevErrors[sectionTitle].filter(
//         (error, index) => index !== rowIndex
//       ),
//     }));
//   };
//
//
//
//
//   useEffect(() => {
//     const storedOptions = JSON.parse(localStorage.getItem("options_step_one"));
//
//     if (!storedOptions || Object.keys(storedOptions).length === 0) {
//       // Options data not found in localStorage, fetch it
//       const fetchData = async () => {
//         try {
//           const fetchedOptions = {};
//
//           for (const section of fields) {
//             if (section.children) {
//               for (const field of section.children) {
//                 const { name, apiEndpoint } = field;
//
//                 if (apiEndpoint) {
//                   const response = await axios.get(apiEndpoint, {
//                     headers: {
//                       Authorization: `Bearer ${token}`,
//                     },
//                   });
//
//                   fetchedOptions[name] = response.data.data;
//                 }
//               }
//             }
//           }
//
//           // Store fetched options in localStorage
//           localStorage.setItem(
//             "options_step_one",
//             JSON.stringify(fetchedOptions)
//           );
//
//           // Set options in state
//           setOptions(fetchedOptions);
//         } catch (error) {
//           console.error("Error fetching options:", error);
//         }
//       };
//
//       fetchData();
//     } else {
//       // Options data found in localStorage, set it in state
//       setOptions(storedOptions);
//     }
//   }, [token]);
//   useEffect(() => {
//     console.log('ALLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL')
//     console.log(errors);
//     console.log(formValues);
//     if (actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER || actionType === ACTION_TYPE.EDITED) {
//       setIsLoadedFormValues(true)
//     }
//
//   }, [errors, formValues]);
//
//   const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
//     setFormValues((prevValues) => {
//       const updatedValues = { ...prevValues };
//       if (!isNaN(rowIndex)) {
//         updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
//       } else {
//         updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
//       }
//
//       return updatedValues;
//     });
//   };
//
//   const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
//     if (!isNaN(rowIndex)) {
//       setFormValues((prevValues) => {
//         const updatedValues = { ...prevValues };
//         updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate
//
//         return updatedValues;
//       });
//     } else {
//       setFormValues((prevValues) => {
//         const updatedValues = { ...prevValues };
//         updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
//         return updatedValues;
//       });
//
//     }
//   };
//
//   const handleFileSelect = (
//     file,
//     rowIndex,
//     sectionTitle,
//     fieldName,
//     fileIndex
//   ) => {
//     // debugger;
//     if (!isNaN(rowIndex)) {
//       setFormValues((prevValues) => {
//         const updatedValues = { ...prevValues };
//         const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];
//
//         // Check if the certificate at certIndex already exists
//         if (fileColumn[fileIndex] !== undefined) {
//           // Update the existing certificate at fileIndex
//           fileColumn[fileIndex] = '';
//         }
//
//         return updatedValues;
//       });
//
//     } else {
//       setFormValues((prevValues) => {
//         const updatedValues = { ...prevValues };
//         const fileColumn = updatedValues[sectionTitle][fieldName];
//
//         // Check if the certificate at certIndex already exists
//         if (fileColumn[fileIndex] !== undefined) {
//           // Update the existing certificate at fileIndex
//           fileColumn[fileIndex] = file;
//         }
//
//         return updatedValues;
//       });
//
//     }
//   };
//
//   const handleInputChange = (
//     event,
//     index,
//     sectionTitle,
//     field,
//     fileIndex
//   ) => {
//     const { name, value, type } = event.target;
//     // debugger
//
//     if (type === "file") {
//       const file = event.target.files[0];
//       if (file) {
//         const fileError = validateFileInput(file, field);
//         // debugger;
//         if (fileError) {
//           setErrors((prevErrors) => {
//             const updatedErrors = { ...prevErrors };
//             if (!isNaN(index)) {
//               updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
//             } else {
//               updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
//             }
//
//             return updatedErrors;
//           });
//           return;
//         }
//
//         setErrors((prevErrors) => {
//           const updatedErrors = { ...prevErrors };
//           // debugger;
//           if (!isNaN(index)) {
//             updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
//           } else {
//             updatedErrors[sectionTitle][field.name][fileIndex] = "";
//           }
//           return updatedErrors;
//         });
//
//         // Call the handleFileSelect function with the file if it's valid
//         if (handleFileSelect) {
//           handleFileSelect(file, index, sectionTitle, name);
//         }
//       } else {
//         console.error("there is no file");
//       }
//     } else {
//       // Handle regular input fields
//
//       setFormValues((prevValues) => {
//         const updatedValues = { ...prevValues };
//         if (index !== undefined) {
//           updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
//             (item, i) => (i === index ? { ...item, [name]: value } : item)
//           );
//         } else {
//           updatedValues[sectionTitle] = {
//             ...updatedValues[sectionTitle],
//             [name]: value,
//           }
//
//         }
//         return updatedValues;
//
//       });
//
//       setErrors((prevErrors) => {
//         const updatedErrors = { ...prevErrors };
//         const fieldValue = value;
//         const fieldErrors = validateFieldHelper(fieldValue, field);
//
//         if (index !== undefined) {
//           updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
//             (item, i) =>
//               i === index ? { ...item, [name]: fieldErrors } : { ...item }
//           );
//         } else {
//           updatedErrors[sectionTitle] = {
//             ...updatedErrors[sectionTitle],
//             [name]: fieldErrors,
//           };
//         };
//         return updatedErrors;
//       });
//
//     }
//   };
//   // const handleInputChange = (event, index, sectionTitle, field) => {
//   //   const { name, value } = event.target;
//   //   const updatedErrors = { ...errors };
//
//   //   setFormValues((prevValues) => {
//   //     const updatedValues = { ...prevValues };
//   //     if (
//   //       index !== undefined &&
//   //       (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
//   //     ) {
//   //       updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
//   //         (item, i) => (i === index ? { ...item, [name]: value } : item)
//   //       );
//   //     } else {
//   //       updatedValues[sectionTitle] = {
//   //         ...updatedValues[sectionTitle],
//   //         [name]: value,
//   //       };
//   //     }
//
//   //     // Buat column residential address
//   //     if (name === "use_as_residential_address" && value.includes(1)) {
//   //       updatedValues[sectionTitle]["residential_address"] =
//   //         updatedValues[sectionTitle]["citizen_id_address"];
//   //     }
//
//   //     if (
//   //       name === "residential_address" &&
//   //       value !== updatedValues[sectionTitle]["citizen_id_address"]
//   //     ) {
//   //       updatedValues[sectionTitle]["use_as_residential_address"] = [];
//   //     }
//
//   //     if (
//   //       name === "citizen_id_address" &&
//   //       updatedValues[sectionTitle]["use_as_residential_address"].length > 0 &&
//   //       updatedValues[sectionTitle]["use_as_residential_address"].includes(1)
//   //     ) {
//   //       updatedValues[sectionTitle]["residential_address"] =
//   //         updatedValues[sectionTitle]["citizen_id_address"];
//   //     }
//
//   //     return updatedValues;
//   //   });
//
//   //   setErrors((prevErrors) => {
//   //     const fieldValue = value;
//   //     const fieldErrors = validateFieldHelper(fieldValue, field);
//
//   //     if (
//   //       index !== undefined &&
//   //       (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
//   //     ) {
//   //       updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
//   //         (item, i) =>
//   //           i === index ? { ...item, [name]: fieldErrors } : { ...item }
//   //       );
//   //     } else {
//   //       updatedErrors[sectionTitle] = {
//   //         ...updatedErrors[sectionTitle],
//   //         [name]: fieldErrors,
//   //       };
//   //     }
//   //     return updatedErrors;
//   //   });
//   // };
//
//   const handleInputBlur = (fieldName, sectionTitle, index, event) => {
//     const { name, value } = event.target;
//     validateField(sectionTitle, fieldName, index, { name, value });
//     // debugger;
//   };
//
//   const validateFileInput = (
//     fieldValue,
//     field,
//     sectionTitle,
//     index,
//     sectionErrors
//   ) => {
//     if (fieldValue instanceof File) {
//       const allowedExtensions = field.allowedExtensions || [
//         ".pdf",
//         ".doc",
//         ".docx",
//         ".jpeg",
//         ".jpg",
//         ".png",
//       ];
//       const size = field.maximumSizeInMB || 10;
//       const maxSizeInBytes = size * 1024 * 1024; // MB
//
//       const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
//       if (!allowedExtensions.includes(`.${fileExtension}`)) {
//         return "Invalid file extension. Please select a file with a valid extension.";
//       } else if (fieldValue.size > maxSizeInBytes) {
//         return `File size exceeds the maximum allowed size of ${size} MB.`;
//       }
//
//     }
//
//     return "";
//   };
//
//
//   const validateField = (sectionTitle, fieldName, index, { value }) => {
//     const newErrors = { ...errors };
//     const fieldGroup = fields.find((group) => group.title === sectionTitle);
//
//     if (fieldGroup && fieldGroup.children) {
//       const field = fieldGroup.children.find(
//         (field) => field.name === fieldName
//       );
//
//       if (field) {
//         const { name } = field;
//
//         if (
//           !isNaN(index) &&
//           (sectionTitle === "Sub Service")
//           // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
//         ) {
//           const fieldValue = formValues[sectionTitle][index][name] || value;
//           newErrors[sectionTitle][index][name] = validateFieldHelper(
//             fieldValue,
//             field
//           );
//         } else {
//           const fieldValue = formValues[sectionTitle][name] || value;
//           newErrors[sectionTitle][name] = validateFieldHelper(
//             fieldValue,
//             field
//           );
//         }
//       }
//     }
//     setErrors(newErrors);
//   };
//
//   const validateFieldHelper = (fieldValue, field) => {
//     const { required, validation, errorMessage, label, type } = field;
//
//     // Format the date fieldValue if the type is DATE
//     if (type === INPUT_TYPE.DATE) {
//       fieldValue = dayjs(fieldValue).isValid()
//         ? dayjs(fieldValue).format("DD/MM/YYYY")
//         : "";
//       console.log(fieldValue, "fieldValue");
//     }
//
//     if (
//       required &&
//       (String(fieldValue).trim() === "" ||
//         fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
//         fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
//     ) {
//       return `${label} is required`;
//     } else if (
//       fieldValue &&
//       fieldValue.length &&
//       validation &&
//       !validation(fieldValue)
//     ) {
//       return errorMessage || `${label} is invalid`;
//     } else {
//       return "";
//     }
//   };
//
//
//   // const resetFormValues = () => {
//   //   setFormValues(
//   // Object.assign({}, initialFormValues(fields), actionType !== 'ADD_NEW_GROUP'
//   //       ? {
//   //         id: null,
//   //       }
//   //       : {})
//   //   );
//   // };
//   /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */
//
//   // const initialFormValues = (type = "fields") => {
//   //   if (type === "columns") {
//   //     return columns.reduce(
//   //       (acc, field) => ({ ...acc, [field.field]: "" }),
//   //       {}
//   //     );
//   //   } else {
//   //     const nonDividerAndTypographyFields = fields.filter(
//   //       (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
//   //     );
//   //     return nonDividerAndTypographyFields.reduce(
//   //       (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
//   //       {}
//   //     );
//   //   }
//   // };
//
//   // const initialErrors = fields
//   //   .filter(
//   //     (field) =>
//   //       field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
//   //   )
//   //   .reduce((acc, field) => ({ ...acc, [field.name]: "" }), {});
//
//   // const [formValues, setFormValues] = useState(
//   // Object.assign({}, initialFormValues("fields"), { id: null, })
//   // );
//   // const [detailRow, setDetailRow] = useState({});
//   // const [errors, setErrors] = useState(initialErrors);
//
//   useEffect(() => {
//     if (actionType !== 'ADD_NEW_GROUP' && actionType !== actionType.DELETE) {
//       localStorage.setItem("sales-management/sales-order/formValues", JSON.stringify(formValues));
//     }
//
//
//   }, [formValues, actionType]);
//
//   useEffect(() => {
//     console.log('-----------------FORM VALUES - SALES ORDER ----------')
//     console.log(formValues)
//     console.log('-----------------ERRORS - SALES ORDER ----------')
//     console.log(errors)
//
//   }, [formValues, errors]);
//
//   useEffect(() => {
//     const filteredRows = rows.filter((row) => selectedData.includes(row.id));
//     // console.log(filteredRows, "filteredRows");
//   }, [selectedData]);
//
//   useEffect(() => {
//     // console.log(sortModel, "newSortModel");
//     if (sortModel && sortModel.length) {
//       const { field, sort } = sortModel[0];
//       setSortBy(field);
//       setSortDirection(sort);
//     }
//   }, [sortModel]);
//
//   useEffect(() => {
//     if (isActive === true) {
//       dispatch(
//         getApplication({
//           token,
//           limit,
//           search,
//           status,
//           sortDirection,
//           sortBy,
//           type,
//         })
//       );
//     }
//   }, [isActive]);
//
//   const setFormValuesFromSelectedData = (data = {
//     "Client": { "date_created": "23/11/2023" },
//     "Service": { "service_id": 1 },
//     "Sub Service": [{ "sub_service_id": "", "discount": "11", "vat": ['11%'], "criteria_id": "", "description": "Test Desc", subtotal_to_rp: "0", vat_to_rp: "0", total_to_rp: "0", }, { "sub_service_id": "", "discount": 99, "vat": "", "criteria_id": "", "description": "Test Desc 2", subtotal_to_rp: "900", vat_to_rp: "800", total_to_rp: "900", }],
//     "Attachment": { "attachment": [{ id: 1, filename: 'gambar-satu.jpg', size: 5000 }] },
//     "Note Section": { "note": "yhjyuu" },
//     "Price Information": { "price_services_info": "(1 service)", "price_services_info_to_rp": "1000,000", "discount_info_to_rp": "2000,000", "vat_info_to_rp": "30000,000", "total_price_info_to_rp": "45000,000" }
//   }
//   ) => {
//
//     // nanti ini di uncomment kalau udah dijalan functionnya
//     // const data = rows.filter((row) => selectedData.includes(row.id))[0];
//
//     if (isLoadedFormValues) {
//       for (const key in data) {
//         let value;
//
//         if (key === "Client" && data[key]["date_created"]) {
//           // Handle date_created using dayjs
//           if (dayjs(data[key]["date_created"], "DD/MM/YYYY").isValid()) {
//             value = dayjs(data[key]["date_created"], "DD/MM/YYYY");
//             data[key]["date_created"] = value;
//             value = { ...data[key] };
//           } else {
//             value = "";
//             data[key]["date_created"] = value;
//             value = { ...data[key] };
//           }
//         } else if (Array.isArray(data[key])) {
//           // Corrected condition to check if the current property is an array
//           value = data[key].slice();
//         } else if (typeof data[key] === 'object' && data[key] !== null) {
//           // Handle nested objects
//           value = { ...data[key] };
//         } else {
//           // Handle regular values
//           value = data[key];
//         }
//
//         setFormValues((prevFormValues) => ({
//           ...prevFormValues,
//           [key]: value,
//         }));
//       }
//       setErrors(cloneObjectWithEmptyStrings(data, 'attachment'))
//
//     }
//
//   };
//
//
//   const isFormFilled = () => {
//     // return Object.values(formValues).every((value) => value.trim() !== "");
//     return Object.values(formValues).some((value) => value.trim() !== "");
//   };
//   /* -------------------------------------------------------------------------- */
//   /*                                   ACTION                                   */
//   /* -------------------------------------------------------------------------- */
//
//   // const handleInputChange = (event, field) => {
//   //   const { name, value, type } = event.target;
//   //   const newErrors = { ...errors };
//   //   console.log(name, value, "name, value");
//
//   //   if (type === "file") {
//   //     const file = event.target.files[0];
//   //     if (file) {
//   //       const fileError = validateFileInput(file, field);
//   //       if (fileError) {
//   //         newErrors[name] = fileError;
//   //         setErrors(newErrors);
//   //         return;
//   //       }
//   //       setFormValues((prevValues) => ({
//   //         ...prevValues,
//   //         [name]: file,
//   //       }));
//   //     }
//   //   } else {
//   //     setFormValues((prevValues) => ({
//   //       ...prevValues,
//   //       [name]: value,
//   //     }));
//   //   }
//   // };
//
//   // const handleSwitchChange = (event) => {
//   //   const { name, checked } = event.target;
//   //   // console.log(name, checked);
//   //   setFormValues((prevValues) => ({
//   //     ...prevValues,
//   //     [name]: checked,
//   //   }));
//   // };
//
//   // const handleInputBlur = (event) => {
//   //   const { name } = event.target;
//   //   validateField(name);
//   // };
//
//   // const validateFileInput = (
//   //   fieldValue,
//   //   field,
//   // ) => {
//   //   if (fieldValue instanceof File) {
//   //     const allowedExtensions = field.allowedExtensions || [
//   //       ".pdf",
//   //       ".doc",
//   //       ".docx",
//   //       ".jpeg",
//   //       ".jpg",
//   //       ".png",
//   //     ];
//   //     const size = field.maximumSizeInMB || 10;
//   //     const maxSizeInBytes = size * 1024 * 1024; // MB
//
//   //     const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
//   //     if (!allowedExtensions.includes(`.${fileExtension}`)) {
//   //       return "Invalid file extension. Please select a file with a valid extension.";
//   //     } else if (fieldValue.size > maxSizeInBytes) {
//   //       return `File size exceeds the maximum allowed size of ${size} MB.`;
//   //     }
//   //   }
//
//   //   return "";
//   // };
//
//   // const validateField = (fieldName) => {
//   //   const field = fields.find((field) => field.name === fieldName);
//   //   const newErrors = { ...errors };
//
//   //   if (field) {
//   //     const { name, required, validation, errorMessage, label, type } = field;
//
//   //     if (
//   //       required &&
//   //       (String(formValues[name]).trim() === "" ||
//   //         formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
//   //         formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
//   //     ) {
//   //       newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
//   //     } else if (
//   //       formValues[name] &&
//   //       formValues[name].length &&
//   //       validation &&
//   //       !validation(formValues[name])
//   //     ) {
//   //       newErrors[name] = errorMessage || `${label} is invalid`;
//   //     } else {
//   //       newErrors[name] = "";
//   //     }
//
//   //     setErrors(newErrors);
//   //     console.log(newErrors, "newErrors");
//   //   }
//   // };
//
//   // const { token } = useSelector((state) => state.auth);
//
//
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // console.log(formValues);
//     if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
//       handleDeleteRow();
//       handleCloseFormModal(true);
//       setSelectedData([]);
//     } else {
//       if (validateForm()) {
//         if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
//           handleEditRow();
//           handleCloseFormModal(true);
//         } else if (actionType === ACTION_TYPE.ADD) {
//           handleAddRow();
//           handleCloseFormModal(true);
//         } else if (actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION) {
//           handleCloseFormModal(true);
//           handleFormModal(ANOTHER_ACTION_TYPE.ADD_SALES_ORDER)
//         }
//         setSelectedData([]);
//       }
//     }
//   };
//
//   const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
//     setIsLoadedFormValues(false);
//     setActionType(actionType);
//     setFormModal(true);
//     // setErrors(initialErrors);
//     if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
//       setTitle("Edit Sales Order");
//       setFormValuesFromSelectedData();
//     } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
//       setTitle("Delete Sales Order");
//       const data = rows.filter((row) => selectedData.includes(row.id));
//       setUserDeleted(
//         `the client '${data.map((row) => row.fullname).join(", ")}'`
//       );
//     } else if (actionType === ACTION_TYPE.DETAIL) {
//       setTitle("Detail Sales Order");
//       // setDetailRow(dataSelected)
//     } else if (actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION) {
//       // resetFormValues();
//       setTitle("Check Sales Quotation");
//     } else if (actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER) {
//       // resetFormValues();
//       setTitle("Add Sales Order");
//       setFormValues(JSON.parse(localStorage.getItem("sales-management/sales-order/formValues")));
//     } else {
//       setTitle("Source Sales Order?");
//     }
//   };
//
//   const handleCloseFormModal = (important = false) => {
//     if (!important) {
//       console.log("a", important);
//       if (actionType === ACTION_TYPE.EDITED || actionType === ANOTHER_ACTION_TYPE.ADD || actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER) {
//         console.log("b", important);
//         if (isFormFilled) setConfirmModal(true);
//       } else {
//         setFormModal(false);
//         console.log("c", important);
//       }
//     } else {
//       console.log("d", important);
//       setFormModal(false);
//     }
//   };
//
//   const handleOnConfirmCancelModal = () => {
//     setConfirmModal(false);
//   };
//
//   const handleOnCloseConfirmCancelModal = () => {
//     setConfirmModal(false);
//
//     setFormModal(false);
//     // resetFormValues()
//     // setSelectedData([]); //buat disabled button toolbar
//   };
//
//   const handleExitedModal = () => {
//     // resetFormValues()
//   };
//
//   const handleAddRow = () => {
//     dispatch(addApplication(token, formValues));
//   };
//
//   const handleEditRow = () => {
//     dispatch(editApplication(token, formValues, formValues.id));
//   };
//
//   const handleDeleteRow = () => {
//     const data = rows.filter((row) => selectedData.includes(row.id));
//     const ids = data.map((row) => row.id);
//     dispatch(deleteApplication(token, { data: { ids } }, true));
//   };
//
//   const handleSortModelChange = (newSortModel) => {
//     // if (newSortModel && newSortModel.length) {
//     //   const { field, sort } = newSortModel[0];
//     setSortModel(newSortModel);
//     // setSortBy(field);
//     // setSortDirection(sort);
//     // }
//   };
//   /* -------------------------------------------------------------------------- */
//   /*                                    FORM                                    */
//   /* -------------------------------------------------------------------------- */
//
//
//
//   const validateForm = () => {
//     let valid = true;
//     const newErrors = {};
//
//     fields.forEach((fieldGroup) => {
//       if (fieldGroup.children) {
//
//
//
//         const { title } = fieldGroup;
//         const sectionErrors =
//           fieldGroup.title === "Sub Service"
//             ? []
//             : {};
//
//
//         fieldGroup.children.forEach((field) => {
//           const { name, type } = field;
//           if (
//             fieldGroup.title === "Sub Service"
//           ) {
//
//             formValues[title].forEach((sectionValues, index) => {
//               // Check if the field is of type "file" and an array
//               if (
//                 type === INPUT_TYPE.FILE &&
//                 Array.isArray(sectionValues[name])
//               ) {
//                 sectionValues[name].forEach((file, subIndex) => {
//                   // Handle file input validation
//                   const fileError = validateFileInput(file, field);
//
//                   if (!sectionErrors[index]) {
//                     sectionErrors[index] = {};
//                   }
//
//                   if (!sectionErrors[index][name]) {
//                     sectionErrors[index][name] = [];
//                   }
//
//                   sectionErrors[index][name][subIndex] = fileError;
//
//                   if (fileError) {
//                     valid = false;
//                     // debugger;
//                   }
//                 });
//               } else if (type !== "file") {
//                 const fieldValue =
//                   sectionValues[name] !== undefined ? sectionValues[name] : "";
//                 const error = validateFieldHelper(fieldValue, field);
//
//                 if (!sectionErrors[index]) {
//                   sectionErrors[index] = {};
//                 }
//
//                 sectionErrors[index][name] = error;
//
//                 if (error) {
//                   valid = false;
//                 }
//
//               }
//             });
//           } else {
//             if (
//               type === INPUT_TYPE.FILE &&
//               Array.isArray(formValues[title][name])
//             ) {
//
//               formValues[title][name].forEach((file, subIndex) => {
//                 // Handle file input validation
//                 const fileError = validateFileInput(file, field);
//
//                 if (!sectionErrors[name]) {
//                   sectionErrors[name] = [];
//                 }
//
//                 sectionErrors[name][subIndex] = fileError;
//
//                 if (fileError) {
//                   valid = false;
//                 }
//               });
//             } else if (type !== "file") {
//               // Handle regular input field validation
//               const fieldValue =
//                 formValues[title] && formValues[title][name] !== undefined
//                   ? formValues[title][name]
//                   : "";
//               const error = validateFieldHelper(fieldValue, field);
//
//               sectionErrors[name] = error;
//
//               if (error) {
//                 valid = false;
//               }
//             }
//           }
//         });
//
//         newErrors[title] = sectionErrors;
//       }
//     });
//
//
//     setErrors(newErrors);
//     return valid;
//   };
//
//
//   // const validateForm = () => {
//   //   let valid = true;
//   //   const newErrors = {};
//
//   //   fields.forEach((fieldGroup) => {
//   //     if (fieldGroup.children) {
//   //       const { title } = fieldGroup;
//
//   //       const sectionErrors =
//   //         fieldGroup.title === "Sub Service"
//   //           ? []
//   //           : {};
//
//   //       fieldGroup.children.forEach((field) => {
//   //         const { name } = field;
//
//   //         if (
//   //           fieldGroup.title === "Sub Service"
//   //         ) {
//   //           formValues[title].forEach((sectionValues, index) => {
//   //             const fieldValue =
//   //               sectionValues[name] !== undefined ? sectionValues[name] : "";
//   //             const error = validateFieldHelper(fieldValue, field);
//
//   //             if (!sectionErrors[index]) {
//   //               sectionErrors[index] = {};
//   //             }
//
//   //             sectionErrors[index][name] = error;
//
//   //             if (error) {
//   //               valid = false;
//   //             }
//   //           });
//   //         } else {
//   //           const fieldValue =
//   //             formValues[title] && formValues[title][name] !== undefined
//   //               ? formValues[title][name]
//   //               : "";
//   //           const error = validateFieldHelper(fieldValue, field);
//   //           const errorsz = errors;
//   //           debugger;
//   //           sectionErrors[name] = error;
//
//   //           if (error) {
//   //             valid = false;
//   //           }
//   //         }
//   //       });
//
//   //       newErrors[title] = sectionErrors;
//   //     }
//   //   });
//
//   //   setErrors(newErrors);
//   //   return valid;
//   // };
//
//
//   const renderFields = (section, index, field) => {
//     if (isLoadedFormValues) {
//       const currentFormValues =
//         section.title === "Sub Service"
//           ? formValues[section.title][index]
//           // : section.title === "Attachment"
//           //   ? formValues[section.title][index]
//           : formValues[section.title];
//       const currentErrors =
//         section.title === "Sub Service"
//           ? errors[section.title][index]
//           // : section.title === "Attachment"
//           //   ? errors[section.title][index]
//           : errors[section.title];
//
//       // debugger;
//       // If section.title is undefined, use the general errors
//       if (!section.title) {
//         currentErrors = errors;
//       }
//       return (
//         <>
//           {section.children ?.map((field) => {
//             // Check if children array exists
//             let optionsData;
//             if (Array.isArray(options[field.name])) {
//               const isRequired = field.required &&
//                 field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
//                   id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
//                   name: "Choose Option",
//                 };
//
//               if (isRequired) {
//                 optionsData = [
//                   // field.required &&
//                   //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
//                   //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
//                   //     name: "Choose Option",
//                   //   },
//                   ...options[field.name],
//                 ];
//               } else {
//                 optionsData = [...options[field.name]];
//               }
//             }
//
//             const fieldOptions = Array.isArray(options[field.name])
//               ? optionsData
//               : [];
//
//             let fieldComponent = null;
//
//             switch (field.type) {
//               case INPUT_TYPE.TEXT:
//               case INPUT_TYPE.NUMBER:
//               case INPUT_TYPE.PHONE_NUMBER:
//               case INPUT_TYPE.POINT:
//               case INPUT_TYPE.CURRENCY:
//                 fieldComponent = (
//                   <TextInput
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.TEXTAREA:
//                 fieldComponent = (
//                   <TextArea
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.FILE:
//                 fieldComponent = (
//                   <div>
//                     {currentFormValues[field.name].map(
//                       (_, fileIndex) => (
//                         <div key={fileIndex}>
//                           <Grid container spacing={0.3}>
//                             <Grid item xs={11.3}>
//                               <FilePicker
//                                 indexFile={fileIndex}
//                                 field={field}
//                                 formValues={
//                                   currentFormValues[field.name][fileIndex]
//                                 }
//                                 errors={currentErrors}
//                                 onFileSelect={(file) =>
//                                   handleFileSelect(
//                                     file,
//                                     index,
//                                     section.title,
//                                     field.name,
//                                     fileIndex
//                                   )
//                                 }
//                                 handleInputChange={(e) =>
//                                   handleInputChange(
//                                     e,
//                                     index,
//                                     section.title,
//                                     field,
//                                     fileIndex
//                                   )
//                                 }
//                                 tip={field ?.tip}
//                               />
//                             </Grid>
//
//                             <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
//                               <Button
//                                 variant="contained"
//                                 disableElevation
//                                 sx={{
//                                   backgroundColor: (theme) =>
//                                     theme.palette.primary.main,
//                                   ...removeButton,
//                                 }}
//                                 onClick={() =>
//                                   handleRemoveFile(
//                                     section.title,
//                                     index,
//                                     fileIndex,
//                                     field.name
//                                   )
//                                 }
//                                 disabled={
//                                   currentFormValues[field.name].length === 1
//                                 }
//                               >
//                                 <>
//                                   <img
//                                     src={RemoveIcon}
//                                     alt={`Remove ${section.title}`}
//                                   />
//
//                                 </>
//                               </Button>
//                             </Grid>
//                           </Grid>
//                         </div>
//                       )
//                     )}
//
//                     <Box sx={{ ...justifyContentEnd }}>
//                       <Button
//                         onClick={() => handleAddFile(section.title, index, field.name)}
//                       >
//                         Add More
//                       </Button>
//                     </Box>
//                   </div>
//                 );
//
//                 break;
//               case INPUT_TYPE.SELECT_ONE:
//                 fieldComponent = (
//                   <SelectOne
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                     fieldOptions={fieldOptions}
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
//                 fieldComponent = (
//                   <SelectOneAutocomplete
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) => {
//                       handleInputBlur(field.name, section.title, index, e);
//                     }}
//                     fieldOptions={fieldOptions}
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.DIVIDER:
//                 fieldComponent = (
//                   <Divider />
//                 );
//                 break;
//               case INPUT_TYPE.TYPOGRAPHY:
//                 fieldComponent = (
//                   <Typography variant={field.variant} sx={field.style}>{field.label}</Typography>
//                 );
//                 break;
//               case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
//                 fieldComponent = (
//                   <Typography key={field.name} variant={field.variant} sx={field.style}>{field.label} {currentFormValues[field.name]}</Typography>
//                 );
//                 break;
//               case INPUT_TYPE.SELECT_MULTIPLE:
//                 fieldComponent = (
//                   <SelectMultiple
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                     fieldOptions={fieldOptions}
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
//                 fieldComponent = (
//                   <SelectMultipleAutocomplete
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                     fieldOptions={fieldOptions}
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.CHECKBOX:
//                 fieldComponent = (
//                   <CheckboxGroupInput
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                     fieldOptions={
//                       fieldOptions.length
//                         ? fieldOptions.filter((option) => option !== false)
//                         : field.options
//                     }
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.RADIO:
//                 fieldComponent = (
//                   <RadioGroupInput
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                     errors={currentErrors}
//                     fieldOptions={
//                       fieldOptions.length
//                         ? fieldOptions.filter((option) => option !== false)
//                         : field.options
//                     }
//                   />
//                 );
//                 break;
//               case INPUT_TYPE.DATE:
//                 fieldComponent = (
//                   <DatePickerInput
//                     key={field.name}
//                     field={field}
//                     formValues={currentFormValues}
//                     errors={currentErrors}
//                     handleInputChange={(e) =>
//                       handleInputChange(e, index, section.title, field)
//                     }
//                     handleInputBlur={(e) =>
//                       handleInputBlur(field.name, section.title, index, e)
//                     }
//                   />
//                 );
//                 break;
//               // Add cases for other field types
//               default:
//                 fieldComponent = null;
//             }
//
//             return (
//               <Grid
//                 item
//                 xs={field.gridWidth || 12}
//                 key={field.name}
//                 sx={{ paddingTop: "unset !important" }}
//               >
//                 {fieldComponent}
//               </Grid>
//             );
//           })}
//         </>
//       );
//     }
//   };
//
//   const renderSectionFields = (section) => {
//     if (isLoadedFormValues) {
//       // if (section.title === "Sub Service" || section.title === "Attachment") {
//       if (section.title === "Sub Service") {
//         // Render Sub Service or Attachment section
//         return (
//           <Grid item xs={12} key={section.title}>
//             {section.isDisplayTitle !== false && (
//               <Box sx={{ marginBottom: 2 }}>
//                 {/* Title */}
//                 <Typography variant="h6" sx={{ fontWeight: 800 }}>
//                   {section.title}
//                 </Typography>
//               </Box>
//             )}
//             {formValues[section.title].map((rowValues, index) => (
//               <div key={index}>
//                 <Grid container spacing={0.3}>
//                   <Grid item xs={11.3}>
//                     <Grid container spacing={1}>
//                       {renderFields(section, index)}
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
//                     <Button
//                       variant="contained"
//                       disableElevation
//                       sx={{
//                         backgroundColor: (theme) => theme.palette.primary.main,
//                         ...removeButton,
//                       }}
//                       onClick={() => handleRemoveFieldRow(section.title, index)}
//                       disabled={formValues[section.title].length === 1}
//                     >
//                       <img src={RemoveIcon} alt={`Remove ${section.title}`} />
//                     </Button>
//                   </Grid>
//                 </Grid>
//                 {formValues[section.title].length > 1 && (
//                   <Divider sx={{ mt: 2, mb: 2 }} />
//                 )}
//               </div>
//             ))}
//             <Box sx={{ ...justifyContentEnd }}>
//               <Button onClick={() => handleAddFieldRow(section.title)}>
//                 Add Another Sub {section.title === "Sub Service" ? " " : " "}
//               </Button>
//             </Box>
//           </Grid>
//         );
//       } else {
//         // Render other sections using renderFields function
//         return (
//           <Grid item xs={12} key={section.title}>
//             {section.isDisplayTitle !== false && (
//               <Box sx={{ marginBottom: 2 }}>
//                 {/* Title */}
//                 <Typography variant="h6" sx={{ fontWeight: 800 }}>
//                   {section.title}
//                 </Typography>
//                 {/* Subtitle */}
//                 {section.subtitle && (
//                   <Typography variant="subtitle2">{section.subtitle}</Typography>
//                 )}
//               </Box>
//             )
//             }
//             <Grid container spacing={1}>
//               {renderFields(section)}
//             </Grid>
//           </Grid >
//         );
//       }
//     }
//   };
//
//   const renderFormContent = () => {
//     if (
//       (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
//       (!selectedData.length && actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER) || actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION
//     ) {
//       return (
//         <>
//           <Grid
//             container
//             spacing={1.5}
//             sx={{
//               // overflowX: "auto",
//               // height: "52vh",
//               // paddingBottom: "2rem",
//             }}
//           // className="smooth-scroll"
//           >
//             {/* {JSON.stringify(isLoadedFormValues)} */}
//             {/* {JSON.stringify(formValues)} */}
//             {/* <hr /> */}
//             {/* {JSON.stringify(errors)} */}
//             {isLoadedFormValues ?
//               fields.map((section) => renderSectionFields(section)) : (<Box sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: '100%'
//               }}><FacebookCircularProgress /></Box>)
//             }
//           </Grid>
//         </>
//       )
//     } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
//       return <ConfirmDelete userDeleted={userDeleted} />;
//     } else if (actionType === ACTION_TYPE.DETAIL) {
//       return <></>
//     } else if (actionType === ACTION_TYPE.ADD) {
//       return (<Grid container spacing="5">
//         <Grid item xs={12}><Button variant="contained" fullWidth disableElevation onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION)}>Get From Sales Quotation</Button></Grid>
//         <Grid item xs={12}><Button variant="outlined" fullWidth disableElevation onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.ADD_SALES_ORDER)}> Create New Sales Order</Button></Grid>
//       </Grid>)
//     }
//   };
//
//   const renderOtherActionButtons = () => {
//     return (
//       <Box>
//         {actionType === ACTION_TYPE.DETAIL ? (
//           <>
//             <Button variant="outlined" sx={{ ...btnLightBlue }} disableElevation>
//               <img src={PrinterIcon} style={{ marginRight: '5px' }} alt="Printer Icon" />
//               Download Quotation
//             </Button>
//           </>
//         ) : actionType === ACTION_TYPE.EDITED ? (
//           <>
//             {/* <Button
//               variant="outlined"
//               sx={{ ...btnGreen }}
//               disableElevation
//               onClick={() => handleCloseFormModal()}
//             >
//               Mark as Deal
//             </Button> */}
//           </>
//         ) : null}
//       </Box>
//     );
//   };
//
//
//
//
//   const renderActionButtons = () => {
//     return (
//       <Box>
//         {actionType !== ACTION_TYPE.ADD && (
//           <>
//             <Button
//               variant="outlined"
//               sx={{ ...btnWhite }}
//               onClick={() => handleCloseFormModal()}
//             >
//               Cancel
//         </Button>
//             <Button
//               color="primary"
//               variant="contained"
//               disableElevation
//               type="submit"
//             >
//               {actionType === ACTION_TYPE.DELETED
//                 ? "Yes"
//                 : actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION
//                   ? "Get It"
//                   : "Save"
//               }
//             </Button>
//           </>
//         )}
//       </Box>
//     );
//   };
//
//   /* -------------------------------------------------------------------------- */
//   /*                                  DATATABLE                                 */
//   /* -------------------------------------------------------------------------- */
//   useEffect(() => {
//     // console.log(search, status, isLoading, "query");
//   }, [search, status, isLoading]);
//
//   useEffect(() => {
//     if (!search) {
//       _search();
//     }
//   }, [search])
//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && search) {
//       _search();
//     }
//   };
//   ;
//
//   useEffect(() => {
//     if (status !== ACTION_TYPE.DEFAULT_STATUS) {
//       dispatch(
//         getApplication({
//           token,
//           page,
//           limit,
//           search,
//           status,
//           sortDirection,
//           sortBy,
//           type,
//         })
//       );
//     }
//   }, [sortDirection, sortBy]);
//
//   function _getByPage(page) {
//     if (pagination === null || pagination === undefined) {
//       return;
//     }
//     dispatch(
//       getApplication({
//         token,
//         page,
//         limit,
//         search,
//         status,
//         sortDirection,
//         sortBy,
//         type,
//       })
//     );
//   }
//
//   function _getByLimit(limit) {
//     dispatch(
//       getApplication({
//         token,
//         limit,
//         search,
//         status,
//         sortDirection,
//         sortBy,
//         type,
//       })
//     );
//   }
//   function _search() {
//     dispatch(
//       getApplication({ token, limit, search, status, sortDirection, sortBy, type })
//     );
//   }
//
//   function _status(status) {
//     setStatus(status);
//     // dispatch(
//     //   getApplication({ token, limit, search, status, sortDirection, sortBy, type })
//     // );
//   }
//
//   function _type(val) {
//     setType(val);
//   }
//
//
//   function _dateFilter(date) {
//     if (date === "custom_date") {
//       setShowDatePicker(true);
//       setDateFilter(date);
//
//     } else {
//       setShowSelectDate(false);
//
//       if ((dateFilter == 'custom_date' && (date !== 'custom_date' && date !== undefined))) {
//         setShowDatePicker(false);
//
//         setStartDate(null);
//         setStartDateElm(null);
//         setEndDate(null);
//         setEndDateElm(null);
//       }
//
//       if (date && status !== ACTION_TYPE.DEFAULT_STATUS) {
//         setDateFilter(date);
//       }
//     }
//   }
//
//   const handleStartDateChange = (date) => {
//     if (dayjs(date).isValid()) {
//       const formattedDate = dayjs(date).format("YYYY-MM-DD");
//       setStartDate(formattedDate);
//       setStartDateElm(date);
//     } else {
//       console.error("Invalid start date:", date);
//       // You might want to handle this error case gracefully
//     }
//   };
//
//   const handleEndDateChange = (date) => {
//     if (dayjs(date).isValid()) {
//       const formattedDate = dayjs(date).format("YYYY-MM-DD");
//       setEndDate(formattedDate);
//       setEndDateElm(date);
//     } else {
//       console.error("Invalid end date:", date);
//       // You might want to handle this error case gracefully
//     }
//   };
//
//   useEffect(() => {
//     if (startDate && endDate) {
//       setShowSelectDate(false);
//       dispatch(
//         getApplication({
//           token,
//           limit,
//           search,
//           status,
//           sortDirection,
//           sortBy,
//           dateFilter,
//           startDate,
//           endDate,
//         })
//       );
//     }
//   }, [startDate, endDate]);
//
//   const handleSelectClose = (e) => {
//     if (e) e.stopPropagation();
//     setShowDatePicker(false);
//     setShowSelectDate(false);
//   };
//
//
//
//   const handlePageChange = (newPage) => {
//     setPage(newPage + 1);
//     setPageDB(newPage);
//     _getByPage(newPage + 1);
//   };
//
//   const handleLimitChange = (newLimit) => {
//     // console.log(newLimit);
//     setPage(1);
//     setPageDB(1);
//     setLimit(newLimit);
//     _getByLimit(newLimit);
//   };
//
//   const handleSelectionChange = (selection) => {
//     console.log(selection, "selection");
//     setSelectedData(selection || []);
//   };
//
//   return (
//     <>
//       {/* /* -------------------------------------------------------------------------- */
//       /*                                   SEARCH                                   */
//       /* -------------------------------------------------------------------------- */}
//       <Card
//         style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
//       >
//         <CardContent>
//           <Grid container spacing={2}>
//             <Grid item xs={4.8}>
//             <TextField
//       fullWidth
//       placeholder={`Search ${searchBy}`}
//       size="small"
//       InputProps={{
//         startAdornment: (
//           <InputAdornment position="start">
//             <img
//               src={SearchIconBlack}
//               style={{ width: '20px', margin: ' 0 8px 0 0' }}
//             />
//
//           </InputAdornment>
//         ),
//         endAdornment: (
//           <TextField
//           select
//           fullWidth
//           size="small"
//           SelectProps={{
//             IconComponent: ExpandMoreIcon,
//           }}
//           sx={{
//             borderRadius,
//           }}
//           value={searchBy}
//           sx={{'& fieldset': {
//             border:'unset',
//           },borderLeft:`2px solid #ddd`}}
//           onChange={(e) => setSearchBy(e.target.value)}
//         >
//           <MenuItem value="No. Reference">No. Reference
//           </MenuItem>
//           <MenuItem value="Company">Company</MenuItem>
//           <MenuItem value="Applicant">Applicant</MenuItem>
//         </TextField>
//         )
//       }}
//       sx={{
//         borderRadius: 4, // Adjust the borderRadius as needed
//       }}
//       onChange={(e) => setSearch(e.target.value)}
//       onKeyDown={handleKeyPress}
//     />
//             </Grid>
//             <Grid item xs={1}>
//               <Button
//                 variant="contained"
//                 disableElevation
//                 sx={{ height: "100%" }}
//                 onClick={_search}
//               >
//                 <img src={SearchIcon} />
//               </Button>
//             </Grid>
//             <Grid item xs={2.2}>
//
//             <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
//   <DatePicker
//     selectedDate={periodElm}
//     onChange={handlePeriodChange}
//     components={{
//       OpenPickerButton: CustomOpenPickerButton,
//     }}
//     format="MMMM YYYY"
//     views={["month", "year"]}
//     value={periodElm}
//     sx={{
//       ...inputDate,
//     }}
//   />
// </LocalizationProvider>
//
//             </Grid>
//             <Grid item xs={2}>
//               <TextField
//                 select
//                 fullWidth
//                 size="small"
//                 SelectProps={{
//                   IconComponent: ExpandMoreIcon,
//                 }}
//                 sx={{
//                   borderRadius,
//                 }}
//                 value={type}
//                 onChange={(e) => _type(e.target.value)}
//                 disabled={type === ACTION_TYPE.DEFAULT_TYPE}
//               >
//                  <MenuItem value={ACTION_TYPE.DEFAULT_TYPE}>
//                   Select Type
//                 </MenuItem>
//                 {options["type"] &&
//                   options["type"].map((type) => (
//                     <MenuItem key={type.id} value={type.id}>
//                       {type.name}
//                     </MenuItem>
//                   ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={2}>
//               <TextField
//                 select
//                 fullWidth
//                 size="small"
//                 SelectProps={{
//                   IconComponent: ExpandMoreIcon,
//                 }}
//                 sx={{
//                   borderRadius,
//                 }}
//                 value={status}
//                 onChange={(e) => _status(e.target.value)}
//               >
//                 <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
//                   Select Status
//                 </MenuItem>
//                 <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
//                 <MenuItem value="active">Active</MenuItem>
//                 <MenuItem value="inactive">Inactive</MenuItem>
//               </TextField>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//
//       {/* /* -------------------------------------------------------------------------- */
//       /*                                  DATATABLE                                 */
//       /* -------------------------------------------------------------------------- */}
//       <Card
//         style={{
//           marginTop: "1rem",
//           boxShadow: "unset",
//           borderRadius,
//           border: "1px solid #ddd",
//         }}
//       >
//         <CardContent sx={{ paddingTop: "unset" }}>
//           <DataTable
//             title={"Application"}
//             useStyles={useStyles}
//             rows={rows}
//             columns={columns}
//             isLoading={isLoading}
//             pagination={pagination}
//             limit={limit}
//             page={pageDB}
//             handlePageChange={handlePageChange}
//             handleLimitChange={handleLimitChange}
//             handleFormModal={handleFormModal}
//             selectedData={selectedData}
//             handleSelectionChange={handleSelectionChange}
//             theme={theme}
//             search={search}
//             statusChoosed={status}
//             sortModel={sortModel}
//             onSortModelChange={handleSortModelChange}
//             modeCRUD={MODE_CRUD_WITH.PAGING}
//             linkCRUD={{ create: "add", update: `edit/${selectedData}` }}
//           />
//         </CardContent>
//       </Card>
//
//       {actionType !== ACTION_TYPE.DELETED && actionType !== ACTION_TYPE.ADD && actionType !== ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION ? (
//         <LeftDrawer open={formModal}>
//           <Box>
//             <Form
//               title={title}
//               onCloseModal={() => handleCloseFormModal()}
//               elementForm={renderFormContent}
//               elementOtherActionButton={renderOtherActionButtons}
//               elementActionButton={renderActionButtons}
//               onSubmit={handleSubmit}
//               workingWithDrawer={true}
//             />
//           </Box>
//         </LeftDrawer>
//       ) : (
//           <Modal open={formModal} onClose={() => handleCloseFormModal()}>
//             <Box>
//               <Form
//                 title={title}
//                 onCloseModal={() => handleCloseFormModal()}
//                 elementForm={renderFormContent}
//                 elementOtherActionButton={renderOtherActionButtons}
//                 elementActionButton={renderActionButtons}
//                 onSubmit={handleSubmit}
//               />
//             </Box>
//           </Modal>
//         )}
//
//       <ModalConfirmCancel
//         confirmModal={confirmModal}
//         onConfirmModal={handleOnConfirmCancelModal}
//         onCloseModal={handleOnCloseConfirmCancelModal}
//       ></ModalConfirmCancel>
//     </>
//   );
// };
//
// export default Application;


import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  InputAdornment,
  Divider,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "./../../../assets/minus.svg";
import addIcon from "./../../../assets/add.png";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  fourColor,
  secondColor,
  imgBtnToolbar,
  btnTableToolbar,
  tabStyles
} from "../../../util/style";

import Form, {
  CheckboxGroupInput,
  RadioGroupInput,
  DatePickerInput,
  TextArea,
  FilePicker,
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../../components/Form";
import {
    addApplication,
  deleteApplication,
  editApplication,
  getApplication,
} from "./clients/actions";
import {
  findMenuItemByLink,
  ACTION_TYPE,
  USER_ACCESS as _,
  INPUT_TYPE,
  SELECT_OPTION,
  MODE_CRUD_WITH,
  formatCurrencyWithoutLable,
  TabPanel
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import axios from "../../../core/axios_config";
import { useNavigate, useParams } from "react-router-dom";

const Application = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const columns = [
    {
      field: "id",
      headerName: "No. Reference",
      width: 180,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <a
            onClick={() => navigate(`detail/${params.row.id}`)}
            style={{ textDecoration: "none", cursor: "grab", color:'blue' }}
          >
          {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "code",
      headerName: "No. Application",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "client_name",
      headerName: "Title",
      width: 190,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerName: "Patent Type",
      width: 180,
      disableColumnMenu: true,
    },

    {
      field: "business_field",
      headerName: "Criteria",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "submitted",
      headerName: "Submitted",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "last_update",
      headerName: "Last Update",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        const statusColors = {
          Draft: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Approved: { bgColor: successSurfaceColor, colors: successMainColor },
          Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
          // Add more statuses as needed
        };

        const statusInfo = statusColors[params.value] || {
          bgColor: infoSurfaceColor,
          colors: infoMainColor,
        };
        return (
          <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
            {params.value}
          </StatusBadge>
        )
      },
    },

  ];

   const fields  = [
        // {
        //   title: "Basic Information",
        //   subtitle: "Complete all employee basic information, as basic data",
        //   isDisplayTitle: false,
        // },
        {
          title: "Add Patent Application",
          isDisplayTitle: false,
          children: [
             {
              name: "reference_number",
              type: INPUT_TYPE.TEXT,
              label: "New Our Reference",
              required: false,
            },
            {
              name: "get_reference_number_from_previous_data",
              type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
              label: "Select Our Reference",
              placeholder: "Select Our Reference",
              apiEndpoint: "/master/employes",
              required: false,
              styleForGrid: {
                display:'none'
              },
            },

          ],
        },
      ]

  const [activeTab, setActiveTab] = useState(0); // State to manage active tab, defaulting to 0
  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
    const [searchBy, setSearchBy] = useState("code");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New Application");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [category, setCategory] = useState(ACTION_TYPE.ALL_CATEGORY);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.ALL_DATE);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateElm, setStartDateElm] = useState(null);
  const [endDateElm, setEndDateElm] = useState(null);
  const [periodElm, setPeriodElm] = useState(null);
  const [period, setPeriod] = useState(null);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

 const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };


  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.applications);

  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    console.log(currentPath, userAccess);
  }, [userAccess]);

//     const rows = [
//     {
//       "id": 1,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Draft"
//     },
//     {
//       "id": 2,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Waiting"
//     },
//     {
//       "id": 3,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Draft"
//     },
//     {
//       "id": 4,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Waiting"
//     },
//     {
//       "id": 5,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 6,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 7,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 8,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 9,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 10,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 11,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//     {
//       "id": 12,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Approved"
//     },
//     {
//       "id": 13,
//       "number": "ADDI",
//       "client_name": "GlobalTradin",
//       "category": "Derhind",
//       "business_field": "Konta",
//       "brand": "Brand A",
//       "applicant": "Carter Culhane",
//       "submitted": "08/08/2021, 02:10",
//       "due_date": "13/04/3323",
//       "last_update": "13/06/2021, 02:10",
//       "status": "Rejected"
//     },
//   ]
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);


  const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.name,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      // Check if the field has a children array
      if (field.children) {
        field.children.forEach((child) => {
          const { name, apiEndpoint } = child;
          if (apiEndpoint) {
            if(!options.hasOwnProperty(name)) {
              fetchOptions(apiEndpoint, name);
            }
          }
        });
      } else {
        // If there's no children array, use the field directly
        const { name, apiEndpoint } = field;
        if (apiEndpoint) {
          if(!options.hasOwnProperty(name)) {
            fetchOptions(apiEndpoint, name);
          }
        }
      }
    });
  }, [options]);


  useEffect(() => {
    fetchOptions('master/services?main_service_id=300', 'category');
  }, []);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getApplication({ token, limit, search,searchBy, status, sortDirection, sortBy,  period, })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

    useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {

      dispatch(
        getApplication({
          token,
          limit,
          search,
          searchBy,
          status,
          sortDirection,
          sortBy,
          category,
          period,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [category]);

  useEffect(() => {
    if (isActive === true) {
       if (status !== ACTION_TYPE.DEFAULT_STATUS) {
        dispatch(
          getApplication({ token, limit, search,searchBy, status, sortDirection, sortBy,  period, })
        );
       }
    }
  }, [isActive]);


   const initialSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: 0,
    vat_percent: '',
    vat_to_rp: 0,
    total_to_rp: 0,
    criteria_id: "",
    desc: "",
  };

  const initialAttachment = {
    attachment: [""],
  };

  const initialErrorSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: "",
    vat_percent: "",
    criteria_id: "",
    desc: "",
  };
  const initialErrorAttachment = {
    attachment: [""],
  };

  const initialFormValues = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialSubService]
            : fieldGroup.title === "Attachment"
              ? initialAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
              );
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialErrorValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialErrorValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialErrorSubService]
            : fieldGroup.title === "Attachment"
              ? initialErrorAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: "" }),
                {}
              );
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());


  const handleRemoveFieldRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  }


  const handleAddFieldRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Sub Service" && initialSubService,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Sub Service"
        && initialErrorSubService
        ,
      ], // Add an empty error object
    }));
  };

  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!isNaN(rowIndex)) {
        updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
      } else {
        updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
      }

      return updatedValues;
    });
  };

  const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
        return updatedValues;
      });

    }
  };

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    fileIndex
  ) => {
    debugger;
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];
        debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = '';
        }

        return updatedValues;
      });

    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];
debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });

    }
  };
  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Application");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `application '${data
          .map((row) => row.code)
          .join(", ")}'`
      );
    } else {
      setTitle("Add Application");
      setFormValues(initialFormValues())
      setErrors(initialErrors())
    }
  };

  const handleCloseFormModal = (important = false) => {
    setFormModal(false);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    setSelectedData([]); //buat disabled button toolbar
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteApplication(token, { data: { ids } }, true));
  };

  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

 const renderFields = (section, index, field) => {
    if (isLoadedFormValues) {
      const currentFormValues =
        section.title === "Sub Service"
          ? formValues[section.title][index]
          // : section.title === "Attachment"
          //   ? formValues[section.title][index]
          : formValues[section.title];
      const currentErrors =
        section.title === "Sub Service"
          ? errors[section.title][index]
          // : section.title === "Attachment"
          //   ? errors[section.title][index]
          : errors[section.title];

      // debugger;
      // If section.title is undefined, use the general errors
      if (!section.title) {
        currentErrors = errors;
      }
      return (
        <>
          {section.children ?.map((field) => {
            // Check if children array exists
/*
          const checkSalesQuotation = formValues['Check Sales Quotation'];

          const [isClientIdExist, isClientIdANDPeriodeExist] = [
            !checkSalesQuotation ||
              checkSalesQuotation.client_id === undefined ||
              checkSalesQuotation.client_id === null ||
              checkSalesQuotation.client_id === '',
            !checkSalesQuotation ||
              checkSalesQuotation.client_id === undefined ||
              checkSalesQuotation.client_id === null ||
              checkSalesQuotation.client_id === '' ||
              checkSalesQuotation.periode === undefined ||
              checkSalesQuotation.periode === null ||
              checkSalesQuotation.periode === '',
          ];*/

/*
 if (field.title === "Sub Service" && field.children) {
        return {
          ...field,
          children: field.children.map((subField, index) => {
            if(subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
              if (subField.name === "sub_service_id") {
                return {
                  ...subField,
                  additionalProps: {
                    ...subField.additionalProps,
                    disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                     // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                };
              } else {
              // return {
              //   ...subField,
              //   additionalProps: {
              //     ...subField.additionalProps,
              //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
              //   },
              // };
            }
          } else {
            if (subField.name === "discount" || subField.name === "vat_percent"  || subField.name === "subtotal" || subField.name === "criteria_id") {
              // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
              return {
                ...subField,
                additionalProps: {
                  ...subField.additionalProps,
                  disabled: true
                  // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                  // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                },
              };
            }}
            return subField;
          }),
        };
      }
      */
            let optionsData;

            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              // if (isRequired) {
              //   optionsData = [
              //     // field.required &&
              //     //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //     //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //     //     name: "Choose Option",
              //     //   },
              //     ...options[field.name],
              //   ];
              // } else {
              //   optionsData = [...options[field.name]];
              // }

                if (field.name === "sub_service_id") {

                  field = {
                    ...field,
                    additionalProps: {
                      ...field.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };

                  // Filter options based on sub_service_id values that are not used
                  const usedSubServiceIds = formValues['Sub Service']
                  .filter((_, i) => i !== index) // Exclude the sub_service_id at the current index
                  .map(subService => subService?.sub_service_id); // Use optional chaining to prevent errors if subService is undefined

                  // Filter options based on sub_service_id values that are not used
                  optionsData = options[field.name].filter(option => !usedSubServiceIds.includes(option.id));
                  console.warn('usedSubServiceIds', usedSubServiceIds);
                  console.warn('optionsData', optionsData);

                }  else if (field.name === "criteria_id") {

                    const usedSubServiceIds = formValues['Sub Service']
                    .filter((_, i) => i === index)
                    .map(subService => subService?.sub_service_id);
                    // console.log(usedSubServiceIds,'KKKK');

                    optionsData = options[field.name].filter(option => usedSubServiceIds.includes(option.service_id));

                } else {
                  optionsData = [...options[field.name]];

//                   if (field.name === "sales_quotation_id") {
//                     field = {...field, additionalProps: {
//                         ...field.additionalProps,
//                         disabled: isClientIdANDPeriodeExist ,
//                       },
//                       disabled: isClientIdANDPeriodeExist
//                     }
//                   }
                }

              } else {

                if(!currentFormValues[field.name]) {
                  currentFormValues[field.name] = field.defaultValue
                }

                if (field.name === "price" || field.name === "quantity" || field.name === "discount" || field.name === "vat_percent"  || field.name === "criteria_id") {
                  field = {...field, additionalProps: {
                    ...field.additionalProps,
                    disabled: formValues['Sub Service'][index] && (formValues['Sub Service'][index]['sub_service_id'] === undefined || formValues['Sub Service'][index]['sub_service_id'] === null || formValues['Sub Service'][index]['sub_service_id'] === '')
                  }}
                  console.log(field)
                }

            }


          const startIconMappings = {
              USD: { subtotal: 'USD ', price: 'USD ' },
              Rp: { subtotal: 'Rp ', price: 'Rp ' },
              EUR: { subtotal: 'EUR ', price: 'EUR ' },
              CNY: { subtotal: 'CNY ', price: 'CNY ' },
              SGD: { subtotal: 'SGD ', price: 'SGD ' },
              GBP: { subtotal: 'GBP ', price: 'GBP ' },
              AUD: { subtotal: 'AUD ', price: 'AUD ' },
            };


            const labelMappings = {
              USD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              Rp: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              EUR: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              CNY: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              SGD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              GBP: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              AUD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
            };


            if(formValues['Service'] && formValues['Service']['currency']) {
              const currency = formValues['Service']['currency'];

              if (currency && startIconMappings[currency] && startIconMappings[currency][field.name]) {
                field = { ...field, startIcon: startIconMappings[currency][field.name] };
              }

              if (currency && labelMappings[currency] && labelMappings[currency].includes(field.name)) {
                field = { ...field, label: `${currency} ` };
              }
            }

            /*
             * ADD APPLICATION - Our Reference or Select Our Reference
             */
            if(activeTab === 0) {
              if(field.name === 'get_reference_number_from_previous_data') {
                field = { ...field, styleForGrid: {display:'none'} };
              } else {
                field = { ...field, styleForGrid: {display:'block'} };
              }
            } else {
              if(field.name === 'get_reference_number_from_previous_data') {
                field = { ...field, styleForGrid: {display:'block'} };
              } else {
                field = { ...field, styleForGrid: {display:'none'} };
              }
            }


            /*
             * EFFECTIVE DATE
             *
             * if(formValues['Employee Information'] && formValues['Employee Information']['effective_date']) {

                const currentDate = new Date(); // Current date
                const effectiveDate = new Date(formValues['Employee Information']['effective_date']); // Effective date from the form values

                if (effectiveDate < currentDate) {
                  field = {
                    ...field
                      ,additionalProps: {
                      ...field.additionalProps,
                      disabled: true
                    },
                    disabled:true
                  }
                }

              }
              */



            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
              case INPUT_TYPE.DISCOUNT:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.FILE:
                console.log(currentFormValues[field.name], 'SOBARIIIIII', field.name, section.title, currentFormValues)
                fieldComponent = (
                  <div>
                    {currentFormValues[field.name].map(
                      (_, fileIndex) => (
                        <div key={fileIndex}>
                          <Grid container spacing={0.3}>
                            <Grid item xs={11.3}>
                              <FilePicker
                                indexFile={fileIndex}
                                field={field}
                                formValues={
                                  currentFormValues[field.name][fileIndex]
                                }
                                errors={currentErrors}
                                onFileSelect={(file) =>
                                  handleFileSelect(
                                    file,
                                    index,
                                    section.title,
                                    field.name,
                                    fileIndex
                                  )
                                }
                                handleInputChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    section.title,
                                    field,
                                    fileIndex
                                  )
                                }
                                tip={field ?.tip}
                                URIForViewDocument={currentFormValues[field.name][fileIndex]['url']}
                              />
                            </Grid>

                            <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ...removeButton,
                                }}
                                onClick={() =>
                                  handleRemoveFile(
                                    section.title,
                                    index,
                                    fileIndex,
                                    field.name
                                  )
                                }
                                disabled={
                                  currentFormValues[field.name].length === 1
                                }
                              >
                                <>
                                  <img
                                    src={RemoveIcon}
                                    alt={`Remove ${section.title}`}
                                  />

                                </>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}

                    <Box sx={{ ...justifyContentEnd }}>
                      <Button
                        onClick={() => handleAddFile(section.title, index, field.name)}
                      >
                        Add More
                      </Button>
                    </Box>
                  </div>
                );

                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) => {
                      handleInputBlur(field.name, section.title, index, e);
                    }}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.DIVIDER:
                fieldComponent = (
                  <Divider sx={field.style}/>
                );
                break;
              case INPUT_TYPE.TYPOGRAPHY:
                fieldComponent = (
                  <Typography variant={field.variant} sx={field.style}>{field.label}</Typography>
                );
                break;
//               OLD
//               case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
//                 fieldComponent = (
//                   <Typography key={field.name} variant={field.variant} sx={field.style}>{field.label} {field.name === 'price_services_info' ? currentFormValues[field.name] : formatCurrencyWithoutLable(currentFormValues[field.name])}</Typography>
//                 );
//                 break;
              case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
                if (field.name === 'price_services_info') {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            {field.label} {currentFormValues[field.name]}
                        </Typography>
                    );
                } else if (field.name === 'discount_info_to_rp') {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            ({field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])})
                        </Typography>
                    );
                } else {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            {field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])}
                        </Typography>
                    );
                }
              break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.CHECKBOX:
                fieldComponent = (
                  <CheckboxGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.RADIO:
                fieldComponent = (
                  <RadioGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    errors={currentErrors}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.DATE:
                fieldComponent = (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              // Add cases for other field types
              default:
                fieldComponent = null;
            }

            return (
              <Grid
                item
                xs={field.gridWidth || 12}
                key={field.name}
                sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
              >
                {fieldComponent}
              </Grid>
            );
          })}
        </>
      );
    }
  };

  const renderSectionFields = (section) => {
    if (isLoadedFormValues) {
      // if (section.title === "Sub Service" || section.title === "Attachment") {
      if (section.title === "Sub Service") {
        // Render Sub Service or Attachment section
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
              </Box>
            )}
            {formValues[section.title].map((rowValues, index) => (
              <div key={index}>
                <Grid container spacing={0.3}>
                  <Grid item xs={11.3}>
                    <Grid container spacing={1}>
                      {renderFields(section, index)}
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ...removeButton,
                      }}
                      onClick={() => handleRemoveFieldRow(section.title, index)}
                      // OLD disabled={(actionType === ACTION_TYPE.ADD && formValues[section.title].length === 1) || actionType === ACTION_TYPE.EDITED}>
                      disabled={formValues[section.title].length === 1}>
                      <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                    </Button>
                  </Grid>
                </Grid>
                {formValues[section.title].length > 1 && (
                  <Divider sx={{ mt: 2, mb: 2 }} />
                )}
              </div>
            ))}
            {
              /* ********************** OLD **********************
               actionType !== ACTION_TYPE.EDITED && (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)} disabled={
                //(actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER && formValues[section.title].length === 1) ||
                actionType === ACTION_TYPE.EDITED}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
              */
            (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
            }
          </Grid>
        );
      } else {
        // Render other sections using renderFields function
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
                {/* Subtitle */}
                {section.subtitle && (
                  <Typography variant="subtitle2">{section.subtitle}</Typography>
                )}
              </Box>
            )
            }
            <Grid container spacing={1}>
              {renderFields(section)}
            </Grid>
          </Grid >
        );
      }
    }
  };

  const renderFormContent = () => {
    if(isLoadedFormValues) {
       if (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED ) {
        return (
          <>
            <Grid
              container
              spacing={1.5}
              sx={{
                // overflowX: "auto",
                // height: "52vh",
                // paddingBottom: "2rem",
              }}
            // className="smooth-scroll"
            >
            <Grid item xs={12}>
             <Box sx={{ marginBottom: 2, ...justifyContentBetween, ...alignItemsCenter }}>
              <Button
                variant="contained"
                className={activeTab === 0 ? "activeButton" : "inactiveButton"}
                onClick={() => setActiveTab(0)}
                variant={activeTab === 0 ? "contained" : "outlined"}
                sx={{width:'49%'}}
                size="large"
                disableElevation
              >
                New Our Reference
              </Button>
              <Button
                variant="contained"
                className={activeTab === 1 ? "activeButton" : "inactiveButton"}
                onClick={() => setActiveTab(1)}
                variant={activeTab === 1 ? "contained" : "outlined"}
                sx={{width:'49%'}}
                size="large"
                disableElevation
              >
                Select Our Reference
              </Button>
              </Box>
              </Grid>
              {/* {JSON.stringify(isLoadedFormValues)} */}
              {/* <div class="test">
                {JSON.stringify(actionType)}
              {JSON.stringify(formValues)}
              </div>
              */}
              {/* <hr /> */}
              {/* {JSON.stringify(errors)} */}
              {isLoadedFormValues ?
                fields.map((section) => renderSectionFields(section)) : (<Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '100%'
                }}><FacebookCircularProgress /></Box>)
              }
            </Grid>
          </>
        )
       } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
        return <ConfirmDelete userDeleted={userDeleted} />;
      } else if (actionType === ACTION_TYPE.DETAIL) {
        return ''
      }
    } else {
    return(<Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'
    }}><FacebookCircularProgress /></Box>)
  }

  };

  const renderActionButtons = () => {
    return (
      <Box>
        {actionType !== ACTION_TYPE.DETAIL ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
              id="cancel-modal"
            >
              Cancel
        </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              id="submit-modal"
            >
              {actionType === ACTION_TYPE.DELETED || actionType === "PREVIEW" ? "Yes" : "Save"}
            </Button>
          </>
        ) : (
            <>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => handleCloseFormModal()}
                sx={{ mr: 2 }}
                id="cancel-modal"
              >
                Cancel
        </Button>
              <Button
                variant="outlined"
                sx={{ ...btnWhite }}
                onClick={() => handleCloseFormModal()}
                id="submit-modal"
              >
                Oke
        </Button>
            </>
          )}
      </Box>
    );
  };


   const handleInputChange = async (
    event,
    index,
    sectionTitle,
    field,
    fileIndex
  ) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, 'SOBARI')
    // debugger;

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (!isNaN(index)) {
              updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
            } else {
              updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
            }

            return updatedErrors;
          });
          return;
        // }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = "";
          }
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => (i === index ? { ...item, [name]: value } : item)
          );
        } else {
          updatedValues[sectionTitle] = {
            ...updatedValues[sectionTitle],
            [name]: value,
          }

        }
        return updatedValues;

      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
            (item, i) =>
              i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          updatedErrors[sectionTitle] = {
            ...updatedErrors[sectionTitle],
            [name]: fieldErrors,
          };
        };
        return updatedErrors;
      });

      if(name === 'user_id') {
        const aa = value;
        const dataItem = options[name].find(option => option.id === value);
        debugger;
        setFormValues((prevValues) => {
          return {
            ...prevValues,
            'Employee Information': {
              ...prevValues['Employee Information'],
              [name]: value,
              job_level: dataItem ? dataItem.job_level : 'Job Level', // Set default value if dataItem is undefined
              job_position: dataItem ? dataItem.position : 'Job Position', // Set default value if dataItem is undefined
              organization_name: dataItem ? dataItem.organization_name : 'Organization Name', // Set default value if dataItem is undefined
              employe_status: dataItem ? dataItem.employe_status : 'Employee Status', // Set default value if dataItem is undefined
            }
          };
        });

      }

    }
  }



  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
  }

  const validateFileInput = (
    fieldValue,
    field,
    sectionTitle,
    index,
    sectionErrors
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }

    }

    return "";
  };


  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Sub Service")
          // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" || fieldValue === undefined ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };


  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {



        const { title } = fieldGroup;
        const sectionErrors =
          fieldGroup.title === "Sub Service"
            ? []
            : {};


        fieldGroup.children.forEach((field) => {
          const { name, type } = field;
          if (
            fieldGroup.title === "Sub Service"
          ) {

            formValues[title].forEach((sectionValues, index) => {
              // Check if the field is of type "file" and an array
              if (
                type === INPUT_TYPE.FILE &&
                Array.isArray(sectionValues[name])
              ) {
                sectionValues[name].forEach((file, subIndex) => {
                  // Handle file input validation
                  const fileError = validateFileInput(file, field);

                  if (!sectionErrors[index]) {
                    sectionErrors[index] = {};
                  }

                  if (!sectionErrors[index][name]) {
                    sectionErrors[index][name] = [];
                  }

                  sectionErrors[index][name][subIndex] = fileError;

                  if (fileError) {
                    valid = false;
                    // debugger;
                  }
                });
              } else if (type !== "file") {
                const fieldValue =
                  sectionValues[name] !== undefined ? sectionValues[name] : "";
                const error = validateFieldHelper(fieldValue, field);

                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

                sectionErrors[index][name] = error;

                if (error) {
                  valid = false;
                }

              }
            });
          } else {
            if (
              type === INPUT_TYPE.FILE &&
              Array.isArray(formValues[title][name])
            ) {

              formValues[title][name].forEach((file, subIndex) => {
                // Handle file input validation
                const fileError = validateFileInput(file, field);

                if (!sectionErrors[name]) {
                  sectionErrors[name] = [];
                }

                sectionErrors[name][subIndex] = fileError;

                if (fileError) {
                  valid = false;
                }
              });
            } else if (type !== "file") {

                if (activeTab === 0 &&  name === 'reference_number') {
                  if (!formValues[title][name]) {
                    field = {...field,  required:true}
                    sectionErrors[name] = validateFieldHelper(formValues[title][name], field)
                    debugger
                    valid = false;
                  }
                } else if(activeTab === 1 && name === 'get_reference_number_from_previous_data'){
                  if (!formValues[title][name]) {
                    field = {...field,  required:true}
                    sectionErrors[name] = validateFieldHelper(formValues[title][name], field)
                    valid = false;
                  }
                }

              // Handle regular input field validation
              const fieldValue =
                formValues[title] && formValues[title][name] !== undefined
                  ? formValues[title][name]
                  : "";
              const error = validateFieldHelper(fieldValue, field);

              sectionErrors[name] = error;

              if (error) {
                valid = false;
              }



              //
            }

          }
        });

        newErrors[title] = sectionErrors;
      }
    });


    setErrors(newErrors);

    // debugger;
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
//           handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
//           handleAddRow();
          // if isNew true -> nanti get yg our_reference aja tapi kalo gak true maka diambil dari get_reference_number_from_previous_data
          localStorage.setItem('patent-our-reference-is-new-or-not', JSON.stringify({'isNew': activeTab === 0, ...formValues['Add Patent Application'] }))
          handleCloseFormModal(true);
          navigate('add')
        } else if (actionType === "ADD_NEW_GROUP") {
          handleCloseFormModal(true);
          handleFormModal(ACTION_TYPE.ADD)
        }
        setSelectedData([]);
      }
    }
  };


  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getApplication({
          token,
          page,
          limit,
          search,
          searchBy,
          status,
          sortDirection,
          sortBy,
          period,
          category
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getApplication({
        token,
        page,
        limit,
        search,
        searchBy,
        status,
        sortDirection,
        sortBy,
        period,
        category
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getApplication({
        token,
        limit,
        search,
        searchBy,
        status,
        sortDirection,
        sortBy,
        category,
         period,
      })
    );
  }

  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getApplication({ token, limit, search,searchBy, status, sortDirection, sortBy, category, period, })
      );
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getApplication({ token, limit, search, status, sortDirection, sortBy, type })
    // );
  }

  function _category(val) {
    setCategory(val);
  }


  const handlePeriodChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setPeriod(formattedDate);
      setPeriodElm(date);
    } else {
      console.error("Invalid start date:", date);
    }
  };



  useEffect(() => {
    if (period) {
    if (dayjs(period).isValid()) {
      dispatch(
        getApplication({ token, limit, search,searchBy, status, sortDirection, sortBy, category, period })
      );
    }
    }
  }, [period]);


  function _dateFilter(date) {
    if (date === "custom_date") {
      setShowDatePicker(true);
      setDateFilter(date);

    } else {
      setShowSelectDate(false);

      if ((dateFilter == 'custom_date' && (date !== 'custom_date' && date !== undefined))) {
        setShowDatePicker(false);

        setStartDate(null);
        setStartDateElm(null);
        setEndDate(null);
        setEndDateElm(null);
      }

      if (date && status !== ACTION_TYPE.DEFAULT_STATUS) {
        setDateFilter(date);
      }
    }
  }

  const handleStartDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setStartDate(formattedDate);
      setStartDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleEndDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setEndDate(formattedDate);
      setEndDateElm(date);
    } else {
      console.error("Invalid end date:", date);
      // You might want to handle this error case gracefully
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setShowSelectDate(false);
      dispatch(
        getApplication({
          token,
          limit,
          search,
          searchBy,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
        })
      );
    }
  }, [startDate, endDate]);

  const handleSelectClose = (e) => {
    if (e) e.stopPropagation();
    setShowDatePicker(false);
    setShowSelectDate(false);
  };

        const placeholder = {
        "code":"No. Application",
        "reference_number":"No. Reference",
        "title":"Title",
        }


  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    // console.log(selection);
    setSelectedData(selection || []);
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

    const ModifyToolbarRight = () => {
    return (
      <Tooltip title={`Add New KPI Rules`}>
        <span>
          <IconButton
            size="medium"
            color="primary"
            aria-label="add"
            disabled={
              userAccess.includes(_.CREATE) == false
            }
            sx={{
              ...btnTableToolbar,
              backgroundColor:
                selectedData.length > 0 ? "#ccc" : "primary.main",
              "&:hover": {
                backgroundColor:
                  selectedData.length > 0 ? "#ccc" : "primary.dark",
              },
            }}
            onClick={() => handleFormModal(ACTION_TYPE.ADD)}
            id="add-btn"
          >
            <img
              src={addIcon}
              alt="Add"
              style={{
                ...imgBtnToolbar,
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
{/*      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search attachment file name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
*/}
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4.8}>
            <TextField
                fullWidth
                placeholder={`Search ${placeholder[searchBy]}`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchIconBlack}
                        style={{ width: '20px', margin: ' 0 8px 0 0' }}
                      />

                    </InputAdornment>
                  ),
                  endAdornment: (
                    <TextField
                    select
                    fullWidth
                    size="small"
                    SelectProps={{
                      IconComponent: ExpandMoreIcon,
                    }}
                    sx={{
                      borderRadius,
                    }}
                    value={searchBy}
                    sx={{'& fieldset': {
                      border:'unset',
                    },borderLeft:`2px solid #ddd`}}
                    onChange={(e) => setSearchBy(e.target.value)}
                  >
                    <MenuItem value="reference_number">No. Reference</MenuItem>
                    <MenuItem value="code">No. Application</MenuItem>
                    <MenuItem value="title">Title</MenuItem>
                  </TextField>
                  )
                }}
                sx={{
                  borderRadius: 4, // Adjust the borderRadius as needed
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2.2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <DatePicker
                selectedDate={periodElm}
                onChange={handlePeriodChange}
                components={{
                  OpenPickerButton: CustomOpenPickerButton,
                }}
                format="MMMM YYYY"
                views={["month", "year"]}
                value={periodElm}
                sx={{
                  ...inputDate,
                }}
              />
            </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={category}
                onChange={(e) => _category(e.target.value)}
                disabled={status === ACTION_TYPE.DEFAULT_STATUS}
              >
                 <MenuItem value={ACTION_TYPE.ALL_CATEGORY} id="all-service">
                  All Category
                </MenuItem>
                {options["category"] &&
                  options["category"].map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Application"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.PAGING}
            search={search}
            showAddToolbar={false}
            componentModifyToolbarRight={ModifyToolbarRight}
            linkCRUD={{ update: `edit/${selectedData}` }}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Application;
