import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  CLEAR_KEY_OPTION,
  DETAIL_INVOICE_FAILED,
  DETAIL_INVOICE_SUCCESS,
  SET_EDIT_ID,
  SET_KEY_OPTION,
} from "./state";

const initialState = {
  isLoading: false,
  message: null,
  data: null,
  edit_id: null,
  key_options: [],
};

const detailInvoiceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case DETAIL_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    case DETAIL_INVOICE_FAILED:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    case SET_EDIT_ID:
      return {
        ...state,
        edit_id: payload,
      };
    case SET_KEY_OPTION:
      const prevVal = Array.isArray(state.key_options)
        ? [...state.key_options]
        : [];
      prevVal.push(payload);
      return {
        ...state,
        key_options: prevVal,
      };
    case CLEAR_KEY_OPTION:
      return {
        ...state,
        key_options: [],
      };
    default:
      return state;
  }
};

export default detailInvoiceReducer;

/**
 * response payload
 * {
    "canDownload": false,
    "canAction": false,
    "Others": {
        "approval": "Lead",
        "notes": [
            "Metode Pembayaran - via bank transfer sesuai dengan bank details yang tercantum pada invoice",
            "Mohon cantumkan nomor invoice / proforma invoice pada saat pembayaran dan bukti pembayaran di email ke accounting@pacificpatent.com atau via WhatsApp ke 0877 2245 8585"
        ]
    },
    "Client": {
        "Bill To": "22222",
        "Attn": "Mary Smith",
        "Bill Address": "123 Main Street",
        "Accounting": "Lead",
        "Remark": "-"
    },
    "Product": {
        "title": "Evie Jacobson",
        "status": "Pending",
        "No. Proforma Invoice": "A0003",
        "Created At": "27/03/2024, 15:42",
        "No Reference": "2222222223",
        "Service Category": "Main Service 4",
        "Sub Category Service": [],
        "Price ( 0 service)": "Rp 0",
        "Discount": "Rp 0",
        "VAT": "Rp 0",
        "Total": "Rp 0",
        "Description": "-"
    },
    "Bank Transfer": {
        "Account Name": "llaksjdf",
        "Account address": "456 Oak Avenue",
        "Name of Bank": "Bank Central Asia (BCA)",
        "Account No": "341341234123",
        "Bank address": "222222",
        "Payment Received": 1000,
        "Payment Conversion Received": 800
    }
}
 */
