//@ts-check
import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";
import {
  RequestLoading,
  REQUEST_LOADING,
  RunModal,
} from "../../../../util/global_state";
import { logout } from "../../../auth/clients/login/actions";
import {
  ADD_PURCHASE_REQUEST_FAIL,
  ADD_PURCHASE_REQUEST_SUCCESS,
  DELETE_PURCHASE_REQUEST_FAIL,
  DELETE_PURCHASE_REQUEST_SUCCESS,
  DETAIL_PURCHASE_REQUEST_FAIL,
  DETAIL_PURCHASE_REQUEST_SUCCESS,
  GET_PURCHASE_REQUEST_FAIL,
  GET_PURCHASE_REQUEST_SUCCESS,
  UPDATE_PURCHASE_REQUEST_FAIL,
  UPDATE_PURCHASE_REQUEST_SUCCESS,
} from "./state";

/**
 * @typedef {import("@reduxjs/toolkit").Dispatch} dispatch
 * @typedef {'GET'|'POST'|'EDIT'|'DELETE'|'DETAIL'} MethodType
 */

const purchaseBaseUrl = "purchase-requests";

/** --------------------------------------------------------------------- */
/**                                 HELPER                                */
/** --------------------------------------------------------------------- */

/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {dispatch} dispatch
 * @param {Function} successHandler
 * @param {Function} errorHandler
 * @param {MethodType} type
 */
const responseHandler = (
  response,
  dispatch,
  successHandler,
  errorHandler,
  type
) => {
  if (
    typeof successHandler !== "function" ||
    typeof errorHandler !== "function"
  ) {
    throw new Error("Handler is not function");
  }
  const listActionRunModal = ["POST", "EDIT", "DELETE"];
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    if (type === "GET") {
      dispatch(successHandler(response.data.data, response.data));
    } else if (type === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      let message = "Purchase request";
      dispatch(
        RunModal(
          type === "POST" ? "SAVED" : type === "EDIT" ? "EDITED" : "DELETED",
          message
        )
      );
      dispatch(successHandler(response.data.message));
    }
  } else if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === false
  ) {
    if (type === "GET") {
      dispatch(successHandler([], null));
    } else {
      dispatch(errorHandler(response.data.message));
      dispatch(RunModal("FAILED", response.data.message));
    }
  } else {
    dispatch(errorHandler(response.data.message));
    dispatch(RunModal("FAILED", response.data.message));
  }
};

/**
 *
 * @param {import('axios').AxiosError} errorResposense
 * @param {dispatch} dispatch
 * @param {Function} failHandler
 */
const errorHandler = (errorResposense, dispatch, failHandler) => {
  let messageError = "";
  if (
    errorResposense.response !== undefined &&
    errorResposense.response.data !== undefined
  ) {
    if (errorResposense.response.status === 401) {
      dispatch(logout());
      return;
    } else {
      dispatch(failHandler(errorResposense.response.data.message));
      messageError = errorResposense.response.data.message;
    }
  } else {
    messageError = formatNetworkErrorMessage(errorResposense.message);
    dispatch(failHandler(messageError));
  }

  dispatch(RunModal("FAILED", messageError));
};

/** --------------------------------------------------------------------- */
/**                                   GET                                 */
/** --------------------------------------------------------------------- */
export const getPurchaseRequest = (
  token,
  { search = "", status, page = 1, limit = 10, sortDirection, sortBy }
) => {
  return (/** @type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    //@ts-ignore
    const craftedUrl = urlRequest({
      limit,
      page,
      search,
      status:
        status === ACTION_TYPE.DEFAULT_STATUS ||
        status === ACTION_TYPE.ALL_STATUS
          ? null
          : status,
      sortDirection,
      sortBy,
      url: purchaseBaseUrl,
    });

    axios
      .get(craftedUrl, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          getPurchaseRequestSuccess,
          getPurchaseRequestFail,
          "GET"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, getPurchaseRequestFail);
      });
  };
};

function getPurchaseRequestSuccess(data, pagination) {
  return {
    type: GET_PURCHASE_REQUEST_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: parseInt(pagination.limit),
            totalData: pagination.total_data,
            totalPage: pagination.total_data,
          },
  };
}

function getPurchaseRequestFail(message) {
  return {
    type: GET_PURCHASE_REQUEST_FAIL,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                   ADD                                 */
/** --------------------------------------------------------------------- */

export const addPurchaseRequest = (token, payload) => {
  return (/** @type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    return axios
      .post(purchaseBaseUrl, payload, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          addPurchaseRequestSuccess,
          addPurchaseRequestFail,
          "POST"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, addPurchaseRequestFail);
      });
  };
};

function addPurchaseRequestSuccess(message) {
  return {
    type: ADD_PURCHASE_REQUEST_SUCCESS,
    payload: message,
  };
}

function addPurchaseRequestFail(message) {
  return {
    type: ADD_PURCHASE_REQUEST_FAIL,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                 UPDATE                                */
/** --------------------------------------------------------------------- */
export const updatePurchaseRequest = (token, payload, id) => {
  return (/**@type {dispatch} */ dispacth) => {
    dispacth(RequestLoading());
    let localUrl = purchaseBaseUrl.concat("/", id);
    return axios
      .put(localUrl, payload, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispacth,
          updatePurchaseRequestSuccess,
          updatePurchaseRequestFail,
          "EDIT"
        );
      })
      .catch((error) => {
        errorHandler(error, dispacth, updatePurchaseRequestFail);
      });
  };
};

function updatePurchaseRequestSuccess(message) {
  return {
    type: UPDATE_PURCHASE_REQUEST_SUCCESS,
    payload: message,
  };
}

function updatePurchaseRequestFail(message) {
  return {
    type: UPDATE_PURCHASE_REQUEST_FAIL,
    payload: message,
  };
}
/** --------------------------------------------------------------------- */
/**                                 DELETE                                */
/** --------------------------------------------------------------------- */
export const deletePurchaseRequest = (token, ids) => {
  return (/**@type {dispatch} */ dispacth) => {
    dispacth(RequestLoading());
    const localUrl = purchaseBaseUrl.concat("/", ids);
    axios
      .delete(localUrl, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispacth,
          deletePurchaseRequestSucces,
          deletePurchaseRequestFail,
          "DELETE"
        );
      })
      .catch((error) => {
        errorHandler(error, dispacth, deletePurchaseRequestFail);
      });
  };
};

function deletePurchaseRequestSucces(message) {
  return {
    type: DELETE_PURCHASE_REQUEST_SUCCESS,
    payload: message,
  };
}

function deletePurchaseRequestFail(message) {
  return {
    type: DELETE_PURCHASE_REQUEST_FAIL,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                 DETAIL                                */
/** --------------------------------------------------------------------- */

export const detailPurchaseRequest = (token, id) => {
  return (/**@type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    return axios
      .get(purchaseBaseUrl.concat("/", id), headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          detailPurchaseRequestSuccess,
          detailPurchaseRequestFail,
          "DETAIL"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, detailPurchaseRequestFail);
      });
  };
};

function detailPurchaseRequestSuccess(data) {
  return {
    type: DETAIL_PURCHASE_REQUEST_SUCCESS,
    payload: data,
  };
}

function detailPurchaseRequestFail(message) {
  return {
    type: DETAIL_PURCHASE_REQUEST_FAIL,
    payload: message,
  };
}
