import React from "react";
import { getTimeFromDate } from "../parts/imutable_state";
import { Typography, Grid, Box } from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../util/style";
import { formatValue } from "../detail_payment.hooks";
import { PaymentStatusBadge } from "../parts/CusomStyle";
import DocumentIcon from "../../../../assets/document.png";

const DotCircleGreen = (props) => (
  <span
    style={{
      display: "inline-block",
      width: props?.size || ".8rem",
      height: props?.size || ".8rem",
      borderRadius: "100%",
      background: props?.color || "#751314",
    }}
  />
);

export default function ApprovalLine({ detailDocument }) {
  const statusColors = {
    pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    approved: { bgColor: successSurfaceColor, colors: successMainColor },
    rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    paid: { bgColor: "#B0CDE7", colors: "#1268B8" },
    // Add more statuses as needed
  };
  const renderTimeStamp = (dateCreate) => {
    const dateOption = {
      location: "id-ID",
      option: { year: "numeric", month: "short", day: "numeric" },
    };
    if (!dateCreate) {
      dateCreate = detailDocument["Date Created"];
    }
    // const valueToRender = renderValue(
    //   dateCreate,
    //   "Create At",
    //   false,
    //   dateOption
    // );

    const dateToRender = new Date(dateCreate).toLocaleDateString(
      dateOption.location,
      dateOption.option
    );

    return new String(dateToRender).concat(" ", getTimeFromDate(dateCreate));
  };

  const setColorStatus = (status) => {
    return statusColors[status.toLowerCase()].colors || "#000";
  };

  if (detailDocument["Approval Line"]) {
    return (
      <React.Fragment>
        {detailDocument["Approval Line"].map((approval) => (
          <Grid container key={approval["created_at"]}>
            <Grid
              item
              xs={1}
              style={{
                paddingInline: ".5rem",
                paddingTop: "1.1rem",
                paddingBottom: ".2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // gap: ".1rem",
              }}
            >
              <Box>{DotCircleGreen()}</Box>
              <span
                style={{
                  display: "inline-block",
                  height: "100%",
                  width: "3px",
                  borderRadius: "5px",
                  background: "#F5F5F5",
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Box display={"flex"} marginY={".3rem"}>
                <Typography
                  fontWeight={"600"}
                  fontSize={"1.1rem"}
                  variant="body1"
                >
                  {approval?.user_fullname}{" "}
                </Typography>
                <Typography paddingInline=".5rem">
                  {DotCircleGreen({ size: ".5rem" })}{" "}
                </Typography>
                <Typography
                  fontWeight={"400"}
                  fontSize={"1.1rem"}
                  variant="body1"
                >
                  {approval?.position}
                </Typography>
              </Box>
              <Box display={"flex"} marginY={".3rem"} alignItems={"center"}>
                {/* <Grid>{renderTimeStamp(approval?.updated_at)}</Grid> */}
                <Grid>{renderTimeStamp(approval?.updated_at)}</Grid>
                <Grid paddingInline=".5rem">
                  {DotCircleGreen({ size: ".3rem" })}{" "}
                </Grid>
                <Grid color={setColorStatus(approval?.status)} fontWeight={600}>
                  {approval?.status}
                </Grid>
              </Box>
              <Box marginY={".3rem"}>
                {/* Comment approval */}
                <Typography variant="body1">{approval?.reason || "-"}</Typography>
              </Box>
            </Grid>
          </Grid>
        ))}

        {/* Approval payment request created */}

        <Grid container key={"Payment Requested"}>
          <Grid
            item
            xs={1}
            style={{
              paddingInline: ".5rem",
              paddingTop: "1.1rem",
              paddingBottom: ".2rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // gap: ".1rem",
            }}
          >
            <Box>{DotCircleGreen()}</Box>
            <span
              style={{
                display: "inline-block",
                height: "100%",
                width: "3px",
                borderRadius: "5px",
                background: "#F5F5F5",
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Box display={"flex"} marginY={".3rem"}>
              <Typography
                fontWeight={"400"}
                fontSize={"1.1rem"}
                variant="body1"
              >
                Payment Requested
              </Typography>
            </Box>
            <Box display={"flex"} marginY={".3rem"} alignItems={"center"}>
              <Grid>{renderTimeStamp()}</Grid>
            </Box>
            <Box>
                {detailDocument["Note"]}
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
