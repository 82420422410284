
import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Box, Divider, Button,Card, CardContent } from "@mui/material";
import CalendarIcon from "./../../../../assets/calendar.png";
import RemoveIcon from "./../../../../assets/minus.svg";

import { removeButton, justifyContentEnd,borderRadius } from "../../../../util/style";
import axios from "../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../../../components/Form";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const Step4 = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
  setIsFormValid,
  formDataAllStep
}) => {

//   debugger
  const { id } = useParams();

    if (formData &&
        Object.values(formData).length > 0 &&
        formData['StepOne'] &&
        formData['StepOne']['Applicant Information'] &&
        (String(formDataAllStep['StepOne']['Applicant Information']['type_id']).includes(103) ||
        String(formDataAllStep['StepOne']['Applicant Information']['type_id']).includes(104))) {

        if (!formData.hasOwnProperty('Product Composition')) {
            formData['Product Composition'] = { material_name: null, material_type_name: null, composition: null }
        }
    }




//   const [isFormValid, setIsFormValid] = useState(false);

  const fields = [
    {
      title: "Inventor",
      children: [
         {
          name: "name",
          type: INPUT_TYPE.TEXT,
          label: "Name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "nationality",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Nationality",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/provinces",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "country_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Country",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/provinces",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "province_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Province",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/provinces",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "regency_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "City",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
//NEW
//           apiEndpoint: "/master/regencies",
//           params: {
//             province_id: 11,
//           },
          alias:'Office Address.regency_id',
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "district_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Sub District",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
//NEW
//           apiEndpoint: "/master/districts",
//           params: {
//             regency_id: 1101,
//           },
          alias:'Office Address.district_id',
          required: true,
          gridWidth: 6, // Half width
        },
         {
          name: "address",
          type: INPUT_TYPE.TEXTAREA,
          label: "Address",
          placeholder: "E.g.: Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "zip_code",
          type: INPUT_TYPE.NUMBER,
          label: "Zip Code",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
            name: "email",
            type: INPUT_TYPE.TEXT,
            label: "Email",
            defaultValue: "",
            required: true,
            validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
            errorMessage: "Invalid email address",
            gridWidth: 6, // Half width
        },
        {
            name: "phone",
            type: INPUT_TYPE.NUMBER,
            label: "Phone",
            placeholder: 'e.g : 02123456789',
            defaultValue: "",
            required: true,
            gridWidth: 6, // Half width
        },

      ],
    }
  ];

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered]);

  const { token } = useSelector((state) => state.auth);

  const initialProductComposition = {
    material_name: '',
    material_type_name: '',
    composition: ''
  };

  const initialProductInformation = {
    product_name: "",
    brand_name: "",
    primary_packaging: "",
    secondary_packaging: "",
    net_weight: "",
    monthly_production_capacity:"",
    "Product Composition": [{material_name:'',material_type_name:'', composition:'' }]
  };

    const initialInventor = {
       name:"",nationality: "",country_id: "",province_id:"",regency_id:"",district_id:"",address:"",zip_code:"",
       email:"",phone:""
    };

  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialErrorProductInformation = {
    product_name: "",
    brand_name: "",
    primary_packaging: "",
    secondary_packaging: "",
    net_weight: "",
    monthly_production_capacity:"",
    "Product Composition": [{material_name:'',material_type_name:'', composition:'' }]
  };

  const initialErrorInventor = {
      name:"",nationality: "",country_id: "",province_id:"",regency_id:"",district_id:"",address:"",zip_code:"",
      email:"",phone:""
  };

  const initialErrorEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialErrorProductComposition = {
    material_name: '',
    material_type_name: '',
    composition: ''
  };


  const handleAddRow = (sectionTitle, rowIndex) => {
    debugger;
    if (sectionTitle === "Product Information") {
        setFormValues((prevValues) => ({
        ...prevValues,
        [sectionTitle]: [
            ...prevValues[sectionTitle],
            {
            ...initialProductInformation,
            "Product Composition": [initialProductComposition],
            },
        ],
        }));

        setErrors((prevErrors) => ({
        ...prevErrors,
        [sectionTitle]: [
            ...prevErrors[sectionTitle],  // Spread the existing errors
            {
            ...initialErrorProductInformation,
            "Product Composition": [initialErrorProductComposition],
            },
        ],
        }));
    } else if (sectionTitle === "Product Composition") {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            updatedValues["Product Information"] = updatedValues["Product Information"].map(
            (item, i) => {
            if (i === rowIndex) {
    //           debugger;  // This will pause execution and open the browser's debugger
                return {
                ...item,
                "Product Composition": [
                    ...item["Product Composition"],
                    initialProductComposition,
                ],
                };
            } else {
                return item;
            }
            }
            );
            return updatedValues;
        });

    setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        debugger;
        updatedErrors["Product Information"] = updatedErrors["Product Information"].map(
        (item, i) => {
            if (i === rowIndex) {
    //           debugger;  // This will pause execution and open the browser's debugger
            return {
                ...item,
                "Product Composition": [
                ...item["Product Composition"],
                initialErrorProductComposition,
                ],
            };
            } else {
            return item;
            }
        }
        );
        return updatedErrors;
    });

    } else if (sectionTitle === "Inventor") {
        setFormValues((prevValues) => ({
        ...prevValues,
        [sectionTitle]: [
            ...prevValues[sectionTitle],
            {
            ...initialInventor,
            },
        ],
        }));

        setErrors((prevErrors) => ({
        ...prevErrors,
        [sectionTitle]: [
            ...prevErrors[sectionTitle],  // Spread the existing errors
            {
            ...initialErrorInventor,
            },
        ],
        }));
    };
  }

  const removeRow = (sectionTitle, rowIndex, values, errors, setValues, setErrors) => {
    if (sectionTitle === "Product Information") {
      setValues((prevValues) => ({
        ...prevValues,
        [sectionTitle]: prevValues[sectionTitle].filter(
          (row, index) => index !== rowIndex
        ),
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [sectionTitle]: prevErrors[sectionTitle].filter(
          (error, index) => index !== rowIndex
        ),
      }));
    } else if (sectionTitle === "Product Composition") {
      setValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues["Product Information"] = updatedValues["Product Information"].map(
          (item, i) =>
            i === rowIndex
              ? {
                  ...item,
                  "Product Composition": item["Product Composition"].filter(
                    (composition, compIndex) => compIndex !== rowIndex
                  ),
                }
              : item
        );
        return updatedValues;
      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors["Product Information"] = updatedErrors["Product Information"].map(
          (item, i) =>
            i === rowIndex
              ? {
                  ...item,
                  "Product Composition": item["Product Composition"].filter(
                    (composition, compIndex) => compIndex !== rowIndex
                  ),
                }
              : item
        );
        return updatedErrors;
      });
    } else if (sectionTitle === "Inventor") {
      setValues((prevValues) => ({
        ...prevValues,
        [sectionTitle]: prevValues[sectionTitle].filter(
          (row, index) => index !== rowIndex
        ),
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [sectionTitle]: prevErrors[sectionTitle].filter(
          (error, index) => index !== rowIndex
        ),
      }));
    }
  };

  const handleRemoveRow = (sectionTitle, rowIndex, ) => {
    removeRow(sectionTitle, rowIndex, formValues, errors, setFormValues, setErrors);
  };


  const initialFormValues = () => {
    const initialValues = {};
    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (
          fieldGroup.title === "Product Information" ||
          fieldGroup.title === "Emergency Contact" ||
          fieldGroup.title === "Inventor"
        ) {
            initialValues[fieldGroup.title] =
            fieldGroup.title === "Product Information"
                ? [initialProductInformation]
                : fieldGroup.title === "Inventor"
                ? [initialInventor]
                : [initialEmergencyContact];
        } else {
          fieldGroup.children.forEach((field) => {
            if (!initialValues[fieldGroup.title]) {
              initialValues[fieldGroup.title] = {};
            }
            initialValues[fieldGroup.title][field.name] = field.defaultValue;
          });
        }
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const initialErrorValues = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (
          fieldGroup.title === "Product Information" ||
          fieldGroup.title === "Emergency Contact" ||
          fieldGroup.title === "Inventor"
        ) {
        initialErrorValues[fieldGroup.title] =
        fieldGroup.title === "Product Information"
            ? [initialErrorProductInformation]
            : fieldGroup.title === "Inventor"
            ? [initialErrorInventor]
            : [initialErrorEmergencyContact];

        } else {
          if (!initialErrorValues[fieldGroup.title]) {
            initialErrorValues[fieldGroup.title] = {};
          }
          fieldGroup.children.forEach((field) => {
            initialErrorValues[fieldGroup.title][field.name] = "";
          });
        }
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0
      ? formData
      : initialFormValues()
  );

  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState(
    formData && Object.values(formData)?.length > 0
      ? cloneObjectWithEmptyStrings(formData)
      : initialErrors()
  );

  useEffect(() => {
    const storedOptions = JSON.parse(localStorage.getItem("patent_options_step_four"));

    if (!storedOptions || Object.keys(storedOptions || {}).length === 0 || id) {
      // Options data not found in localStorage, fetch it
      const fetchData = async () => {
        try {
          const fetchedOptions = {};

          for (const section of fields) {
            if (section.children) {
              for (const field of section.children) {
                const { name, apiEndpoint } = field;

                if (apiEndpoint) {
                  const response = await axios.get(apiEndpoint, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });

                  fetchedOptions[name] = response.data.data;
                }
              }
            }
          }

          // Store fetched options in localStorage
          localStorage.setItem(
            "patent_options_step_four",
            JSON.stringify(fetchedOptions)
          );

          // Set options in state
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      };

      fetchData();
    } else {
      // Options data found in localStorage, set it in state
      setOptions(storedOptions);
    }
  }, [token]);

  /*const handleInputChange = (event, index, sectionTitle, field) => {
    const { name, value } = event.target;
    const updatedErrors = { ...errors };

    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (
        index !== undefined &&
        (sectionTitle === "Product Information" || sectionTitle === "Emergency Contact")
      ) {
        updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
          (item, i) => (i === index ? { ...item, [name]: value } : item)
        );
      } else {
        updatedValues[sectionTitle] = {
          ...updatedValues[sectionTitle],
          [name]: value,
        };
      }

      // Buat column identity_expiry
      if (name === "is_permanent") {
        if (value.includes(1)) {
          setIsPermanent(true);
          updatedValues[sectionTitle]["identity_expiry"] = "";
          updatedErrors[sectionTitle]["identity_expiry"] = "";
        } else {
          setIsPermanent(false);
        }
      }

      // Buat column residential address
      if (name === "use_as_residential_address" && value.includes(1)) {
        updatedValues[sectionTitle]["residential_address"] =
          updatedValues[sectionTitle]["citizen_id_address"];
      }

      if (
        name === "residential_address" &&
        value !== updatedValues[sectionTitle]["citizen_id_address"]
      ) {
        updatedValues[sectionTitle]["use_as_residential_address"] = [];
      }

      if (
        name === "citizen_id_address" &&
        updatedValues[sectionTitle]["use_as_residential_address"].length > 0 &&
        updatedValues[sectionTitle]["use_as_residential_address"].includes(1)
      ) {
        updatedValues[sectionTitle]["residential_address"] =
          updatedValues[sectionTitle]["citizen_id_address"];
      }

      return updatedValues;
    });

    setErrors((prevErrors) => {
      const fieldValue = value;
      const fieldErrors = validateFieldHelper(fieldValue, field);

      if (
        index !== undefined &&
        (sectionTitle === "Product Information" || sectionTitle === "Emergency Contact")
      ) {
        updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
          (item, i) =>
            i === index ? { ...item, [name]: fieldErrors } : { ...item }
        );
      } else {
        updatedErrors[sectionTitle] = {
          ...updatedErrors[sectionTitle],
          [name]: fieldErrors,
        };
      }
      return updatedErrors;
    });
  };
  */

  const handleInputChange = (event, index, sectionTitle, field, compositionIndex) => {
    const { name, value } = event.target;
    const updatedErrors = { ...errors };

    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };

      if (sectionTitle === "Product Composition" && compositionIndex !== undefined) {
        updatedValues["Product Information"] = updatedValues["Product Information"].map(
          (item, i) =>
            i === index
              ? {
                  ...item,
                  "Product Composition": item["Product Composition"].map(
                    (composition, compIndex) =>
                      compIndex === compositionIndex
                        ? { ...composition, [name]: value }
                        : composition
                  ),
                }
              : item
        );
      } else {
        updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
          (item, i) =>
            i === index ? { ...item, [name]: value } : item
        );
      }

      // Placeholder for existing logic - you may have additional logic here

      return updatedValues;
    });

    setErrors((prevErrors) => {
      const fieldValue = value;
      const fieldErrors = validateFieldHelper(fieldValue, field);

      if (sectionTitle === "Product Composition" && compositionIndex !== undefined) {
        updatedErrors["Product Information"] = updatedErrors["Product Information"].map(
          (item, i) =>
            i === index
              ? {
                  ...item,
                  "Product Composition": item["Product Composition"].map(
                    (composition, compIndex) =>
                      compIndex === compositionIndex
                        ? { ...composition, [name]: fieldErrors }
                        : composition
                  ),
                }
              : item
        );
      } else {
        updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
          (item, i) =>
            i === index ? { ...item, [name]: fieldErrors } : item
        );
      }

      // Placeholder for existing logic - you may have additional logic here

      return updatedErrors;
    });

    updateWizardData("StepFour", formValues);
  };


  const handleInputBlur = (fieldName, sectionTitle, index, compositionIndex, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, compositionIndex, { name, value });
  };


const validateField = (sectionTitle, fieldName, index, compositionIndex, { value }) => {
  const newErrors = { ...errors };
  const fieldGroup = fields.find((group) => group.title === sectionTitle);

  if (fieldGroup && fieldGroup.children) {
    const field = fieldGroup.children.find(
      (field) => field.name === fieldName
    );

    if (field) {
      const { name } = field;
      const targetIndex = compositionIndex !== undefined ? compositionIndex : index;

      if (
        !isNaN(targetIndex) &&
        (sectionTitle === "Product Information" || sectionTitle === "Emergency Contact" || sectionTitle === "Inventor")
      ) {
        if (!newErrors[sectionTitle][targetIndex]) {
          newErrors[sectionTitle][targetIndex] = {};
        }

        const fieldValue = formValues[sectionTitle][targetIndex][name] || value;
        newErrors[sectionTitle][targetIndex][name] = validateFieldHelper(
          fieldValue,
          field
        );
      } else {
        const fieldValue = formValues[sectionTitle][name] || value;
        newErrors[sectionTitle][name] = validateFieldHelper(
          fieldValue,
          field
        );
      }
    }
  }
  setErrors(newErrors);
};
  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    console.log(
      "------------------------------FORM VALUE----------------------"
    );
    console.log(formValues);
    console.log("------------------------------ERRRORS----------------------");
    console.log(errors);
  }, [formValues, errors]);


//   lagi testing --- okeee
  const validateForm = () => {
  let valid = true;
  const newErrors = {};

  fields.forEach((fieldGroup) => {
    if (fieldGroup.children) {
      const { title } = fieldGroup;
      const sectionErrors = [];

      fieldGroup.children.forEach((field) => {
        const { name } = field;

          // Handle validation for "Product Composition" fields
//         const aa = formValues[title];
//         debugger;
//           if (Array.isArray(formValues[title])) {
            formValues[title].forEach((sectionValues, index) => {
//               debugger;
                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

//               if (Array.isArray(sectionValues['Product Composition'])) {
//               debugger
//                 if (!sectionErrors[index]) {
//                   sectionErrors[index] = {};
//                 }
                    if (sectionValues['Product Composition']) {
                        sectionValues['Product Composition'].forEach((productComposition, itemIndex) => {
                        if(field.children) {
                            field.children.forEach((subField) => {
                            const { name } = subField;
                            Object.keys(productComposition).forEach((fieldName) => {
                                if(name === fieldName) {
                                const fieldValue = productComposition[fieldName] !== undefined ? productComposition[fieldName] : "";
                                const error = validateFieldHelper(fieldValue, subField);

                                if (!sectionErrors[index][field.title]) {
                                    sectionErrors[index][field.title] = [];
                                }

                                if (!sectionErrors[index][field.title][itemIndex]) {
                                    sectionErrors[index][field.title][itemIndex] = {};
                                }

                                if (!sectionErrors[index][field.title][itemIndex][fieldName]) {
                                    sectionErrors[index][field.title][itemIndex][fieldName] = error;
                                }

                                if (error) {
                                    valid = false;
                                }
                                }
                            });
                            });
                        }
                        });
                    }
                 });

                formValues[title].forEach((sectionValues, index) => {
                  const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
                  const error = validateFieldHelper(fieldValue, field);

//                   if (!sectionErrors[index]) {
//                     sectionErrors[index] = {};
//                   }

                  // Ensure that the name property is defined before using it as a key
                  if (name !== undefined) {
                    sectionErrors[index][name] = error;
                  }

                  if (error) {
                    valid = false;
                  }
                });
//               } else {
                /*
                debugger;
                // Handle the case where the field is not an array
                const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
                const error = validateFieldHelper(fieldValue, field);

                if (!sectionErrors[index][name]) {
                  sectionErrors[index][name] = [];
                }

                // Add the error to the array
                sectionErrors[index][name].push(error);

                if (error) {
                  valid = false;
                }
                */
                 // Handle validation for other sections
//                   formValues[title].forEach((sectionValues, index) => {
//                     const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//                     const error = validateFieldHelper(fieldValue, field);
//
//                     if (!sectionErrors[index]) {
//                       sectionErrors[index] = {};
//                     }
//
//                     // Ensure that the name property is defined before using it as a key
//                     if (name !== undefined) {
//                       sectionErrors[index][name] = error;
//                     }
//
//                     if (error) {
//                       valid = false;
//                     }
//                   });
//               }
//             });
//           }
          /*else if */
//         else if(Array.isArray(formValues[title])) {
//           // Handle validation for other sections
//           formValues[title].forEach((sectionValues, index) => {
//             const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//             const error = validateFieldHelper(fieldValue, field);
//
//             if (!sectionErrors[index]) {
//               sectionErrors[index] = {};
//             }
//
//             // Ensure that the name property is defined before using it as a key
//             if (name !== undefined) {
//               sectionErrors[index][name] = error;
//             }
//
//             if (error) {
//               valid = false;
//             }
//           });

//         }
        /*else {
          // Handle validation for non-array sections
          const fieldValue = formValues[title] && formValues[title][name] !== undefined
            ? formValues[title][name]
            : "";
          const error = validateFieldHelper(fieldValue, field);

          // Ensure that the name property is defined before using it as a key
          if (name !== undefined) {
            sectionErrors[name] = error;
          }

          if (error) {
            valid = false;
          }
        }
        */
      });

      newErrors[title] = sectionErrors;
    }
  });

  setErrors(newErrors);
  formAttempted(valid);
  updateWizardData("StepFour", formValues);
  return valid;
};
// ini unutk field biasa okeee
//   const validateForm = () => {
//   let valid = true;
//   const newErrors = {};
//
//   fields.forEach((fieldGroup) => {
//     if (fieldGroup.children) {
//       const { title } = fieldGroup;
//       const sectionErrors = [];
//
//       fieldGroup.children.forEach((field) => {
//         const { name } = field;
//
//         if (title === "Product Composition") {
//           // Handle validation for "Product Composition" fields
//           if (Array.isArray(formValues[title])) {
//             formValues[title].forEach((sectionValues, index) => {
//               if (!sectionErrors[index]) {
//                 sectionErrors[index] = {};
//               }
//
//               if (Array.isArray(sectionValues[name])) {
//                 sectionValues[name].forEach((productComposition, itemIndex) => {
//                   Object.keys(productComposition).forEach((fieldName) => {
//                     const fieldValue = productComposition[fieldName] !== undefined ? productComposition[fieldName] : "";
//                     const error = validateFieldHelper(fieldValue, field);
//
//                     if (!sectionErrors[index][name]) {
//                       sectionErrors[index][name] = [];
//                     }
//
//                     if (!sectionErrors[index][name][itemIndex]) {
//                       sectionErrors[index][name][itemIndex] = {};
//                     }
//
//                     if (!sectionErrors[index][name][itemIndex][fieldName]) {
//                       sectionErrors[index][name][itemIndex][fieldName] = error;
//                     }
//
//                     if (error) {
//                       valid = false;
//                     }
//                   });
//                 });
//               } else {
//                 // Handle the case where the field is not an array
//                 const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//                 const error = validateFieldHelper(fieldValue, field);
//
//                 if (!sectionErrors[index][name]) {
//                   sectionErrors[index][name] = [];
//                 }
//
//                 // Add the error to the array
//                 sectionErrors[index][name].push(error);
//
//                 if (error) {
//                   valid = false;
//                 }
//               }
//             });
//           }
//         } else if (Array.isArray(formValues[title])) {
//           // Handle validation for other sections
//           formValues[title].forEach((sectionValues, index) => {
//             const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//             const error = validateFieldHelper(fieldValue, field);
//
//             if (!sectionErrors[index]) {
//               sectionErrors[index] = {};
//             }
//
//             // Ensure that the name property is defined before using it as a key
//             if (name !== undefined) {
//               sectionErrors[index][name] = error;
//             }
//
//             if (error) {
//               valid = false;
//             }
//           });
//         } else {
//           // Handle validation for non-array sections
//           const fieldValue = formValues[title] && formValues[title][name] !== undefined
//             ? formValues[title][name]
//             : "";
//           const error = validateFieldHelper(fieldValue, field);
//
//           // Ensure that the name property is defined before using it as a key
//           if (name !== undefined) {
//             sectionErrors[name] = error;
//           }
//
//           if (error) {
//             valid = false;
//           }
//         }
//       });
//
//       newErrors[title] = sectionErrors;
//     }
//   });
//
//   setErrors(newErrors);
//   formAttempted(valid);
//   updateWizardData("StepTwo", formValues);
//   return valid;
// };

/* okee lumayan untuk product composition udah ok, cuman message errornya aja yg blm fix*/
//   const validateForm = () => {
//   let valid = true;
//   const newErrors = {};
//
//   fields.forEach((fieldGroup) => {
//     if (fieldGroup.children) {
//       const { title } = fieldGroup;
//       const sectionErrors = [];
//
//       fieldGroup.children.forEach((field) => {
//         const { name } = field;
//
//         if (Array.isArray(formValues[title])) {
//           formValues[title].forEach((sectionValues, index) => {
//             // Check if sectionValues[name] is an array, and validate each element
//             if (Array.isArray(sectionValues['Product Composition'])) {
//               if (!sectionErrors[index]) {
//                 sectionErrors[index] = {};
//               }
//
//               sectionValues['Product Composition'].forEach((productComposition, itemIndex) => {
//                 Object.keys(productComposition).forEach((fieldName) => {
//                   const fieldValue = productComposition[fieldName] !== undefined ? productComposition[fieldName] : "";
//                   const error = validateFieldHelper(fieldValue, field);
//
//                   // Ensure that the name property is defined before using it as a key
//                   if (!sectionErrors[index]['Product Composition']) {
//                     sectionErrors[index]['Product Composition'] = [];
//                   }
//
//                   if (!sectionErrors[index]['Product Composition'][itemIndex]) {
//                     sectionErrors[index]['Product Composition'][itemIndex] = {};
//                   }
//
//                   if (!sectionErrors[index]['Product Composition'][itemIndex][fieldName]) {
//                     sectionErrors[index]['Product Composition'][itemIndex][fieldName] = error;
//                   }
//
//                   if (error) {
//                     valid = false;
//                   }
//                 });
//               });
//             } else {
//               const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//               const error = validateFieldHelper(fieldValue, field);
//
//               if (!sectionErrors[index]) {
//                 sectionErrors[index] = {};
//               }
//
//               // Ensure that the name property is defined before using it as a key
//               if (name !== undefined) {
//                 sectionErrors[index][name] = error;
//               }
//
//               if (error) {
//                 valid = false;
//               }
//             }
//           });
//         } else {
//           const fieldValue = formValues[title] && formValues[title][name] !== undefined
//             ? formValues[title][name]
//             : "";
//           const error = validateFieldHelper(fieldValue, field);
//
//           // Ensure that the name property is defined before using it as a key
//           if (name !== undefined) {
//             sectionErrors[name] = error;
//           }
//
//           if (error) {
//             valid = false;
//           }
//         }
//       });
//
//       newErrors[title] = sectionErrors;
//     }
//   });
//
//   setErrors(newErrors);
//   formAttempted(valid);
//   updateWizardData("StepTwo", formValues);
//   return valid;
// };


// ini udah ok,, cuman product compositionnya aja yg error
//  const validateForm = () => {
//   let valid = true;
//   const newErrors = {};
//
//   fields.forEach((fieldGroup) => {
//     if (fieldGroup.children) {
//       const { title } = fieldGroup;
//       const sectionErrors = [];
//
//       fieldGroup.children.forEach((field) => {
//         const { name } = field;
//
//         if (Array.isArray(formValues[title])) {
//           // formValues[title].forEach((sectionValues, index) => {
//           //   const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//           //   const error = validateFieldHelper(fieldValue, field);
//           //   debugger;
//           //   if (!sectionErrors[index]) {
//           //     sectionErrors[index] = {};
//           //   }
//           //
//           //   // Ensure that the name property is defined before using it as a key
//           //   if (name !== undefined) {
//           //     sectionErrors[index][name] = error;
//           //   }
//           //
//           //   if (error) {
//           //     valid = false;
//           //   }
//           // });
//
//          formValues[title].forEach((sectionValues, index) => {
//           // Check if sectionValues[name] is an array, and validate each element
//            console.log(sectionValues, 'AKUUUU', name, 'KAMUUU', title, 'DIAAAA', sectionValues['Product Composition'], 'HMMM', sectionErrors);
//             if (Array.isArray(sectionValues['Product Composition'])) {
//               sectionValues['Product Composition'].forEach((productComposition, itemIndex) => {
//   // Iterate over each field within the product composition
//   Object.keys(productComposition).forEach((fieldName) => {
//     const fieldValue = productComposition[fieldName] !== undefined ? productComposition[fieldName] : "";
// const aa = field;
//                 const error = validateFieldHelper(fieldValue, field);
//
//                                       if (!sectionErrors[index]) {
//               sectionErrors[index] = {};
//             }
//                 // Ensure that the name property is defined before using it as a key
// //                 if ('Product Composition' !== undefined) {
//                   if (!sectionErrors[index]['Product Composition']) {
//                     sectionErrors[index]['Product Composition'] = {};
//                   }
//                   sectionErrors[index]['Product Composition'][itemIndex][fieldName] = error;
// //                 }
//
//                                                   debugger;
//
//
//                 if (error) {
//                   valid = false;
//                 }
//               });
//               });
//             } else {
//               const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
//               const error = validateFieldHelper(fieldValue, field);
//
//               if (!sectionErrors[index]) {
//                 sectionErrors[index] = {};
//               }
//
//               // Ensure that the name property is defined before using it as a key
//               if (name !== undefined) {
//                 sectionErrors[index][name] = error;
//               }
//
//               if (error) {
//                 valid = false;
//               }
//             }
//             });
//         } else {
//           const fieldValue = formValues[title] && formValues[title][name] !== undefined
//             ? formValues[title][name]
//             : "";
//           const error = validateFieldHelper(fieldValue, field);
//
//           // Ensure that the name property is defined before using it as a key
//           if (name !== undefined) {
//             sectionErrors[name] = error;
//           }
//
//           if (error) {
//             valid = false;
//           }
//         }
//       });
//
//       newErrors[title] = sectionErrors;
//     }
//   });
//
//   setErrors(newErrors);
//   formAttempted(valid);
//   updateWizardData("StepTwo", formValues);
//   return valid;
// };
/*
const validateForm = () => {
  let valid = true;
  const newErrors = {};

  fields.forEach((fieldGroup) => {
    if (fieldGroup.children) {
      const { title } = fieldGroup;
      const sectionErrors = [];

      fieldGroup.children.forEach((field) => {
        const { name } = field;

        if (Array.isArray(formValues[title])) {
          formValues[title].forEach((sectionValues, index) => {
            const fieldValue = sectionValues[name] !== undefined ? sectionValues[name] : "";
            const error = validateFieldHelper(fieldValue, field);

            if (!sectionErrors[index]) {
              sectionErrors[index] = {};
            }

            sectionErrors[index][name] = error;

            if (error) {
              valid = false;
            }
          });
        } else {
          const fieldValue = formValues[title] && formValues[title][name] !== undefined
            ? formValues[title][name]
            : "";
          const error = validateFieldHelper(fieldValue, field);

          sectionErrors[name] = error;

          if (error) {
            valid = false;
          }
        }
      });

      newErrors[title] = sectionErrors;
    }
  });

  setErrors(newErrors);
  formAttempted(valid);
  updateWizardData("StepTwo", formValues);
  return valid;
};*/





/*
const validateForm = () => {
  let valid = true;
  const newErrors = {};

  fields.forEach((fieldGroup) => {
    if (fieldGroup.children) {
      const { title } = fieldGroup;

      const sectionErrors = {};

      fieldGroup.children.forEach((field) => {
        const { name } = field;
        const isNestedField = field.children && field.children.length > 0;

        if (isNestedField) {
          const nestedErrors = field.children.reduce((acc, nestedField) => {
            const { name: nestedFieldName } = nestedField;
            const fieldValue =
              formValues[title] &&
              formValues[title][nestedFieldName] !== undefined
                ? formValues[title][nestedFieldName]
                : "";
            const error = validateFieldHelper(fieldValue, nestedField);

            if (error) {
              valid = false;
            }

            acc[nestedFieldName] = error;
            return acc;
          }, {});

          sectionErrors[name] = [nestedErrors];
        } else {
          const fieldValue =
            formValues[title] && formValues[title][name] !== undefined
              ? formValues[title][name]
              : "";
          const error = validateFieldHelper(fieldValue, field);

          if (error) {
            valid = false;
          }

          sectionErrors[name] = error;
        }
      });

      newErrors[title] = sectionErrors;
    }
  });

  setErrors(newErrors);
  formAttempted(valid);
  updateWizardData("StepFour", formValues);
  return valid;
};*/







const renderFields = (section, rowIndex, compositionIndex) => {
  const currentFormValues = formValues[section.title][rowIndex] || formValues[section.title];
  const currentErrors = errors[section.title][rowIndex] || errors[section.title];

  return (
    <>
      {section.children?.map((field) => {
        if (field.title === "Product Composition") {
                  console.log(field.title, 'SOOO')
                  console.log(formValues, 'ZZZZ')
                  console.log(currentFormValues, 'YYYYY')

          // Render fields for "Product Composition" array
          return (
            <>
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 800 }}>
              {field.title}
            </Typography>
          </Grid>

        {currentFormValues[field.title].map((compositionItem, compositionIndex) => (
            <React.Fragment key={compositionIndex}>
              <Grid container sx={{ marginBottom: 1 }}>
                <Grid item xs={11.2} sx={{marginLeft:1}}>
                  <Grid container spacing={1}>
                  {field.children.map((compositionField) => {
//                     const bb = currentFormValues[field.title][compositionIndex];
//                     const aa = currentErrors;
//                     const cc = currentErrors[field.title][compositionIndex]
                    // debugger;
                  const compositionFieldName = `${field.title}[${compositionIndex}].${compositionField.name}`;

                    const optionsData = Array.isArray(options[compositionField.name])
                      ? [
    //                       ...(compositionField.required &&
    //                         compositionField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE
    //                         ? [{ id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE, name: "Choose Option" }]
    //                         : []),
                          ...options[compositionField.name],
                        ]
                      : [];

                    const fieldOptions = optionsData.filter((option) => option !== false);

                    let fieldComponent = null;

                    switch (compositionField.type) {
                    case INPUT_TYPE.TEXT:
                    case INPUT_TYPE.NUMBER:
                    case INPUT_TYPE.PHONE_NUMBER:
                    case INPUT_TYPE.POINT:
                    case INPUT_TYPE.CURRENCY:
                    case INPUT_TYPE.UNIT:
                    case INPUT_TYPE.DISCOUNT:
                      fieldComponent = (
                        <TextInput
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                          unitTypeIsOn={true}
                        />
                      );
                      break;
                    case INPUT_TYPE.TEXTAREA:
                      fieldComponent = (
                        <TextArea
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                        />
                      );
                      break;
                    case INPUT_TYPE.SELECT_ONE:
                      fieldComponent = (
                        <SelectOne
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                          fieldOptions={fieldOptions}
                        />
                      );
                      break;
                    case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                      fieldComponent = (
                        <SelectOneAutocomplete
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) => {
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e);
                          }}
                          fieldOptions={fieldOptions}
                        />
                      );
                      break;
                    case INPUT_TYPE.SELECT_MULTIPLE:
                      fieldComponent = (
                        <SelectMultiple
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                          fieldOptions={fieldOptions}
                        />
                      );
                      break;
                    case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                      fieldComponent = (
                        <SelectMultipleAutocomplete
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                          fieldOptions={fieldOptions}
                        />
                      );
                      break;
                    case INPUT_TYPE.CHECKBOX:
                      fieldComponent = (
                        <CheckboxGroupInput
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                          fieldOptions={
                            fieldOptions.length
                              ? fieldOptions.filter((option) => option !== false)
                              : field.options
                          }
                        />
                      );
                      break;
                    case INPUT_TYPE.RADIO:
                      fieldComponent = (
                        <RadioGroupInput
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                          errors={currentErrors[field.title][compositionIndex]}
                          fieldOptions={
                            fieldOptions.length
                              ? fieldOptions.filter((option) => option !== false)
                              : field.options
                          }
                        />
                      );
                      break;
                    case INPUT_TYPE.DATE:
                      fieldComponent = (
                        <DatePickerInput
                          key={compositionFieldName}
                          field={compositionField}
                          formValues={currentFormValues[field.title][compositionIndex]}
                          errors={currentErrors[field.title][compositionIndex]}
                          handleInputChange={(e) =>
                            handleInputChange(e, rowIndex, field.title, compositionField, compositionIndex)
                          }
                          handleInputBlur={(e) =>
                            handleInputBlur(field.name, field.title, rowIndex, compositionIndex, e)
                          }
                        />
                      );
                      break;
                    // Add cases for other field types
                    default:
                      fieldComponent = null;
                  }

                    return (

                      <Grid
                        item
                        xs={compositionField.gridWidth || 12}
                        key={compositionFieldName}
                        sx={{ paddingTop: "unset !important", ...compositionField.styleForGrid }}
                      >
                        {fieldComponent}
                      </Grid>
                    );
                  })}
                  </Grid>
                </Grid>

                <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    backgroundColor: 'red',
                    ...removeButton,
                  }}
                  onClick={() => handleRemoveRow(field.title, rowIndex, compositionIndex)}
                  disabled={currentFormValues[field.title].length === 1}
                >
                  <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                </Button>

                {/*<Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: 'red',
                      ...removeButton,
                    }}
                    onClick={() => handleRemoveRow(field.title, compositionIndex)}
                    disabled={currentFormValues[field.title].length === 1}
                  >
                    <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                  </Button>*/}
                </Grid>
              </Grid>
            </React.Fragment>
          ))
        }
        <Grid item xs={12} sx={{...justifyContentEnd}}>
        <Button onClick={() => handleAddRow(field.title, rowIndex)}>
          Add Material
        </Button>
        </Grid>
          </>
          );
        } else {
           let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired =
              field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            optionsData = [
//               ...(isRequired ? [isRequired] : []),
              ...options[field.name],
            ];
          }

          const fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];


          // Render fields for other types of fields
          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
            case INPUT_TYPE.UNIT:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, rowIndex, section.title, field, compositionIndex)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, rowIndex, compositionIndex, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
            >
              {fieldComponent}
            </Grid>
          );
        }
      })}
    </>
  );
};



//   const renderFields = (section, index, field, compositionIndex) => {
//     const currentFormValues =
//       section.title === "Product Information"
//         ? formValues[section.title][index]
//         : section.title === "Emergency Contact"
//         ? formValues[section.title][index]
//         : formValues[section.title];
//
//     const currentErrors =
//       section.title === "Product Information"
//         ? errors[section.title][index]
//         : section.title === "Emergency Contact"
//         ? errors[section.title][index]
//         : errors[section.title];
//     return (
//       <>
//         {section.children?.map((field) => {
//           // Check if children array exists
//           let optionsData;
//           if (Array.isArray(options[field.name])) {
//             const isRequired = field.required &&
//               field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
//                 id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
//                 name: "Choose Option",
//               };
//
//             if (isRequired) {
//               optionsData = [
//                 // field.required &&
//                 //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
//                 //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
//                 //     name: "Choose Option",
//                 //   },
//                 ...options[field.name],
//               ];
//             } else {
//               optionsData = [...options[field.name]];
//             }
//           }
//
//           const fieldOptions = Array.isArray(options[field.name])
//             ? optionsData
//             : [];
//
//           let fieldComponent = null;
//
//           switch (field.type) {
//             case INPUT_TYPE.TEXT:
//             case INPUT_TYPE.NUMBER:
//             case INPUT_TYPE.PHONE_NUMBER:
//             case INPUT_TYPE.POINT:
//             case INPUT_TYPE.CURRENCY:
//             case INPUT_TYPE.UNIT:
//               fieldComponent = (
//                 <TextInput
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                 />
//               );
//               break;
//             case INPUT_TYPE.TEXTAREA:
//               fieldComponent = (
//                 <TextArea
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                 />
//               );
//               break;
//             case INPUT_TYPE.SELECT_ONE:
//               fieldComponent = (
//                 <SelectOne
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                   fieldOptions={fieldOptions}
//                 />
//               );
//               break;
//             case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
//               fieldComponent = (
//                 <SelectOneAutocomplete
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) => {
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e);
//                   }}
//                   fieldOptions={fieldOptions}
//                 />
//               );
//               break;
//             case INPUT_TYPE.SELECT_MULTIPLE:
//               fieldComponent = (
//                 <SelectMultiple
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                   fieldOptions={fieldOptions}
//                 />
//               );
//               break;
//             case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
//               fieldComponent = (
//                 <SelectMultipleAutocomplete
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                   fieldOptions={fieldOptions}
//                 />
//               );
//               break;
//             case INPUT_TYPE.CHECKBOX:
//               fieldComponent = (
//                 <CheckboxGroupInput
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                   fieldOptions={
//                     fieldOptions.length
//                       ? fieldOptions.filter((option) => option !== false)
//                       : field.options
//                   }
//                 />
//               );
//               break;
//             case INPUT_TYPE.RADIO:
//               fieldComponent = (
//                 <RadioGroupInput
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                   errors={currentErrors}
//                   fieldOptions={
//                     fieldOptions.length
//                       ? fieldOptions.filter((option) => option !== false)
//                       : field.options
//                   }
//                 />
//               );
//               break;
//             case INPUT_TYPE.DATE:
//               fieldComponent = (
//                 <DatePickerInput
//                   key={field.name}
//                   field={field}
//                   formValues={currentFormValues}
//                   errors={currentErrors}
//                   handleInputChange={(e) =>
//                     handleInputChange(e, index, section.title, field, compositionIndex)
//                   }
//                   handleInputBlur={(e) =>
//                     handleInputBlur(field.name, section.title, index, compositionIndex, e)
//                   }
//                 />
//               );
//               break;
//             // Add cases for other field types
//             default:
//               fieldComponent = null;
//           }
//
//           return (
//             <Grid
//               item
//               xs={field.gridWidth || 12}
//               key={field.name}
//               sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
//             >
//               {fieldComponent}
//             </Grid>
//           );
//         })}
//       </>
//     );
//   };


const renderSectionFields = (section, compositionIndex) => {
  console.log(section,'SECTION')
  if (section.title === "Product Information") {
    return (
      <Grid item xs={12} key={section.title}>
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {section.title}
          </Typography>
        </Box>

        {formValues[section.title].map((rowValues, rowIndex) => (
          <div key={rowIndex}>
            <Grid container spacing={0.3}>
              <Grid item xs={11.3}>
                <Grid container spacing={1}>
                  {renderFields(section, rowIndex, compositionIndex)} {/* Pass rowIndex and compositionIndex */}
                </Grid>
              </Grid>
              <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    backgroundColor: 'red',
                    ...removeButton,
                  }}
                  onClick={() => handleRemoveRow(section.title, rowIndex)}
                  disabled={formValues[section.title].length === 1}
                >
                  <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                </Button>
              </Grid>
            </Grid>
            {formValues[section.title].length > 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
          </div>
        ))}

        <Box sx={{ ...justifyContentEnd }}>
          <Button onClick={() => handleAddRow(section.title)}>
            Add Another Product
          </Button>
        </Box>
      </Grid>
    );
  } else if (section.title === "Inventor") {
    return (
    <Card
    style={{
        boxShadow: "unset",
        borderRadius,
        border: "1px solid #ddd",
    }}
    >
    <CardContent>
      <Grid item xs={12} key={section.title}>
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {section.title}
          </Typography>
        </Box>

        {formValues[section.title].map((rowValues, rowIndex) => (
          <div key={rowIndex}>
            <Grid container spacing={0.3}>
              <Grid item xs={11.3}>
                <Grid container spacing={1}>
                  {renderFields(section, rowIndex, compositionIndex)} {/* Pass rowIndex and compositionIndex */}
                </Grid>
              </Grid>
              <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    backgroundColor: 'red',
                    ...removeButton,
                  }}
                  onClick={() => handleRemoveRow(section.title, rowIndex)}
                  disabled={formValues[section.title].length === 1}
                >
                  <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                </Button>
              </Grid>
            </Grid>
            {formValues[section.title].length > 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
          </div>
        ))}

        <Box sx={{ ...justifyContentEnd }}>
          <Button onClick={() => handleAddRow(section.title)} style={{fontWeight:'bold'}}>
            + Add More
          </Button>
        </Box>
      </Grid>
    </CardContent>
    </Card>
    );
  } else {
    // Render other sections using renderFields function
    return (
      <Grid item xs={12} key={section.title}>
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {section.title}
          </Typography>
          {/* Subtitle */}
          {section.subtitle && (
            <Typography variant="subtitle2">{section.subtitle}</Typography>
          )}
        </Box>
        <Grid container spacing={1}>

          {renderFields(section, undefined, compositionIndex)} {/* Pass undefined for rowIndex */}
        </Grid>
      </Grid>
    );
  }
};


  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {fields.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default Step4;
