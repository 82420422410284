import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_LEAVE_REQUEST_DETAILS_SUCCESS,
  GET_LEAVE_REQUEST_DETAILS_FAILED,
  GET_LEAVE_REQUEST_SUCCESS,
  GET_LEAVE_REQUEST_FAILED,
  ADD_LEAVE_REQUEST_SUCCESS,
  ADD_LEAVE_REQUEST_FAILED,
  EDIT_LEAVE_REQUEST_SUCCESS,
  EDIT_LEAVE_REQUEST_FAILED,
  DELETE_LEAVE_REQUEST_FAILED,
  DELETE_LEAVE_REQUEST_SUCCESS,
  CANCEL_LEAVE_REQUEST_SUCCESS,
  CANCEL_LEAVE_REQUEST_FAILED,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getLeaveRequest = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  status = null,
  dateFilter = null,
  startDate = dateFilter !== "custom_date" ? null : startDate,
  endDate = dateFilter !== "custom_date" ? null : endDate,
  period = null,
  category = null,
  userId = null
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    const apiUrl = userId ? `web-leave/${userId}/detail-list` : 'web-leave';

    axios.get(
        urlRequest({
          url: apiUrl,
          page,
          limit,
          search,
          period: period == ACTION_TYPE.ALL_DATE ? null : period,
          sortBy,
          sortDirection,
          ...(userId ? {
            leaveCategory: category == ACTION_TYPE.ALL_CATEGORY ? null : category,
            status: status == ACTION_TYPE.ALL_STATUS ? null : status
          } : {})
        }),
        headersAPIToken(token)
        ).then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getLeaveRequestSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getLeaveRequestSuccess([], null));
        } else {
          dispatch(getLeaveRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getLeaveRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(getLeaveRequestFailed(error.message));
        }
      });
  };
};

export const getLeaveRequestDetail = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  status = null,
  dateFilter = null,
  startDate = dateFilter !== "custom_date" ? null : startDate,
  endDate = dateFilter !== "custom_date" ? null : endDate,
  period = null,
  category = null,
  userId = null
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    const apiUrl = userId ? `web-leave/${userId}/detail-list` : 'web-leave';

    axios.get(
        urlRequest({
          url: apiUrl,
          page,
          limit,
          search,
          period: period == ACTION_TYPE.ALL_DATE ? null : period,
          sortBy,
          sortDirection,
          ...(userId ? {
            leaveCategory: category == ACTION_TYPE.ALL_CATEGORY ? null : category,
            status: status == ACTION_TYPE.ALL_STATUS ? null : status
          } : {})
        }),
        headersAPIToken(token)
        ).then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getLeaveRequestDetailSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getLeaveRequestDetailSuccess([], null));
        } else {
          dispatch(getLeaveRequestDetailFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getLeaveRequestDetailFailed(error.response.data.message));
          }
        } else {
          dispatch(getLeaveRequestDetailFailed(error.message));
        }
      });
  };
};

export const cancelLeaveRequest = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`time-off-requests/action/${id}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("CANCELED", "Request leave"));
          dispatch(cancelLeaveRequestSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(cancelLeaveRequestFailed(response.data.message));
        } else {
          dispatch(cancelLeaveRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(cancelLeaveRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(cancelLeaveRequestFailed(error.message));
        }
      });
  };
};


const cancelLeaveRequestSuccess = (message) => {
  return {
    type: CANCEL_LEAVE_REQUEST_SUCCESS,
    payload: message,
  };
};

const cancelLeaveRequestFailed = (message) => {
  return {
    type: CANCEL_LEAVE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

export const getLeaveRequestSuccess = (data, pagination) => {
  return {
    type: GET_LEAVE_REQUEST_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

export const getLeaveRequestDetailSuccess = (data, pagination) => {
  return {
    type: GET_LEAVE_REQUEST_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getLeaveRequestDetailFailed = (errorMessage) => {
  return {
    type: GET_LEAVE_REQUEST_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getLeaveRequestFailed = (errorMessage) => {
  return {
    type: GET_LEAVE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getAttendanceDetails = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  dateFilter = null,
  userId = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: `activities/details`,
          page,
          limit,
          search,
          dateFilter,
          sortBy,
          sortDirection,
          userId,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getAttendanceDetailsSuccess(response.data.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getAttendanceDetailsSuccess(null));
        } else {
          dispatch(getAttendanceDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getAttendanceDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getAttendanceDetailsFailed(error.message));
        }
      });
  };
};

const getAttendanceDetailsFailed = (errorMessage) => {
  return {
    type: GET_LEAVE_REQUEST_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getAttendanceDetailsSuccess = (data, pagination) => {
  return {
    type: GET_LEAVE_REQUEST_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addLeaveRequest = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "activities",
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "attendance"));
          dispatch(addLeaveRequestSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addLeaveRequestFailed(response.data.message));
        } else {
          dispatch(addLeaveRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addLeaveRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(addLeaveRequestFailed(error.message));
        }
      });
  };
};

const addLeaveRequestSuccess = (message) => {
  return {
    type: ADD_LEAVE_REQUEST_SUCCESS,
    payload: message,
  };
};

const addLeaveRequestFailed = (message) => {
  return {
    type: ADD_LEAVE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editLeaveRequest = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(`activities/${id}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "attendance"));
          dispatch(editLeaveRequestSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editLeaveRequestFailed(response.data.message));
        } else {
          dispatch(editLeaveRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editLeaveRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(editLeaveRequestFailed(error.message));
        }
      });
  };
};

const editLeaveRequestSuccess = (message) => {
  return {
    type: EDIT_LEAVE_REQUEST_SUCCESS,
    payload: message,
  };
};

const editLeaveRequestFailed = (message) => {
  return {
    type: EDIT_LEAVE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteLeaveRequest = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`activities${params}`, {
        ...formData,
        ...headersAPIToken(token),
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "attendance"));
          dispatch(deleteLeaveRequestSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteLeaveRequestFailed(response.data.message));
        } else {
          dispatch(deleteLeaveRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteLeaveRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteLeaveRequestFailed(error.message));
        }
      });
  };
};

const deleteLeaveRequestSuccess = (message) => {
  return {
    type: DELETE_LEAVE_REQUEST_SUCCESS,
    payload: message,
  };
};

const deleteLeaveRequestFailed = (message) => {
  return {
    type: DELETE_LEAVE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
