import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILED,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILED,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILED,
  DELETE_BRAND_FAILED,
  DELETE_BRAND_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getBrand = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "brands",
          page,
          limit,
          search,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getBrandSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getBrandSuccess([], null));
        } else {
          dispatch(getBrandFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getBrandFailed(error.response.data.message));
          }
        } else {
          dispatch(getBrandFailed(error.message));
        }
      });
  };
};

const getBrandSuccess = (data, pagination) => {
  return {
    type: GET_BRAND_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getBrandFailed = (errorMessage) => {
  return {
    type: GET_BRAND_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addBrand = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "brands",
//         Object.assign({}, formData, {
//           status: formData.status ? "active" : "inactive",
//         }),
        formData,
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "brands"));
          dispatch(addBrandSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addBrandFailed(response.data.message));
        } else {
          dispatch(addBrandFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addBrandFailed(error.response.data.message));
          }
        } else {
          dispatch(addBrandFailed(error.message));
        }
      });
  };
};

const addBrandSuccess = (message) => {
  return {
    type: ADD_BRAND_SUCCESS,
    payload: message,
  };
};

const addBrandFailed = (message) => {
  return {
    type: ADD_BRAND_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editBrand = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(
        `brands/${id}`,
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "brands"));
          dispatch(editBrandSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editBrandFailed(response.data.message));
        } else {
          dispatch(editBrandFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editBrandFailed(error.response.data.message));
          }
        } else {
          dispatch(editBrandFailed(error.message));
        }
      });
  };
};

const editBrandSuccess = (message) => {
  return {
    type: EDIT_BRAND_SUCCESS,
    payload: message,
  };
};

const editBrandFailed = (message) => {
  return {
    type: EDIT_BRAND_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteBrand = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`brands${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "brands"));
          dispatch(deleteBrandSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteBrandFailed(response.data.message));
        } else {
          dispatch(deleteBrandFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteBrandFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteBrandFailed(error.message));
        }
      });
  };
};

const deleteBrandSuccess = (message) => {
  return {
    type: DELETE_BRAND_SUCCESS,
    payload: message,
  };
};

const deleteBrandFailed = (message) => {
  return {
    type: DELETE_BRAND_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
