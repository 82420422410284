import { useTheme } from "@mui/styles";
import { defaultStylePage, borderRadius, btnWhite, CustomOpenPickerButton, formGroup, StatusBadge, inputDate } from "../../../../util/style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ACTION_TYPE, INPUT_TYPE, formatDate } from "../../../../util/function";
import { Box, Button, Card, CardContent, Grid, MenuItem, Modal, TextField, Toolbar, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataTable from "../../../../components/Table";
import { canceledBookingSubmission, getBookingSubmission } from "./clients/actions";
import ModalConfirmCancel from "../../../../components/ConfirmCancel";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Form from "../../../../components/Form";
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from "dayjs";


const columns = [
    {
        field: "code",
        headerName: "No. Reference",
        disableColumnMenu: true,
        width: 135,
    },
    {
        field: "room_name",
        headerName: "Room Name",
        disableColumnMenu: true,
        width: 256,
    },
    {
        field: "date",
        headerName: "Date",
        disableColumnMenu: true,
        width: 153,
        renderCell: (params) => {
            return (
                <div><span>{formatDate(params.value)}</span></div>
            );
        }
    },
    {
        field: "starting_time",
        headerName: "Starting Time",
        disableColumnMenu: true,
        width: 153,
    },
    {
        field: "ending_time",
        headerName: "Ending Time",
        disableColumnMenu: true,
        width: 153,
    },
    {
        field: "room_capacity",
        headerName: "Request Capacity",
        disableColumnMenu: true,
        width: 200,
    },
    {
        field: "room",
        headerName: "Number of Capacity",
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params) => {
            return params.value.max_capacity;
        }
    },
    {
        field: "guest",
        headerName: "Guest",
        disableColumnMenu: true,
        width: 200,
    },
    {
        field: "required_request",
        headerName: "Note",
        disableColumnMenu: true,
        width: 200,
    },
    {
        field: "comment",
        headerName: "Reject/Cancel Reason",
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params) => {
            return params.value ?? "-";
        }
    },
    {
        field: "user",
        headerName: "Request By",
        disableColumnMenu: true,
        width: 200,
        valueGetter: (params) => {
            return params.value.fullname;
        }
    },
    {
        field: "status",
        headerName: "Status",
        disableColumnMenu: true,
        width: 200,
        renderCell: (params) => (
            <StatusBadge
                bgColor={params.value === 'Pending' ? '#FFF1CF' : params.value === 'Approved' ? '#D2F0CC' : params.value === 'Canceled' ? '#EDEDED' : params.value === 'Finish' ? '#1268B8' : '#FFCDCC'}
                colors={params.value === 'Pending' ? '#FFBA10' : params.value === 'Approved' ? '#1DB200' : params.value === 'Canceled' ? '#757575' : params.value === 'Finish' ? '#FFFFFF' : '#FF0400'}
                status={params.value}>
                {params.value}
            </StatusBadge>
        ),
    },
]

const BookingRoom = () => {
    const theme = useTheme();
    const useStyles = defaultStylePage;
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.auth);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [searchBy, setSearchBy] = useState("no_reference");
    const [statusFilter, setStatusFilter] = useState(ACTION_TYPE.ALL_STATUS);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataRow, setSelectedDataRow] = useState(null);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [title, setTitle] = useState("Detail Booking Submission");
    const [cancelType, setCancelType] = useState("Cancel");
    const [confirmModal, setConfirmModal] = useState(false);
    const [periodElm, setPeriodElm] = useState(null);
    const [period, setPeriod] = useState(null);
    const placeholder = {
        "no_reference": "No. Reference",
        "room_name": "Room Name",
    }
    const [formValues, setFormValues] = useState(Object.assign({}));
    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.bookingSubmission);
    const {
        modal: { isActive },
    } = useSelector((state) => state.global);


    useEffect(() => {
        console.log(token, "token");
        dispatch(
            getBookingSubmission({
                statusFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period,
            })
        );
    }, [sortDirection, sortBy]);

    // useEffect(() => {
    //     console.log("period");
    //     console.log(period);
    //     dispatch(
    //         getBookingSubmission({
    //             statusFilter,
    //             token,
    //             page,
    //             limit,
    //             searchValue,
    //             searchBy,
    //             sortDirection,
    //             sortBy,
    //             period,
    //         })
    //     );
    // }, [period]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && searchValue) {
            _search();
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        setPage(0);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const handleRowDoubleClick = (selection) => {
        // navigate(`detail/${selection.id}`, { state: { type: typeData } });
        console.log(selection.row);
        setSelectedDataRow(selection.row);
        setFormModal(true);
    }

    function _search() {
        dispatch(
            getBookingSubmission({
                statusFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period,
            })
        )
    }

    function _statusFilter(status) {
        setStatusFilter(status);
        dispatch(
            getBookingSubmission({
                statusFilter: status,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period,
            })
        )
    }

    function _setFilterDate(formattedDate, date) {
        setPeriod(formattedDate);
        setPeriodElm(date);
        dispatch(
            getBookingSubmission({
                statusFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period: formattedDate,
            })
        )
    }

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getBookingSubmission({
                statusFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getBookingSubmission({
                statusFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period,
            })
        );
    }
    const handleFormModal = (actionType = ACTION_TYPE.ADD) => { }

    const handleCloseFormModal = (important = false) => {
        if (!important) {
            setSelectedDataRow(null)
            setFormModal(false)
        }
    };

    const handlePeriodChange = (date) => {
        if (dayjs(date).isValid()) {
            const formattedDate = dayjs(date).format("YYYY-MM-DD");
            _setFilterDate(formattedDate, date)
        } else {
            console.error("Invalid start date:", date);
        }
    };



    const detailData = (props) => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography>
                            {props.key}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {props.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {
                            props.status && (
                                <StatusBadge
                                    bgColor={props.status === 'Pending' ? '#FFF1CF' : props.status === 'Approved' ? '#D2F0CC' : props.status === 'Canceled' ? '#EDEDED' : '#FFCDCC'}
                                    colors={props.status === 'Pending' ? '#FFBA10' : props.status === 'Approved' ? '#1DB200' : props.status === 'Canceled' ? '#757575' : '#FF0400'}
                                    status={props.status}>
                                    {props.status}
                                </StatusBadge>
                            )
                        }
                    </Grid>
                </Grid>
                <Box sx={{ height: '16px' }}></Box>
            </>
        );
    }

    const renderFormContent = () => {
        return (
            <>
                {detailData({ key: 'No. Reference', value: selectedDataRow.code, status: selectedDataRow.status })}
                {detailData({ key: 'Room Name', value: selectedDataRow.room_name })}
                {detailData({ key: 'Date of Use', value: formatDate(selectedDataRow.date) })}
                {detailData({ key: 'Starting Time', value: selectedDataRow.starting_time })}
                {detailData({ key: 'Ending Time', value: selectedDataRow.ending_time })}
                {detailData({ key: 'Number of Participant', value: selectedDataRow.room_capacity })}
                {detailData({ key: 'Note', value: selectedDataRow.required_request })}
            </>
        )
    }

    const selectCancel = (e) => {
        setCancelType(e);
        setConfirmModal(true);
    }

    const renderActionButtons = () => {
        if (selectedDataRow.status === 'Pending' || selectedDataRow.status === 'Approved') {
            return (
                <>
                    <Box>
                        {
                            selectedDataRow.status === 'Approved' && (
                                <Button
                                    variant="outlined"
                                    sx={{ ...btnWhite }}
                                    onClick={() => selectCancel("End")} >
                                    End Meeting
                                </Button>
                            )
                        }
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => selectCancel("Cancel")}
                            disableElevation >
                            Cancel booking Submission
                        </Button>
                    </Box>
                </>
            );
        }

        return (
            <>
                <Box>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleCloseFormModal()}
                        disableElevation>
                        Yes
                    </Button>
                </Box>
            </>
        );
    }

    const handleOnCloseConfirmCancelModal = () => {
        setConfirmModal(false);
        setFormModal(false);
        formValues.booking_id = selectedDataRow.id
        dispatch(canceledBookingSubmission(token, formValues, `?type=${cancelType}`))
        setSelectedDataRow(null)
    }

    const handleClearDate = () => {
        setPeriod(null);
        setPeriodElm(null);
        setPickerOpen(false);
        dispatch(
            getBookingSubmission({
                statusFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
                period: null,
            })
        );
    };

    return (
        <>
            <Card style={{ boxShadow: "unset" }}>
                <CardContent>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={6}> */}
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                placeholder={`Search ${placeholder[searchBy]}`}
                                size="small"
                                sx={{
                                    borderRadius,
                                }}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    onKeyDown: handleKeyPress,
                                    startAdornment: <SearchIcon />,
                                    endAdornment: (
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                                IconComponent: ExpandMoreIcon,
                                            }}
                                            value={searchBy}
                                            sx={{
                                                width: 250,
                                                '& fieldset': {
                                                    border: 'unset',
                                                }, borderLeft: `2px solid #ddd`
                                            }}
                                            onChange={(e) => setSearchBy(e.target.value)}
                                        >
                                            <MenuItem value="no_reference">No. Reference</MenuItem>
                                            <MenuItem value="room_name">Room Name</MenuItem>
                                        </TextField>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ height: "100%" }}
                                onClick={_search}
                                onKeyPress={handleKeyPress}
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                <DatePicker
                                    open={pickerOpen}
                                    onOpen={() => setPickerOpen(true)}
                                    onClose={() => setPickerOpen(false)}
                                    selectedDate={periodElm}
                                    onChange={handlePeriodChange}
                                    components={{
                                        OpenPickerButton: CustomOpenPickerButton,
                                        Toolbar: (props) => (
                                            <Toolbar {...props}>
                                                <Button onClick={handleClearDate} startIcon={<ClearIcon />}>
                                                    Clear
                                                </Button>
                                            </Toolbar>
                                        ),
                                    }}
                                    format="DD/MM/YYYY"
                                    value={periodElm}
                                    sx={{
                                        ...inputDate,
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                SelectProps={{ IconComponent: ExpandMoreIcon }}
                                value={statusFilter}
                                onChange={(e) => _statusFilter(e.target.value)}
                                sx={{ borderRadius }} >
                                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Canceled">Canceled</MenuItem>
                                <MenuItem value="Rejected">Rejected</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Room Name"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        handleFormModal={handleFormModal}
                        selectedData={selectedData}
                        handleSelectionChange={handleSelectionChange}
                        handleRowDoubleClick={handleRowDoubleClick}
                        theme={theme}
                        sortModel={sortModel}
                        search={searchValue}
                        onSortModelChange={handleSortModelChange}
                        showAddToolbar={false}
                        showEditToolbar={false}
                        showDeleteToolbar={false}
                    // modeCRUD={MODE_CRUD_WITH.PAGING}
                    // linkCRUD={{ create: "add", update: `edit/${selectedData}`, createOption: "hybrid" }}
                    />
                </CardContent>
            </Card>
            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={() => { }}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                message={'You want to cancel the process booking submission'}
                onConfirmModal={() => { setConfirmModal(false) }}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
        </>
    )
}
export default BookingRoom