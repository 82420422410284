import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  fourColor,
  secondColor,
} from "../../../util/style";

import Form, {
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../../components/Form";

// import {
//     addApplication,
//   deleteApplication,
//   editApplication,
//   getApplication,
// } from "./clients/actions";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  INPUT_TYPE,
  SELECT_OPTION,
  MODE_CRUD_WITH,
} from "../../../util/function";  

import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import axios from "../../../core/axios_config";
import { useNavigate, useParams } from "react-router-dom";

// URL: http://localhost:3000/operation-management/domestic-trademark/trademark-applications

const TrademarkApplication = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                              IMUTABLE STATE                                */
  /* -------------------------------------------------------------------------- */

 const columns = [
    {
      field: "no_transaction",
      headerName: "No. Transaction",
      width: 180,
      disableColumnMenu: true,
      renderCell: (params) => params.value,
    },
    {
      field: "no_application",
      headerName: "No. Aplication",
      width: 180,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <a
            onClick={() => navigate(`detail/${params.row.id}`)}
            style={{ textDecoration: "none", cursor: "grab", color: "blue" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      width: 190,
      disableColumnMenu: true,
    },
    {
      field: "applicant",
      headerName: "Applicant",
      width: 180,
      disableColumnMenu: true,
    },

    {
      field: "trademark",
      headerName: "Trademark/Title",
      width: 180,
      disableColumnMenu: true,
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   width: 180,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "applicant",
    //   headerName: "Applicant",
    //   width: 180,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "trademark",
    //   headerName: "Trademark",
    //   width: 180,
    //   disableColumnMenu: true,
    // },
    {
      field: "submitted",
      headerName: "Submitted",
      width: 180,
      disableColumnMenu: true,
      renderCell: ({value}) => {
        return value ? value : "-"
      }
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "updated_at_formated",
      headerName: "Last Update",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        const statusColors = {
          Draft: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Approved: { bgColor: successSurfaceColor, colors: successMainColor },
          Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
          // Add more statuses as needed
        };

        const statusInfo = statusColors[params.value] || {
          bgColor: infoSurfaceColor,
          colors: infoMainColor,
        };
        return (
          <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
            {params.value}
          </StatusBadge>
        );
      },
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               STATE                                */
  /* -------------------------------------------------------------------------- */

  const searchBy_key = Object.freeze({
    code: "No. Aplication",
    trademark: "Trademark",
    applicant: "Aplication's Name",
  });

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("code");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New Application");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [category, setCategory] = useState(ACTION_TYPE.ALL_CATEGORY);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.ALL_DATE);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateElm, setStartDateElm] = useState(null);
  const [endDateElm, setEndDateElm] = useState(null);
  const [periodElm, setPeriodElm] = useState(null);
  const [period, setPeriod] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.trademarkApplication);

  //* -----------------------------------------------------  */
  //*                          DUMMY                         */
  //* -----------------------------------------------------  */
  const rowsDummy = [
    {
      id: 1,
      no_transaction: "IPT1231435241",
      no_application: "DID233431222131",
      // receipt_date_formatted: "08/08/2021, 02:10",
      // class: "3",
      category: "Merek Dagang",
      applicant: "Sari Mutiara",
      // type: "Umum",
      trademark: "KAIRA",
      submitted: null,
      due_date: "13/04/3323",
      updated_at_formated: "13/06/2021, 02:10",
      status: "Pending",
    },
    {
      id: 2,
      no_transaction: "IPT1231435241",
      no_application: "DID233431222131",
      // receipt_date_formatted: "08/08/2021, 02:10",
      // class: "3",
      category: "Merek Dagang",
      applicant: "Sari Mutiara",
      // type: "Umum",
      trademark: "KAIRA",
      submitted: null,
      due_date: "13/04/3323",
      updated_at_formated: "13/06/2021, 02:10",
      status: "Draft",
    },
    {
      id: 3,
      no_transaction: "IPT1231435241",
      no_application: "DID233431222131",
      // receipt_date_formatted: "08/08/2021, 02:10",
      // class: "3",
      category: "Merek Dagang",
      applicant: "Sari Mutiara",
      // type: "Umum",
      trademark: "KAIRA",
      submitted: null,
      due_date: "13/04/3323",
      updated_at_formated: "13/06/2021, 02:10",
      status: "Approved",
    },
    {
      id: 4,
      no_transaction: "IPT1231435241",
      no_application: "DID233431222131",
      // receipt_date_formatted: "08/08/2021, 02:10",
      // class: "3",
      category: "Merek Dagang",
      applicant: "Sari Mutiara",
      // type: "Umum",
      trademark: "KAIRA",
      submitted: null,
      due_date: "13/04/3323",
      updated_at_formated: "13/06/2021, 02:10",
      status: "Rejected",
    },
    {
      id: 6,
      no_transaction: "IPT1231435241",
      no_application: "DID233431222131",
      // receipt_date_formatted: "08/08/2021, 02:10",
      // class: "3",
      category: "Merek Dagang",
      applicant: "Sari Mutiara",
      // type: "Umum",
      trademark: "KAIRA",
      submitted: null,
      due_date: "13/04/3323",
      updated_at_formated: "13/06/2021, 02:10",
      status: "Draf",
    },
  ];
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  const fetchOptions = async (apiEndpoint, name) => {
    try {
      const response = await axios.get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the response data has the 'fullname' property
      const modifiedData = response.data.data.map((item) => {
        if (item.hasOwnProperty("fullname")) {
          return {
            ...item,
            name: item.fullname,
          };
        } else {
          return item;
        }
      });

      // console.log("fetchOptions", name, modifiedData);
      setOptions((prevOptions) => ({
        ...prevOptions,
        [name]: modifiedData,
      }));
    } catch (error) {
      console.error(`Error fetching ${name} options:`, error);
    }
  };

  useEffect(() => {
    fetchOptions("master/services?main_service_id=100", "category");
  }, []);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch();
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch();
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [category]);

  useEffect(() => {
    if (isActive === true) {
      if (status !== ACTION_TYPE.DEFAULT_STATUS) {
        //TODO: dispatch
        // dispatch( );
      }
    }
  }, [isActive]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Application");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(`application '${data.map((row) => row.code).join(", ")}'`);
    }
  };

  const handleCloseFormModal = (important = false) => {
    setFormModal(false);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    setSelectedData([]); //buat disabled button toolbar
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    //TODO: Dispatch
    // dispatch(deleteApplication(token, { data: { ids } }, true));
  };

  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" disableElevation type="submit">
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch();
    }
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (period) {
      if (dayjs(period).isValid()) {
        //TODO: dispatch
        // dispatch();
      }
    }
  }, [period]);

  useEffect(() => {
    if (startDate && endDate) {
      setShowSelectDate(false);
      //TODO: dispatch
      //   dispatch( );
    }
  }, [startDate, endDate]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    //TODO: dispatch
    // dispatch();
  }

  function _getByLimit(limit) {
    //TODO: dispatch
    // dispatch();
  }

  function _search() {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch( );
    }
  }

  function _status(status) {
    setStatus(status);
  }

  function _category(val) {
    setCategory(val);
  }

  const handlePeriodChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setPeriod(formattedDate);
      setPeriodElm(date);
    } else {
      console.error("Invalid start date:", date);
    }
  };

  function _dateFilter(date) {
    if (date === "custom_date") {
      setShowDatePicker(true);
      setDateFilter(date);
    } else {
      setShowSelectDate(false);

      if (dateFilter == "custom_date" && date !== "custom_date" && date !== undefined) {
        setShowDatePicker(false);

        setStartDate(null);
        setStartDateElm(null);
        setEndDate(null);
        setEndDateElm(null);
      }

      if (date && status !== ACTION_TYPE.DEFAULT_STATUS) {
        setDateFilter(date);
      }
    }
  }

  const handleStartDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setStartDate(formattedDate);
      setStartDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleEndDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setEndDate(formattedDate);
      setEndDateElm(date);
    } else {
      console.error("Invalid end date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleSelectClose = (e) => {
    if (e) e.stopPropagation();
    setShowDatePicker(false);
    setShowSelectDate(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    // console.log(selection);
    setSelectedData(selection || []);
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      {/*      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search attachment file name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
*/}
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}>
        <CardContent>
          <Grid container spacing={1.5}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder={`Search ${searchBy_key[searchBy]}`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchIconBlack}
                        style={{ width: "20px", margin: " 0 8px 0 0" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <TextField
                      select
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      sx={{
                        borderRadius,
                      }}
                      value={searchBy}
                      sx={{
                        "& fieldset": {
                          border: "unset",
                        },
                        borderLeft: `2px solid #ddd`,
                        width: "16rem",
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      {Object.entries(searchBy_key).map(([key, val]) => (
                        <MenuItem value={key}>{val}</MenuItem>
                      ))}
                      {/* <MenuItem value="code">No. Reference</MenuItem>
                      <MenuItem value="company_name">Company</MenuItem>
                      <MenuItem value="applicant">Applicant</MenuItem> */}
                    </TextField>
                  ),
                }}
                sx={{
                  borderRadius: 4, // Adjust the borderRadius as needed
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            {/* ----------------- RECEIFE DATE  ---------------------- */}
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                <DatePicker
                  selectedDate={periodElm}
                  onChange={handlePeriodChange}
                  components={{
                    OpenPickerButton: CustomOpenPickerButton,
                  }}
                  format="MMMM YYYY"
                  views={["month", "year"]}
                  value={periodElm}
                  sx={{
                    ...inputDate,
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* ----------------- STATUS ---------------------- */}
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>Select Status</MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="Submitted">Draft</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Finish">Approved</MenuItem>
                <MenuItem value="Granted">Rejected</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Application"}
            useStyles={useStyles}
            rows={rowsDummy}
            // rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.PAGING}
            search={search}
            linkCRUD={{ create: "add", update: `edit/${selectedData}` }}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default TrademarkApplication;
