import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  fourColor,
  removeButton,
  FacebookCircularProgress,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import DocumentIcon from "./../../../assets/document.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
} from "../../../components/Form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
// import { getApplicationDetails } from "./clients/actions";
import {
  ACTION_TYPE,
  SALES_ORDER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
  cloneObjectWithEmptyStrings,
  formatCurrencyWithoutLable,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackIcon from "./../../../assets/back.svg";
import ZoomInIcon from "./../../../assets/zoom-in.png";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import PrinterGrayIcon from "./../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../assets/download-gray.png";
import ZoomOutIcon from "./../../../assets/zoom-out.png";
import logo from "./../../../logo.png";
import boderImg from "./../../../assets/border.png";
import DetailWizard from "./detail_wizard";
// import DetailTimeline from "./detail-timeline";
import RemoveIcon from "./../../../assets/minus.svg";
import LeftDrawer from "../../../components/LeftDrawer";

import {
  detailPayload1 as detailTradeAplication,
  detailPayload2 as detailTradeApplication2,
} from "./detail_payload";
import DetailWizard2 from "./detail_wizard2";

  /* -------------------------------------------------------------------------- */
  /*                              PRIMITIF STATE                                */
  /* -------------------------------------------------------------------------- */

  export const STEP_NAME = Object.freeze({
    STEP_ONE:"StepOne",
    STEP_TWO:"StepTwo",
    STEP_THREE: "StepThree",
    STEP_FOUR: "StepFour",
    STEP_FIVE:"StepFive",
    toArray: (steps = STEP_NAME) => {
      return Object.entries(steps).filter(([_, val]) => typeof val !== "function");
    }
    
  })


const DetailTrademarkApplication = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  // const useStyles = defaultStylePage;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#e1e1e1",
      overflow: "hidden",
      position: "relative !important",
    },
    logo: {
      position: "absolute",
      width: "100%",
    },
    header: {
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    footer: {
      padding: "20px",
      backgroundColor: "#09345C",
      borderRadius: "0 0 10px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  const [formModal, setFormModal] = useState(false);
  const [userDeleted, setUserDeleted] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [title, setTitle] = useState("Detail Application");
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const { data, message, isLoading, pagination } = useSelector(
    (state) => state.applicationsdetail
  );

  const { token } = useSelector((state) => state.auth);
  const params = useParams();
  const [id, setId] = useState(parseInt(params.id));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documentPreviewForPrintRef = useRef(null);
  const [requestTo, setRequestTo] = React.useState("");
  const [height, setHeight] = useState(0);
  const [isDoneFetchHeightDocumentPreview, setIsDoneFetchHeightDocumentPreview] =
    useState(false);

  const handleChangeRequestTo = (event) => {
    setRequestTo(event.target.value);
  };

  const ANOTHER_ACTION_TYPE = {
    PENDING_TO_SUBMITTED: "PENDING_TO_SUBMITTED",
    SUBMITTED_TO_FINISH: "SUBMITTED_TO_FINISH",
  };

  // const [title, setTitle] = useState("Submit Registration");
  const [options, setOptions] = useState({});
  const [actionType, setActionType] = useState(ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED);

  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const [fields, setFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedIdApplication, setSelectedIdApplication] = useState(null);
  const [detailRowV2, setDetailRowV2] = useState({
    can_download_pdf: false,
    canResubmit: false,
    others: {
      category: "",
      bussiness_field: "",
      date: "",
      date_approved: "",
    },
    application: {
      client_name: "",
      "No. Reference": "",
      "No. Registration": "",
      Date: "",
      Comment: "",
      Status: "",
      "Request to": {
        name: "",
        position: "",
      },
    },
    applicationStep: {
      Applicant: {
        "Applicant Information": {
          Applicant: "",
          Category: "",
          Type: "",
          Email: "",
          "Mobile Number": "",
          "Business Field": "",
          "Submission on Behalf of": "",
        },
      },
      Description: {
        "Business Description": {
          "Business Activities": "",
          "Product Origin": "",
          "Product Marketing": "",
        },
      },
      Address: {
        "Office Address": {
          Province: "",
          Regency: "",
          District: "",
          Village: "",
          Address: "",
          "Postal Code": "",
        },
        "Facility Address": {
          Province: "",
          Regency: "",
          District: "",
          Village: "",
          Address: "",
          "Postal Code": "",
          "Phone Number": "",
          Status: "",
        },
      },
      Product: {
        "Product Information": [
          {
            Product: "",
            "Primary Packaging": "",
            "Net Weight": "",
            Brand: "",
            "Secondary Packaging": "",
            "Monthly Production Capacity": "",
          },
        ],
      },
      Attachment: {
        "KTP Pemohon": [
          {
            id: "",
            filename: "",
            size: "",
            url: "",
          },
        ],
        "KTP Penyelia Halal": [
          {
            id: "",
            filename: "",
            size: "",
            url: "",
          },
        ],
        NPWP: [
          {
            id: "",
            filename: "",
            size: "",
            url: "",
          },
        ],
        "Nomor Induk Berusaha (NIB)": [
          {
            id: "",
            filename: "",
            size: "",
            url: "",
          },
        ],
        "Akte Perusahaan": [
          {
            id: "",
            filename: "",
            size: "",
            url: "",
          },
        ],
        "Sertifikat Pelatihan Eksternal Halal": [
          {
            id: "",
            filename: "",
            size: "",
            url: "",
          },
        ],
      },
    },
    timeline: [
      {
        user: "",
        role: "",
        timestamp: "",
        status: "",
      },
      {
        user: "",
        role: "",
        timestamp: "",
        status: "",
      },
    ],
  });

  /* -------------------------------------------------------------------------- */
  /*                                   HELPERS                                  */
  /* -------------------------------------------------------------------------- */

  /**
   *
   * @param {Date | String | Number} date
   * @param {'date-only'|'date-style-1' | 'date-style-2' | 'time-style'} style
   */
  const dateHelper = (date = new Date(), style) => {
    const dateStyle1 = new Intl.DateTimeFormat("id_ID", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const dateStyle2 = Intl.DateTimeFormat("id_ID", {
      dateStyle: "medium",
    });

    const dateOnly = Intl.DateTimeFormat("id_ID", { dateStyle: "medium" });

    const timeStyle = Intl.DateTimeFormat("en-UK", { timeStyle: "short" });

    switch (style) {
      case "date-style-1":
        return dateStyle1.format(date);
      case "date-style-2":
        return dateStyle2.format(date);
      case "time-style":
        return timeStyle.format(new Date(dateStyle1.format(date)));
      case "date-only":
        return dateOnly.format(date);
      default:
        return date;
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                     FORM                                   */
  /* -------------------------------------------------------------------------- */

  const initialAttachment = {
    attachment: [""],
  };

  const initialErrorAttachment = {
    attachment: [""],
  };

  const initialFormValues = (fields) => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialValues[fieldGroup.title] =
          fieldGroup.title === "Attachment"
            ? initialAttachment
            : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
              );
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = (fields) => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialErrorValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialErrorValues[fieldGroup.title] =
          fieldGroup.title === "Attachment"
            ? initialErrorAttachment
            : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: "" }),
                {}
              );
      }
    });

    return initialErrorValues;
  };

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      // Check if the field has a children array
      if (field.children) {
        field.children.forEach((child) => {
          const { name, apiEndpoint } = child;
          if (apiEndpoint) {
            if (!options.hasOwnProperty(name)) {
              fetchOptions(apiEndpoint, name);
            }
          }
        });
      } else {
        // If there's no children array, use the field directly
        const { name, apiEndpoint } = field;
        if (apiEndpoint) {
          if (!options.hasOwnProperty(name)) {
            fetchOptions(apiEndpoint, name);
          }
        }
      }
    });
  }, [fields, options]);

  useEffect(() => {
    setSelectedIdApplication(parseInt(id));
  }, [id]);

  // Function to set height if the ref is initialized
  const setHeightIfRefExists = () => {
    if (documentPreviewForPrintRef?.current) {
      console.log("Document height: ", documentPreviewForPrintRef.current.clientHeight);
      setHeight(
        documentPreviewForPrintRef.current.clientHeight < 1122.5
          ? 1122.5
          : documentPreviewForPrintRef.current.clientHeight
      );
    }
  };

  // Check if the ref exists and then set the height
  useEffect(() => {
    if (!isLoading && data !== null) {
      setTimeout(() => {
        console.log("timeout");
        setHeightIfRefExists();
        setIsDoneFetchHeightDocumentPreview(true);
      }, 2000);
    }
  }, [dispatch, isLoading, data, documentPreviewForPrintRef]); // Dependency array to watch for changes in the ref

  useEffect(() => {
    if (selectedIdApplication) {
      //TODO: Dispatch
      //   dispatch(
      //     getApplicationDetails({
      //       token,
      //       id: selectedIdApplication,
      //     })
      //   );
    }
  }, [selectedIdApplication, token]);

  useEffect(() => {
    if (data && data["application"]["Status"]) {
      const status = data["application"]["Status"];
      if (status === "Pending") {
        setActionType(ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED);
      } else if (status === "Submitted") {
        setActionType(ANOTHER_ACTION_TYPE.SUBMITTED_TO_FINISH);
      }
      setDetailRowV2(data);
      //       setDetailRowV2(   {
      //         "canResubmit": true,
      //         "others": {
      //             "category": "Halal",
      //             "bussiness_field": "BussnesField 2",
      //             "date": "02 February 2024",
      //             "date_approved": "-"
      //         },
      //         "application": {
      //             "client_name": "",
      //             "No. Reference": "",
      //             "No. Registration": "-",
      //             "Date": "",
      //             "Comment": "",
      //             "Status": "Approved",
      //             "Request to": {
      //                 "name": "",
      //                 "position": ""
      //             }
      //         },
      //         "applicationStep": {
      //             "Applicant": {
      //                 "Applicant Information": {
      //                     "Applicant": "test applicant1",
      //                     "Category": "Halal",
      //                     "Type": "Halal",
      //                     "Email": "muhamadsobari198@gmail.com",
      //                     "Mobile Number": "083456789012",
      //                     "Business Field": "BussnesField 2",
      //                     "Submission on Behalf of": "Company"
      //                 }
      //             },
      //             "Description": {
      //                 "Business Description": {
      //                     "Business Activities": "Bisnis Activity 2",
      //                     "Product Origin": "Import",
      //                     "Product Marketing": "Produk Marketing 2"
      //                 }
      //             },
      //             "Address": {
      //                 "Office Address": {
      //                     "Province": "SUMATERA UTARA",
      //                     "Regency": "KABUPATEN ACEH SELATAN",
      //                     "District": "TEUPAH BARAT",
      //                     "Village": "ANA AO",
      //                     "Address": "Address",
      //                     "Postal Code": "101"
      //                 },
      //                 "Facility Address": {
      //                     "Province": "BENGKULU",
      //                     "Regency": "KABUPATEN ACEH BARAT",
      //                     "District": "TELUK DALAM",
      //                     "Village": "PASIR TINGGI",
      //                     "Address": "Address2",
      //                     "Postal Code": "103",
      //                     "Phone Number": "02812381238123",
      //                     "Status": "Rent"
      //                 }
      //             },
      //             "Product": {
      //                 "Product Information": [
      //                     {
      //                         "Product": "energen",
      //                         "Primary Packaging": "bottle",
      //                         "Net Weight": "50 mg",
      //                         "Brand": "wiw",
      //                         "Secondary Packaging": "box",
      //                         "Monthly Production Capacity": 5000
      //                     }
      //                 ]
      //             },
      //             "Attachment": {
      //                 "KTP Pemohon": [
      //                     {
      //                         "id": 2,
      //                         "filename": "1706839979_Events.png",
      //                         "size": 203956,
      //                         "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839979_Events.png"
      //                     }
      //                 ],
      //                 "KTP Penyelia Halal": [
      //                     {
      //                         "id": 3,
      //                         "filename": "1706839980_JH1.png",
      //                         "size": 161374,
      //                         "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839980_JH1.png"
      //                     }
      //                 ],
      //                 "NPWP": [
      //                     {
      //                         "id": 4,
      //                         "filename": "1706839981_merging.png",
      //                         "size": 130002,
      //                         "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_merging.png"
      //                     }
      //                 ],
      //                 "Nomor Induk Berusaha (NIB)": [
      //                     {
      //                         "id": 5,
      //                         "filename": "1706839981_Screenshot_2022-01-05_11-50-53.png",
      //                         "size": 154928,
      //                         "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_Screenshot_2022-01-05_11-50-53.png"
      //                     }
      //                 ],
      //                 "Akte Perusahaan": [
      //                     {
      //                         "id": 6,
      //                         "filename": "1706839981_Screenshot_2022-10-14_07-47-57.png",
      //                         "size": 369262,
      //                         "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_Screenshot_2022-10-14_07-47-57.png"
      //                     }
      //                 ],
      //                 "Sertifikat Pelatihan Eksternal Halal": [
      //                     {
      //                         "id": 7,
      //                         "filename": "1706839982_Screenshot_1668003144.png",
      //                         "size": 4858,
      //                         "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839982_Screenshot_1668003144.png"
      //                     }
      //                 ]
      //             }
      //         },
      //         "timeline": [
      //             {
      //                 "user": "Manager Divisi",
      //                 "role": "Manager Divisi",
      //                 "timestamp": "4 February 2024, 19:23",
      //                 "status": "Rejected"
      //             },
      //             {
      //                 "user": "Application Requested",
      //                 "role": null,
      //                 "timestamp": "2 Feb 2024, 09:12",
      //                 "status": null
      //             }
      //         ]
      //     })
    }
  }, [data]);

  useEffect(() => {
    setIsLoadedFormValues(false);
    // Define your fields based on the actionType
    const newFields =
      actionType === ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED
        ? [
            {
              title: "Registration",
              subtitle:
                "Enter the registration number obtained from the registration process.",
              isDisplayTitle: true,
              children: [
                {
                  name: "number_registration",
                  type: INPUT_TYPE.TEXT,
                  defaultValue: "",
                  label: "No. Registration",
                  placeholder: "Input no registration",
                  required: true,
                },
              ],
            },
            {
              title: "Attachment",
              subtitle: "Upload the documents obtained from the registration process.",
              isDisplayTitle: true,
              children: [
                {
                  name: "attachment",
                  type: INPUT_TYPE.FILE,
                  label: "Upload File",
                  tip: "Max size: 5 MB. Format file: PDF.",
                  allowedExtensions: [".pdf"],
                  maximumSizeInMB: 5, //MB
                  defaultValue: "",
                  required: true,
                },
              ],
            },
          ]
        : actionType === ANOTHER_ACTION_TYPE.SUBMITTED_TO_FINISH
        ? [
            // {
            //   title: "Basic Information",
            //   subtitle: "Complete all employee basic information, as basic data",
            //   isDisplayTitle: false,
            // },
            {
              title: "Status",
              subtitle:
                "After the Submitted status, please select the status that corresponds to the relevant steps of the Business Permit process.",
              isDisplayTitle: true,
              children: [
                {
                  name: "status_id",
                  type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
                  label: "Status",
                  apiEndpoint: "/master/criterias",
                  required: true,
                },
              ],
            },
            {
              title: "Attachment",
              subtitle:
                "You can upload the documents required for this process (optional).",
              isDisplayTitle: true,
              children: [
                {
                  name: "attachment",
                  type: INPUT_TYPE.FILE,
                  label: "Upload File",
                  tip: "Max size: 5 MB. Format file: PDF.",
                  allowedExtensions: [".pdf"],
                  maximumSizeInMB: 5, //MB
                  defaultValue: "",
                  // required: true,
                },
              ],
            },
          ]
        : [];

    setFields(newFields);

    setFormValues(initialFormValues(newFields));
    setErrors(initialErrors(newFields));
    const aa = actionType;
    const bb = fields;
    // debugger
    // setIsLoadedFormValues(true)
    console.log(fields, "FIELDSSSSS");
  }, [actionType]); // Trigger the effect whenever actionType changes

  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    setIsLoadedFormValues(false);
    setActionType(actionType);
    setFormModal(true);
  };

  useEffect(() => {
    console.warn("-----------------FORM VALUES - SALES ORDER ----------");
    console.log(formValues);
    console.warn("-----------------ERRORS - SALES ORDER ----------");
    console.log(errors);
    console.warn("-----------------OPTIONS - SALES ORDER ----------");
    console.log(options);

    if (
      actionType === ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED ||
      actionType === ANOTHER_ACTION_TYPE.SUBMITTED_TO_FINISH ||
      actionType === ACTION_TYPE.EDITED
    ) {
      setIsLoadedFormValues(true);
    }
  }, [formValues, errors]);

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (
        actionType === ACTION_TYPE.EDITED ||
        actionType === ANOTHER_ACTION_TYPE.ADD ||
        actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER
      ) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;
        const sectionErrors = fieldGroup.title === "Sub Service" ? [] : {};

        fieldGroup.children.forEach((field) => {
          const { name, type } = field;
          if (fieldGroup.title === "Sub Service") {
            formValues[title].forEach((sectionValues, index) => {
              // Check if the field is of type "file" and an array
              if (type === INPUT_TYPE.FILE && Array.isArray(sectionValues[name])) {
                sectionValues[name].forEach((file, subIndex) => {
                  // Handle file input validation
                  const fileError = validateFileInput(file, field);

                  if (!sectionErrors[index]) {
                    sectionErrors[index] = {};
                  }

                  if (!sectionErrors[index][name]) {
                    sectionErrors[index][name] = [];
                  }

                  sectionErrors[index][name][subIndex] = fileError;

                  if (fileError) {
                    valid = false;
                    // debugger;
                  }
                });
              } else if (type !== "file") {
                const fieldValue =
                  sectionValues[name] !== undefined ? sectionValues[name] : "";
                const error = validateFieldHelper(fieldValue, field);

                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

                sectionErrors[index][name] = error;

                if (error) {
                  valid = false;
                }
              }
            });
          } else {
            if (type === INPUT_TYPE.FILE && Array.isArray(formValues[title][name])) {
              formValues[title][name].forEach((file, subIndex) => {
                // Handle file input validation
                const fileError = validateFileInput(file, field);

                if (!sectionErrors[name]) {
                  sectionErrors[name] = [];
                }

                sectionErrors[name][subIndex] = fileError;

                if (fileError) {
                  valid = false;
                }
              });
            } else if (type !== "file") {
              // Handle regular input field validation
              const fieldValue =
                formValues[title] && formValues[title][name] !== undefined
                  ? formValues[title][name]
                  : "";
              const error = validateFieldHelper(fieldValue, field);

              sectionErrors[name] = error;

              if (error) {
                valid = false;
              }
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });

    setErrors(newErrors);

    // debugger;
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      // handleDeleteRow();
      handleCloseFormModal(true);
      // setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          // handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER) {
          // handleAddRow();
          // handleCloseFormModal(true);
          // } else if (actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION) {
          //   handleCloseFormModal(true);
          //   handleFormModal(ANOTHER_ACTION_TYPE.ADD_SALES_ORDER)
        }
        // setSelectedData([]);
      }
    }
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
  };

  const validateFileInput = (fieldValue, field, sectionTitle, index, sectionErrors) => {
    const { required } = field;

    // Check if the field is required and the value is missing
    if (required && (!fieldValue || fieldValue instanceof File === false)) {
      return `${field.label} is required`;
    }

    // If the value is a File object, perform additional validations
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }

    return "";
  };

  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find((field) => field.name === fieldName);

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          sectionTitle === "Sub Service"
          // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(fieldValue, field);
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(fieldValue, field);
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (fieldValue && fieldValue.length && validation && !validation(fieldValue)) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!isNaN(rowIndex)) {
        updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
      } else {
        updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
      }

      return updatedValues;
    });
  };

  const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
        return updatedValues;
      });
    }
  };

  const handleFileSelect = (file, rowIndex, sectionTitle, fieldName, fileIndex) => {
    // debugger;
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];

        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = "";
        }

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];

        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });
    }
  };

  const handleInputChange = (event, index, sectionTitle, field, fileIndex) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, "SOBARI");

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
          }

          return updatedErrors;
        });
        return;
        // }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = "";
          }
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map((item, i) =>
            i === index ? { ...item, [name]: value } : item
          );
        } else {
          updatedValues[sectionTitle] = {
            ...updatedValues[sectionTitle],
            [name]: value,
          };
        }
        return updatedValues;
      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map((item, i) =>
            i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          updatedErrors[sectionTitle] = {
            ...updatedErrors[sectionTitle],
            [name]: fieldErrors,
          };
        }
        return updatedErrors;
      });
    }
  };

  const renderFields = (section, index, field) => {
    // if (isLoadedFormValues) {
    if (true) {
      const currentFormValues = formValues[section.title];
      const currentErrors = errors[section.title];

      // debugger;
      // If section.title is undefined, use the general errors
      if (!section.title) {
        currentErrors = errors;
      }
      return (
        <>
          {section.children?.map((field) => {
            // Check if children array exists
            let optionsData;
            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              if (isRequired) {
                optionsData = [
                  // field.required &&
                  //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  //     name: "Choose Option",
                  //   },
                  ...options[field.name],
                ];
              } else {
                optionsData = [...options[field.name]];
              }
            }

            const fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.FILE:
                fieldComponent = (
                  <div>
                    {currentFormValues[field.name].map((_, fileIndex) => (
                      <div key={fileIndex}>
                        <Grid container spacing={0.3}>
                          <Grid item xs={11.3}>
                            <FilePicker
                              indexFile={fileIndex}
                              field={field}
                              formValues={currentFormValues[field.name][fileIndex]}
                              errors={currentErrors}
                              onFileSelect={(file) =>
                                handleFileSelect(
                                  file,
                                  index,
                                  section.title,
                                  field.name,
                                  fileIndex
                                )
                              }
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  section.title,
                                  field,
                                  fileIndex
                                )
                              }
                              tip={field?.tip}
                            />
                          </Grid>

                          <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                            <Button
                              variant="contained"
                              disableElevation
                              sx={{
                                backgroundColor: (theme) => theme.palette.primary.main,
                                ...removeButton,
                              }}
                              onClick={() =>
                                handleRemoveFile(
                                  section.title,
                                  index,
                                  fileIndex,
                                  field.name
                                )
                              }
                              disabled={currentFormValues[field.name].length === 1}
                            >
                              <>
                                <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                              </>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ))}

                    <Box sx={{ ...justifyContentEnd }}>
                      <Button
                        onClick={() => handleAddFile(section.title, index, field.name)}
                      >
                        Add More
                      </Button>
                    </Box>
                  </div>
                );

                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) => {
                      handleInputBlur(field.name, section.title, index, e);
                    }}
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.DIVIDER:
                fieldComponent = <Divider />;
                break;
              case INPUT_TYPE.TYPOGRAPHY:
                fieldComponent = (
                  <Typography variant={field.variant} sx={field.style}>
                    {field.label}
                  </Typography>
                );
                break;
              case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
                fieldComponent = (
                  <Typography key={field.name} variant={field.variant} sx={field.style}>
                    {field.label}{" "}
                    {field.name === "price_services_info"
                      ? currentFormValues[field.name]
                      : formatCurrencyWithoutLable(currentFormValues[field.name])}
                  </Typography>
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.CHECKBOX:
                fieldComponent = (
                  <CheckboxGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.RADIO:
                fieldComponent = (
                  <RadioGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    errors={currentErrors}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.DATE:
                fieldComponent = (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              // Add cases for other field types
              default:
                fieldComponent = null;
            }

            return (
              <Grid
                item
                xs={field.gridWidth || 12}
                key={field.name}
                sx={{ paddingTop: "unset !important" }}
              >
                {fieldComponent}
              </Grid>
            );
          })}
        </>
      );
    }
  };

  const renderSectionFields = (section) => {
    // if (isLoadedFormValues) {
    if (true) {
      // if (section.title === "Sub Service" || section.title === "Attachment") {
      // Render other sections using renderFields function
      return (
        <Grid item xs={12} key={section.title}>
          {section.isDisplayTitle !== false && (
            <Box sx={{ marginBottom: 2 }}>
              {/* Title */}
              <Typography variant="h6" sx={{ fontWeight: 800 }}>
                {section.title}
              </Typography>
              {/* Subtitle */}
              {section.subtitle && (
                <Typography variant="subtitle2">{section.subtitle}</Typography>
              )}
            </Box>
          )}
          <Grid container spacing={1}>
            {renderFields(section)}
          </Grid>
        </Grid>
      );
    }
  };

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      actionType === ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED ||
      actionType === ANOTHER_ACTION_TYPE.SUBMITTED_TO_FINISH
    ) {
      return (
        <>
          <Grid
            container
            spacing={1.5}
            sx={
              {
                // overflowX: "auto",
                // height: "52vh",
                // paddingBottom: "2rem",
              }
            }
            // className="smooth-scroll"
          >
            {/* {JSON.stringify(isLoadedFormValues)} */}
            {/* <div class="test"> */}
            {/* {JSON.stringify(actionType)} */}
            {/* {JSON.stringify(formValues)} */}
            {/* </div> */}
            {/* <hr /> */}
            {/* {JSON.stringify(fields)} */}
            {/* {isLoadedFormValues ? */}
            {actionType === ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED && (
              <Alert severity="warning" sx={{ marginBottom: 1 }}>
                <AlertTitle>Attention</AlertTitle>
                Before proceeding to the next stage, make sure you have registered with
                the relevant agency. Enter the Registration Number and attach the
                documents you obtained from the registration process at the agency.
              </Alert>
            )}
            {true ? (
              fields.map((section) => renderSectionFields(section))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FacebookCircularProgress />
              </Box>
            )}
          </Grid>
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    } else if (actionType === ACTION_TYPE.DETAIL) {
      return <></>;
    } else if (actionType === ACTION_TYPE.ADD) {
      return (
        <Grid container spacing="5">
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disableElevation
              onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION)}
              id="CHECK_SALES_QUOTATION"
            >
              Get From Sales Quotation
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              disableElevation
              onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.ADD_SALES_ORDER)}
              id="ADD_SALES_ORDER"
            >
              {" "}
              Create New Sales Order
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
  };

  const renderOtherActionButtons = () => {
    return (
      <Box>
        {actionType === ACTION_TYPE.DETAIL ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnLightBlue }}
              disableElevation
              id="download-quotation"
            >
              <img src={PrinterIcon} style={{ marginRight: "5px" }} alt="Printer Icon" />
              Download Quotation
            </Button>
          </>
        ) : actionType === ACTION_TYPE.EDITED ? (
          <>
            {/* <Button
              variant="outlined"
              sx={{ ...btnGreen }}
              disableElevation
              onClick={() => handleCloseFormModal()}
            >
              Mark as Deal
            </Button> */}
          </>
        ) : null}
      </Box>
    );
  };

  const renderActionButtons = () => {
    return (
      <Box>
        {actionType !== ACTION_TYPE.ADD && (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
              id="cancel-modal"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              id="submit-modal"
            >
              {actionType === ACTION_TYPE.DELETED
                ? "Yes"
                : actionType === ANOTHER_ACTION_TYPE.CHECK_SALES_QUOTATION
                ? "Get It"
                : "Save"}
            </Button>
          </>
        )}
      </Box>
    );
  };

  useEffect(() => {
    // setSelectedIdEmployee(parseInt(id));
  }, [id]);

  // OLD Format
  const detailRow = {
    id: 1,
    code: "293/PPM/SQ/XII/2022",
    client_name: "PT.Kompas Tbk",
    service_categories: [
      {
        service: {
          id: 1,
          name: "Main Service 1",
          status: "active",
        },
        sub_services: [
          {
            sub_service_id: 3,
            name: "Sub Service 2",
            status: "active",
          },
          {
            sub_service_id: 2,
            name: "Sub Service 1",
            status: "active",
          },
        ],
      },
    ],
    total_price: "Rp. 29.000.000",
    date_created: "24/02/2023",
    created_by: "Howard Esther",
    status: "Rejected",
    approval_line: "Duke Silver",
    note: "img elements must have an alt prop, either with meaningful text, or an empty string for decorative images...",
    reason_reject: "",
  };

  // NEW Format
  //   const detailRowV2 = {
  //       "canResubmit": true,
  //       "others": {
  //           "category": "Halal",
  //           "bussiness_field": "BussnesField 2"
  //       },
  //       "application": {
  //           "client_name": "Dr. Shayna Corkery DDS",
  //           "No. Reference": "A0009",
  //           "No. Registration": "-",
  //           "Date": "02/02/2024, 09:12",
  //           "Comment": "aaa",
  //           "Status": "Rejected",
  //           "Request to": {
  //               "name": "Manager Divisi",
  //               "position": "Admin Finance"
  //           }
  //       },
  //       "applicationStep": {
  //           "Applicant": {
  //               "Applicant Information": {
  //                   "Applicant": "test applicant1",
  //                   "Category": "Halal",
  //                   "Type": "Halal",
  //                   "Email": "muhamadsobari198@gmail.com",
  //                   "Mobile Number": "083456789012",
  //                   "Business Field": "BussnesField 2",
  //                   "Submission on Behalf of": "Company"
  //               }
  //           },
  //           "Description": {
  //               "Business Description": {
  //                   "Business Activities": "Bisnis Activity 2",
  //                   "Product Origin": "Import",
  //                   "Product Marketing": "Produk Marketing 2"
  //               }
  //           },
  //           "Address": {
  //               "Office Address": {
  //                   "Province": "SUMATERA UTARA",
  //                   "Regency": "KABUPATEN ACEH SELATAN",
  //                   "District": "TEUPAH BARAT",
  //                   "Village": "ANA AO",
  //                   "Address": "Address",
  //                   "Postal Code": "101"
  //               },
  //               "Facility Address": {
  //                   "Province": "BENGKULU",
  //                   "Regency": "KABUPATEN ACEH BARAT",
  //                   "District": "TELUK DALAM",
  //                   "Village": "PASIR TINGGI",
  //                   "Address": "Address2",
  //                   "Postal Code": "103",
  //                   "Phone Number": "02812381238123",
  //                   "Status": "Rent"
  //               }
  //           },
  //           "Product": {
  //               "Product Information": [
  //                   {
  //                       "Product": "energen",
  //                       "Primary Packaging": "bottle",
  //                       "Net Weight": "50 mg",
  //                       "Brand": "wiw",
  //                       "Secondary Packaging": "box",
  //                       "Monthly Production Capacity": 5000
  //                   }
  //               ]
  //           },
  //           "Attachment": {
  //               "KTP Pemohon": [
  //                   {
  //                       "id": 2,
  //                       "filename": "1706839979_Events.png",
  //                       "size": 203956,
  //                       "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839979_Events.png"
  //                   }
  //               ],
  //               "KTP Penyelia Halal": [
  //                   {
  //                       "id": 3,
  //                       "filename": "1706839980_JH1.png",
  //                       "size": 161374,
  //                       "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839980_JH1.png"
  //                   }
  //               ],
  //               "NPWP": [
  //                   {
  //                       "id": 4,
  //                       "filename": "1706839981_merging.png",
  //                       "size": 130002,
  //                       "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_merging.png"
  //                   }
  //               ],
  //               "Nomor Induk Berusaha (NIB)": [
  //                   {
  //                       "id": 5,
  //                       "filename": "1706839981_Screenshot_2022-01-05_11-50-53.png",
  //                       "size": 154928,
  //                       "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_Screenshot_2022-01-05_11-50-53.png"
  //                   }
  //               ],
  //               "Akte Perusahaan": [
  //                   {
  //                       "id": 6,
  //                       "filename": "1706839981_Screenshot_2022-10-14_07-47-57.png",
  //                       "size": 369262,
  //                       "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839981_Screenshot_2022-10-14_07-47-57.png"
  //                   }
  //               ],
  //               "Sertifikat Pelatihan Eksternal Halal": [
  //                   {
  //                       "id": 7,
  //                       "filename": "1706839982_Screenshot_1668003144.png",
  //                       "size": 4858,
  //                       "url": "https://storage.googleapis.com/ppm_storage/application_attachments/1706839982_Screenshot_1668003144.png"
  //                   }
  //               ]
  //           }
  //       },
  //       "timeline": [
  //           {
  //               "user": "Manager Divisi",
  //               "role": "Manager Divisi",
  //               "timestamp": "4 February 2024, 19:23",
  //               "status": "Rejected"
  //           },
  //           {
  //               "user": "Application Requested",
  //               "role": null,
  //               "timestamp": "2 Feb 2024, 09:12",
  //               "status": null
  //           }
  //       ],

  //   // OLD
  //     "Sales Order Information": {
  //         "No. Sales Order": "293/PPM/SP/XII/2022",
  //         "Client Name": "PT. Kompas Tbk",
  //         "Date Created": "24 September 2023",
  //         "Created By": "Howard Esther",
  //         "Request to": {
  //             "name": "Dianne Russel",
  //             "position": "Sales Manager"
  //         },
  //         "Date Approved": "24/09/2023, 0910",
  //         "Comment": "-",
  //         'Status': 'Pending'
  //     },
  //     "Requested Service": [
  //         {
  //             "title": "Izin Usaha",
  //             "subtitle": "Penamaan Bisnis",
  //             "children": {
  //                 "Criteria": "Criteria 1",
  //                 "Subtotal": "Rp. 5000,000",
  //                 "Discount (50%)": "Rp. 6000,000",
  //                 "VAT (11%)": "Rp. 7000,000",
  //                 "Total": "Rp. 8000,000"
  //             }
  //         },
  //         {
  //           "title": "Izin Usaha",
  //           "subtitle": "Bangunan Bisnis",
  //           "children": {
  //               "Criteria": "Criteria 11",
  //               "Subtotal": "Rp. 1000,000",
  //               "Discount (50%)": "Rp. 2000,000",
  //               "VAT (11%)": "Rp. 73000,000",
  //               "Total": "Rp. 123000,000"
  //           }
  //       }
  //     ],
  //     "Price Information": {
  //         "Price (2 service)": "Rp.3241",
  //         "Discount": "Rp. 6000000",
  //         "VAT": "Rp.4534534566"
  //     },
  //     "Total Price": "Rp.123123123123123",
  //     "Note": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  ut labore et dolore magna aliqua.Ut enim ad minim veniam "
  // }

  /* -------------------------------------------------------------------------- */
  /*                                    DETAIL                                  */
  /* -------------------------------------------------------------------------- */
  const detailRowV3 = {
    registration: {
      "Elite Innovations": {
        "No. Reference": "A005",
        "No. Registration": "B005",
        Date: "07/08/2023, 09:10",
        Comment: "-",
        Status: "Submitted",
      },
      "Registration Attachment": {
        Attachment: {
          filename: "certificatedoc1.pdf",
          size: "3.2 MB",
          url: "https://download_certificate1.pdf",
        },
      },
    },
    application: {
      Applicant: {
        "Applicant Information": {
          Applicant: "Lincoln Carder",
          Category: "BPOM",
          Type: "CPKB",
          Email: "lincolncarder@gmail.com",
          "Mobile Number": "081234567890",
          "Business Field": "Kosmetik & Kecantikan",
          "Submission on Behalf of": "Company",
        },
      },
      Description: {
        "Business Description": {
          "Business Activities": "Produksi",
          "Product Origin": "Local",
          "Product Marketing": "Nasional",
        },
      },
      Address: {
        "Office Address": {
          Province: "DKI Jakarta",
          "Sub-district": "Tanah Abang",
          "City/District": "Kota Jakarta Pusat",
          Subdivision: "Petamburan",
          Address:
            "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
          "Postal Code": "10260",
        },
        "Facility Address": {
          Province: "DKI Jakarta",
          "Sub-district": "Tanah Abang",
          "City/District": "Kota Jakarta Pusat",
          Subdivision: "Petamburan",
          Address:
            "Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
          "Postal Code": "10260",
          "Phone Number": "02123456789",
          Status: "Owned",
        },
      },
      Product: {
        "Product Information": {
          Product: "Minuman Bersoda",
          "Primary Packaging": "Botol Plastik",
          "Net Weight": "450 ml",
          Brand: "RefreshFizz",
          "Secondary Packaging": "Kardus",
          "Monthly Production Capacity": 50000,
        },
      },
      Attachment: {
        "KTP Pimpinan": [
          {
            filename: "certificatedoc1.pdf",
            size: "3.2 MB",
            url: "https://download_certificate1.pdf",
          },
          {
            filename: "certificatedoc2.pdf",
            size: "4.2 MB",
            url: "https://download_certificate2.pdf",
          },
        ],
        NPWP: [
          {
            filename: "certificatedoc1.pdf",
            size: "3.2 MB",
            url: "https://download_certificate1.pdf",
          },
          {
            filename: "certificatedoc2.pdf",
            size: "4.2 MB",
            url: "https://download_certificate2.pdf",
          },
        ],
        "Nomor Induk Berusaha (NIB)": [
          {
            filename: "certificatedoc1.pdf",
            size: "3.2 MB",
            url: "https://download_certificate1.pdf",
          },
          {
            filename: "certificatedoc2.pdf",
            size: "4.2 MB",
            url: "https://download_certificate2.pdf",
          },
        ],
        "Akte Perusahaan": [
          {
            filename: "certificatedoc1.pdf",
            size: "3.2 MB",
            url: "https://download_certificate1.pdf",
          },
          {
            filename: "certificatedoc2.pdf",
            size: "4.2 MB",
            url: "https://download_certificate2.pdf",
          },
        ],
        // etc.....
      },
    },
    timeline: [
      {
        user: "Ahmad Torff",
        role: "Staff",
        timestamp: "7 July 2023, 09:05",
        status: "Finish",
      },
      {
        user: "Ahmad Torff",
        role: "Staff",
        timestamp: "7 July 2023, 09:05",
        status: "Submitted",
      },
      {
        activity: "Registration",
        timestamp: "7 July 2023, 09:00",
        status: "Pending",
      },
    ],
  };

  // const detailTradeAplication = {
  //   status: "Approved",
  //   no_transaction: "IPT12314345343",
  //   no_application: "DID123123123123",
  //   receive_date: "26/06/2024, 14:34",
  //   created_at_formated: "29 Feb 2023, 14:30",
  //   created_at: "29/02/2023, 14:30",
  //   Applicant: {
  //     client_name: "Mutiara",
  //     applicant_name: "Sari Mutiara",
  //     category: "Merek Dagang",
  //     type: "Umum",
  //     bussines_field: "Kosmetik & Kecantikan",
  //     phone: "0876123123",
  //     email: "lanykonsultaky@pacificpatent.com",
  //     company: "PT. Antum beauty",
  //     owner_type: "Personal",
  //   },
  //   Description: {
  //     mark_type: "Merek Kata dan Lukisan",
  //     image: { name: "mark.jpg", size: 2009988, url: "https://exampleimage.com" },
  //     trademark: "ANTUM BEAUTY",
  //     description: "-",
  //     colors: "Kuning keemasan, hitam, abu-abu",
  //     translation: "-",
  //     transliteration: "-",
  //     disclaimers: "-",
  //   },
  //   Address: {
  //     address:
  //       "Dukuh Bantaran RT 014 RW 004, Kel Ketangan, Kec Gringsing, Kabupaten Batang, Jawa Tengah",
  //     city: "Kabupaten Batang",
  //     province: "Jawa Tengah",
  //     zip_code: "4342",
  //     country: "Indonesia",
  //   },
  //   Representative: {
  //     no_consultan: "0743-3974",
  //     name: "Lanny Setiawan MBA.,",
  //     office_name: "Pacific Patent Multiglobal",
  //     address:
  //       "Pacific Patent Multiglobal DIPO Business Center Lt. 11 Jalan Jend Gatot Subroto Kav 51-52, Jakarta Pusat-10260, Indonesia",
  //     phone: "087546343534",
  //     email: "lannykonsultanhki@pacificpatent.com",
  //   },
  //   Attachment: {
  //     support_document: [
  //       {
  //         name: "Support.pdf",
  //         size: 22323434,
  //         url: "https://exampleimage.com",
  //       },
  //       {
  //         name: "Support-2.pdf",
  //         size: 22323434,
  //         url: "https://exampleimage.com",
  //       },
  //     ],
  //     surat_kuasa_konsultan: {
  //       name: "suratkuasa.pdf",
  //       size: 22323434,
  //       url: "https://exampleimage.com",
  //     },
  //     tanda_tangan_kuasa: {
  //       name: "tandatangankuasa.pdf",
  //       size: 22323434,
  //       url: "https://exampleimage.com",
  //     },
  //     tanda_tangan_pemohon: {
  //       name: "tandatanganpemohon.pdf",
  //       size: 22323434,
  //       url: "https://exampleimage.com",
  //     },
  //   },
  //   Other: {
  //     priorityData: [{ date: "-", country: "-", number_of_priority: "-" }],
  //     class: [
  //       {
  //         class: 1,
  //         description:
  //           "krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi",
  //       },
  //     ],
  //     additional_applicant: { applicant_name: ["Edward, louise", "Ahmad thourf"] },
  //     additional_mark: {
  //       upload_file: [
  //         {
  //           name: "Mark1.pdf",
  //           size: 1232434,
  //           url: "https://example.com",
  //         },
  //         {
  //           name: "Mark2.pdf",
  //           size: 1232434,
  //           url: "https://example.com",
  //         },
  //       ]
  //     },
  //   },
  //   approvalLine: [
  //     {
  //       user: {
  //         fullname: "Marcus",
  //         position: "Oprational Manager",
  //       },
  //       status: "Approved",
  //       date_formated: "20 Jul 2024, 09:00",
  //     },
  //   ],
  // };

  //* ================================================================== */
  //*                           DETAIL DATA 2                            */
  //* ================================================================== */
  const DetailV2 = () => {
    const [activeStep, setActiveStep] = useState(0);
    const stepLen = 5; //* Total length of step

    const statusColors = {
      Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
      Approved: { bgColor: successSurfaceColor, colors: successMainColor },
      Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
      // Add more statuses as needed
    };

    const statusInfo = statusColors[detailRow.status] || {
      bgColor: infoSurfaceColor,
      colors: infoMainColor,
    };

    const handleStepChange = (/** @type {'next'|'prev'} */ type) => {
      setActiveStep((prevActiveStep) =>
        type == "next"
          ? prevActiveStep + 1
          : type === "prev"
          ? prevActiveStep - 1
          : prevActiveStep
      );
    };

    const ApprovalTimeline = () => {
      const Dot = () => (
        <p
          style={{
            display: "inline-block",
            padding: "0",
            margin: "0",
            // paddinBottom:"2%",
            width: ".3rem",
            height: ".3rem",
            background: "#000",
            borderRadius: "99999px",
          }}
        ></p>
      );
      return (
        <>
          <Grid container sx={{ marginBlock: "1rem" }} spacing={2}>
            {detailTradeAplication["approvalLine"].map((approval) => {
              return (
                <>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: ".3rem",
                        marginBottom: ".4rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 550 }}>
                        {approval?.user?.fullname}
                      </Typography>
                      <Dot />
                      <span style={{ fontWeight: 400 }}>{approval?.user?.position}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: ".2rem" }}>
                      <Typography>{approval?.date_formated}</Typography>
                      <Dot />
                      <span
                        style={{
                          color: statusColors[approval?.status].colors,
                          fontWeight: 600,
                        }}
                      >
                        {approval?.status}
                      </span>
                    </div>
                  </Grid>
                </>
              );
            })}
            {/* Approval line when application created */}
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Typography variant="body1" sx={{ fontWeight: 550, marginBottom: ".4rem" }}>
                Application Requested
              </Typography>
              <Typography>{detailTradeAplication["created_at_formated"]}</Typography>
            </Grid>
          </Grid>
        </>
      );
    };

    const ControllButton = () => (
      <div
        style={{
          marginBlock: "1.2rem",
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-end",
          paddingRight: "1rem",
        }}
      >
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          disabled={activeStep === 0}
          onClick={() => handleStepChange("prev")}
          disableElevation
        >
          Previous
        </Button>

        {activeStep === stepLen ? (
          // Remove the 'Finish' button on the last step
          <></>
        ) : (
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleStepChange("next")}
          >
            Next
          </Button>
        )}
      </div>
    );

    return (
      <>
        {/* /* --------------------------------  Detail Application ------------------------------- */}
        <Grid
          container
          spacing={2}
          sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, p: 3 }}
        >
          <Grid item xs={12}>
            <Box sx={{ marginBottom: 2, ...justifyContentBetween, ...alignItemsCenter }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  No. Transactin
                </Grid>
                <Grid item xs={4}>
                  IPT23123123
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                  <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
                    {detailRowV2["application"]["Status"]}
                    Pending
                  </StatusBadge>
                </Grid>
                <Grid item xs={4}>
                  No. Aplication
                </Grid>
                <Grid item xs={4}>
                  DID23123123
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  Receipt Date
                </Grid>
                <Grid item xs={4}>
                  20/04/2024, 14:33
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <div style={{ height: "10px", backgroundColor: thirdColor, width: "100%" }}></div>

        <DetailWizard2
          activeStep={activeStep}
          wizardData={detailTradeApplication2?.detail}
        />

        <div style={{ height: "10px", backgroundColor: thirdColor, width: "100%" }}></div>
        <ApprovalTimeline />

        <div
          style={{ height: "2.5px", backgroundColor: thirdColor, width: "100%" }}
        ></div>
        <ControllButton />
      </>
    );
  };

  //* ---------------------------------------------------------- */
  //*                           DETAIL USED  data 1                    */
  //* ---------------------------------------------------------- */

  // NEW
  const Detail = () => {
    const [activeStep, setActiveStep] = useState(0);

    const statusColors = {
      Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
      Approved: { bgColor: successSurfaceColor, colors: successMainColor },
      Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
      // Add more statuses as needed
    };
    const stepLen = 5; //* total length of step

    const statusInfo = statusColors[detailRowV2["application"]["Status"]] || {
      bgColor: infoSurfaceColor,
      colors: infoMainColor,
    };

    const handleStepChange = (/** @type {'next'|'prev'} */ type) => {
      setActiveStep((prevActiveStep) =>
        type == "next"
          ? prevActiveStep + 1
          : type === "prev"
          ? prevActiveStep - 1
          : prevActiveStep
      );
    };

    const ApprovalTimeline = () => {
      const Dot = () => (
        <p
          style={{
            display: "inline-block",
            padding: "0",
            margin: "0",
            // paddinBottom:"2%",
            width: ".3rem",
            height: ".3rem",
            background: "#000",
            borderRadius: "99999px",
          }}
        ></p>
      );
      return (
        <>
          <Grid container sx={{ marginBlock: "1rem" }} spacing={2}>
            {detailTradeAplication["approvalLine"].map((approval) => {
              return (
                <>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: ".3rem",
                        marginBottom: ".4rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 550 }}>
                        {approval?.user?.fullname}
                      </Typography>
                      <Dot />
                      <span style={{ fontWeight: 400 }}>{approval?.user?.position}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: ".2rem" }}>
                      <Typography>{approval?.date_formated}</Typography>
                      <Dot />
                      <span
                        style={{
                          color: statusColors[approval?.status].colors,
                          fontWeight: 600,
                        }}
                      >
                        {approval?.status}
                      </span>
                    </div>
                  </Grid>
                </>
              );
            })}
            {/* Approval line when application created */}
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Typography variant="body1" sx={{ fontWeight: 550, marginBottom: ".4rem" }}>
                Application Requested
              </Typography>
              <Typography>{detailTradeAplication["created_at_formated"]}</Typography>
            </Grid>
          </Grid>
        </>
      );
    };

    const ControllButton = () => (
      <div
        style={{
          marginBlock: "1.2rem",
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-end",
          paddingRight: "1rem",
        }}
      >
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          disabled={activeStep === 0}
          onClick={() => handleStepChange("prev")}
          disableElevation
        >
          Previous
        </Button>

        {activeStep === stepLen ? (
          // Remove the 'Finish' button on the last step
          <></>
        ) : (
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleStepChange("next")}
          >
            Next
          </Button>
        )}
      </div>
    );

    return (
      <>
        {/* -------------------------------- Detail Application ------------------------------- */}
        <Grid
          container
          spacing={2}
          sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, p: 3 }}
        >
          <Grid item xs={12}>
            <Box sx={{ marginBottom: 2, ...justifyContentBetween, ...alignItemsCenter }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  No. Transactin
                </Grid>
                <Grid item xs={4}>
                  IPT23123123
                </Grid>
                <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                  <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
                    {detailRowV2["application"]["Status"]}
                    Pending
                  </StatusBadge>
                </Grid>
                <Grid item xs={4}>
                  No. Aplication
                </Grid>
                <Grid item xs={4}>
                  DID23123123
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  Receipt Date
                </Grid>
                <Grid item xs={4}>
                  20/04/2024, 14:33
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <div style={{ height: "10px", backgroundColor: thirdColor, width: "100%" }}></div>

        <DetailWizard wizardData={detailTradeAplication} activeStep={activeStep} />

        <div style={{ height: "10px", backgroundColor: thirdColor, width: "100%" }}></div>

        {/* <DetailTimeline timelineData={detailRowV2["timeline"]} /> */}
        <ApprovalTimeline />
        <div
          style={{ height: "2.5px", backgroundColor: thirdColor, width: "100%" }}
        ></div>
        {/* Control Next and Prev button */}
        <ControllButton />
      </>
    );
  };

  const Signature = ({ companyName, isMarked, signatureText }) => (
    <Box sx={{ textAlign: "center", width: "100%", borderRadius: "8px", p: 1 }}>
      <Typography variant="subtitle1">{companyName}</Typography>
      <Box sx={{ height: "60px" }}>
        {isMarked && <img src={successIMG} width="55px" />}
      </Box>
      <Typography sx={{ fontWeight: "500" }}>
        {signatureText
          ? signatureText
          : "(................................................)"}
      </Typography>
    </Box>
  );

  //* ----------------------------------------------------------- */
  //*                       USED PREVIEW                          */
  //* ----------------------------------------------------------- */
  const PreviewPDFV2 = () => {
    const [fontSize, setFontSize] = useState(12); // Initial font size
    const documentPreviewRef = useRef(null);
    const [percentage, setPercentage] = useState(100); // Initial percentage text

    useEffect(() => {
      // Apply the zoom effect to specific elements when fontSize changes
      if (documentPreviewRef.current) {
        const applyZoomToChildren = (element) => {
          if (element.childNodes.length > 0) {
            for (const childNode of element.childNodes) {
              applyZoomToChildren(childNode);
            }
          }
          if (element.style) {
            const tagName = element.tagName.toLowerCase();
            const className = element.className.toLowerCase();
            // if (tagName === 'h1' || tagName === 'h2' || tagName === 'h3' || tagName === 'h4' || tagName === 'h5' || tagName === 'h6') {
            //   // Apply +1px zoom to h1, h2, h3, h4, h5, h6
            //   element.style.fontSize = `${fontSize + 1}px`;
            // } else {
            //   element.style.fontSize = `${fontSize}px`;
            // }

            const hasNotEffectClass = className && className.includes("not-effect");

            if (!hasNotEffectClass) {
              // Apply +1px zoom to h1, h2, h3, h4, h5, h6 if it doesn't have the 'not-effect' class
              element.style.fontSize = `${fontSize + 1}px`;
            } else {
              // Apply normal zoom if it has the 'not-effect' class
              element.style.fontSize = `${element.style.fontSize}px`;
            }
          }
        };
        applyZoomToChildren(documentPreviewRef.current);
      }
    }, [fontSize]);

    const handleZoomIn = () => {
      setFontSize((prevSize) => prevSize + 1);
      setPercentage((prevPercentage) => prevPercentage + 10);
    };

    const handleZoomOut = () => {
      setFontSize((prevSize) => Math.max(prevSize - 1, 1));
      setPercentage((prevPercentage) => Math.max(prevPercentage - 10, 10));
    };

    const printContent = () => {
      // Implement the print logic
      window.print();
    };

    const renderGridItemsFromObject = (object) => {
      const excludedProperties = ["Status"];
      const rightAlignedKeys = [""];

      const isRightAlignedKey = (key) => {
        return rightAlignedKeys.includes(key) || key.toLowerCase().includes("");
      };

      return Object.entries(object).map(([key, value]) =>
        // Skip rendering "Request to"
        excludedProperties.includes(key) ? null : (
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Typography variant="body1">{key}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: isRightAlignedKey(key) ? "right" : "left" }}
              >
                {value}
              </Typography>
            </Grid>
          </React.Fragment>
        )
      );
    };

    const renderRequestedServices = (services) => {
      return services.map((service, serviceIndex) => (
        <React.Fragment key={serviceIndex}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {service.title} - {service.subtitle}
              </Typography>
            </Box>
          </Grid>
          {renderGridItemsFromObject(service.children)}
        </React.Fragment>
      ));
    };

    const renderPriceInformation = (priceInfo) => {
      return Object.entries(priceInfo).map(([key, value]) => (
        <React.Fragment key={key}>
          <Grid item xs={8}>
            <Typography variant="body2" sx={{ textAlign: "right", fontWeight: "bold" }}>
              {key}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 800, textAlign: "right" }}>
              {value}
            </Typography>
          </Grid>
        </React.Fragment>
      ));
    };

    const classes = useStyles();

    const paragraphStyle = {
      padding: ".2rem, 0",
      margin: ".4rem 0",
    };
    const titleContentStyle = {
      color: neutral70,
      margin: ".5rem 0",
    };

    return (
      <Box
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: thirdColor,
          position: "relative",
          p: 3,
          overflow: "auto",
        }}
        ref={documentPreviewRef}
      >
        <Grid item xs={12} sx={{ position: "absolute", width: "100%", p: 2 }}>
          <Box
            sx={{
              marginBottom: 2,
              ...justifyContentBetween,
              ...alignItemsCenter,
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 800, color: neutral70 }}
              className="not-effect"
            >
              Document Preview
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
              <Box sx={{ ...justifyContentEnd, gap: ".5rem" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={() => handleZoomIn()}
                >
                  <img src={ZoomInIcon} />
                </IconButton>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={() => handleZoomOut()}
                >
                  <img src={ZoomOutIcon} />
                </IconButton>
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "darkgray", // Change the color when hovered if desired
                    },
                    borderRadius,
                  }}
                  className="not-effect"
                >
                  {percentage}%
                </Button>
              </Box>
              <Box sx={{ display: "flex", gap: ".5rem" }}>
                <Button
                  variant="outlined"
                  sx={{
                    ...(detailRowV2["can_download_pdf"] === true
                      ? btnLightBlue
                      : btnGray),
                    borderRadius,
                  }}
                  disableElevation
                  //   disabled={detailRowV2["application"]?.Status !== "Approved"}
                  disabled={detailTradeApplication2["can_print"] === false}
                  className="not-effect"
                  onClick={() => printContent()}
                >
                  <img
                    src={PrinterGrayIcon}
                    style={{ marginRight: "5px" }}
                    alt="Printer Icon"
                  />
                  Print
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    //   disabled={detailRowV2["application"]?.Status === "Approved"}
                    ...(detailRowV2["can_download_pdf"] === true
                      ? btnLightBlue
                      : btnGray),
                    borderRadius,
                  }}
                  disableElevation
                  //   disabled={detailRowV2["application"]?.Status !== "Approved"}
                  disabled={detailTradeApplication2["can_download"] === false}
                  className="not-effect"
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_API_URL +
                        `/api/applications/${id}/download-pdf`,
                      "_blank"
                    );
                  }}
                >
                  <img
                    src={DownloadGrayIcon}
                    style={{ marginRight: "5px" }}
                    alt="Download Icon"
                  />
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
          <Paper className={classes.root} ref={documentPreviewForPrintRef}>
            <img src={boderImg} className={classes.logo} />

            {/* Header */}
            <div className={classes.header}>
              <Typography
                variant="body"
                sx={{ fontWeight: "800" }}
                className="not-effect"
              >
                APPLICATION FORM OF INDONESIAN TRADEMARK REGISTRATION
              </Typography>
              <img src={logo} alt="logo" />
            </div>

            <Grid
              container
              display="flex"
              sx={{ ...justifyContentBetween, padding: "20px" }}
            >
              <Grid item xs="6" container>
                <Grid item xs={6}>
                  <Typography variant="body1">No. Transaction</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {/* {detailRowV2["application"]["No. Reference"]} */}: IPT131231244223
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">No. Application</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {/* {detailRowV2["application"]["client_name"]} */}: DID23425452897
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Receipt Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {/* {detailRowV2["others"]["date"]} */}: 26/04/2029, 14:06
                  </Typography>
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Typography variant="body1" sx={{ textAlign: "right" }}>
                  PT Pacific Patent Multiglobal{" "}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: "right", color: neutral70 }}
                >
                  DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav 51-52
                  <br /> Jakarta Pusat 10260
                </Typography>
              </Box>
            </Grid>

            {/* ======================= Content ========================*/}
            <Grid
              container
              display="flex"
              sx={{ ...justifyContentBetween, padding: "20px" }}
            >
              <Grid item xs={7} container>
                {/* Content */}
                <div>
                  <Grid container>
                    {/*  */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={titleContentStyle}>
                        Applicant
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <p style={paragraphStyle}>Applicant name</p>
                      <p style={paragraphStyle}>Perorangan (type)</p>
                      <p style={paragraphStyle}>sarii@gmail.com (email)</p>
                      <p style={paragraphStyle}>09377743344 (phone)</p>
                    </Grid>
                    {/*  */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={titleContentStyle}>
                        Description
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Type of mark</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: Merek Kata dan Lukisan</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Image of Mark</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: Mark</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Trademark</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: ANTUM BEAUTY'S</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Description</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: -</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Colors</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: Kuning keemasan, hitam abu abu</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Translation</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: -</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Transliteration</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: -</p>
                    </Grid>
                    <Grid item xs={5}>
                      <p style={paragraphStyle}>Disclaimers</p>
                    </Grid>
                    <Grid item xs={7}>
                      <p style={paragraphStyle}>: -</p>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={titleContentStyle}>
                        Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        Dukuh Bantaran RT 014 RW 004, Kel Ketangan, Kec Gringsing,
                        Kabupaten Batang, Jawa Tengah
                      </p>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={titleContentStyle}>
                        Representative/IP Consultant
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight={580}>
                        Lany Setiawan MB., M.Mgt., MA-LCP., MA-LMFT
                      </Typography>
                      <p>
                        (address) Pacific Patent Multiglobal DIPO Business Center Lt. 11,
                        Jalan Jend. G Kav. 51-52, Jakarta Pusat- 10260 Indonesia
                      </p>
                      <p>(email) lannykonsultanhki@pacificpatent.com</p>
                      <p>(phone) 0865343434</p>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={titleContentStyle}>
                        Priority Data
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      -
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={titleContentStyle}>
                        Class
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      Class
                    </Grid>
                    <Grid item xs={7}>
                      : 3,14
                    </Grid>
                    <Grid item xs={5}>
                      Description
                    </Grid>
                    <Grid item xs={7} sx={{ display: "flex" }}>
                      <p style={{ padding: 0, margin: 0 }}>:</p>
                      <ul style={{ margin: 0, paddingLeft: "2.2rem" }}>
                        <li>
                          krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun
                          mandi; produk perawatan bibir (kosmetik); losion untuk tubuh;
                          lulur; krim tubuh; masker rambut; sediaan perawatan kulit;
                          kosmetik; susu pembersih wajah; astringen untuk keperluan
                          kosmetik; kapas untuk keperluan kosmetik; pensil alis; Eyeliner
                        </li>
                        <li>
                          krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun
                          mandi;
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </div>
                <Divider />
              </Grid>
              <Grid item xs={5} container spacing={2}></Grid>
            </Grid>

            {/* =========================== Signature ============================*/}
            <Grid
              container
              spacing={3}
              sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
            >
              <Grid item xs={6} sx={{ ...justifyContentCenter }}>
                <Signature
                  companyName="Pemohon"
                  // signatureText={detailRowV2["application"]['client_name']}
                  signatureText={"Liberty Enterprises"}
                />
              </Grid>
              <Grid item xs={6} sx={{ ...justifyContentCenter }}>
                <Signature
                  companyName={"Opration Manager"}
                  // companyName={detailRowV2["application"]['Request to']['position']}
                  signatureText={"Marcus levin"}
                  // signatureText={detailRowV2["application"]['Request to']['name']}
                />
              </Grid>
            </Grid>

            {/* ========================= Footer ============================*/}
            <div className={classes.footer}>
              <Box sx={{ display: "flex", gap: ".5rem" }}>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                  +6221 2986 5888
                </Typography>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                  pacific@pacificpatent.com
                </Typography>
              </Box>
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                www.pacificpatent.com
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Box>
    );
  };

  const ForPrintingV3 = () => {
    const classes = useStyles();

    // const renderGridItemsFromObject = (object) => {
    //   const excludedProperties = [
    //     "Request to",
    //     "Created By",
    //     "Date Approved",
    //     "Comment",
    //     "Status",
    //   ];
    //   const rightAlignedKeys = ["Subtotal", "Discount", "VAT (11%)", "Total"];

    //   const isRightAlignedKey = (key) => {
    //     return rightAlignedKeys.includes(key) || key.toLowerCase().includes("discount");
    //   };

    //   return Object.entries(object).map(([key, value]) =>
    //     // Skip rendering "Request to"
    //     excludedProperties.includes(key) ? null : (
    //       <React.Fragment key={key}>
    //         <Grid item xs={4}>
    //           <Typography variant="body1">{key}</Typography>
    //         </Grid>
    //         <Grid item xs={8}>
    //           <Typography
    //             variant="subtitle1"
    //             sx={{ textAlign: isRightAlignedKey(key) ? "right" : "left" }}
    //           >
    //             {value}
    //           </Typography>
    //         </Grid>
    //       </React.Fragment>
    //     )
    //   );
    // };

    // const renderRequestedServices = (services) => {
    //   return services.map((service, serviceIndex) => (
    //     <React.Fragment key={serviceIndex}>
    //       <Grid item xs={12}>
    //         <Box display="flex" alignItems="center">
    //           <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
    //             {service.title} - {service.subtitle}
    //           </Typography>
    //         </Box>
    //       </Grid>
    //       {renderGridItemsFromObject(service.children)}
    //     </React.Fragment>
    //   ));
    // };

    // const renderPriceInformation = (priceInfo) => {
    //   return Object.entries(priceInfo).map(([key, value]) => (
    //     <React.Fragment key={key}>
    //       <Grid item xs={8}>
    //         <Typography variant="body2" sx={{ textAlign: "right", fontWeight: "bold" }}>
    //           {key}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={4}>
    //         <Typography variant="subtitle1" sx={{ fontWeight: 800, textAlign: "right" }}>
    //           {value}
    //         </Typography>
    //       </Grid>
    //     </React.Fragment>
    //   ));
    // };


    const paragraphStyle = {
      padding: ".2rem, 0",
      margin: ".4rem 0",
    };
    const titleContentStyle = {
      color: neutral70,
      margin: ".5rem 0",
    };
    return (
      <div className="page-break">
        <Paper className={`${classes.root} DocumentPreview`} sx={{ boxShadow: "unset" }}>
          <img src={boderImg} className={classes.logo} />

          {/* Header */}
          <div className={classes.header}>
            <Typography variant="body" sx={{ fontWeight: "800" }} className="not-effect">
              APPLICATION FORM OF INDONESIAN TRADEMARK REGISTRATION
            </Typography>
            <img src={logo} alt="logo" />
          </div>

          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs="6" container>
              <Grid item xs={6}>
                <Typography variant="body1">No. Transaction</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {/* {detailRowV2["application"]["No. Reference"]} */}: IPT131231244223
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">No. Application</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {/* {detailRowV2["application"]["client_name"]} */}: DID23425452897
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Receipt Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {/* {detailRowV2["others"]["date"]} */}: 26/04/2029, 14:06
                </Typography>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: "50%",
              }}
            >
              {/* <Grid item xs="5" container> */}
              {/* <Grid item xs={12}> */}
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                PT Pacific Patent Multiglobal{" "}
              </Typography>
              {/* </Grid> */}
              {/* <Grid item xs={12}> */}
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "right", color: neutral70 }}
              >
                DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav 51-52
                <br /> Jakarta Pusat 10260
              </Typography>
              {/* </Grid> */}
              {/* </Grid>  */}
            </Box>
          </Grid>

          {/* Content */}
          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs={7} container>
              {/* Content */}
              <div>
                <Grid container>
                  {/*  */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={titleContentStyle}>
                      Applicant
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <p style={paragraphStyle}>Applicant name</p>
                    <p style={paragraphStyle}>Perorangan (type)</p>
                    <p style={paragraphStyle}>sarii@gmail.com (email)</p>
                    <p style={paragraphStyle}>09377743344 (phone)</p>
                  </Grid>
                  {/*  */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={titleContentStyle}>
                      Description
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Type of mark</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: Merek Kata dan Lukisan</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Image of Mark</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: Mark</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Trademark</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: ANTUM BEAUTY'S</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Description</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: -</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Colors</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: Kuning keemasan, hitam abu abu</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Translation</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: -</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Transliteration</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: -</p>
                  </Grid>
                  <Grid item xs={5}>
                    <p style={paragraphStyle}>Disclaimers</p>
                  </Grid>
                  <Grid item xs={7}>
                    <p style={paragraphStyle}>: -</p>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={titleContentStyle}>
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      Dukuh Bantaran RT 014 RW 004, Kel Ketangan, Kec Gringsing, Kabupaten
                      Batang, Jawa Tengah
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={titleContentStyle}>
                      Representative/IP Consultant
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" fontWeight={580}>
                      Lany Setiawan MB., M.Mgt., MA-LCP., MA-LMFT
                    </Typography>
                    <p>
                      (address) Pacific Patent Multiglobal DIPO Business Center Lt. 11,
                      Jalan Jend. G Kav. 51-52, Jakarta Pusat- 10260 Indonesia
                    </p>
                    <p>(email) lannykonsultanhki@pacificpatent.com</p>
                    <p>(phone) 0865343434</p>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={titleContentStyle}>
                      Priority Data
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    -
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={titleContentStyle}>
                      Class
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    Class
                  </Grid>
                  <Grid item xs={7}>
                    : 3,14
                  </Grid>
                  <Grid item xs={5}>
                    Description
                  </Grid>
                  <Grid item xs={7} sx={{ display: "flex" }}>
                    <p style={{ padding: 0, margin: 0 }}>:</p>
                    <ul style={{ margin: 0, paddingLeft: "2.2rem" }}>
                      <li>
                        krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun
                        mandi; produk perawatan bibir (kosmetik); losion untuk tubuh;
                        lulur; krim tubuh; masker rambut; sediaan perawatan kulit;
                        kosmetik; susu pembersih wajah; astringen untuk keperluan
                        kosmetik; kapas untuk keperluan kosmetik; pensil alis; Eyeliner
                      </li>
                      <li>
                        krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun
                        mandi;
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </div>
              <Divider />
            </Grid>
            <Grid item xs={5} container spacing={2}></Grid>
          </Grid>
          {/* =========================== Signature ============================*/}
          <Grid
            container
            spacing={3}
            sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
          >
            <Grid item xs={6} sx={{ ...justifyContentCenter }}>
              <Signature
                companyName="Pemohon"
                // signatureText={detailRowV2["application"]['client_name']}
                signatureText={"Liberty Enterprises"}
              />
            </Grid>
            <Grid item xs={6} sx={{ ...justifyContentCenter }}>
              <Signature
                companyName={"Opration Manager"}
                // companyName={detailRowV2["application"]['Request to']['position']}
                signatureText={"Marcus levin"}
                // signatureText={detailRowV2["application"]['Request to']['name']}
              />
            </Grid>
          </Grid>

          {/* Footer */}
          <div className={`${classes.footer} DocumentPreviewFooter`}>
            <Box sx={{ display: "flex", gap: ".5rem" }}>
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                +6221 2986 5888
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                pacific@pacificpatent.com
              </Typography>
            </Box>
            <Typography variant="subtitle2" sx={{ color: "white" }}>
              www.pacificpatent.com
            </Typography>
          </div>
        </Paper>
      </div>
    );
  };

  const printStyle = () => (
    <style>
      {`
        .page-break {
          page-break-before: always;
        }

        .DocumentPreview{
          display:none;
        }

        .DocumentPreviewFooter{
          padding: 20px;
          background-color: #09345C;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

          @page {
              // size: A4;
//               size: 210mm 280mm;
            // size: 210mm ${pxToMm(height)}mm; /* A4 size */
              margin: 0;
              /* Remove all margins */
          }

          body {
              margin: 0;
          }

          body *{
            visibility:hidden;
          }
        .DocumentPreviewFooter{
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        border-radius:unset;
        }   
          .DocumentPreviewFooter, .DocumentPreviewFooter *{
              visibility: visible;
 padding: 10px 20px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          print-color-adjust: exact;
          webkit-print-color-adjust: exact; /* For WebKit-based browsers */
        background-color:  #09345C !important; /* Override background color */
        }

          .DocumentPreview{
            display:block;
            position: fixed !important;
            top: 0;

            left: 0;
            width: 100%;
            z-index: 11999;
            visibility: visible;
            page-break-before: always;
          }

          .DocumentPreview,.DocumentPreview *{
              visibility: visible;
          }
        }

        `}
    </style>
  );

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  sx={{ marginRight: ".5rem" }}
                >
                  <img src={ArrowBackIcon} />
                </IconButton>
                {title}
              </Box>

              {/* {detailRowV2["canResubmit"] === true && (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() =>
                    navigate(
                      `/operation-management/domestic-trademark/trademark-aplications/resubmit/${id}`
                    )
                  }
                >
                  Resubmit
                </Button>
              )} */}
            </div>
          }
        />
        <Divider />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            maxHeight: "70vh",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <ForPrintingV3 />
              </Grid>
              <Grid item xs={5.2}>
                <DetailV2 />
              </Grid>
              <Grid item xs={6.8}>
                <PreviewPDFV2 />
              </Grid>
            </Grid>
          )}
        </Box>
        {printStyle()}
      </Card>

      {actionType !== ACTION_TYPE.DELETED &&
      actionType !== ACTION_TYPE.ADD &&
      actionType !== ANOTHER_ACTION_TYPE.PENDING_TO_SUBMITTED &&
      actionType !== ANOTHER_ACTION_TYPE.SUBMITTED_TO_FINISH ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={"Submit Registration"}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementOtherActionButton={renderOtherActionButtons}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
        <Modal open={formModal} onClose={() => handleCloseFormModal()}>
          <Box>
            <Form
              title={"Submit Registration"}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementOtherActionButton={renderOtherActionButtons}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
            />
          </Box>
        </Modal>
      )}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};
export default DetailTrademarkApplication;
