import { REQUEST_LOADING } from "../../../../util/global_state";

import {
  ADD_INVOICE_FAILED,
  ADD_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_SUCCESS,
  EDIT_INVOICE_FAILED,
  EDIT_INVOICE_SUCCESS,
  GET_INVOICE_FAILED,
  GET_INVOICE_SUCCESS,
  GET_PAYLOAD_FORM_FAILED,
  GET_PAYLOAD_FORM_SUCCESS,
} from "./state";

const initState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
  payload_add_form: null,
};

const invoiceReducer = (state = initState, { type, payload, pagination }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_INVOICE_SUCCESS:
      return {
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case GET_INVOICE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_INVOICE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_INVOICE_FAILED:
    case EDIT_INVOICE_SUCCESS:
    case EDIT_INVOICE_FAILED:
    case DELETE_INVOICE_SUCCESS:
    case DELETE_INVOICE_FAILED:
    case GET_PAYLOAD_FORM_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case GET_PAYLOAD_FORM_SUCCESS:
      return {
        ...state,
        payload_add_form: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
