import React, { useState } from "react";
import confirmLogoutIMG from "./../assets/confirm-logout.png";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Breadcrumbs,
  Link,
  Divider,
  Grid,
} from "@mui/material";
import {
  thirdColor,
  secondColor,
  borderRadius,
  drawerWidth,
} from "../util/style";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import axios from "../core/axios_config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../pages/auth/clients/login/actions";
import { useDispatch, useSelector } from "react-redux";
// import { routes } from "./../routes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "1rem",
  },
  title: {
    flexGrow: 1,
  },
  navbar: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    borderBottom: "1px solid #ddd",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    color: "black",
    border: `1px solid ${secondColor}`,
    borderRadius,
  },
  userName: {
    marginLeft: theme.spacing(1),
  },
  btnAvatar: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    padding: "0.3rem 0.8rem !important",
    // paddingRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
  },
  card: {
    position: "absolute",
    top: "100%",
    right: "2%",
    marginTop: theme.spacing(1),
    zIndex: 1,
    width: "220px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    padding: theme.spacing(0),
  },
  dialog: {
    "& .MuiPaper-root": {
      width: "624px",
      borderRadius,
    },
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  dialogImage: {
    marginBottom: theme.spacing(2),
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: thirdColor,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [logoutIsLoading, setLogoutIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { menus } = useSelector((state) => state.auth);
  const location = useLocation();

  const findCurrentRoute = (routes, pathname) => {
    if (routes) {
      const cleanedPath = pathname.replace(
        /(.*?)(\/(add|edit\/\d+|detail\/\d+|resubmit\/\d+|preview-editing\/\d+)).*$/,
        "$1"
      );
      // debugger;
      for (const route of routes) {
        if (cleanedPath === route.link) {
          return route;
        }

        if (route.children) {
          const childMatch = findCurrentRoute(route.children, pathname);
          if (childMatch) {
            return childMatch;
          }
        }
      }
    }

    return null;
  };

  const currentRoute = findCurrentRoute(menus, location.pathname);

  /* -------------------------------------------------------------------------- */
  /*      Versi Bisa Parent > Sub Parent > Child (HR > Setting > Time Off )     */
  /* -------------------------------------------------------------------------- */

  const generateBreadcrumbs = (currentRoute) => {
    const breadcrumbs = [];

    const findRouteById = (routeId, routes) => {
      for (const route of routes) {
        if (route.id === routeId) {
          return route;
        }
        if (route.children && route.children.length > 0) {
          const foundInChildren = findRouteById(routeId, route.children);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    };

    const getParentRoutes = (route, parentRoutes = []) => {
      if (route.parent_id) {
        const parent = findRouteById(route.parent_id, menus);
        if (parent) {
          parentRoutes.unshift(parent);
          getParentRoutes(parent, parentRoutes);
        }
      }
      return parentRoutes;
    };

    /* ------------------------------- OLD VERSION ------------------------------ */
    /*
    const parentRoutes = getParentRoutes(currentRoute);
    breadcrumbs.push(...parentRoutes, currentRoute);

    // Add the "Add {Route Name}" breadcrumb if path contains "/add"
    if (location.pathname.includes("/add")) {
      let name = `Add ${currentRoute.name}`;
      if (location.state && location.state.type) {
        name = `${name} ${location.state.type}`;
      }

      name = location.pathname.includes("human-resources/benefit/detail")
        ? location.pathname.includes("meal_allowance")
          ? "Meal Allowance"
          : "Allowance"
        : location.pathname.includes("human-resources/employees")
        ? "Employee"
        : name;

      breadcrumbs.push({
        path: currentRoute.link,
        name: name,
        parent_route: [...parentRoutes, currentRoute],
      });
    }

    // Add the "Edit {Route Name}" breadcrumb if path contains "/edit"
    if (location.pathname.includes("/edit")) {
      let name = `Edit ${currentRoute.name}`;
      if (location.state && location.state.type) {
        name = `${name} ${location.state.type}`;
      }

      name = location.pathname.includes("human-resources/benefit/detail")
      ? location.pathname.includes("meal_allowance")
        ? "Meal Allowance"
        : "Allowance"
      : location.pathname.includes("human-resources/employees")
      ? "Employee"
      : name;


      breadcrumbs.push({
        path: currentRoute.link,
        name: name,
        parent_route: [...parentRoutes, currentRoute],
      });
    }

    if (location.pathname.includes("/detail")) {
      let name = `Detail ${currentRoute.name}`;
      if (location.state && location.state.type) {
        name = `${name} ${location.state.type}`;
      }

      name = location.pathname.includes("human-resources/benefit/detail")
      ? location.pathname.includes("meal_allowance")
        ? "Meal Allowance"
        : "Allowance"
      : location.pathname.includes("human-resources/employees")
      ? "Employee"
      : name;


      breadcrumbs.push({
        path: currentRoute.link,
        name: name,
        parent_route: [...parentRoutes, currentRoute],
      });
    }

    // console.log(breadcrumbs, "END V2");
    return breadcrumbs;
    */

    /* ------------------------------- NEW VERSION ------------------------------ */
    function generateBreadcrumb(path, currentRoute, location) {
      let prefix = "";

      if (location.pathname.includes("/add")) {
        prefix = "Add";
      } else if (location.pathname.includes("/edit")) {
        prefix = "Edit";
      } else if (location.pathname.includes("/detail")) {
        prefix = "Detail";
      } else if (location.pathname.includes("/resubmit")) {
        prefix = "Resubmit";
      } else if (location.pathname.includes("/preview-editing")) {
        prefix = "Preview Editing";
      }

      let name = `${prefix} ${currentRoute.name}`;

      if (location.state && location.state.type) {
        name = `${name} ${location.state.type}`;
      }

      if (path.includes("human-resources/benefit/detail")) {
        name = `${prefix} ${
          path.includes("meal_allowance") ? "Meal Allowance" : "Allowance"
        }`;
      } else if (path.includes("human-resources/employees")) {
        name = `${prefix} Employee`;
      } else if (path.includes("business-permit/application")) {
        name = `${prefix} Application`;
      }

      return {
        path: currentRoute.link,
        name: name,
        parent_route: [...parentRoutes, currentRoute],
      };
    }

    const parentRoutes = getParentRoutes(currentRoute);
    breadcrumbs.push(...parentRoutes, currentRoute);

    if (
      location.pathname.includes("/add") ||
      location.pathname.includes("/edit") ||
      location.pathname.includes("/detail") ||
      location.pathname.includes("/resubmit") ||
      location.pathname.includes("/preview-editing")
    ) {
      console.log('fixroute', location.pathname, currentRoute, location)
      breadcrumbs.push(
        generateBreadcrumb(location.pathname, currentRoute, location)
      );
    }

    // console.log(breadcrumbs, "END V2");
    return breadcrumbs;
  };

  const generateBigTitle = (currentRoute) => {
    let bigTitle = "";
    if (location.state && location.state.type) {
      bigTitle = `${currentRoute.name} ${location.state.type}`;
    } else {
      bigTitle = `${currentRoute.name}`;
    }

    // bigTitle = location.pathname.includes("human-resources/benefit/detail")
    //   ? location.pathname.includes("meal_allowance")
    //     ? "Meal Allowance"
    //     : "Allowance"
    //   : location.pathname.includes("human-resources/employees/") // Berlaku hanya untuk Add/Edit/Detail
    //   ? "Employee"
    //   : bigTitle;

    if (location.pathname.includes("/add")) {
      bigTitle = `Add ${bigTitle}`;
    } else if (location.pathname.includes("/edit")) {
      bigTitle = `Edit ${bigTitle}`;
    } else if (location.pathname.includes("/detail")) {
      bigTitle = bigTitle !== "Attendance" ? `Detail ${bigTitle}` : bigTitle;
    } else if (location.pathname.includes("/resubmit")) {
      bigTitle = `Resubmit ${bigTitle}`;
    } else if (location.pathname.includes("/preview-editing")) {
      bigTitle = `Preview Editing ${bigTitle}`;
    }
    return bigTitle;
  };

  /* -------------------------------------------------------------------------- */
  /*      Versi Hanya Bisa Parent > Child (System Configuration > Director)     */
  /* -------------------------------------------------------------------------- */

  /*const generateBreadcrumbs = (currentRoute) => {
  const breadcrumbs = [];

  const findRouteById = (routeId) => {
    return menus.find((route) => route.id === routeId);
  };

  const traverse = (route, isParentRoute) => {
    if (isParentRoute) {
      breadcrumbs.push({
        path: route.link,
        name: route.name,
        parent_route: route.parent_id ? findRouteById(route.parent_id) : null,
      });
    }

    if (route.link) {
      breadcrumbs.push({
        path: route.link,
        name: route.name,
        parent_route: isParentRoute ? route : findRouteById(route.parent_id),
      });
    }

    if (route.children) {
      route.children.forEach((childRoute) => {
        traverse(childRoute, false);
      });
    }
  };

  traverse(currentRoute, true);

  // Add the "Add {Route Name}" breadcrumb if path contains "/add"
  if (location.pathname.includes("/add")) {
    breadcrumbs.push({
      path: currentRoute.link,
      name: `Add ${currentRoute.name}`,
      parent_route: currentRoute,
    });
  }

  // Add the "Edit {Route Name}" breadcrumb if path contains "/edit"
  if (location.pathname.includes("/edit")) {
    breadcrumbs.push({
      path: currentRoute.link,
      name: `Edit ${currentRoute.name}`,
      parent_route: currentRoute,
    });
  }

  return breadcrumbs;
};
*/
  const { user, token } = useSelector((state) => state.auth);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    setOpen(!open);
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogoutConfirm = async () => {
    if (logoutIsLoading == true) return;

    setLogoutIsLoading(true);

    await axios
      .post(
        "/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLogoutIsLoading(false);
        dispatch(logout());
        localStorage.removeItem("t");
        setLogoutDialogOpen(false);
        navigate("/login");
      })
      .catch((error) => {
        setLogoutIsLoading(false);
        //toast.error(error.response.data.message);
        // setconfirmOtpIsLoading(false);
        dispatch(logout());
        localStorage.removeItem("t");
        setLogoutDialogOpen(false);
        navigate("/login");
      });
  };
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "white",
          boxShadow: "unset",
          display: "flex",
          justifyContent: "flex-end",
          borderBottom: "1px solid #ddd",
          // width: `calc(100% - ${drawerWidth}px)`,
          // zIndex:'9999'
        }}
      >
        <ToastContainer />
        <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid item xs={9.7}>
            <Toolbar>
              <Typography
                variant="h6"
                className={classes.title}
                sx={{ color: "black" }}
              >
                {currentRoute ? (
                  <React.Fragment>
                    {generateBigTitle(currentRoute)}
                    <Breadcrumbs
                      separator={<ChevronRightIcon />}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {generateBreadcrumbs(currentRoute).map(
                        (breadcrumb, index, breadcrumbsArray) => {
                          // debugger; // This will pause execution and opn developer tools
                          return index === breadcrumbsArray.length - 1 ? (
                            <Typography
                              key={breadcrumb.path}
                              color="text.primary"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {breadcrumb.name}
                            </Typography>
                          ) : (
                            <Link
                              key={breadcrumb.path}
                              color="inherit"
                              href={breadcrumb.path}
                              underline="none"
                            >
                              {breadcrumb.name}
                            </Link>
                          );
                        }
                      )}
                    </Breadcrumbs>
                  </React.Fragment>
                ) : (
                  "Unknown Title"
                )}
              </Typography>

              <div className={classes.userContainer}>
                <Button
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  variant="text"
                  color="inherit"
                  startIcon={
                    <Avatar
                      alt={user?.fullname}
                      src="/path/to/user-avatar.jpg"
                    />
                  }
                  size="small"
                  className={classes.btnAvatar}
                >
                  {user?.fullname}
                  <div className="fcenter">
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </Button>
                {open && (
                  <Card
                    id="user-card"
                    className={`${classes.card} `}
                    sx={{
                      borderRadius: 4,
                      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <Avatar
                      alt="User Avatar"
                      src="/path/to/user-avatar.jpg"
                      sx={{ width: "90px", height: "90px", marginTop: "1rem" }}
                    />
                    <CardContent className={`${classes.cardContent} `}>
                      <div>
                        <Typography variant="h6" align="center">
                          {user?.fullname}
                        </Typography>
                        <Typography variant="body2" align="center">
                          {user?.email}
                        </Typography>
                      </div>
                      <Divider sx={{ margin: "10px 0 0" }} />
                      <Button onClick={handleLogout} color="primary" fullWidth>
                        Logout
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </div>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        className={classes.dialog}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h6"></Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleLogoutDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <img
            src={confirmLogoutIMG}
            alt="Confirmation Image"
            className={classes.dialogImage}
          />
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: "700" }}
          >
            Are you sure you want to Logout?
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: secondColor, fontStyle: "italic", fontWeight: "400" }}
          >
            Click the button below to continue logging out?
          </Typography>
        </DialogContent>
        <DialogActions
          className={`${classes.dialogActions}`}
          sx={{ display: "flex", justifyContent: "center", padding: "15px 0" }}
        >
          <Button
            onClick={handleLogoutConfirm}
            sx={{
              backgroundColor: logoutIsLoading == true ? "grey.400" : "primary",
            }}
            variant="contained"
            disableElevation
          >
            Continue Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Navbar;
