import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Tooltip,
  ThemeProvider,
  Toolbar,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import {
  StatusBadge,
  confirmDelete,
  secondColor,
} from "../util/style";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import addIcon from "./../assets/add.png";
import editIcon from "./../assets/edit.png";
import deleteIcon from "./../assets/delete.png";
import {
  imgBtnToolbar,
  btnTableToolbar,
  table,
  FacebookCircularProgress,
} from "../util/style";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  MODE_CRUD_WITH,
  findMenuItemByLink,
} from "../util/function";
import noDataIMG from "./../assets/no-data.png";
import noResultFilter from "./../assets/no-result-filter.svg";
import emptyData from "./../assets/empty-data.svg";
import searchNoData from "./../assets/search-no-data.svg";
import { AccessRestrictedAlert } from "./Alert";

const DataTable = ({
  title,
  useStyles,
  rows,
  columns,
  isLoading,
  pagination,
  limit,
  page,
  handlePageChange,
  handleLimitChange,
  handleFormModal,
  selectedData,
  handleSelectionChange,
  handleRowDoubleClick,
  theme,
  statusChoosed = null, // status yang dipilih apa
  typeStageChoosed = null, // stages
  actionChoosed = null, // userLogs
  dateChoosed = null, // dateChoosed
  timeOffChoosed = null, // TimeOff
  positionChoosed = null, // position yg dipilih apa
  categoryChoosed = null, // category yang dipilih apa
  stateUpdate = null, // stateNavigation
  stateCreate = null, // stateNavigation
  search = null,
  sortModel,
  toolBarLeft = null,
  showAddToolbar = true,
  showEditToolbar = true,
  showDeleteToolbar = true,
  iconEditToolbar = null,
  isOnSelection = true,
  onSortModelChange,
  componentModifyToolbarRight = null,
  componentModifyToolbarLeft = null,
  modeCRUD = MODE_CRUD_WITH.MODAL,
  linkCRUD = { create: "add", update: "edit/1", createOption: "native" }, // tambah property ini jika modeCRUD == MODE_CRUD_WITH.PAGING;
  additionalProps = {
    currentPath: null,
  },
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([_.READ]);
  const { menus } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    setIsFetching(true);

    // dont push
    if (
      //false
      // process.env.REACT_APP_API_URL !== "https://api-app.pacificpatent.com" &&
      currentPath.includes("business-permit") || // NEW
      currentPath.includes("trademark-applications") ||
      currentPath.includes("kpi-rules") ||
      currentPath.includes("patent")
      // (currentPath.includes("sales-management") || currentPath.includes("business-permit")) // OLD
    ) {
      setUserAccess([_.CREATE, _.READ, _.UPDATE, _.DELETE]);
      setTimeout(() => setIsFetching(false), 600);
      return;
    }

    if (menus) {
      if (menus.length > 0) {
        // Find the menu item with the matching pathname
        const menuItem = findMenuItemByLink(
          menus,
          additionalProps.currentPath || currentPath
        );

        if (menuItem) {
          // Filter permissions with has_access set to true
          const filteredPermissions = menuItem.permission.filter(
            (permission) => permission.has_access
          );

          // Extract the permission actions and update userAccess state
          const accessNames = filteredPermissions.map((permission) => {
            const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
            return action.toUpperCase(); // Convert to uppercase
          });
          setUserAccess(accessNames);
        }

        setTimeout(() => setIsFetching(false), 600);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    const aa = userAccess;
    // debugger;
  }, [userAccess]);
  /* -------------------------------------------------------------------------- */
  /*                                   Toolbar                                  */
  /* -------------------------------------------------------------------------- */
  const CustomToolbar = () => {
    const isDeleteDisabled = selectedData.length === 0;
    const isEditDisabled = selectedData.length !== 1;

    const handleAction = (action) => {
      if (modeCRUD === MODE_CRUD_WITH.MODAL) {
        return handleFormModal(action);
      } else if (modeCRUD === MODE_CRUD_WITH.PAGING) {
        switch (action) {
          case ACTION_TYPE.ADD:
            if (linkCRUD.createOption === "hybrid") {
              return handleFormModal(action);
            }
            return navigate(linkCRUD.create, { state: stateCreate ?? {} });
          case ACTION_TYPE.EDITED:
            return navigate(linkCRUD.update, { state: stateUpdate ?? {} });
          case ACTION_TYPE.DELETED:
            return handleFormModal(action);
          default:
            break;
        }
      }
    };
    return (
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: toolBarLeft ? "space-between" : "flex-end",
          // paddingRight: "16px",
          padding: "unset !important",
        }}
      >
        {toolBarLeft ? toolBarLeft() : <></>}
        {isFetching ? (
          <Box sx={{ width: "10%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <Box>
            {componentModifyToolbarLeft && componentModifyToolbarLeft()}

            {showDeleteToolbar && (
              <Tooltip title={`Delete ${title}`}>
                <span>
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="delete"
                    disabled={
                      userAccess.includes(_.DELETE) === false ||
                      isDeleteDisabled ||
                      currentPath.includes("menu-management") == true
                    }
                    sx={{
                      ...btnTableToolbar,
                      backgroundColor: isDeleteDisabled ? "#ccc" : "red",
                      "&:hover": {
                        backgroundColor: isDeleteDisabled ? "#ccc" : "red",
                      },
                    }}
                    onClick={() => handleAction(ACTION_TYPE.DELETED)}
                    id="delete-btn"
                  >
                    <img
                      src={deleteIcon}
                      alt="Delete"
                      style={{
                        ...imgBtnToolbar,
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {showEditToolbar && (
              <Tooltip title={`Edit ${title}`}>
                <span>
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="edit"
                    disabled={
                      userAccess.includes(_.UPDATE) == false || isEditDisabled
                    }
                    sx={{
                      ...btnTableToolbar,
                      backgroundColor: isDeleteDisabled
                        ? "#ccc"
                        : "primary.main",
                      "&:hover": {
                        backgroundColor: isDeleteDisabled
                          ? "#ccc"
                          : "primary.dark",
                      },
                    }}
                    onClick={() => handleAction(ACTION_TYPE.EDITED)}
                    id="edit-btn"
                  >
                    <img
                      src={iconEditToolbar ? iconEditToolbar : editIcon}
                      alt="Edit"
                      style={{
                        ...imgBtnToolbar,
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {showAddToolbar && (
              <Tooltip title={`Add New ${title}`}>
                <span>
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="add"
                    disabled={
                      userAccess.includes(_.CREATE) == false ||
                      selectedData.length > 0
                    }
                    sx={{
                      ...btnTableToolbar,
                      backgroundColor:
                        selectedData.length > 0 ? "#ccc" : "primary.main",
                      "&:hover": {
                        backgroundColor:
                          selectedData.length > 0 ? "#ccc" : "primary.dark",
                      },
                    }}
                    onClick={() => handleAction(ACTION_TYPE.ADD)}
                    id="add-btn"
                  >
                    <img
                      src={addIcon}
                      alt="Add"
                      style={{
                        ...imgBtnToolbar,
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {componentModifyToolbarRight && componentModifyToolbarRight()}
          </Box>
        )}
      </Toolbar>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                               Grid Pagination                              */
  /* -------------------------------------------------------------------------- */
  const CustomGridPagination = (props) => {
    const classes = useStyles();
    return (
      <GridPagination
        {...props}
        classes={{
          root: classes.root,
          select: classes.select,
          action: classes.button,
        }}
      />
    );
  };

  const GenerateDatas = () => {
    if (
      statusChoosed === ACTION_TYPE.DEFAULT_STATUS ||
      categoryChoosed === ACTION_TYPE.DEFAULT_CATEGORY ||
      typeStageChoosed === ACTION_TYPE.DEFAULT_TYPE_STAGE ||
      actionChoosed === ACTION_TYPE.DEFAULT_ACTION ||
      dateChoosed === ACTION_TYPE.DEFAULT_DATE ||
      positionChoosed === ACTION_TYPE.DEFAULT_POSITION ||
      (timeOffChoosed &&
        (timeOffChoosed.status === ACTION_TYPE.DEFAULT_STATUS ||
          timeOffChoosed.category === ACTION_TYPE.DEFAULT_CATEGORY_TIME_OFF))
    ) {
      return (
        <Box style={{ ...confirmDelete }}>
          <img src={noDataIMG} alt="No Data Image" />
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: "700" }}
          >
            There is no data to display.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: secondColor, fontStyle: "italic", fontWeight: "400" }}
          >
            Set the data you need
          </Typography>
        </Box>
      );
    } else {
      return (
        <ThemeProvider theme={theme}>
          {isLoading ? (
            <FacebookCircularProgress />
          ) : rows.length === 0 ? (
            <Box style={{ ...confirmDelete }}>
              <img
                src={search ? searchNoData : emptyData}
                alt="Search No Data"
              />
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: "700" }}
              >
                {search ? "Couldn't find anything for" : "No Data Found"}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: secondColor,
                  fontStyle: "italic",
                  fontWeight: "400",
                }}
              >
                {search ? `"${search}"` : "There is no data to display."}
              </Typography>
            </Box>
          ) : (
            <>
              {/* <Grid container> */}
              {/* <Grid item xs={12}> */}
              {/* <CustomToolbar /> */}
              {/* </Grid>
              <Grid item xs={12}> */}
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                components={{
                  // Toolbar: CustomToolbar,
                  Pagination: CustomGridPagination,
                }}
                pagination
                checkboxSelection={isOnSelection}
                disableSelectionOnClick
                onRowDoubleClick={handleRowDoubleClick}
                onSelectionModelChange={handleSelectionChange}
                sx={{
                  overflowX: "auto",
                  paddingRight: "0px",
                  "& .MuiDataGrid-row .MuiDataGrid-cell:focus": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-row": {
                    fontWeight: "normal",
                  },
                }}
                // rowHeight={42} // Adjust the row height here
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                // density="compact" // Make the table more compact
                count={
                  pagination && pagination.totalData ? pagination.totalData : 0
                }
                rowsPerPageOptions={[5, 10, 100]}
                pageSize={limit}
                page={page}
                paginationMode="server" //
                rowCount={
                  pagination && pagination.totalData ? pagination.totalData : 0
                }
                onPageChange={handlePageChange}
                onPageSizeChange={handleLimitChange}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
              />
              {/* </Grid> */}
              {/* </Grid> */}
            </>
          )}
        </ThemeProvider>
      );
    }
  };

  return (
    <div style={{ ...table }}>
      <CustomToolbar />
      {userAccess.includes(_.READ) ? (
        GenerateDatas()
      ) : (
        <AccessRestrictedAlert />
      )}
    </div>
  );
};

export default DataTable;
