import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { borderRadius } from "../../../../util/style";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";

export const paymentStatusColor = {
  pending: { backgroundColor: "#FFF1CF", color: "#FFBA10" },
  paid: { backgroundColor: "#B0CDE7", color: "#1268B8" },
  approved: { backgroundColor: "#D2F0CC", color: "#1DB200" },
  rejected: { backgroundColor: "#FFCDCC", color: "#FF0400" },
  partial: { backgroundColor: "#1B3B94", color: "#FFFFFF" },
};

export const PaymentStatusBadge = styled(Badge)(
  ({ theme, status, isinfo, style = {}, bgColor, colors }) => {
    let backgroundColor = "#FFF1CF";
    let color = "#FFBA10";

    if (colors) {
      color = colors;
    }
    if (bgColor) {
      backgroundColor = bgColor;
    }

    switch (status.toLowerCase()) {
      case "paid":
        backgroundColor = paymentStatusColor.paid.backgroundColor;
        color = paymentStatusColor.paid.color;
        break;
      case "approved":
        backgroundColor = paymentStatusColor.approved.backgroundColor;
        color = paymentStatusColor.approved.color;
        break;
      case "cancel":
      case "rejected":
        backgroundColor = paymentStatusColor.rejected.backgroundColor;
        color = paymentStatusColor.rejected.color;
        break;
      case "partial":
        backgroundColor = paymentStatusColor.partial.backgroundColor;  
        color = paymentStatusColor.partial.color;  
      break;
      // Default for Pending and Waiting
      default:
        backgroundColor = paymentStatusColor.pending.backgroundColor;
        color = paymentStatusColor.pending.color;
    }

    return {
      backgroundColor,
      color,
      padding: "2px 8px",
      borderRadius,
      textTransform: "capitalize",
      ...style,
    };
  }
);

// const theme = useTheme();
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e1e1e1",
    overflow: "hidden",
    position: "relative !important",
  },
  logo: {
    position: "absolute",
    width: "100%",
  },
  header: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "10px 10px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: "20px",
    backgroundColor: "#ffffff",
  },
  footer: {
    padding: "20px",
    backgroundColor: "#09345C",
    borderRadius: "0 0 10px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
