import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_CUPBOARD_SUCCESS,
  GET_CUPBOARD_FAILED,
  ADD_CUPBOARD_SUCCESS,
  ADD_CUPBOARD_FAILED,
  EDIT_CUPBOARD_SUCCESS,
  EDIT_CUPBOARD_FAILED,
  DELETE_CUPBOARD_FAILED,
  DELETE_CUPBOARD_SUCCESS,
} from "./state";
import { logout } from "../../../auth/clients/login/actions";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getCupboard = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "cupboard",
          page,
          limit,
          search,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getCupboardSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getCupboardSuccess([], null));
        } else {
          dispatch(getCupboardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getCupboardFailed(error.response.data.message));
          }
        } else {
          dispatch(getCupboardFailed(error.message));
        }
      });
  };
};

const getCupboardSuccess = (data, pagination) => {
  return {
    type: GET_CUPBOARD_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getCupboardFailed = (errorMessage) => {
  return {
    type: GET_CUPBOARD_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addCupboard = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`cupboard`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "cupboard"));
          dispatch(addCupboardSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addCupboardFailed(response.data.message));
        } else {
          dispatch(addCupboardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addCupboardFailed(error.response.data.message));
          }
        } else {
          dispatch(addCupboardFailed(error.message));
        }
      });
  };
};

const addCupboardSuccess = (message) => {
  return {
    type: ADD_CUPBOARD_SUCCESS,
    payload: message,
  };
};

const addCupboardFailed = (message) => {
  return {
    type: ADD_CUPBOARD_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editCupboard = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `cupboard/${formData.id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "cupboard"));
          dispatch(editCupboardSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editCupboardFailed(response.data.message));
        } else {
          dispatch(editCupboardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editCupboardFailed(error.response.data.message));
          }
        } else {
          dispatch(editCupboardFailed(error.message));
        }
      });
  };
};

const editCupboardSuccess = (message) => {
  return {
    type: EDIT_CUPBOARD_SUCCESS,
    payload: message,
  };
};

const editCupboardFailed = (message) => {
  return {
    type: EDIT_CUPBOARD_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteCupboard = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`cupboard${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "cupboard"));
          dispatch(deleteCupboardSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteCupboardFailed(response.data.message));
        } else {
          dispatch(deleteCupboardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteCupboardFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteCupboardFailed(error.message));
        }
      });
  };
};

const deleteCupboardSuccess = (message) => {
  return {
    type: DELETE_CUPBOARD_SUCCESS,
    payload: message,
  };
};

const deleteCupboardFailed = (message) => {
  return {
    type: DELETE_CUPBOARD_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
