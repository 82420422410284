"use strict";
//@ts-check

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
  AttachmentItem,
} from "../../../../../util/style";
import {
  ACTION_TYPE,
  SALES_ORDER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
} from "../../../../../util/function";
import { PaymentStatusBadge } from "../../../payment-request/parts/CusomStyle";
import { ValidateValueRender } from "../../../proforma-invoice/validation/validateValue";
import { manipulatePaylaodDetail } from "../../parts/imutable_state";

const DocumentDetail = () => {
  const { data, message } = useSelector((state) => state.financeInvoiceDetail);
  const [detailDocument, setDetailDocument] = useState({
    product: {
      status: "Pending",
      no_proforma: "12782",
      create_at: new Date().toLocaleDateString("id-ID", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
      }),
      no_reference: "123321",
      service_category: "Patent",
      created_by: "Json miranti",
      currency: "Rp",
      sub_service: [
        { name: "Patent Pecahan", id: 2, price: 2_000_000 },
        { name: "Patent Sederhana", id: 4, price: 4_000_000 },
      ],
      price: 12323121,
      discount: 2_000_000,
      vat: 50_000,
      total: 2536273,
      description:
        "Lorem ipsum dolor sit amet consectetur. Auctor dolor pellentesque viverra enim posuere elementum iaculis a",
      remark: "Microsoft Office",
    },
    bank: {
      acount_name: "eric",
      acount_address: "Jl. Gatot",
      acount_no: 1232321,
      bank_name: "Bank XYZ",
      bank_address: "Jalan Sudirman",
      payment_receive: 99999999,
      payment_conversion_receive: 990099,
    },
    client: {
      bill_to: "Andre",
      attn: "Avon product",
      bill_address: "Jl. Tomang No. 123 Jakarta 456",
    },
    Accounting: "Markus jhonson",
  });

  useEffect(() => {
    if (data) {
      const manipulated = manipulatePaylaodDetail(data);
      setDetailDocument(manipulated);
    }
  }, [message, data]);

  const validation = new ValidateValueRender(detailDocument);
  const statusColors = {
    pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    approved: { bgColor: successSurfaceColor, colors: successMainColor },
    rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    paid: { bgColor: "#B0CDE7", colors: "#1268B8" },
    // Add more statuses as needed
  };

  //   const statusInfo = statusColors[
  //     detailRowV2["Sales Order Information"]["Status"]
  //   ] || {
  //     bgColor: infoSurfaceColor,
  //     colors: infoMainColor,
  //   };

  /**
   *
   * @param {'product'|'payment'} type
   * @returns
   */
  const renderPropertyFromObject = (type = "") => {
    /**
     * key === key in document detail
     * value === show in UI
     */
    const listRenderProduct = {
      one: {
        no_proforma: "No. Proforma Invoice",
        create_at: "Create At",
        no_reference: "No. Reference",
        service_category: "Service Category",
        sub_service: "Sub Category Service",
        price: "Price",
        discount: "Discount",
      },
      two: {
        vat: "VAT",
        total: "Total",
        description: "Description",
      },
    };
    const listRenderBank = {
      acount_name: "Account Name",
      acount_address: "Account address",
      bank_name: "Name of Bank",
      acount_no: "Account No.",
      bank_address: "Bank Address",
      payment_receive: "Payment Received",
      payment_conversion_receive: "Payment Conversion Received",
    };

    const validateValueRender = (value) => {
      if (Array.isArray(value) && value.length > 0) {
        return value.map((item) => item.name);
      }
      return value;
    };

    const RenderElement = ({ detail, type }) =>
      // Object.entries(objEntries).map(([key, titleShow]) => (
      Object.entries(detail).map(([key, value]) => {
        const listExclude = {
          product: ["title", "status"],
        };

        return (
          !listExclude.product.includes(key) && (
            <React.Fragment key={key}>
              <Grid item xs={6}>
                <Typography variant="body1">{key === "No. Proforma Invoice" ? "No. Invoice" : key}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  {/* {validation.renderValue(detail[key], key, false, {
                location: "id-ID",
                options: {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                },
              })} */}
                  {validateValueRender(value)}
                </Typography>
              </Grid>
              {(key === "Discount" || key === "Total") && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          )
        );
      });

    if (type === "product") {
      return (
        <>
          <RenderElement
            detail={detailDocument.product}
            objEntries={listRenderProduct.one}
          />
          {/* <Grid item xs={12}>
            <Divider />
          </Grid>
          <RenderElement
            detail={detailDocument.product}
            objEntries={listRenderProduct.two}
          /> */}
        </>
      );
    } else if (type === "payment") {
      return (
        <>
          <RenderElement
            detail={detailDocument.bank}
            objEntries={listRenderBank}
          />
        </>
      );
    } else return null;
  };

  const documentDetails = ["product", "payment"];

  return (
    <>
      {/* -------------------------------- Detail ------------------------------- */}
      {documentDetails.map((detail) => (
        <Grid
          container
          spacing={2}
          sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, p: 3 }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" fontWeight={"800"}>
              {detail === documentDetails[0]
                ? detailDocument.product["title"]
                : "Bank Transfer"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {detail === documentDetails[0] && (
              <Box
                sx={{
                  marginBottom: 2,
                  ...justifyContentEnd,
                  ...alignItemsCenter,
                }}
              >
                {detailDocument.product.hasOwnProperty("status") && (
                  <PaymentStatusBadge status={detailDocument.product["status"]}>
                    {detailDocument.product["status"]}
                  </PaymentStatusBadge>
                )}
              </Box>
            )}
          </Grid>
          {renderPropertyFromObject(detail)}
        </Grid>
      ))}
    </>
  );
};

export default DocumentDetail;
