import { REQUEST_LOADING } from "../../../../util/global_state";

import {
  GET_SALES_ORDER_DETAILS_SUCCESS,
  GET_SALES_ORDER_DETAILS_FAILED,
} from "./state";

const initialState = {
  data: null,
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

// Define a helper function for common state updates
const updateState = (state, payload, pagination, isLoading = false) => {
  return {
    ...state,
    data: payload,
    pagination,
    message: null,
    isLoading,
  };
};

const salesOrdersDetailReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_SALES_ORDER_DETAILS_SUCCESS:
      return updateState(state, payload, pagination);
    case GET_SALES_ORDER_DETAILS_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default salesOrdersDetailReducer;
