export const GET_REGISTRATION_SUCCESS = "GET_REGISTRATION_SUCCESS";
export const GET_REGISTRATION_FAILED = "GET_REGISTRATION_FAILED";
export const ADD_REGISTRATION_SUCCESS = "ADD_REGISTRATION_SUCCESS";
export const ADD_REGISTRATION_FAILED = "ADD_REGISTRATION_FAILED";
export const EDIT_REGISTRATION_SUCCESS = "EDIT_REGISTRATION_SUCCESS";
export const EDIT_REGISTRATION_FAILED = "EDIT_REGISTRATION_FAILED";
export const DELETE_REGISTRATION_SUCCESS = "DELETE_REGISTRATION_SUCCESS";
export const DELETE_REGISTRATION_FAILED = "DELETE_REGISTRATION_FAILED";
export const GET_REGISTRATION_DETAILS_SUCCESS = "GET_REGISTRATION_DETAILS_SUCCESS";
export const GET_REGISTRATION_DETAILS_FAILED = "GET_REGISTRATION_DETAILS_FAILED";
