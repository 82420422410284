import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import {
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getUser = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  position = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "users",
          page,
          limit,
          search,
          position: position == ACTION_TYPE.ALL_POSITION ? null : position,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getUserSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getUserSuccess([], null));
        } else {
          dispatch(getUserFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getUserFailed(error.response.data.message));
          }
        } else {
          dispatch(getUserFailed(error.message));
        }
      });
  };
};

const getUserSuccess = (data, pagination) => {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getUserFailed = (errorMessage) => {
  return {
    type: GET_USER_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addUser = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "users",
        Object.assign({}, formData, {
          approval: formData.approval == -1 ? null : formData.approval,
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "user"));
          dispatch(addUserSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addUserFailed(response.data.message));
        } else {
          dispatch(addUserFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addUserFailed(error.response.data.message));
          }
        } else {
          dispatch(addUserFailed(error.message));
        }
      });
  };
};

const addUserSuccess = (message) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: message,
  };
};

const addUserFailed = (message) => {
  return {
    type: ADD_USER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editUser = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(
        `users/${id}`,
        Object.assign({}, formData, {
          approval: formData.approval == -1 ? null : formData.approval,
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "user"));
          dispatch(editUserSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editUserFailed(response.data.message));
        } else {
          dispatch(editUserFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editUserFailed(error.response.data.message));
          }
        } else {
          dispatch(editUserFailed(error.message));
        }
      });
  };
};

const editUserSuccess = (message) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: message,
  };
};

const editUserFailed = (message) => {
  return {
    type: EDIT_USER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteUser = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`users${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "user"));
          dispatch(deleteUserSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteUserFailed(response.data.message));
        } else {
          dispatch(deleteUserFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteUserFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteUserFailed(error.message));
        }
      });
  };
};

const deleteUserSuccess = (message) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: message,
  };
};

const deleteUserFailed = (message) => {
  return {
    type: DELETE_USER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
