import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../assets/back.svg";
import { AccessRestrictedAlert } from "./../../components/Alert";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../components/Form";
import {
  addAttachment,
  deleteAttachment,
  editAttachment,
  getAttachment,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { modalActionButton } from "../../util/style";
import { useNavigate, useParams } from "react-router-dom";

const fields = [
  {
    name: "attachment_name",
    type: INPUT_TYPE.TEXT,
    label: "Attachment Name",
    defaultValue: "",
    required: true,
  },
  {
    name: "formats",
    type: INPUT_TYPE.CHECKBOX,
    label: "Formats",
    defaultValue: [], // Array for selected format values
    options: [
      { id: 1, name: "jpg" },
      { id: 2, name: "png" },
      { id: 3, name: "pdf" },
    ],
    apiEndpoint: "/master/format-types",
  },
  {
    name: "sizes",
    type: INPUT_TYPE.NUMBER,
    label: "Maximum Sizes",
    defaultValue: null,
    required: true,
  },
  // {
  //   name: "status",
  //   type: "switch",
  //   label: "Status",
  //   defaultValue: true,
  // },
  {
    name: "setting_attachments",
    type: "settingAttachments", // Custom input component for setting attachments
    label: "Setting Attachments",
    defaultValue: [
      { service_id: "", sub_service_ids: [], is_mandatory: false },
    ],
  },
  // Add more fields as needed
];

const AddAttachment = () => {
  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [title, setTitle] = useState("Add New Attachment");
  const [actionType, setActionType] = useState();
  const [currentPath, setCurrentPath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  const { id } = useParams();

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    const fetchAttachmentData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/attachments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            response_such_as_post: true,
          },
        });

        const attachmentData = response.data.data; // Assuming the response contains attachment data
        attachmentData["status"] =
          attachmentData.status === "active" ? true : false;
        setFormValues((prevValues) => ({
          ...prevValues,
          ...attachmentData, // Merge fetched data into formValues
          id, // Make sure to keep the ID
        }));

        // Extract an array of service IDs from attachments
        const serviceIds = attachmentData.setting_attachments.map(
          (attachment) => attachment.service_id
        );

        // Set selected service category ID based on the first attachment's service_id
        if (serviceIds.length > 0) {
          setSelectedServiceCategoryId(serviceIds);
        }

        // Set errors for setting_attachments based on sub_service_ids length
        const newErrors = { ...errors };
        attachmentData.setting_attachments.forEach((setting, settingIndex) => {
          newErrors.setting_attachments[settingIndex] = {
            service_id: null,
            sub_service_ids: null,
          };
        });

        setErrors(newErrors);
        setActionType(ACTION_TYPE.EDIT); // Set the action type to edit
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching attachment data:", error);
        setIsLoading(false);
      }
    };

    if (id) {
      setTitle("Edit Attachment");
      fetchAttachmentData();
    } else {
      setActionType(ACTION_TYPE.ADD); // Set the action type to add
    }
  }, [id]);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  /* ------------------- Check if user has CREATE permission ------------------ */

  const hasCreatePermission = userAccess.includes(_.CREATE);

  const formRef = useRef(null);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialFormValues = (type = "fields") => {
    return fields.reduce(
      (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
      {}
    );
  };
  const initialErrors = {
    setting_attachments: [{ service_id: null, sub_service_ids: null }],
    attachment_name: "",
    formats: "",
    sizes: "",
    // Add other fields here
  };
  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null, status: false, setting_attachments: [{ service_id: null, sub_service_ids: [], is_mandatory: false }]})
  );
  const [errors, setErrors] = useState(initialErrors);
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState(
    []
  );

  const [options, setOptions] = useState({
    // Replace with actual fetched options data
    serviceCategories: [], // Example for service categories
    subServiceCategories: {}, // Example for sub-service categories based on service_category_id
  });

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    /* -------------------------------------------------------------------------- */
    /*                                   1                                   */
    /* -------------------------------------------------------------------------- */

    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(name, response.data.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });

    /* -------------------------------------------------------------------------- */
    /*                                   2                                   */
    /* -------------------------------------------------------------------------- */

    // Fetch service category options
    axios
      .get("/master/services", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOptions((prevOptions) => ({
          ...prevOptions,
          serviceCategories: response.data.data,
        }));
      })
      .catch((error) => {
        console.error("Error fetching service categories:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch sub-service category options based on selected service category
    for (const i of selectedServiceCategoryId) {
      if (selectedServiceCategoryId) {
        axios
          .get(`/master/services`, {
            params: {
              main_service_id: i,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setOptions((prevOptions) => ({
              ...prevOptions,
              subServiceCategories: {
                ...prevOptions.subServiceCategories, // Preserve existing data
                [i]: response.data.data,
              },
            }));
            // debugger;
          })
          .catch((error) => {
            console.error("Error fetching sub-service categories:", error);
          });
      }
    }
  }, [selectedServiceCategoryId]);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const validateField = (fieldName, settingIndex) => {
    const newErrors = { ...errors };

    const settingAttachErr = newErrors.setting_attachments[settingIndex];

    const formValuesSetting = formValues.setting_attachments[settingIndex];

    if (fieldName === "service_id") {
      if (
        !formValuesSetting.service_id ||
        formValuesSetting.service_id === -1
      ) {
        settingAttachErr.service_id = "Service category is required";
      } else {
        settingAttachErr.service_id = null;
      }
    }

    if (fieldName === "sub_service_ids") {
      if (
        !formValuesSetting.sub_service_ids ||
        formValuesSetting.sub_service_ids.length === 0
      ) {
        settingAttachErr.sub_service_ids = "Sub Category Service is required";
      } else {
        settingAttachErr.sub_service_ids = null;
      }
    }

    if (fieldName === "attachment_name") {
      newErrors.attachment_name = !formValues.attachment_name
        ? "Attachment Name is required"
        : null;
    }

    if (fieldName === "formats") {
      newErrors.formats =
        formValues.formats.length === 0
          ? "At least one format is required"
          : null;
    }

    if (fieldName === "sizes") {
      newErrors.sizes = !formValues.sizes ? "Sizes is required" : null;
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    formValues.setting_attachments.forEach((setting, settingIndex) => {
      const settingAttachErr = newErrors.setting_attachments[settingIndex];
      if (!setting.service_id || setting.service_id === -1) {
        settingAttachErr.service_id = "Service category is required";
        valid = false;
      } else {
        settingAttachErr.service_id = null;
      }

      if (!setting.sub_service_ids || setting.sub_service_ids.length === 0) {
        settingAttachErr.sub_service_ids = "Sub Category Service is required";
        valid = false;
      } else {
        settingAttachErr.sub_service_ids = null;
      }
    });

    if (!formValues.attachment_name) {
      newErrors.attachment_name = "Attachment Name is required";
      valid = false;
    } else {
      newErrors.attachment_name = null;
    }

    if (formValues.formats.length === 0) {
      newErrors.formats = "At least one format is required";
      valid = false;
    } else {
      newErrors.formats = null;
    }

    if (!formValues.sizes) {
      newErrors.sizes = "Sizes is required";
      valid = false;
    } else {
      newErrors.sizes = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "service_category_id") {
      // Assuming `value` is the ID of the selected service category
      setSelectedServiceCategoryId((prevIds) => [...prevIds, value]);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name } = e.target;
    validateField(name);
  };

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
        sizes: 0,
        setting_attachments: [
          { service_id: null, sub_service_ids: [], is_mandatory: false },
        ],
      })
    );
    setErrors(initialErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      navigate(-1);

      if (actionType === ACTION_TYPE.EDIT) {
        dispatch(editAttachment(token, formValues, id));
      } else {
        dispatch(addAttachment(token, formValues));
      }
    }
  };

  const handleSettingChange = (settingIndex, field, value) => {
    const newFormValues = { ...formValues };
    const setting = newFormValues.setting_attachments[settingIndex];
    // debugger;
    if (field === "service_id") {
      if (setting.service_id !== value) {
        // Service ID is changing, reset sub_service_ids
        setting.sub_service_ids = [];
      }
      setting.service_id = value;
      // Assuming `value` is the ID of the selected service category
      setSelectedServiceCategoryId((prevIds) => [...prevIds, value]);
    } else if (field === "sub_service_ids") {
      setting[field] = value; // value should be an array of selected IDs
    } else {
      setting[field] = value;
    }

    validateField(field, settingIndex);

    setFormValues(newFormValues);
  };

  const addSettingAttachment = () => {
    const newFormValues = { ...formValues }; // Use formValues, not fields
    newFormValues.setting_attachments.push({
      service_id: "",
      sub_service_ids: [],
      is_mandatory: false,
    });
    setFormValues(newFormValues);
    setErrors((prevErrors) => {
      const updatedAttachments = [...prevErrors.setting_attachments];
      updatedAttachments.push({
        service_id: null,
        sub_service_ids: null,
      });

      return {
        ...prevErrors,
        setting_attachments: updatedAttachments,
      };
    });
  };

  const removeSettingAttachment = (settingIndex) => {
    const newFormValues = { ...formValues }; // Use formValues, not fields
    newFormValues.setting_attachments.splice(settingIndex, 1);
    setFormValues(newFormValues);
  };

  const handleCancel = () => {
    setConfirmModal(true);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    navigate(-1);
  };

  const renderFormContent = () => {
    return (
      <Box display="flex" sx={{ gap: "1.5rem" }}>
        {/* Grid Left */}
        <Box flex="1">
          {fields.map((field) => {

            let optionsData;
            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              if (isRequired) {
                optionsData = [
                  // field.required &&
                  //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  //     name: "Choose Option",
                  //   },
                  ...options[field.name],
                ];
              } else {
                optionsData = [...options[field.name]];
              }
            }

            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            switch (field.type) {
              case INPUT_TYPE.TEXT:
                return (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.SELECT_ONE:
                return (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                return (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE:
                return (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                return (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.CHECKBOX:
                return (
                  <CheckboxGroupInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions.filter(
                      (option) => option !== false
                    )}
                  />
                );

              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
                return (
                  <Box display="flex">
                    <Box sx={{ flexGrow: 9 }}>
                      <TextInput
                        key={field.name}
                        field={field}
                        formValues={formValues}
                        errors={errors}
                        handleInputChange={handleInputChange}
                        handleInputBlur={handleInputBlur}
                      />
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margiBottom: ".5rem",
                      }}
                    >
                      MB
                    </Box>
                  </Box>
                );
              default:
                return null;
            }
          })}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...formGroup,
              padding: "0 1rem",
            }}
          >
            <FormControlLabel
              control={
                <StatusSwitch
                  checked={formValues.status}
                  name="status"
                  onChange={handleSwitchChange}
                />
              }
              label={
                <Typography
                  variant="h7"
                  sx={{ marginLeft: 1, fontWeight: "500" }}
                >
                  {formValues.status ? "Active" : "Inactive"}{" "}
                </Typography>
              }
            />
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            maxHeight: "56vh",
          }}
        >
          {/* Grid Right */}
          {fields.map((field) => {
            if (field.name === "setting_attachments") {
              return (
                <div key={field.name}>
                  <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                    Setting Attachments
                  </Typography>

                  {formValues.setting_attachments.map(
                    (setting, settingIndex) => {
                      const otherServiceIds = formValues.setting_attachments
                        .filter((_, index) => index !== settingIndex)
                        .map((otherSetting) => otherSetting.service_id);

                      const fieldOptions = {
                        serviceCategories: [
                          // {
                          //   id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                          //   name: "Choose Option",
                          // },
                          ...(options.serviceCategories || []).filter(
                            (category) => !otherServiceIds.includes(category.id)
                          ),
                        ],
                        subServiceCategories: [
                          ...(options.subServiceCategories[
                            setting.service_id
                          ] || []),
                        ],
                      };

                      const settingErrors =
                        errors.setting_attachments[settingIndex] || {}; // Get errors for the current setting

                      return (
                        <div key={settingIndex}>
                          {/* Service Category */}
                          <InputLabel>Service Category</InputLabel>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box sx={{ flexGrow: 5 }}>
                              <Autocomplete
                                fullWidth
                                size="small"
                                options={fieldOptions["serviceCategories"]}
                                getOptionLabel={(category) => category.name}
                                value={
                                  fieldOptions["serviceCategories"].find(
                                    (option) => option.id === setting.service_id
                                  ) || null
                                }
                                onChange={(_, newValue) =>
                                  handleSettingChange(
                                    settingIndex,
                                    "service_id",
                                    newValue?.id || null
                                  )
                                }
                                // onBlur={(event, newValue) =>
                                //   handleSettingChange(
                                //     settingIndex,
                                //     "service_id",
                                //     newValue?.id || null
                                //   )
                                // }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={`Select Service Category `}
                                    sx={{
                                      ...formGroup,
                                      marginBottom: "0.2rem",
                                    }}
                                  />
                                )}
                              />
                            </Box>
                            <div sx={{ flexGrow: 1, alignItems: "center" }}>
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ...removeButton,
                                }}
                                onClick={() =>
                                  removeSettingAttachment(settingIndex)
                                }
                                disabled={
                                  formValues.setting_attachments.length == 1
                                }
                              >
                                <img src={RemoveIcon} />
                              </Button>
                            </div>
                          </div>
                          {settingErrors.service_id && (
                            <FormHelperText
                              error
                              sx={{
                                color: "#fb2525",
                                fontSize: "1rem",
                                margin: "0.3rem 0 0",
                              }}
                            >
                              {settingErrors.service_id}
                            </FormHelperText>
                          )}

                          {/* Sub Category Service */}
                          <InputLabel>Sub Category Service</InputLabel>
                          <Autocomplete
                            multiple
                            fullWidth
                            size="small"
                            options={fieldOptions["subServiceCategories"]}
                            getOptionLabel={(option) => option.name}
                            value={fieldOptions["subServiceCategories"].filter(
                              (option) =>
                                setting.sub_service_ids.includes(option.id)
                            )}
                            onChange={(_, newValues) =>
                              handleSettingChange(
                                settingIndex,
                                "sub_service_ids",
                                newValues.map((subCategory) => subCategory.id)
                              )
                            }
                            disabled={!setting.service_id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder={`Select Sub Category Service`}
                                sx={{ ...formGroup, marginBottom: "0.2rem" }}
                              />
                            )}
                          />
                          {settingErrors.sub_service_ids && (
                            <FormHelperText
                              error
                              sx={{
                                color: "#fb2525",
                                fontSize: "1rem",
                                margin: "0.3rem 0 0",
                              }}
                            >
                              {settingErrors.sub_service_ids}
                            </FormHelperText>
                          )}

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={setting.is_mandatory}
                                onChange={(e) =>
                                  handleSettingChange(
                                    settingIndex,
                                    "is_mandatory",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            sx={{ textAlign: "right", marginBottom: "0.4rem" }}
                            label="Files are mandatory in this service"
                          />
                        </div>
                      );
                    }
                  )}
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                    onClick={addSettingAttachment}
                  >
                    Add Another Service
                  </Typography>
                </div>
              );
            }
          })}
        </Box>
      </Box>
    );
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
          // onClick={handleSaveButtonClick}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ marginRight: ".5rem" }}
              >
                <img src={ArrowBackIcon} />
              </IconButton>
              {title}
            </div>
          }
        />
        <Divider />
        {isLoading ? (
          <FacebookCircularProgress />
        ) : hasCreatePermission ? (
          <form ref={formRef} onSubmit={handleSubmit}>
            <CardContent sx={{ padding: "1.7rem" }}>
              {renderFormContent()}
            </CardContent>
            <CardActions
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "100%",
                justifyContent: "flex-end",
                background: "white",
                padding: "20px",
              }}
            >
              {renderActionButtons()}
            </CardActions>
          </form>
        ) : (
          <AccessRestrictedAlert />
        )}
      </Card>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default AddAttachment;
