import React, { useState } from "react";
import {
  Grid,
  TextField,
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  MenuItem,
} from "@mui/material";
import TextFieldCom from "../../components/Form/TextFieldCom";
import OtpCom from "../../components/Form/OtpCom";
import ButtonCom from "../../components/ButtonCom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../core/axios_config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenuAccessed } from "./clients/login/actions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: 4,
  bgcolor: "background.paper",
  border: "none",
  // boxShadow: 24,
};

const CreatePassword = () => {
  const { token } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmationPasswordVisibility = () => {
    setShowConfirmationPassword(!showConfirmationPassword);
  };

  const handleSave = async () => {
    setSaveIsLoading(true);

    await axios
      .post(
        "/new-password",
        { password, confirmation_password: confirmationPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        // navigate("/");
        // window.location.href = '/';
        // untuk mengambil data master menu dulu, sebelum masuk ke dashboard
        const response1 = await dispatch(getMenuAccessed(token));
        navigate("/");
        setSaveIsLoading(false);
      })
      .catch((error) => {
        setSaveIsLoading(false);
        var response = error.response.data;

        if (response.errors) {
          Object.values(response.errors).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              toast.error(errorMessage);
            });
          });
        }
      });
  };

  return (
    <Grid container spacing={2} sx={{ pt: 4, pl: 4, pr: 4, height: "100vh" }}>
      <ToastContainer />
      <Grid item xs={6} sx={{ bgcolor: "primary.secondary1" }}>
        <img src="assets/auth/password.svg" alt="Login" />
      </Grid>
      <Grid item xs={6} sx={{ m: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img sx={{}} src="logo.png" alt="Logo" />
        </Box>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            lineHeight: "54px",
            letterSpacing: "-0.021em",
            textAlign: "left",
            mx: 12,
            mt: 4,
          }}
        >
          Create New Password
        </Typography>
        <Box sx={{ mx: 12 }}>
          <TextFieldCom
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            hint="Enter your password"
            type={showPassword ? "text" : "password"}
            suffixIcon={
              showPassword ? (
                <VisibilityOff sx={{ cursor: "pointer" }} />
              ) : (
                <Visibility sx={{ cursor: "pointer" }} />
              )
            }
            onSuffixIconClick={handleTogglePasswordVisibility}
          ></TextFieldCom>
          <TextFieldCom
            label="Confirm Password"
            value={confirmationPassword}
            onChange={(event) => setConfirmationPassword(event.target.value)}
            hint="Enter your confirmation password"
            type={showConfirmationPassword ? "text" : "password"}
            suffixIcon={
              showConfirmationPassword ? (
                <VisibilityOff sx={{ cursor: "pointer" }} />
              ) : (
                <Visibility sx={{ cursor: "pointer" }} />
              )
            }
            onSuffixIconClick={handleToggleConfirmationPasswordVisibility}
          ></TextFieldCom>
          <ButtonCom
            sx={{ mt: 2 }}
            textButton="Save"
            onClick={handleSave}
            isDisable={saveIsLoading}
          ></ButtonCom>
        </Box>
      </Grid>

      {/* Modal sukses create new password */}
      <Modal
        open={showModalSuccess}
        onClose={handleSave}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            direction="column"
            alignItems="center"
            display="flex"
            sx={{ p: 4 }}
          >
            <Grid item>
              <img src="assets/auth/success.svg" width="150px" alt="" />
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>Saved!</Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontWeight: "light", opacity: 0.5, fontStyle: "italic" }}
              >
                Your new password has been successfully saved
              </Typography>
            </Grid>
          </Grid>
          {/* <Box sx={{ height: 72, bgcolor: 'grey.50', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
            <ButtonCom
              width='30%'
              textButton="Contact Admin"
              sx={{mt: 2,}}
            ></ButtonCom>
            </Box> */}
        </Box>
      </Modal>
    </Grid>
  );
};

export default CreatePassword;
