import React, { useEffect, useState } from "react";
import {
  APPROVE_TYPE,
  LOCAL_ACTION_TYPE,
  MODAL_STYLE,
  fieldDrawerForEach,
  fieldDrawerMap,
  field_drawer,
  field_modal,
  validateDisabled,
} from "../parts/imutable_state";
import {
  SelectOneAutocompleteSearch,
  TextInput,
  CheckboxGroupInput,
  DatePickerInput,
  FilePicker,
  PasswordInputWithValidation,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  SelectOneCountryAutocomplete,
  TextArea,
  TimePickerInput,
} from "../../../../components/Form";
import {
  ACTION_TYPE,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../util/function";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  Divider,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../../util/style";
import {
  ACTION_APPROVE,
  ANOTHER_INPUT_TYPE,
} from "../../payment-request/parts/imutable_state";
import ComponentRender from "./form-content/ComponentRender";
import ServiceFormComponent from "./form-content/ServiceFormComponent";
import ConfirmDelete from "../../../../components/ConfirmDelete";
import RemoveIcon from "../../../../assets/minus.svg";
import {
  extraFields,
  INVOICE_TYPE,
  referal_field,
} from "../../invoice/parts/imutable_state";

const RenderFormContent = ({
  errors,
  formValues,
  options,
  actionType,
  onFileSelect,
  priceInformation,
  handleInputChange,
  handleInputBlur,
  handleAddFile,
  handleRemoveFile,
  handleAddSubservice,
  handleRemoveSubservice,
  showPaymanetReceive,
  canConvert,
  invoiceType,
}) => {
  // const RenderAttachmentDisabled = (field) => {
  //   return (
  //     <Grid grid container>
  //       <Grid grid item xs={10}>
  //         <div
  //           style={{
  //             paddingBlock: ".7rem",
  //             paddingInline: ".6rem",
  //             border: "1px black",
  //             borderStyle: "dashed",
  //             display: "flex",
  //             justifyContent: "space-between",
  //             borderRadius: ".2rem",
  //           }}
  //         >
  //           <div>Left</div>
  //           <div>right</div>
  //         </div>
  //       </Grid>
  //       <Grid grid item xs={2} style={{ paddingBlock: ".2rem" }}>
  //         <Button
  //           variant="contained"
  //           disableElevation
  //           sx={{
  //             backgroundColor: (theme) => theme.palette.primary.main,
  //             ...removeButton,
  //           }}
  //           // onClick={() => handleRemoveFile(childName, index)}
  //           disabled={true}
  //         >
  //           <img src={RemoveIcon} alt={`Remove file`} />
  //         </Button>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const disabledPayementSection = (fieldName) => {
    let value = formValues["bank_id"];
    if (fieldName === "bank_address_id" && (value === "" || value === -1)) {
      // console.log('masuk bank dfilter')
      // * Return true for disabled field;
      return true;
    }
    // console.log('gk masuk', fieldName, value)

    value = formValues["payment_currency"];
    if (
      fieldName === "payment_received" &&
      (!value || value === "" || value === undefined)
    ) {
      //* return true for disabled / hidden field payment_receive;
      return true;
    }

    return false;
  };

  const RenderCostText = ({ label, value, varianLabel }) => (
    <React.Fragment>
      <Grid container marginY=".5rem">
        <Grid item xs={6}>
          <Typography
            variant="body1"
            fontWeight={varianLabel === "bold" ? 700 : 300}
          >
            {/* TODO: Make it dynamic depend on service choosed */}
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={"700"} textAlign={"right"}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const RenderPriceInformation = () => {
    return (
      <>
        <RenderCostText
          label={`Price ${priceInformation?.price_services_info}`}
          value={priceInformation?.price_services_info_to_rp}
        />
        <RenderCostText
          label={"Discount"}
          value={`(${priceInformation?.discount_info_to_rp})`}
        />
        <Box marginY={".5rem"}>
          <Divider />
        </Box>
        <RenderCostText
          label={"VAT"}
          value={priceInformation?.vat_info_to_rp}
        />
        <RenderCostText
          label={"Total Price"}
          value={priceInformation?.total_price_info_to_rp}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      {/* ===================== Client Section =======================*/}
      {field_drawer.client.field.map((field, index) => {
        let fieldToRender = field;
        fieldToRender.additionalProps = {};
        if (!field.additionalProps) {
          field.additionalProps = {};
        }

        if (
          invoiceType &&
          invoiceType === INVOICE_TYPE.PERFORMA_INVOICE &&
          field.name === "sales_order_id"
        ) {
          fieldToRender = extraFields;
        }
        const referalField = referal_field;

        if (
          (actionType === APPROVE_TYPE.APPROVE ||
            invoiceType === INVOICE_TYPE.PERFORMA_INVOICE) &&
          field.name !== "no_reference"
        ) {
          field.additionalProps.disabled = true;
          if (!fieldToRender.additionalProps) {
            fieldToRender.additionalProps = {};
          }
          fieldToRender.additionalProps.disabled = true;
        }

        if (field.name == "remark") {
          field.additionalProps.disabled = true;
          fieldToRender.additionalProps.disabled = true;
        }
        // console.log({ actionType, invoiceType });
        return (
          <React.Fragment key={`${field.name}-${index}`}>
            <ComponentRender
              key={fieldToRender?.name}
              errors={errors}
              formValues={formValues}
              options={options}
              handleChange={handleInputChange}
              handleBlur={handleInputBlur}
              handleAddFile={handleAddFile}
              handleRemoveFile={handleRemoveFile}
              parentField={field_drawer.client}
              field={fieldToRender}
              disabled={validateDisabled(fieldToRender?.name, actionType)}
              paymentReceive={showPaymanetReceive}
              canConvert={canConvert}
            />
            {field.name === "client_id" && invoiceType && (
              <ComponentRender
                key={referalField?.name}
                errors={errors}
                formValues={formValues}
                options={options}
                handleChange={handleInputChange}
                handleBlur={handleInputBlur}
                handleAddFile={handleAddFile}
                handleRemoveFile={handleRemoveFile}
                // parentField={field_drawer.client}
                field={referalField}
                disabled={true}
                paymentReceive={showPaymanetReceive}
                canConvert={canConvert}
              />
            )}
          </React.Fragment>
        );
      })}
      <Box marginY={".5rem"}>
        <Divider />
      </Box>

      {/* ================== SERVICE SECTION ====================*/}
      <Box sx={{ marginBottom: 2 }}>
        {/* Title */}
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          {field_drawer.service.title}
        </Typography>
        {field_drawer.service.isDisplayTitle && (
          <Typography variant="subtitle2">
            {field_drawer.service.subtitle}
          </Typography>
        )}
      </Box>
      {/* {field_drawer.service.field.map((field, index) => (
      ))} */}
      <ServiceFormComponent
        // key={`${field.name}+${index}`}
        errors={errors}
        // field={field}
        formValues={formValues}
        options={options}
        // parentField={field_drawer.service}
        handleAddSubService={handleAddSubservice}
        handleRemoveSubService={handleRemoveSubservice}
        handleInputChange={handleInputChange}
        handleInputBlur={handleInputBlur}
        actionType={actionType}
      />
      {/* =========================== ATTACHMENT SECTION ========================= */}
      <React.Fragment>
        <Box sx={{ marginBottom: 2 }}>
          {/* Title */}
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            {field_drawer.attachment.title}
          </Typography>
          {field_drawer.attachment.subtitle && (
            <Typography variant="subtitle2">
              {field_drawer.attachment.subtitle}
            </Typography>
          )}
        </Box>
        {formValues[field_drawer.attachment.field.name]?.map(
          (_, indexValue) => (
            <>
              <FilePicker
                key={indexValue}
                errors={errors}
                field={field_drawer.attachment.field}
                formValues={
                  formValues[field_drawer.attachment.field.name][indexValue]
                }
                handleInputChange={handleInputChange}
                indexFile={indexValue}
                onFileSelect={onFileSelect}
                tip={field_drawer.attachment.field.tip}
                isModeEdit={actionType !== ACTION_TYPE.ADD}
              />
            </>
          )
        )}
        {/* <RenderAttachmentDisabled /> */}
        <Box marginY={".5rem"}>
          <Divider />
        </Box>
        <RenderPriceInformation />
        <Box marginY={".5rem"}>
          <Divider />
        </Box>
      </React.Fragment>
      {/* =========================== BANK TRANSFER SECTION ========================= */}
      <Box marginBottom={".2rem"} marginY=".5rem">
        <Typography variant="h6" fontWeight={800}>
          {field_drawer.payment.title}
        </Typography>
      </Box>
      {field_drawer.payment.field.map((field) => {
        const excludeField = [
          "payment_received",
          "payment_currency",
          "payment_received_convert",
        ];
        if (
          actionType === ACTION_APPROVE.APPROVE &&
          field.name === "payment_currency"
        ) {
          return null;
        }
        if (excludeField.includes(field.name)) return null;
        return (
          <ComponentRender
            field={field}
            errors={errors}
            formValues={formValues}
            options={options}
            handleChange={handleInputChange}
            handleBlur={handleInputBlur}
            parentField={field_drawer.payment}
            disabled={
              disabledPayementSection(field.name) ||
              validateDisabled(field.name, actionType)
            }
            paymentReceive={showPaymanetReceive}
            canConvert={canConvert}
          />
        );
      })}
    </React.Fragment>
  );
  // Object.values(field_drawer).map((parentField) => (
  //   <React.Fragment key={parentField.title}>
  //     {parentField.isDisplayTitle !== false && (
  //       <Box sx={{ marginBottom: 2 }}>
  //         {/* Title */}
  //         <Typography variant="h6" sx={{ fontWeight: 800 }}>
  //           {parentField.title}
  //         </Typography>
  //         {parentField.subtitle && (
  //           <Typography variant="subtitle2">{parentField.subtitle}</Typography>
  //         )}
  //       </Box>
  //     )}
  //     {Array.isArray(parentField.field) ? (
  //       <ComponentRender
  //         errors={errors}
  //         formValues={formValues}
  //         options={options}
  //         handleChange={onChange}
  //         handleBlur={onBlur}
  //         handleAddFile={handleAddFile}
  //         handleRemoveFile={handleRemoveFile}
  //         parentField={parentField}
  //       />
  //     ) : (
  //       // parentField.field.map((field) => (
  //       //   <ComponentRender
  //       //   errors={errors}
  //       //   field={field}
  //       //   formValues={formValues}
  //       //   options={options}
  //       //   handleChange={onChange}
  //       //   handleBlur={onBlur}
  //       //   handleAddFile={handleAddFile}
  //       //   handleRemoveFile={handleRemoveFile}
  //       //   parentField={parentField}
  //       //   />
  //       //   ))
  //       <p>File field</p>
  //     )}
  //   </React.Fragment>
  // ));

  // {field_drawer.client.field.map((field) => {
  //   return (
  //     <React.Fragment key={field.name}>
  //     <Grid
  //     item
  //     xs={field.gridWidth || 12}
  //     key={field.name}
  //     sx={{ paddingTop: "unset !important" }}
  //     >
  //     <ListComponent field={field} />
  //     </Grid>
  //     </React.Fragment>
  //     );
  //   })}
};

export default RenderFormContent;
