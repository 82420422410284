import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormHelperText,
    IconButton,
    InputLabel,
    TextField,
    Tooltip,
    Typography
} from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    FacebookCircularProgress,
    borderRadius,
    btnWhite,
    dangerMainColor,
    dangerSurfaceColor,
    formGroup,
    mainColor,
    neutral60,
    neutral100,
    removeButton,
} from "../../../util/style";
import ArrowBackIcon from "./../../../assets/back.svg";
import RemoveIcon from "./../../../assets/minus.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import InfoIcon from "./../../../assets/info-circle.svg";
import EmptyAttachment from "./../../../assets/empty-attachment.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ACTION_TYPE, INPUT_TYPE } from "../../../util/function";
import { DatePickerInput, TextInput } from "../../../components/Form";
import { TextArea } from "../../../components/Form";
import axios from "../../../core/axios_config";
import { addRequest, editRequest } from "./clients/actions";
import dayjs from "dayjs";

const fields = [
    {
        name: "customer_name",
        type: INPUT_TYPE.TEXT,
        label: "Customer Name",
        defaultValue: "",
        additionalProps: {
            disabled: true,
        }
    },
    {
        name: "request_date",
        type: INPUT_TYPE.DATE,
        label: "Request Date",
        endIcon: <img src={CalendarIcon} />,
        formatDate: "DD/MM/YYYY", // Custom date format
        defaultValue: "",
        required: true,
    },
    {
        name: "return_date",
        type: INPUT_TYPE.DATE,
        label: "Return Date",
        endIcon: <img src={CalendarIcon} />,
        formatDate: "DD/MM/YYYY", // Custom date format
        defaultValue: "",
        required: true,
    },
    {
        name: "note",
        type: INPUT_TYPE.TEXTAREA,
        label: "Note (Ops)",
        defaultValue: "",
        required: false,
    },
    {
        name: "divider",
        type: INPUT_TYPE.DIVIDER,
    },
    {
        name: "service_category",
        type: "service_category",
        label: "Service Category",
        defaultValue: [
            {
                service_catergori_id_x: "",
                service_category_id: "",
                sub_category_service_id: "",
                registration_number: "",
                reference_number: "",
                applicant_id: "",
                brand_id: "",
            }
        ]
    },
    {
        name: "attach_file",
        type: "attach_file",
        label: "Attach File",
        defaultValue: [
            // {
            //     is_checked: false,
            //     desc: ""
            // },
        ]
    },
];

const AddRequest = () => {
    const { state } = useLocation();
    const [title, setTitle] = useState("Add Request");
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useSelector(state => state.auth)
    const [actionType, setActionType] = useState();
    const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState([]);
    const [selectedSubServiceCategoryId, setSelectedSubServiceCategoryId] = useState([]);
    const [lengthService, setLengthService] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = useRef(null);

    const initialFormValues = (type = "fields") => {
        return fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
            {}
        );
    };

    const initialErrors = {
        customer_name: "",
        request_date: "",
        return_date: "",
        service_category: [{
            service_category_id: null,
            sub_category_service_id: null
        }],
    };

    const [errors, setErrors] = useState(initialErrors);

    const [formValues, setFormValues] = useState(
        Object.assign({}, initialFormValues("fields"), { id: null, status: false })
    );

    const [options, setOptions] = useState({
        serviceCategories: [],
        subServiceCategories: [],
        applicantIds: [],
        brandIds: [],
        attachChecked: [],
        attachDesc: [],
    });

    const { id } = useParams();

    // useEffect
    useEffect(() => {
        let name = "";
        if (state && state.type) {
            name = `${state.type}`
        }
        if (id) {
            setTitle(`Edit Request ${name}`)
            setActionType(ACTION_TYPE.EDIT);
            fetchRequestData();
        } else {
            resetFormValues()
            setTitle(`Add Request ${name}`)
            setActionType(ACTION_TYPE.ADD);
        }
    }, [id]);

    useEffect(() => {
        if (state) {
            axios
                .get(`/archive/${state.customer['archive_id']}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    const data = response.data.data;
                    console.log("data.service_category");
                    console.log(data.service_category);
                    const sC = [];
                    data.service_category.map((s) => {
                        sC.push({
                            idx: s.id,
                            id: s.service_category_id,
                            name: s.service_category_name
                        })
                    })
                    setLengthService(data.length)
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        serviceCategories: sC,
                    }));
                })
                .catch((error) => {
                    console.error("Error fetching service categories:", error);
                });
        }
    }, [state])

    useEffect(() => {
        // Fetch sub-service category options based on selected service category
        const uniqueSelectedServiceCategoryId = [...new Set(selectedServiceCategoryId)];
        for (let i = 0; i < uniqueSelectedServiceCategoryId.length; i++) {
            const element = uniqueSelectedServiceCategoryId[i];
            axios.get(`/archive/${state.customer['archive_id']}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                const data = response.data.data.service_category.filter((item) => item.service_category_id === element);


                console.log("data")
                console.log(data)
                console.log(element)

                const sSC = [];
                // const appId = [];
                // const brId = [];

                data.map((v) => {

                    // appId.push({
                    //     id: v.applicant.id,
                    //     name: v.applicant.name,
                    // })
                    // brId.push({
                    //     id: v.brand.id,
                    //     name: v.brand.name,
                    // })
                    v.get_sub_category.map((s) => {
                        sSC.push({
                            id: s.id,
                            name: s.name
                        })
                    })
                })
                console.log("typeof(element)1");
                console.log(typeof (element));
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    subServiceCategories: {
                        ...prevOptions.subServiceCategories, // Preserve existing data
                        [element]: [...sSC],
                    },
                    // applicantIds: {
                    //     ...prevOptions.applicantIds,
                    //     [element]: [...appId],
                    // },
                    // brandIds: {
                    //     ...prevOptions.brandIds,
                    //     [element]: [...brId],
                    // }
                }));
                console.log(options);
            }).catch((e) => {
                console.log(e)
            })
        }
    }, [selectedServiceCategoryId]);

    function getRegistrationNumber(data, serviceCategoryId, subServiceCategoryId) {
        const matchingItem = data.find(item =>
            item.service_category_id === serviceCategoryId && item.sub_service_category_id === subServiceCategoryId
        );

        if (matchingItem) {
            return {
                registration_number: matchingItem.registration_number,
                reference_number: matchingItem.reference_number,
                applicant: matchingItem.applicant,
                brand: matchingItem.brand,
            };
        } else {
            return null;
        }

    }

    useEffect(() => {
        const listData = formValues.service_category.map(item => item.sub_category_service_id)

        if (listData.some(e => !!e)) {
            axios
                // .get(`/master/services-attachment/${strData.slice(0, -1)}`, {
                .get(`/archive/${state.customer['archive_id']}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    const newFormValues = { ...formValues };
                    newFormValues.attach_file = [];
                    const appId = [];
                    const brId = [];
                    newFormValues.service_category.map((v) => {
                        const data = getRegistrationNumber(response.data.data.service_category, v.service_category_id, v.sub_category_service_id)
                        v.registration_number = data.registration_number;
                        v.reference_number = data.reference_number;
                        appId.push({
                            id: data.applicant.id,
                            name: data.applicant.name,
                        })
                        brId.push({
                            id: data.brand.id,
                            name: data.brand.name,
                        })
                        console.log("v.service_category_id");
                        console.log(v.service_category_id);
                        const element = parseInt(v.service_category_id)
                        console.log("typeof(element)2");
                        console.log(typeof (element));
                        setOptions((prevOptions) => ({
                            ...prevOptions,
                            applicantIds: {
                                ...prevOptions.applicantIds,
                                [element]: [...appId],
                            },
                            brandIds: {
                                ...prevOptions.brandIds,
                                [element]: [...brId],
                            }
                        }))
                    })
                    for (const i of response.data.data.attachment_list) {
                        if (i.is_checked) {
                            newFormValues.attach_file.push({
                                id_attachment: i.id_attachment,
                                services_category_id: i.services_category_id,
                                sub_services_category_id: i.sub_services_category_id,
                                is_checked: i.is_checked,
                                desc: i.desc,
                                status_loan: i.status_loan,
                            })
                        }
                    }
                    setFormValues(newFormValues)
                })
                .catch((error) => {
                    console.error("Error fetching sub-service categories:", error);
                });
        }
    }, [selectedSubServiceCategoryId || selectedServiceCategoryId]);
    // }, [formValues]);

    // handle
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value);
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleInputBlur = (e) => {
        const { name } = e.target;
        validateField(name);
    };

    const handleSettingChange = (serviceIndex, field, value, value_x) => {

        const newFormValues = { ...formValues };
        const service = newFormValues.service_category[serviceIndex];
        console.log("handleSettingChange");
        console.log(value_x);
        console.log(value);
        // fetchAttachmentData()
        if (field === "service_category_id") {
            if (service.service_category_id !== value) {
                const newSelectedSubServiceCategoryId = [...selectedSubServiceCategoryId];
                service.sub_category_service_id = '';
                service.reference_number = '';
                service.registration_number = '';
                service.applicant_id = '';
                service.brand_id = '';
                newSelectedSubServiceCategoryId.splice(serviceIndex, 1);
                setSelectedSubServiceCategoryId(newSelectedSubServiceCategoryId);
            }
            service.service_category_id = value;
            service.service_category_id_x = value_x;

            // Assuming `value` is the ID of the selected service category
            setSelectedServiceCategoryId((prevIds) => [...prevIds, value]);
        } else if (field === "registration_number") {
            service.registration_number = value;
        } else if (field === "reference_number") {
            service.reference_number = value;
        } else {
            service[field] = value;
            if (field === "sub_category_service_id") {
                setSelectedSubServiceCategoryId((prevIds) => [...prevIds, value])
            }
        }
        validateField(field, serviceIndex);
        setFormValues(newFormValues);
    };

    const handleAttachChange = (attachIndex, field, value) => {
        const newFormValues = { ...formValues };
        const attach = newFormValues.attach_file[attachIndex];
        attach[field] = value;
        setFormValues(newFormValues);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            let request_date = new Date(formValues.request_date);
            let request_day = request_date.getDate() < 10 ? `0${request_date.getDate()}` : `${request_date.getDate()}`;
            let request_month = (request_date.getMonth() + 1) < 10 ? `0${request_date.getMonth() + 1}` : `${request_date.getMonth() + 1}`;
            let request_year = request_date.getFullYear();
            formValues.request_date = `${request_year}-${request_month}-${request_day}`;
            let return_date = new Date(formValues.return_date);
            let return_day = return_date.getDate() < 10 ? `0${return_date.getDate()}` : `${return_date.getDate()}`;
            let return_month = (return_date.getMonth() + 1) < 10 ? `0${return_date.getMonth() + 1}` : `${return_date.getMonth() + 1}`;
            let return_year = return_date.getFullYear();
            formValues.return_date = `${return_year}-${return_month}-${return_day}`;
            console.log("formValues");
            console.log(formValues);
            navigate(-1);
            if (actionType === ACTION_TYPE.EDIT) {
                dispatch(editRequest(token, formValues, id));
            } else {
                dispatch(addRequest(token, formValues));
            }
        }

    }

    const resetFormValues = () => {
        let date = new Date();
        let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
        let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
        let year = date.getFullYear();
        setFormValues(Object.assign({}, {
            customer_id: state ? state.customer['customer_id'] : "",
            customer_name: state ? state.customer['customer_name'] : "",
            archive_id: state ? state.customer['archive_id'] : "",
            request_date: `${year}-${month}-${day}`,
            return_date: "",
            note: "",
            service_category: [
                {
                    service_category_id_x: "",
                    service_category_id: "",
                    sub_category_service_id: "",
                    applicant_id: "",
                    brand_id: "",
                    registration_number: "",
                    reference_number: "",
                }
            ],
            attach_file: [],
        }));
    }

    const fetchRequestData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/requests/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            let data = response.data.data;
            const request_date = dayjs(data.request_date).isValid() ? dayjs(data.request_date) : dayjs(data.request_date).format("DD/MM/YYYY");
            const return_date = dayjs(data.return_date).isValid() ? dayjs(data.return_date) : dayjs(data.return_date).format("DD/MM/YYYY");
            let service_category = [];
            let attach_file = [];
            console.log("data.get_archive_service");
            console.log(data.get_archive_service);
            for (let i of data.get_archive_service) {
                service_category.push({
                    service_category_id: i.get_service_category.id,
                    sub_category_service_id: i.get_sub_service_category.id,
                    applicant_id: i.applicant_id,
                    brand_id: i.brand_id,
                });
                setSelectedServiceCategoryId((prevIds) => [...prevIds, i.service_category_id]);
            }
            for (let i of data.get_attachment) {
                attach_file.push({
                    id_attachment: i.id_attachment,
                    services_category_id: i.services_category_id,
                    sub_services_category_id: i.sub_services_category_id,
                    is_checked: i.is_checked,
                    desc: i.desc,
                    status_loan: i.status_loan,
                });
            }
            const newErrors = { ...errors };
            data.get_archive_service.forEach((service, serviceIndex) => {
                newErrors.service_category[serviceIndex] = {
                    service_category_id: null,
                    sub_category_service_id: null,
                    applicant_id: null,
                    brand_id: null,
                    registration_number: null,
                    reference_number: null,
                }
            })
            setErrors(newErrors);
            console.log(selectedServiceCategoryId);
            setFormValues((prevValues) => ({
                ...prevValues,
                ['id']: id,
                ['request_date']: request_date,
                ['return_date']: return_date,
                ['note']: data.note,
                ['customer_id']: data.customer_id,
                ['customer_name']: data.customer_name,
                ['service_category']: service_category,
                ['attach_file']: attach_file,
            }));

        } catch (error) {
            console.error("Error fetching archive data:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const validateField = (fieldName, serviceIndex) => {
        const newErrors = { ...errors };
        const serviceErr = newErrors.service_category[serviceIndex]
        const formValuesService = formValues.service_category[serviceIndex];

        if (fieldName === "customer_name") {
            newErrors.customer_id = (!formValues.customer_name)
                ? "Customer Name is required"
                : null
        }

        if (fieldName === "request_date") {
            newErrors.request_date = !formValues.request_date
                ? "Request Date is required"
                : null
        }
        if (fieldName === "return_date") {
            newErrors.return_date = !formValues.return_date
                ? "Return Date is required"
                : null
        }

        if (fieldName === "service_category_id") {
            if (!formValuesService.service_category_id || formValues.service_category_id === -1) {
                serviceErr.service_category_id = "Service Category is required";
            } else {
                serviceErr.service_category_id = null;
            }
        }

        if (fieldName === "sub_category_service_id") {
            if (!formValuesService.sub_category_service_id || formValues.sub_category_service_id === -1) {
                serviceErr.sub_category_service_id = "Sub Service Category is required";
            } else {
                serviceErr.sub_category_service_id = null;
            }
        }

        setErrors(newErrors);
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };

        formValues.service_category.forEach((service, serviceIndex) => {
            const serviceCategoryErr = newErrors.service_category[serviceIndex];
            console.log(service.service_category_id);
            if (!service.service_category_id || service.service_category_id === -1) {
                serviceCategoryErr.service_category_id = "Service Category is required";
                valid = false;
            } else {
                serviceCategoryErr.service_category_id = null
            }
            if (!service.sub_category_service_id || service.sub_category_service_id === -1) {
                serviceCategoryErr.sub_category_service_id = "Sub Service Category is required";
                valid = false;
            } else {
                serviceCategoryErr.sub_category_service_id = null
            }
            if (!service.applicant_id || service.applicant_id === -1) {
                serviceCategoryErr.applicant_id = "Applicant is required";
                valid = false;
            } else {
                serviceCategoryErr.applicant_id = null
            }
            if (!service.brand_id || service.brand_id === -1) {
                serviceCategoryErr.brand_id = "Brand is required";
                valid = false;
            } else {
                serviceCategoryErr.brand_id = null
            }
            if (!service.registration_number) {
                serviceCategoryErr.registration_number = "Registration Number is required";
                valid = false;
            } else {
                serviceCategoryErr.registration_number = null
            }
            if (!service.reference_number) {
                serviceCategoryErr.reference_number = "Reference Number is required";
                valid = false;
            } else {
                serviceCategoryErr.reference_number = null
            }
        });

        if (!formValues.customer_name) {
            newErrors.customer_name = "Customer Name is required";
            valid = false;
        } else {
            newErrors.customer_name = null;
        }
        if (!formValues.request_date) {
            newErrors.request_date = "Request Date is required";
            valid = false;
        } else {
            newErrors.request_date = null;
        }
        if (!formValues.return_date) {
            newErrors.return_date = "Return Date is required";
            valid = false;
        } else {
            newErrors.return_date = null;
        }

        setErrors(newErrors);
        return valid;
    }

    const addService = () => {
        if (formValues.service_category.length < options.serviceCategories.length) {
            const newFormValues = { ...formValues };
            newFormValues.service_category.push({
                service_category_id_x: "",
                service_category_id: "",
                sub_category_service_id: "",
                applicant_id: "",
                brand_id: "",
                registration_number: "",
                reference_number: "",
            });
            setFormValues(newFormValues);
            setErrors((prevErrors) => {
                const updateService = [...prevErrors.service_category];
                updateService.push({
                    service_category_id: null,
                    sub_category_service_id: null,
                });

                return {
                    ...prevErrors,
                    service_category: updateService,
                };
            });
        }
    }

    const removeService = (serviceIndex) => {
        const newFormValues = { ...formValues }; // Use formValues, not fields
        newFormValues.service_category.splice(serviceIndex, 1);
        setFormValues(newFormValues);
    };

    const handleNameServiceCategory = (id) => {
        const data = options.serviceCategories;
        if (data) {
            const item = data.find(item => item.id === id);
            return item ? item.name : null;
        }
        return null
    }

    const handleNameSubServiceCategory = (id, idSub) => {
        const data = options.subServiceCategories[id];
        if (data) {
            const item = data.find(item => item.id === idSub);
            return item ? item.name : null;
        }
        return null
    }

    // render
    const renderFormContent = () => {
        return (
            <Box display="flex" sx={{ gap: "1.5rem" }} style={{ overflow: "auto", maxHeight: "480px" }}>
                <Box flex="1" style={{ overflow: "auto" }}>
                    {
                        fields.map((field) => {
                            const fieldOptions = Array.isArray(options[field.name])
                                ? [...options[field.name]]
                                : [];
                            switch (field.type) {
                                case INPUT_TYPE.TEXT:
                                    return (
                                        <TextInput
                                            key={field.name}
                                            field={field}
                                            formValues={formValues}
                                            errors={errors}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                        />
                                    );


                                default:
                                    return null;
                            }
                        })
                    }
                    <Box display="flex">
                        {

                            fields.filter(field => field.type === INPUT_TYPE.DATE).map((field, index) => {
                                switch (field.type) {
                                    case INPUT_TYPE.DATE:
                                        return (
                                            <Box flex="1" sx={{ marginRight: index === 1 ? 0 : 1 }}>
                                                <DatePickerInput
                                                    key={field.name}
                                                    field={field}
                                                    formValues={formValues}
                                                    errors={errors}
                                                    showError={false}
                                                    handleInputChange={handleInputChange}
                                                    handleInputBlur={handleInputBlur}
                                                />
                                            </Box>
                                        );
                                    default:
                                        return null;
                                }
                            })
                        }
                    </Box>
                    {
                        fields.map((field) => {
                            const fieldOptions = Array.isArray(options[field.name])
                                ? [...options[field.name]]
                                : [];
                            const len = fields.filter(field => field.type === INPUT_TYPE.DATE);


                            switch (field.type) {
                                case INPUT_TYPE.TEXTAREA:
                                    return (
                                        <TextArea
                                            key={field.name}
                                            field={field}
                                            formValues={formValues}
                                            errors={errors}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                        />
                                    );
                                case INPUT_TYPE.DIVIDER:
                                    return (
                                        <Divider />
                                    );
                                default:
                                    return null;
                            }
                        })
                    }
                    {
                        fields.map((field) => {
                            if (field.name === "service_category") {
                                return (
                                    <div key={field.name} style={{ marginTop: 16, marginBottom: 16 }}>
                                        <Typography variant="h6">
                                            Service
                                        </Typography>
                                        <Typography sx={{ marginBottom: 1.5 }}>
                                            Decide what customer service you want to request
                                        </Typography>
                                        {
                                            formValues.service_category.map((service, serviceIndex) => {
                                                const otherServiceIds = formValues.service_category
                                                    .filter((_, index) => index !== serviceIndex)
                                                    .map((otherService) => otherService.service_category_id_x);
                                                const otherSubServiceIds = formValues.service_category
                                                    .filter((_, index) => index !== serviceIndex)
                                                    .map((otherService) => otherService.sub_category_service_id);
                                                const otherApplicantIds = formValues.service_category
                                                    .filter((_, index) => index !== serviceIndex)
                                                    .map((otherService) => otherService.applicant_id);
                                                const otherBrandIds = formValues.service_category
                                                    .filter((_, index) => index !== serviceIndex)
                                                    .map((otherService) => otherService.brand_id);
                                                // const otherCupboardIds = formValues.service_category
                                                //     .filter((_, index) => index !== serviceIndex)
                                                //     .map((otherService) => otherService.cupboard_id);
                                                // console.log("otherServiceIds");
                                                // console.log(otherServiceIds);
                                                // console.log(formValues.service_category);

                                                const fieldOptions = {
                                                    serviceCategories: [
                                                        ...(options.serviceCategories || []).filter((category) => !otherServiceIds.includes(category.idx)),
                                                    ],
                                                    applicantIds: [
                                                        ...(options.applicantIds[service.service_category_id] || []).filter((category) => !otherApplicantIds.includes(category.id)),
                                                    ],
                                                    brandIds: [
                                                        ...(options.brandIds[service.service_category_id] || []).filter((category) => !otherBrandIds.includes(category.id)),
                                                    ],
                                                    // cupboardIds: [
                                                    //     ...(options.cupboardIds || []),
                                                    // ],
                                                    subServiceCategories: [
                                                        ...(options.subServiceCategories[service.service_category_id] || []).filter((category) => !otherSubServiceIds.includes(category.id)),
                                                    ],
                                                };

                                                // debugger;
                                                const serviceErrors =
                                                    errors.service_category[serviceIndex] || {};
                                                return (
                                                    <div key={serviceIndex}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Box style={{ flex: 11 }}>
                                                                <InputLabel>Service Category</InputLabel>
                                                                <Autocomplete
                                                                    fullWidth
                                                                    size="small"
                                                                    options={fieldOptions["serviceCategories"]}
                                                                    getOptionLabel={(category) => category.name}
                                                                    value={
                                                                        fieldOptions["serviceCategories"].find(
                                                                            (option) => option.id === service.service_category_id
                                                                        ) || null
                                                                    }
                                                                    onChange={(_, newValue) =>
                                                                        handleSettingChange(
                                                                            serviceIndex,
                                                                            "service_category_id",
                                                                            newValue?.id || null,
                                                                            newValue?.idx || null
                                                                        )
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            error={serviceErrors.service_category_id}
                                                                            variant="outlined"
                                                                            placeholder={`Select Service Category `}
                                                                            sx={{
                                                                                ...formGroup,
                                                                                marginBottom: "0.2rem",
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Box>
                                                            <Box style={{ flex: 1, alignItems: "center" }}>
                                                                <InputLabel>&nbsp;</InputLabel>
                                                                <Button
                                                                    variant="contained"
                                                                    disableElevation
                                                                    sx={{
                                                                        height: "100%",
                                                                        backgroundColor: formValues.service_category.length == 1 ? `${dangerSurfaceColor} !important` : `${dangerMainColor} !important`,
                                                                        ...removeButton
                                                                    }}
                                                                    onClick={() =>
                                                                        removeService(serviceIndex)
                                                                    }
                                                                    disabled={formValues.service_category.length == 1}
                                                                >
                                                                    <img src={RemoveIcon} />
                                                                </Button>
                                                            </Box>
                                                        </div>
                                                        {/* {serviceErrors.service_category_id && (
                                                            <FormHelperText
                                                                error
                                                                sx={{
                                                                    color: "#fb2525",
                                                                    fontSize: "1rem",
                                                                    margin: "0.3rem 0 0",
                                                                }}
                                                            >
                                                                {serviceErrors.service_category_id}
                                                            </FormHelperText>
                                                        )} */}
                                                        {
                                                            formValues.service_category[serviceIndex].service_category_id ?
                                                                <>
                                                                    <InputLabel>Sub Category Service</InputLabel>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        size="small"
                                                                        options={fieldOptions["subServiceCategories"]}
                                                                        getOptionLabel={(option) => option.name}
                                                                        value={
                                                                            fieldOptions["subServiceCategories"].find(
                                                                                (option) => option.id === service.sub_category_service_id
                                                                            ) || null
                                                                        }
                                                                        onChange={(_, newValues) =>
                                                                            handleSettingChange(
                                                                                serviceIndex,
                                                                                "sub_category_service_id",
                                                                                newValues?.id || null
                                                                            )
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                error={serviceErrors.sub_category_service_id}
                                                                                placeholder={`Select Sub Category Service`}
                                                                                sx={{ ...formGroup, marginBottom: "0.2rem" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <InputLabel>Registration Number</InputLabel>
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder={`Input Registration Number`}
                                                                        value={service["registration_number"] || null}
                                                                        margin="normal"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        error={serviceErrors.registration_number}
                                                                        sx={{ ...formGroup }}
                                                                        onChange={(event) =>
                                                                            handleSettingChange(
                                                                                serviceIndex,
                                                                                "registration_number",
                                                                                event.target.value || null
                                                                            )
                                                                        }
                                                                    />
                                                                    <InputLabel>Reference Number</InputLabel>
                                                                    <TextField
                                                                        fullWidth
                                                                        placeholder={`Input Reference Number`}
                                                                        value={service["reference_number"] || null}
                                                                        margin="normal"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        error={serviceErrors.reference_number}
                                                                        sx={{ ...formGroup }}
                                                                        onChange={(event) =>
                                                                            handleSettingChange(
                                                                                serviceIndex,
                                                                                "reference_number",
                                                                                event.target.value || null
                                                                            )
                                                                        }
                                                                    />
                                                                    <InputLabel>Brand</InputLabel>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        size="small"
                                                                        options={fieldOptions["brandIds"]}
                                                                        getOptionLabel={(value) => value.name}
                                                                        value={
                                                                            fieldOptions["brandIds"].find(
                                                                                (option) => option.id === service.brand_id
                                                                            ) || null
                                                                        }
                                                                        onChange={(_, newValue) =>
                                                                            handleSettingChange(
                                                                                serviceIndex,
                                                                                "brand_id",
                                                                                newValue?.id || null
                                                                            )
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                error={serviceErrors.brand_id}
                                                                                variant="outlined"
                                                                                placeholder={`Select Brand`}
                                                                                sx={{
                                                                                    ...formGroup,
                                                                                    marginBottom: "0.2rem",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <InputLabel>Applicant</InputLabel>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        size="small"
                                                                        options={fieldOptions["applicantIds"]}
                                                                        getOptionLabel={(value) => value.name}
                                                                        value={
                                                                            fieldOptions["applicantIds"].find(
                                                                                (option) => option.id === service.applicant_id
                                                                            ) || null
                                                                        }
                                                                        onChange={(_, newValue) =>
                                                                            handleSettingChange(
                                                                                serviceIndex,
                                                                                "applicant_id",
                                                                                newValue?.id || null
                                                                            )
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                error={serviceErrors.applicant_id}
                                                                                variant="outlined"
                                                                                placeholder={`Select Applicant`}
                                                                                sx={{
                                                                                    ...formGroup,
                                                                                    marginBottom: "0.2rem",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {/* {serviceErrors.sub_category_service_id && (
                                                                        <FormHelperText
                                                                            error
                                                                            sx={{
                                                                                color: "#fb2525",
                                                                                fontSize: "1rem",
                                                                                margin: "0.3rem 0 0",
                                                                            }}
                                                                        >
                                                                            {serviceErrors.sub_category_service_id}
                                                                        </FormHelperText>
                                                                    )} */}
                                                                </>
                                                                : <></>
                                                        }

                                                    </div>
                                                );
                                            })
                                        }
                                        {
                                            (formValues.service_category.length < options.serviceCategories.length) ?
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: (theme) => theme.palette.primary.main,
                                                        cursor: "pointer",
                                                        textAlign: "right",
                                                        marginBottom: "150px",
                                                    }}
                                                    onClick={addService}
                                                >
                                                    Add Another Service
                                                </Typography> : <Box sx={{ marginBottom: "300px" }} />
                                        }
                                    </div>
                                );
                            }
                        })
                    }
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex="1" style={{ overflow: "auto" }}>
                    <Box flex="1" sx={{ overflow: "auto", maxHeight: "56vh" }}>

                        {
                            fields.map((field) => {
                                if (field.name === "attach_file") {
                                    return (
                                        <div key={field.name} style={{ marginTop: 16, marginBottom: 16 }}>
                                            <Typography variant="h6">
                                                Attachment File List
                                            </Typography>
                                            <Typography >
                                                Check the list of file attachments that you want to request from the specified service
                                            </Typography>
                                            {
                                                formValues.attach_file.length === 0 ?
                                                    <>
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            flexDirection="column"
                                                            height="50vh" >
                                                            <img src={EmptyAttachment} alt="Centered Image" style={{ maxWidth: '100%' }} />
                                                            <Typography sx={{ color: "#616161", fontStyle: "italic" }} >
                                                                Define customer service first
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                    : <></>
                                            }
                                            {
                                                formValues.service_category.map((service, serviceIndex) => (
                                                    <>
                                                        <Box>
                                                            <Box sx={{ display: "flex", marginY: "4px" }}>
                                                                <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${serviceIndex + 1}`}</Typography></Avatar>
                                                                <Typography> {` ${handleNameServiceCategory(service.service_category_id)} - ${handleNameSubServiceCategory(service.service_category_id, service.sub_category_service_id)}`}</Typography>
                                                            </Box>
                                                            {
                                                                formValues.attach_file.map((attach, attachIndex) => (
                                                                    attach.services_category_id === service.service_category_id && attach.sub_services_category_id === service.sub_category_service_id && (
                                                                        <div key={attachIndex} style={{ display: "flex", alignItems: "center" }}>
                                                                            <Box>
                                                                                <Checkbox
                                                                                    checked={attach.status_loan ? false : attach.is_checked}
                                                                                    sx={{ paddingLeft: 0 }}
                                                                                    disabled={attach.status_loan}
                                                                                    // color={neutral60}
                                                                                    onChange={(e) =>
                                                                                        handleAttachChange(
                                                                                            attachIndex,
                                                                                            "is_checked",
                                                                                            e.target.checked
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <Box sx={{ flex: 1 }} >
                                                                                <Typography sx={{ color: attach.status_loan ? neutral60 : neutral100 }}>
                                                                                    {attach.desc}
                                                                                </Typography>
                                                                            </Box>
                                                                            {
                                                                                attach.status_loan && (
                                                                                    <Box >
                                                                                        <Tooltip placement="top" title={
                                                                                            <Typography sx={{ fontSize: 14 }}>This attached file is being borrowed</Typography>
                                                                                        } sx={{ margin: 0, padding: 0 }}>
                                                                                            <IconButton aria-label="Image">
                                                                                                <img src={InfoIcon} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                ))
                                                            }
                                                        </Box>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    );
                                }


                            })
                        }
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit"
                // onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>
        );
    }

    return (
        <Card
            style={{
                marginTop: "1rem",
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
                height: "81vh",
                position: "relative",
            }}
        >
            <CardHeader
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "1.4rem",
                            fontWeight: 800,
                            paddingLeft: "1rem",
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            sx={{ marginRight: ".5rem" }}
                        >
                            <img src={ArrowBackIcon} />
                        </IconButton>
                        {title}
                    </div>
                }
            />
            <Divider />
            {
                isLoading ? <FacebookCircularProgress /> :
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <CardContent sx={{ padding: "1.7rem" }}>
                            {renderFormContent()}
                        </CardContent>
                        <CardActions
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                width: "100%",
                                justifyContent: "flex-end",
                                background: "white",
                                padding: "20px",
                            }}
                        >
                            {renderActionButtons()}
                        </CardActions>
                    </form>
            }
        </Card>
    )
}
export default AddRequest;