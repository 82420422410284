import React, { useEffect, useState } from "react";
import {
  createTheme,
  Toolbar,
  IconButton,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  MenuItem,
  ThemeProvider,
  Chip,
  Box,
  Divider,
  Typography,
  InputLabel,
  Modal,
  FormHelperText,
} from "@mui/material";
import addIcon from "./../../../assets/add.png";
import editIcon from "./../../../assets/edit.png";
import deleteIcon from "./../../../assets/delete.png";
import RemoveIcon from "./../../../assets/minus.svg";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  mainColor,
  surfaceColor,
  dangerMainColor,
  dangerSurfaceColor,
  defaultStylePage,
  btnWhite,
  formGroup,
} from "../../../util/style";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCupboard,
  editCupboard,
  addCupboard,
  deleteCupboard,
} from "./clients/actions";
import DataTable from "../../../components/Table";
import { ACTION_TYPE, INPUT_TYPE, SELECT_OPTION } from "../../../util/function";
import LeftDrawer from "../../../components/LeftDrawer";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import Form, {
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../../components/Form";
import axios from "../../../core/axios_config";
import ConfirmDelete from "../../../components/ConfirmDelete";
import { useTheme } from "@mui/styles";
import { RunModal } from "../../../util/global_state";

const columns = [
  {
    field: "cupboard_name",
    headerName: "Cupboard Name",
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "cupboard_description",
    headerName: "Cupboard Description",
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "detail_rack",
    headerName: "Total Rack Cupboard",
    flex: 1,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.row.detail_rack.length + " Rack";
    },
  },
];

const fields = [
  {
    name: "cupboard_name",
    type: INPUT_TYPE.TEXT,
    label: "Cupboard Name",
    defaultValue: "",
    required: true,
  },
  {
    name: "cupboard_description",
    type: INPUT_TYPE.TEXT,
    label: "Cupboard Description (Ops)",
    defaultValue: "",
    required: false,
  },
  {
    name: "divider",
    type: INPUT_TYPE.DIVIDER,
  },
  {
    name: "detail_rack",
    type: "detailRacks",
    label: "Detail Racks",
    defaultValue: [{ rack_number: "", rack_description: "" }],
  },
];

const Cupboard = () => {
  const theme = useTheme();
  const useStyles = defaultStylePage;

  const { token } = useSelector((state) => state.auth);
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [cupboardDeleted, setCupboardDeleted] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [formDrawer, setFormDrawer] = useState(true);
  const [title, setTitle] = useState("Add New Cupboard");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();
  const initialFormValues = (type = "fields") => {
    return fields.reduce(
      (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
      {}
    );
  };
  const initialErrors = {
    cupboard_name: "",
    detail_rack: [{ rack_number: null }],
  };
  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), {
      id: null,
      permissions: [],
    })
  );
  const [errors, setErrors] = useState(initialErrors);

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name, value");

    if (name === "id") {
      // Update email and position based on user and id
      const userData = await fetchUserData(value);

      if (userData) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
          email: userData.email,
          position: userData.position?.id || -1,
        }));
      }
    } else {
      // For other fields, just update the formValues as usual
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleDetailRackChange = (idx, field, value) => {
    const newFormValues = { ...formValues };
    const detailRacks = newFormValues.detail_rack[idx];
    // debugger;
    if (field === "rack_number") {
      detailRacks.rack_number = value;
    } else if (field === "rack_description") {
      detailRacks[field] = value; // value should be an array of selected IDs
    } else {
      detailRacks[field] = value;
    }

    validateField(field, idx);
    setFormValues(newFormValues);
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateField = (fieldName, rackIndex) => {
    const newErrors = { ...errors };
    const detailRackErr = newErrors.detail_rack[rackIndex];
    const formValuesRack = formValues.detail_rack[rackIndex];

    if (fieldName === "cupboard_name") {
      newErrors.cupboard_name = !formValues.cupboard_name
        ? "Please enter a cupboard name"
        : null;
    }

    if (fieldName === "rack_number") {
      if (!formValuesRack.rack_number) {
        detailRackErr.rack_number = "Please enter rack number";
      } else {
        detailRackErr.rack_number = null;
      }
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    formValues.detail_rack.forEach((rack, rackIndex) => {
      const detailRackErr = newErrors.detail_rack[rackIndex];
      console.log(rackIndex);
      if (!rack.rack_number) {
        detailRackErr.rack_number = "Please enter rack number";
        valid = false;
      } else {
        detailRackErr.rack_number = null;
      }
    });

    if (!formValues.cupboard_name) {
      newErrors.cupboard_name = "Please enter a cupboard name";
      valid = false;
    } else {
      newErrors.cupboard_name = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleAddRow = () => {
    dispatch(addCupboard(token, formValues));
  };

  const handleEditRow = () => {
    dispatch(editCupboard(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteCupboard(token, { data: { ids } }, true));
  };

  const fetchUserData = async (id) => {
    try {
      const response = await axios.get(`/cupboard/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.cupboards);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    console.log(token);
    if (isActive === true) {
      dispatch(getCupboard({ token, limit, search, sortDirection, sortBy }));
    }
  }, [isActive]);
  // useEffect(() => { const a = rows; debugger }, [rows])

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const ii = token;
    dispatch(getCupboard({ token, limit, search, sortDirection, sortBy }));
  }, [token]);

  // useEffect(() => { const foras = formValues; debugger }, [formValues])
  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    dispatch(
      getCupboard({
        token,
        page,
        limit,
        search,
        sortDirection,
        sortBy,
      })
    );
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getCupboard({
        token,
        page,
        limit,
        search,
        sortDirection,
        sortBy,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getCupboard({
        token,
        limit,
        search,
        sortDirection,
        sortBy,
      })
    );
  }

  function _search() {
    dispatch(
      getCupboard({
        token,
        limit,
        search,
        sortDirection,
        sortBy,
      })
    );
  }

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(0);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      console.log(2);
      const isValid = validateForm();
      if (isValid) {
        console.log(3);
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          console.log(4);
          handleAddRow();
          handleCloseFormModal(true);
        }
        console.log(5);
        resetFormValues();
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Cupboard");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setCupboardDeleted(data.map((row) => row.cupboard_name).join(", "));
      setFormDrawer(false);
      setFormModal(true);
    } else {
      setErrors(initialErrors);
      setFormDrawer(true);
      setFormModal(true);
      if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
        setTitle("Edit Cupboard");
        setFormValuesFromSelectedData();
      } else {
        setTitle("Add New Cupboard");
      }
    }
  };

  const setFormValuesFromSelectedData = () => {
    axios
      .get(`cupboard/${selectedData[0]}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        console.log(response.data.data);
        const data = response.data.data;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          ["id"]: data["id"],
          ["cupboard_name"]: data["cupboard_name"],
          ["cupboard_description"]: data["cupboard_description"],
          ["detail_rack"]: data["detail_rack"],
        }));
        const newErrors = { ...errors };
        newErrors.cupboard_name = "";
        data["detail_rack"].forEach((rack, rackIndex) => {
          newErrors.detail_rack[rackIndex] = {
            rack_number: null,
            rack_description: null,
          };
        });
        setErrors(newErrors);
      });
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (true) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    // setSelectedData([]); //buat disabled button toolbar
    resetFormValues();
  };

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
        cupboard_name: "",
        cupboard_description: "",
        detail_rack: [{ rack_number: "", rack_description: "" }],
      })
    );
  };

  const addRack = () => {
    const newFormValues = { ...formValues };
    newFormValues.detail_rack.push({
      rack_number: "",
      rack_description: "",
    });
    setFormValues(newFormValues);
    setErrors((prevErrors) => {
      const updateRacks = [...prevErrors.detail_rack];
      updateRacks.push({
        rack_number: null,
        rack_description: null,
      });

      return {
        ...prevErrors,
        detail_rack: updateRacks,
      };
    });
  };

  const removeRack = (rackIndex) => {
    const newFormValues = { ...formValues }; // Use formValues, not fields
    newFormValues.detail_rack.splice(rackIndex, 1);
    setFormValues(newFormValues);
  };

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <>
          {fields.map((field) => {
            if (actionType === ACTION_TYPE.EDITED && field.name === "id")
              field.additionalProps = { disabled: true };
            if (actionType === ACTION_TYPE.ADD && field.name === "id")
              field.additionalProps = { disabled: false };

            const fieldOptions = Array.isArray(options[field.name])
              ? [
                  field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    // name: `Select ${field.label}`,
                    name: `Choose Option`,
                  },
                  ...options[field.name],
                ]
              : [];

            if (field.name === "detail_rack") {
              return (
                <div
                  key={field.name}
                  style={{ marginTop: 16, marginBottom: 16 }}
                >
                  <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                    Setting Rack
                  </Typography>
                  {formValues.detail_rack.map((rack, rackIndex) => {
                    const rackErrors = errors.detail_rack[rackIndex] || {};
                    return (
                      <div
                        style={{ display: "flex", height: "100px" }}
                        key={rackIndex}
                      >
                        <Box style={{ flex: 2 }}>
                          <InputLabel>Rack Number</InputLabel>
                          <TextField
                            fullWidth
                            placeholder={`Input Rack Number`}
                            value={rack["rack_number"] || null}
                            margin="normal"
                            size="small"
                            variant="outlined"
                            sx={{ ...formGroup }}
                            onChange={(event) =>
                              handleDetailRackChange(
                                rackIndex,
                                "rack_number",
                                event.target.value || null
                              )
                            }
                          />
                          {rackErrors.rack_number && (
                            <FormHelperText
                              error
                              sx={{
                                color: "#fb2525",
                                fontSize: "1rem",
                                margin: "0.3rem 0 0",
                              }}
                            >
                              {rackErrors.rack_number}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box style={{ flex: 4, marginLeft: 1 }}>
                          <InputLabel>Rack Description</InputLabel>
                          <TextField
                            fullWidth
                            placeholder={`Input rack description (Ops)`}
                            value={rack["rack_description"] || null}
                            margin="normal"
                            size="small"
                            variant="outlined"
                            sx={{ ...formGroup }}
                            onChange={(event) =>
                              handleDetailRackChange(
                                rackIndex,
                                "rack_description",
                                event.target.value
                              )
                            }
                          />
                          {rackErrors.rack_description && (
                            <FormHelperText
                              error
                              sx={{
                                color: "#fb2525",
                                fontSize: "1rem",
                                margin: "0.3rem 0 0",
                              }}
                            >
                              {rackErrors.rack_description}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box style={{ flex: 1 }}>
                          <Button
                            variant="contained"
                            disableElevation
                            sx={{
                              // backgroundColor: (theme) =>
                              //     theme.palette.primary.main,
                              backgroundColor:
                                formValues.detail_rack.length == 1
                                  ? dangerSurfaceColor
                                  : dangerMainColor,
                              color: "#fff",
                              minWidth: "auto",
                              marginLeft: "7px",
                              marginBottom: 16,
                              marginTop: 3,
                              width: "40px !important",
                            }}
                            onClick={() => removeRack(rackIndex)}
                            disabled={formValues.detail_rack.length == 1}
                          >
                            <img src={RemoveIcon} />
                          </Button>
                        </Box>
                      </div>
                    );
                  })}
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                    onClick={addRack}
                  >
                    Add Another Rack
                  </Typography>
                </div>
              );
            }

            switch (field.type) {
              case INPUT_TYPE.TEXT:
                return (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.PASSWORD:
                return (
                  <PasswordInputWithValidation
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.SELECT_ONE:
                return (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                return (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE:
                return (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                return (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.DIVIDER:
                return <Divider />;
              default:
                return null;
            }
          })}
        </>
      );
    } else {
      return <ConfirmDelete userDeleted={cupboardDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Card style={{ boxShadow: "unset" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                placeholder="Search cupboard name"
                size="small" // Set the size to "small"
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
                sx={{
                  borderRadius, // Apply the desired border radius value
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
                // onClick={()=>{
                //   dispatch(RunModal("SAVED", `Testing`));
                //   dispatch(RunModal("FAILED", `Testing`));
                // }}
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Cupboard"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={page}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            sortModel={sortModel}
            search={search}
            onSortModelChange={handleSortModelChange}
            // Cupboard={Cupboard}
          />
        </CardContent>
      </Card>
      {formDrawer ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={formDrawer}
            />
          </Box>
        </LeftDrawer>
      ) : (
        <Modal open={formModal} onClose={() => handleCloseFormModal()}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
            />
          </Box>
        </Modal>
      )}
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Cupboard;
