import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILED,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILED,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_SUCCESS,

  GET_GROUP_SUCCESS,
  GET_GROUP_FAILED,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILED,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                   CLIENT                                   */
/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getClient = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  type = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "clients",
          page,
          limit,
          search,
          type: type == ACTION_TYPE.ALL_TYPE ? null : type,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getClientSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getClientSuccess([], null));
        } else {
          dispatch(getClientFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getClientFailed(error.response.data.message));
          }
        } else {
          dispatch(getClientFailed(error.message));
        }
      });
  };
};

const getClientSuccess = (data, pagination) => {
  return {
    type: GET_CLIENT_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getClientFailed = (errorMessage) => {
  return {
    type: GET_CLIENT_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addClient = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
    .post("clients", Object.assign({}, formData, {
      status: formData.status ? "active" : "inactive",
    }), {
      headers: {
        "Content-Type": "multipart/form-data", // Set the content type for file uploads
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "client"));
          dispatch(addClientSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addClientFailed(response.data.message));
        } else {
          dispatch(addClientFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addClientFailed(error.response.data.message));
          }
        } else {
          dispatch(addClientFailed(error.message));
        }
      });
  };
};

const addClientSuccess = (message) => {
  return {
    type: ADD_CLIENT_SUCCESS,
    payload: message,
  };
};

const addClientFailed = (message) => {
  return {
    type: ADD_CLIENT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editClient = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        `clients/${id}`,
        Object.assign({}, formData, {
          npwp_file: (formData.npwp_file?.size && formData.npwp_file?.filename && formData.npwp_file?.id) ? null : formData.npwp_file,
          status: formData.status ? "active" : "inactive",
        }),{
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for file uploads
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "client"));
          dispatch(editClientSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editClientFailed(response.data.message));
        } else {
          dispatch(editClientFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editClientFailed(error.response.data.message));
          }
        } else {
          dispatch(editClientFailed(error.message));
        }
      });
  };
};

const editClientSuccess = (message) => {
  return {
    type: EDIT_CLIENT_SUCCESS,
    payload: message,
  };
};

const editClientFailed = (message) => {
  return {
    type: EDIT_CLIENT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteClient = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`clients${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "client"));
          dispatch(deleteClientSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteClientFailed(response.data.message));
        } else {
          dispatch(deleteClientFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteClientFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteClientFailed(error.message));
        }
      });
  };
};

const deleteClientSuccess = (message) => {
  return {
    type: DELETE_CLIENT_SUCCESS,
    payload: message,
  };
};

const deleteClientFailed = (message) => {
  return {
    type: DELETE_CLIENT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};



/* -------------------------------------------------------------------------- */
/*                                   GROUP                                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getGroupClient = ({
  token,
}) => {
  return (dispatch) => {
    // dispatch(RequestLoading());
    axios
      .get("master/groups",
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getGroupClientSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getGroupClientSuccess([], null));
        } else {
          dispatch(getGroupClientFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getGroupClientFailed(error.response.data.message));
          }
        } else {
          dispatch(getGroupClientFailed(error.message));
        }
      });
  };
};

const getGroupClientSuccess = (data, pagination) => {
  return {
    type: GET_GROUP_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getGroupClientFailed = (errorMessage) => {
  return {
    type: GET_GROUP_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addGroupClient = (token, formData) => {
  return (dispatch) => {
    dispatch(groupClientLoading());
    axios
      .post(
        "groups",
        Object.assign({}, formData, {
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(addGroupClientSuccess(response.data.message));
          dispatch(
            getGroupClient({
              token,
            })
          );
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addGroupClientFailed(response.data.message));
        } else {
          dispatch(addGroupClientFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addGroupClientFailed(error.response.data.message));
          }
        } else {
          dispatch(addGroupClientFailed(error.message));
        }
      });
  };
};

const addGroupClientSuccess = (message) => {
  return {
    type: ADD_GROUP_SUCCESS,
    payload: message,
  };
};

const addGroupClientFailed = (message) => {
  return {
    type: ADD_GROUP_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};


const groupClientLoading = () => {
  return { type: 'GROUP_LOADING' }
}