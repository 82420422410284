"use strict";
import { INPUT_TYPE, SELECT_OPTION } from "../../../../util/function";

/**
 * @typedef {object} AdditionalPropsType
 * @property {boolean} disabled
 * @property {boolean} showLable
 * @property {boolean} showError
 * @property {boolean} readOnly
 */

/**
 * @typedef {object} FieldType
 * @property {string} name - name of field
 * @property {string} type - type field
 * @property {string} label - label on field
 * @property {string} placeholder - placeholder
 * @property {string|number|boolean} defaultValue - default value
 * @property {string} [apiEndpoint] - endpoint for get option
 * @property {string} [errorMessage] - custom error message
 * @property {string} [tip] - tip for field
 * @property {string} [reactive] - reactive to some field name
 * @property {boolean} [required] - flag for field require
 * @property {boolean} [parent] - flag form parent field in multiple value
 * @property {boolean} [hidden] - flag for hidden form when render by loop
 * @property {boolean} [multiple] - flag for multiple value
 * @property {boolean} [canChange] - flag for can chage value
 * @property {boolean} [autoFill] - flag for autoFill value
 * @property {boolean} [disabled] - flag for disabeld value
 * @property {AdditionalPropsType} [additionalProps] - object for aditional props
 * @property {Function} [validation] - custom function validation
 * @property {Array<OptionType>} [options] - custom options when cannot get from server
 *
 */

/**
 * @typedef {object} ColumnType
 * @property {string} field
 * @property {string} headerName
 * @property {number} width
 * @property {boolean} disableColumnMenu
 * @property {boolean} sortable
 * @property {Function} [valueGetter]
 * @property {Function} [renderCell]
 *
 */

/**@type {FieldType[]} */
export const fields = [
  {
    name: "category_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    label: "Category",
    apiEndpoint: "/master/asset-categories",
    required: true,
    additionalProps: {
      showError: true,
    },
  },
  {
    name: "brand_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    label: "Brand Name",
    apiEndpoint: "/master/asset-brands",
    required: true,
    additionalProps: {
      showError: true,
    },
  },
  {
    name: "name",
    type: INPUT_TYPE.TEXT,
    label: "Stock Name",
    defaultValue: "",
    required: true,
    additionalProps: {
      showError: true,
    },
  },
  {
    name: "qty",
    type: INPUT_TYPE.NUMBER,
    label: "Quantity",
    defaultValue: 0,
    required: true,
    additionalProps: {
      showError: true,
    },
  },
];

/** @type {ColumnType[]} */
export const columns = [
  {
    field: "code",
    headerName: "No. References",
    disableColumnMenu: true,
    sortable: true,
    width: 160,
  },
  {
    field: "category_name",
    headerName: "Category",
    disableColumnMenu: true,
    sortable: true,
    width: 160,
  },
  {
    field: "brand_name",
    headerName: "Brand Name",
    disableColumnMenu: true,
    sortable: true,
    width: 160,
  },
  {
    field: "name",
    headerName: "Stock Name",
    disableColumnMenu: true,
    sortable: true,
    width: 160,
  },
  {
    field: "qty",
    headerName: "Quantity",
    disableColumnMenu: true,
    sortable: true,
    width: 160,
  },
  {
    field: "created_at_formatted",
    headerName: "Create At",
    disableColumnMenu: true,
    sortable: true,
    width: 160,
  },
];
