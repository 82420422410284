import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../components/Form";
import { addUser, deleteUser, editUser, getUser } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";

const User = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  /*
  const [currentPath, setCurrentPath] = useState("");
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  useEffect(() => {
    console.log(currentPath, userAccess);
  }, [userAccess]);
  */
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New User");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [position, setPosition] = useState(ACTION_TYPE.ALL_POSITION);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "fullname",
      headerName: "Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      valueGetter: (params) => {
        return params.row.position ? params.row.position ?.name : "-";
      },
    },
    // {
    //   field: "approval",
    //   headerName: "Approval",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   sortable: true,
    //   valueGetter: (params) => {
    //     return params.row.approval ? params.row.approval?.fullname : "-";
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value}> {params.value} </StatusBadge>
      ),
    },
  ];

  const fields = [
    {
      name: "fullname",
      type: INPUT_TYPE.TEXT,
      label: "Name",
      placeholder: "Input name",
      defaultValue: "",
      required: true,
      additionalProps: {
        disabled: actionType === ACTION_TYPE.EDITED,
      },
      errorMessage: "Please enter username. Example: Rahmat Hermansyah",
    },
    {
      name: "email",
      type: INPUT_TYPE.TEXT,
      label: "Email",
      placeholder: "Input email",
      defaultValue: "",
      required: true,
      additionalProps: {
        disabled: actionType === ACTION_TYPE.EDITED,
      },
      validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      errorMessage: "Please enter email. Example: rahmat@pasificpatent.com",
    },
    {
      name: "password",
      type: INPUT_TYPE.PASSWORD,
      label: "Temporary Password",
      placeholder: "Create temporary password",
      defaultValue: "",
      required: actionType === ACTION_TYPE.ADD,
      validation: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value), // Add password validation function
      errorMessage: "Please create a temporary password",
      // errorMessage:
      //   "Password must be at least 8 characters long and contain a mix of uppercase, lowercase, numbers, and special characters",
    },
    {
      name: "position",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Position",
      placeholder: `Select position`,
      defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      apiEndpoint: "/master/positions",
      required: true,
      errorMessage: "Please select a position",
    },
    {
      name: "approval",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Approval Line",
      placeholder: "Set approval line ( Ops )",
      defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      apiEndpoint: "/master/approval",
      required: false,
    },
  ];

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.users);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });
  }, []);

  useEffect(() => {
    if (status === ACTION_TYPE.DEFAULT_STATUS) {
      setPosition(ACTION_TYPE.ALL_POSITION);
    }
  }, [status]);

  useEffect(() => {
    if (
      position !== ACTION_TYPE.DEFAULT_POSITION &&
      status !== ACTION_TYPE.DEFAULT_STATUS
    ) {

      dispatch(
        getUser({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          position,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [position, status]);

  // useEffect(() => {
  //   if (position !== ACTION_TYPE.DEFAULT_POSITION) {
  //     dispatch(
  //       getUser({
  //         token,
  //         limit,
  //         search,
  //         status,
  //         sortDirection,
  //         sortBy,
  //         position,
  //       })
  //     );
  //   } else {
  //     setSelectedData([]); //buat disabled button toolbar
  //   }
  // }, [position]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
      })
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null, status: false })
  );
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
  }, [formValues]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getUser({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          position,
        })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    for (const key in data) {
      // console.log(key, "key");
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value ?.id : value,
        }));
      }
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name, value");
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
      } else if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = errorMessage || `${field.label} is required`;
        valid = false;
      }

      if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        }
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit User");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete User");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `the user '${data.map((row) => row.fullname).join(", ")}'`
      );
    } else {
      resetFormValues();
      setTitle("Add User");
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  const handleAddRow = () => {
    dispatch(addUser(token, formValues));
  };

  const handleEditRow = () => {
    dispatch(editUser(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteUser(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <>
          {fields.map((field) => {
            let optionsData;
            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              if (isRequired) {
                optionsData = [
                  // field.required &&
                  //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  //     name: "Choose Option",
                  //   },
                  ...options[field.name],
                ];
              } else {
                optionsData = [...options[field.name]];
              }
            }

            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            switch (field.type) {
              case INPUT_TYPE.TEXT:
                return (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.PASSWORD:
                return (
                  <PasswordInputWithValidation
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
              case INPUT_TYPE.SELECT_ONE:
                return (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                return (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE:
                return (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                return (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                  />
                );
              default:
                return null;
            }
          })}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...formGroup,
              padding: "0 1rem",
            }}
          >
            <FormControlLabel
              control={
                <StatusSwitch
                  checked={formValues.status}
                  name="status"
                  onChange={handleSwitchChange}
                />
              }
              label={
                <Typography
                  variant="h7"
                  sx={{ marginLeft: 1, fontWeight: "500" }}
                >
                  {formValues.status ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </Box>
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getUser({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          position,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getUser({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        position,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getUser({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        position,
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getUser({ token, limit, search, status, sortDirection, sortBy, position })
      );
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getUser({ token, limit, search, status, sortDirection, sortBy, position })
    // );
  }

  function _position(val) {
    setPosition(val);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder="Search name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={position}
                onChange={(e) => _position(e.target.value)}
                disabled={status === ACTION_TYPE.DEFAULT_STATUS}
              >
                <MenuItem value={ACTION_TYPE.ALL_POSITION}>
                  All Position
                </MenuItem>
                {options["position"] &&
                  options["position"].map((position) => (
                    <MenuItem key={position.id} value={position.id}>
                      {position.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"User"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          // userAccess={userAccess}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default User;
