import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, GET_MENUS,GET_MENUS_LOADING } from "./state";
import { REQUEST_LOADING } from "../../../../util/global_state";

const initState = {
  user: null,
  token: null,
  menus: [],
  message: null,
  isLoading: false,
};

const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_MENUS_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
    };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        message: null,
        isLoading: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case GET_MENUS:
      return {
        ...state,
        menus: payload,
        message: null,
        isLoading: false,
      };
    case LOGOUT:
      return {
        user: null,
        token: null,
        menus: [],
        message: null,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
