import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  ADD_TRADEMARK_APPLICATION_FAIL,
  ADD_TRADEMARK_APPLICATION_SUCCESS,
  CLEAR_MESSAGE,
  DELETE_TRADEMARK_APPLICATION_FAIL,
  DELETE_TRADEMARK_APPLICATION_SUCCESS,
  EDIT_TRADEMARK_APPLICATION_FAIL,
  EDIT_TRADEMARK_APPLICATION_SUCCESS,
  GET_TRADEMARK_APPLICATION_FAIL,
  GET_TRADEMARK_APPLICATION_SUCCESS,
} from "./state";

const initialState = {
  data: null,
  isLoading: false,
  message: null,
  pagination: null,
};

const trademarkAplicationReducer = (state = initialState, action) => {
  const { type, payload, pagination } = action;
  switch (type) {
    case REQUEST_LOADING:
      return { ...state, isLoading: true };
    case GET_TRADEMARK_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        pagination: pagination,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case GET_TRADEMARK_APPLICATION_FAIL:
    case ADD_TRADEMARK_APPLICATION_SUCCESS:
    case ADD_TRADEMARK_APPLICATION_FAIL:
    case EDIT_TRADEMARK_APPLICATION_SUCCESS:
    case EDIT_TRADEMARK_APPLICATION_FAIL:
    case DELETE_TRADEMARK_APPLICATION_SUCCESS:
    case DELETE_TRADEMARK_APPLICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default trademarkAplicationReducer;
