"use strict";

import React from "react";
import { INPUT_TYPE, SELECT_OPTION } from "../../../../../util/function";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../../../util/style";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
  InputLabel,
  Checkbox,
  FormHelperText,
  Avatar,
  Grid,
  FormLabel,
} from "@mui/material";
import {
  TextInput,
  CheckboxGroupInput,
  DatePickerInput,
  FilePicker,
  PasswordInputWithValidation,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  SelectOneAutocompleteSearch,
  SelectOneCountryAutocomplete,
  TextArea,
  TimePickerInput,
} from "../../../../../components/Form";
import { ANOTHER_INPUT_TYPE } from "../../../payment-request/parts/imutable_state";
import {
  APPROVE_TYPE,
  validateDisabled,
} from "../../../proforma-invoice/parts/imutable_state";

export default function ComponentFormRender({
  field,
  errors,
  options,
  formValues,
  parentField,
  handleChange,
  handleBlur,
  canConvert,
  showPaymentConvert,
  key,
  disabled,
}) {
  if (field.hidden) {
    return null;
  }
  let fieldComponent = null;
  let optionData = [],
    optionValue = [];

  if (
    field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE &&
    (field.label === "Currency" || field.name === "payment_currency")
  ) {
    optionValue = field.options;
    optionData = field.options;
  } else if (Array.isArray(options[field.name])) {
    let isRequired = null;
    if (field.required && field.type === INPUT_TYPE.SELECT_ONE) {
      isRequired = {
        id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        name: "Choose Option",
      };
    }
    if (isRequired) {
      optionData = [...options[field.name], isRequired];
    } else {
      optionData = [...options[field.name]];
    }
    optionValue = optionData.length ? optionData : [];
  }
  const handleInputChange = (e) => {
    handleChange(e, parentField);
  };
  const handleInputBlur = (e) => {
    handleBlur(e, parentField);
  };

  const RenderSelectOneAutocomplite = () => {
    let paymentReceiveField = null;

    if (field.name === "payment_currency") {
      paymentReceiveField = parentField?.field?.find(
        (localField) => localField.name === "payment_received"
      );
    }
    switch (field.name) {
      case "payment_currency":
        return (
          <Grid container>
            <Grid item xs={2.5}>
              <SelectOneAutocomplete
                errors={errors}
                field={field}
                fieldOptions={optionValue}
                formValues={formValues}
                handleInputBlur={handleInputBlur}
                handleInputChange={handleInputChange}
                tip={field.tip}
              />
            </Grid>
            <Grid item xs={9.5} paddingLeft={".6rem"}>
              <TextInput
                tip={field.tip}
                errors={errors}
                field={paymentReceiveField}
                formValues={formValues}
                handleInputBlur={handleInputBlur}
                handleInputChange={handleInputChange}
                key={paymentReceiveField.name}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box style={{ paddingBlock: ".9rem" }}>
                {!canConvert ? null : (
                  <>
                    <input
                      style={{ width: "1.6rem", paddingInline: ".5rem" }}
                      type="checkbox"
                      id="convert"
                      name="convert_payment"
                      disabled={
                        formValues["payment_currency"] === "Rp" ||
                        !formValues["payment_currency"]
                      }
                      checked={formValues["convert_payment"]}
                      onChange={handleChange}
                    />
                    <label htmlFor="convert">convert it</label>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return (
          <SelectOneAutocomplete
            errors={errors}
            field={field}
            fieldOptions={optionValue}
            formValues={formValues}
            handleInputBlur={handleInputBlur}
            handleInputChange={handleInputChange}
            tip={field.tip}
          />
        );
    }
    // return field.name === "payment_currency" ? (
    //   <Grid container>
    //     <Grid item xs={2.5}>
    //       <SelectOneAutocomplete
    //         errors={errors}
    //         field={field}
    //         fieldOptions={optionValue}
    //         formValues={formValues}
    //         handleInputBlur={handleInputBlur}
    //         handleInputChange={handleInputChange}
    //         tip={field.tip}
    //       />
    //     </Grid>
    //     <Grid item xs={9.5} paddingLeft={".6rem"}>
    //       <TextInput
    //         tip={field.tip}
    //         errors={errors}
    //         field={paymentReceiveField}
    //         formValues={formValues}
    //         handleInputBlur={handleInputBlur}
    //         handleInputChange={handleInputChange}
    //         key={paymentReceiveField.name}
    //       />
    //     </Grid>
    //   </Grid>
    // ) : (
    //   <SelectOneAutocomplete
    //     errors={errors}
    //     field={field}
    //     fieldOptions={optionValue}
    //     formValues={formValues}
    //     handleInputBlur={handleInputBlur}
    //     handleInputChange={handleInputChange}
    //     tip={field.tip}
    //   />
    // );
  };

  const RenderInputTextDisabled = ({ field }) => {
    let valueRender = formValues[field.name];
    if (field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
      const fieldValue = options[field.name]?.find(
        (opt) => opt.id === valueRender
      );
      valueRender = fieldValue?.name;
    }
    let rightAlignField = null;
    if (field.name === "sub_total") {
      valueRender = new String("Rp ").concat(valueRender);
      rightAlignField = true;
    } else if (field.name === "discount") {
      valueRender = valueRender.padEnd(10, " ").concat("%");
      rightAlignField = true;
    }
    return rightAlignField ? (
      <Grid container style={{ paddingBlock: ".6rem" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Typography> {field.label}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "right" }}>
          <input
            disabled
            type="text"
            placeholder=""
            value={valueRender}
            style={{
              width: field.name === "discount" ? "40%" : "70%",
              paddingBlock: ".6rem",
              paddingInline: ".5rem",
            }}
          />
        </Grid>
      </Grid>
    ) : (
      <div style={{ padding: 0, margin: 0, paddingRight: ".55rem" }}>
        <p
          style={{ paddingBlock: ".4rem", marginBlock: "0", fontWeight: "500" }}
        >
          {field.label}
        </p>
        {field.name === "description" ? (
          <textarea
            readOnly
            disabled
            style={{ width: "100%", paddingBlock: ".6rem" }}
            value={valueRender}
          ></textarea>
        ) : (
          <input
            type="text"
            placeholder={field.placeholder}
            disabled
            style={{
              width: "100%",
              paddingBlock: ".6rem",
              paddingInline: ".5rem",
            }}
            value={valueRender}
          />
        )}
      </div>
    );
  };

  // if (validateDisabled(field.name, APPROVE_TYPE.APPROVE)) {
  //   return <RenderInputTextDisabled field={field} />;
  // }
  if (!field.additionalProps) {
    field.additionalProps = {};
  }
  if (disabled && field.name !== "no_reference") {
    field.additionalProps.disabled = true;
  } else {
    field.additionalProps.disabled = false;
  }

  switch (field.type) {
    case INPUT_TYPE.TEXT:
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.PHONE_NUMBER:
    case INPUT_TYPE.POINT:
    case INPUT_TYPE.DISCOUNT:
      if (
        field.name === "payment_received_convert" &&
        formValues["convert_payment"] === false
      ) {
        fieldComponent = null;
        break;
      }
      if (field.name === "sub_total" || field.name === "discount") {
        const validateWidth = (min, max) => {
          return field.name === "discount" ? min : max;
        };
        fieldComponent = (
          <Grid container>
            <Grid items xs={validateWidth(9, 8)}>
              {/* <Grid items xs={field.name === "discount" ? 9 : 8}> */}
              <Typography paddingY={".7rem"}>{field.label}</Typography>
            </Grid>
            <Grid items xs={validateWidth(3, 4)}>
              <Box flex justifyContent="end">
                <TextInput
                  errors={errors}
                  field={field}
                  formValues={formValues}
                  handleInputBlur={handleInputBlur}
                  handleInputChange={handleInputChange}
                  tip={field?.tip}
                />
              </Box>
            </Grid>
          </Grid>
        );
        break;
      }
      fieldComponent = (
        <TextInput
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.CURRENCY:
      // console.log({ showPaymentConvert });

      fieldComponent = (
        <>
          <TextInput
            key={field.name}
            field={field}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            tip={field?.tip}
          />
        </>
      );
      break;
    case INPUT_TYPE.PASSWORD:
      fieldComponent = (
        <PasswordInputWithValidation
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          tip={null}
        />
      );
      break;
    case INPUT_TYPE.TEXTAREA:
      fieldComponent = (
        <TextArea
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          placeholder={field.placeholder}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          tip={field.tip}
        />
      );
      break;
    case ANOTHER_INPUT_TYPE.FILE:
      //TODO: Improve
      fieldComponent = (
        <>File</>

        //   <Grid item xs={12} key={field.title}>
        //     {field.isDisplayTitle !== false && (
        //       <Box sx={{ marginBottom: 2 }}>
        //         {/* Title */}
        //         <Typography variant="h6" sx={{ fontWeight: 800 }}>
        //           {field.title}
        //         </Typography>
        //         {field.subtitle && (
        //           <Typography variant="subtitle2">{field.subtitle}</Typography>
        //         )}
        //       </Box>
        //     )}
        //     {formValues[childName].map((_, index) => (
        //       <div key={index}>
        //         <Grid container spacing={0.3}>
        //           <Grid item xs={11.3}>
        //             <Grid container>
        //               <Grid container>
        //                 <Grid item xs={11.3}>
        //                   <FilePicker
        //                     indexFile={index}
        //                     field={field.children[0]}
        //                     formValues={formValues[childName][index]}
        //                     errors={errors}
        //                     onFileSelect={(file) =>
        //                       handleFileSelect(file, childName, index)
        //                     }
        //                     handleInputChange={(e) =>
        //                       handleInputChange(e, field, index, childName)
        //                     }
        //                     tip={field?.tip}
        //                   />
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //           </Grid>
        //           <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
        //             <Button
        //               variant="contained"
        //               disableElevation
        //               sx={{
        //                 backgroundColor: (theme) => theme.palette.primary.main,
        //                 ...removeButton,
        //               }}
        //               onClick={() => handleRemoveFile(childName, index)}
        //               disabled={
        //                 (actionType === ACTION_TYPE.ADD &&
        //                   formValues[childName].length === 1) ||
        //                 actionType === ACTION_TYPE.EDITED
        //               }
        //             >
        //               <img src={RemoveIcon} alt={`Remove ${childName}`} />
        //             </Button>
        //           </Grid>
        //         </Grid>
        //       </div>
        //     ))}
        //     {actionType !== ACTION_TYPE.EDITED && (
        //       <Box sx={{ ...justifyContentEnd }}>
        //         <Button
        //           onClick={() => handleAddFile(childName)}
        //           disabled={actionType === ACTION_TYPE.EDITED}
        //         >
        //           <span
        //             style={{
        //               fontSize: "1.5rem",
        //               marginRight: ".6rem",
        //               display: "inline-block",
        //             }}
        //           >
        //             +
        //           </span>
        //           Add More
        //         </Button>
        //       </Box>
        //     )}
        //   </Grid>
      );
      break;
    case INPUT_TYPE.SELECT_ONE:
      fieldComponent = (
        <SelectOne
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={optionValue}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
      //TODO: Need customize
      // fieldComponent = (
      //   <SelectOneAutocomplete
      //     errors={errors}
      //     field={field}
      //     fieldOptions={fieldOptions}
      //     formValues={formValues}
      //     handleInputBlur={handleBlur}
      //     handleInputChange={handleInputChange}
      //     tip={field.tip}
      //   />
      // );
      fieldComponent = RenderSelectOneAutocomplite();
      break;
    // fieldComponent = RenderSelectOneAutocomplite(field, fieldOptions);
    case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
      fieldComponent = (
        <SelectOneCountryAutocomplete
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={optionValue}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.SELECT_MULTIPLE:
      fieldComponent = (
        <SelectMultiple
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={optionValue}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
      fieldComponent = (
        <SelectMultipleAutocomplete
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={optionValue}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.CHECKBOX:
      let renderLabel = field.label,
        vatValue = formValues["vat"],
        totalValue = formValues["sub_total"];

      if (field.name === "vat") {
        // console.log(formValues);
        renderLabel = renderLabel.concat(
          " ",
          formValues["vat_percent"] || " ",
          "%"
        );
      }
      fieldComponent = (
        <Box paddingY={".5rem"}>
          <Grid container paddingY={".25rem"}>
            <Grid item xs={1}>
              <Checkbox
                name={field.name}
                checked={false}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                id={field.name}
                disabled={field.additionalProps?.disabled}
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: ".5rem",
              }}
            >
              <FormLabel>{renderLabel}</FormLabel>
            </Grid>
            <Grid item xs={8} textAlign={"right"}>
              <Typography variant="h4" fontSize={"1rem"} fontWeight={600}>
                {/* TODO: GANTI AGAR DINAMIS SESUAI KALKULASI  */}
                {vatValue}
              </Typography>
            </Grid>
          </Grid>
          <Grid container paddingY={".25rem"}>
            <Grid item xs={3}>
              <FormLabel>Total</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" fontWeight={600} textAlign={"right"}>
                {/* TODO: GANTI AGAR DINAMIS SESUAI KALKULASI  */}
                {totalValue}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
      break;
    case INPUT_TYPE.RADIO:
      fieldComponent = (
        <RadioGroupInput
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={
            optionValue.length
              ? optionValue.filter((option) => option !== false)
              : field.options
          }
        />
      );
      break;
    case INPUT_TYPE.DATE:
      fieldComponent = (
        <DatePickerInput
          tip={field.tip}
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
        />
      );
      break;
    case INPUT_TYPE.TIME:
      fieldComponent = (
        <TimePickerInput
          tip={field.tip}
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
        />
      );
    case INPUT_TYPE.DIVIDER:
      fieldComponent = <Divider />;
    default:
      fieldComponent = null;
  }

  return <React.Fragment key={key}>{fieldComponent}</React.Fragment>;
}
