import logo from "./logo.svg";
import "./App.css";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import {
  createTheme,
  ThemeProvider,
  Grid,
  LinearProgress,
  Modal,
  Box,
} from "@mui/material";
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { routes } from "./routes";
import { useSelector } from "react-redux";
import Login from "./pages/auth/login";
import CreatePassword from "./pages/auth/create-password";
import ProtectedRoute from "./util/protected_route";
import Dashboard from "./pages/dashboard/dashboard";
import NotificationSuccess from "./components/NotificationSuccess";
import { getMenuAccessed } from "./pages/auth/clients/login/actions";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          height: "90% !important",
          border: "none",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            borderRight: "none",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1268B8",
      secondary1: "#ECF3F9",
    },
    grey: {
      50: "#F5F5F5",
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // State to track loading
  const allState = useSelector((state) => state);
  console.log(
    "----------------------------------- ALL STATE ----------------------------------",
    allState
  );

  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/login") === false && token === null) {
      navigate("/login");
    } else if (
      location.pathname.includes("/login") === true &&
      token !== null
    ) {
      dispatch(getMenuAccessed(token));
      navigate("/");
    }
  }, [token, location]);

  function ScrollToTop() {
    // Scroll to the top when the location changes
    useEffect(() => {
      window.scrollTo(0, 0);
      if (location.pathname.includes("human-resources") === false) {
        localStorage.removeItem("options_step_one");
        localStorage.removeItem("options_step_two");
        localStorage.removeItem("options_step_three");
        localStorage.removeItem("options_step_four");
      }

      if (location.pathname.includes("business-permit") === false) {
        localStorage.removeItem("business_permit_options_step_one");
        localStorage.removeItem("business_permit_options_step_two");
        localStorage.removeItem("business_permit_options_step_three");
        localStorage.removeItem("business_permit_options_step_four");
      }

    }, [location]);

    // This component doesn't render anything; it just handles scrolling
    return null;
  }

  const renderRoutes = (routes) => {
    return routes.map((route) => {
      if (!route.children) {
        return (
          <Route key={route.path} path={route.path} element={route.component} />
        );
      } else {
        return (
          <Route key={route.path} path={route.path} element={route.component}>
            {renderRoutes(route.children)}
          </Route>
        );
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/create-new-password" element={<CreatePassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={
                <>
                  {/* {loading && <LinearProgress />}{" "} */}
                  <NotificationSuccess />
                  <div style={{ paddingTop: "65px" }}></div>
                  <Navbar />
                  <Sidebar routes={routes} />
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "40px",
                    }}
                    spacing={20}
                  >
                    <Grid item xs={9.7} className="">
                      <Outlet />
                    </Grid>
                  </Grid>
                </>
              }
            />
          }
        >
          <Route index element={<Dashboard />} />
          {renderRoutes(routes)}
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
