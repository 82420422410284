import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import {
  GET_BENEFIT_DETAILS_SUCCESS,
  GET_BENEFIT_DETAILS_FAILED,
  GET_BENEFIT_SUCCESS,
  GET_BENEFIT_FAILED,
  ADD_BENEFIT_SUCCESS,
  ADD_BENEFIT_FAILED,
  EDIT_BENEFIT_SUCCESS,
  EDIT_BENEFIT_FAILED,
  DELETE_BENEFIT_FAILED,
  DELETE_BENEFIT_SUCCESS,
  ADD_PAY_BENEFIT_FAILED,
  ADD_PAY_BENEFIT_SUCCESS,
  EDIT_PAY_BENEFIT_FAILED,
  EDIT_PAY_BENEFIT_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getBenefits = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  period = null,
  typeBenefit = "allowance", // allowance/meal_allowance
  idBenefit = null
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "benefits",
          page,
          limit,
          search,
          period,
          typeBenefit,
          sortBy,
          sortDirection,
          idBenefit
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getBenefitsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getBenefitsSuccess([], null));
        } else {
          dispatch(getBenefitsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getBenefitsFailed(error.response.data.message));
          }
        } else {
          dispatch(getBenefitsFailed(error.message));
        }
      });
  };
};

const getBenefitsSuccess = (data, pagination) => {
  return {
    type: GET_BENEFIT_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getBenefitsFailed = (errorMessage) => {
  return {
    type: GET_BENEFIT_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getBenefitDetails = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  id = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: `benefits/${id}`,
          page,
          limit,
          search,
          sortBy,
          sortDirection,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getBenefitDetailsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getBenefitDetailsSuccess(null));
        } else {
          dispatch(getBenefitDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getBenefitDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getBenefitDetailsFailed(error.message));
        }
      });
  };
};

const getBenefitDetailsFailed = (errorMessage) => {
  return {
    type: GET_BENEFIT_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getBenefitDetailsSuccess = (data, pagination) => {
  return {
    type: GET_BENEFIT_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addBenefits = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post("benefits", formData, headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "benefit"));
          dispatch(addBenefitsSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          alert(response.data.message);
          dispatch(addBenefitsFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addBenefitsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addBenefitsFailed(error.response.data.message));
          }
        } else {
          dispatch(addBenefitsFailed(error.message));
        }
      });
  };
};

const addBenefitsSuccess = (message) => {
  return {
    type: ADD_BENEFIT_SUCCESS,
    payload: message,
  };
};

const addBenefitsFailed = (message) => {
  return {
    type: ADD_BENEFIT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* ------------------------------- PayBenefit ------------------------------- */
export const addPayBenefitDetail = (token, formData) => {
  delete formData.id;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`benefits/add-employe`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("SAVED", "benefit"));
          dispatch(addPayBenefitDetailSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          alert(response.data.message);
          dispatch(addPayBenefitDetailFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addPayBenefitDetailFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addPayBenefitDetailFailed(error.response.data.message));
          }
        } else {
          dispatch(addPayBenefitDetailFailed(error.message));
        }
      });
  };
};

const addPayBenefitDetailSuccess = (message) => {
  return {
    type: ADD_PAY_BENEFIT_SUCCESS,
    payload: message,
  };
};

const addPayBenefitDetailFailed = (message) => {
  return {
    type: ADD_PAY_BENEFIT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editBenefits = (token, formData, id) => {

  if (formData.type === 'allowance') {
    formData = {
      type: formData.type,
      periode_full_date: formData.periode_full_date
    }
  } else if (formData.type === 'meal_allowance') {
    formData = {
      type: formData.type,
      start_date: formData.start_date,
      end_date: formData.end_date
    }
  }

  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(`benefits/${id}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "benefit"));
          dispatch(editBenefitsSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          alert(response.data.message);
          dispatch(editBenefitsFailed(response.data.message));
        } else {
          dispatch(editBenefitsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(editBenefitsFailed(error.response.data.message));
          }
        } else {
          dispatch(editBenefitsFailed(error.message));
        }
      });
  };
};

const editBenefitsSuccess = (message) => {
  return {
    type: EDIT_BENEFIT_SUCCESS,
    payload: message,
  };
};

const editBenefitsFailed = (message) => {
  return {
    type: EDIT_BENEFIT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* ------------------------------- PayBenefit ------------------------------- */


export const editPayBenefitDetail = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(`benefits/detail/update-pay/${id}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "benefit"));
          dispatch(editPayBenefitDetailSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          alert(response.data.message);
          dispatch(editPayBenefitDetailFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editPayBenefitDetailFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(editPayBenefitDetailFailed(error.response.data.message));
          }
        } else {
          dispatch(editPayBenefitDetailFailed(error.message));
        }
      });
  };
};

const editPayBenefitDetailSuccess = (message) => {
  return {
    type: EDIT_PAY_BENEFIT_SUCCESS,
    payload: message,
  };
};

const editPayBenefitDetailFailed = (message) => {
  return {
    type: EDIT_PAY_BENEFIT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteBenefits = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`benefits${params}`, {
        ...formData,
        ...headersAPIToken(token),
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "benefit"));
          dispatch(deleteBenefitsSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteBenefitsFailed(response.data.message));
        } else {
          dispatch(deleteBenefitsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteBenefitsFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteBenefitsFailed(error.message));
        }
      });
  };
};

const deleteBenefitsSuccess = (message) => {
  return {
    type: DELETE_BENEFIT_SUCCESS,
    payload: message,
  };
};

const deleteBenefitsFailed = (message) => {
  return {
    type: DELETE_BENEFIT_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
