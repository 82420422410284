export class Pagination {
  /**@type {number} */
  page;
  /**@type {number} */
  limit;
  /**@type {number} */
  totalData;
  /**@type {number} */
  totalPage;

  constructor() {
    if (this.constructor === Pagination) {
      throw new Error("This is abstract class");
    }
  }
}

export const urlHeaderFiletype = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type":
      "multipart/form-data; boundary=<calculated when request is sent>",
  },
});

/**
 * @typedef {object} PaginationType
 * @property {number} page
 * @property {number} limit
 * @property {number} totalData
 * @property {number} totalPage
 */

/**@type {PaginationType} */
export let PaginationType;

/**
 * @typedef {object} OptionType
 * @property {string|number} id
 * @property {string} name
 */

/**
 * @typedef {object} FieldType
 * @property {string} name - name of field
 * @property {string} type - type field
 * @property {string} label - label on field
 * @property {string} placeholder - placeholder
 * @property {string|number|boolean} defaultValue - default value
 * @property {string} [apiEndpoint] - endpoint for get option
 * @property {string} [errorMessage] - custom error message
 * @property {string} [tip] - tip for field
 * @property {string} [reactive] - reactive to some field name
 * @property {boolean} [required] - flag for field require
 * @property {boolean} [parent] - flag form parent field in multiple value
 * @property {boolean} [hidden] - flag for hidden form when render by loop
 * @property {boolean} [multiple] - flag for multiple value
 * @property {boolean} [canChange] - flag for can chage value
 * @property {boolean} [autoFill] - flag for autoFill value
 * @property {boolean} [disabled] - flag for disabeld value
 * @property {object} [additionalProps] - object for aditional props
 * @property {Function} [validation] - custom function validation
 * @property {Array<OptionType>} [options] - custom options when cannot get from server
 *
 */

 
/**
 * @typedef {object} ParentFieldType
 * @property {string} title - title of parent field
 * @property {string} subtitle - subtitle of parent field
 * @property {string} [type] - type of parent field
 * @property {string} multipleValueName - alias name for store in formValues
 * @property {boolean} isDisplayTitle 
 * @property {Array<FieldType> | FieldType} field - list of field
 * 
 */
