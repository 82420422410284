import { REQUEST_LOADING } from "./../../../../util/global_state";
import {
  ADD_STOCK_IN_FAILED,
  ADD_STOCK_IN_SUCCESS,
  DELETE_STOCK_IN_FAILED,
  DELETE_STOCK_IN_SUCCESS,
  GET_STOCK_IN_FAILED,
  GET_STOCK_IN_SUCCESS,
  UPDATE_STOCK_IN_FAILED,
  UPDATE_STOCK_IN_SUCCESS,
} from "./state";

const initialState = {
  isLoading: false,
  data: null,
  pagination: null,
  message: null,
};

const stockInReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STOCK_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        pagination: pagination,
      };
    case GET_STOCK_IN_FAILED:
    case ADD_STOCK_IN_FAILED:
    case ADD_STOCK_IN_SUCCESS:
    case UPDATE_STOCK_IN_FAILED:
    case UPDATE_STOCK_IN_SUCCESS:
    case DELETE_STOCK_IN_SUCCESS:
    case DELETE_STOCK_IN_FAILED:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default stockInReducer;
