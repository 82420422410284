export const detailPayload1 = {
  status: "Approved",
  no_transaction: "IPT12314345343",
  no_application: "DID123123123123",
  receive_date: "26/06/2024, 14:34",
  created_at_formated: "29 Feb 2023, 14:30",
  created_at: "29/02/2023, 14:30",
  additional_information: {
    request_by: "Liberty Enterprises",
    request_to: "Marcus Levin",
  },
  Applicant: {
    client_name: "Mutiara",
    applicant_name: "Sari Mutiara",
    category: "Merek Dagang",
    type: "Umum",
    bussines_field: "Kosmetik & Kecantikan",
    phone: "0876123123",
    email: "lanykonsultaky@pacificpatent.com",
    company: "PT. Antum beauty",
    owner_type: "Personal",
  },
  Description: {
    mark_type: "Merek Kata dan Lukisan",
    image: { filename: "mark.jpg", size: 2009988, url: "https://exampleimage.com" },
    trademark: "ANTUM BEAUTY",
    description: "-",
    colors: "Kuning keemasan, hitam, abu-abu",
    translation: "-",
    transliteration: "-",
    disclaimers: "-",
  },
  Address: {
    address:
      "Dukuh Bantaran RT 014 RW 004, Kel Ketangan, Kec Gringsing, Kabupaten Batang, Jawa Tengah",
    city: "Kabupaten Batang",
    province: "Jawa Tengah",
    zip_code: "4342",
    country: "Indonesia",
  },
  Representative: {
    no_consultant: "0743-3974",
    name: "Lanny Setiawan MBA.,",
    office_name: "Pacific Patent Multiglobal",
    address:
      "Pacific Patent Multiglobal DIPO Business Center Lt. 11 Jalan Jend Gatot Subroto Kav 51-52, Jakarta Pusat-10260, Indonesia",
    phone: "087546343534",
    email: "lannykonsultanhki@pacificpatent.com",
  },
  Attachment: {
    support_document: [
      {
        filename: "Support.pdf",
        size: 22323434,
        url: "https://exampleimage.com",
      },
      {
        filename: "Support-2.pdf",
        size: 22323434,
        url: "https://exampleimage.com",
      },
    ],
    surat_kuasa_konsultan: {
      filename: "suratkuasa.pdf",
      size: 22323434,
      url: "https://exampleimage.com",
    },
    tanda_tangan_kuasa: {
      filename: "tandatangankuasa.pdf",
      size: 22323434,
      url: "https://exampleimage.com",
    },
    tanda_tangan_pemohon: {
      filename: "tandatanganpemohon.pdf",
      size: 22323434,
      url: "https://exampleimage.com",
    },
  },
  Other: {
    priorityData: [
      { date: "-", country: "-", number_of_priority: "-" },
      { date: "-", country: "-", number_of_priority: "-" },
    ],
    class: [
      {
        class: 1,
        description:
          "krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi",
      },
      {
        class: 3,
        description:
          "krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi",
      },
    ],
    additional_applicant: { applicant_name: ["Edward, louise", "Ahmad thourf"] },
    additional_mark: {
      upload_file: [
        {
          filename: "Mark1.pdf",
          size: 1232434,
          url: "https://example.com",
        },
        {
          filename: "Mark2.pdf",
          size: 1232434,
          url: "https://example.com",
        },
      ],
    },
  },
  approvalLine: [
    {
      user: {
        fullname: "Marcus",
        position: "Oprational Manager",
      },
      status: "Approved",
      date_formated: "20 Jul 2024, 09:00",
    },
  ],
};

export const detailPayload2 = {
  status: "Approved",
  no_transaction: "IPT12314345343",
  no_application: "DID123123123123",
  receive_date: "26/06/2024, 14:34",
  created_at_formated: "29 Feb 2023, 14:30",
  created_at: "29/02/2023, 14:30",
  can_download: true,
  can_print: true,
  user_information: {
    request_by: { fullname: "Liberty Enterprises", position: "" },
    request_to: { fullname: "Marcus Levin", position: "Oprational Manager" },
  },
  detail: {
    StepOne: {
      Applicant: {
        Group: "Lorem ipsum",
        Client: "Mutiara",
        "Application Name": "Sari Mutiara",
        "PIC Name": "Mutiara",
        Category: "Merek Dagang",
        Type: "Umum",
        //! Note: isinya multiple
        Phone: "0876123123",
        Email: "lanykonsultaky@pacificpatent.com",
        //! ================
        "Bussines Field": "Kosmetik & Kecantikan",
        // Company: "PT. Antum beauty",
        "Owner Type": "Personal",
        Notes: "Lorem ipsum",
      },
      Address: {
        address:
          "Dukuh Bantaran RT 014 RW 004, Kel Ketangan, Kec Gringsing, Kabupaten Batang, Jawa Tengah",
        City: "Kabupaten Batang",
        Province: "Jawa Tengah",
        "Zip Code": "4342",
        Country: "Indonesia",
      },
    },
    StepTwo: {
      Description: {
        "Type of Mark": "Merek Kata dan Lukisan",
        "Image of Mark": {
          filename: "mark.jpg",
          size: 2009988,
          url: "https://exampleimage.com",
        },
        Trademark: "ANTUM BEAUTY",
        Description: "-",
        Colors: "Kuning keemasan, hitam, abu-abu",
        Translation: "-",
        Transliteration: "-",
        Disclaimers: "-",
      },
    },
    StepThree: {
      Class: [
        {
          Class: "12",
          "Description of Goods/Service":
            "krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi; produk perawatan bibir (kosmetik); losion untuk tubuh; lulur; krim tubuh; masker rambut; sediaan perawatan kulit; kosmetik; susu pembersih wajah; astringen untuk keperluan kosmetik; kapas untuk keperluan kosmetik; pensil alis; Eyeliner krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi;",
        },
      ],
    },
    StepFour: {
      Representative: {
        "Number Of Consultant": "0743-3974",
        "Consultant Name": "Lanny Setiawan MBA.",
        "Office Name": "Pacific Patent Multiglobal",
        Address:
          "Pacific Patent Multiglobal DIPO Business Center Lt. 11 Jalan Jend Gatot Subroto Kav 51-52, Jakarta Pusat-10260, Indonesia",
        Phone: "087546343534",
        Email: "lannykonsultanhki@pacificpatent.com",
      },
    },
    StepFive: {
      Attachment: {
        "Support Documents": [
          {
            filename: "Support.pdf",
            size: 22323434,
            url: "https://exampleimage.com",
          },
          {
            filename: "Support-2.pdf",
            size: 22323434,
            url: "https://exampleimage.com",
          },
        ],
        "Surat Kuasa konsultan KI Bermatrai Cukup (Asli)": {
          filename: "suratkuasa.pdf",
          size: 22323434,
          url: "https://exampleimage.com",
        },
        "Tanda Tangan Digital Kuasa": {
          filename: "tandatangankuasa.pdf",
          size: 22323434,
          url: "https://exampleimage.com",
        },
        "Tanda Tangan Digital Pemohon": {
          filename: "tandatanganpemohon.pdf",
          size: 22323434,
          url: "https://exampleimage.com",
        },
      },
    },
    // Other: {
    //   "Priority Data": [
    //     {
    //       "Priority Date": "-",
    //       "Priority Country": "-",
    //       "Number of Priority": "-",
    //     },
    //   ],
    //   Class: [
    //     {
    //       Class: 1,
    //       "Description of Goods/Service":
    //         "krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi; produk perawatan bibir (kosmetik); losion untuk tubuh; lulur; krim tubuh; masker rambut; sediaan perawatan kulit; kosmetik; susu pembersih wajah; astringen untuk keperluan kosmetik; kapas untuk keperluan kosmetik; pensil alis; Eyeliner",
    //     },
    //     {
    //       Class: 12,
    //       "Description of Goods/Service":
    //         "krim bibir; Masker Wajah; Perona Pipi; Sampo; Kondisioner; sabun mandi;",
    //     },
    //   ],
    //   "Additional Applicant": {
    //     "Applicant's Name": ["Edward, louise", "Ahmad thourf"],
    //   },
    //   "Additional Mark": {
    //     "Upload File": [
    //       {
    //         filename: "Mark1.pdf",
    //         size: 1232434,
    //         url: "https://example.com",
    //       },
    //       {
    //         filename: "Mark2.pdf",
    //         size: 1232434,
    //         url: "https://example.com",
    //       },
    //     ],
    //   },
    // },
  },
  approvalLine: [
    {
      user: {
        fullname: "Marcus",
        position: "Oprational Manager",
      },
      status: "Approved",
      date_formated: "20 Jul 2024, 09:00",
    },
  ],
};

const attachmentSample = {
  name: "",
  size: "",
  url: "",
};

const createExampleAttahment = (
  name,
  size = 12391233,
  url = "https://exampleimage.com"
) => {
  return {
    name,
    size,
    url,
  };
};

const addPayload = {
  StepOne: {
    "Applicant Information": {
      client_id: 3,
      applicant_id: 29,
      category_id: 1,
      email: "admin@mail.com",
      mobile_phone: "0898765467",
      business_field_id: 2,
      company_name: "Testing",
      submission_on_behalf_of: "Personal",
    },
  },
  StepTwo: {
    Description: {
      mark_type_id: 3,
      image_mark: {},
      trademark: "-",
      description: "-",
      colors: "-",
      translation: "-",
      transliteration: "-",
      disclaimers: "-",
    },
  },
  StepThree: {
    Address: {
      address: "--",
      city_id: 1102,
      province_id: 11,
      zip_code: "15810",
      country: 3,
    },
  },
  StepFour: {
    "Representative/IP Consultan": {
      consultan_id: 2,
      mobile_phone: "", // not used
      email: "", // not used
    },
  },
  StepFive: {
    Attachment: {
      support_documents: [{}],
      surat_kuasa: {},
      tanda_tangan_kuasa: {},
      tanda_tangan_pemohon: {},
    },
  },
  StepSix: {
    "Priority Data (Optional)": [
      {
        date: "2024-06-14",
        country: 1,
        number_priority: "2",
      },
    ],
    Class: [
      {
        class: "3",
        description: "-",
      },
    ],
    "Aditional Applicant (Optional)": {
      name: ["Rahmat Hidayat"],
    },
    "Aditional Mark (Optional)": {
      attachment: [{}],
    },
  },
};

const FormValuesPayload = {
  "Application Information": {
    group: "",
    applicant_id: [-1],
    client_id: -1,
    PIC_name: ["name"],
    category_id: -1,
    type_id: -1,
    email_phone: [{ email: "", phone: "" }],
    bussines_field: -1,
    company: "",
    owner_type: "personal",
    notes: "",
  },
};

/**
 * Type mark === image
 * owener type === personal
 * consultan === no
 *
 */
const newPaylaod1 = {
  StepOne: {
    "Applicant Information": {
      group: 2,
      client_id: 3,
      applicant_id: [201],
      pic_name: ["Mutia"],
      category_id: 100,
      type: "Umum",
      email_phone: [
        {
          email: "design01@bestada.co.id",
          phone: "0832324422",
        },
      ],
      business_field_id: 7,
      owner_type: "personal",
      notes: "Nmoting",
    },
    Address: {
      address: "banten",
      rigency_id: 1174,
      province_id: 11,
      zip_code: "15810",
      country_id: 3,
    },
    "Delivery Address": {
      address: "banten barat dikit",
      rigency_id: 1174,
      province_id: 11,
      zip_code: "15810",
      country_id: 3,
    },
  },
  StepTwo: {
    Description: {
      type_of_mark_id: "kata_dan_lukisan",
      mark_attachment: {},
      trademark: "Antum beauty",
      description: "aAAAA",
      type_of_goods_id: 3,
      notes: "asdsda",
      colors: "-",
      translation: "-",
      transliteration: "-",
      disclaimers: "-",
    },
  },
  StepThree: {
    Class: [
      {
        class: "3",
        description_goods_service_indonesia:
          "Sampo rambut 3-in-1\nkain dan kertas abrasif\npasta abrasif\nsediaan abrasif\nbahan kikis\nlembar abrasif\nSediaan penghapus perekat\nperekat untuk membubuhkan alis palsu\nperekat untuk membubuhkan bulu mata palsu\nperekat untuk membubuhkan rambut palsu\nperekat untuk membubuhkan kuku palsu\nperekat untuk membubuhkan kuku atau bulu mata palsu\nperekat untuk keperluan kosmetik\ncologne setelah bercukur\nkrim after-shave (non-obat)\nemulsi setelah bercukur\ngel untuk dipakai setelah bercukur\nlosion setelah bercukur\nsusu pelembab setelah bercukur\nminyak after-sun untuk keperluan kosmetik\ngel tahan lama untuk keperluan kosmetik lotion tahan usia",
        description_goods_service_english:
          "3-in-1 hair shampoos\nabrasive cloth and paper\nabrasive pastes\nabrasive preparations\nabrasives\nabrasive sheets\nadhesive removers\nadhesives for affixing false eyebrows\nadhesives for affixing false eyelashes\nadhesives for affixing false hair\nadhesives for affixing false nails\nadhesives for affixing false nails or eyelashes\nadhesives for cosmetic purposes\nafter-shave cologne\nafter-shave creams (non-medicated)\nafter-shave emulsions\nafter-shave gel\nafter-shave lotions\nafter-shave moisturising milk\nafter-sun oils for cosmetic purposes\nage retardant gels for cosmetic purposes",
      },
    ],
  },
  StepFour: {
    "Representative/IP Consultan": {
      consultan: "no",
      notes: "Noting we can do",
    },
  },
  StepFive: {
    Attachment: {
      surat_kuasa: {},
      surat_pernyataan: {},
      tanda_tangan_kuasa: {},
      tanda_tangan_direktur: {},
      npwp: {},
      ktp_direktur: {},
      akta: {},
      support_documents: [{}],
    },
  },
};

/**
 * mark type === suara
 * owner type === company
 * consultan === yes
 */
const newPayload2 = {
  StepOne: {
    "Applicant Information": {
      group: 2,
      client_id: 2,
      applicant_id: [29],
      pic_name: ["Mutira"],
      category_id: 6,
      type: "Umum",
      email_phone: [
        {
          email: "email@mail.com",
          phone: "083445678454",
        },
      ],
      business_field_id: 4,
      owner_type: "company",
      notes: "noting we can do",
    },
    Address: {
      address: "fsdgdsfds",
      rigency_id: 1174,
      province_id: 11,
      zip_code: "15810",
      country_id: 3,
    },
    "Delivery Address": {
      address: "fsdgdsfds",
      rigency_id: 1174,
      province_id: 11,
      zip_code: "15810",
      country_id: 3,
    },
  },
  StepTwo: {
    Description: {
      type_of_mark_id: "suara",
      mark_attachment: {},
      trademark: "Kuatum Beauty",
      description: "",
      type_of_goods_id: 3,
      notes: "00",
      colors: "kuning",
      translation: "-",
      transliteration: "-",
      disclaimers: "-",
    },
  },
  StepThree: {
    Class: [
      {
        class: "3",
        description_goods_service_indonesia:
          "Sampo rambut 3-in-1\nkain dan kertas abrasif\npasta abrasif\nsediaan abrasif\nbahan kikis\nlembar abrasif\nSediaan penghapus perekat\nperekat untuk membubuhkan alis palsu\nperekat untuk membubuhkan bulu mata palsu\nperekat untuk membubuhkan rambut palsu\nperekat untuk membubuhkan kuku palsu\nperekat untuk membubuhkan kuku atau bulu mata palsu\nperekat untuk keperluan kosmetik\ncologne setelah bercukur\nkrim after-shave (non-obat)\nemulsi setelah bercukur\ngel untuk dipakai setelah bercukur\nlosion setelah bercukur\nsusu pelembab setelah bercukur\nminyak after-sun untuk keperluan kosmetik\ngel tahan lama untuk keperluan kosmetik lotion tahan usia",
        description_goods_service_english:
          "3-in-1 hair shampoos\nabrasive cloth and paper\nabrasive pastes\nabrasive preparations\nabrasives\nabrasive sheets\nadhesive removers\nadhesives for affixing false eyebrows\nadhesives for affixing false eyelashes\nadhesives for affixing false hair\nadhesives for affixing false nails\nadhesives for affixing false nails or eyelashes\nadhesives for cosmetic purposes\nafter-shave cologne\nafter-shave creams (non-medicated)\nafter-shave emulsions\nafter-shave gel\nafter-shave lotions\nafter-shave moisturising milk\nafter-sun oils for cosmetic purposes\nage retardant gels for cosmetic purposes",
      },
    ],
  },
  StepFour: {
    "Representative/IP Consultan": {
      consultan: "yes",
      consultan_id: 304,
      consultan_number: "",
      office_name: "",
      address: "",
      mobile_phone: "",
      email: "",
    },
  },
  /** Step 5 depending to owner type */
  StepFive: {
    Attachment: {
      surat_kuasa: {},
      surat_pernyataan: {},
      tanda_tangan_pemohon: {},
      ktp_pemohon: {},
      support_documents: [{}],
    },
  },
};

/**
 * mark type === 3D
 * owner type === company
 * consultant === no
 *
 */
const newPayload3 = {
  StepOne: {
    "Applicant Information": {
      group: 3,
      client_id: 2,
      applicant_id: [29],
      pic_name: ["Restiani"],
      category_id: 9,
      type: "Umum",
      email_phone: [
        {
          email: "staff@mail.com",
          phone: "0878765467",
        },
      ],
      business_field_id: 3,
      owner_type: "company",
      notes: "nothing we can do",
    },
    Address: {
      address: "banten kiri dikit",
      rigency_id: 1174,
      province_id: 11,
      zip_code: "15810",
      country_id: 3,
    },
    "Delivery Address": {
      address: "banten kiri dikit",
      rigency_id: 1174,
      province_id: 11,
      zip_code: "15810",
      country_id: 3,
    },
  },
  StepTwo: {
    Description: {
      type_of_mark_id: "3d",
      mark_attachment: "",
      trademark: "Kualitos tuntos",
      description: "",
      type_of_goods_id: 7,
      notes: "-",
      colors: "-",
      translation: "-",
      transliteration: "-",
      disclaimers: "-",
      three_dimensi: {
        "3d_front_view": {},
        "3d_rear_view": {},
        "3d_top_view": {},
        "3d_bottom_view": {},
        "3d_right_side_view": {},
        "3d_left_side_view": {},
      },
    },
  },
  StepThree: {
    Class: [
      {
        class: "3",
        description_goods_service_indonesia:
          "Sampo rambut 3-in-1\nkain dan kertas abrasif\npasta abrasif\nsediaan abrasif\nbahan kikis\nlembar abrasif\nSediaan penghapus perekat\nperekat untuk membubuhkan alis palsu\nperekat untuk membubuhkan bulu mata palsu\nperekat untuk membubuhkan rambut palsu\nperekat untuk membubuhkan kuku palsu\nperekat untuk membubuhkan kuku atau bulu mata palsu\nperekat untuk keperluan kosmetik\ncologne setelah bercukur\nkrim after-shave (non-obat)\nemulsi setelah bercukur\ngel untuk dipakai setelah bercukur\nlosion setelah bercukur\nsusu pelembab setelah bercukur\nminyak after-sun untuk keperluan kosmetik\ngel tahan lama untuk keperluan kosmetik lotion tahan usia\n",
        description_goods_service_english:
          "3-in-1 hair shampoos\nabrasive cloth and paper\nabrasive pastes\nabrasive preparations\nabrasives\nabrasive sheets\nadhesive removers\nadhesives for affixing false eyebrows\nadhesives for affixing false eyelashes\nadhesives for affixing false hair\nadhesives for affixing false nails\nadhesives for affixing false nails or eyelashes\nadhesives for cosmetic purposes\nafter-shave cologne\nafter-shave creams (non-medicated)\nafter-shave emulsions\nafter-shave gel\nafter-shave lotions\nafter-shave moisturising milk\nafter-sun oils for cosmetic purposes\nage retardant gels for cosmetic purposes\n",
      },
    ],
  },
  StepFour: {
    "Representative/IP Consultan": {
      consultan: "no",
      notes: "nothing we can do",
    },
  },
  StepFive: {
    Attachment: {
      surat_kuasa: {},
      surat_pernyataan: {},
      tanda_tangan_pemohon: {},
      ktp_pemohon: {},
      support_documents: [{}],
    },
  },
};
