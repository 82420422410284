import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
  Divider,
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarIcon from "./../../../assets/calendar.png";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  AttachmentItem
} from "../../../util/style";
import axios from "../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "../../../components/Form";
import { addEmployeTransfer, deleteEmployeTransfer, editEmployeTransfer, getEmployeTransfer } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
  formatCurrencyWithoutLable,
  MODE_CRUD_WITH
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import LeftDrawer from "../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";
import RemoveIcon from "./../../../assets/minus.svg";

const EmployeTransfer = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.ALL_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Employee Transfer");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [ setType] = useState(ACTION_TYPE.ALL_TYPE);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "employe_code",
      headerName: "Employee ID",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "employe_name",
      headerName: "Employee Name",
      width: 260,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ color: "blue" }}>
          <a
            // onClick={() => navigate(`detail/${params.row.id}`)}
            onClick={() => handleFormModal(ACTION_TYPE.DETAIL, params.row)}
            style={{ textDecoration: "none", cursor: "grab" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "job_position",
      headerName: "Job Position",
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      width: 200,
      disableColumnMenu: true,
    },
   {
    field: "job_level",
    headerName: "Job Level",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "employe_status",
    headerName: "Employee Status",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "transfer_type",
    headerName: "Transfer Type",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "effective_date",
    headerName: "Effective Date",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "created_at_formatted",
    headerName: "Created At",
    width: 200,
    disableColumnMenu: true,
  }
  ];


  const fields  = [
        // {
        //   title: "Basic Information",
        //   subtitle: "Complete all employee basic information, as basic data",
        //   isDisplayTitle: false,
        // },
        {
          title: "Employee Information",
          isDisplayTitle: false,
          children: [
             {
              name: "user_id",
              type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
              label: "Employee",
              apiEndpoint: "/master/employes",
              required: true,
            },
            {
              name: "job_position",
              label: "Job Position",
              type: INPUT_TYPE.TEXT,
              placeholder: 'Job Position',
              additionalProps: {
                disabled:true,
              },
            },
            {
              name: "organization_name",
              label: "Organization Name",
              type: INPUT_TYPE.TEXT,
              placeholder: 'Organization Name',
              additionalProps: {
                disabled:true,
              },
            },
            {
              name: "job_level",
              label: "Job Level",
              type: INPUT_TYPE.TEXT,
              placeholder: 'Job Level',
              additionalProps: {
                disabled:true,
              },
            },
            {
              name: "employe_status",
              label: "Employee Status",
              type: INPUT_TYPE.TEXT,
              placeholder: 'Employee Status',
              additionalProps: {
                disabled:true,
              },
            },
            {
              name: "requested_at",
              type: INPUT_TYPE.DATE,
              label: "Requested At",
              endIcon: <img src={CalendarIcon} />,
              formatDate: "DD/MM/YYYY", // Custom date format
              defaultValue: '',
              required: true,
            },
            {
              name: "effective_date",
              type: INPUT_TYPE.DATE,
              label: "Effective Date",
              endIcon: <img src={CalendarIcon} />,
              formatDate: "DD/MM/YYYY", // Custom date format
              defaultValue: '',
              required: true,
            },
            {
              name: "reason_id",
              type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
              label: "Reason",
              apiEndpoint: "/master/reasons",
              required: true,
            },
             {
              name: "notes",
              type: INPUT_TYPE.TEXTAREA,
              label: "Notes",
              placeholder: "Input notes",
              defaultValue: "",
              required: true,
              additionalProps: {
                // disabled: actionType === ACTION_TYPE.EDITED
              }
            },
          ],
        },
        {
          title: "Attachment",
          subtitle: 'You can upload the documents for this process ( Optional )',
          isDisplayTitle: true,
          children: [
            {
              name: "attachment",
              type: INPUT_TYPE.FILE,
              label: "Upload File",
              tip: "Max size: 2 MB. Supported file: DOCX, PDF",
              allowedExtensions: [".pdf", ".doc", ".docx",],
              maximumSizeInMB: 2, //MB
              defaultValue: '',
              // required: true,
            },
          ],
        },
      ]

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.employeeTransfer);

  const initialSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: 0,
    vat_percent: '',
    vat_to_rp: 0,
    total_to_rp: 0,
    criteria_id: "",
    desc: "",
  };

  const initialAttachment = {
    attachment: [""],
  };

  const initialErrorSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: "",
    vat_percent: "",
    criteria_id: "",
    desc: "",
  };
  const initialErrorAttachment = {
    attachment: [""],
  };
  // const rows = [

  //   {
  //     "id": 1,
  //     "code": "Code 1",
  //     "client_name": "PT. Telkom Indonesia ",
  //     "name": "Marco",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 2,
  //     "code": "Code 2",
  //     "client_name": "PT. Unilever Tbk",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 3,
  //     "code": "Code 3",
  //     "client_name": "PT. Kompas Tbk",
  //     "name": "Faris",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 4,
  //     "code": "Code 4",
  //     "client_name": "PT. Siantar Top Tbk",
  //     "name": "Zaid",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 5,
  //     "code": "Code 5",
  //     "client_name": "PT. Wilmar Tbk",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 6,
  //     "code": "Anauco",
  //     "client_name": "PT. Gudang Garam Tbk",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 7,
  //     "code": "Code 7",
  //     "client_name": "EmployeTransfer Name 7",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 8,
  //     "code": "Code 8",
  //     "client_name": "EmployeTransfer Name 8",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 9,
  //     "code": "Code 9",
  //     "client_name": "EmployeTransfer Name 9",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 10,
  //     "code": "Code 10",
  //     "client_name": "EmployeTransfer Name 10",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 11,
  //     "code": "Code 11",
  //     "client_name": "EmployeTransfer Name 11",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 12,
  //     "code": "Code 12",
  //     "client_name": "EmployeTransfer Name 12",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 13,
  //     "code": "Code 13",
  //     "client_name": "EmployeTransfer Name 13",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 14,
  //     "code": "Code 14",
  //     "client_name": "EmployeTransfer Name 14",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 15,
  //     "code": "Code 15",
  //     "client_name": "EmployeTransfer Name 15",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 16,
  //     "code": "Code 16",
  //     "client_name": "EmployeTransfer Name 16",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 17,
  //     "code": "Code 17",
  //     "client_name": "EmployeTransfer Name 17",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 18,
  //     "code": "Code 18",
  //     "client_name": "EmployeTransfer Name 18",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 19,
  //     "code": "Code 19",
  //     "client_name": "EmployeTransfer Name 19",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 20,
  //     "code": "Code 20",
  //     "client_name": "EmployeTransfer Name 20",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   }
  // ];

  // const countries = [
  //   { code: 'AD', label: 'Andorra', phone: '376' },
  //   {
  //     code: 'AE',
  //     label: 'United Arab Emirates',
  //     phone: '971',
  //   },
  //   { code: 'AF', label: 'Afghanistan', phone: '93' },
  //   {
  //     code: 'AG',
  //     label: 'Antigua and Barbuda',
  //     phone: '1-268',
  //   },
  //   { code: 'AI', label: 'Anguilla', phone: '1-264' },
  //   { code: 'AL', label: 'Albania', phone: '355' },
  //   { code: 'AM', label: 'Armenia', phone: '374' },
  //   { code: 'AO', label: 'Angola', phone: '244' },
  //   { code: 'AQ', label: 'Antarctica', phone: '672' },
  //   { code: 'AR', label: 'Argentina', phone: '54' },
  //   { code: 'AS', label: 'American Samoa', phone: '1-684' },
  //   { code: 'AT', label: 'Austria', phone: '43' },
  //   {
  //     code: 'AU',
  //     label: 'Australia',
  //     phone: '61',
  //     suggested: true,
  //   },
  //   { code: 'AW', label: 'Aruba', phone: '297' },
  //   { code: 'AX', label: 'Alland Islands', phone: '358' },
  //   { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  //   {
  //     code: 'BA',
  //     label: 'Bosnia and Herzegovina',
  //     phone: '387',
  //   },
  //   { code: 'BB', label: 'Barbados', phone: '1-246' },
  //   { code: 'BD', label: 'Bangladesh', phone: '880' },
  //   { code: 'BE', label: 'Belgium', phone: '32' },
  //   { code: 'BF', label: 'Burkina Faso', phone: '226' },
  //   { code: 'BG', label: 'Bulgaria', phone: '359' },
  //   { code: 'BH', label: 'Bahrain', phone: '973' },
  //   { code: 'BI', label: 'Burundi', phone: '257' },
  //   { code: 'BJ', label: 'Benin', phone: '229' },
  //   { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  //   { code: 'BM', label: 'Bermuda', phone: '1-441' },
  //   { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  //   { code: 'BO', label: 'Bolivia', phone: '591' },
  //   { code: 'BR', label: 'Brazil', phone: '55' },
  //   { code: 'BS', label: 'Bahamas', phone: '1-242' },
  //   { code: 'BT', label: 'Bhutan', phone: '975' },
  //   { code: 'BV', label: 'Bouvet Island', phone: '47' },
  //   { code: 'BW', label: 'Botswana', phone: '267' },
  //   { code: 'BY', label: 'Belarus', phone: '375' },
  //   { code: 'BZ', label: 'Belize', phone: '501' },
  //   {
  //     code: 'CA',
  //     label: 'Canada',
  //     phone: '1',
  //     suggested: true,
  //   },
  //   {
  //     code: 'CC',
  //     label: 'Cocos (Keeling) Islands',
  //     phone: '61',
  //   },
  //   {
  //     code: 'CD',
  //     label: 'Congo, Democratic Republic of the',
  //     phone: '243',
  //   },
  //   {
  //     code: 'CF',
  //     label: 'Central African Republic',
  //     phone: '236',
  //   },
  //   {
  //     code: 'CG',
  //     label: 'Congo, Republic of the',
  //     phone: '242',
  //   },
  //   { code: 'CH', label: 'Switzerland', phone: '41' },
  //   { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  //   { code: 'CK', label: 'Cook Islands', phone: '682' },
  //   { code: 'CL', label: 'Chile', phone: '56' },
  //   { code: 'CM', label: 'Cameroon', phone: '237' },
  //   { code: 'CN', label: 'China', phone: '86' },
  //   { code: 'CO', label: 'Colombia', phone: '57' },
  //   { code: 'CR', label: 'Costa Rica', phone: '506' },
  //   { code: 'CU', label: 'Cuba', phone: '53' },
  //   { code: 'CV', label: 'Cape Verde', phone: '238' },
  //   { code: 'CW', label: 'Curacao', phone: '599' },
  //   { code: 'CX', label: 'Christmas Island', phone: '61' },
  //   { code: 'CY', label: 'Cyprus', phone: '357' },
  //   { code: 'CZ', label: 'Czech Republic', phone: '420' },
  //   {
  //     code: 'DE',
  //     label: 'Germany',
  //     phone: '49',
  //     suggested: true,
  //   },
  //   { code: 'DJ', label: 'Djibouti', phone: '253' },
  //   { code: 'DK', label: 'Denmark', phone: '45' },
  //   { code: 'DM', label: 'Dominica', phone: '1-767' },
  //   {
  //     code: 'DO',
  //     label: 'Dominican Republic',
  //     phone: '1-809',
  //   },
  //   { code: 'DZ', label: 'Algeria', phone: '213' },
  //   { code: 'EC', label: 'Ecuador', phone: '593' },
  //   { code: 'EE', label: 'Estonia', phone: '372' },
  //   { code: 'EG', label: 'Egypt', phone: '20' },
  //   { code: 'EH', label: 'Western Sahara', phone: '212' },
  //   { code: 'ER', label: 'Eritrea', phone: '291' },
  //   { code: 'ES', label: 'Spain', phone: '34' },
  //   { code: 'ET', label: 'Ethiopia', phone: '251' },
  //   { code: 'FI', label: 'Finland', phone: '358' },
  //   { code: 'FJ', label: 'Fiji', phone: '679' },
  //   {
  //     code: 'FK',
  //     label: 'Falkland Islands (Malvinas)',
  //     phone: '500',
  //   },
  //   {
  //     code: 'FM',
  //     label: 'Micronesia, Federated States of',
  //     phone: '691',
  //   },
  //   { code: 'FO', label: 'Faroe Islands', phone: '298' },
  //   {
  //     code: 'FR',
  //     label: 'France',
  //     phone: '33',
  //     suggested: true,
  //   },
  //   { code: 'GA', label: 'Gabon', phone: '241' },
  //   { code: 'GB', label: 'United Kingdom', phone: '44' },
  //   { code: 'GD', label: 'Grenada', phone: '1-473' },
  //   { code: 'GE', label: 'Georgia', phone: '995' },
  //   { code: 'GF', label: 'French Guiana', phone: '594' },
  //   { code: 'GG', label: 'Guernsey', phone: '44' },
  //   { code: 'GH', label: 'Ghana', phone: '233' },
  //   { code: 'GI', label: 'Gibraltar', phone: '350' },
  //   { code: 'GL', label: 'Greenland', phone: '299' },
  //   { code: 'GM', label: 'Gambia', phone: '220' },
  //   { code: 'GN', label: 'Guinea', phone: '224' },
  //   { code: 'GP', label: 'Guadeloupe', phone: '590' },
  //   { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  //   { code: 'GR', label: 'Greece', phone: '30' },
  //   {
  //     code: 'GS',
  //     label: 'South Georgia and the South Sandwich Islands',
  //     phone: '500',
  //   },
  //   { code: 'GT', label: 'Guatemala', phone: '502' },
  //   { code: 'GU', label: 'Guam', phone: '1-671' },
  //   { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  //   { code: 'GY', label: 'Guyana', phone: '592' },
  //   { code: 'HK', label: 'Hong Kong', phone: '852' },
  //   {
  //     code: 'HM',
  //     label: 'Heard Island and McDonald Islands',
  //     phone: '672',
  //   },
  //   { code: 'HN', label: 'Honduras', phone: '504' },
  //   { code: 'HR', label: 'Croatia', phone: '385' },
  //   { code: 'HT', label: 'Haiti', phone: '509' },
  //   { code: 'HU', label: 'Hungary', phone: '36' },
  //   { code: 'ID', label: 'Indonesia', phone: '62' },
  //   { code: 'IE', label: 'Ireland', phone: '353' },
  //   { code: 'IL', label: 'Israel', phone: '972' },
  //   { code: 'IM', label: 'Isle of Man', phone: '44' },
  //   { code: 'IN', label: 'India', phone: '91' },
  //   {
  //     code: 'IO',
  //     label: 'British Indian Ocean Territory',
  //     phone: '246',
  //   },
  //   { code: 'IQ', label: 'Iraq', phone: '964' },
  //   {
  //     code: 'IR',
  //     label: 'Iran, Islamic Republic of',
  //     phone: '98',
  //   },
  //   { code: 'IS', label: 'Iceland', phone: '354' },
  //   { code: 'IT', label: 'Italy', phone: '39' },
  //   { code: 'JE', label: 'Jersey', phone: '44' },
  //   { code: 'JM', label: 'Jamaica', phone: '1-876' },
  //   { code: 'JO', label: 'Jordan', phone: '962' },
  //   {
  //     code: 'JP',
  //     label: 'Japan',
  //     phone: '81',
  //     suggested: true,
  //   },
  //   { code: 'KE', label: 'Kenya', phone: '254' },
  //   { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  //   { code: 'KH', label: 'Cambodia', phone: '855' },
  //   { code: 'KI', label: 'Kiribati', phone: '686' },
  //   { code: 'KM', label: 'Comoros', phone: '269' },
  //   {
  //     code: 'KN',
  //     label: 'Saint Kitts and Nevis',
  //     phone: '1-869',
  //   },
  //   {
  //     code: 'KP',
  //     label: "Korea, Democratic People's Republic of",
  //     phone: '850',
  //   },
  //   { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  //   { code: 'KW', label: 'Kuwait', phone: '965' },
  //   { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  //   { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  //   {
  //     code: 'LA',
  //     label: "Lao People's Democratic Republic",
  //     phone: '856',
  //   },
  //   { code: 'LB', label: 'Lebanon', phone: '961' },
  //   { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  //   { code: 'LI', label: 'Liechtenstein', phone: '423' },
  //   { code: 'LK', label: 'Sri Lanka', phone: '94' },
  //   { code: 'LR', label: 'Liberia', phone: '231' },
  //   { code: 'LS', label: 'Lesotho', phone: '266' },
  //   { code: 'LT', label: 'Lithuania', phone: '370' },
  //   { code: 'LU', label: 'Luxembourg', phone: '352' },
  //   { code: 'LV', label: 'Latvia', phone: '371' },
  //   { code: 'LY', label: 'Libya', phone: '218' },
  //   { code: 'MA', label: 'Morocco', phone: '212' },
  //   { code: 'MC', label: 'Monaco', phone: '377' },
  //   {
  //     code: 'MD',
  //     label: 'Moldova, Republic of',
  //     phone: '373',
  //   },
  //   { code: 'ME', label: 'Montenegro', phone: '382' },
  //   {
  //     code: 'MF',
  //     label: 'Saint Martin (French part)',
  //     phone: '590',
  //   },
  //   { code: 'MG', label: 'Madagascar', phone: '261' },
  //   { code: 'MH', label: 'Marshall Islands', phone: '692' },
  //   {
  //     code: 'MK',
  //     label: 'Macedonia, the Former Yugoslav Republic of',
  //     phone: '389',
  //   },
  //   { code: 'ML', label: 'Mali', phone: '223' },
  //   { code: 'MM', label: 'Myanmar', phone: '95' },
  //   { code: 'MN', label: 'Mongolia', phone: '976' },
  //   { code: 'MO', label: 'Macao', phone: '853' },
  //   {
  //     code: 'MP',
  //     label: 'Northern Mariana Islands',
  //     phone: '1-670',
  //   },
  //   { code: 'MQ', label: 'Martinique', phone: '596' },
  //   { code: 'MR', label: 'Mauritania', phone: '222' },
  //   { code: 'MS', label: 'Montserrat', phone: '1-664' },
  //   { code: 'MT', label: 'Malta', phone: '356' },
  //   { code: 'MU', label: 'Mauritius', phone: '230' },
  //   { code: 'MV', label: 'Maldives', phone: '960' },
  //   { code: 'MW', label: 'Malawi', phone: '265' },
  //   { code: 'MX', label: 'Mexico', phone: '52' },
  //   { code: 'MY', label: 'Malaysia', phone: '60' },
  //   { code: 'MZ', label: 'Mozambique', phone: '258' },
  //   { code: 'NA', label: 'Namibia', phone: '264' },
  //   { code: 'NC', label: 'New Caledonia', phone: '687' },
  //   { code: 'NE', label: 'Niger', phone: '227' },
  //   { code: 'NF', label: 'Norfolk Island', phone: '672' },
  //   { code: 'NG', label: 'Nigeria', phone: '234' },
  //   { code: 'NI', label: 'Nicaragua', phone: '505' },
  //   { code: 'NL', label: 'Netherlands', phone: '31' },
  //   { code: 'NO', label: 'Norway', phone: '47' },
  //   { code: 'NP', label: 'Nepal', phone: '977' },
  //   { code: 'NR', label: 'Nauru', phone: '674' },
  //   { code: 'NU', label: 'Niue', phone: '683' },
  //   { code: 'NZ', label: 'New Zealand', phone: '64' },
  //   { code: 'OM', label: 'Oman', phone: '968' },
  //   { code: 'PA', label: 'Panama', phone: '507' },
  //   { code: 'PE', label: 'Peru', phone: '51' },
  //   { code: 'PF', label: 'French Polynesia', phone: '689' },
  //   { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  //   { code: 'PH', label: 'Philippines', phone: '63' },
  //   { code: 'PK', label: 'Pakistan', phone: '92' },
  //   { code: 'PL', label: 'Poland', phone: '48' },
  //   {
  //     code: 'PM',
  //     label: 'Saint Pierre and Miquelon',
  //     phone: '508',
  //   },
  //   { code: 'PN', label: 'Pitcairn', phone: '870' },
  //   { code: 'PR', label: 'Puerto Rico', phone: '1' },
  //   {
  //     code: 'PS',
  //     label: 'Palestine, State of',
  //     phone: '970',
  //   },
  //   { code: 'PT', label: 'Portugal', phone: '351' },
  //   { code: 'PW', label: 'Palau', phone: '680' },
  //   { code: 'PY', label: 'Paraguay', phone: '595' },
  //   { code: 'QA', label: 'Qatar', phone: '974' },
  //   { code: 'RE', label: 'Reunion', phone: '262' },
  //   { code: 'RO', label: 'Romania', phone: '40' },
  //   { code: 'RS', label: 'Serbia', phone: '381' },
  //   { code: 'RU', label: 'Russian Federation', phone: '7' },
  //   { code: 'RW', label: 'Rwanda', phone: '250' },
  //   { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  //   { code: 'SB', label: 'Solomon Islands', phone: '677' },
  //   { code: 'SC', label: 'Seychelles', phone: '248' },
  //   { code: 'SD', label: 'Sudan', phone: '249' },
  //   { code: 'SE', label: 'Sweden', phone: '46' },
  //   { code: 'SG', label: 'Singapore', phone: '65' },
  //   { code: 'SH', label: 'Saint Helena', phone: '290' },
  //   { code: 'SI', label: 'Slovenia', phone: '386' },
  //   {
  //     code: 'SJ',
  //     label: 'Svalbard and Jan Mayen',
  //     phone: '47',
  //   },
  //   { code: 'SK', label: 'Slovakia', phone: '421' },
  //   { code: 'SL', label: 'Sierra Leone', phone: '232' },
  //   { code: 'SM', label: 'San Marino', phone: '378' },
  //   { code: 'SN', label: 'Senegal', phone: '221' },
  //   { code: 'SO', label: 'Somalia', phone: '252' },
  //   { code: 'SR', label: 'Suriname', phone: '597' },
  //   { code: 'SS', label: 'South Sudan', phone: '211' },
  //   {
  //     code: 'ST',
  //     label: 'Sao Tome and Principe',
  //     phone: '239',
  //   },
  //   { code: 'SV', label: 'El Salvador', phone: '503' },
  //   {
  //     code: 'SX',
  //     label: 'Sint Maarten (Dutch part)',
  //     phone: '1-721',
  //   },
  //   {
  //     code: 'SY',
  //     label: 'Syrian Arab Republic',
  //     phone: '963',
  //   },
  //   { code: 'SZ', label: 'Swaziland', phone: '268' },
  //   {
  //     code: 'TC',
  //     label: 'Turks and Caicos Islands',
  //     phone: '1-649',
  //   },
  //   { code: 'TD', label: 'Chad', phone: '235' },
  //   {
  //     code: 'TF',
  //     label: 'French Southern Territories',
  //     phone: '262',
  //   },
  //   { code: 'TG', label: 'Togo', phone: '228' },
  //   { code: 'TH', label: 'Thailand', phone: '66' },
  //   { code: 'TJ', label: 'Tajikistan', phone: '992' },
  //   { code: 'TK', label: 'Tokelau', phone: '690' },
  //   { code: 'TL', label: 'Timor-Leste', phone: '670' },
  //   { code: 'TM', label: 'Turkmenistan', phone: '993' },
  //   { code: 'TN', label: 'Tunisia', phone: '216' },
  //   { code: 'TO', label: 'Tonga', phone: '676' },
  //   { code: 'TR', label: 'Turkey', phone: '90' },
  //   {
  //     code: 'TT',
  //     label: 'Trinidad and Tobago',
  //     phone: '1-868',
  //   },
  //   { code: 'TV', label: 'Tuvalu', phone: '688' },
  //   {
  //     code: 'TW',
  //     label: 'Taiwan',
  //     phone: '886',
  //   },
  //   {
  //     code: 'TZ',
  //     label: 'United Republic of Tanzania',
  //     phone: '255',
  //   },
  //   { code: 'UA', label: 'Ukraine', phone: '380' },
  //   { code: 'UG', label: 'Uganda', phone: '256' },
  //   {
  //     code: 'US',
  //     label: 'United States',
  //     phone: '1',
  //     suggested: true,
  //   },
  //   { code: 'UY', label: 'Uruguay', phone: '598' },
  //   { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  //   {
  //     code: 'VA',
  //     label: 'Holy See (Vatican City State)',
  //     phone: '379',
  //   },
  //   {
  //     code: 'VC',
  //     label: 'Saint Vincent and the Grenadines',
  //     phone: '1-784',
  //   },
  //   { code: 'VE', label: 'Venezuela', phone: '58' },
  //   {
  //     code: 'VG',
  //     label: 'British Virgin Islands',
  //     phone: '1-284',
  //   },
  //   {
  //     code: 'VI',
  //     label: 'US Virgin Islands',
  //     phone: '1-340',
  //   },
  //   { code: 'VN', label: 'Vietnam', phone: '84' },
  //   { code: 'VU', label: 'Vanuatu', phone: '678' },
  //   { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  //   { code: 'WS', label: 'Samoa', phone: '685' },
  //   { code: 'XK', label: 'Kosovo', phone: '383' },
  //   { code: 'YE', label: 'Yemen', phone: '967' },
  //   { code: 'YT', label: 'Mayotte', phone: '262' },
  //   { code: 'ZA', label: 'South Africa', phone: '27' },
  //   { code: 'ZM', label: 'Zambia', phone: '260' },
  //   { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  // ];
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);


  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.name,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      // Check if the field has a children array
      if (field.children) {
        field.children.forEach((child) => {
          const { name, apiEndpoint } = child;
          if (apiEndpoint) {
            if(!options.hasOwnProperty(name)) {
              fetchOptions(apiEndpoint, name);
            }
          }
        });
      } else {
        // If there's no children array, use the field directly
        const { name, apiEndpoint } = field;
        if (apiEndpoint) {
          if(!options.hasOwnProperty(name)) {
            fetchOptions(apiEndpoint, name);
          }
        }
      }
    });
  }, [options]);

  const handleRemoveFieldRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  }


  const handleAddFieldRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Sub Service" && initialSubService,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Sub Service"
        && initialErrorSubService
        ,
      ], // Add an empty error object
    }));
  };

  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!isNaN(rowIndex)) {
        updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
      } else {
        updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
      }

      return updatedValues;
    });
  };

  const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
        return updatedValues;
      });

    }
  };

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    fileIndex
  ) => {
    debugger;
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];
        debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = '';
        }

        return updatedValues;
      });

    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];
debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });

    }
  };

  const handleInputChange = async (
    event,
    index,
    sectionTitle,
    field,
    fileIndex
  ) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, 'SOBARI')
    // debugger;

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (!isNaN(index)) {
              updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
            } else {
              updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
            }

            return updatedErrors;
          });
          return;
        // }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = "";
          }
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => (i === index ? { ...item, [name]: value } : item)
          );
        } else {
          updatedValues[sectionTitle] = {
            ...updatedValues[sectionTitle],
            [name]: value,
          }

        }
        return updatedValues;

      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
            (item, i) =>
              i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          updatedErrors[sectionTitle] = {
            ...updatedErrors[sectionTitle],
            [name]: fieldErrors,
          };
        };
        return updatedErrors;
      });

      if(name === 'user_id') {
        const aa = value;
        const dataItem = options[name].find(option => option.id === value);
        debugger;
        setFormValues((prevValues) => {
          return {
            ...prevValues,
            'Employee Information': {
              ...prevValues['Employee Information'],
              [name]: value,
              job_level: dataItem ? dataItem.job_level : 'Job Level', // Set default value if dataItem is undefined
              job_position: dataItem ? dataItem.position : 'Job Position', // Set default value if dataItem is undefined
              organization_name: dataItem ? dataItem.organization_name : 'Organization Name', // Set default value if dataItem is undefined
              employe_status: dataItem ? dataItem.employe_status : 'Employee Status', // Set default value if dataItem is undefined
            }
          };
        });

      }

    }
  }


  useEffect(() => {
      dispatch(
        getEmployeTransfer({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,

        })
      );
  }, []);



/*  useEffect(() => {
    if (
      status !== ACTION_TYPE.DEFAULT_STATUS
    ) {

      dispatch(
        getEmployeTransfer({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [ status]);
  */

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues(fields), actionType !== 'ADD_NEW_GROUP'
        ? {
          id: null,
          status: false,
        }
        : {})
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */


  const initialFormValues = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialSubService]
            : fieldGroup.title === "Attachment"
              ? initialAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
              );
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialErrorValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialErrorValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialErrorSubService]
            : fieldGroup.title === "Attachment"
              ? initialErrorAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: "" }),
                {}
              );
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());

  const [detailRow, setDetailRow] = useState(/*[
        {
            "children": {
                "Employee ID": "100000005",
                "Employee Name": "Employe 1 test",
                "Effective Date": "20 Apr 2024",
                "Transfer Type": "Promotion"
            }
        },
        {
            "title": "Manage Transfer",
            "children": [
                {
                    "type": "Job Position",
                    "from": "Admin Finance",
                    "to": "Admin Finance"
                },
                {
                    "type": "Organization Name",
                    "from": "Sales & Marketing",
                    "to": "Finance & Accounting"
                },
                {
                    "type": "Job Level",
                    "from": "Manajer Divisi",
                    "to": "Manajer Divisi"
                },
                {
                    "type": "Employment Status",
                    "from": "Contract",
                    "to": "Contract"
                },
                {
                    "type": "Approval Line",
                    "from": {
                        "name": "Muslikhul Adib",
                        "position": "-"
                    },
                    "to": {
                        "name": "Muslikhul Adib",
                        "position": "-"
                    }
                }
            ]
        },
        {
            "title": "Attachment",
            "children": {
                "attachments": [
                    {
                        "id": 1,
                        "employe_transfer_id": 4,
                        "url": "https://storage.googleapis.com/ppm_storage/employe_transfers/1713517830_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                        "filename": "1713517830_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                        "size": 165745,
                        "created_at": "2024-04-19T09:10:31.000000Z",
                        "updated_at": "2024-04-19T09:10:31.000000Z"
                    }
                ],
                "Notes": "asdfasdf"
            }
        }
    ]*/{});

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getEmployeTransfer({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [isActive]);

  /*
   * OLD
  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    
    for (const key in data) {
      // console.log(key, "key");
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value ?.id : value,
        }));
      }
    }
  };
  */
  const setFormValuesFromSelectedData = async (
    /*data = {
        "id": 3,
        "Employee Information": {
            "employee_id": 5,
            "requested_at": "2024-04-19",
            "effective_date": "2024-04-20",
            "reason_id": 1,
            "notes": "asdkflkasdjf"
        },
        "Attachment": {
            "attachment": [
                {
                    "filename": "1713517958_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                    "id": 1,
                    "employe_resign_id": 3,
                    "url": "https://storage.googleapis.com/ppm_storage/employe_resigns/1713517958_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                    "name": "1713517958_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                    "size": 165745,
                    "created_at": "2024-04-19T09:12:39.000000Z",
                    "updated_at": "2024-04-19T09:12:39.000000Z"
                }
            ]
        }
    }
  */
    idFromDetail
  ) => {
    setIsLoadedFormValues(false);
    // nanti ini di uncomment kalau udah dijalan functionnya
    let data;
    if(idFromDetail) {
      data = rows.filter((row) => row.id == idFromDetail)[0];
    } else {
      data = rows.filter((row) => selectedData.includes(row.id))[0];
    }

    const { id } = data;
//     debugger;

    if (id) {
      try {
        const {data} = await(await axios.get(`/employe-transfers/${id}/edit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }))?.data;
        debugger;

          setFormValues(data)
          setErrors(cloneObjectWithEmptyStrings(data, 'attachment'))
          setIsLoadedFormValues(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    // if (isLoadedFormValues) {
      // for (const key in data) {
      //   let value;

      //   if (key === "Client" && data[key]["date"]) {
      //     // Handle date using dayjs
      //     if (dayjs(data[key]["date"], "DD/MM/YYYY").isValid()) {
      //       value = dayjs(data[key]["date"], "DD/MM/YYYY");
      //       data[key]["date"] = value;
      //       value = { ...data[key] };
      //     } else {
      //       value = "";
      //       data[key]["date"] = value;
      //       value = { ...data[key] };
      //     }
      //   } else if (Array.isArray(data[key])) {
      //     // Corrected condition to check if the current property is an array
      //     value = data[key].slice();
      //   } else if (typeof data[key] === 'object' && data[key] !== null) {
      //     // Handle nested objects
      //     value = { ...data[key] };
      //   } else {
      //     // Handle regular values
      //     value = data[key];
      //   }

      //   setFormValues((prevFormValues) => ({
      //     ...prevFormValues,
      //     [key]: value,
      //   }));
      // }

      // };

    // }

  };

  const setFormValuesFromClientId = async (id) => {
    try {
      const {data} = await(await axios.get(`/clients/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }))?.data;

        data['client_id'] = data.id;
        delete data.id
        debugger;
        for (const key in data) {
          if (formValues.hasOwnProperty(key)) {
            // Special handling for the 'status' property
            const value = key === "status" ? data[key] === "active" : data[key];
            setFormValues((prevFormValues) => ({
              ...prevFormValues,
              [key]: typeof value === "object" ? value ?.id : value,
            }));
          }
        }

        setErrors(initialErrors);

    } catch (error) {
      console.error('Error fetching sub-service categories:', error);
    }
  };


  const setDetailRowFromSelectedData = async (dataSelected) => {
    const {id} = dataSelected;
    // debugger;
    setIsLoadedFormValues(false);
    try {
        const response = await axios.get(`/employe-transfers/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        let data = response.data.data;
        debugger;
        setDetailRow(data || [
        {
            "children": {
                "Employee ID": "100000005",
                "Employee Name": "Employe 1 test",
                "Effective Date": "20 Apr 2024",
                "Transfer Type": "Promotion"
            }
        },
        {
            "title": "Manage Transfer",
            "children": [
                {
                    "type": "Job Position",
                    "from": "Admin Finance",
                    "to": "Admin Finance"
                },
                {
                    "type": "Organization Name",
                    "from": "Sales & Marketing",
                    "to": "Finance & Accounting"
                },
                {
                    "type": "Job Level",
                    "from": "Manajer Divisi",
                    "to": "Manajer Divisi"
                },
                {
                    "type": "Employment Status",
                    "from": "Contract",
                    "to": "Contract"
                },
                {
                    "type": "Approval Line",
                    "from": {
                        "name": "Muslikhul Adib",
                        "position": "-"
                    },
                    "to": {
                        "name": "Muslikhul Adib",
                        "position": "-"
                    }
                }
            ]
        },
        {
            "title": "Attachment",
            "children": {
                "attachments": [
                    {
                        "id": 1,
                        "employe_transfer_id": 4,
                        "url": "https://storage.googleapis.com/ppm_storage/employe_transfers/1713517830_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                        "filename": "1713517830_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                        "size": 165745,
                        "created_at": "2024-04-19T09:10:31.000000Z",
                        "updated_at": "2024-04-19T09:10:31.000000Z"
                    },
                     {
                        "id": 1,
                        "employe_transfer_id": 4,
                        "url": "https://storage.googleapis.com/ppm_storage/employe_transfers/1713517830_Internal Memo Libur Cuti Bersama Idul Fitri 1445 H.pdf",
                        "filename": "1713517830_InternalH.pdf",
                        "size": 1222222745,
                        "created_at": "2024-04-19T09:10:31.000000Z",
                        "updated_at": "2024-04-19T09:10:31.000000Z"
                    }

                ],
                "Notes": "asdfasdf"
            }
        }
    ]);
        setIsLoadedFormValues(true);

      } catch (error) {
        console.error("Error fetching client data:", error);
    } finally {
      setIsLoadedFormValues(true);
    }
    
  };


  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  /*
   * OLD
   * const handleFileSelect = (
    file,
    fieldName
  ) => {
    const [name] = [fieldName]
    // debugger;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: file,
    }));
  };
  */

  /*
   * OLD
  const handleInputChange = async (event, field) => {
    const { name, value, type } = event.target;
    const newErrors = { ...errors };
    console.log(name, value, "name, value");
    // debugger
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        if (fileError) {
          newErrors[name] = fileError;
          setErrors(newErrors);
          return;
        }
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: file,
        }));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name === "client_id") {
      if (value) {
        await setFormValuesFromClientId(value);
      }
    }

  };
  */

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
  }

  const validateFileInput = (
    fieldValue,
    field,
    sectionTitle,
    index,
    sectionErrors
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }

    }

    return "";
  };


  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Sub Service")
          // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" || fieldValue === undefined ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };


  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {



        const { title } = fieldGroup;
        const sectionErrors =
          fieldGroup.title === "Sub Service"
            ? []
            : {};


        fieldGroup.children.forEach((field) => {
          const { name, type } = field;
          if (
            fieldGroup.title === "Sub Service"
          ) {

            formValues[title].forEach((sectionValues, index) => {
              // Check if the field is of type "file" and an array
              if (
                type === INPUT_TYPE.FILE &&
                Array.isArray(sectionValues[name])
              ) {
                sectionValues[name].forEach((file, subIndex) => {
                  // Handle file input validation
                  const fileError = validateFileInput(file, field);

                  if (!sectionErrors[index]) {
                    sectionErrors[index] = {};
                  }

                  if (!sectionErrors[index][name]) {
                    sectionErrors[index][name] = [];
                  }

                  sectionErrors[index][name][subIndex] = fileError;

                  if (fileError) {
                    valid = false;
                    // debugger;
                  }
                });
              } else if (type !== "file") {
                const fieldValue =
                  sectionValues[name] !== undefined ? sectionValues[name] : "";
                const error = validateFieldHelper(fieldValue, field);

                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

                sectionErrors[index][name] = error;

                if (error) {
                  valid = false;
                }

              }
            });
          } else {
            if (
              type === INPUT_TYPE.FILE &&
              Array.isArray(formValues[title][name])
            ) {

              formValues[title][name].forEach((file, subIndex) => {
                // Handle file input validation
                const fileError = validateFileInput(file, field);

                if (!sectionErrors[name]) {
                  sectionErrors[name] = [];
                }

                sectionErrors[name][subIndex] = fileError;

                if (fileError) {
                  valid = false;
                }
              });
            } else if (type !== "file") {
              // Handle regular input field validation
              const fieldValue =
                formValues[title] && formValues[title][name] !== undefined
                  ? formValues[title][name]
                  : "";
              const error = validateFieldHelper(fieldValue, field);

              sectionErrors[name] = error;

              if (error) {
                valid = false;
              }
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });


    setErrors(newErrors);

    // debugger;
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
          debugger;
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        } else if (actionType === "ADD_NEW_GROUP") {
          handleCloseFormModal(true);
          handleFormModal(ACTION_TYPE.ADD)
        }
        setSelectedData([]);
      }
    }
  };

    const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    // setIsLoadedFormValues(false);
    setActionType(actionType);
    setFormModal(true);
    // setErrors(initialErrors);
    const aaa = selectedData;
//     debugger;
//     if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
    if (actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Employee Transfer");
      setFormValuesFromSelectedData(dataSelected?.id);
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Employee Transfer");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `the employee transfer '${data.map((row) => row.employe_name).join(", ")}'`
      );
    } else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle("Detail Employee Transfer");
      setDetailRowFromSelectedData(dataSelected)
    } else {
      setTitle("Add Employee Transfer");
      setFormValues(initialFormValues())
      setErrors(initialErrors())
    }
  };

  /*
   * OLD
  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Employee Transfer");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Employee Transfer");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `the applicant '${data.map((row) => row.name).join(", ")}'`
      );
    } else if (actionType === "ADD_NEW_GROUP") {
      setTitle("Add New Group");
      resetFormValues();
    }  else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle("Detail Employee Transfer");
      setDetailRowFromSelectedData(dataSelected)
    } else {
      resetFormValues();
      setTitle("Add Employee Transfer");
    }
  };
  */

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD || actionType === 'ADD_NEW_GROUP') {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);

    setFormModal(false);

    setFormValues(initialFormValues())
    setErrors(initialErrors())
    // resetFormValues()
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues()
  };

  const handleAddRow = () => {
    dispatch(addEmployeTransfer(token, formValues));
  };

  const handleEditRow = () => {
    const aa =formValues;
    debugger;
    dispatch(editEmployeTransfer(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteEmployeTransfer(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */
const Detail = () => {
  const renderDetailRows = () => {
    const rows = [];

    if (Array.isArray(detailRow)) {
      detailRow.forEach((section, index) => {
        if (section.title) {
          if (section.title === "Manage Transfer") {
            const tableHeaders = Object.keys(section.children[0]);
            const tableRows = section.children.map((item, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                {tableHeaders.map((header, headerIndex) => (
                  <TableCell key={`cell-${rowIndex}-${headerIndex}`}  style={{ padding: '10px' }}>
                    {/* OLD typeof item[header] === 'object' ? `${item[header].name} as ${item[header].position}` : item[header] */}
                    {typeof item[header] === 'object' ? (
                      <React.Fragment>
                        {item[header].name} {/* OLD <span style={{ color: '#666' }}>as {item[header].position}</span>*/}
                      </React.Fragment>
                    ) : item[header]}

                  </TableCell>
                ))}
              </TableRow>
            ));

            rows.push(
              <Grid item xs={12} key={`section-${index}`} sx={{ mt: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 800, marginBottom:1}}>Manage Transfer</Typography>
                <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                  <Table>
                     <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                      <TableRow>
                        {tableHeaders.map((header, headerIndex) => (
                          <TableCell key={`header-${headerIndex}`}  style={{ padding: '10px', textTransform:'capitalize', fontWeight:'600' }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRows}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            );
          } else {
            // rows.push(
            //   <Grid item xs={12} key={`section-${index}`} sx={{ mt: 2 }}>
            //     <Typography variant="h6" sx={{ fontWeight: 800 }}>{section.title}</Typography>
            //   </Grid>
            // );
          }
        } else {
          // Handle sections without a title here
      // Assuming these sections have a "children" property
      const sectionKeys = Object.keys(section.children);
      sectionKeys.forEach((key, rowIndex) => {
        rows.push(
           <React.Fragment key={`row-${rowIndex}`}>
              <Grid item xs={4}>
                <Typography variant="body2">{key}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">{section.children[key]}</Typography>
              </Grid>
            </React.Fragment>
        );
      });
        }
      });
    } else {
      for (const key in detailRow) {
        if (key !== 'attachments') {
          rows.push(
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <Typography variant="body2">{key}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">{detailRow[key]}</Typography>
              </Grid>
            </React.Fragment>
          );
        }
      }
    }
    return rows;
  };

  const renderAttachments = () => {
    if (Array.isArray(detailRow)) {
      return detailRow.map((section, index) => {
        if (section.title === "Attachment" && section.children.attachments) {
          return (
            <React.Fragment key={`section-${index}`}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 800 }}>Attachment</Typography>
              </Grid>
              <Grid item xs={12}>
                {section.children.attachments.map((attachment, attachmentIndex) => (
                  <AttachmentItem key={attachmentIndex} item={attachment} title="Attach File" />
                ))}
              </Grid>
              {section.children.Notes && ( // Check if Notes exists
              <>
              <Grid item xs={3}>
                <Typography variant="body1">Reason</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{section.children.Notes}</Typography>
              </Grid>
              </>
            )}
            </React.Fragment>
          );
        }
        return null;
      });
    } else {
      if (detailRow['attachments']) {
        return (
          <React.Fragment>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 800 }}>Attachment</Typography>
            </Grid>
            <Grid item xs={12}>
              {detailRow['attachments'].map((attachment, attachmentIndex) => (
                <AttachmentItem key={attachmentIndex} item={attachment} title="Attach File" />
              ))}
            </Grid>
          </React.Fragment>
        );
      }
    }
    return null;
  };

  return (
    <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' } }}>
      {renderDetailRows()}
      {renderAttachments()}
    </Grid>
  );
};



  const renderFields = (section, index, field) => {
    if (isLoadedFormValues) {
      const currentFormValues =
        section.title === "Sub Service"
          ? formValues[section.title][index]
          // : section.title === "Attachment"
          //   ? formValues[section.title][index]
          : formValues[section.title];
      const currentErrors =
        section.title === "Sub Service"
          ? errors[section.title][index]
          // : section.title === "Attachment"
          //   ? errors[section.title][index]
          : errors[section.title];

      // debugger;
      // If section.title is undefined, use the general errors
      if (!section.title) {
        currentErrors = errors;
      }
      return (
        <>
          {section.children ?.map((field) => {
            // Check if children array exists
/*
          const checkSalesQuotation = formValues['Check Sales Quotation'];

          const [isClientIdExist, isClientIdANDPeriodeExist] = [
            !checkSalesQuotation ||
              checkSalesQuotation.client_id === undefined ||
              checkSalesQuotation.client_id === null ||
              checkSalesQuotation.client_id === '',
            !checkSalesQuotation ||
              checkSalesQuotation.client_id === undefined ||
              checkSalesQuotation.client_id === null ||
              checkSalesQuotation.client_id === '' ||
              checkSalesQuotation.periode === undefined ||
              checkSalesQuotation.periode === null ||
              checkSalesQuotation.periode === '',
          ];*/

/*
 if (field.title === "Sub Service" && field.children) {
        return {
          ...field,
          children: field.children.map((subField, index) => {
            if(subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
              if (subField.name === "sub_service_id") {
                return {
                  ...subField,
                  additionalProps: {
                    ...subField.additionalProps,
                    disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                     // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                };
              } else {
              // return {
              //   ...subField,
              //   additionalProps: {
              //     ...subField.additionalProps,
              //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
              //   },
              // };
            }
          } else {
            if (subField.name === "discount" || subField.name === "vat_percent"  || subField.name === "subtotal" || subField.name === "criteria_id") {
              // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
              return {
                ...subField,
                additionalProps: {
                  ...subField.additionalProps,
                  disabled: true
                  // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                  // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                },
              };
            }}
            return subField;
          }),
        };
      }
      */
            let optionsData;

            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              // if (isRequired) {
              //   optionsData = [
              //     // field.required &&
              //     //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //     //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //     //     name: "Choose Option",
              //     //   },
              //     ...options[field.name],
              //   ];
              // } else {
              //   optionsData = [...options[field.name]];
              // }

                if (field.name === "sub_service_id") {

                  field = {
                    ...field,
                    additionalProps: {
                      ...field.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };

                  // Filter options based on sub_service_id values that are not used
                  const usedSubServiceIds = formValues['Sub Service']
                  .filter((_, i) => i !== index) // Exclude the sub_service_id at the current index
                  .map(subService => subService?.sub_service_id); // Use optional chaining to prevent errors if subService is undefined

                  // Filter options based on sub_service_id values that are not used
                  optionsData = options[field.name].filter(option => !usedSubServiceIds.includes(option.id));
                  console.warn('usedSubServiceIds', usedSubServiceIds);
                  console.warn('optionsData', optionsData);

                }  else if (field.name === "criteria_id") {

                    const usedSubServiceIds = formValues['Sub Service']
                    .filter((_, i) => i === index)
                    .map(subService => subService?.sub_service_id);
                    // console.log(usedSubServiceIds,'KKKK');

                    optionsData = options[field.name].filter(option => usedSubServiceIds.includes(option.service_id));

                } else {
                  optionsData = [...options[field.name]];

//                   if (field.name === "sales_quotation_id") {
//                     field = {...field, additionalProps: {
//                         ...field.additionalProps,
//                         disabled: isClientIdANDPeriodeExist ,
//                       },
//                       disabled: isClientIdANDPeriodeExist
//                     }
//                   }
                }

              } else {

                if(!currentFormValues[field.name]) {
                  currentFormValues[field.name] = field.defaultValue
                }

                if (field.name === "price" || field.name === "quantity" || field.name === "discount" || field.name === "vat_percent"  || field.name === "criteria_id") {
                  field = {...field, additionalProps: {
                    ...field.additionalProps,
                    disabled: formValues['Sub Service'][index] && (formValues['Sub Service'][index]['sub_service_id'] === undefined || formValues['Sub Service'][index]['sub_service_id'] === null || formValues['Sub Service'][index]['sub_service_id'] === '')
                  }}
                  console.log(field)
                }

            }


          const startIconMappings = {
              USD: { subtotal: 'USD ', price: 'USD ' },
              Rp: { subtotal: 'Rp ', price: 'Rp ' },
              EUR: { subtotal: 'EUR ', price: 'EUR ' },
              CNY: { subtotal: 'CNY ', price: 'CNY ' },
              SGD: { subtotal: 'SGD ', price: 'SGD ' },
              GBP: { subtotal: 'GBP ', price: 'GBP ' },
              AUD: { subtotal: 'AUD ', price: 'AUD ' },
            };


            const labelMappings = {
              USD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              Rp: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              EUR: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              CNY: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              SGD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              GBP: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              AUD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
            };


            if(formValues['Service'] && formValues['Service']['currency']) {
              const currency = formValues['Service']['currency'];

              if (currency && startIconMappings[currency] && startIconMappings[currency][field.name]) {
                field = { ...field, startIcon: startIconMappings[currency][field.name] };
              }

              if (currency && labelMappings[currency] && labelMappings[currency].includes(field.name)) {
                field = { ...field, label: `${currency} ` };
              }
            }


            /*
             * EFFECTIVE DATE
             *
             * if(formValues['Employee Information'] && formValues['Employee Information']['effective_date']) {

                const currentDate = new Date(); // Current date
                const effectiveDate = new Date(formValues['Employee Information']['effective_date']); // Effective date from the form values

                if (effectiveDate < currentDate) {
                  field = {
                    ...field
                      ,additionalProps: {
                      ...field.additionalProps,
                      disabled: true
                    },
                    disabled:true
                  }
                }

              }
              */



            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
              case INPUT_TYPE.DISCOUNT:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.FILE:
                console.log(currentFormValues[field.name], 'SOBARIIIIII', field.name, section.title, currentFormValues)
                fieldComponent = (
                  <div>
                    {currentFormValues[field.name].map(
                      (_, fileIndex) => (
                        <div key={fileIndex}>
                          <Grid container spacing={0.3}>
                            <Grid item xs={11.3}>
                              <FilePicker
                                indexFile={fileIndex}
                                field={field}
                                formValues={
                                  currentFormValues[field.name][fileIndex]
                                }
                                errors={currentErrors}
                                onFileSelect={(file) =>
                                  handleFileSelect(
                                    file,
                                    index,
                                    section.title,
                                    field.name,
                                    fileIndex
                                  )
                                }
                                handleInputChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    section.title,
                                    field,
                                    fileIndex
                                  )
                                }
                                tip={field ?.tip}
                                URIForViewDocument={currentFormValues[field.name][fileIndex]['url']}
                              />
                            </Grid>

                            <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ...removeButton,
                                }}
                                onClick={() =>
                                  handleRemoveFile(
                                    section.title,
                                    index,
                                    fileIndex,
                                    field.name
                                  )
                                }
                                disabled={
                                  currentFormValues[field.name].length === 1
                                }
                              >
                                <>
                                  <img
                                    src={RemoveIcon}
                                    alt={`Remove ${section.title}`}
                                  />

                                </>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}

                    <Box sx={{ ...justifyContentEnd }}>
                      <Button
                        onClick={() => handleAddFile(section.title, index, field.name)}
                      >
                        Add More
                      </Button>
                    </Box>
                  </div>
                );

                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) => {
                      handleInputBlur(field.name, section.title, index, e);
                    }}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.DIVIDER:
                fieldComponent = (
                  <Divider sx={field.style}/>
                );
                break;
              case INPUT_TYPE.TYPOGRAPHY:
                fieldComponent = (
                  <Typography variant={field.variant} sx={field.style}>{field.label}</Typography>
                );
                break;
//               OLD
//               case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
//                 fieldComponent = (
//                   <Typography key={field.name} variant={field.variant} sx={field.style}>{field.label} {field.name === 'price_services_info' ? currentFormValues[field.name] : formatCurrencyWithoutLable(currentFormValues[field.name])}</Typography>
//                 );
//                 break;
              case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
                if (field.name === 'price_services_info') {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            {field.label} {currentFormValues[field.name]}
                        </Typography>
                    );
                } else if (field.name === 'discount_info_to_rp') {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            ({field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])})
                        </Typography>
                    );
                } else {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            {field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])}
                        </Typography>
                    );
                }
              break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.CHECKBOX:
                fieldComponent = (
                  <CheckboxGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.RADIO:
                fieldComponent = (
                  <RadioGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    errors={currentErrors}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.DATE:
                fieldComponent = (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              // Add cases for other field types
              default:
                fieldComponent = null;
            }

            return (
              <Grid
                item
                xs={field.gridWidth || 12}
                key={field.name}
                sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
              >
                {fieldComponent}
              </Grid>
            );
          })}
        </>
      );
    }
  };

  const renderSectionFields = (section) => {
    if (isLoadedFormValues) {
      // if (section.title === "Sub Service" || section.title === "Attachment") {
      if (section.title === "Sub Service") {
        // Render Sub Service or Attachment section
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
              </Box>
            )}
            {formValues[section.title].map((rowValues, index) => (
              <div key={index}>
                <Grid container spacing={0.3}>
                  <Grid item xs={11.3}>
                    <Grid container spacing={1}>
                      {renderFields(section, index)}
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ...removeButton,
                      }}
                      onClick={() => handleRemoveFieldRow(section.title, index)}
                      // OLD disabled={(actionType === ACTION_TYPE.ADD && formValues[section.title].length === 1) || actionType === ACTION_TYPE.EDITED}>
                      disabled={formValues[section.title].length === 1}>
                      <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                    </Button>
                  </Grid>
                </Grid>
                {formValues[section.title].length > 1 && (
                  <Divider sx={{ mt: 2, mb: 2 }} />
                )}
              </div>
            ))}
            {
              /* ********************** OLD **********************
               actionType !== ACTION_TYPE.EDITED && (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)} disabled={
                //(actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER && formValues[section.title].length === 1) ||
                actionType === ACTION_TYPE.EDITED}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
              */
            (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
            }
          </Grid>
        );
      } else {
        // Render other sections using renderFields function
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
                {/* Subtitle */}
                {section.subtitle && (
                  <Typography variant="subtitle2">{section.subtitle}</Typography>
                )}
              </Box>
            )
            }
            <Grid container spacing={1}>
              {renderFields(section)}
            </Grid>
          </Grid >
        );
      }
    }
  };

  const renderFormContent = () => {
    if(isLoadedFormValues) {
       if (actionType === ACTION_TYPE.ADD || actionType === ACTION_TYPE.EDITED ) {
        return (
          <>
            <Grid
              container
              spacing={1.5}
              sx={{
                // overflowX: "auto",
                // height: "52vh",
                // paddingBottom: "2rem",
              }}
            // className="smooth-scroll"
            >
              {/* {JSON.stringify(isLoadedFormValues)} */}
              {/* <div class="test">
                {JSON.stringify(actionType)}
              {JSON.stringify(formValues)}
              </div>
              */}
              {/* <hr /> */}
              {/* {JSON.stringify(errors)} */}
              {isLoadedFormValues ?
                fields.map((section) => renderSectionFields(section)) : (<Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '100%'
                }}><FacebookCircularProgress /></Box>)
              }
            </Grid>
          </>
        )
       } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
        return <ConfirmDelete userDeleted={userDeleted} />;
      } else if (actionType === ACTION_TYPE.DETAIL) {
        return <Detail />
      }
    } else {
    return(<Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'
    }}><FacebookCircularProgress /></Box>)
  }

  };

  const renderActionButtons = () => {
    return (
      <Box>
        {actionType !== ACTION_TYPE.DETAIL ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
              id="cancel-modal"
            >
              Cancel
        </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              id="submit-modal"
            >
              {actionType === ACTION_TYPE.DELETED || actionType === "PREVIEW" ? "Yes" : "Save"}
            </Button>
          </>
        ) : (
            <>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => handleCloseFormModal()}
                sx={{ mr: 2 }}
                id="cancel-modal"
              >
                Cancel
        </Button>
              <Button
                variant="outlined"
                sx={{ ...btnWhite }}
                onClick={() => handleCloseFormModal()}
                id="submit-modal"
              >
                Oke
        </Button>
            </>
          )}
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
    console.log(formValues, 'formValues')
  }, [search, status, isLoading, formValues]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getEmployeTransfer({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getEmployeTransfer({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getEmployeTransfer({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getEmployeTransfer({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getEmployeTransfer({ token, limit, search, status, sortDirection, sortBy })
    // );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                placeholder="Search by employee name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
                id="search-input"
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
                id="search-button"
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Employee Transfer"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.PAGING}
            search={search}
            linkCRUD={{ create: "add", update: `edit/${selectedData}` }}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED && actionType !== ACTION_TYPE.DETAIL && actionType !== ACTION_TYPE.ADD && actionType !== ACTION_TYPE.EDITED  ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
          <Modal open={formModal} onClose={() => handleCloseFormModal()}>
            <Box sx={{
              '& form > .MuiBox-root':{
                width: '750px',
              }
            }}>
              <Form
                title={title}
                onCloseModal={() => handleCloseFormModal()}
                elementForm={renderFormContent}
                elementActionButton={renderActionButtons}
                onSubmit={handleSubmit}
              />
            </Box>
          </Modal>
        )}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default EmployeTransfer;
