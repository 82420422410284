import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { blue, green, yellow,red } from '@mui/material/colors';

/*
const timelineData = [
  {
    "user": "Manager Divisi",
    "role": "Manager Divisi",
    "timestamp": "4 February 2024, 19:23",
    "status": "Rejected"
},
{
    "user": "Application Requested",
    "role": null,
    "timestamp": "2 Feb 2024, 09:12",
    "status": null
},
  // {
  //   user: 'Ahmad Torff',
  //   role: 'Staff',
  //   activity: 'Registration',
  //   timestamp: '7 July 2023, 09:05',
  //   status: 'Finish',
  // },
  // {
  //   user: 'Ahmad Torff',
  //   role: 'Staff',
  //   activity: 'Registration',
  //   timestamp: '7 July 2023, 09:05',
  //   status: 'Submitted',
  // },
  // {
  //   user: 'Ahmad Torff',
  //   role: 'Staff',
  //   activity: 'Registration',
  //   timestamp: '7 July 2023, 09:00',
  //   status: 'Pending',
  // },
];
*/

const DetailTimeline = ({timelineData}) => {
    const getStatusColor = (status) => {
        switch (status) {
          case 'Finish':
          case 'Submitted':
          case 'Approved':
            return green[500];
          case 'Pending':
          case 'Waiting':
            return yellow[500];
          case 'Rejected':
            return red[500];
          default:
            return '#1268B8';
        }
      };

  return (
    <Grid container spacing={2} sx={{p:3}}>
      <Grid item xs={12}>
    <Typography variant="h6" sx={{ fontWeight: 800 }}>Status</Typography>
    </Grid>
    <Timeline sx={{'& .MuiTimelineItem-root:before': {flex:0}}}>
      {timelineData.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              style={{ backgroundColor: '#1268B8',boxShadow: 'none',  }}
            />
            {index < timelineData.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="subtitle1">
              {item.user && `${item.user} ${item.role ? '• ' + item.role : ''}`}
              {item.activity && item.activity}
            </Typography>
            <Typography variant="body2">{item.timestamp}</Typography>
            <Typography
              variant="body2"
              style={{ color: getStatusColor(item.status) }}
            >
              {item.status}
            </Typography>
            {(item.reason && item.reason != '-') && <Typography variant="body2">Reason : {item.reason}</Typography> }
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
    </Grid>
  );
};

export default DetailTimeline;
