export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAILED = "GET_ACTIVITY_FAILED";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAILED = "ADD_ACTIVITY_FAILED";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_FAILED = "EDIT_ACTIVITY_FAILED";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILED = "DELETE_ACTIVITY_FAILED";
export const GET_ACTIVITY_DETAILS_SUCCESS = "GET_ACTIVITY_DETAILS_SUCCESS";
export const GET_ACTIVITY_DETAILS_FAILED = "GET_ACTIVITY_DETAILS_FAILED";
