import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "./../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  fourColor,
  secondColor,
  btnTableToolbar,
  imgBtnToolbar,
} from "../../../util/style";

import Form, {
  DatePickerInput,
  FilePicker,
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextArea,
  TextInput,
} from "../../../components/Form";

// import {
//     addApplication,
//   deleteApplication,
//   editApplication,
//   getApplication,
// } from "./clients/actions";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  INPUT_TYPE,
  SELECT_OPTION,
  MODE_CRUD_WITH,
} from "../../../util/function";

import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import axios, { headersAPIToken } from "../../../core/axios_config";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { clearUpdateId } from "./client/action";

// http://localhost:3000/operation-management/domestic-trademark/trademark-registartions

/**
 * @typedef {object} FieldType
 * @property {string} name
 * @property {string} label
 * @property {string} type
 * @property {string|number|boolean} defaultValue
 * @property {string} required
 * @property {string} placeholder
 * @property {boolean} disabled
 * @property {boolean} multiple
 * @property {string} [aliasLabel]
 * @property {string} [errorMessage]
 * @property {string} [apiEndpoint]
 * @property {object[]} [options]
 * @property {Function} [validate]
 * @property {Array<String>} [allowedExtensions] - list of extention allowed
 * @property {Number} [maximumSizeInMB] - Maximum size on MB
 */

/**
 * @typedef {object} SectionField
 * @property {string} title
 * @property {string} subtitle
 * @property {FieldType[]} children
 */

const ANOTHER_INPUT_TYPE = Object.freeze({
  FILE: "File",
});

export const STATUS_APPLICANT = Object.freeze({
  PENDING: "Pending",
  SUBMITTED: "Submitted",
  TECHNICAL_SERVICE: "Technical Services",
  HEARING: "Hearing",
  OPPOSITION: "Opposition",
  OPPOSITION_REBUTTAL: "Opposition Rebuttal",
  APPEAL: "Appeal",
  REGISTERED: "Registered",
  PUBLICATION: "Publication",
});

const SEARCH_BY_KEYS = Object.freeze({
  code: "No. References",
  no_registration: "No. Registration",
  trademark: "Trademark",
  name: "Applicant's Name",
});

/** @type {SectionField[]} */
const fields = [
  {
    title: "Registration",
    subtitle:
      "Enter the trademark registration number obtained from the registration process.",
    children: [
      {
        name: "no_agenda",
        defaultValue: "",
        label: "No. Agenda",
        required: true,
        type: INPUT_TYPE.TEXT,
      },
      {
        name: "registration_date",
        type: INPUT_TYPE.DATE,
        defaultValue: "",
        required: true,
        label: "Registration Date",
        placeholder: "Select Date",
      },
    ],
  },
  {
    title: "Attachment",
    subtitle: "Upload the documents obtained from the trademark registration process.",
    children: [
      {
        name: "attachments",
        defaultValue: "",
        label: "Upload File",
        multiple: true,
        type: ANOTHER_INPUT_TYPE.FILE,
        required: true,
        allowedExtensions: [".pdf"],
        maximumSizeInMB: 5,
      },
    ],
  },
];

/** @type {SectionField[]} */
const fields2 = [
  {
    title: "Status",
    subtitle:
      "After the Submitted status, please select the status that corresponds to the relevant steps of the Trademark process.",
    children: [
      {
        name: "status",
        label: "Status",
        defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        required: true,
        type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
        options: [
          ...Object.values(STATUS_APPLICANT)
            .filter((status) => status !== "Pending" && status !== "Submitted")
            .map((status) => ({ id: status, name: status })),
          { id: "Substantive", name: "Substantive" },
        ],
      },
      {
        name: "date",
        type: INPUT_TYPE.DATE,
        label: "Date",
        defaultValue: "",
        placeholder: "Select Date",
        required: true,
      },
      {
        name: "no",
        type: INPUT_TYPE.NUMBER,
        label: "No",
        defaultValue: "",
        placeholder: "Input No",
        required: true,
      },
      {
        name: "notes",
        type: INPUT_TYPE.TEXTAREA,
        label: "Notes (Optional)",
        defaultValue: "",
        placeholder: "Input Notes",
        required: false,
      },
    ],
  },
  {
    title: "Attachment",
    subtitle: "Upload the documents obtained from the trademark registration process.",
    children: [
      {
        name: "attachments",
        defaultValue: "",
        label: "Upload File",
        multiple: true,
        type: ANOTHER_INPUT_TYPE.FILE,
        required: true,
        allowedExtensions: [".pdf"],
        maximumSizeInMB: 5,
      },
    ],
  },
];

const TrademarkRegistration = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const columns = [
    {
      field: "code",
      headerName: "No. Reference",
      width: 170,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <a
            onClick={() => navigate(`detail/${params.row.id}`)}
            style={{ textDecoration: "none", cursor: "grab", color: "blue" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "no_agenda",
      headerName: "No. Agenda",
      width: 190,
      disableColumnMenu: true,
    },

    // {
    //   field: "date_receipt",
    //   headerName: "Receipt Date",
    //   width: 180,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "class",
    //   headerName: "Class",
    //   width: 90,
    //   disableColumnMenu: true,
    // },
    {
      field: "category",
      headerName: "Category",
      width: 180,
      disableColumnMenu: true,
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   width: 180,
    //   disableColumnMenu: true,
    // },
    {
      field: "applicant",
      headerName: "Applicant",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "trademark",
      headerName: "Trademark/Title",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "created_at_formated",
      headerName: "Date",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        const statusColors = {
          Draft: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
          Approved: { bgColor: successSurfaceColor, colors: successMainColor },
          Rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
          // Add more statuses as needed
        };

        const statusInfo = statusColors[params.value] || {
          bgColor: infoSurfaceColor,
          colors: infoMainColor,
        };
        return (
          <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
            {params.value}
          </StatusBadge>
        );
      },
    },
  ];

  const baseUrl = "/trademark-registrations";

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [statusApplicant, setStatusApplicant] = useState(STATUS_APPLICANT.PENDING);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("code");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New Application");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [category, setCategory] = useState(ACTION_TYPE.ALL_CATEGORY);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.ALL_DATE);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateElm, setStartDateElm] = useState(null);
  const [endDateElm, setEndDateElm] = useState(null);
  const [periodElm, setPeriodElm] = useState(null);
  const [period, setPeriod] = useState(null);
  const [isLoadFormValues, setIsLoadFormValues] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParam, setSearchParam] = useSearchParams();

  const { data, message, isLoading, pagination } = useSelector(
    (state) => state.applications
  );

  const { updateId: updateIdReducer } = useSelector(
    (state) => state.trademarkRegistration
  );

  //* ------------------ [ SEARCH PARAMS ] --------------------- */
  //* Watcher for update id
  useEffect(() => {
    const updateId = searchParam.get("update");
    if (updateId && !isNaN(updateId) && updateIdReducer !== null) {
      if (updateId == updateIdReducer) {
        //TODO: set mode edit;
        // handleUpdateFromDetail(updateIdReducer);
        setSelectedId(updateIdReducer);
        // console.log({ updateIdReducer });
      } else {
        console.error("Update id not same");
        console.log(updateId);
      }
    } else {
      console.log("Bukan mode edit");
    }
  }, [searchParam, updateIdReducer]);

  useEffect(() => {
    if (selectedId && !isNaN(selectedId)) {
      // handleUpdateFromDetail(selectedId);
    }
    console.count("detail id");
  }, [selectedId]);

  //* Clear search param
  const clearSearchParam = () => {
    setSearchParam("");
  };

  //* Dummy rows;
  const rows = [
    {
      id: 1,
      code: "A0001",
      no_agenda: "R2024035166",
      // date_receipt: "26/04/2024, 14:33",
      // class: "2",
      category: "Merek Dagang",
      // type: "Umum",
      applicant: "Sari Mutiara",
      trademark: "ANTUM BEAUTY’S",
      created_at_formated: "13/06/2021, 02:10",
      status: "Pending",
    },
    {
      id: 2,
      code: "A0002",
      no_agenda: "R2024035164",
      // date_receipt: "26/04/2024, 14:33",
      // class: "21",
      category: "Merek Dagang",
      // type: "Umum",
      applicant: "Carter Culhane",
      trademark: "AIRA",
      created_at_formated: "13/06/2021, 02:10",
      status: "Submitted",
    },
  ];

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  const fetchOptions = async (apiEndpoint, name) => {
    try {
      const response = await axios.get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the response data has the 'fullname' property
      const modifiedData = response.data.data.map((item) => {
        if (item.hasOwnProperty("fullname")) {
          return {
            ...item,
            name: item.fullname,
          };
        } else {
          return item;
        }
      });

      // console.log("fetchOptions", name, modifiedData);
      setOptions((prevOptions) => ({
        ...prevOptions,
        [name]: modifiedData,
      }));
    } catch (error) {
      console.error(`Error fetching ${name} options:`, error);
    }
  };

  /** ====================================================================== */
  /**                                 WATCHER                                */
  /** ====================================================================== */

  // useEffect(() => {
  //   fetchOptions("master/services?main_service_id=100", "category");
  // }, []);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch();
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch();
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [category]);

  useEffect(() => {
    if (isActive === true) {
      if (status !== ACTION_TYPE.DEFAULT_STATUS) {
        //TODO: dispatch
        // dispatch( );
      }
    }
  }, [isActive]);

  /** ====================================================================== */
  /**                                  FORM                                  */
  /** ====================================================================== */

  const initialValueFile = [""];

  /**
   *
   * @param {'submit'|'approval'} status
   */
  const initialFormValues = (status) => {
    const result = {};
    //* fielter by status applicant
    let iterableFieldGroup = [];
    if (status == "approval") {
      iterableFieldGroup = [...fields2];
    } else if (status === "submit") {
      iterableFieldGroup = [...fields];
    }
    // fields.filter((fieldGroup) => {
    //   if (status == "submit" && fieldGroup.title !== "Status") {
    //     return fieldGroup;
    //   } else if (status === "approval" && fieldGroup.title !== "Registration") {
    //     return fieldGroup;
    //   }
    // });

    iterableFieldGroup.forEach((fieldGroup) => {
      if (Array.isArray(fieldGroup.children)) {
        fieldGroup.children.forEach((field) => {
          if (field.type === ANOTHER_INPUT_TYPE.FILE) {
            result[field.name] = initialValueFile || [""];
          } else {
            result[field.name] = field.defaultValue || "";
          }
        });
      }
    });
    console.trace("Initial form");
    console.count("initial form");
    return { ...result };
  };

  /**
   *
   * @param {'submit'|'approval'} status
   * @returns {{  }}
   */
  const initialError = (status) => {
    const newErrors = {};
    //* fielter by status applicant
    let iterableFieldGroup = [];
    if (status === "approval") {
      iterableFieldGroup = [...fields2];
    } else if (status === "submit") {
      iterableFieldGroup = [...fields];
    }
    // fields.filter((fieldGroup) => {
    //   if (status == "submit" && fieldGroup.title !== "Status") {
    //     return fieldGroup;
    //   } else if (status === "approval" && fieldGroup.title !== "Registration") {
    //     return fieldGroup;
    //   }
    // });

    iterableFieldGroup.forEach((fieldGroup) => {
      if (Array.isArray(fieldGroup.children)) {
        fieldGroup.children.forEach((field) => {
          if (field.type === ANOTHER_INPUT_TYPE.FILE) {
            newErrors[field.name] = [""];
          } else {
            newErrors[field.name] = "";
          }
        });
      }
    });
    return { ...newErrors };
  };

  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});

  /**
   *
   * @param {'submit'|'approval'} status
   * @returns void
   */
  const resetForm = (status) => {
    setFormValues(initialFormValues(status));
    setErrors(initialError(status));
  };

  /** ====================================================================== */
  /**                                 VALIDATE                               */
  /** ====================================================================== */

  /**
   *
   * @param {File} file
   * @param {import("./part/imutalbe_state").FieldType} field
   */
  const validateFileInput = (file, field) => {
    if (file instanceof File) {
      const allowExtension = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowExtension.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (file.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  /**
   *
   * @param {String} fieldName Field Name
   */
  const validateField = (value, fieldName, sectionName) => {
    let sectionGroup = null;
    if (statusApplicant === STATUS_APPLICANT.PENDING) {
      sectionGroup = fields.find((fieldGroup) => fieldGroup.title === sectionName);
    } else {
      sectionGroup = fields2.find((fieldGroup) => fieldGroup.title === sectionName);
    }

    if (sectionGroup) {
      const field = sectionGroup.children.find((field) => field.name === fieldName);
      if (field) {
        const updatedError = { ...errors };
        let messageError = "";
        const { name, defaultValue, label, errorMessage, validate, required, type } =
          field;
        if (type === ANOTHER_INPUT_TYPE.FILE) {
          formValues[name].forEach((file, indexFile) => {
            const errorFile = validateFileInput(file, field);
            updatedError[indexFile] = errorFile;
          });
        } else {
          const fieldValue = formValues[name] || value;
          if (
            required &&
            (fieldValue === null ||
              fieldValue === undefined ||
              fieldValue === "" ||
              fieldValue === defaultValue ||
              fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
          ) {
            messageError = `Please enter ${String(label).toLowerCase()}`;
          }

          if (fieldValue && validate && !validate(fieldValue)) {
            messageError = errorMessage || `${label} is invalid`;
          }
          updatedError[name] = messageError;
        }
        setErrors(updatedError);
        return messageError;
      }
    }
  };

  /**
   * @return {Boolean}
   */
  const validateForm = () => {
    let valid = true;
    const copyError = { ...errors };
    let iterableField = null;
    if (statusApplicant === STATUS_APPLICANT.PENDING) {
      iterableField = [...fields];
    } else {
      iterableField = [...fields2];
    }

    /**
     *
     * @param {any} valueForm
     * @param {FieldType} field
     */
    const validateFieldHelper = (valueForm, field) => {
      let messageError = "";
      const { defaultValue, required, errorMessage, validate, label } = field;
      if (
        required &&
        (valueForm === null ||
          valueForm === undefined ||
          valueForm === "" ||
          valueForm === defaultValue ||
          valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        messageError = `Please input ${String(label).toLowerCase()}`;
        valid = false;
      }

      if ((valueForm || valueForm.length) && validate && !validate(valueForm)) {
        messageError = errorMessage || `${label} is invalid`;
        valid = false;
      }
      return messageError;
    };

    iterableField.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        fieldGroup.children.forEach((field) => {
          if (field.multiple) {
            const valueField = formValues[field.name];
            if (Array.isArray(valueField)) {
              copyError[field.name] = valueField.map((value) =>
                validateFieldHelper(value, field)
              );
            }
          } else {
            const valueField = formValues[field.name];
            copyError[field.name] = validateFieldHelper(valueField, field);
          }
        });
      }
    });
    console.log({ copyError });
    setErrors(copyError);
    return false;
  };

  /** ====================================================================== */
  /**                                 HANDLER                                */
  /** ====================================================================== */

  /**
   *
   * @param {String} fieldName
   * @param {Number} index
   */
  const handleRemoveItem = (fieldName, index) => {
    //* Remove element form on <index>
    setFormValues((prevValue) => {
      const updatedForm = { ...prevValue };
      const deleted = updatedForm[fieldName].splice(index, 1);
      console.log(deleted);
      return updatedForm;
    });

    //* Remove element error on <index>
    setErrors((prevError) => {
      const updatedError = { ...prevError };
      updatedError[fieldName].splice(index, 1);
      return updatedError;
    });
  };

  const handleAddItem = (fieldName) => {
    setFormValues((prevValue) => {
      const updatedForm = { ...prevValue };
      updatedForm[fieldName].push("");
      return updatedForm;
    });
  };

  const handleInputBlur = (event, fieldName, sectionName) => {
    const { name, value, type } = event.target;
    validateField(value, fieldName, sectionName);
  };

  /**
   *
   * @param {import("react").SyntheticEvent} event
   */
  const handleInputChange = (event, field, index) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const updatedError = { ...errors };
        const errorFile = validateFileInput(file, field);
        if (Array.isArray(errors[field.name])) {
          if (!isNaN(index)) {
            updatedError[field.name][index] = errorFile;
          } else {
            console.error("Invalid Index on input file");
          }
        } else {
          updatedError[field.name] = errorFile;
        }
        setErrors(updatedError);

        // const is = formVaal === updatedError;
      } else {
        console.log("Invalid file on input type file");
      }
      //* Return if input type file, just validate file and set error
      return;
    }

    setFormValues((prevValue) => ({ ...prevValue, [name]: value }));
  };

  const handleFileSelect = (file, fieldName, index) => {
    if (isNaN(index)) {
      console.error("Invalid index at file change");
      return;
    }
    const newFormValues = { ...formValues };
    const fileValues = [...newFormValues[fieldName]];
    fileValues[index] = file;
    newFormValues[fieldName] = [...fileValues];
    setFormValues(newFormValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleUpdateApplicant();
    } else {
      console.log("Not Valid");
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    // Get detail Cocument for check status;
    setIsLoadFormValues(false);
    if (selectedData.length && actionType === ACTION_TYPE.ADD) {
      setTitle("Submit Registration");
      const id = selectedData[0];
      const selectedRow = rows.find((row) => row.id == id);
      if (selectedRow) {
        setSelectedId(id);
        console.log({ selectedRow });
        setStatusApplicant(selectedRow?.status);
        //* ------------ Set form by status data ---------------;
        if (selectedRow?.status === STATUS_APPLICANT.PENDING) {
          resetForm("submit");
        } else {
          // console.log({ selectedRow });
          resetForm("approval");
        }
        setIsLoadFormValues(true);
      } else {
        console.log("Selected row not found");
      }
    }
  };

  const handleUpdateFromDetail = async (detailId) => {
    try {
      setIsLoadFormValues(false);
      setFormModal(true);
      // setSelectedId(detailId);
      const craftedUrl = baseUrl.concat("/", detailId, "/detail");
      const { data: responseData } = await axios.get(craftedUrl, headersAPIToken(token));
      if (responseData?.data) {
        // setStatusApplicant(responseData?.data?.status); // TODO: Disabled for a while
        if (responseData?.data.status === STATUS_APPLICANT.PENDING) {
          resetForm("submit");
        } else {
          resetForm("approval");
        }
        setIsLoadFormValues(true);
      }
    } catch (error) {
      console.error("Failed get detail:", error);
      //!Development purpose, bypass reset form
      // setStatusApplicant(STATUS_APPLICANT.SUBMITTED);
      resetForm("approval");
      setIsLoadFormValues(true);
    }
  };

  const handleCloseFormModal = (important = false) => {
    // setFormModal(false);
    if (important) {
      setFormModal(false);
      resetForm("submit");
      setSelectedData([]); //buat disabled button toolbar
      return;
    }
    setConfirmModal(true);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetForm("submit");
    clearSearchParam(); // clear search param from update id
    setSelectedData([]); //buat disabled button toolbar
    dispatch(clearUpdateId()); // clear updateId from reducer
    setSelectedId(0);
  };

  /** -------------------------------------------------------------------- */
  /**                          [ HANDLER REDUCER ]                         */
  /** -------------------------------------------------------------------- */

  const handleUpdateApplicant = () => {
    const newPayload = { ...formValues };
    newPayload["id"] = selectedId;
    //TODO: dispatch
    // dispatch()
  };

  //* -------------------------- [ GET DATA ] ------------------------------ */
  //TODO: Belum selesai
  const getDetailApplicant = async () => {
    const id = selectedData[0];
    try {
      if (!id) {
        throw new Error("Failed get detail, invalid ID");
      }
      setSelectedId(id);
      //! Dev only. by pass for development purpose
      return new Promise((resolve) => resolve({ status: STATUS_APPLICANT.SUBMITTED }));
      // const response = await axios.get("url", headersAPIToken(token));

      // if (response.status === 200 && response.data.status === true) {
      // }
    } catch (error) {
      console.error(`Failed get detail data for `);
    }
  };

  // useEffect(() => {
  //   console.log({statusApplicant});
  // },[statusApplicant])

  // useEffect(() => {
  //   console.log(selectedData);
  // }, [selectedData]);

  /* -------------------------------------------------------------------------- */
  /*                                COMPONENT                                   */
  /* -------------------------------------------------------------------------- */

  /**
   *
   * @param {{ field:FieldType }} param0
   * @returns
   */
  const ButtonAdd = ({ field }) => {
    return (
      <Box sx={{ ...justifyContentEnd }}>
        <Button onClick={() => handleAddItem(field.name)}>
          <span
            style={{
              fontSize: "1.5rem",
              marginRight: ".6rem",
              display: "inline-block",
            }}
          >
            +
          </span>
          Add More
        </Button>
      </Box>
    );
  };

  /**
   *
   * @param {{field:FieldType, index:number, disabled:boolean }} param0
   * @returns
   */
  const ButtonRemove = ({ field, index, disabled }) => {
    return (
      <Grid item xs={2} style={{ paddingBlock: ".2rem" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            ...removeButton,
          }}
          onClick={() => handleRemoveItem(field.name, index)}
        >
          <img src={RemoveIcon} alt={`Remove item`} />
        </Button>
      </Grid>
    );
  };

  const LoadingComponent = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  );

  const infoSvg = (size = 16) => {
    const color = warningMainColor;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.33334"
          y="1.33398"
          width="13.3333"
          height="13.3333"
          rx="6.66667"
          stroke={color}
          stroke-width="1.5"
        />
        <path
          d="M8.33334 11.334L8.33334 7.33398"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.00001 7.33398L8.33334 7.33398"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.33334 5.33464L8.33334 4.66797"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  /**
   * Render Warning header when status applicant is pending
   * @returns {React.JSX}
   */
  const WarningHeader = () => (
    <Box sx={{ marginInline: ".5rem" }}>
      <Grid container sx={{ background: warningSurfaceColor, borderRadius: ".4rem" }}>
        <Grid
          item
          xs={1}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          {infoSvg(24)}
        </Grid>
        <Grid item xs={11} sx={{ padding: ".4rem" }}>
          <Typography variant="body1" sx={{ color: warningMainColor, fontWeight: 600 }}>
            Attention
          </Typography>
          <p style={{ margin: ".3rem 0" }}>
            Before proceeding to the next stage, make sure you have a trademark registered
            with the relevant agency. Enter the trademark registration number and attach
            the documents you obtained from the registration process at the agency.
          </p>
        </Grid>
      </Grid>
    </Box>
  );

  const renderElementForm = (field, indexField, fieldGroup) => {
    const fieldOption = field.options || [];
    switch (field.type) {
      case INPUT_TYPE.TEXT:
      case INPUT_TYPE.NUMBER:
        return (
          <>
            <TextInput
              errors={errors}
              field={field}
              formValues={formValues}
              handleInputBlur={(event) =>
                handleInputBlur(event, field.name, fieldGroup.title)
              }
              handleInputChange={(event) => handleInputChange(event, field, null)}
            />
          </>
        );
      case INPUT_TYPE.TEXTAREA:
        return (
          <>
            <TextArea
              errors={errors}
              field={field}
              formValues={formValues}
              handleInputBlur={(event) =>
                handleInputBlur(event, field.name, fieldGroup.title)
              }
              handleInputChange={(event) => handleInputChange(event, field, null)}
              placeholder={field.placeholder}
              showError={true}
            />
          </>
        );
      case ANOTHER_INPUT_TYPE.FILE:
        return (
          <>
            <Grid>
              <Grid>
                {formValues[field.name]?.map((_, indexValue) => (
                  <Grid container>
                    <Grid item xs={11}>
                      <FilePicker
                        errors={errors}
                        field={field}
                        formValues={formValues[field.name][indexValue]}
                        handleInputChange={(event) =>
                          handleInputChange(event, field, indexValue)
                        }
                        onFileSelect={(file) =>
                          handleFileSelect(file, field.name, indexValue)
                        }
                        indexFile={indexValue}
                        URIForViewDocument={formValues[field.name][indexValue]?.url}
                        tip={"Max size: 5 MB. Format file: PDF."}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <ButtonRemove
                        index={indexValue}
                        disabled={formValues[field.name].length < 2}
                        field={field}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid>
                <ButtonAdd field={field} />
              </Grid>
            </Grid>
          </>
        );
      case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
        return (
          <SelectOneAutocomplete
            errors={errors}
            field={field}
            fieldOptions={fieldOption}
            formValues={formValues}
            handleInputBlur={(event) =>
              handleInputBlur(event, field.name, fieldGroup.title)
            }
            handleInputChange={(event) => handleInputChange(event, field, null)}
          />
        );
      case INPUT_TYPE.DATE:
        return (
          <React.Fragment key={field.name}>
            <DatePickerInput
              errors={errors}
              field={field}
              formValues={formValues}
              handleInputBlur={(event) =>
                handleInputBlur(event, field.name, fieldGroup.title)
              }
              handleInputChange={(event) => handleInputChange(event, field, null)}
            />
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  const renderFormContent = () => {
    // if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
    //   return <ConfirmDelete userDeleted={userDeleted} />;
    // }
    let iterableFields = null;
    if (statusApplicant === STATUS_APPLICANT.PENDING) {
      iterableFields = [...fields];
    } else {
      iterableFields = [...fields2];
    }
    // fields.filter((fieldGroup) => {
    //   if (statusApplicant === STATUS_APPLICANT.PENDING && fieldGroup.title !== "Status") {
    //     // console.log(statusApplicant);
    //     return fieldGroup;
    //   } else if (
    //     statusApplicant !== STATUS_APPLICANT.PENDING &&
    //     fieldGroup.title !== "Registration"
    //   ) {
    //     // console.log(statusApplicant);
    //     return fieldGroup;
    //   }
    // });

    // console.log({ iterableFields });
    // console.log({ statusApplicant, enum: STATUS_APPLICANT.PENDING });
    return (
      <>
        {isLoading || !isLoadFormValues ? (
          <LoadingComponent />
        ) : (
          <>
            {/* Header form */}
            {statusApplicant === STATUS_APPLICANT.PENDING && <WarningHeader />}
            {/* Content Form */}
            <Box sx={{ margin: ".4rem .5rem" }}>
              {iterableFields.map((fieldGroup) => {
                return (
                  <React.Fragment key={fieldGroup.title}>
                    <Box sx={{ margin: ".6rem 0" }}>
                      <Typography variant="h6" sx={{ fontWeight: "650" }}>
                        {fieldGroup?.title}
                      </Typography>
                      <Typography>{fieldGroup?.subtitle}</Typography>
                    </Box>
                    <Box>
                      {fieldGroup?.children?.map((field, indexField) =>
                        renderElementForm(field, indexField, fieldGroup)
                      )}
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </>
        )}
      </>
    );
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" disableElevation type="submit">
          Update
        </Button>
      </Box>
    );
  };

  const modifiedToolbar = () => {
    return (
      <span>
        <Button
          size="medium"
          color="primary"
          disabled={selectedData.length < 1}
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => handleFormModal(ACTION_TYPE.ADD)}
        >
          Update
        </Button>
      </span>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch();
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    //TODO: dispatch
    // dispatch();
  }

  function _getByLimit(limit) {
    //TODO: dispatch
    // dispatch();
  }

  function _search() {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      //TODO: dispatch
      //   dispatch( );
    }
  }

  function _status(status) {
    setStatus(status);
  }

  function _category(val) {
    setCategory(val);
  }

  const handlePeriodChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setPeriod(formattedDate);
      setPeriodElm(date);
    } else {
      console.error("Invalid start date:", date);
    }
  };

  useEffect(() => {
    if (period) {
      if (dayjs(period).isValid()) {
        //TODO: dispatch
        // dispatch();
      }
    }
  }, [period]);

  function _dateFilter(date) {
    if (date === "custom_date") {
      setShowDatePicker(true);
      setDateFilter(date);
    } else {
      setShowSelectDate(false);

      if (dateFilter == "custom_date" && date !== "custom_date" && date !== undefined) {
        setShowDatePicker(false);

        setStartDate(null);
        setStartDateElm(null);
        setEndDate(null);
        setEndDateElm(null);
      }

      if (date && status !== ACTION_TYPE.DEFAULT_STATUS) {
        setDateFilter(date);
      }
    }
  }

  const handleStartDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setStartDate(formattedDate);
      setStartDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleEndDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setEndDate(formattedDate);
      setEndDateElm(date);
    } else {
      console.error("Invalid end date:", date);
      // You might want to handle this error case gracefully
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setShowSelectDate(false);
      //TODO: dispatch
      //   dispatch( );
    }
  }, [startDate, endDate]);

  const handleSelectClose = (e) => {
    if (e) e.stopPropagation();
    setShowDatePicker(false);
    setShowSelectDate(false);
  };

  const placeholder = {
    code: "No. Reference",
    company_name: "Company",
    applicant: "Applicant",
  };

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    // console.log(selection);
    setSelectedData(selection || []);
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  const renderPlaceholderSearch = () => {
    let result = "";

    Object.entries(SEARCH_BY_KEYS).forEach(([name, key]) => {
      if (name === searchBy) {
        result = String(key).toLowerCase();
      }
    });
    return result;
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      {/*      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search attachment file name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
*/}
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}>
        <CardContent>
          <Grid container spacing={1.5}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder={`Search by ${renderPlaceholderSearch()}`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchIconBlack}
                        style={{ width: "20px", margin: " 0 8px 0 0" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <TextField
                      select
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      // sx={{
                      //   borderRadius,
                      // }}
                      style={{ borderRadius }}
                      value={searchBy}
                      sx={{
                        "& fieldset": {
                          border: "unset",
                        },
                        borderLeft: `2px solid #ddd`,
                        width: "16rem",
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      {Object.entries(SEARCH_BY_KEYS).map(([name, key]) => (
                        <MenuItem value={name}>{key}</MenuItem>
                      ))}
                      {/* <MenuItem value="company_name">Company</MenuItem>
                      <MenuItem value="applicant">Applicant</MenuItem> */}
                    </TextField>
                  ),
                }}
                sx={{
                  borderRadius: 4, // Adjust the borderRadius as needed
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            {/* ----------------- RECEIFE DATE  ---------------------- */}
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                <DatePicker
                  selectedDate={periodElm}
                  onChange={handlePeriodChange}
                  components={{
                    OpenPickerButton: CustomOpenPickerButton,
                  }}
                  format="MMMM YYYY"
                  views={["month", "year"]}
                  value={periodElm}
                  sx={{
                    ...inputDate,
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* ----------------- STATUS ---------------------- */}
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>Select Status</MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                {Object.values(STATUS_APPLICANT).map((value) => {
                  return <MenuItem value={value}>{value}</MenuItem>;
                })}
                {/* <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Submitted">Submited</MenuItem>
                <MenuItem value="Finish">Finish</MenuItem>
                <MenuItem value="Granted">Grandted</MenuItem> */}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Application"}
            useStyles={useStyles}
            // rows={dummyRows}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.MODAL}
            search={search}
            showAddToolbar={false}
            showEditToolbar={false}
            showDeleteToolbar={false}
            componentModifyToolbarLeft={modifiedToolbar}
            // linkCRUD={{ create: "add", update: `edit/${selectedData}` }}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default TrademarkRegistration;
