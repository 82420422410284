export const GET_PERFORMA_INVOICE_SUCCESS = "GET_PERFORMA_INVOICE_SUCCESS";
export const GET_PERFORMA_INVOICE_FAIL = "GET_PERFORMA_INVOICE_FAIL";

export const ADD_PERFORMA_INVOICE_SUCCESS = "ADD_PERFORMA_INVOICE_SUCCESS";
export const ADD_PERFORMA_INVOICE_FAIL = "ADD_PERFORMA_INVOICE_FAIL";

export const EDIT_PERFORMA_INVOICE_SUCCESS = "EDIT_PERFORMA_INVOICE_SUCCESS";
export const EDIT_PERFORMA_INVOICE_FAIL = "EDIT_PERFORMA_INVOICE_FAIL";

export const DELETE_PERFORMA_INVOICE_SUCCESS = "DELETE_PERFORMA_INVOICE_SUCCESS";
export const DELETE_PERFORMA_INVOICE_FAIL = "DELETE_PERFORMA_INVOICE_FAIL";

export const GET_DETAIL_PERFORMA_INVOICE_SUCCESS = "GET_DETAIL_PERFORMA_INVOICE_SUCCESS";
export const GET_DETAIL_PERFORMA_INVOICE_FAIL = "GET_DETAIL_PERFORMA_INVOICE_FAIL";

export const GET_DETAIL_FOR_EDIT_SUCCESS = "GET_DETAIL_FOR_EDIT_PROFORMA_INVOICE_SUCCESS";
export const GET_DETAIL_FOR_EDIT_FAIL = "GET_DETAIL_FOR_EDIT_PROFORMA_INVOICE_FAIL";

export const SET_ID_EDITED_DOCUMENT = "SET_ID_EDITED_DOCUMENT_PROFORMA_INVOICE";

export const SET_OPTION_KEY = "SET_OPTION_KEY";
export const CLEAR_OPTION_KEY = "CLEAR_OPTION_KEY";
