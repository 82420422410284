import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  GET_PAYMENT_REQUEST_DETAIL_FAILED,
  GET_PAYMENT_REQUEST_DETAIL_SUCCESS,
  CLEAR_DATA_DETAIL_PAYMENT_REQUEST,
  MARK_DETAIL_FOR_EDIT,
} from "./state";

const initialState = {
  data: null,
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
  id_for_edit: null,
  attachment: null
};

const detailPaymentRequestReducer = (
  state = initialState,
  { payload, type, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_PAYMENT_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        data: payload,
        pagination,
        message: null,
        isLoading: false,
      };
    case GET_PAYMENT_REQUEST_DETAIL_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case CLEAR_DATA_DETAIL_PAYMENT_REQUEST:
      return {
        data: null,
        pagination: null,
        message: null,
        isLoading: false,
        response_status: false,
        id_for_edit: null,
        attachment: null
      };
    case MARK_DETAIL_FOR_EDIT:
      return {
        ...state,
        id_for_edit: payload,
      };
    default:
      return state;
  }
};

export default detailPaymentRequestReducer;
