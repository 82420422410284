import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Autocomplete,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  secondColor,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  inputDate,
  btnTableToolbar,
  imgBtnToolbar,
  fourColor,
  FacebookCircularProgress,
  removeButton,
  alignItemsCenter,
  CustomOpenPickerButton,
} from "../../util/style";
import exportIcon from "./../../assets/export.png";
import axios, { urlRequest } from "../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../components/Form";
import {
  getAttendances,
  addAttendances,
  editAttendances,
  deleteAttendances,
  getAttendanceDetails,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { RequestLoading } from "../../util/global_state";
import ArrowBackWhiteIcon from "./../../assets/back-white.svg";

const DetailAttendance = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const ANOTHER_ACTION_TYPE = {
    PREVIEW: 'PREVIEW',
    MAP: 'MAP'
  }


  const [isPresenceHadir, setIsPresenceHadir] = useState(true);

  const isPresenceHadirDisabled = () => {
    return !isPresenceHadir;
  };

  // const isPresenceHadirRequired = () => {
  //   return isPresenceHadir;
  // };

  const fields = [
    {
      name: "date",
      type: INPUT_TYPE.DATE,
      label: "Date",
      defaultValue: "",
      required: true,
      formatDate: "DD/MM/YYYY", // Custom date format
      additionalProps: {
        disabled: true,
      },
    },
     {
      name: "presence",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Peresence",
      required: true,
      apiEndpoint: "/master/presences",
//       placeholder: "Select presence type",
      additionalProps: {
        disabled: false,
      },
    },
    {
      name: "time_off_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Time Off",
      required: true,
      apiEndpoint: "/master/time-off?status=active&with_attend=false",
//       placeholder: "Select presence type",
      additionalProps: {
        disabled: false,
      },
    },
    {
      name: "schedule_in_time",
      type: INPUT_TYPE.TIME,
      label: "Schedule In",
      defaultValue: "",
      required: true,
      additionalProps: {
        disabled: false,
      },
      gridWidth: 6,
    },
    {
      name: "clock_in_time",
      type: INPUT_TYPE.TIME,
      label: "Clock In",
      defaultValue: "",
      required: false, //isPresenceHadirRequired(),
      additionalProps: {
        disabled: isPresenceHadirDisabled(),
      },
      gridWidth: 6,
    },
    {
      name: "schedule_out_time",
      type: INPUT_TYPE.TIME,
      label: "Schedule Out",
      defaultValue: "",
      required: false,
      additionalProps: {
        disabled: false,
      },
      gridWidth: 6,
    },
    {
      name: "clock_out_time",
      type: INPUT_TYPE.TIME,
      label: "Clock Out",
      defaultValue: "",
      required: false, //isPresenceHadirRequired(),
      additionalProps: {
        disabled: isPresenceHadirDisabled(),
      },
      gridWidth: 6,
    },
    {
      name: "reason_in",
      type: INPUT_TYPE.TEXTAREA,
      label: "Reason for Editing",
      defaultValue: "",
      required: false,
      placeholder: "Reason for Editing",
    },
    // Add more fields as needed
  ];

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 140,
      disableColumnMenu: true,
    },
  //     ...(process.env.REACT_APP_API_URL !== "https://api-app.pacificpatent.com"
    ...(true
    ? [
    {
      field: "presence",
      headerName: "Presence",
      width: 140,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
            <StatusBadge isinfo={true}>{params.row.presence}</StatusBadge>
        );
      }
    },
    {
      field: "time_off",
      headerName: "Time Off",
      sortable: true,
      width: 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        const code = params.row.timeoff ? params.row.timeoff ?.code : "-";
        const desc = params.row.timeoff
          ? params.row.timeoff ?.description
            : "-";
        return (
          <Tooltip title={desc}>
            <StatusBadge isinfo={true}>{code}</StatusBadge>
          </Tooltip>
        );
      },
    },
  ]
  : [
      {
      field: "presence",
      headerName: "Presence",
      sortable: true,
      width: 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        const code = params.row.presence ? params.row.presence ?.code : "-";
        const desc = params.row.presence
          ? params.row.presence ?.description
            : "-";
        return (
          <Tooltip title={desc}>
            <StatusBadge isinfo={true}>{code}</StatusBadge>
          </Tooltip>
        );
      },
    },
    ]),
    {
      field: "schedule_in_time",
      headerName: "Schedule In",
      sortable: true,
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "clock_in_time",
      headerName: "Clock In",
      sortable: true,
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        const  isClockInRed = params.row.isClockInRed;
        return (
          <span style={{color:isClockInRed ? 'red' : 'inherit'}}>{params.value}</span>
        );
      }
    },
    {
      field: "schedule_out_time",
      headerName: "Schedule Out",
      sortable: true,
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "clock_out_time",
      headerName: "Clock Out",
      sortable: true,
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        const  isClockOutRed = params.row.isClockOutRed;
        return (
          <span style={{color:isClockOutRed ? 'red' : 'inherit'}}>{params.value}</span>
        );
      }
    },
        {
      field: "foto_in",
      headerName: "Photo Check In",
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => {
       // Check if foto_in data is '-'
        const isFotoInDash = params.row.foto_in === '-';

        // Render dash if foto_in data is '-'
        if (isFotoInDash) {
          return <div>-</div>;
        }

        // Render the link if foto_in data is not '-'
        return (
          <div style={{ color: "blue" }}>
            <a
              onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.PREVIEW, params.row, 'foto_in')}
              style={{ textDecoration: "none", cursor: "grab" }}
            >
              View Photo
            </a>
          </div>
        );
      }
    },
    {
      field: "lat_in",
      headerName: "Location Check In",
      sortable: false,
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        // Check if lat_in data is '-'
        const isLatInDash = params.row.lat_in === '-';

        // Render dash if lat_in data is '-'
        if (isLatInDash) {
          return <div>-</div>;
        }

        // Render the link if lat_in data is not '-'
        return (
          <div style={{ color: "blue" }}>
            <a
              onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.MAP, params.row, 'lat_in')}
              style={{ textDecoration: "none", cursor: "grab" }}
            >
              View Map
            </a>
          </div>
        );

      }
    },
    {
      field: "foto_out",
      headerName: "Photo Check Out",
      sortable: false,
      width: 140,
      disableColumnMenu: true,
      renderCell: (params) => {
        // Check if foto_out data is '-'
        const isFotoOutDash = params.row.foto_out === '-';

        // Render dash if foto_out data is '-'
        if (isFotoOutDash) {
          return <div>-</div>;
        }

        // Render the link if foto_out data is not '-'
        return (
          <div style={{ color: "blue" }}>
            <a
              onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.PREVIEW, params.row, 'foto_out')}
              style={{ textDecoration: "none", cursor: "grab" }}
            >
              View Photo
            </a>
          </div>
        );
      }
    },
    {
      field: "lat_out",
      headerName: "Location Check Out",
      sortable: false,
      width: 170,
      disableColumnMenu: true,
      renderCell: (params) => {
      // Check if lat_out data is '-'
      const isLatOutDash = params.row.lat_out === '-';

      // Render dash if lat_out data is '-'
      if (isLatOutDash) {
        return <div>-</div>;
      }

      // Render the link if lat_out data is not '-'
      return (
        <div style={{ color: "blue" }}>
          <a
            onClick={() => handleFormModal(ANOTHER_ACTION_TYPE.MAP, params.row, 'lat_out')}
            style={{ textDecoration: "none", cursor: "grab" }}
          >
            View Map
          </a>
        </div>
      );

      }
    },
    {
      field: "reason_in",
      headerName: "Note",
      sortable: false,
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: "reason_time_off",
      headerName: "Reason Time Off",
      sortable: false,
      width: 270,
      disableColumnMenu: true,
    },
  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState("attendance");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Time Off");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({
    employes: [],
  });
  const [categoryAttendances, setCategoryAttendances] = useState(
    ACTION_TYPE.DEFAULT_CATEGORY_TIME_OFF
  );
  const [periodDateElm, setPeriodDateElm] = useState(dayjs());
  const [periodDate, setPeriodDate] = useState(null);
  const [selectedIdEmployee, setSelectedIdEmployee] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const params = useParams();
  const [id, setId] = useState(parseInt(params.id));
  const [detailRow, setDetailRow] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: { attendances: rows, analytic },
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.attendances);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    setSelectedIdEmployee(parseInt(id));
  }, [id]);

  useEffect(() => {
    if (dayjs(periodDateElm).isValid()) {
      setPeriodDate(dayjs(periodDateElm).format("YYYY-MM"));
    }
  }, [periodDateElm]);

  const handleChange = ({ target: { value } }) => {
    if (value && value !== -1) {
      setSelectedIdEmployee(parseInt(value));
    } else {
      setSelectedIdEmployee(parseInt(id));
    }
  };

  useEffect(() => {
    setIsFetchLoading(true);

    /* ------------------------ Fetch Options on Columns ------------------------ */

    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("code")) {
            return {
              ...item,
              name: `${item.code} - (${item.description})`,
            };
          } else {
            return item;
          }
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));

        setIsFetchLoading(false);
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });

    /* ------------------------- Fetch employes options ------------------------- */

    axios
      .get("/master/employes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        setOptions((prevOptions) => ({
          ...prevOptions,
          employes: modifiedData,
        }));
      })
      .catch((error) => {
        console.error("Error fetching service categories:", error);
      });
  }, []);

  useEffect(() => {
    const STATE_NIHH = rows;
    console.log(STATE_NIHH, "STATE_NIHH");
  }, [rows]);

  useEffect(() => {
    // const fetchAttendanceDetails = async () => {
    if (selectedIdEmployee && periodDate) {
      dispatch(
        getAttendanceDetails({
          token,
          limit,
          search,
          sortDirection,
          sortBy,
          dateFilter: periodDate,
          userId: selectedIdEmployee,
        })
      );
    }
    // };
    // fetchAttendanceDetails();
  }, [selectedIdEmployee, periodDate, token]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
      })
    );
  };

  const handlePeriodDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM");
      setPeriodDate(formattedDate);
      setPeriodDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null })
  );
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
  }, [formValues]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getAttendanceDetails({
          userId: selectedIdEmployee,
          token,
          limit,
          search,
          sortDirection,
          sortBy,
          dateFilter: periodDate,
        })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];

    for (const key in data) {
      let value;

      if (key === "date") {
        if (dayjs(data[key], "DD/MM/YYYY").isValid()) {
          value = dayjs(data[key], "DD/MM/YYYY").format("YYYY-MM-DD");
        } else {
          value = "";
        }
      } else if (key === "user") {
        value = data[key].fullname;
      } else if (key.includes("_time")) {
        value =
          data[key] !== "-"
            ? dayjs(dayjs().format("YYYY-MM-DD") + " " + data[key])
            : "";
      } else if (key === "time_off_id") {
        value =
          typeof data[key] === "object" && data[key] !== null
            ? data[key].id
            : data[key]; // Extract the 'id' property from the 'time_off_id' object
        /*
        // Katanya dicomment dulu
        if (data[key]) {
          if (data[key] === 5) {
            // id 5 => Hadir
            setIsPresenceHadir(true);
          } else {
            setIsPresenceHadir(false);
          }

        } else {
          setIsPresenceHadir(false);
        }
        */
      } else {
        value = data[key];
      }

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [key]: value,
      }));
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected, clickedFromColumn) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Attendances");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Attendance");
      const data = rows.filter((row) => selectedData.includes(row.id));
      // setUserDeleted(data.map((row) => row.id).join(", "));
      setUserDeleted("attendance");
    } else if (actionType === ANOTHER_ACTION_TYPE.PREVIEW || actionType === ANOTHER_ACTION_TYPE.MAP) {
      setTitle("Preview");
      switch (clickedFromColumn) {
        case 'foto_in':
          setDetailRow({...detailRow, foto: dataSelected.foto_in });
          break;
        case 'foto_out':
          setDetailRow({...detailRow, foto: dataSelected.foto_out });
          break;
        case 'lat_in':
          setDetailRow({...detailRow, lat: dataSelected.lat_in,long: dataSelected.long_in, });
          break;
        case 'lat_out':
          setDetailRow({...detailRow, lat: dataSelected.lat_out,long: dataSelected.long_out, });
          break;
        default:
          break;
      }

      const aa = detailRow;
      debugger;
      setIsFetchLoading(true);
    } else {
      resetFormValues();
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
      }
    } else {
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  const handleAddRow = () => {
    // dispatch(addAttendances(token, formValues));
  };

  const handleEditRow = () => {
    const formatTime = (time) =>
      dayjs(time).isValid() ? dayjs(time).format("HH:mm") : null;

    dispatch(
      editAttendances(
        token,
        Object.assign(formValues, {
          clock_in_time: formatTime(formValues.clock_in_time),
          clock_out_time: formatTime(formValues.clock_out_time),
          schedule_in_time: formatTime(formValues.schedule_in_time),
          schedule_out_time: formatTime(formValues.schedule_out_time),
        }),
        formValues.id
      )
    );
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteAttendances(token, { data: { ids } }, true));
  };

  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "time_off_id") {
      // setIsPresenceHadir(value == 5 ? true : false);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);

    if (name === "time_off_id") {
      const value = formValues[name];
      // setIsPresenceHadir(value == 5 ? true : false);
    }
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `${field.label} is required`;
      } else if (validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `${field.label} is required`;
        valid = false;
      }

      if (validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        }
        setSelectedData([]);
      }
    }
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <Grid container spacing={1}>
          {fields.map((field) => {
            const fieldOptions = Array.isArray(options[field.name])
              ? [
                field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  // name: `Select ${field.label}`,
                  name: `Choose Option`,
                },
                ...options[field.name],
              ]
              : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
                break;
              case INPUT_TYPE.DATE:
                fieldComponent = (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
                break;
              case INPUT_TYPE.TIME:
                fieldComponent = (
                  <TimePickerInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                  />
                );
                break;
              default:
                fieldComponent = null;
            }

            return (
              <Grid
                item
                xs={field.gridWidth || 12}
                key={field.name}
                sx={{ paddingTop: "unset !important" }}
              >
                {fieldComponent}
              </Grid>
            );
          })}
        </Grid>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }else if (actionType === ANOTHER_ACTION_TYPE.PREVIEW) {
      return <Grid
        item
        xs={12}>
          <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'}}>
          {isFetchLoading && <FacebookCircularProgress />}
          <img
              src={detailRow.foto}
              loading="lazy"
              style={{
                  maxWidth: isFetchLoading ? '0px' : '100%',
                  maxHeight:isFetchLoading ? '0px' : '400px',
                  objectFit: 'cover',
              }}
              onLoad={() => setIsFetchLoading(false)}
              onError={() => setIsFetchLoading(false)}
          />
          </Box>
      </Grid>
  } else if (actionType === ANOTHER_ACTION_TYPE.MAP) {
          return <Grid
        item
        xs={12}>
          <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'}}>
        <iframe src={`//maps.google.com/maps?q=${detailRow.lat},${detailRow.long}&z=19&output=embed`}  width="600" height="400" frameborder="0" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
    </Box>
    </Grid>
  }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={actionType === ANOTHER_ACTION_TYPE.PREVIEW || actionType === ANOTHER_ACTION_TYPE.MAP ?
            () => handleCloseFormModal(true) :
            undefined
          }
          type={actionType !== ANOTHER_ACTION_TYPE.PREVIEW && actionType !== ANOTHER_ACTION_TYPE.MAP ? "submit" : undefined}
        >
          {actionType === ACTION_TYPE.DELETED || actionType === ANOTHER_ACTION_TYPE.PREVIEW || actionType === ANOTHER_ACTION_TYPE.MAP ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (selectedIdEmployee && periodDate) {
      dispatch(
        getAttendanceDetails({
          userId: selectedIdEmployee,
          token,
          page,
          limit,
          search,
          sortDirection,
          sortBy,
          dateFilter: periodDate,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getAttendanceDetails({
        userId: selectedIdEmployee,
        token,
        page,
        limit,
        search,
        sortDirection,
        sortBy,
        dateFilter: periodDate,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getAttendanceDetails({
        userId: selectedIdEmployee,
        token,
        limit,
        search,
        sortDirection,
        sortBy,
        dateFilter: periodDate,
      })
    );
  }
  function _search() {
    dispatch(
      getAttendanceDetails({
        userId: selectedIdEmployee,
        token,
        limit,
        search,
        sortDirection,
        sortBy,
        categoryAttendances,
      })
    );
  }
  function _status(status) {
    setStatus(status);
  }

  function _category(val) {
    setCategoryAttendances(val);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const ModifyToolbarRight = () => {
    return (
      <span>
        <Button
          size="medium"
          color="primary"
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            window.open(
              process.env.REACT_APP_API_URL +
              `/api/attendances/export?date_filter=${periodDate}&user_id=${selectedIdEmployee}`,
              "_blank"
            );
          }}
        >
          <img
            src={exportIcon}
            style={{
              ...imgBtnToolbar,
              marginRight: 5,
            }}
          />
          Export
        </Button>
      </span>
    );
  };

  const Analytics = () => {
    const css = {
      display: "flex",
      justifyContent: "space-between",
      "> div": {
        display: "flex",
        flexDirection: "column",
        gap: ".25rem",
        "& span": {
          fontSize: "1rem",
        },
        "& .count": {
          fontWeight: "bold",
        },
        "& .subtitle": {
          color: `${fourColor} !important`,
        },
      },
    };
    return (
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          {isLoading == true || isFetchLoading === true ? (
            <FacebookCircularProgress />
          ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "5rem",
                  "> div": {
                    flexGrow: "1",
                    gap: "0.3rem",
                    display: "flex",
                    flexDirection: "column",
                  },
                  "> div:first-child": {
                    paddingLeft: ".5rem",
                  },
                  "> div:last-child": {
                    paddingRight: ".5rem",
                  },
                }}
              >
                <div>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Present
                </Typography>
                  <Box sx={{ ...css }}>
                    <Box>
                      <span className="count">{analytic.total_on_time}</span>
                      <span className="subtitle">On Time</span>
                    </Box>
                    <Box>
                      <span className="count">
                        {analytic.total_late_clock_in}
                      </span>
                      <span className="subtitle">Late Clock In</span>
                    </Box>
                    <Box>
                      <span className="count">
                        {analytic.total_early_clock_in}
                      </span>
                      <span className="subtitle">Early Clock In</span>
                    </Box>
                  </Box>
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Not Present
                </Typography>
                  <Box sx={{ ...css }}>
                    <Box>
                      <span className="count">{analytic.total_absent}</span>
                      <span className="subtitle">Absent</span>
                    </Box>
                    <Box>
                      <span className="count">{analytic.total_no_clock_in}</span>
                      <span className="subtitle">No Clock In</span>
                    </Box>
                    <Box>
                      <span className="count">{analytic.total_no_clock_out}</span>
                      <span className="subtitle">No Clock Out</span>
                    </Box>
                    <Box>
                      <span className="count">{analytic.total_invalid}</span>
                      <span className="subtitle">Invalid</span>
                    </Box>
                  </Box>
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                    Away
                </Typography>
                  <Box sx={{ ...css }}>
                    <Box>
                      <span className="count">{analytic.total_day_off}</span>
                      <span className="subtitle">Day Off</span>
                    </Box>
                    <Box>
                      <span className="count">{analytic.total_time_off}</span>
                      <span className="subtitle">Time Off</span>
                    </Box>
                  </Box>
                </div>
              </Box>
            )}
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}

      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={0.7} sx={{ ...alignItemsCenter }}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              ...removeButton,
            }}
            onClick={() => navigate(-1)}
          >
            <img src={ArrowBackWhiteIcon} />
          </Button>
        </Grid>

        <Grid item xs={11.3}>
          <Card
            style={{
              boxShadow: "unset",
              borderRadius,
              border: "1px solid #ddd",
            }}
          >
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Autocomplete
                    options={options["employes"] || []}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      options["employes"].find(
                        (option) => option.id === selectedIdEmployee
                      ) || -1
                    }
                    onChange={(event, newValue) =>
                      handleChange({
                        target: {
                          value: newValue ?.id || -1,
                        },
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                    sx={{ ...formGroup }}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="id">
                    <DatePicker
                      selectedDate={periodDateElm}
                      onChange={handlePeriodDateChange}
                      value={periodDateElm}
                      format="MMMM YYYY"
                      views={["month", "year"]}
                      components={{
                        OpenPickerButton: CustomOpenPickerButton,
                      }}
                      sx={{
                        ...inputDate,
                        marginTop: "0.25rem",
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* -------------------------------------------------------------------------- */
      /*                                  ANALYTICS                                 */
      /* -------------------------------------------------------------------------- */}

      <Analytics />

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Attendance"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handleFormModal={handleFormModal}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            showAddToolbar={false}
            componentModifyToolbarRight={ModifyToolbarRight}
            additionalProps={{
              currentPath: "/human-resources/attendance",
            }}
          />
        </CardContent>
      </Card>

      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default DetailAttendance;
