export const GET_STOCK_OUT_SUCCESS = "GET STOCK OUT SUCCESS";
export const GET_STOCK_OUT_FAILED = "GET STOCK OUT FAILED";

export const ADD_STOCK_OUT_SUCCESS = "ADD STOCK OUT SUCCESS";
export const ADD_STOCK_OUT_FAILED = "ADD STOCK OUT FAILED";

export const UPDATE_STOCK_OUT_SUCCESS = "UPDATE STOCK OUT SUCCESS";
export const UPDATE_STOCK_OUT_FAILED = "UPDATE STOCK OUT FAILED";

export const DELETE_STOCK_OUT_SUCCESS = "DELETE STOCK OUT SUCCESS";
export const DELETE_STOCK_OUT_FAILED = "DELETE STOCK OUT FAILED";