import { TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";

export const CustomStyledTextArea = styled(({ error, InputProps, ...rest }) => (
  <TextareaAutosize {...rest} InputProps={InputProps} />
))(({ theme, error, disabled }) => ({
  width: "98%",
//   height: "auto !important",
  padding: "8px",
  border: `1px solid ${error ? "red" : " #ccc"}`,
  backgroundColor: disabled ? "#F5F5F5" : "transparent", // Set background color to #F5F5F5 if disabled
  borderRadius: "4px",
  fontFamily: "inherit",
  fontSize: "inherit",
  resize: "vertical",
  transition: "border-color 0.2s ease-in-out",
  "&:hover": {
    borderColor: error ? "red" : " #aaa",
  },
  "&:focus": {
    outlineColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    // boxShadow: '0 0 0 2px rgba(0, 123, 255, 0.25)',
  },
}));
