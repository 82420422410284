import axios from "../../../../core/axios_config";
import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, GET_MENUS,GET_MENUS_LOADING } from "./state";
import { RequestLoading } from "../../../../util/global_state";

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/login", { email, password });
      return response.data;
    } catch (error) {
      return error;
    }
  };
};

export const emailCheck = (email) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/check-email", { email });
      return response.data;
    } catch (error) {
      return error;
    }
  };
};

export const getMenuAccessed = (token) => {
  return async (dispatch) => {
    dispatch(getMenusLoading())
    try {
      const response = await axios.get(`/master/menus`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          with_children: true,
        },
      });
      const { data } = response.data;
      dispatch(getMenus(data)); // Assuming you have a loginSuccess action
      return data;
    } catch (error) {
      throw error; // Rethrow the error to the component
    }
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};



export const getMenusLoading = () => {
  // debugger;
  return {
    type: GET_MENUS_LOADING,
  };
};

export const getMenus = (data) => {
  // debugger;
  return {
    type: GET_MENUS,
    payload: data,
  };
};

export const loginFailed = (errorMessage) => {
  return {
    type: LOGIN_FAILED,
    payload: errorMessage,
  };
};

export const logout = () => {
  return { type: LOGOUT };
};
