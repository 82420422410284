import React from 'react';
import { IconButton } from '@mui/material';
import { HourglassBottomRounded } from '@mui/icons-material';
// import Icon from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledButton = styled('div')({});

const ButtonCom = ({
  height,
  width,
  backgroundColor,
  textColor,
  onClick,
  textButton,
  padding,
  isDisable = false,
  isLoading = false,
  sufixSize,
  suffixIcon,
  prefixIcon,
  prefixSize,
  sx,
}) => {
  return (
    <StyledButton
      onClick={isDisable || isLoading ? () => {} : onClick}
      sx={{
        height: height || 42,
        width: width || '100%',
        backgroundColor: isDisable ? '#bdbdbd' : backgroundColor || 'primary.main',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding,
        cursor: isDisable ? 'default' : 'pointer',
        ...sx,
      }}
    >
      {/* {isLoading ? (
        <div style={{ height: 24, width: 24 }}>
          <HourglassBottomRounded color="#fff" />
        </div>
      ) : ( */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* {prefixIcon && (
            <IconButton style={{ marginRight: 8 }}>
              <Icon
                style={{ color: textColor || '#fff', fontSize: sufixSize }}
              >
                {prefixIcon}
              </Icon>
            </IconButton>
          )} */}
          <span
            style={{
              color: textColor || '#fff',
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            {textButton || ''}
          </span>
          {/* {suffixIcon && (
            <IconButton style={{ marginLeft: 8 }}>
              <Icon
                style={{ color: textColor || '#fff', fontSize: sufixSize }}
              >
                {suffixIcon}
              </Icon>
            </IconButton>
          )} */}
        </div>
      {/* )} */}
    </StyledButton>
  );
};

export default ButtonCom;
