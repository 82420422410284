"use strict";
//@ts-check
import React, { useEffect, useState } from "react";
import { INPUT_TYPE, SELECT_OPTION } from "../../../../../util/function";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../../../util/style";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
  InputLabel,
  Checkbox,
  FormHelperText,
  Avatar,
  Grid,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import {
  TextInput,
  CheckboxGroupInput,
  DatePickerInput,
  FilePicker,
  PasswordInputWithValidation,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  SelectOneAutocompleteSearch,
  SelectOneCountryAutocomplete,
  TextArea,
  TimePickerInput,
} from "../../../../../components/Form";
import { ANOTHER_INPUT_TYPE } from "../../../payment-request/parts/imutable_state";
import RemoveIcon from "../../../../../assets/minus.svg";
import {
  CheckBox,
  DisabledByDefaultRounded,
  Padding,
} from "@mui/icons-material";
import { field_drawer } from "../../parts/imutable_state";

function ComponentRender({
  parentField,
  field,
  options,
  errors,
  formValues,
  handleChange,
  handleBlur,
  disabled,
  paymentReceive,
  canConvert,
}) {
  // if (typeof formValues === "object") {
  //   const aa = field;
  //   const val = formValues;
  //   debugger;
  // }

  if (field.name === "criteria_id" && formValues[field.name] === null)
    return null;

  let fieldOptions = [],
    optionsData = [];
  if (
    field.hidden === true ||
    // * hidden when currency not selected
    (field.name === "payment_received" && disabled) ||
    field.name === "payment_received_convert"
  ) {
    return null;
  } else if (
    field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE &&
    field.label == "Currency"
  ) {
    fieldOptions = field.options;
  } else if (Array.isArray(options[field.name])) {
    let isRequired = null;
    if (field.required && field.type === INPUT_TYPE.SELECT_ONE) {
      isRequired = {
        id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
        name: "Choose Option",
      };
    }
    if (isRequired) {
      optionsData = [...options[field.name], isRequired];
    } else {
      optionsData = [...options[field.name]];
    }

    fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];
  }

  const handleInputBlur = (e) => {
    handleBlur(e, parentField);
  };

  const handleInputChange = (e) => {
    handleChange(e, parentField);
  };

  const RenderInputTextDisabled = ({ field }) => {
    let valueRender = formValues[field.name];
    if (field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
      const fieldValue = options[field.name]?.find(
        (opt) => opt.id === valueRender
      );
      valueRender = fieldValue?.name;
    }

    let labelToRender = field.label;

    if (field.name === "subtotal" || field.name === "discount") {
      labelToRender = null;
    }
    return (
      <div style={{ padding: 0, margin: 0, paddingRight: ".55rem" }}>
        <p
          style={{ paddingBlock: ".4rem", marginBlock: "0", fontWeight: "500" }}
        >
          {labelToRender}
        </p>
        {field.name === "description" ? (
          <textarea
            readOnly
            disabled
            style={{ width: "100%", paddingBlock: ".6rem" }}
            value={valueRender}
          ></textarea>
        ) : (
          <input
            type="text"
            placeholder={field.placeholder}
            disabled
            style={{
              width: "100%",
              paddingBlock: ".6rem",
              paddingInline: ".5rem",
            }}
            value={valueRender}
          />
        )}
      </div>
    );
  };

  const RenderSelectOneAutocomplite = () => {
    let paymentReceiveField = null;
    if (field.name === "payment_currency") {
      paymentReceiveField = parentField?.field?.find(
        (localField) => localField.name === "payment_received"
      );
    }
    const paymentConvertField = field_drawer.payment.field.find(
      (field) => field.name === "payment_received_convert"
    );

    if (field.name === paymentConvertField.name) {
      const concated = "Rp. ".concat(formValues[paymentConvertField.name]);
      formValues[paymentConvertField.name] = concated;
    }

    return field.name === "payment_currency" ? (
      <Grid container>
        <Grid item xs={2.5}>
          <SelectOneAutocomplete
            errors={errors}
            field={field}
            fieldOptions={fieldOptions}
            formValues={formValues}
            handleInputBlur={handleInputBlur}
            handleInputChange={handleInputChange}
            tip={field.tip}
          />
        </Grid>
        <Grid item xs={9.5} paddingLeft={".6rem"}>
          {!paymentReceive ? null : (
            <TextInput
              errors={errors}
              field={paymentReceiveField}
              formValues={formValues}
              handleInputBlur={handleInputBlur}
              handleInputChange={handleInputChange}
              key={paymentReceiveField.name}
            />
          )}
        </Grid>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ paddingBlock: ".9rem" }}>
            {!paymentReceive ? null : (
              <>
                <input
                  style={{ width: "1.6rem", paddingInline: ".5rem" }}
                  type="checkbox"
                  id="convert"
                  name="convert_payment"
                  checked={canConvert}
                  onChange={handleChange}
                  disabled={formValues["payment_currency"] === "Rp"}
                  // disabled={disabledConvert}
                />
                <label htmlFor="convert">convert it</label>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} paddingLeft={".6rem"}>
          {canConvert ? (
            <TextInput
              errors={errors}
              field={paymentConvertField}
              formValues={formValues}
              handleInputBlur={handleInputBlur}
              handleInputChange={handleInputChange}
              key={paymentConvertField.name}
            />
          ) : null}
        </Grid>
      </Grid>
    ) : (
      <>
        <SelectOneAutocomplete
          errors={errors}
          field={field}
          fieldOptions={fieldOptions}
          formValues={formValues}
          handleInputBlur={handleInputBlur}
          handleInputChange={handleInputChange}
          tip={field.tip}
        />
      </>
    );
  };

  /**@type {React.JSX.Element|null} */
  let FieldComponent;
  switch (field.type) {
    case INPUT_TYPE.TEXT:
    case INPUT_TYPE.NUMBER:
    case INPUT_TYPE.PHONE_NUMBER:
    case INPUT_TYPE.POINT:
    case INPUT_TYPE.DISCOUNT:
      if (field.name === "subtotal" || field.name === "discount") {
        field.additionalProps["showLable"] = false;
        FieldComponent = (
          <Grid container>
            <Grid items xs={field.name === "discount" ? 9 : 6}>
              <Typography paddingY={".7rem"}>{field.label}</Typography>
            </Grid>
            <Grid items xs={field.name === "discount" ? 3 : 6}>
              <Box flex justifyContent="end">
                <TextInput
                  errors={errors}
                  field={field}
                  formValues={formValues}
                  handleInputBlur={handleInputBlur}
                  handleInputChange={handleInputChange}
                  tip={field?.tip}
                />
              </Box>
            </Grid>
          </Grid>
        );
      } else {
        FieldComponent = (
          <TextInput
            key={field.name}
            field={field}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            tip={field?.tip}
          />
        );
      }
      break;
    case INPUT_TYPE.CURRENCY:
      FieldComponent = (
        <TextInput
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.TEXTAREA:
      FieldComponent = (
        <TextArea
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          tip={field.tip}
        />
      );
      break;
    case ANOTHER_INPUT_TYPE.FILE:
      //TODO: Improve
      FieldComponent = disabled ? (
        <RenderInputTextDisabled field={field} />
      ) : (
        <>File</>

        //   <Grid item xs={12} key={field.title}>
        //     {field.isDisplayTitle !== false && (
        //       <Box sx={{ marginBottom: 2 }}>
        //         {/* Title */}
        //         <Typography variant="h6" sx={{ fontWeight: 800 }}>
        //           {field.title}
        //         </Typography>
        //         {field.subtitle && (
        //           <Typography variant="subtitle2">{field.subtitle}</Typography>
        //         )}
        //       </Box>
        //     )}
        //     {formValues[childName].map((_, index) => (
        //       <div key={index}>
        //         <Grid container spacing={0.3}>
        //           <Grid item xs={11.3}>
        //             <Grid container>
        //               <Grid container>
        //                 <Grid item xs={11.3}>
        //                   <FilePicker
        //                     indexFile={index}
        //                     field={field.children[0]}
        //                     formValues={formValues[childName][index]}
        //                     errors={errors}
        //                     onFileSelect={(file) =>
        //                       handleFileSelect(file, childName, index)
        //                     }
        //                     handleInputChange={(e) =>
        //                       handleInputChange(e, field, index, childName)
        //                     }
        //                     tip={field?.tip}
        //                   />
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //           </Grid>
        //           <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
        //             <Button
        //               variant="contained"
        //               disableElevation
        //               sx={{
        //                 backgroundColor: (theme) => theme.palette.primary.main,
        //                 ...removeButton,
        //               }}
        //               onClick={() => handleRemoveFile(childName, index)}
        //               disabled={
        //                 (actionType === ACTION_TYPE.ADD &&
        //                   formValues[childName].length === 1) ||
        //                 actionType === ACTION_TYPE.EDITED
        //               }
        //             >
        //               <img src={RemoveIcon} alt={`Remove ${childName}`} />
        //             </Button>
        //           </Grid>
        //         </Grid>
        //       </div>
        //     ))}
        //     {actionType !== ACTION_TYPE.EDITED && (
        //       <Box sx={{ ...justifyContentEnd }}>
        //         <Button
        //           onClick={() => handleAddFile(childName)}
        //           disabled={actionType === ACTION_TYPE.EDITED}
        //         >
        //           <span
        //             style={{
        //               fontSize: "1.5rem",
        //               marginRight: ".6rem",
        //               display: "inline-block",
        //             }}
        //           >
        //             +
        //           </span>
        //           Add More
        //         </Button>
        //       </Box>
        //     )}
        //   </Grid>
      );
      break;
    case INPUT_TYPE.SELECT_ONE:
      FieldComponent = (
        <SelectOne
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={fieldOptions}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
      //TODO: Need customize
      // FieldComponent = (
      //   <SelectOneAutocomplete
      //     errors={errors}
      //     field={field}
      //     fieldOptions={fieldOptions}
      //     formValues={formValues}
      //     handleInputBlur={handleBlur}
      //     handleInputChange={handleInputChange}
      //     tip={field.tip}
      //   />
      // );
      FieldComponent = RenderSelectOneAutocomplite();
      break;
    // FieldComponent = RenderSelectOneAutocomplite(field, fieldOptions);
    case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
      FieldComponent = (
        <SelectOneCountryAutocomplete
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={fieldOptions}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.SELECT_MULTIPLE:
      FieldComponent = (
        <SelectMultiple
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={fieldOptions}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
      FieldComponent = (
        <SelectMultipleAutocomplete
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={fieldOptions}
          tip={field?.tip}
        />
      );
      break;
    case INPUT_TYPE.DATE:
      FieldComponent = (
        <DatePickerInput
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
        />
      );
      break;
    case INPUT_TYPE.TIME:
      FieldComponent = (
        <TimePickerInput
          key={field.name}
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
        />
      );
      break;
    case INPUT_TYPE.CHECKBOX:
      //* Sub service section
      FieldComponent = (
        <Grid container>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <div className="checkbox-container">
              <FormControlLabel
                key={field.name}
                control={
                  <Checkbox
                    name={field.name}
                    // checked={formValues[field.name].includes(option.id)}
                    checked={false}
                    // onChange={() => handleChange(option.id)}
                    // onBlur={handleInputBlur}
                    id={field.name}
                    disabled={true}
                  />
                }
                // label={}
              />
            </div>
            <span>VAT {formValues["vat_percent"]}%</span>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Typography variant="body1" fontWeight={700}>
                {formValues["vat_to_rp"]}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography>Total</Typography>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Typography variant="body1" fontWeight={700}>
                {formValues["total_to_rp"]}
              </Typography>
            </div>
          </Grid>
        </Grid>
      );
      break;
    case INPUT_TYPE.DIVIDER:
      FieldComponent = <Divider />;
    default:
      FieldComponent = null;
  }
  return FieldComponent;
}

export default ComponentRender;
