/* -------------------------------------------------------------------------- */
/*                             BISA - VERSI BIASA                             */
/* -------------------------------------------------------------------------- */

import logo from "./../logo.png";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { mainColor, drawerWidth, FacebookCircularProgress, } from "../util/style";
import Navbar from "./Navbar";
import axios from "../core/axios_config";
import { useSelector } from "react-redux";
import { RampRight } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      boxShadow: "0px 2px 20px -8px rgb(0 0 0 / 17%)",
    },
  },
  drawerPaper: {
    width: "18.5%",
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  nested: {
    // paddingLeft: theme.spacing(6),
  },
  nestedList: {
    // padding: theme.spacing(8),
  },
  logo: {
    padding: theme.spacing(3),
  },
  list: {
    fontSize: "16px",
    fontWeight: "700 !important",
  },
  listItem: {
    fontSize: "16px",
    fontWeight: "700 !important",
    color: mainColor, // Set the default text color
  },
  activeLink: {
    backgroundColor: `${mainColor} !important`,
    "& .MuiTypography-root": {
      color: "white",
    },
  },
}));

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const classes = useStyles();
  const navigate = useNavigate();
  const [openRoutes, setOpenRoutes] = useState([]);

  // NEW
  const { menus, isLoading } = useSelector((state) => state.auth);

  // OLD
  /*const [menus, setMenus] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const token = localStorage.getItem("t");
    const response = await axios.get("/master/menus", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        with_children: true,
      },
    });
    setMenus(response.data.data);
    console.log(menus);
  };
  */

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleToggle = (path) => {
    if (openRoutes.includes(path)) {
      setOpenRoutes(openRoutes.filter((route) => route !== path));
    } else {
      setOpenRoutes([...openRoutes, path]);
    }
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  const renderMenuItems = (menuItems, depth = 0, isSubMenu = false) => {
    if (!menuItems) {
      return;
    }
    const paddingLeft = depth * 10;
    return menuItems.map((menuItem) => {
      const { id, name, link, children, ...others } = menuItem;
      const cleanedLink = currentPath.replace(
        // OLD : /(.*?)(\/(add|edit\/\d+|detail\/\d+)).*$/,
        /(.*?)(\/(add|edit\/\d+|detail\/\d+|resubmit\/\d+|preview-editing\/\d+)).*$/,
        "$1"
      );
      const isActive = cleanedLink === link;
      const hasChildren =
        children && Array.isArray(children) && children.length > 0;
      const isOpen = openRoutes.includes(link);

      const handleClick = () => {
        if (hasChildren) {
          handleToggle(link);
        } else {
          handleMenuItemClick(link);
        }
      };

      const filteredChildren = (children || []).filter(
        (child) => child.status === "active" && child.has_access
      );

      return (
        <React.Fragment key={id}>
          {menuItem.status === "active" && menuItem.has_access && (
            <ListItem
              button
              className={`${classes.nestedList} ${classes.listItem} ${
                isActive ? classes.activeLink : ""
              }`}
              style={{ paddingLeft }}
              onClick={handleClick}
            >
              <ListItemText primary={name} sx={{ color: mainColor }} />
              {/* {link}{" "}
              <span title={JSON.stringify(others)}>O</span> */}
              {hasChildren && (isOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
          )}
          {filteredChildren &&
          Array.isArray(filteredChildren) &&
          filteredChildren.length > 0 ? (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.nested}>
                {renderMenuItems(filteredChildren, depth + 1, true)}
              </List>
            </Collapse>
          ) : null}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={`${classes.root} Sidebar-erp`}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </div>
        <List className={classes.list} sx={{ maxHeight: "80vh" }}>
          {isLoading && (
            <div
              style={{
                minHeight: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          )}
          {renderMenuItems(menus, 2, false)}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;

/* -------------------------------------------------------------------------- */
/*                             BISA - VERSI RAPIH                             */
/* -------------------------------------------------------------------------- */

/*
import logo from "./../logo.png";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { mainColor, drawerWidth } from "../util/style";
import Navbar from "./Navbar";
import axios from "../core/axios_config";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      boxShadow: "0px 2px 20px -8px rgb(0 0 0 / 17%)",
    },
  },
  drawerPaper: {
    width: "18.5%",
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  nested: {
    // paddingLeft: theme.spacing(6),
  },
  nestedList: {
    // padding: theme.spacing(8),
  },
  logo: {
    padding: theme.spacing(3),
  },
  list: {
    fontSize: "16px",
    fontWeight: "700 !important",
  },
  listItem: {
    fontSize: "16px",
    fontWeight: "700 !important",
    color: mainColor, // Set the default text color
  },
  activeLink: {
    backgroundColor: `${mainColor} !important`,
    "& .MuiTypography-root": {
      color: "white",
    },
  },
}));

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const classes = useStyles();
  const navigate = useNavigate();
  const [openRoutes, setOpenRoutes] = useState([]);

  // NEW
  const { menus } = useSelector((state) => state.auth);


  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleToggle = (path) => {
    if (openRoutes.includes(path)) {
      setOpenRoutes(openRoutes.filter((route) => route !== path));
    } else {
      setOpenRoutes([...openRoutes, path]);
    }
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  const hasSubMenuChildren = (menuItem) => {
    if (!menuItem.children || menuItem.children.length === 0) {
      return false;
    }

    for (const child of menuItem.children) {
      if (child.status === "active" && child.has_access) {
        return true;
      }
    }

    return false;
  };

  const renderSubMenu = (menuItem, depth) => {
    const { children, link } = menuItem;

    if (!children || children.length === 0) {
      return null;
    }

    const isOpen = openRoutes.includes(link);
    const paddingLeft = depth * 10;

    return (
      <Collapse in={isOpen} timeout="auto" unmountOnExit key={menuItem.id}>
        <List component="div" disablePadding className={classes.nested}>
          {renderMenuItems(children, depth + 1, true)}
        </List>
      </Collapse>
    );
  };

  const renderMenuItems = (menuItems, depth = 0, isSubMenu = false) => {
    if (!menuItems) {
      return;
    }

    return menuItems.map((menuItem) => {
      const { id, name, link, ...others } = menuItem;
      const cleanedLink = currentPath.replace(/\/(add|edit\/\d+)/, "");
      const isActive = cleanedLink === link;
      const hasChildren = hasSubMenuChildren(menuItem);
      const isOpen = openRoutes.includes(link);

      const handleClick = () => {
        if (hasChildren) {
          handleToggle(link);
        } else {
          handleMenuItemClick(link);
        }
      };

      return (
        <React.Fragment key={id}>
          {menuItem.status === "active" && menuItem.has_access && (
            <ListItem
              button
              className={`${classes.nestedList} ${classes.listItem} ${
                isActive ? classes.activeLink : ""
              }`}
              style={{ paddingLeft: depth * 10 }}
              onClick={handleClick}
            >
              <ListItemText primary={name} sx={{ color: mainColor }} />
              {hasChildren && (isOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
          )}
          {renderSubMenu(menuItem, depth)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={`${classes.root} Sidebar-erp`}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </div>
        <List className={classes.list} sx={{ maxHeight: "80vh" }}>
          {renderMenuItems(menus, 2, false)}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
*/
