import { Box, Button, Card, CardContent, Grid, MenuItem, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ACTION_TYPE, MODE_CRUD_WITH, formatDate } from "../../../util/function";
import { getRequest } from "./clients/actions";
import {
    borderRadius,
    StatusBadge,
    warningMainColor,
    successMainColor,
    dangerMainColor,
    warningSurfaceColor,
    successSurfaceColor,
    dangerSurfaceColor,
    defaultStylePage,
    btnWhite,
    mainColor,
    neutral100,
    surfaceColor,
    neutral10,
    neutral40,
} from "../../../util/style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../components/Table";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import axios from "../../../core/axios_config";

const columns = [
    {
        field: "request_code",
        headerName: "Request Number",
        width: 135,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div><span>{params.value.replace("-", "")}</span></div>
            );
        }
    },
    {
        field: "request_date",
        headerName: "Request Date",
        width: 201,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div><span>{formatDate(params.value)}</span></div>
            );
        }
    },
    {
        field: "return_date",
        headerName: "Return Date",
        width: 201,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div><span>{formatDate(params.value)}</span></div>
            );
        }
    },
    {
        field: "customer_name",
        headerName: "Customer Name",
        width: 291,
        disableColumnMenu: true,
    },
    {
        field: "service_category",
        headerName: "Service Category",
        width: 161,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {params.value.map((v, key) => (
                        <StatusBadge
                            key={key}
                            status={v.get_service_category ? v.get_service_category.name : ""}
                            isinfo={true}
                            style={{ margin: ".1rem .25rem" }}
                        >
                            {v.get_service_category ? v.get_service_category.name : ""}
                        </StatusBadge>
                    ))}
                </Box>
            );
        }
    },
    {
        field: "sub_service_category",
        headerName: "Sub Service Category",
        width: 301,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            console.log("params===>", params.row);
            let ssc = sc.flatMap((v) => v.get_sub_service_category);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v ? v.name : ""}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v ? v.name : ""}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "registration_number",
        headerName: "Registration Number",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {params.value.map((v, key) => (
                        // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
                        <StatusBadge
                            key={key}
                            status={v.registration_number}
                            isinfo={true}
                            style={{ margin: ".1rem .25rem" }}
                        >
                            {v.registration_number}
                        </StatusBadge>
                    ))}
                </Box>
            );
        }
    },
    {
        field: "reference_number",
        headerName: "Reference Number",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {params.value.map((v, key) => (
                        // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
                        <StatusBadge
                            key={key}
                            status={v.reference_number}
                            isinfo={true}
                            style={{ margin: ".1rem .25rem" }}
                        >
                            {v.reference_number}
                        </StatusBadge>
                    ))}
                </Box>
            );
        }
    },
    {
        field: "brand",
        headerName: "Brand",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.brand);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v ? v.name : ""}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v ? v.name : ""}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "applicant",
        headerName: "Applicant",
        width: 291,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.applicant);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v ? v.name : ""}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v ? v.name : ""}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "cupboard",
        headerName: "Cupboard Name",
        width: 301,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.get_archive_detail.get_cupboard);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v.cupboard_name}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v.cupboard_name}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "rack_number",
        headerName: "Rack Number",
        width: 301,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_archive_service;
            let ssc = sc.flatMap((v) => v.get_archive_detail.get_rack);
            return ssc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    {
                        params.value.map((v, key) => (
                            <StatusBadge
                                key={key}
                                status={v.rack_number}
                                isinfo={true}
                                style={{ margin: ".1rem .25rem" }}
                            >
                                {v.rack_number}
                            </StatusBadge>
                        ))
                    }

                </Box>
            );
        }
    },
    {
        field: "note",
        headerName: "Note",
        width: 240,
        disableColumnMenu: true,
    },
    {
        field: "get_user",
        headerName: "Request By",
        width: 250,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.get_user.fullname;
            return sc;
        },
    },
    {
        field: "status",
        headerName: "Request Status",
        width: 240,
        disableColumnMenu: true,
        valueGetter: (params) => {
            let sc = params.row.status;
            return sc;
        },
        renderCell: (params) => {
            return (
                <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
                    <StatusBadge
                        colors={params.value.color_code}
                        bgColor={params.value.bg_color}
                        status={params.value.name}
                        isinfo={false}
                        style={{ margin: ".1rem .25rem" }}
                    >
                        {params.value.name}
                    </StatusBadge>
                </Box>
            );
        }
    },

];

const RequestRecipient = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const useStyles = defaultStylePage;
    const { token } = useSelector(state => state.auth)
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [searchBy, setSearchBy] = useState("customer_name");
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
    const [dateFilter, setDateFilter] = useState(ACTION_TYPE.DEFAULT_DATE);
    const [categoryFilter, setCategoryFilter] = useState(ACTION_TYPE.ALL_CATEGORY);
    const [listCategory, setlistCategory] = useState([]);
    const [statusFilter, setStatusFilter] = useState(ACTION_TYPE.ALL_STATUS);
    const [listStatus, setlistStatus] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [typeData, setTypeData] = useState("Hardcopy");
    const placeholder = {
        "customer_name": "Customer Name",
        "Brand": "Brand",
        "Applicant": "Applicant",
        "reference_number": "No. Reference",
        "registration_number": "No. Registration",
    }
    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.request);

    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    useEffect(() => {
        if (isActive === true) {
            dispatch(
                getRequest({
                    statusFilter,
                    categoryFilter,
                    dateFilter,
                    token,
                    page,
                    limit,
                    searchValue,
                    searchBy,
                    sortDirection,
                    sortBy,
                })
            );
        }
    }, [isActive]);

    const [formValues, setFormValues] = useState(Object.assign({}));

    // useEffect
    useEffect(() => {
        axios
            .get("/master/services", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setlistCategory(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching service categories:", error);
            });
    }, [])
    useEffect(() => {
        axios
            .get("/master/statuses", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setlistStatus(response.data.data);
            })
            .catch((error) => {
                console.error("Error fetching service categories:", error);
            });
    }, [])
    useEffect(() => {
        if (!searchValue) { _search() }
    }, [searchValue])

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && searchValue) {
            _search();
        }
    };


    // handle
    const handlePageChange = (newPage) => {
        setPage(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        // console.log(newLimit);
        setPage(0);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handleRowDoubleClick = (selection) => {
        navigate(`detail/${selection.id}`, { state: { type: typeData } });
    }

    const handleChangeType = (v) => {
        setTypeData(v);
    }

    const handleToolbarLeft = () => {
        return (
            <Box>
                <StatusBadge
                    key={"Hardcopy"}
                    status={"Hardcopy"}
                    isinfo={false}
                    colors={typeData === "Hardcopy" ? mainColor : neutral100}
                    bgColor={typeData === "Hardcopy" ? surfaceColor : neutral10}
                    onClick={() => handleChangeType("Hardcopy")}
                    style={{
                        marginRight: "16px",
                        borderRadius: "48px",
                        padding: "6px 16px 6px 16px",
                        border: typeData !== "Hardcopy" ? `1px solid ${neutral40}` : 'none'
                    }} >
                    Hardcopy
                </StatusBadge>
                <StatusBadge
                    key={"Softcopy"}
                    status={"Softcopy"}
                    isinfo={false}
                    colors={typeData === "Softcopy" ? mainColor : neutral100}
                    bgColor={typeData === "Softcopy" ? surfaceColor : neutral10}
                    onClick={() => handleChangeType("Softcopy")}
                    style={{
                        marginRight: "16px",
                        borderRadius: "48px",
                        padding: "6px 16px 6px 16px",
                        border: typeData !== "Softcopy" ? `1px solid ${neutral40}` : 'none'
                    }} >
                    Softcopy
                </StatusBadge >
            </Box >
        )
    }

    // function
    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    function _search() {
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _categoryFilter(category) {
        setCategoryFilter(category);
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter: category,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _statusFilter(status) {
        setStatusFilter(status);
        dispatch(
            getRequest({
                statusFilter: status,
                categoryFilter,
                dateFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _dateFilter(date) {
        setDateFilter(date);
        dispatch(
            getRequest({
                statusFilter,
                categoryFilter,
                dateFilter: date,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,

            })
        )
    }

    return (
        <>
            <Card style={{ boxShadow: "unset" }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                placeholder={`Search ${placeholder[searchBy]}`}
                                size="small" // Set the size to "small"
                                InputProps={{
                                    startAdornment: <SearchIcon />,
                                    onKeyDown: handleKeyPress,
                                    endAdornment: (
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                                IconComponent: ExpandMoreIcon,
                                            }}
                                            value={searchBy}
                                            sx={{
                                                width: 250,
                                                '& fieldset': {
                                                    border: 'unset',
                                                }, borderLeft: `2px solid #ddd`
                                            }}
                                            onChange={(e) => setSearchBy(e.target.value)}
                                        >
                                            <MenuItem value="customer_name">Customer Name</MenuItem>
                                            <MenuItem value="Brand">Brand</MenuItem>
                                            <MenuItem value="Applicant">Applicant</MenuItem>
                                            <MenuItem value="reference_number">No. Reference</MenuItem>
                                            <MenuItem value="registration_number">No. Registration</MenuItem>
                                        </TextField>
                                    )
                                }}
                                sx={{
                                    borderRadius // Apply the desired border radius value
                                }}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ height: "100%" }}
                                onClick={_search}>
                                <SearchIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                value={categoryFilter}
                                size="small"
                                disabled={dateFilter === ACTION_TYPE.DEFAULT_DATE}
                                SelectProps={{
                                    IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                                    // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                                    // IconComponent: ExpandLessIcon,
                                }}
                                onChange={(e) => _categoryFilter(e.target.value)}
                                sx={{
                                    borderRadius, // Apply the desired border radius value
                                }} >
                                <MenuItem value={ACTION_TYPE.ALL_CATEGORY}>All service category</MenuItem>
                                {listCategory.map((category) => (
                                    <MenuItem value={category.id}>{category.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                value={statusFilter}
                                size="small"
                                disabled={dateFilter === ACTION_TYPE.DEFAULT_DATE}
                                SelectProps={{
                                    IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                                    // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                                    // IconComponent: ExpandLessIcon,
                                }}
                                onChange={(e) => _statusFilter(e.target.value)}
                                sx={{
                                    borderRadius, // Apply the desired border radius value
                                }} >
                                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All status</MenuItem>
                                {listStatus.map((status) => (
                                    <MenuItem value={status.id}>{status.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                value={dateFilter}
                                size="small"
                                SelectProps={{
                                    IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                                    // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                                    // IconComponent: ExpandLessIcon,
                                }}
                                onChange={(e) => _dateFilter(e.target.value)}
                                style={{
                                    borderRadius, // Apply the desired border radius value
                                }}  >
                                <MenuItem value={ACTION_TYPE.DEFAULT_DATE}>Select Date</MenuItem>
                                <MenuItem value="all_date">All date</MenuItem>
                                <MenuItem value="this_week">This week</MenuItem>
                                <MenuItem value="this_month">This month</MenuItem>
                                <MenuItem value="last_6_months">Last 6 months</MenuItem>
                                <MenuItem value="this_year">This year</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Request"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        handleFormModal={() => { }}
                        selectedData={selectedData}
                        isOnSelection={false}
                        handleSelectionChange={handleSelectionChange}
                        handleRowDoubleClick={handleRowDoubleClick}
                        theme={theme}
                        sortModel={sortModel}
                        search={searchValue}
                        showAddToolbar={false}
                        showEditToolbar={false}
                        showDeleteToolbar={false}
                        dateChoosed={dateFilter}
                        toolBarLeft={handleToolbarLeft}
                        onSortModelChange={handleSortModelChange}
                        modeCRUD={MODE_CRUD_WITH.PAGING}
                        stateUpdate={{ customer: formValues, type: typeData }}
                        linkCRUD={{ create: "add", update: `edit/${selectedData}`, createOption: "hybrid" }}
                    />
                </CardContent>
            </Card>
        </>
    )
}
export default RequestRecipient;