import confirmLogoutIMG from "./../assets/confirm-delete.svg";
import { confirmDelete, secondColor } from "../util/style";
import { Typography, Box } from "@mui/material";

const ConfirmDelete = ({ userDeleted }) => {
  return (
    <Box style={{ ...confirmDelete }}>
      <img src={confirmLogoutIMG} alt="Confirmation Delete" />
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "700" }}>
        Are you sure ?
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: secondColor, fontStyle: "italic", fontWeight: "400" }}
      >
        You wont to delete {userDeleted} ?
      </Typography>
    </Box>
  );
};

export default ConfirmDelete;
