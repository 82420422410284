import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  GET_DETAIL_PERFORMA_INVOICE_FAIL,
  GET_DETAIL_PERFORMA_INVOICE_SUCCESS,
} from "./state";

const initialState = {
  isLoading: false,
  message: null,
  data: null,
  for_edit: false,
};

const detailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DETAIL_PERFORMA_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        for_edit: false,
      };
    case GET_DETAIL_PERFORMA_INVOICE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};
export default detailReducer;
