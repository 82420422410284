import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  formHelperTextClasses,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@mui/material";
import CalendarIcon from "./../../../../assets/calendar.png";
import RemoveIcon from "./../../../../assets/minus.svg";

import { removeButton, justifyContentEnd } from "../../../../util/style";
import axios, { headersAPIToken } from "../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  SelectOneAutocompleteSearch,
  SelectOneCountryAutocomplete,
} from "../../../../components/Form";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const StepOne = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
  setIsFormValid,
}) => {
  const ANOTHER_INPUT_TYPE = Object.freeze({
    SELECT_ONE_AUTOCOMPLETE_SEARCH: "SELECT_ONE_AUTOCOMPLETE_SEARCH",
  });

  const { id } = useParams();

  //* Local Storage Hook
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    "trademark_application_options_stepOne",
    {}
  );

  // const [isFormValid, setIsFormValid] = useState(false);

  const fields = [
    {
      title: "Applicant Information",
      children: [
        {
          name: "client_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Client Name",
          placeholder: "Select client name",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/clients",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "applicant_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Applicant",
          placeholder: "Select applicant",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          //           apiEndpoint: "/master/applicants",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "category_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Category",
          placeholder: "Select category",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/services", // get all service
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "type_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Type",
          placeholder: "Select type",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          // apiEndpoint: "/master/criterias",
          //           apiEndpoint: `/master/services?main_service_id=${id}`,
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "email",
          type: INPUT_TYPE.TEXT,
          label: "Email",
          defaultValue: "",
          required: true,
          validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          errorMessage: "Invalid email address",
          gridWidth: 6, // Full width
        },
        {
          name: "mobile_phone",
          type: INPUT_TYPE.PHONE_NUMBER,
          label: "Phone",
          placeholder: "Input mobile phone",
          defaultValue: "",
          validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
          errorMessage:
            "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "business_field_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Business Field",
          placeholder: "Select business field",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/business-fields",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "company_name",
          type: INPUT_TYPE.TEXT,
          label: "Company",
          placeholder: "Enter company name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Full width
        },
        {
          name: "submission_on_behalf_of",
          type: INPUT_TYPE.RADIO,
          label: "Owner Type",
          defaultValue: [], // Array for selected format values
          options: [
            { id: "Personal", name: "Personal" },
            { id: "Company", name: "Company" },
          ],
          required: true,
          gridWidth: 6, // Half width
        },
      ],
    },
  ];

  const fields2 = [
    {
      title: "Applicant Information",
      deepth: 1,
      children: [
        {
          name: "group",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "Group",
          placeholder: "Input Group",
          gridWidth: 6,
          options: [],
          apiEndpoint:"/master/clients"
        },
        {
          name: "client_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          multiple: false,
          label: "Client",
          placeholder: "Select Client",
          gridWidth: 6,
          // apiEndpoint: "",
          apiEndpoint: "/master/clients",
        },
        {
          name: "applicant_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          multiple: true,
          label: "Applicant",
          placeholder: "Select Applicant",
          gridWidth: 6,
          // apiEndpoint: "",
        },
        {
          name: "pic_name",
          required: true,
          type: INPUT_TYPE.TEXT,
          defaultValue: "",
          multiple: true,
          label: "PIC Name",
          placeholder: "Input PIC Name",
          gridWidth: 6,
          // apiEndpoint:""
        },
        {
          name: "category_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          multiple: false,
          label: "Category",
          placeholder: "Select Category",
          gridWidth: 6,
          apiEndpoint: "/master/services", // get all service
        },
        {
          name: "type",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          multiple: false,
          label: "Type",
          placeholder: "Select Type",
          gridWidth: 6,
          // apiEndpoint:""
          // apiEndpoint: "/master/clients", // !Just for testing
          options: [
            {
              id: "UMK, Lembaga Pendidikan, Lembaga Litbang Pemerintah",
              name: "UMK, Lembaga Pendidikan, Lembaga Litbang Pemerintah",
            },
            { id: "Umum", name: "Umum" },
          ],
        },
        {
          name: "email_phone",
          title: "email phone",
          showTitle: false,
          gridWidth: 12,
          multiple: true,
          border: true,
          deepth: 2,
          children: [
            {
              name: "email",
              required: true,
              type: INPUT_TYPE.TEXT,
              defaultValue: "",
              label: "Email",
              placeholder: "Input email",
              validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
              gridWidth: 6,
            },
            {
              name: "phone",
              required: true,
              type: INPUT_TYPE.TEXT,
              defaultValue: "",
              label: "Phone",
              validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
              errorMessage:
                "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
              placeholder: "E.g: 08123456789",
              gridWidth: 6,
            },
          ],
        },
        {
          name: "business_field_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "Bussines Field",
          placeholder: "Select Bussines field",
          apiEndpoint: "/master/business-fields",
          gridWidth: 6,
        },
        // {
        //   name: "company",
        //   required: true,
        //   type: INPUT_TYPE.TEXT,
        //   defaultValue: "",
        //   label: "Company",
        //   placeholder: "Input company",
        //   gridWidth: 6,
        // },
        {
          name: "owner_type",
          required: true,
          type: INPUT_TYPE.RADIO,
          defaultValue: [], // for defaul value
          label: "Owner Type",
          placeholder: "",
          gridWidth: 6,
          options: [
            { id: "personal", name: "Personal" },
            { id: "company", name: "Company" },
          ],
        },
        {
          name: "notes",
          required: true,
          type: INPUT_TYPE.TEXTAREA,
          defaultValue: "",
          label: "Notes",
          placeholder: "Input Notes",
          gridWidth: 6,
        },
      ],
    },
    {
      title: "Address",
      deepth: 1,
      children: [
        {
          name: "address",
          required: true,
          type: INPUT_TYPE.TEXTAREA,
          defaultValue: "",
          label: "Address",
          placeholder:
            "E.g.: Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
          gridWidth: 6,
        },
        {
          name: "rigency_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "City",
          placeholder: "Select City",
          gridWidth: 6,
        },
        {
          name: "province_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "Province",
          placeholder: "Select province",
          apiEndpoint: "/master/provinces", // get all service
          gridWidth: 6,
        },
        {
          name: "zip_code",
          required: false,
          type: INPUT_TYPE.TEXT,
          defaultValue: "",
          label: "Zip Code (Optional)",
          placeholder: "Input zip code",
          gridWidth: 6,
          validate: (value) => {
            if (value !== "") {
              console.log(String(value).length <= 5);
              return String(value).length <= 5;
            }
            return true;
          },
        },
        {
          name: "country_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "Country",
          placeholder: "Select Country",
          gridWidth: 6,
          apiEndpoint: "/master/countries", // get all service
        },
      ],
    },
    {
      title: "Delivery Address",
      deepth: 1,
      children: [
        {
          name: "address",
          required: true,
          type: INPUT_TYPE.TEXTAREA,
          defaultValue: "",
          label: "Address",
          placeholder:
            "E.g.: Jl. Jenderal Gatot Subroto Kav. 51-53 Jakarta Pusat 10260, RW.7, Petamburan",
          gridWidth: 6,
        },
        {
          name: "rigency_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "City",
          placeholder: "Select City",
          gridWidth: 6,
        },
        {
          name: "province_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "Province",
          placeholder: "Select province",
          apiEndpoint: "/master/provinces", // get all service
          gridWidth: 6,
        },
        {
          name: "zip_code",
          required: false,
          type: INPUT_TYPE.TEXT,
          defaultValue: "",
          label: "Zip Code (Optional)",
          placeholder: "Input zip code",
          gridWidth: 6,
          validate: (value) => {
            if (value !== "") {
              console.log(String(value).length <= 5);
              return String(value).length <= 5;
            }
            return true;
          },
        },
        {
          name: "country_id",
          required: true,
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY,
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          label: "Country",
          placeholder: "Select Country",
          gridWidth: 6,
          apiEndpoint: "/master/countries", // get all service
        },
      ],
    },
  ];

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered]);

  const { token } = useSelector((state) => state.auth);

  //* ===================================================================  */
  //*                              INITIAL FORM                            */
  //* ===================================================================  */

  const initialFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };

  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialErrorFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };
  const initialErrorEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialFormValues = () => {
    console.group("Initial form");
    const initialValues = {};
    const initialValues2 = {};
    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
          initialValues[fieldGroup.title] =
            fieldGroup.title === "Family" ? [initialFamily] : [initialEmergencyContact];
        } else {
          fieldGroup.children.forEach((field) => {
            if (!initialValues[fieldGroup.title]) {
              initialValues[fieldGroup.title] = {};
            }
            initialValues[fieldGroup.title][field.name] = field.defaultValue;
          });
        }
      }
    });

    fields2.forEach((section) => {
      if (section.children && Array.isArray(section.children)) {
        if (!initialValues2[section.title]) {
          initialValues2[section.title] = {};
        }
        section.children.forEach((field) => {
          // console.log(field);
          if (field.children && Array.isArray(field.children)) {
            let childVal = {};
            field.children.forEach((fiel) => {
              childVal[fiel.name] = fiel.defaultValue || "";
            });

            if (field.multiple) {
              initialValues2[section.title][field.name] = [childVal];
            } else {
              initialValues2[section.title][field.name] = childVal;
            }
          } else {
            initialValues2[section.title][field.name] = field.multiple
              ? [field.defaultValue]
              : field.defaultValue || "";
          }
        });
      }
    });

    console.log("Result init 2", initialValues2);
    console.groupEnd("");
    return {
      ...initialValues2,
    };
  };

  const initialErrors = () => {
    console.groupEnd("Initial Error");
    const initialErrorValues = {};
    const initialErrorValues2 = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
          initialErrorValues[fieldGroup.title] =
            fieldGroup.title === "Family"
              ? [initialErrorFamily]
              : [initialErrorEmergencyContact];
        } else {
          if (!initialErrorValues[fieldGroup.title]) {
            initialErrorValues[fieldGroup.title] = {};
          }
          fieldGroup.children.forEach((field) => {
            initialErrorValues[fieldGroup.title][field.name] = "";
          });
        }
      }
    });

    fields2.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (!initialErrorValues2[fieldGroup.title]) {
          initialErrorValues2[fieldGroup.title] = {};
        }
        fieldGroup.children.forEach((field) => {
          if (field.children && Array.isArray(field.children)) {
            let childVal = {};
            field.children.forEach((fiel) => {
              childVal[fiel.name] = "";
            });
            if (field.multiple) {
              initialErrorValues2[fieldGroup.title][field.name] = [childVal];
            } else {
              initialErrorValues2[fieldGroup.title][field.name] = childVal;
            }
          } else {
            initialErrorValues2[fieldGroup.title][field.name] = field.multiple
              ? [""]
              : "";
          }
        });
      }
    });

    console.log("Initial error 2", initialErrorValues2);
    console.groupEnd();

    return { ...initialErrorValues2 };
  };

  // ---------------------- [ STATE ] ---------------------------- */
  const [options, setOptions] = useState({});

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0 ? formData : initialFormValues()
  );

  const [errors, setErrors] = useState(
    formData && Object.values(formData)?.length > 0
      ? cloneObjectWithEmptyStrings(formData)
      : initialErrors()
  );

  const fetchDataType = async () => {
    try {
      const serviceId = formValues["Applicant Information"]["category_id"];

      // console.log(headersAPIToken(token));
      if (serviceId) {
        const response = await axios.get(
          `/master/criterias/${serviceId}`,
          headersAPIToken(token)
        );

        const responseData = response?.data?.data;

        setOptions((prevOptions) => ({
          ...prevOptions,
          ["type_id"]: responseData,
        }));

        if (localStorageValue) {
          const copyLocalStorage = { ...localStorageValue };
          copyLocalStorage["type_id"] = responseData;
          setLocalStorageValue(copyLocalStorage);
        } else {
          // localStorage.setItem("business_permit_options_step_one", JSON.stringify(options));
          setLocalStorageValue(options);
        }
      }
    } catch (error) {
      console.error("Failed get option, error: ", error);
    }
  };

  const fetchDataApplicantAndBrand = async () => {
    const client_id = formValues["Applicant Information"]["client_id"];

    if (client_id) {
      const aplicanEndpoint = `/master/applicants`;
      const brandEndpoint = `/master/brands`;
      const param = { params: client_id };

      fetchOption(aplicanEndpoint, "applicant_id", null, param);
      fetchOption(brandEndpoint, "brand_id", null, param);

      // const { data: dataApplicants } = await (
      //   await axios.get(`master/applicants`, {
      //     headers: { Authorization: `Bearer ${token}` },
      //     params: { client_id },
      //   })
      // )?.data;

      // const { data: dataBrands } = await (
      //   await axios.get(`/master/brands`, {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       params: { client_id },
      //     },
      //   })
      // )?.data;

      // setOptions((prevOptions) => ({
      //   ...prevOptions,
      //   ["applicant_id"]: dataApplicants,
      //   ["brand_id"]: dataBrands,
      // }));

      // const fetchedOptions = JSON.parse(
      //   localStorage.getItem("business_permit_options_step_one")
      // );
      // if (fetchedOptions) {
      //   fetchedOptions["applicant_id"] = dataApplicants;
      //   fetchedOptions["brand_id"] = dataBrands;
      //   localStorage.setItem(
      //     "business_permit_options_step_one",
      //     JSON.stringify(fetchedOptions)
      //   );
      // } else {
      //   localStorage.setItem("business_permit_options_step_one", JSON.stringify(options));
      //   setLocalStorageValue(options);
      // }
      // debugger;
    }
  };

  useEffect(() => {
    if (!localStorageValue || Object.keys(localStorageValue || {}).length === 0 || id) {
      console.log("Masuk fetch option");
      const fetchData = async () => {
        try {
          const fetchedOptions = {};

          for (const section of fields2) {
            if (section.children) {
              for (const field of section.children) {
                const { name, apiEndpoint } = field;
                // console.log({ name, apiEndpoint });
                if (apiEndpoint) {
                  const response = await axios.get(apiEndpoint, headersAPIToken(token));
                  fetchedOptions[name] = response.data.data;
                }
              }
            }
          }

          // Store fetched options in localStorage
          setLocalStorageValue(fetchedOptions);
          // localStorage.setItem(
          //   "business_permit_options_step_one",
          //   JSON.stringify(fetchedOptions)
          // );

          // Set options in state
          setOptions(fetchedOptions);
          fetchDataType();
          fetchDataApplicantAndBrand();
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      };

      fetchData();
    } else {
      console.log("Gk masuk fetcg option");
      setOptions(localStorageValue);
      fetchDataType();
    }
    console.log("Initial option");
  }, [token]);

  const fetchOption = async (
    endpoint,
    optionName,
    aliasName = "",
    customConfig = null
  ) => {
    try {
      let config = { ...headersAPIToken(token) };

      if (customConfig) {
        config = { ...config, ...customConfig };
      }
      console.log("This is fetch config", { config });

      const response = await axios.get(endpoint, config);
      let optionPayload = response?.data?.data;

      //* If option don't have key name, pass alias name for make alias;
      if (aliasName) {
        optionPayload = optionPayload.map((opt) => {
          opt["name"] = opt[aliasName];
          return opt;
        });
      }
      if (Array.isArray(optionPayload)) {
        //* Set option
        setOptions((prevValue) => ({ ...prevValue, [optionName]: optionPayload }));

        //* Set to localStorage
        if (localStorageValue) {
          const copyLocalStorage = { ...localStorageValue };
          copyLocalStorage[optionName] = optionPayload;
          setLocalStorageValue(copyLocalStorage);
        }
      } else {
        console.warn("Option payload not array");
        throw new Error("Failed get option for : ".concat(optionName));
      }
    } catch (error) {
      console.error(`failed get option for ${optionName} : ${error}`);
    }
  };

  const initialFormValuesBySection = (sectionTitle) => {
    const newFormValues = {};
    const sectionField = fields2.find((section) => section.title === sectionTitle);
    if (sectionField) {
      sectionField.children.forEach((field) => {
        newFormValues[field.name] = field.defaultValue || "";
      });
    }
    return newFormValues;
  };

  //* ===================================================================  */
  //*                               HANDLER                                */
  //* ===================================================================  */

  const handleCheckboxSetSameAddres = (e) => {
    const { checked } = e.target;
    // console.log(name, checked);
    const updatedFormValues = { ...formValues };
    const updatedError = {...errors};
    const addressSection = fields2[1];
    const deleveryAddressSection = fields2[2];
    if (checked) {
      const addressFormValues = formValues[addressSection.title];
      const newDeleveryAddress = {};

      //* Copy value from address;
      for (const keyForm in addressFormValues) {
        newDeleveryAddress[keyForm] = addressFormValues[keyForm];
      }
      updatedFormValues[deleveryAddressSection.title] = newDeleveryAddress;
    } else {
      const emptyFormValues = initialFormValuesBySection(deleveryAddressSection.title);
      updatedFormValues[deleveryAddressSection.title] = emptyFormValues;
    }

    const newDeleveryAddressError = {};
    for(const key in updatedError[deleveryAddressSection.title]){
      newDeleveryAddressError[key] = "";
    }
    updatedError[deleveryAddressSection.title] = newDeleveryAddressError;

    // console.log({newDeleveryAddressError});

    setErrors(updatedError)
    setFormValues(updatedFormValues);
  };

  const handleAddRow = (section, field) => {
    const newFormValues = { ...formValues };
    const updatedErrors = { ...errors };
    let newValue = null;
    let newErrors = null;
    if (field?.children) {
      newValue = {};
      newErrors = {};
      field.children.forEach((fiel) => {
        newValue[fiel.name] = fiel.defaultValue || "";
        // updatedErrors[fiel.name] = "";
        newErrors[fiel.name] = "";
      });
    } else {
      newValue = field.defaultValue || "";
      newErrors = "";
    }
    //* Assing new value to copy formValues
    newFormValues[section.title][field.name].push(newValue);
    setFormValues(newFormValues);

    //* Set new Errors
    updatedErrors[section.title][field.name].push(newErrors);
    setErrors(updatedErrors);
    // console.log({ section, field });
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [sectionTitle]: [
    //     ...prevValues[sectionTitle],
    //     sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
    //   ], // Add an empty row
    // }));

    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [sectionTitle]: [
    //     ...prevErrors[sectionTitle],
    //     sectionTitle === "Family" ? initialErrorFamily : initialErrorEmergencyContact,
    //   ], // Add an empty error object
    // }));
  };

  const handleRemoveRow = (rowIndex, section, field) => {
    const newFormValues = { ...formValues };
    const newErrors = { ...errors };
    newFormValues[section.title][field.name].splice(rowIndex, 1);
    // console.log({ errors: newErrors[section.title] });
    console.log({ error: newErrors[section.title][field.name] });
    // newErrors[section.title][field.name]?.splice(rowIndex, 1);

    setFormValues(newFormValues);
    setErrors(newErrors);
  };

  const handleInputChange = async (event, index, sectionTitle, field, parent) => {
    const { name, value } = event.target;
    // const updatedErrors = { ...errors };
    const updatedFormValues = { ...formValues };

    console.group("handle change");
    console.log({ index, sectionTitle, field, parent, name, value });
    console.count("Change");
    console.groupEnd();
    // return;
    if (parent) {
      if (!isNaN(index)) {
        updatedFormValues[sectionTitle][parent.name][index][name] = value;
      }
    } else if (!isNaN(index) && field.multiple) {
      updatedFormValues[sectionTitle][name][index] = value;
    } else {
      updatedFormValues[sectionTitle][name] = value;
    }

    // debugger;
    // setFormValues((prevValues) => {
    //   const updatedValues = { ...prevValues };
    //   if (
    //     index !== undefined &&
    //     (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
    //   ) {
    //     updatedValues[sectionTitle] = updatedValues[sectionTitle].map((item, i) =>
    //       i === index ? { ...item, [name]: value } : item
    //     );
    //   } else {
    //     updatedValues[sectionTitle] = {
    //       ...updatedValues[sectionTitle],
    //       [name]: value,
    //     };
    //   }

    //   return updatedValues;
    // });

    // setErrors((prevErrors) => {
    //   const fieldValue = value;
    //   const fieldErrors = validateFieldHelper(fieldValue, field);

    //   if (
    //     index !== undefined &&
    //     (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
    //   ) {
    //     updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map((item, i) =>
    //       i === index ? { ...item, [name]: fieldErrors } : { ...item }
    //     );
    //   } else {
    //     updatedErrors[sectionTitle] = {
    //       ...updatedErrors[sectionTitle],
    //       [name]: fieldErrors,
    //     };
    //   }
    //   return updatedErrors;
    // });

    setFormValues(updatedFormValues);

    if (name === "category_id") {
      if (value) {
        const serviceId = value;
        const criteriaEndpoint = `/master/criterias/${serviceId}`;
        fetchOption(criteriaEndpoint, "type_id");
      }
    }

    if (name === "client_id") {
      if (value) {
        const client_id = value;
        const param = { params: { client_id } };
        const applicantEndpoint = "master/applicants";
        const brandEndpoint = "/master/brands";

        fetchOption(applicantEndpoint, "applicant_id", null, param);
        fetchOption(brandEndpoint, "brand_id", null, param);

        // const { data: dataApplicants } = await (
        //   await axios.get(``, {
        //     headers: { Authorization: `Bearer ${token}` },
        //     ,
        //   })
        // )?.data;

        // const { data: dataBrands } = await (
        //   await axios.get(``, {
        //     headers: { Authorization: `Bearer ${token}` },
        //     params: { client_id },
        //   })
        // )?.data;

        // setOptions((prevOptions) => ({
        //   ...prevOptions,
        //   ["applicant_id"]: dataApplicants,
        //   ["brand_id"]: dataBrands,
        // }));

        // const fetchedOptions = JSON.parse(
        //   localStorage.getItem("business_permit_options_step_one")
        // );
        // if (fetchedOptions) {
        //   fetchedOptions["brand_id"] = dataBrands;
        //   fetchedOptions["applicant_id"] = dataApplicants;
        //   localStorage.setItem(
        //     "business_permit_options_step_one",
        //     JSON.stringify(fetchedOptions)
        //   );
        // }
      }
    }

    if (name === "province_id") {
      if (value) {
        const provinceId = value;
        const rigencyEndpoint = "/master/regencies?province_id=".concat(provinceId);
        fetchOption(rigencyEndpoint, "rigency_id");
      }
    }

    updateWizardData("StepOne", updatedFormValues);
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event, parent) => {
    const { name, value } = event.target;
    updateWizardData("StepOne", formValues);
    validateField(sectionTitle, fieldName, index, parent, { name, value });
    // debugger;
  };

  //* ===================================================================  */
  //*                               VALIDATE                               */
  //* ===================================================================  */

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // fields.forEach((fieldGroup) => {
    //   if (fieldGroup.children) {
    //     const { title } = fieldGroup;

    //     const sectionErrors =
    //       fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact"
    //         ? []
    //         : {};

    //     fieldGroup.children.forEach((field) => {
    //       const { name } = field;

    //       if (fieldGroup.title === "Family" || fieldGroup.title === "Emergency Contact") {
    //         formValues[title].forEach((sectionValues, index) => {
    //           const fieldValue =
    //             sectionValues[name] !== undefined ? sectionValues[name] : "";
    //           const error = validateFieldHelper(fieldValue, field);

    //           if (!sectionErrors[index]) {
    //             sectionErrors[index] = {};
    //           }

    //           sectionErrors[index][name] = error;

    //           if (error) {
    //             valid = false;
    //           }
    //         });
    //       } else {
    //         const categoryId = formValues["Applicant Information"]["category_id"];

    //         if (categoryId && categoryId !== 102) {
    //           if (field.name !== "type_id") {
    //             const fieldValue =
    //               formValues[title] && formValues[title][name] !== undefined
    //                 ? formValues[title][name]
    //                 : "";
    //             const error = validateFieldHelper(fieldValue, field);

    //             sectionErrors[name] = error;

    //             if (error) {
    //               valid = false;
    //             }
    //           }
    //         } else {
    //           const fieldValue =
    //             formValues[title] && formValues[title][name] !== undefined
    //               ? formValues[title][name]
    //               : "";
    //           const error = validateFieldHelper(fieldValue, field);

    //           sectionErrors[name] = error;

    //           if (error) {
    //             valid = false;
    //           }
    //         }
    //       }
    //     });

    //     newErrors[title] = sectionErrors;
    //   }
    // });

    fields2.forEach((fieldGroup) => {
      if (fieldGroup.children && Array.isArray(fieldGroup.children)) {
        const sectionTitle = fieldGroup.title;
        console.group("validate form, section: ".concat(sectionTitle));
        fieldGroup.children.forEach((field) => {
          if (field.children && Array.isArray(field.children)) {
            // console.log(field);
            // console.log("has children", field);
            if (!newErrors[sectionTitle]) {
              newErrors[sectionTitle] = {};
            }
            if (!newErrors[sectionTitle][field.name]) {
              newErrors[sectionTitle][field.name] = [];
            }
            const childField = field.children;

            formValues[sectionTitle][field.name].forEach((valueInForm, indexValue) => {
              // console.log({ valueInForm });
              if (typeof valueInForm === "object") {
                for (const keyValue in valueInForm) {
                  if (!newErrors[sectionTitle][field.name][indexValue]) {
                    newErrors[sectionTitle][field.name][indexValue] = {};
                  }
                  const currentField = childField.find((fiel) => fiel.name === keyValue);
                  if (currentField) {
                    const errorValidate = validateFieldHelper(
                      valueInForm[keyValue],
                      currentField
                    );

                    if (errorValidate) {
                      valid = false;
                    }
                    newErrors[sectionTitle][field.name][indexValue][keyValue] =
                      errorValidate;
                  }
                }
              }
            });
          } else if (field.multiple) {
            // console.log("Multiple values", field);
            const valueInForm = formValues[sectionTitle][field.name];
            if (valueInForm && Array.isArray(valueInForm)) {
              if (!newErrors[sectionTitle]) {
                newErrors[sectionTitle] = {};
              }
              const errorsValidate = valueInForm.map((value) =>
                validateFieldHelper(value, field)
              );
              const hasErrorMessage = errorsValidate.some((val) => val != "");
              if (hasErrorMessage) {
                valid = false;
              }
              newErrors[sectionTitle][field.name] = errorsValidate;
            }
          } else {
            if (!newErrors[sectionTitle]) {
              newErrors[sectionTitle] = {};
            }
            const valueInForm = formValues[sectionTitle][field.name];
            const errorValidate = validateFieldHelper(valueInForm, field);

            if (errorValidate) {
              valid = false;
            }
            newErrors[sectionTitle][field.name] = errorValidate;

            // console.log("normal field", { field, errorValidate });
          }
        });
        console.groupEnd();
      }
      console.log("Errors", newErrors);
    });
    setErrors(newErrors);
    formAttempted(valid);
    updateWizardData("StepOne", formValues);
    return valid;
  };

  const validateField = (sectionTitle, fieldName, index, parent, { value }) => {
    // console.group("Validate field");
    const updatedErrors = { ...errors };
    const fieldGroup = fields2.find((group) => group.title === sectionTitle);
    let findedField = null;

    //* find field by type
    if (parent && parent !== null) {
      findedField = parent.children.find((fiel) => fiel.name == fieldName);
    } else if (fieldGroup && fieldGroup.children) {
      findedField = fieldGroup.children.find((field) => field.name === fieldName);
    }

    if (findedField) {
      const { name } = findedField;

      if (parent) {
        const valueForm = formValues[sectionTitle][parent.name][index][name];
        updatedErrors[sectionTitle][parent.name][index][name] = validateFieldHelper(
          valueForm,
          findedField
        );
      } else if (findedField.multiple) {
        const valueForm = formValues[sectionTitle][name][index];
        updatedErrors[sectionTitle][name][index] = validateFieldHelper(
          valueForm,
          findedField
        );
      } else {
        const valueForm = formValues[sectionTitle][name];
        updatedErrors[sectionTitle][name] = validateFieldHelper(valueForm, findedField);
      }
    }
    // console.log("Error validate", updatedErrors[sectionTitle]);
    // console.groupEnd();
    setErrors(updatedErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    }
    if (fieldValue && fieldValue.length && validation && !validation(fieldValue)) {
      return errorMessage || `${label} is invalid`;
    }
    return "";
  };

  useEffect(() => {
    updateWizardData("StepOne", formValues);
  }, [formValues]);

  //* ===================================================================  */
  //*                               WATCHER                                */
  //* ===================================================================  */

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  useEffect(() => {
    console.log("This is option", { options });
  }, [options]);

  //* ===================================================================  */
  //*                               COMPONENT                              */
  //* ===================================================================  */

  const ButtonAdd = ({ section, field }) => {
    // const aa = {section, field};
    // debugger;
    return (
      <Box sx={{ ...justifyContentEnd }}>
        <Button
          onClick={() => handleAddRow(section, field)}
          //   disabled={actionType === ACTION_TYPE.EDITED}
        >
          <span
            style={{
              fontSize: "1.5rem",
              marginRight: ".6rem",
              display: "inline-block",
            }}
          >
            +
          </span>
          Add More
        </Button>
      </Box>
    );
  };

  const ButtonRemove = ({ index, section, field, disabled = false }) => {
    return (
      <Grid grid item xs={2} style={{ paddingBlock: ".2rem" }}>
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            ...removeButton,
          }}
          onClick={() => handleRemoveRow(index, section, field)}
        >
          <img src={RemoveIcon} alt={`Remove item`} />
        </Button>
      </Grid>
    );
  };

  const renderFields = (section, index, field) => {
    const currentFormValues = formValues[section.title];
    const currentErrors = errors[section.title];

    const renderComponentField = (children, parent = null, indexValueParent) => {
      return children?.map((field) => {
        let fieldComponent = null;
        let hasChildren = false;
        if (field.children) {
          hasChildren = true;
          fieldComponent = (
            <>
              {formValues[section.title][field.name].map((_, indexValue, arrayValue) => {
                return (
                  <>
                    <Grid
                      item
                      xs={11.3}
                      container
                      spacing={1}
                      sx={{
                        border: "1px solid black",
                        padding: ".4rem",
                        marginTop: ".5rem",
                        marginLeft: ".5rem",
                        borderRadius: "12px",
                        paddingRight: "1rem",
                      }}
                    >
                      {renderComponentField(field.children, field, indexValue)}
                    </Grid>
                    <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
                      <ButtonRemove index={indexValue} field={field} section={section} disabled={arrayValue.length < 2} />
                    </Grid>
                  </>
                );
              })}
            </>
          );
        } else {
          // const checkCategoryId = formValues["Applicant Information"]["category_id"];

          // const isCategoryIdBPOMandExist =
          //   checkCategoryId !== 102 &&
          //   (checkCategoryId !== undefined ||
          //     checkCategoryId !== null ||
          //     checkCategoryId !== "");

          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          let fieldOptions;
          if (Array.isArray(options[field.name])) {
            fieldOptions = optionsData;
          } else if (field.options && Array.isArray(field.options)) {
            fieldOptions = field.options;
          } else {
            fieldOptions = [];
          }

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              if (field.multiple) {
                // console.log({
                //   formValues: formValues[section.title][field.name],
                //   error: currentErrors[field.name],
                //   field: field.name,
                // });
                let localFormValues = formValues[section.title][field.name];

                fieldComponent = (
                  <>
                    {localFormValues.map((_, indexValue, arrayValue) => {
                      return (
                        <Grid container spacing={0.5} key={indexValue}>
                          <Grid item xs={10.5}>
                            <TextInput
                              key={field.name}
                              field={field}
                              formValues={{
                                [field.name]: currentFormValues[field.name][indexValue],
                              }}
                              errors={{
                                [field.name]: currentErrors[field.name][indexValue],
                              }}
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  indexValue,
                                  section.title,
                                  field,
                                  parent
                                )
                              }
                              handleInputBlur={(e) =>
                                handleInputBlur(
                                  field.name,
                                  section.title,
                                  indexValue,
                                  e,
                                  parent
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <div style={{ marginTop: "1.7rem" }}>
                              <ButtonRemove
                                field={field}
                                index={indexValue}
                                section={section}
                                disabled={arrayValue.length < 2}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid>
                      <ButtonAdd field={field} section={section} />
                    </Grid>
                  </>
                );
              } else {
                let localErrors = currentErrors;
                if (parent) {
                  index = indexValueParent;
                  // console.log("has Parrent", {
                  //   currentFormValues: currentFormValues[field.name],
                  //   currentErrors,
                  // });
                  localErrors = {
                    [field.name]: currentErrors[parent.name][index][field.name],
                  };
                }
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={localErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field, parent)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e, parent)
                    }
                  />
                );
              }
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e, parent)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              if (field.multiple) {
                const localFormValues = formValues[section.title][field.name];
                fieldComponent = (
                  <>
                    {localFormValues.map((_, indexValue, arrayValue) => {
                      return (
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <SelectOne
                              key={field.name}
                              field={field}
                              formValues={currentFormValues[indexValue]}
                              errors={currentErrors}
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  indexValue,
                                  section.title,
                                  field,
                                  parent
                                )
                              }
                              handleInputBlur={(e) =>
                                handleInputBlur(
                                  field.name,
                                  section.title,
                                  indexValue,
                                  e,
                                  parent
                                )
                              }
                              fieldOptions={fieldOptions}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ marginTop: "1rem" }}>
                              <ButtonRemove
                                index={indexValue}
                                field={field}
                                section={section}
                                disabled={arrayValue.length < 2}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid>
                      <ButtonAdd section={section} field={field} />
                    </Grid>
                  </>
                );
              } else {
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field, parent)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e, parent)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
              }
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              if (field.multiple) {
                const localFormValues = formValues[section.title][field.name];
                const localErrors = currentErrors[field.name];
                // console.log({ localFormValues, localErrors });
                fieldComponent = (
                  <>
                    {localFormValues.map((_, indexValue, arrayValue) => {
                      return (
                        <Grid container spacing={1} key={indexValue}>
                          <Grid item xs={10.5}>
                            <SelectOneAutocomplete
                              key={field.name}
                              field={field}
                              formValues={{ [field.name]: localFormValues[indexValue] }}
                              errors={{ [field.name]: localErrors[indexValue] }}
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  indexValue,
                                  section.title,
                                  field,
                                  parent
                                )
                              }
                              handleInputBlur={(e) => {
                                handleInputBlur(
                                  field.name,
                                  section.title,
                                  indexValue,
                                  e,
                                  parent
                                );
                              }}
                              fieldOptions={fieldOptions}
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <div style={{ marginTop: "1.7rem" }}>
                              <ButtonRemove
                                field={field}
                                index={indexValue}
                                section={section}
                                disabled={arrayValue.length < 2}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid>
                      <ButtonAdd section={section} field={field} />
                    </Grid>
                  </>
                );
              } else {
                // console.log({ currentErrors });
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field, parent)
                    }
                    handleInputBlur={(e) => {
                      handleInputBlur(field.name, section.title, index, e, parent);
                    }}
                    fieldOptions={fieldOptions}
                  />
                );
              }
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e, parent)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e, parent)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
              fieldComponent = (
                <SelectOneCountryAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, index, e, parent);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e, parent)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e, parent)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field, parent)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e, parent)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }
        }

        const parentStyle = {
          marginTop: ".2rem",
        };
        let defaultStyle = { paddingTop: "unset !important" };

        if (hasChildren) {
          defaultStyle = { ...parentStyle };
        }
        if (field.multiple && hasChildren) {
          return (
            <>
              <Grid
                item
                container={hasChildren}
                spacing={hasChildren ? 1 : 0}
                xs={field.gridWidth || 11}
                key={field.name}
                sx={defaultStyle}
              >
                {fieldComponent}
              </Grid>
              <Grid item xs={12}>
                <ButtonAdd field={field} section={section} />
              </Grid>
            </>
          );
        }
        return (
          <>
            <Grid item xs={field.gridWidth || 12} key={field.name} sx={defaultStyle}>
              {fieldComponent}
            </Grid>
          </>
        );
      });
    };
    return (
      <>
        {" "}
        {renderComponentField(section.children)}
        {/* {section.children?.map((field) => {

          if(field.children){
            console.log(field);
            return;
          }
          const checkCategoryId = formValues["Applicant Information"]["category_id"];

          const isCategoryIdBPOMandExist =
            checkCategoryId !== 102 &&
            (checkCategoryId !== undefined ||
              checkCategoryId !== null ||
              checkCategoryId !== "");

          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }

          }

          const fieldOptions = Array.isArray(options[field.name]) ? optionsData : [];

          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, index, e);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })} */}
      </>
    );
  };

  const renderSectionFields = (section) => {
    if (section.title === "Family" || section.title === "Emergency Contact") {
      // Render Family or Emergency Contact section
      return (
        // <Grid item xs={12} key={section.title}>
        //   <Box sx={{ marginBottom: 2 }}>
        //     {/* Title */}
        //     <Typography variant="h6" sx={{ fontWeight: 800 }}>
        //       {section.title}
        //     </Typography>
        //   </Box>

        //   {formValues[section.title].map((rowValues, index) => (
        //     <div key={index}>
        //       <Grid container spacing={0.3}>
        //         <Grid item xs={11.3}>
        //           <Grid container spacing={1}>
        //             {renderFields(section, index)} {/* Pass rowIndex */}
        //           </Grid>
        //         </Grid>
        //         <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
        //           <Button
        //             variant="contained"
        //             disableElevation
        //             sx={{
        //               backgroundColor: (theme) => theme.palette.primary.main,
        //               ...removeButton,
        //             }}
        //             onClick={() => handleRemoveRow(section.title, index)}
        //             disabled={formValues[section.title].length === 1}
        //           >
        //             <img src={RemoveIcon} alt={`Remove ${section.title}`} />
        //           </Button>
        //         </Grid>
        //       </Grid>
        //       {formValues[section.title].length > 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
        //     </div>
        //   ))}
        //   <Box sx={{ ...justifyContentEnd }}>
        //     <Button onClick={() => handleAddRow(section)}>
        //       Add Another {section.title === "Family" ? "Member" : "Contact"}
        //     </Button>
        //   </Box>
        // </Grid>
        <Grid>Noting</Grid>
      );
    } else {
      // Render other sections using renderFields function
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
            {/* Subtitle */}
            {section.subtitle && (
              <Typography variant="subtitle2">{section.subtitle}</Typography>
            )}
          </Box>
          <Grid container spacing={1}>
            {section.title === "Delivery Address" && (
              <Grid item xs={12} sx={{ marginBottom: ".5rem" }}>
                <FormGroup>
                  <FormControlLabel
                    className="form-control-label full-width-checkbox"
                    label={<FormLabel>Same as the address</FormLabel>}
                    // sx={{ background:"yellow" }}
                    control={
                      <Box sx={{}}>
                        <Checkbox
                          name="set_same_address"
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          onChange={handleCheckboxSetSameAddres}
                        />
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
            )}
            {renderFields(section)}
          </Grid>
        </Grid>
      );
    }
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {fields2.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default StepOne;
