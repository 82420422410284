import { REQUEST_LOADING } from "../../../../util/global_state";

import {
  ADD_EMPLOYEE_RESIGNATION_FAILED,
  ADD_EMPLOYEE_RESIGNATION_SUCCESS,
  DELETE_EMPLOYEE_RESIGNATION_FAILED,
  DELETE_EMPLOYEE_RESIGNATION_SUCCESS,
  EDIT_EMPLOYEE_RESIGNATION_FAILED,
  EDIT_EMPLOYEE_RESIGNATION_SUCCESS,
  GET_EMPLOYEE_RESIGNATION_FAILED,
  GET_EMPLOYEE_RESIGNATION_SUCCESS,
} from "./state";

const initState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

const employeeResignationReducer = (state = initState, { type, payload, pagination }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_EMPLOYEE_RESIGNATION_SUCCESS:
      return {
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case GET_EMPLOYEE_RESIGNATION_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_EMPLOYEE_RESIGNATION_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_EMPLOYEE_RESIGNATION_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_EMPLOYEE_RESIGNATION_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_EMPLOYEE_RESIGNATION_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_EMPLOYEE_RESIGNATION_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_EMPLOYEE_RESIGNATION_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default employeeResignationReducer;
