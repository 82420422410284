import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Box, Divider, Button } from "@mui/material";
import CalendarIcon from "./../../../assets/calendar.png";
import RemoveIcon from "./../../../assets/minus.svg";

import {
  removeButton,
  justifyContentEnd,
  ButtonSaveOnStep,
} from "../../../util/style";
import axios from "../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../../components/Form";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../util/function";
import { useSelector } from "react-redux";

const Step1 = ({
  handleSubmit,
  handleChangeStates,
  formData,
  isNextTriggered,
  setIsNextTriggered,
  indexTabsActive,
}) => {
  // const [isFormValid, setIsFormValid] = useState(false);
  const [isPermanent, setIsPermanent] = useState(
    (formData &&
      formData["Identity & Address"].is_permanent.length &&
      formData["Identity & Address"].is_permanent[0] === 1) ||
      false
  );

  const isIdentityExpiryDisabled = () => {
    return isPermanent;
  };

  const isIdentityExpiryRequired = () => {
    return !isPermanent;
  };

  const fields = [
    {
      title: "Basic Information",
      subtitle: "Complete all employee basic information, as basic data",
    },
    {
      title: "Personal Data",
      children: [
        {
          name: "employe_name",
          type: INPUT_TYPE.TEXT,
          label: "Employee Name",
          placeholder: "Input employee name",
          defaultValue: "",
          required: true,
          gridWidth: 12, // Full width
        },
        {
          name: "birthdate",
          type: INPUT_TYPE.DATE,
          label: "Birth Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "DD/MM/YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "birthplace",
          type: INPUT_TYPE.TEXT,
          label: "Place Of Birth",
          placeholder: "Input place of birth",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "gender",
          type: INPUT_TYPE.RADIO,
          label: "Gender",
          defaultValue: [], // Array for selected format values
          options: [
            { id: "male", name: "Male" },
            { id: "female", name: "Female" },
          ],
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "religion_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Religion",
          placeholder: "Select Religion",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/religions",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "marital_status_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Marital Status",
          placeholder: "Select marital status",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/marital-statuses",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "blood_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Blood Type",
          placeholder: "Select blood type ( Ops )",
          // defaultValue:  SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/bloods",
          required: false,
          gridWidth: 6, // Half width
        },
      ],
    },
    {
      title: "Contact Information",
      children: [
        {
          name: "email",
          type: INPUT_TYPE.TEXT,
          label: "Email",
          defaultValue: "",
          required: true,
          validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          errorMessage: "Invalid email address",
          gridWidth: 12, // Full width
        },
        {
          name: "mobile_phone",
          type: INPUT_TYPE.PHONE_NUMBER,
          label: "Mobile Phone",
          placeholder: "Input mobile phone",
          defaultValue: "",
          validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
          errorMessage:
            "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "phone",
          type: INPUT_TYPE.NUMBER,
          label: "Phone",
          placeholder: "Input phone ( Ops )",
          defaultValue: "",
          required: false,
          validation: (value) => /^02\d+$/.test(value),
          errorMessage: "Phone should start with '02' and numeric characters",
          gridWidth: 6, // Half width
        },
      ],
    },
    {
      title: "Identity & Address",
      children: [
        {
          name: "identity_type",
          type: INPUT_TYPE.RADIO,
          label: "Identity Type",
          defaultValue: [], // Array for selected format values
          options: [
            { id: "ktp", name: "KTP" },
            { id: "passport", name: "Passport" },
          ],
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "identity_number",
          type: INPUT_TYPE.NUMBER,
          label: "Identity Number",
          defaultValue: "",
          validation: (value) => /^.{1,16}$/.test(value),
          errorMessage: "Please enter a value with a maximum of 16 characters",
          required: true,
          gridWidth: 6,
        },
        {
          name: "identity_expiry",
          type: INPUT_TYPE.DATE,
          label: "Identitity Expiration Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "DD/MM/YYYY", // Custom date format
          defaultValue: "",
          required: isIdentityExpiryRequired(),
          additionalProps: {
            disabled: isIdentityExpiryDisabled(),
          },
          gridWidth: 6, // Half width
        },
        {
          name: "postal_code",
          type: INPUT_TYPE.NUMBER,
          label: "Postal Code",
          placeholder: "Input postal code ( Ops )",
          defaultValue: "",
          validation: (value) => /^.{1,9}$/.test(value),
          errorMessage: "Please enter a value with a maximum of 9 characters",
          required: false,
          gridWidth: 6, // Half width
        },
        {
          name: "is_permanent",
          type: INPUT_TYPE.CHECKBOX,
          defaultValue: "", // Array for selected format values
          options: [{ id: 1, name: "Permanent" }],
          gridWidth: 6, // Half width
        },
        {
          name: "citizen_id_address",
          type: INPUT_TYPE.TEXTAREA,
          label: "Citizen ID Address",
          placeholder: "Input citizen id address",
          defaultValue: "",
          required: true,
          gridWidth: 12, // Half width
        },
        {
          name: "use_as_residential_address",
          type: INPUT_TYPE.CHECKBOX,
          defaultValue: "", // Array for selected format values
          options: [{ id: 1, name: "Use as residential address" }],
          gridWidth: 12, // Half width
        },
        {
          name: "residential_address",
          type: INPUT_TYPE.TEXTAREA,
          label: "Residential Address",
          placeholder: "Input residential address",
          defaultValue: "",
          required: true,
          gridWidth: 12, // Half width
        },
      ],
    },
    {
      title: "Family",
      children: [
        {
          name: "name",
          type: INPUT_TYPE.TEXT,
          label: "Full Name",
          placeholder: "Input full name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Full width
        },
        {
          name: "relation_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Relationship",
          placeholder: "Select relationship",
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/family-relations",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "birthdate",
          type: INPUT_TYPE.DATE,
          label: "Birth Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "DD/MM/YYYY", // Custom date format
          defaultValue: "",
          gridWidth: 6, // Half width
          required: false,
        },
        {
          name: "gender",
          type: INPUT_TYPE.RADIO,
          label: "Gender",
          defaultValue: [], // Array for selected format values
          options: [
            { id: "male", name: "Male" },
            { id: "female", name: "Female" },
          ],
          gridWidth: 6, // Half width
          required: true,
        },
        {
          name: "religion_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Religion",
          placeholder: "Select religion ( Ops )",
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/religions",
          required: false,
          gridWidth: 6, // Half width
        },
        {
          name: "marital_status_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Marital Status",
          placeholder: "Select marital status ( Ops )",
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/marital-statuses",
          required: false,
          gridWidth: 6, // Half width
        },
        {
          name: "identity_number",
          type: INPUT_TYPE.NUMBER,
          label: "Identity Number",
          placeholder: "Input identity number ( Ops )",
          defaultValue: "",
          validation: (value) => /^.{1,16}$/.test(value),
          errorMessage: "Please enter a value with a maximum of 16 characters",
          required: false,
          gridWidth: 6,
        },
        {
          name: "job_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Job",
          placeholder: "Select job ( Ops )",
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/jobs",
          required: false,
          gridWidth: 6, // Half width
        },
      ],
    },
    {
      title: "Emergency Contact",
      children: [
        {
          name: "name",
          type: INPUT_TYPE.TEXT,
          label: "Full Name",
          placeholder: "Input full name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Full width
        },
        {
          name: "relation_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Relationship",
          placeholder: "Select relationship",
          defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/family-relations",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "phone",
          type: INPUT_TYPE.TEXT,
          label: "Phone Number",
          placeholder: "Input phone number",
          defaultValue: "",
          validation: (value) => /^(\+62\d{8,13}|08\d{7,12})$/.test(value),
          errorMessage:
            "Please enter a valid phone number (9-14 digits, starting with '08' or '+62').",
          required: true,
          gridWidth: 12, // Half width
        },
      ],
    },
  ];

  const onSave = (e) => {
    const isValid = validateForm();
    if (isValid) {
      const updatedFormValues = { ...formValues };
      handleSubmit(e, {
        stepName: "StepOne",
        stepData: updatedFormValues,
      });
      setIsNextTriggered({ isRun: "YES" });
    }
  };
  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      // validateForm();
    }
  }, [isNextTriggered]);

  const { token } = useSelector((state) => state.auth);
  const initialFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };

  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const initialErrorFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };
  const initialErrorEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const handleAddRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Family"
          ? initialErrorFamily
          : initialErrorEmergencyContact,
      ], // Add an empty error object
    }));
  };

  const handleRemoveRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  };

  const initialFormValues = () => {
    const initialValues = {};
    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
        ) {
          initialValues[fieldGroup.title] =
            fieldGroup.title === "Family"
              ? [initialFamily]
              : [initialEmergencyContact];
        } else {
          fieldGroup.children.forEach((field) => {
            if (!initialValues[fieldGroup.title]) {
              initialValues[fieldGroup.title] = {};
            }
            initialValues[fieldGroup.title][field.name] = field.defaultValue;
          });
        }
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const initialErrorValues = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
        ) {
          initialErrorValues[fieldGroup.title] =
            fieldGroup.title === "Family"
              ? [initialErrorFamily]
              : [initialErrorEmergencyContact];
        } else {
          if (!initialErrorValues[fieldGroup.title]) {
            initialErrorValues[fieldGroup.title] = {};
          }
          fieldGroup.children.forEach((field) => {
            initialErrorValues[fieldGroup.title][field.name] = "";
          });
        }
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0
      ? formData
      : initialFormValues()
  );

  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState(
    formData && Object.values(formData)?.length > 0
      ? cloneObjectWithEmptyStrings(formData)
      : initialErrors()
  );

  useEffect(() => {
    const storedOptions = JSON.parse(localStorage.getItem("options_step_one"));

    if (!storedOptions || Object.keys(storedOptions).length === 0) {
      // Options data not found in localStorage, fetch it
      const fetchData = async () => {
        try {
          const fetchedOptions = {};

          for (const section of fields) {
            if (section.children) {
              for (const field of section.children) {
                const { name, apiEndpoint } = field;

                if (apiEndpoint) {
                  const response = await axios.get(apiEndpoint, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });

                  fetchedOptions[name] = response.data.data;
                }
              }
            }
          }

          // Store fetched options in localStorage
          localStorage.setItem(
            "options_step_one",
            JSON.stringify(fetchedOptions)
          );

          // Set options in state
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      };

      fetchData();
    } else {
      // Options data found in localStorage, set it in state
      setOptions(storedOptions);
    }
  }, [token]);

  const handleInputChange = (event, index, sectionTitle, field) => {
    const { name, value } = event.target;
    const updatedErrors = { ...errors };

    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (
        index !== undefined &&
        (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
      ) {
        updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
          (item, i) => (i === index ? { ...item, [name]: value } : item)
        );
      } else {
        updatedValues[sectionTitle] = {
          ...updatedValues[sectionTitle],
          [name]: value,
        };
      }

      // Buat column identity_expiry
      if (name === "is_permanent") {
        if (value.includes(1)) {
          setIsPermanent(true);
          updatedValues[sectionTitle]["identity_expiry"] = "";
          updatedErrors[sectionTitle]["identity_expiry"] = "";
        } else {
          setIsPermanent(false);
        }
      }

      // Buat column residential address
      if (name === "use_as_residential_address" && value.includes(1)) {
        updatedValues[sectionTitle]["residential_address"] =
          updatedValues[sectionTitle]["citizen_id_address"];
      }

      if (
        name === "residential_address" &&
        value !== updatedValues[sectionTitle]["citizen_id_address"]
      ) {
        updatedValues[sectionTitle]["use_as_residential_address"] = [];
      }

      if (
        name === "citizen_id_address" &&
        updatedValues[sectionTitle]["use_as_residential_address"].length > 0 &&
        updatedValues[sectionTitle]["use_as_residential_address"].includes(1)
      ) {
        updatedValues[sectionTitle]["residential_address"] =
          updatedValues[sectionTitle]["citizen_id_address"];
      }

      if (name.includes('date') || name.includes('identity_expiry')) {
        updatedValues[sectionTitle][name] = value === '1970-01-01' || value === 'NaN-NaN-NaN' ? '' : value
      }

      handleChangeStates({
        stepName: "StepOne",
        stepData: updatedValues,
      });
      return updatedValues;
    });

    setErrors((prevErrors) => {
      const fieldValue = value;
      const fieldErrors = validateFieldHelper(fieldValue, field);

      if (
        index !== undefined &&
        (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
      ) {
        updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
          (item, i) =>
            i === index ? { ...item, [name]: fieldErrors } : { ...item }
        );
      } else {
        updatedErrors[sectionTitle] = {
          ...updatedErrors[sectionTitle],
          [name]: fieldErrors,
        };
      }
      return updatedErrors;
    });
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
    handleChangeStates({
      stepName: "StepOne",
      stepData: { ...formValues },
    });
    // debugger;
  };

  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    console.log(
      "------------------------------FORM VALUE----------------------"
    );
    console.log(formValues);
    console.log("------------------------------ERRRORS----------------------");
    console.log(errors);
  }, [formValues, errors]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;

        const sectionErrors =
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
            ? []
            : {};

        fieldGroup.children.forEach((field) => {
          const { name } = field;

          if (
            fieldGroup.title === "Family" ||
            fieldGroup.title === "Emergency Contact"
          ) {
            formValues[title].forEach((sectionValues, index) => {
              const fieldValue =
                sectionValues[name] !== undefined ? sectionValues[name] : "";
              const error = validateFieldHelper(fieldValue, field);

              if (!sectionErrors[index]) {
                sectionErrors[index] = {};
              }

              sectionErrors[index][name] = error;

              if (error) {
                valid = false;
              }
            });
          } else {
            const fieldValue =
              formValues[title] && formValues[title][name] !== undefined
                ? formValues[title][name]
                : "";
            const error = validateFieldHelper(fieldValue, field);

            sectionErrors[name] = error;

            if (error) {
              valid = false;
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const renderFields = (section, index, field) => {
    const currentFormValues =
      section.title === "Family"
        ? formValues[section.title][index]
        : section.title === "Emergency Contact"
        ? formValues[section.title][index]
        : formValues[section.title];

    const currentErrors =
      section.title === "Family"
        ? errors[section.title][index]
        : section.title === "Emergency Contact"
        ? errors[section.title][index]
        : errors[section.title];

    return (
      <>
        {section.children?.map((field) => {
          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name])
            ? optionsData
            : [];

          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) => {
                    handleInputBlur(field.name, section.title, index, e);
                  }}
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section) => {
    if (section.title === "Family" || section.title === "Emergency Contact") {
      // Render Family or Emergency Contact section
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
          </Box>

          {formValues[section.title].map((rowValues, index) => (
            <div key={index}>
              <Grid container spacing={0.3}>
                <Grid item xs={11.3}>
                  <Grid container spacing={1}>
                    {renderFields(section, index)} {/* Pass rowIndex */}
                  </Grid>
                </Grid>
                <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      ...removeButton,
                    }}
                    onClick={() => handleRemoveRow(section.title, index)}
                    disabled={formValues[section.title].length === 1}
                  >
                    <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                  </Button>
                </Grid>
              </Grid>
              {formValues[section.title].length > 1 && (
                <Divider sx={{ mt: 2, mb: 2 }} />
              )}
            </div>
          ))}
          <Box sx={{ ...justifyContentEnd }}>
            <Button onClick={() => handleAddRow(section.title)}>
              Add Another {section.title === "Family" ? "Member" : "Contact"}
            </Button>
          </Box>
        </Grid>
      );
    } else {
      // Render other sections using renderFields function
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
            {/* Subtitle */}
            {section.subtitle && (
              <Typography variant="subtitle2">{section.subtitle}</Typography>
            )}
          </Box>
          <Grid container spacing={1}>
            {renderFields(section)}
          </Grid>
        </Grid>
      );
    }
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          height: "52vh",
          paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {fields.map((section) => renderSectionFields(section))}
        <ButtonSaveOnStep onSave={onSave} />
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default Step1;
